import styled from 'styled-components';

export const BlobMetricsCountBarWrapper = styled.div`
  background: var(--white);
  box-shadow: 0px 0px 13px var(--black-50);
  display: flex;
  flex-wrap: wrap;
  padding: 7px 5px;
  margin: 1px 0px 5px;
  border-radius: 4px;
`;

export const MetricsCountItemWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0 25px;

  &:not(:last-child) {
    border-right: 1.5px solid var(--gray-275);
  }
`;

export const MetricsCountLabel = styled.span`
  color: var(--gray-900);
  font-size: var(--font-size-md);
`;

export const MetricsCountValue = styled.span`
  margin-right: 15px;
  color: var(--primary-color);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-medium);
`;
