import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Form, Radio, Select } from 'antd';
import type { DatePickerProps, RadioChangeEvent } from 'antd';
import { CalendarOutlined, HourglassOutlined, SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import MultiSelect from '../MultiSelect';
import { quickTimePickerOptions } from '../../../constants/DropDownOptions/dashboard';
import { applicationDataHelper } from '../../../helpers/applicationDataHelper';
import { disableFutureDateSelection } from '../../../helpers/commonHelper';
import type { SearchDateTimePickerType } from '../../../constants/Interfaces/SearchPanel';
import type { SearchFormData } from '../../../constants/Interfaces/DashboardTypes';
import {
  SearchPanelWrapper,
  SearchByWrapper,
  QuickTimePickerWrapper,
  ApplicationSelectionWrapper,
  DatePickerWrapper,
  DateTimePickerWrapper
} from './style';
import { SearchButton } from '../../AdditionalComponents/LogException/LogExceptionSearchPanel/style';

interface SearchPanelProps {
  formData?: SearchFormData
  hasDatePicker?: boolean
  isMultiSelectEnabled?: boolean
  extraSearchType?: string
  hasSearchByType?: boolean
  searchByOptionList?: any[]
  quickTimeOptionList?: any[]
  onSearchSubmitHandler: any
}

const SearchPanel: FC<SearchPanelProps> = ({
  formData,
  hasDatePicker,
  extraSearchType,
  isMultiSelectEnabled,
  onSearchSubmitHandler,
  hasSearchByType,
  searchByOptionList,
  quickTimeOptionList
}) => {
  const [form] = Form.useForm();
  const selectedApplicationName = Form.useWatch('applicationName', form);
  const searchByTypeName = Form.useWatch('searchByType', form);
  const applicationList = useSelector(
    (state: any) => state.applicationConfig.applicationConfigList
  );
  const userAccessInfo = useSelector((state: any) => state.userAccess.userAccessInfo);
  const [appNameOptionList, setAppNameOptionList] = useState([]);
  const [searchByTypeList, setSearchByTypeList] = useState(searchByOptionList);
  const [isDatePickerOpen, setDatePickerOpenState] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [isQuickPickerOpen, setQuickPickerOpenState] = useState<boolean>(false);
  const [selectedDateTimePicker, setSelectedDateTimePicker] =
    useState<SearchDateTimePickerType>('quickTimePicker');

  const handleQuickTimePickerChange = (): void => {
    form.setFieldValue('selectedDate', '');
    setIsFormValid(true);
    setQuickPickerOpenState(false);
  };

  const handleDateChange: DatePickerProps['onChange'] = (): void => {
    setIsFormValid(true);
    form.setFieldValue('quickTime', '');
    setDatePickerOpenState(false);
  };

  const onDateTimePickerChange = (evt: RadioChangeEvent): void => {
    if (evt.target.value === 'datePicker') {
      setQuickPickerOpenState(false);
      setDatePickerOpenState(true);
    } else {
      setDatePickerOpenState(false);
      setQuickPickerOpenState(true);
    }
    setSelectedDateTimePicker(evt.target.value);
  };

  const onSubmitSearchFormHandler = (): void => {
    form
      .validateFields()
      .then((values) => {
        values = {
          ...values,
          applicationName: isMultiSelectEnabled
            ? values.applicationName
            : values.applicationName[0].value,
          ...(!values.quickTime && values.searchByType !== extraSearchType && {
            selectedDate: dayjs(values.selectedDate).format('YYYY-MM-DD')
          }),
          ...(!values.selectedDate && values.searchByType !== extraSearchType && {
            quickTime: values.quickTime
          }),
          ...(values.searchByType === extraSearchType && {
            startTime: dayjs(values.selectedDateRange[0]).format('YYYY-MM-DD'),
            endTime: dayjs(values.selectedDateRange[1]).format('YYYY-MM-DD')
          })
        };
        onSearchSubmitHandler(values);
      })
      .catch((e) => console.log('err', e));
  };

  useEffect(() => {
    let filteredApplicationList: any = [];
    if (userAccessInfo?.adminAccess === 'Y') {
      filteredApplicationList = applicationDataHelper.getApplicationNames(applicationList);
    } else {
      filteredApplicationList = applicationDataHelper.getApplicationNamesForUser(
        userAccessInfo,
        applicationList
      );
    }

    if (!isMultiSelectEnabled) {
      filteredApplicationList.unshift({ label: 'All', value: '' });
    }

    setAppNameOptionList(filteredApplicationList);
  }, [applicationList, userAccessInfo]);

  const selectedAppName = selectedApplicationName?.length > 0 && selectedApplicationName[0]?.value;
  const isDatePickerEnabled = hasDatePicker && selectedAppName;

  useEffect(() => {
    if (!selectedAppName) {
      setSelectedDateTimePicker('quickTimePicker');
      form.setFieldValue('quickTime', 24);
    } else if (extraSearchType) {
      let searchTypeOptionList = [];
      if (
        userAccessInfo?.adminAccess === 'Y' ||
        userAccessInfo?.roleAccess[selectedAppName].includes(extraSearchType.toLowerCase())
      ) {
        searchTypeOptionList = [
          ...(searchByOptionList ?? []),
          { label: extraSearchType, value: extraSearchType }
        ];
      } else {
        searchTypeOptionList = [...(searchByOptionList ?? [])];
      }

      setSearchByTypeList(searchTypeOptionList);
    }
  }, [selectedAppName]);

  useEffect(() => {
    if (extraSearchType && searchByTypeName === extraSearchType && (userAccessInfo?.adminAccess === 'N' &&
    !userAccessInfo?.roleAccess[selectedAppName].includes(extraSearchType.toLowerCase()))) {
      form.setFieldValue('searchByType', 'Component')
    }
  }, [searchByTypeName, selectedAppName])

  useEffect(() => {
    if (formData) {
      const data = {
        ...formData,
        applicationName: [
          {
            label: formData.applicationName || 'All',
            value: formData.applicationName || ''
          }
        ]
      };
      form.setFieldsValue(data);
      setSelectedDateTimePicker(formData.selectedDate ? 'datePicker' : 'quickTimePicker');

      if (formData.quickTime) {
        form.setFieldValue('selectedDate', '');
      } else {
        form.setFieldValue('selectedDate', dayjs(formData.selectedDate));
      }

      if (formData.searchByType === extraSearchType) {
        form.setFieldValue('selectedDateRange', [dayjs(formData.startTime), dayjs(formData.endTime)]);
      }

      setIsFormValid(true);
    }
  }, [formData]);

  return (
    <Form
      form={form}
      onFinish={onSubmitSearchFormHandler}
      onFinishFailed={(formData: any) => {
        if (formData?.errorFields?.length > 0) {
          setIsFormValid(false);
        }
      }}
      initialValues={{
        ...(!isMultiSelectEnabled && { applicationName: [{ label: 'All', value: '' }] }),
        searchByType: 'Component',
        quickTime: 24
      }}
      data-testid="searchPanelForm"
    >
      <SearchPanelWrapper isFormInvalid={!isFormValid}>
        <ApplicationSelectionWrapper>
          <Form.Item
            name="applicationName"
            rules={[{ required: true, message: 'Application is mandatory!' }]}
            noStyle
          >
            <MultiSelect
              dropdownPanelSize="medium"
              fieldName="Application"
              placeholder="Search Application"
              id="applicationName"
              options={appNameOptionList}
              label={''}
              hasSelectAll={isMultiSelectEnabled}
              multi={isMultiSelectEnabled}
              searchInputPlaceholder=""
              allowClear={isMultiSelectEnabled}
              clearOnSelect
            />
          </Form.Item>
        </ApplicationSelectionWrapper>
        {hasSearchByType &&
          selectedApplicationName?.length > 0 &&
          selectedApplicationName[0]?.value && (
            <SearchByWrapper>
              <Form.Item name="searchByType">
                <Select
                  options={searchByTypeList}
                  showSearch={false}
                  {...(isMultiSelectEnabled && { mode: 'multiple' })}
                />
              </Form.Item>
            </SearchByWrapper>
        )}
        <DateTimePickerWrapper isFormInvalid={!isFormValid}>
          {searchByTypeName === extraSearchType
            ? (
                <Form.Item
                  name="selectedDateRange"
                  rules={[{ required: true, message: 'Start/End Date is mandatory!' }]}
                >
                  <DatePicker.RangePicker
                    allowClear={false}
                    disabledDate={disableFutureDateSelection}
                    onChange={() => setIsFormValid(true)}
                    presets={[
                      { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
                      { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
                      { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] }
                    ]}
                    bordered={false}
                  />
                </Form.Item>
              )
            : (
            <>
              {isDatePickerEnabled && (
                <Radio.Group value={selectedDateTimePicker} onChange={onDateTimePickerChange}>
                  <Radio.Button value="quickTimePicker">
                    <HourglassOutlined />
                  </Radio.Button>
                  <Radio.Button value="datePicker">
                    <CalendarOutlined />
                  </Radio.Button>
                </Radio.Group>
              )}
              {isDatePickerEnabled && selectedDateTimePicker === 'datePicker' && (
                <DatePickerWrapper>
                  <Form.Item
                    name="selectedDate"
                    rules={[{ required: true, message: 'Date is mandatory!' }]}
                  >
                    <DatePicker
                      disabledDate={(current) => disableFutureDateSelection(current, true)}
                      open={isDatePickerOpen}
                      onChange={handleDateChange}
                      suffixIcon={null}
                      onOpenChange={(open) => setDatePickerOpenState(open)}
                    />
                  </Form.Item>
                </DatePickerWrapper>
              )}
              {selectedDateTimePicker === 'quickTimePicker' && (
                <QuickTimePickerWrapper>
                  <Form.Item
                    name="quickTime"
                    rules={[{ required: true, message: 'Time is mandatory!' }]}
                  >
                    <Select
                      open={isQuickPickerOpen}
                      onChange={handleQuickTimePickerChange}
                      options={quickTimeOptionList}
                      showSearch={false}
                      onDropdownVisibleChange={(visible) => setQuickPickerOpenState(visible)}
                    />
                  </Form.Item>
                </QuickTimePickerWrapper>
              )}
            </>
              )}
        </DateTimePickerWrapper>
        <SearchButton
          type="primary"
          data-testid="submitSearchBtn"
          htmlType="submit"
          icon={<SearchOutlined />}
        />
      </SearchPanelWrapper>
    </Form>
  );
};

SearchPanel.defaultProps = {
  hasDatePicker: true,
  isMultiSelectEnabled: false,
  extraSearchType: '',
  quickTimeOptionList: quickTimePickerOptions
};

SearchPanel.propTypes = {
  formData: PropTypes.any,
  hasDatePicker: PropTypes.bool,
  extraSearchType: PropTypes.string,
  isMultiSelectEnabled: PropTypes.bool,
  hasSearchByType: PropTypes.bool,
  searchByOptionList: PropTypes.any,
  quickTimeOptionList: PropTypes.any,
  onSearchSubmitHandler: PropTypes.func
};

export default SearchPanel;
