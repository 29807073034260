import {
  EXCEPTION_RECENT_SEARCH_KEY,
  LOG_RECENT_SEARCH_KEY
} from '../constants/KeyLabels/commonKeyConstants';
import { RECENT_SEARCH_MAX_LIMIT, SAVED_SEARCH_MAX_LIMIT } from '../constants/commonConstants';
import { store } from '../redux/store/store';

export const saveRecentSearchQuery = (logOrExceptionType: string, searchQuery: any): void => {
  const searchTypeKey =
    logOrExceptionType.toString().toLowerCase() === 'log'
      ? LOG_RECENT_SEARCH_KEY
      : EXCEPTION_RECENT_SEARCH_KEY;

  const localStoredRecentSearch = localStorage.getItem(searchTypeKey);
  let parsedRecentSearch = localStoredRecentSearch ? JSON.parse(localStoredRecentSearch) : [];

  if (
    !parsedRecentSearch.map((obj: any) => obj.searchQuery).includes(JSON.stringify(searchQuery))
  ) {
    if (parsedRecentSearch.length === RECENT_SEARCH_MAX_LIMIT) {
      parsedRecentSearch.pop();
    }
    parsedRecentSearch = [
      { type: logOrExceptionType, searchQuery: JSON.stringify(searchQuery) },
      ...parsedRecentSearch
    ];
  }

  localStorage.setItem(searchTypeKey, JSON.stringify(parsedRecentSearch));
};

export const checkSavedSearchCriteriaLimitIsReached = (): boolean => {
  const globalStore = store.getState();
  const globalSavedSearchList = globalStore?.savedSearch?.savedSearchDataList;
  if (
    globalSavedSearchList.length === SAVED_SEARCH_MAX_LIMIT ||
    globalSavedSearchList.length > SAVED_SEARCH_MAX_LIMIT
  ) {
    return true;
  }
  return false;
};
