import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { ApplicationAccessCheckBoxGroup, ApplicationStickyWrapper, ApplicationAccessMainWrapper, ApplicationAccessNoApplicationWrapper, ApplicationAccessRadioGroup, ApplicationAccessTableHeaderWrapper } from './style';
import { Form, Row, Col } from 'antd';
import Loader from '../Loader';
import FloatingLabel from '../FloatingLabel';
import { applicationDataHelper } from '../../../helpers/applicationDataHelper';
import { AccessFormItemsLayout } from '../../../constants/FormLayout/AccessFormLayout';
import { DeleteOutlined } from '@ant-design/icons';
import { DeleteIconButton } from '../Footer/Buttons.style';
import { AddButton } from '../Buttons/buttons';
import { AdminRoleList, AdminRoleListForForm, userAccessRolesList, userAccessRolesListForForm } from '../../../constants/FormFields/AccessManagementForm/userAccessRoleList';
import ApplicationAccessHeader from './ApplicationAccessHeader';
import { filterOptionListBySelectedValue } from '../../../helpers/commonHelper';
import textConstants from '../../../constants/textConstants';
import { NoDataAvailableWrapper } from '../../../assets/styles/commonStyled';

interface componentProps {
  accessType?: string
  allowClear?: boolean
  fieldName: string
  formRef?: any
  hasAccessTitle?: boolean
  hasMirrorAccess?: boolean
  showAppAdminAccess?: boolean
  isMirrorRequestCompleted?: boolean | null
  isMultiSelect?: boolean
  selectedApplication?: any
  isViewMode: boolean
  optionList?: any[]
  onCopyUserAccessHandler?: any
}

const columnSpan = AccessFormItemsLayout.columnSpan;

const ApplicationAccess: FC<componentProps> = ({
  accessType,
  allowClear,
  fieldName,
  formRef,
  hasAccessTitle,
  hasMirrorAccess,
  showAppAdminAccess,
  isMirrorRequestCompleted,
  isMultiSelect,
  selectedApplication,
  isViewMode,
  optionList,
  onCopyUserAccessHandler
}) => {
  const [searchValue, setSearchInputValue] = useState('');
  const [mirrorId, setMirrorId] = useState('');

  const accessOptionDataList = optionList ?? applicationDataHelper.getApplicationNames([]);

  const accessFieldType = `${accessType}Name`;

  useEffect(() => {
    if (isMirrorRequestCompleted) {
      setMirrorId('');
    }
  }, [isMirrorRequestCompleted]);

  return (
    <ApplicationAccessMainWrapper
      className="application-access"
      data-testid="testid-application-access-wrapper"
    >
      <Form.List name={fieldName}>
        {(fieldsItem, { add, remove }) => {
          return (
            <>
              <ApplicationStickyWrapper>
                <ApplicationAccessHeader
                  accessFieldType={accessType}
                  onAdd={add}
                  isViewMode={isViewMode}
                  hasAddBtn={!isViewMode && fieldsItem?.length > 0}
                  mirrorInputValue={mirrorId}
                  searchValue={searchValue}
                  applicationSearchHandler={setSearchInputValue}
                  hasAccessTitle={hasAccessTitle}
                  hasMirrorAccess={hasMirrorAccess}
                  onMirrorAccess={onCopyUserAccessHandler}
                  onMirrorInputChange={setMirrorId}
                  isCompleted={isMirrorRequestCompleted}
                />
                <ApplicationAccessTableHeaderWrapper>
                  <Row gutter={AccessFormItemsLayout.rowGutter}>
                    <Col
                      key={accessFieldType}
                      xs={columnSpan.xs}
                      md={AccessFormItemsLayout.applicationColMdSpan}
                    >
                      {accessType === 'application' ? 'Application Name' : 'Group Name'}
                    </Col>
                    {[...userAccessRolesList, ...(showAppAdminAccess ? AdminRoleList : [])]?.map(
                      (accessElementItem) => {
                        return (
                          <Col
                            key={accessElementItem.value}
                            xs={columnSpan.xs}
                            md={columnSpan.md}
                            xxl={columnSpan.xxl}
                          >
                            {accessElementItem.label}
                          </Col>
                        );
                      }
                    )}
                    {!isViewMode && (
                      <Col
                        key={'actions'}
                        xs={columnSpan.xs}
                        md={AccessFormItemsLayout.actionColSpan}
                        xxl={AccessFormItemsLayout.actionColSpan}
                      >
                        Actions
                      </Col>
                    )}
                  </Row>
                </ApplicationAccessTableHeaderWrapper>
              </ApplicationStickyWrapper>
              {fieldsItem?.length !== 0 && (
                <>
                  {fieldsItem?.filter((item: any) => !formRef.getFieldValue([fieldName, item.name, accessFieldType]) || formRef.getFieldValue([fieldName, item.name, accessFieldType])?.toLowerCase()?.includes(searchValue.toLowerCase()))?.map((accessElement, index) => {
                    return (
                      <Row
                        gutter={AccessFormItemsLayout.rowGutter}
                        key={'application-access-' + index.toString()}
                      >
                        <Col
                          key={`application-name-${index}`}
                          xs={columnSpan.xs}
                          md={AccessFormItemsLayout.applicationColMdSpan}
                        >
                          {isViewMode
                            ? (
                              <div className='application-name-col'>
                                {formRef.getFieldValue([fieldName, accessElement.name, accessFieldType])}
                              </div>)
                            : (
                              <Form.Item
                                name={[accessElement.name, accessFieldType]}
                                rules={[{ required: true, message: `${accessType} is mandatory!` }]}
                              >
                                <FloatingLabel
                                  label=""
                                  type="select"
                                  id={accessFieldType}
                                  options={filterOptionListBySelectedValue(
                                    accessFieldType,
                                    accessOptionDataList,
                                    selectedApplication
                                  )}
                                  placeholder={`Select ${accessType}`}
                                  allowClear={allowClear}
                                  showSearch
                                />
                              </Form.Item>
                              )}
                        </Col>
                        <Col md={16} xxl={16}>
                          {isMultiSelect
                            ? (
                              <Form.Item
                                name={[index, 'accessList']}
                                rules={[
                                  { required: true, message: 'Atleast one access is Mandatory!' }
                                ]}
                              >
                                <ApplicationAccessCheckBoxGroup
                                  options={[
                                    ...userAccessRolesListForForm,
                                    ...(showAppAdminAccess ? AdminRoleListForForm : [])
                                  ]}
                                />
                              </Form.Item>
                              )
                            : (
                              <Form.Item
                                name={[index, 'accessName']}
                                rules={[
                                  { required: true, message: 'Access is mandatory!' }
                                ]}
                                className={isViewMode ? 'form-item-view-wrapper' : ''}
                              >
                                <ApplicationAccessRadioGroup
                                  options={[
                                    ...userAccessRolesListForForm,
                                    ...(showAppAdminAccess ? AdminRoleListForForm : [])
                                  ]}
                                  className={isViewMode ? 'radio-item-view' : ''}
                                />
                              </Form.Item>
                              )}
                        </Col>
                        {!isViewMode && (
                          <Col md={AccessFormItemsLayout.actionColSpan} xxl={AccessFormItemsLayout.actionColSpan} style={{ textAlign: 'center' }}>
                            <DeleteIconButton
                              data-testid={`accessDeleteBtn-${accessElement?.name}`}
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                remove(accessElement.name);
                              }}
                              {...(!isMultiSelect && { className: 'mb-10' })}
                              type="text"
                            />
                          </Col>
                        )}
                      </Row>
                    );
                  })}
                </>
              )}
              {fieldsItem?.length === 0 && (
                <ApplicationAccessNoApplicationWrapper>
                  {!isViewMode && (
                    <AddButton
                      btnText={accessType === 'application' ? textConstants.USER_ADD_APPLICATION_ACCESS_BTN_LABEL : textConstants.USER_ADD_GROUP_ACCESS_BTN_LABEL}
                      onClick={() => add()}
                      testId={'test-id-user-access-application-btn'}
                      disabled={!isMirrorRequestCompleted}
                    />
                  )}
                  {isViewMode && <NoDataAvailableWrapper>{textConstants.NO_APPLICATION_ACCESS_ITEM}</NoDataAvailableWrapper>}
                </ApplicationAccessNoApplicationWrapper>
              )}
              {isMirrorRequestCompleted === false && (
                <Loader
                  isOverlayLoader={false}
                  size='small'
                />
              )}
            </>
          );
        }}
      </Form.List>
    </ApplicationAccessMainWrapper>
  );
};

ApplicationAccess.defaultProps = {
  accessType: 'application',
  allowClear: true,
  showAppAdminAccess: false
}

ApplicationAccess.propTypes = {
  accessType: PropTypes.string,
  allowClear: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  hasAccessTitle: PropTypes.bool,
  hasMirrorAccess: PropTypes.bool,
  showAppAdminAccess: PropTypes.bool,
  isMirrorRequestCompleted: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  selectedApplication: PropTypes.any,
  isViewMode: PropTypes.bool.isRequired,
  onCopyUserAccessHandler: PropTypes.func
};

export default ApplicationAccess;
