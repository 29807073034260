import { convertTimeToTimeZoneForDisplay } from '../../../helpers/commonHelper';
const additionalColumnProp = {
  flex: 1
};
export const USER_LIST_COLUMNS = [
  {
    field: 'userID',
    headerName: 'User ID',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'email',
    headerName: 'Email Id',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'lastLogin',
    headerName: 'Last Login',
    sortable: true,
    cellRenderer: (params: any) => {
      return convertTimeToTimeZoneForDisplay(params?.value);
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    ...additionalColumnProp
  }
];
