import axios from 'axios';
import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from './setEndpointURL';

export let apiRequestController: any;

const customAxiosApi: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const apiRequest = async (data: any): Promise<any> => {
  apiRequestController = new AbortController();
  return await customAxiosApi.request({
    ...data,
    signal: apiRequestController.signal
  });
}

interface RetryConfig extends AxiosRequestConfig {
  retry: number
  retryDelay: number
}

const globalConfig: RetryConfig = {
  retry: 3,
  retryDelay: 500
};

const setTokenInAxiosHeader = (): string => {
  const localStore = localStorage.getItem('okta-token-storage') ? JSON.parse(localStorage.getItem('okta-token-storage') ?? '') : '';

  return localStore?.accessToken?.accessToken;
};

customAxiosApi.interceptors.request.use(
  (request) => {
    // get token from local storage and append it in req header.
    request.headers.Authorization = 'Bearer ' + setTokenInAxiosHeader();

    return request;
  },
  async (error) => {
    return await Promise.reject(error);
  }
);

customAxiosApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config } = error;
    console.log('error', error);
    toast.error(`${String(error.message)} (${String(error.response?.data?.errorMessage ?? error.response?.statusText)})`);
    if (!config?.retry) {
      return await Promise.reject(error);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, config.retryDelay || 1000);
    });
    return await delayRetryRequest.then(async () => await customAxiosApi(config));
  }
);
export { customAxiosApi, globalConfig };
