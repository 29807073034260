import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Modal } from 'antd';
import type { GetRowIdParams, RowDoubleClickedEvent } from 'ag-grid-community';
import ConfigurationListTable from '../../../ConfigurationListTable';
import { BLOB_METRICS_DETAIL_COLUMNS } from '../../../../../constants/TableColumns/Admin/BlobMetricsColumns';
import {
  getBlobMetricDetailLogData,
  getBlobMetricDetailExceptionData,
  resetLogExceptionDetailMetricData
} from '../../../../../redux/actions/Admin/blobMetricsActions';
import BlobMetricsCountBar from '../../../BlobMetrics/BlobMetricsCountBar';
import { filterTableData, getMaxDataSize, getMetricTypeCount } from '../../../../../helpers/blobMetricsHelper';
import BlobMetricSearchPanel from '../../../BlobMetrics/BlobMetricSearchPanel';
import { APIPostRequestHandler } from '../../../../../services/logExceptionServiceHandler';
import type { BlobMetricDetailFilterdList, BlobMetricDetailFilters } from '../../../../../constants/Interfaces/BlobMetricsTypes';
import LogExceptionDetailDrawer from '../../../Drawer/LogException/LogExceptionDetailDrawer';
import { applicationDataHelper } from '../../../../../helpers/applicationDataHelper';
import apiUrl from '../../../../../constants/apiEndpointConstants';
import textConstants from '../../../../../constants/textConstants';

const DetailTab: FC = () => {
  const dispatch = useDispatch();
  const blobDetailLogList = useSelector(
    (state: any) => state.blobMetrics.logDetailList
  );
  const blobDetailExceptionList = useSelector(
    (state: any) => state.blobMetrics.exceptionDetailList
  );
  const [modal, modalContext] = Modal.useModal();

  const [filteredData, setFilteredData] = useState<BlobMetricDetailFilterdList>([]);
  const [appliedFilters, setAppliedFilters] = useState<BlobMetricDetailFilters>({
    type: null
  });
  const [isDataLoading, setDataLoadingStatus] = useState<boolean>(false);
  const [isDetailDrawerOpen, setDetailDrawerOpen] = useState(false);
  const [metricItemDetails, setMetricItemDetails] = useState<Record<string, any> | null>(null);

  const onMetricTypeChange = (selectedValues: string[]): void => {
    const filteredData = filterTableData([...blobDetailLogList, ...blobDetailExceptionList], { type: selectedValues });
    setAppliedFilters({
      type: selectedValues
    });
    setFilteredData(filteredData);
  }

  const checkIsValidRetainDay = (date: string, metricType: string): boolean => {
    const today = moment();
    if (metricType === 'Exception' || moment(date).add(2, 'weeks').isSameOrAfter(today)) {
      return true;
    } else {
      return false;
    }
  }

  const getBlobMetricItemDetail = async (selectedItem: Record<string, any>): Promise<void> => {
    setDataLoadingStatus(true);

    if (checkIsValidRetainDay(selectedItem.date_text, selectedItem.type)) {
      const url = selectedItem.type === 'Log' ? apiUrl.GET_LOG_BLOB_METRIC_ITEM_DETAILS : apiUrl.GET_EXCEPTION_BLOB_METRIC_ITEM_DETAILS;
      const request = {
        application: selectedItem.application_nm,
        date: selectedItem.date_text,
        table: applicationDataHelper.getTableNameByApplication(selectedItem.application_nm, selectedItem.type),
        ...(selectedItem.log_id && { logID: selectedItem.log_id }),
        ...(selectedItem.exception_id && { exceptionID: selectedItem.exception_id })
      };

      const respData = await APIPostRequestHandler(request, url);
      if (respData) {
        setDetailDrawerOpen(true);
        setMetricItemDetails({
          ...respData,
          type: selectedItem.type
        });
      }
    } else {
      const config = {
        title: textConstants.BLOB_METRIC_ITEM_WARNING_DETAIL_TITLE,
        content: <>{textConstants.BLOB_METRIC_ITEM_DETAIL_WARNING}</>
      }
      modal.warning(config);
    }

    setDataLoadingStatus(false);
  }

  const onDetailDrawerClose = (): void => {
    setDetailDrawerOpen(false);
    setMetricItemDetails(null);
  };

  const onFormSubmit = (formData: any): void => {
    dispatch(resetLogExceptionDetailMetricData());
    dispatch(getBlobMetricDetailLogData(formData));
    dispatch(getBlobMetricDetailExceptionData(formData));
  };

  useEffect(() => {
    const filteredData = filterTableData([...blobDetailLogList, ...blobDetailExceptionList], appliedFilters)
    setFilteredData(filteredData)
  }, [blobDetailLogList, blobDetailExceptionList]);

  return (
    <>
      <BlobMetricSearchPanel
        onFilterChange={onMetricTypeChange}
        onSearchSubmitHandler={onFormSubmit}
        isMultiSelectAllowed={false}
        hasDatePicker
        hasSubmitBtn
      />
      <BlobMetricsCountBar
        data={{
          totalCount: filteredData?.length,
          logCount: getMetricTypeCount(filteredData, 'Log'),
          exceptionCount: getMetricTypeCount(filteredData, 'Exception'),
          maxDataSize: getMaxDataSize(filteredData)
        }}
      />
      <ConfigurationListTable
        columnDefs={BLOB_METRICS_DETAIL_COLUMNS}
        getRowId={(params: GetRowIdParams) => params.data.log_id ?? params.data.exception_id}
        getConfigList={null}
        isDataLoading={isDataLoading}
        listName=''
        onRowDoubleClicked={(params: RowDoubleClickedEvent) => {
          if (params.data) {
            void getBlobMetricItemDetail(params.data);
          }
        }}
        rowData={filteredData}
        filterBy={{
          fieldName: 'type',
          fieldLabel: 'Type'
        }}
        hasFilter={false}
        showResultsCount={false}
      />
      {isDetailDrawerOpen && (
        <LogExceptionDetailDrawer
          isDrawerOpen={isDetailDrawerOpen}
          logExceptionType={metricItemDetails?.type}
          selectedRows={[]}
          selectedItemDetails={metricItemDetails}
          selectedItemIndex={0}
          onClose={onDetailDrawerClose}
          updateItemDetailTransactionData={null}
          hasDownload
          isMetricDrawer
        />
      )}
      {modalContext}
    </>
  );
};

export default DetailTab;
