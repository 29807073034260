import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { LogDetailColumn } from '../../../constants/DetailViewColumns/LogDetailColumns';
import { BasicInfoLabel, BasicInfoValue } from './style';
import { ExceptionDetailColumn } from '../../../constants/DetailViewColumns/ExceptionDetailColumns';
import { EXCEPTION_STATUS_KEY, TIMEMARK_KEY } from '../../../constants/KeyLabels/commonKeyConstants';
import { convertTimeToTimeZoneForDisplay } from '../../../helpers/commonHelper';

interface componentProps {
  data?: any
  logExceptionType: string
}

const LogExceptionDetailsBasicInfo: FC<componentProps> = ({ data, logExceptionType }) => {
  const [columnInExpandedForm, setColumnInExpandedForm] = useState<any>();
  return <>
    <Row gutter={[8, 8]}>
      {
        data &&
        (logExceptionType?.toString().toLowerCase() === 'log' ? [...LogDetailColumn] : [...ExceptionDetailColumn]).map(colItem => {
          return (
            <Col xs={24} sm={12} md={8} lg={8} xxl={6} key={`${logExceptionType}-${colItem.key}`}>
              <Row>
                <Col span={10}>
                  <BasicInfoLabel>{colItem.label}</BasicInfoLabel>
                </Col>
                <Col span={1}>:</Col>
                <Col span={11}
                  className={'text-wrap-break-word ' + (columnInExpandedForm === colItem.label ? '' : 'text-wrap-ellipsis')}
                  onClick={() => { setColumnInExpandedForm(colItem.label) }}>
                  <BasicInfoValue
                    className={colItem.key === EXCEPTION_STATUS_KEY ? `${String(data[colItem.key])?.toLowerCase()}-btn status-btn` : ''}>
                    {colItem.key === TIMEMARK_KEY &&
                      <>
                        {data[colItem.key]
                          ? convertTimeToTimeZoneForDisplay(data[colItem.key])
                          : 'N/A'
                        }
                      </>}
                    {colItem.key !== TIMEMARK_KEY &&
                      <>
                        {data[colItem.key]
                          ? data[colItem.key]
                          : 'N/A'}
                      </>
                    }
                  </BasicInfoValue>
                </Col>
              </Row>
            </Col>)
        })
      }
    </Row>
  </>;
};
LogExceptionDetailsBasicInfo.defaultProps = {
  logExceptionType: 'log'
}
LogExceptionDetailsBasicInfo.propTypes = {
  data: PropTypes.any,
  logExceptionType: PropTypes.string.isRequired
};

export default LogExceptionDetailsBasicInfo;
