/**
 * Contains all available route constants
 */

const routeConstants = {
  DASHBOARD: '/dashboard',
  LOG_SEARCH: '/log/search-logs',
  LOG_SEARCH_RESULT: '/log/search-results',
  LOG_SAVED_SEARCH: '/log/saved-search',
  EXCEPTION_SAVED_SEARCH: '/exception/saved-search',
  EXCEPTION_SEARCH: '/exception/search-exceptions',
  EXCEPTION_SEARCH_RESULT: '/exception/search-results',
  EXCEPTION_FILE_REPLAY: '/file-replay',
  EXCEPTION_FILE_REPLAY_DETAIL: '/file-replay/detail',
  ADMIN_APPLICATION_CONFIG: '/admin/application-management',
  ADMIN_APPLICATION_NEW_CONFIG: '/admin/application-management/new-application',
  ADMIN_APPLICATION_DETAIL: '/admin/application-management/application-detail',
  ADMIN_EXCEPTION_CONFIG: '/admin/exception-config',
  ADMIN_EXCEPTION_CONFIG_DETAIL: '/admin/exception-config/detail',
  ADMIN_REPLAY_CONFIG: '/admin/replay-config',
  ADMIN_SCHEDULE_CONFIG: '/admin/schedule-config',
  ADMIN_SCHEDULE_CONFIG_DETAIL: '/admin/schedule-config/detail',
  ADMIN_REPLAY_CONFIG_DETAIL: '/admin/replay-config/detail',
  ADMIN_TABLE_CONFIG: '/admin/table-config',
  ADMIN_TABLE_CONFIG_DETAIL: '/admin/table-config/detail',

  ADMIN_COLUMN_ALERTS: '/admin/column-alert-config',
  ADMIN_COST_ANALYSIS: '/admin/cost-analysis',
  ADMIN_COLUMN_ALERTS_DETAIL: '/admin/column-alert-config/detail',
  ADMIN_SOLMAN_STAGING: '/admin/solman-staging',
  ADMIN_SOLMAN_CONFIG_DETAIL: '/admin/solman-staging/detail',
  ADMIN_BLOB_METRICS: '/admin/blob-metrics',
  ADMIN_LOG_EXCEPTION_VOLUME_METRICS: '/admin/volume-metrics',

  // Report config
  ADMIN_REPORT_CONFIG: '/admin/report-config',
  ADMIN_REPORT_CONFIG_DETAIL: '/admin/report-config/detail',
  ADMIN_LOG_REPORT_CONFIG_DETAIL: '/admin/report-config/log-report-detail',
  ADMIN_EXCEPTION_REPORT_CONFIG_DETAIL: '/admin/report-config/exception-report-detail',

  // User access
  ADMIN_USER_ACCESS: '/admin/users',
  ADMIN_USER_ACCESS_DETAIL: '/admin/user/detail',

  // Group Access
  ADMIN_GROUP_ACCESS: '/admin/user-group',
  ADMIN_GROUP_ACCESS_DETAIL: '/admin/user-group/detail',

  // end of user access routes

  USER_APPLICATION_ACCESS: '/admin/applicationgroup',
  OKTA_AUTHENTICATE: '/',
  LOGIN_CALLBACK: '/callback',
  UNAUTHORIZED: '/unauthorized'
};

export default routeConstants;
