import { getSnowServiceClassName } from '../../../helpers/commonHelper';
import { checkBoxFieldCol } from '../tableCheckboxColumn';

const cellStyles = {
  textAlign: 'center'
};
const additionalColumnProp = {
  minWidth: 150,
  flex: 1,
  valueFormatter: (params: { value: any }) => {
    return params?.value
      ? params?.value?.toString().trim().length === 0
        ? '-'
        : params.value
      : '-';
  }
};
export const EXCEPTION_CONFIG_COLUMNS = [
  checkBoxFieldCol,
  {
    field: 'applicationName',
    headerName: 'Application',
    sortable: true,
    ...additionalColumnProp,
    minWidth: 200
  },
  {
    field: 'applicationDomain',
    headerName: 'Application Domain',
    sortable: true,
    ...additionalColumnProp,
    minWidth: 200
  },
  {
    field: 'exceptionCategoryName',
    headerName: 'Category',
    sortable: true,
    ...additionalColumnProp,
    minWidth: 200
  },
  {
    field: 'exceptionTypeName',
    headerName: 'Type',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'exceptionSeverityName',
    headerName: 'Severity',
    sortable: true,
    maxWidth: 125
  },
  {
    field: 'replayType',
    headerName: 'Replay Type',
    sortable: true,
    maxWidth: 140
  },
  {
    field: 'maxAutoReplay',
    headerName: 'Max Auto Replay',
    sortable: true,
    maxWidth: 170
  },
  {
    field: 'description',
    headerName: 'Description',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'replyDestination',
    headerName: 'Reply Destination',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'replyMode',
    headerName: 'Reply Mode',
    sortable: true
  },
  {
    field: 'emailAddress',
    headerName: 'Mail Notification',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'enableMailNotification',
    headerName: 'Enable Mail Notification',
    sortable: true,
    cellStyle: cellStyles,
    cellRenderer: function (params: any) {
      return params.value === 0 ? 'N' : 'Y';
    }
  },
  {
    field: 'enableSnowNotification',
    headerName: 'Enable Snow Notification',
    sortable: true,
    cellStyle: cellStyles,
    cellRenderer: function (params: any) {
      return params.value === 0 ? 'N' : 'Y';
    }
  },
  {
    field: 'snowAssignmentGroup',
    headerName: 'Assignment Group',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'autoReplayInterval',
    headerName: 'Interval',
    sortable: true,
    width: 120
  },
  {
    field: 'enableGroupNotification',
    headerName: 'Enable Group Notification',
    cellStyle: cellStyles,
    sortable: true,
    cellRenderer: function (params: any) {
      return params.value === 0 ? 'N' : 'Y';
    }
  },
  {
    field: 'sysClassName',
    headerName: 'Snow Service Class',
    sortable: true,
    ...additionalColumnProp,
    cellRenderer: function (params: any) {
      return getSnowServiceClassName(params.value);
    }
  },
  {
    field: 'snowParam',
    headerName: 'Snow Service Value',
    sortable: true,
    ...additionalColumnProp
  }
];
