import React from 'react';
import PropTypes from 'prop-types';
import { AdditionalInfoWrapper, Footer, FooterBtnWrapper } from './Footer.style';
import {
  CancelButton,
  ReplayButton,
  SaveButton,
  ViewDetailButton
} from './Buttons.style';
interface FooterInterface {
  onSave?: any
  save?: boolean
  hasReplay?: boolean
  isSaveDisabled?: boolean
  search?: boolean
  onSearch?: any
  isSearchDisabled?: boolean
  onExport?: any
  exportbtn?: boolean
  isExportDisabled?: boolean
  onReset?: any
  reset?: boolean
  add?: boolean
  onAdd?: any
  modify?: boolean
  onModify?: any
  isModifyDisabled?: boolean
  deleteBtn?: boolean
  isDeleteDisabled?: boolean
  onDelete?: any
  detail?: boolean
  isDetailDisabled?: boolean
  onDetail?: any
  upload?: boolean
  onUpload?: any
  cancel?: boolean
  onCancel?: any
  saveWithClose?: boolean
  onSaveClose?: any
  viewDetails?: boolean
  onViewDetails?: any
  additionalInfo?: string
  onReplay?: any
  className?: string
}

const FooterComponent: React.FC<FooterInterface> = ({
  save,
  onSave,
  saveWithClose,
  onSaveClose,
  isSaveDisabled,
  search,
  onSearch,
  hasReplay,
  onReplay,
  isSearchDisabled,
  onExport,
  exportbtn,
  isExportDisabled,
  reset,
  onReset,
  add,
  onAdd,
  modify,
  onModify,
  isModifyDisabled,
  onDelete,
  isDeleteDisabled,
  deleteBtn,
  detail,
  onDetail,
  isDetailDisabled,
  upload,
  onUpload,
  cancel,
  onCancel,
  viewDetails,
  onViewDetails,
  additionalInfo,
  className
}) => {
  return (
    <Footer className={additionalInfo ? 'hasAdditionalInfo' : className ?? ''}
      data-testid="testid-log-footer"
    >
      {
        additionalInfo &&
        <AdditionalInfoWrapper>{additionalInfo}</AdditionalInfoWrapper>
      }
      <div />
      <FooterBtnWrapper>
        {
          cancel &&
          <>
            <CancelButton type="text" className="" onClick={onCancel}>
              Cancel
            </CancelButton>
          </>
        }
        {
          viewDetails &&
          <>
            <ViewDetailButton type="primary" onClick={onViewDetails} data-testid="testid-view-detail-btn">
              View Details
            </ViewDetailButton>
          </>
        }
        {
          hasReplay &&
          <ReplayButton onClick={onReplay} data-testid="replayBtn">
            Replay
          </ReplayButton>
        }
        {reset && (
          <CancelButton type="text" onClick={onReset}>
            Reset
          </CancelButton>
        )}
        {save && (
          <SaveButton type="primary" disabled={isSaveDisabled} onClick={onSave}>
            Save
          </SaveButton>
        )}
        {saveWithClose && (
          <SaveButton data-testid='saveCloseBtn' disabled={isSaveDisabled} onClick={onSaveClose} isSecondary>
            Save & Close
          </SaveButton>
        )}
        {/* {search && (
          <DefaultWithBackgroundButton onClick={onSearch} disabled={isSearchDisabled}>
            <CustomIcon iconType={'searchIcon'} />
            Search
          </DefaultWithBackgroundButton>
        )}
        {exportbtn && (
          <DefaultButton onClick={onExport} disabled={isExportDisabled}>
            <CustomIcon iconType={'exportIcon'} />
            Export
          </DefaultButton>
        )}
        {add && (
          <DefaultButton onClick={onAdd}>
            <CustomIcon iconType={'PlusCircleIcon'} />
            Add
          </DefaultButton>
        )}
        {modify && (
          <DefaultButton onClick={onModify} disabled={isModifyDisabled}>
            <CustomIcon iconType={'PlusCircleIcon'} />
            Modify
          </DefaultButton>
        )}
        {deleteBtn && (
          <Button onClick={onDelete} disabled={isDeleteDisabled} danger>
            <CustomIcon iconType={'deleteIcon'} />
            Delete
          </Button>
        )}
        {detail && (
          <DefaultButton onClick={onDetail} disabled={isDetailDisabled}>
            <CustomIcon iconType={'detailIcon'} />
            Detail
          </DefaultButton>
        )}
        {upload && (
          <DefaultButton onClick={onUpload}>
            <CustomIcon iconType={'uploadIcon'} />
            Upload
          </DefaultButton>
        )} */}
      </FooterBtnWrapper>
    </Footer>
  );
};

FooterComponent.propTypes = {
  save: PropTypes.bool,
  isSaveDisabled: PropTypes.bool,
  saveWithClose: PropTypes.bool,
  onSaveClose: PropTypes.func,
  onSave: PropTypes.func,
  search: PropTypes.bool,
  onSearch: PropTypes.func,
  isSearchDisabled: PropTypes.bool,
  onExport: PropTypes.func,
  exportbtn: PropTypes.bool,
  isExportDisabled: PropTypes.bool,
  reset: PropTypes.bool,
  onReset: PropTypes.func,
  add: PropTypes.bool,
  onAdd: PropTypes.func,
  modify: PropTypes.bool,
  onModify: PropTypes.func,
  isModifyDisabled: PropTypes.bool,
  deleteBtn: PropTypes.bool,
  onDelete: PropTypes.func,
  isDeleteDisabled: PropTypes.bool,
  detail: PropTypes.bool,
  onDetail: PropTypes.func,
  isDetailDisabled: PropTypes.bool,
  upload: PropTypes.bool,
  onUpload: PropTypes.func,
  className: PropTypes.string
};

export default FooterComponent;
