import type {
  DeleteConfigDataActionType,
  GetConfigDetailsActionType,
  SetConfigDetailsActionType
} from '../../../constants/Interfaces/AdminConfigActionTypes';
import {
  DELETE_EXCEPTION_CONFIG,
  GET_EXCEPTION_CONFIG_LIST,
  SET_EXCEPTION_CONFIG_LIST,
  SAVE_EXCEPTION_CONFIG,
  UPLOAD_EXCEPTION_CONFIG
} from '../ActionTypes/Admin/exceptionConfigTypes';

interface UploadConfigAction {
  type: string
  file: Blob
}

interface SaveConfigDetailAction {
  type: string
  payload: {
    data: any[]
    callBackFunc?: any
    isAdd?: boolean
  }
}

export function getExceptionConfigList (applicationName?: string): GetConfigDetailsActionType {
  return {
    type: GET_EXCEPTION_CONFIG_LIST,
    applicationName
  };
}

export function setExceptionConfigList (data: any): SetConfigDetailsActionType {
  return {
    type: SET_EXCEPTION_CONFIG_LIST,
    data
  };
}

export function saveExceptionConfig (data: any, callBackFunc?: any, isAdd?: boolean): SaveConfigDetailAction {
  return {
    type: SAVE_EXCEPTION_CONFIG,
    payload: {
      data,
      callBackFunc,
      isAdd
    }
  };
}

export function deleteExceptionConfig (data: any, callBackFunc?: any): DeleteConfigDataActionType {
  return {
    type: DELETE_EXCEPTION_CONFIG,
    payload: {
      data,
      callBackFunc
    }
  };
}

export function uploadExceptionConfig (file: Blob): UploadConfigAction {
  return {
    type: UPLOAD_EXCEPTION_CONFIG,
    file
  };
}
