import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';
import {
  ArcElement,
  Chart as ChartJS,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { pieChartConfig } from './pieChartConfig';

ChartJS.register(
  ArcElement,
  ChartDataLabels,
  Tooltip,
  Legend,
  Filler
);

interface PieChartProps {
  data: any
  emptyDataMessage?: string
}

const PieChart: FC<PieChartProps> = ({ data, emptyDataMessage }) => {
  if (data?.labels.length < 1) {
    return <Empty description={emptyDataMessage} />
  }

  return (
    <Pie
      data-testid="pieChart"
      data={data}
      options={pieChartConfig}
    />
  );
};

PieChart.propTypes = {
  data: PropTypes.any,
  emptyDataMessage: PropTypes.string
};

export default PieChart;
