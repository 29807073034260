export const pieChartConfig = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: 'value',
      opacity: 1,
      font: {
        size: 12
      },
      rotation: 60,
      color: 'white'
    },
    legend: {
      labels: {
        font: {
          size: 12
        }
      }
    }
  },
  tooltips: {
    yAlign: 'top',
    xAlign: 'left'
  }
};
