export const EXCEPTION_CATEGORY_COLUMN_CONFIG = [
  {
    field: 'exceptionCategoryName',
    headerName: 'Category',
    sortable: true,
    flex: 1
  },
  {
    field: 'exceptionCategoryDescription',
    headerName: 'Description',
    flex: 1
  }
];
export const EXCEPTION_SEVERITIES_COLUMN_CONFIG = [
  {
    field: 'exceptionSeverityName',
    headerName: 'Severity',
    sortable: true,
    flex: 1
  },
  {
    field: 'exceptionSeverityDescription',
    headerName: 'Description',
    flex: 1
  }
];
export const EXCEPTION_TYPES_COLUMN_CONFIG = [
  {
    field: 'exceptionTypeName',
    headerName: 'Types',
    sortable: true,
    flex: 1
  },
  {
    field: 'exceptionTypeDescription',
    headerName: 'Description',
    flex: 1
  }
];
