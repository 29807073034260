import React from 'react';
import type { FC } from 'react';
import { AdminMainLayoutWrapper, AdminMainBreadCrumbWrapper } from '../../../assets/styles/commonStyled';
import { COLUMN_ALERT_MGMNT_TEXT } from '../../../constants/breadCrumbTextConstants';
import ColumnAlertConfigList from '../../../components/AdditionalComponents/DetailSummaryPage/Admin/ColumnAlertConfig/ColumnAlertConfigList';

const ColumnAlertConfig: FC = () => {
  return (
    <AdminMainLayoutWrapper data-testid="colAlertConfigList">
      <AdminMainBreadCrumbWrapper>
        {COLUMN_ALERT_MGMNT_TEXT}
      </AdminMainBreadCrumbWrapper>
      <ColumnAlertConfigList />
    </AdminMainLayoutWrapper>
  );
};

export default ColumnAlertConfig;
