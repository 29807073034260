import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import textConstants from '../../../constants/textConstants';
import { TagInputWrapper } from './style';

interface TagInputProps {
  allowClear?: boolean
  id?: string
  options: any[]
  mode?: 'multiple' | 'tags' | undefined
  noDataFoundMessage?: string
  separator?: string
  value: any
}

const TagInput: FC<TagInputProps> = ({
  allowClear,
  id,
  mode,
  noDataFoundMessage,
  options,
  separator,
  value,
  ...otherProps
}) => {
  return (
    <TagInputWrapper
      dropdownRender={(menu) => <div className={`${mode !== 'multiple' ? 'tag-dropdown-menu' : ''}`}>{menu}</div>}
      allowClear={allowClear}
      id={id}
      mode={mode ?? 'tags'}
      options={options}
      style={{ width: '100%' }}
      tokenSeparators={[separator ?? ',']}
      notFoundContent={noDataFoundMessage}
      value={value}
      {...(mode !== 'multiple' && { showArrow: false })}
      {...otherProps}
    />
  );
};

TagInput.defaultProps = {
  noDataFoundMessage: textConstants.NO_SUGGESTION_AVAILABLE
};

TagInput.propTypes = {
  allowClear: PropTypes.bool,
  id: PropTypes.string,
  mode: PropTypes.oneOf(['tags', 'multiple']),
  noDataFoundMessage: PropTypes.string,
  options: PropTypes.array.isRequired,
  separator: PropTypes.string,
  value: PropTypes.any.isRequired
};

export default TagInput;
