import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { getUserList } from '../../../../../redux/actions/AccessManagement/UserAccess/userAccessListActions';
import ConfigurationListTable from '../../../ConfigurationListTable';
import { GROUP_USER_LIST_COLUMNS } from '../../../../../constants/TableColumns/AccessManagement/GroupUserListColumns';
import { AddButton } from '../../../../common/Buttons/buttons';
import { ApplicationAccessNoApplicationWrapper } from '../../../../common/AccessComponent/style';
import { NoDataAvailableWrapper, SlideDownWrapper } from '../../../../../assets/styles/commonStyled';
import { checkBoxFieldCol } from '../../../../../constants/TableColumns/tableCheckboxColumn';
import textConstants from '../../../../../constants/textConstants';

interface UserListTabProps {
  isEditable?: boolean
  userIdList: string[]
  onUserDelete: any
  onUserAdd: any
}

const UserListTab: FC<UserListTabProps> = ({ isEditable, onUserAdd, onUserDelete, userIdList }) => {
  const [userAddView, setUserAddView] = useState(false);

  const toggleUserAddList = (): void => {
    setUserAddView(!userAddView);
  }

  const handleUserAdd = (data: any): void => {
    const userIdArr = data.map((item: any) => item.userID);

    setUserAddView(false);
    onUserAdd(userIdArr)
  }

  const handleUserDelete = (data: any): void => {
    const ignoreUserIdList = data.map((item: any) => item.userID);
    onUserDelete(ignoreUserIdList);
  }

  useEffect(() => {
    if (!isEditable) {
      setUserAddView(false);
    }
  }, [isEditable])

  if (userAddView) {
    return (
      <SlideDownWrapper>
        <ConfigurationListTable
          createBtnClass='btn-secondary'
          columnDefs={[checkBoxFieldCol, ...GROUP_USER_LIST_COLUMNS]}
          dataFilterFieldConfig={{
            data: userIdList,
            name: 'userID',
            isExist: false
          }}
          getConfigList={getUserList}
          onCreate={handleUserAdd}
          gridName={textConstants.GROUP_USER_LIST_TITLE}
          onClose={toggleUserAddList}
          gridRowId='userID'
          listName='userConfig'
          allowSearchFilterCache={false}
          hasEdit={false}
          hasFilter={false}
          rowSelection='multiple'
          rowMultiSelectWithClick
          deleteBtnLabel='Remove Users'
          hasClose
          hasCustomAdd
          hasCreate
          hasDefaultSearch
        />
      </SlideDownWrapper>
    )
  }

  if (userIdList?.length > 0) {
    return (
      <ConfigurationListTable
        allowSearchFilterCache={false}
        columnDefs={isEditable ? [checkBoxFieldCol, ...GROUP_USER_LIST_COLUMNS] : GROUP_USER_LIST_COLUMNS}
        dataFilterFieldConfig={{
          data: userIdList,
          name: 'userID',
          isExist: true
        }}
        getConfigList={getUserList}
        onCreate={toggleUserAddList}
        onConfigDelete={handleUserDelete}
        gridName={textConstants.GROUP_USER_LIST_TITLE}
        gridRowId='userID'
        listName='userConfig'
        hasEdit={false}
        hasFilter={false}
        hasHeaderDelete={isEditable}
        hasCreate={isEditable}
        rowSelection='multiple'
        rowMultiSelectWithClick
        creatBtnLabel='Add Users'
        deleteBtnLabel='Remove Users'
        hasCustomDelete
        hasDefaultSearch
      />
    );
  }

  return (
    <ApplicationAccessNoApplicationWrapper>
      {isEditable
        ? (
          <AddButton
            btnText='Add New Users'
            onClick={toggleUserAddList}
            testId='add-user-btn'
          />
          )
        : (
          <NoDataAvailableWrapper>
            {textConstants.GROUP_WITHOUT_USERS_LABEL}
          </NoDataAvailableWrapper>
          )
      }
    </ApplicationAccessNoApplicationWrapper>
  )
}

export default UserListTab;
