import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ConfigDetailCardHeader from '../../../../components/AdditionalComponents/ConfigDetailCardHeader';
import UserAccessForm from '../../../../components/AdditionalComponents/UpsertForms/AccessManagement/UserAccess';
import UserAccessSummary from '../../../../components/AdditionalComponents/DetailSummaryPage/AccessManagement/UserAccess/UserAccessSummary';
import { AdminMainLayoutWrapper } from '../../../../assets/styles/commonStyled';
import { ConfigDetailMainWrapper, ConfigDetailWrapper } from '../../../../components/AdditionalComponents/DetailSummaryPage/Admin/style';
import AdminDetailBreadCrumb from '../../../../components/common/BreadCrumb/AdminDetailBreadCrumb';
import textConstants from '../../../../constants/textConstants';
import { saveUserList, deleteUserList } from '../../../../redux/actions/AccessManagement/UserAccess/userAccessListActions';
import { checkIfExistsInArray } from '../../../../helpers/commonHelper';
import { errorNotification } from '../../../../components/common/Notification';

interface UserAccessDetailProps {
  userData?: any
  isProfileView?: boolean
}

const UserAccessDetail: FC<UserAccessDetailProps> = ({ userData, isProfileView }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const userConfigList = useSelector((state: any) => state.userConfig.userConfigList);
  const { data, isAdd, isEditable } = location?.state as { isAdd?: boolean, isEditable?: boolean, data: any } ?? {};

  const [isConfigDetailInEditMode, setConfigDetailInEditMode] = useState(isProfileView ? false : isEditable);
  const [configData, setConfigData] = useState(userData ?? data);

  const onFormSaveCancelHandler = (): void => {
    if (isEditable) {
      history.goBack();
    } else {
      setConfigDetailInEditMode(false);
    }
  }

  const onSaveHandler = (data: any, isClosable?: boolean): void => {
    if (isAdd) {
      if (!checkIfExistsInArray(userConfigList, data, 'userID')) {
        dispatch(saveUserList(data, isClosable ? onFormSaveCancelHandler : null, true));
      } else {
        errorNotification(textConstants.USER_LIST_ALREADY_EXIST_MESSAGE);
      }
    } else {
      dispatch(saveUserList(data, onFormSaveCancelHandler));
      setConfigData(data);
    }
  }

  const onDeleteHandler = (): void => {
    dispatch(deleteUserList([data], history.goBack));
  }

  return (
    <AdminMainLayoutWrapper data-testid="userAccessDetail">
      {!isProfileView && (
        <AdminDetailBreadCrumb
          mainPageText={textConstants.USER_LIST_PAGE_TITLE}
          currentPageText={isAdd ? textConstants.USER_DETAIL_CREATE_USER_TITLE : data?.userID}
        />
      )}
      <ConfigDetailMainWrapper hasBottomGap={isConfigDetailInEditMode}>
        <ConfigDetailWrapper className='config-detail-wrapper'>
          {!isProfileView && (
            <ConfigDetailCardHeader
              isDeleteEnabled={!isProfileView && !isAdd}
              onDelete={onDeleteHandler}
              onEdit={() => setConfigDetailInEditMode(true)}
              isEditEnabled={!isProfileView && !isAdd && !isConfigDetailInEditMode}
              title={textConstants.USER_DETAIL_PAGE_TITLE}
              configName={textConstants.USER_GRID_TITLE}
              {...(isConfigDetailInEditMode && { hasRightGap: true })}
            />
          )}
          {isConfigDetailInEditMode && (
            <UserAccessForm
              data={configData}
              onSave={onSaveHandler}
              onCancel={onFormSaveCancelHandler}
              isEditable={!isAdd}
            />
          )}
          {!isConfigDetailInEditMode && (
            <UserAccessSummary
              data={configData}
            />
          )}
        </ConfigDetailWrapper>
      </ConfigDetailMainWrapper>
    </AdminMainLayoutWrapper>
  );
};

UserAccessDetail.defaultProps = {
  isProfileView: false
};

UserAccessDetail.propTypes = {
  isProfileView: PropTypes.bool,
  userData: PropTypes.any
};

export default UserAccessDetail;
