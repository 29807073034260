const cellStyles = {
  textAlign: 'center'
};

export const APPLICATION_CONFIG_COLUMNS = [
  {
    field: 'applicationName',
    headerName: 'Application Name',
    sortable: true
  },
  {
    field: 'appDescription',
    headerName: 'Description',
    sortable: true
  },
  {
    field: 'appOwner',
    headerName: 'App Owner',
    sortable: true,
    valueFormatter: (params: { value: any }) => {
      return params?.value?.toString().length === 0 ? '-' : params.value
    }
  },
  {
    field: 'teamDl',
    headerName: 'Team DL',
    sortable: true
  },
  {
    field: 'snowCI',
    headerName: 'Snow CI',
    sortable: true
  },
  {
    field: 'assignmentGroup',
    headerName: 'Assignment Group',
    sortable: true
  },
  {
    field: 'logRetain',
    headerName: 'Log Retain',
    cellStyle: cellStyles,
    sortable: true
  },
  {
    field: 'exceptionRetain',
    headerName: 'Exception Retain',
    cellStyle: cellStyles,
    sortable: true
  },
  {
    field: 'logTopic',
    headerName: 'Log Topic',
    sortable: true
  },
  {
    field: 'exceptionTopic',
    headerName: 'Exception Topic',
    sortable: true
  },
  {
    field: 'logTable',
    headerName: 'Log Table',
    sortable: true
  },
  {
    field: 'exceptionTable',
    headerName: 'Exception Table',
    sortable: true
  },
  {
    field: 'appSector',
    headerName: 'Sector',
    sortable: true
  }
];
