import React from 'react';
import PropTypes from 'prop-types';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';

interface FileUploadButtonProps {
  className?: string
  showFileList?: boolean
  label?: string
  onUpload: any
}

const FileUploadButton: React.FC<FileUploadButtonProps> = ({
  className,
  label,
  onUpload,
  showFileList,
  ...otherProps
}) => {
  return (
    <Upload customRequest={onUpload} showUploadList={showFileList} {...otherProps}>
      <Button className={className} type="text" icon={<DownloadOutlined />}>
        {label}
      </Button>
    </Upload>
  );
};

FileUploadButton.defaultProps = {
  label: 'Import',
  showFileList: false
};

FileUploadButton.propTypes = {
  className: PropTypes.string,
  showFileList: PropTypes.bool,
  onUpload: PropTypes.func,
  label: PropTypes.string
};

export default FileUploadButton;
