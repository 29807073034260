import { USER_ID_KEY } from '../constants/KeyLabels/commonKeyConstants';
import apiEndpointConstants from '../constants/apiEndpointConstants';
import { commonApiRequestHandler } from '../services/CommonServiceHandler';
import { getLoggedInUserData } from './commonHelper';

export const saveTransactionServiceHandler = async (
  recordData: any,
  updatedData: any
): Promise<any> => {
  const apiRequestConfig = {
    method: 'PUT',
    data: {
      application: recordData.application_nm,
      dataEncoding: recordData.data_encoding,
      exceptionID: recordData.exception_id,
      timemark: recordData.timemark,
      table: recordData.table,
      transactionData: updatedData,
      jmsHeader: recordData.jmsHeader,
      user: getLoggedInUserData(USER_ID_KEY)
    },
    url: apiEndpointConstants.EXCEPTION_DATA
  };

  const apiResponse = await commonApiRequestHandler(apiRequestConfig);
  return apiResponse.status;
};
