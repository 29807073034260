import React from 'react';
import type { FC } from 'react';
import ChargebackCostAnalysisForm from '../../../components/AdditionalComponents/UpsertForms/Admin/ChargebackCostAnalysis';
import textConstants from '../../../constants/textConstants';
import { AdminMainLayoutWrapper, AdminMainBreadCrumbWrapper } from '../../../assets/styles/commonStyled';
import { ConfigDetailWrapper } from '../../../components/AdditionalComponents/DetailSummaryPage/Admin/style';
import ConfigDetailCardHeader from '../../../components/AdditionalComponents/ConfigDetailCardHeader';

const ChargebackCostAnalysis: FC = () => {
  return (
    <AdminMainLayoutWrapper data-testid="chargebackCostAnalysis">
      <AdminMainBreadCrumbWrapper>
        {textConstants.CHARGEBACK_COST_ANALYSIS_TITLE}
      </AdminMainBreadCrumbWrapper>
      <ConfigDetailWrapper>
        <ConfigDetailCardHeader
          isDeleteEnabled={false}
          isEditEnabled={false}
          title={textConstants.CHARGEBACK_COST_ANALYSIS_TITLE}
        />
        <ChargebackCostAnalysisForm />
      </ConfigDetailWrapper>
    </AdminMainLayoutWrapper>
  );
};

export default ChargebackCostAnalysis;
