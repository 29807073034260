import ReplayStatusTagRenderer from './customRendererForColumns/ReplayStatusTagRenderer';
import { convertTimeToTimeZoneForDisplay } from '../../helpers/commonHelper';

const additionalCommonFieldProperties = {
  minWidth: 130,
  sortable: true
};

export const fileReplayDetailColumns: any[] = [
  {
    field: 'exception_id',
    headerName: 'Exception Id',
    flex: 1,
    ...additionalCommonFieldProperties
  },
  {
    field: 'application_nm',
    headerName: 'Application',
    flex: 1,
    ...additionalCommonFieldProperties
  },
  {
    field: 'replay_status',
    headerName: 'Status',
    flex: 1,
    cellRenderer: ReplayStatusTagRenderer,
    ...additionalCommonFieldProperties
  },
  {
    field: 'last_update_on',
    headerName: 'Last Update',
    flex: 1,
    cellRenderer: (params: any) => {
      return convertTimeToTimeZoneForDisplay(params?.value);
    },
    ...additionalCommonFieldProperties
  }
];
