import React from 'react';
import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import AdminDetailBreadCrumb from '../../../../components/common/BreadCrumb/AdminDetailBreadCrumb';
import ScheduleConfigSummary from '../../../../components/AdditionalComponents/DetailSummaryPage/Admin/ScheduleConfig/ScheduleConfigSummary';
import ConfigDetailCardHeader from '../../../../components/AdditionalComponents/ConfigDetailCardHeader';
import textConstants from '../../../../constants/textConstants';
import { ConfigDetailWrapper } from '../../../../components/AdditionalComponents/DetailSummaryPage/Admin/style';
import { AdminMainLayoutWrapper } from '../../../../assets/styles/commonStyled';

const ScheduleConfigDetail: FC = () => {
  const location = useLocation();
  const { data } = location?.state as { data: any } ?? {};

  return (
    <AdminMainLayoutWrapper data-testid="scheduleConfigDetail">
      <AdminDetailBreadCrumb
        mainPageText={textConstants.SCHEDULE_CONFIG_PAGE_TITLE}
        currentPageText={data?.applicationName}
      />
      <ConfigDetailWrapper>
        <ConfigDetailCardHeader
          isDeleteEnabled={false}
          title={textConstants.SCHEDULE_CONFIG_DETAIL_PAGE_TITLE}
          configName={textConstants.SCHEDULE_CONFIG_PAGE_TITLE} />
        <ScheduleConfigSummary
          data={data} />
      </ConfigDetailWrapper>
    </AdminMainLayoutWrapper>
  );
}

export default ScheduleConfigDetail;
