import { convertTimeToTimeZoneForDisplay } from '../../helpers/commonHelper';
import ReplayStatusTagRenderer from './customRendererForColumns/ReplayStatusTagRenderer';
import ReplayDetailLinkRenderer from './customRendererForColumns/ReplayDetailLinkRenderer';

const additionalCommonFieldProperties = {
  sortable: true,
  minWidth: 130
};

export const fileReplayDashboardTableColumns: any[] = [
  {
    field: 'replayID',
    headerName: 'Replay Id',
    cellRenderer: ReplayDetailLinkRenderer,
    ...additionalCommonFieldProperties,
    minWidth: 300
  },
  {
    field: 'fileName',
    headerName: 'File Name',
    flex: 1,
    ...additionalCommonFieldProperties
  },
  {
    field: 'status',
    headerName: 'Status',
    cellRenderer: ReplayStatusTagRenderer,
    flex: 1,
    ...additionalCommonFieldProperties
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    flex: 1,
    ...additionalCommonFieldProperties
  },
  {
    field: 'createdOn',
    headerName: 'Created On',
    flex: 1,
    cellRenderer: (params: any) => {
      return convertTimeToTimeZoneForDisplay(params?.value);
    },
    ...additionalCommonFieldProperties
  },
  {
    field: 'lastUpdatedDate',
    headerName: 'Last Update',
    flex: 1,
    cellRenderer: (params: any) => {
      return convertTimeToTimeZoneForDisplay(params?.value);
    },
    ...additionalCommonFieldProperties
  }
];
