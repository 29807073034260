export const logLevelOptions = [
  { label: 'Success', value: 'Success' },
  { label: 'Error', value: 'Error' },
  { label: 'All logs', value: ' ' }
]

export const exceptionStatusOptions = [
  { label: 'Scheduled', value: 'Scheduled' },
  { label: 'Pending', value: 'Pending' },
  { label: 'Resolved', value: 'Resolved' },
  { label: 'Error', value: 'Error' }
];

export const exceptionStatusDefaultSelected = [
  { label: 'Pending', value: 'Pending' },
  { label: 'Resolved', value: 'Resolved' }
];

export const exceptionReplayAllowedStatus = ['Pending', 'Error', 'Scheduled'];
export const exceptionAlreadyReplayedStatus = ['Resolved'];
