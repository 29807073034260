import styled from 'styled-components';

export const FileReplayContainer = styled.div`
    padding: 6px 20px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const FileReplayWrapper = styled.div`
    background: var(--white-300);
    display: flex;    
    flex-direction: column;
    height: 100%;
`;

export const FileReplayHeader = styled.div`
    padding: 10px 20px;
    background: var(--blue-color-600);
    border: 1px solid var(--blue-color-275);
    color: var(--blue-color-700);
    font-size: var(--font-size-md);

    a {
        color: inherit;
        text-decoration: none;
    }

    .anticon {
        padding-right: 5px;
        color: var(--blue-color);
    }

    .replay-header-info {
        color: var(--black-100);
    }
`;

export const FileReplayStatusHeader = styled.div`
    margin: 8px 0 10px;
    display: flex;
    background: var(--white);
    border-radius: 4px;
    padding: 10px 15px;

    & > div {
        margin-right: 20px;
        padding-right: 20px;
        border-right: 1px solid var(--gray-275);
    }

    h3 {
        color: var(--primary-color);
        font-size: var(--font-size-lg);
        font-weight: var(--font-weight-bold);
        margin-bottom: 5px;
    }
`;

export const FileReplayLastUpdatedLabel = styled.label`
    color: var(--gray-color);
    font-size: var(--font-size-sm);
    letter-spacing: .07rem;

    span {
        font-weight: var(--font-weight-medium);
    }
`
export const FileReplayTableWrapper = styled.div`
  background: var(--white);
  margin-top: 5px;
  padding: 5px 0;
  box-shadow: var(--card-box-shadow);
  border-radius: 4px;
  height: 100%;

  .ant-layout-content {
    border-top: 1px solid var(--default-border-color);
    margin: 0;
  }

  section {
    & > div:first-child {
        border-bottom: none;
    }
  }
`;

export const FileReplayDetailSubheader = styled.div`
    margin: 0 0 10px;
    display: flex;
    background: var(--white);
    border-radius: 4px;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .detail-title {
        color: var(--primary-color);
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-medium);

        a {
            color: inherit;
        }

        .anticon {
            vertical-align: middle;
            margin-right: 5px;
        }
    }
`;
