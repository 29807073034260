import * as types from '../ActionTypes/SavedSearch/SavedSearchTypes';

interface dataInterface {
  type: string
  data?: []
  payload?: any
}
export function storeSavedSearchList (payload: any): dataInterface {
  return {
    type: types.STORE_SAVED_SEARCH_LIST,
    payload
  };
}
export function getSavedSearchList (): dataInterface {
  return {
    type: types.GET_SAVED_SEARCH_LIST
  };
}
export function saveSearchCriteria (payload: any): dataInterface {
  return {
    type: types.SAVE_SEARCH_CRITERIA,
    payload
  };
}

export function deleteSavedSearchCriteria (payload: any): dataInterface {
  return {
    type: types.DELETE_SAVED_SEARCH_LIST,
    payload
  };
}
