import styled from 'styled-components';

export const StatusBarWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 11vh;
  max-height: 15vh;
  >div{
    margin-right: 1.5vw;
    flex-grow: 1; 
    flex-basis: 200;
    margin-bottom: 5px;
    background: var(--white);
    box-shadow: var(--card-box-shadow);
    display: grid;
    grid-template-columns: 30% auto;
    grid-template-rows: auto auto;
    border-radius: 4px;
    >div:first-child{
      grid-row: 1 / span 2;
    }
  }
  >div:last-child{
    margin:0px;
    margin-bottom: 5px;
  }
`;
export const LabelWrapper = styled.div`
  font-size: var(--font-size-md);
`;

export const CountWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-lg);
  margin-bottom: 2px;
`;
export const ReplayStatusIconWrapper = styled.div`
  display: flex;
  align-content: flex-end;
  justify-content: end;
  font-size: var(--font-size-3xl);
  margin-right: 15px;
  &[class*="resolve"]{
    color:var(--replay-resolved-color);
  }
  &[class*="progress"]{
    color:var(--replay-progress-color);
  }
  &[class*="pending"]{
    color:var(--replay-pending-color);
  }
  &[class*="schedule"]{
    color:var(--replay-schedule-color);
  }
  &[class*="error"]{
    color:var(--replay-error-color);
  }
`;
