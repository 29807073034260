import { produce } from 'immer';
import { SET_REPLAY_CONFIG_LIST } from '../../../actions/ActionTypes/Admin/replayConfigTypes';
import { processOperation } from '../../../../helpers/reducerHelper';

export interface ReplayConfigState {
  replayConfigList: any[]
}

const initialState = {
  replayConfigList: []
};

const replayConfigReducer = (state = initialState, action: any): ReplayConfigState =>
  produce(state, (draft: ReplayConfigState) => {
    switch (action.type) {
      case SET_REPLAY_CONFIG_LIST:
        draft.replayConfigList = processOperation(action.data, [...draft.replayConfigList]);
        break;
    }
  });

export default replayConfigReducer;
