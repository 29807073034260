import type {
  DeleteConfigDataActionType,
  GetConfigDetailsActionType,
  SetConfigDetailsActionType
} from '../../../constants/Interfaces/AdminConfigActionTypes';
import {
  GET_LOG_REPORT_CONFIG_LIST,
  SET_LOG_REPORT_CONFIG_LIST,
  DELETE_LOG_REPORT_CONFIG,
  SAVE_LOG_REPORT_CONFIG
} from '../ActionTypes/Admin/logReportConfigTypes';

interface SaveConfigDetailAction {
  type: string
  payload: {
    data: any[]
    callBackFunc?: any
    isAdd?: boolean
  }
}

export function getLogReportConfigList (): GetConfigDetailsActionType {
  return {
    type: GET_LOG_REPORT_CONFIG_LIST
  };
}

export function setLogReportConfigList (data: any): SetConfigDetailsActionType {
  return {
    type: SET_LOG_REPORT_CONFIG_LIST,
    data
  };
}

export function deleteLogReportConfig (data: any, callBackFunc?: any): DeleteConfigDataActionType {
  return {
    type: DELETE_LOG_REPORT_CONFIG,
    payload: {
      data,
      callBackFunc
    }
  };
}
export function saveLogReportConfig (
  data: any,
  callBackFunc?: any,
  isAdd?: boolean
): SaveConfigDetailAction {
  return {
    type: SAVE_LOG_REPORT_CONFIG,
    payload: {
      data,
      callBackFunc,
      isAdd
    }
  };
}
