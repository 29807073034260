export const exceptionConfigFieldLabels = {
  exceptionConfigId: 'Exception Config ID',
  applicationName: 'Application Name',
  applicationDomain: 'Application Domain',
  description: 'Description',
  exceptionCategoryName: 'Category',
  exceptionTypeName: 'Type',
  exceptionSeverityName: 'Severity',
  replayType: 'Replay Type',
  replyMode: 'Replay Mode',
  replyDestination: 'Replay Destination',
  maxAutoReplay: 'Maximum Auto Replay',
  autoReplayInterval: 'Interval (minutes)',
  enableMailNotification: 'Enable Mail Notification',
  enableGroupNotification: 'Enable Group Notification',
  enableSnowNotification: 'Enable Snow Notification',
  emailAddress: 'Notification Email ID',
  snowAssignmentGroup: 'Assignment Group',
  sysClassName: 'Snow Service Class',
  snowParam: 'Snow Service Value'
}
