import CustomSearchQueryRenderer from './customRendererForColumns/CustomSearchQueryRenderer';
import OpenSearchCriteriaButtonRenderer from './customRendererForColumns/OpenSearchCriteriaButtonRenderer';
import DeleteSearchCriteriaButtonRenderer from './customRendererForColumns/DeleteSearchCriteriaButtonRenderer';

export const COLUMNS: any[] = [
  {
    field: 'name',
    headerName: 'Criteria Name',
    maxWidth: 300
  },
  {
    field: 'description',
    headerName: 'Description',
    maxWidth: 500
  },
  {
    field: 'criteriaJson',
    headerName: 'Criteria',
    cellRenderer: CustomSearchQueryRenderer,
    flex: 1
  },
  {
    field: 'criteria',
    headerName: '',
    minWidth: 20,
    maxWidth: 70,
    cellRenderer: OpenSearchCriteriaButtonRenderer
  }
];

export const DELETECOLUMN: any[] = [
  {
    field: 'delete_button',
    headerName: '',
    minWidth: 20,
    maxWidth: 70,
    cellRenderer: DeleteSearchCriteriaButtonRenderer
  }
];
