import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { getGroupApplicationAccessList } from '../../../../../redux/actions/AccessManagement/GroupAccess/groupAccessActions';
import ApplicationAccess from '../../../../common/AccessComponent/ApplicationAccess';
import { GroupApplicationAccessForm } from '../../../../common/AccessComponent/style';
import { getUpdatedApplicationAccessConfig } from '../../../../../helpers/applicationDataHelper';

interface ApplicationAccessTabProps {
  isAddView?: boolean
  isEditable: boolean
  groupName: string
  ref: any
}

const ApplicationAccessTab: FC<ApplicationAccessTabProps> = forwardRef(({ groupName, isAddView, isEditable }, ref) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const applicationAccessList = Form.useWatch('appAccessList', form);

  const groupApplicationAccessList = useSelector((state: any) => state.groupConfig.groupApplicationAccessList);
  const [applicationAccessDataList, setGroupApplicationAccessDataList] = useState([]);

  const validateApplicationAccessForm = async (): Promise<boolean> => {
    let isFormInvalid = false;

    await form.validateFields()
      .then(async () => {
        isFormInvalid = false;
      }).catch(() => {
        isFormInvalid = true;
      });

    return isFormInvalid;
  }

  useEffect(() => {
    if (groupApplicationAccessList.length < 1) {
      dispatch(getGroupApplicationAccessList());
    }
  }, []);

  useEffect(() => {
    if (groupName) {
      const groupApplicationAccessData = groupApplicationAccessList.filter((item: any) => item.groupName === groupName);

      setGroupApplicationAccessDataList(groupApplicationAccessData);
      form.setFieldValue('appAccessList', groupApplicationAccessData);
    }
  }, [groupApplicationAccessList, isEditable]);

  useImperativeHandle(
    ref,
    () => {
      return {
        getApplicationAccess: () => {
          let data = null;
          const isApplicationAccessDataChanged = isAddView ? false : isEqual(applicationAccessDataList, applicationAccessList);

          if (!isApplicationAccessDataChanged && applicationAccessList) {
            data = getUpdatedApplicationAccessConfig(isAddView ? [] : applicationAccessDataList, applicationAccessList, 'applicationName', 'accessName', groupName)
          }

          return data;
        },
        checkIsApplicationAccessFormValid: async () => {
          const isFormInvalid = await validateApplicationAccessForm();

          return isFormInvalid;
        }
      }
    },
    [applicationAccessList, isEditable, groupName]
  );

  return (
    <GroupApplicationAccessForm
      data-testid="group-application-access-form"
      form={form}
    >
      <ApplicationAccess
        formRef={form}
        fieldName={'appAccessList'}
        selectedApplication={applicationAccessList}
        isViewMode={!isEditable}
        allowClear={false}
        isMultiSelect={false}
        hasAccessTitle={false}
        hasMirrorAccess={false}
      />
    </GroupApplicationAccessForm>
  )
});

ApplicationAccessTab.propTypes = {
  isAddView: PropTypes.bool,
  isEditable: PropTypes.bool.isRequired,
  groupName: PropTypes.string.isRequired
};

ApplicationAccessTab.displayName = 'ApplicationAccessTab';

export default ApplicationAccessTab;
