import React, { useLayoutEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col, Form } from 'antd';
import type { FormFieldType } from '../../../../../constants/Interfaces/FormFieldTypes';
import type { AdminConfigFormPropsType } from '../../../../../constants/Interfaces/AdminFormConfigTypes';
import { replayConfigIFBlockFormFields, getUpdatedReplayConfigThenFormConfig } from '../../../../../constants/FormFields/AdminForm/replayConfigFormFields';
import FloatingLabel from '../../../../common/FloatingLabel';
import Footer from '../../../../common/Footer';
import { applicationDataHelper } from '../../../../../helpers/applicationDataHelper';
import { replayModeOptions } from '../../../../../constants/DropDownOptions/adminConfig';
import { REPLAY_ID } from '../../../../../constants/KeyLabels/commonKeyConstants';
import { RowCriteriaWithBorderWrapper, RowCriteriaWithOutBorderWrapper } from '../../../../../assets/styles/commonStyled';
import textConstants from '../../../../../constants/textConstants';

const ReplayConfigForm: FC<AdminConfigFormPropsType> = ({ isDrawerView, isEditable, data, onSave, onCancel }) => {
  const [form] = Form.useForm();
  const applicationNames = applicationDataHelper.getApplicationNames([]);
  const replayModeUpdatedValue = Form.useWatch('replayMode', form);
  const replayThenFormConfig = getUpdatedReplayConfigThenFormConfig(replayModeUpdatedValue);

  const onSaveHandler = (isClosable: boolean): void => {
    form.validateFields()
      .then(async values => {
        onSave(values, isClosable);
      }).catch(error => console.log(error));
  }

  useLayoutEffect(() => {
    form.setFieldsValue(data);
  }, [data[REPLAY_ID]]);

  return (
    <>
      <Form
        form={form}
        initialValues={{
          ...data
        }}
        data-testid="replayConfigForm"
      >
        <RowCriteriaWithBorderWrapper
          gutter={{ lg: 16, xl: 16, xxl: 28 }}
          data-title={textConstants.REPLAY_CONFIG_DETAIL_IF_CRITERIA_LABEL}
        >
          {replayConfigIFBlockFormFields.filter((item: FormFieldType) => (isEditable || item.id !== 'replayID')).map((formField: FormFieldType) => {
            return (
              <Col key={formField.id} xs={24} md={8} xl={5}>
                <Form.Item name={formField.name} rules={formField.rules}>
                  <FloatingLabel
                    label={formField.label}
                    type={formField.type}
                    id={formField.id}
                    disabled={((data?.applicationName ?? isEditable) && formField.disableOnEdit)}
                    required={formField?.required}
                    {...(formField.type === 'select' && { options: applicationNames, showSearch: true })}
                  />
                </Form.Item>
              </Col>
            );
          })}
        </RowCriteriaWithBorderWrapper>
        <RowCriteriaWithOutBorderWrapper
          gutter={{ lg: 16, xl: 16, xxl: 28 }}
          data-title={textConstants.REPLAY_CONFIG_DETAIL_THEN_CRITERIA_LABEL}
        >
          {Object.keys(replayThenFormConfig).map((key) => (
            <Col key={replayThenFormConfig[key].id} xs={24} md={8} xl={5}>
              <Form.Item name={replayThenFormConfig[key].name} rules={replayThenFormConfig[key].rules}>
                <FloatingLabel
                  label={replayThenFormConfig[key].label}
                  type={replayThenFormConfig[key].type}
                  id={replayThenFormConfig[key].id}
                  disabled={isEditable && replayThenFormConfig[key].disableOnEdit}
                  required={replayThenFormConfig[key]?.required}
                  {...(replayThenFormConfig[key].type === 'select' && { options: replayModeOptions })}
                />
              </Form.Item>
            </Col>
          ))}
        </RowCriteriaWithOutBorderWrapper>
      </Form>
      <Footer
        className={`grey-theme ${isDrawerView ? 'add-form-footer' : ''}`}
        onSave={() => onSaveHandler(false)}
        onSaveClose={() => onSaveHandler(true)}
        onCancel={onCancel}
        saveWithClose={!isEditable}
        save
        cancel
      />
    </>
  );
};

ReplayConfigForm.defaultProps = {
  isEditable: true
};

ReplayConfigForm.propTypes = {
  isDrawerView: PropTypes.bool,
  isEditable: PropTypes.bool,
  data: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ReplayConfigForm;
