import { produce } from 'immer';
import {
  SET_APPLICATION_DETAILS,
  SET_APPLICATION_SECTOR_LIST
} from '../../actions/ActionTypes/Admin/applicationConfigTypes';
import { processOperation } from '../../../helpers/reducerHelper';

export interface ApplicationsConfigState {
  applicationConfigList: any[]
  detectAction: object | null
  isApplicationLoading: boolean
  isListloading: boolean
  applicationSectorList: any[] | null
  isApplicationSectorListLoading: boolean
}

const initialState = {
  applicationConfigList: [],
  detectAction: null,
  isApplicationLoading: true,
  isListloading: true,
  applicationSectorList: [],
  isApplicationSectorListLoading: false
};

const applicationsConfigReducer = (state = initialState, action: any): ApplicationsConfigState =>
  produce(state, (draft: ApplicationsConfigState) => {
    switch (action.type) {
      case SET_APPLICATION_DETAILS:
        draft.applicationConfigList = processOperation(action.data, [...draft.applicationConfigList]);
        draft.isApplicationLoading = false;
        break;
      case SET_APPLICATION_SECTOR_LIST:
        draft.applicationSectorList = action.data;
        draft.isApplicationSectorListLoading = false;
        break;
    }
  });

export default applicationsConfigReducer;
