import React, { useState, useEffect, useRef } from 'react';
import type { FC, ReactNode } from 'react';
import { cloneDeep, uniq } from 'lodash';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import type { InputRef } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import useDetectOutsideClick from '../../CustomHooks/checkOutsideClick';
import textConstants from '../../../constants/textConstants';
import { AutoCompleteInputWrapper, AutoCompletePopover } from './style';

interface FieldType {
  label: string
  name: string
  id: string
}

interface CustomSearchInputProps {
  form: any
  isDisabled?: boolean
  isMultiValueAllowed?: boolean
  ignoreFieldList: string[]
  resetData: () => void
  suggestionData: any
  value?: string
  onChange?: any
  setCustomSearchModeStatus: any
}

const CustomSearchInput: FC<CustomSearchInputProps> = ({
  form,
  isDisabled,
  ignoreFieldList,
  isMultiValueAllowed,
  resetData,
  suggestionData,
  setCustomSearchModeStatus,
  onChange,
  value
}) => {
  const inputRef = useRef<InputRef>(null);
  const [searchString, setAutocompleteSearchString] = useState<string>('');
  const [inputCursorPosition, setInputCursorPosition] = useState<number>(0);
  const [isAutoCompleteVisible, setAutocompleteVisibility] = useState<boolean>(false);
  const { ref, isComponentVisible } = useDetectOutsideClick(isAutoCompleteVisible);
  const [currentFieldName, setCurrentFieldName] = useState<string>('field');
  const [suggestionList, setSuggestionList] = useState<any[]>([]);
  const [querySuggestionData, setQuerySuggestionData] = useState<any>({});

  const getFieldName = (field: string): string => {
    const fieldName = suggestionData.field.find(
      (item: any) => item.label.replace(/ /g, '') === field
    );
    return fieldName?.name || '';
  };

  const updateSelectedQuerySuggestionData = (fieldKey: string, fieldValue: string): void => {
    let querySuggestionClonedData = cloneDeep(querySuggestionData);

    const selectedFieldList = querySuggestionClonedData[fieldKey] || [];
    const fieldValues = [...selectedFieldList, fieldValue?.replace(/^\s+/g, '').toLowerCase()];

    querySuggestionClonedData = {
      ...querySuggestionClonedData,
      [fieldKey]: uniq([...fieldValues])
    };

    setQuerySuggestionData(querySuggestionClonedData);
  };

  const handleSelectedQuerySuggestionDataChange = (queryString: string): void => {
    const fieldValueArr = queryString.split(';').map((item: string) => item.trim());

    const field: string[] = [];

    let queryData = {
      field
    };

    fieldValueArr.forEach((element: string) => {
      const valueData = element.split('=').map((item: string) => item.trim());
      const inputFieldName = getFieldName(valueData[0].replace(/ /g, ''));
      const inputFieldValue = valueData[1]
        ?.toLowerCase()
        .replace(/\s*,\s*/g, ',')
        .split(',');

      const fieldKeyName = inputFieldName.replace(/ /g, '');

      if (inputFieldName) {
        queryData = {
          ...queryData,
          field: uniq([...queryData.field, inputFieldName]),
          ...(fieldKeyName.length > 0 && { [fieldKeyName]: uniq(inputFieldValue) })
        };
      }
    });

    setQuerySuggestionData(queryData);
  };

  // Method to append new criteria at end of query
  const appendNewFieldValueSuggestion = (
    prevVal: string,
    label: string,
    currentLabelPos: number,
    currentValuePos: number,
    currentSingleValuePos: number
  ): any => {
    let inputStr = '';
    const valArr = prevVal?.split('') || [];
    let updatedValue = '';
    let fieldName = '';

    if (currentLabelPos >= currentSingleValuePos) {
      inputStr = prevVal?.substring(currentLabelPos + 1, inputCursorPosition) || '';
      valArr.splice(currentLabelPos + 1, inputStr.length, ` ${label}= `);
      fieldName = getFieldName(label.replace(/ /g, ''));
    } else {
      inputStr = prevVal?.substring(currentValuePos + 1, inputCursorPosition);
      valArr.splice(currentValuePos + 1, inputStr.length, ` ${label};`);
      fieldName = 'field';
    }

    updatedValue = valArr.join('').trim();

    return {
      updatedValue,
      fieldName
    };
  };

  const handleSuggestionDataClick = (label: string): void => {
    const prevVal = form.getFieldValue('searchFieldValue');
    let inputStr = '';
    let updatedValue = '';
    let fieldName = '';
    // let searchValue = '';

    const currentLabelPos = prevVal?.lastIndexOf(';', inputCursorPosition - 1) || -1;
    const currentSingleValuePos = prevVal?.lastIndexOf('=', inputCursorPosition - 1) || -1;
    const currentMultiValuePos = prevVal?.lastIndexOf(',', inputCursorPosition - 1) || -1;
    const currentValuePos: number =
      isMultiValueAllowed && currentMultiValuePos > currentSingleValuePos
        ? currentMultiValuePos
        : currentSingleValuePos;
    const valArr = prevVal?.split('') || [];

    if (inputCursorPosition && inputCursorPosition < prevVal?.length) {
      // if user updates value between query
      if (currentLabelPos >= currentValuePos) {
        // If users change current field name
        const fieldVal = currentLabelPos > -1 ? `; ${label}` : label;
        inputStr = prevVal.substring(currentLabelPos, prevVal.indexOf('=', inputCursorPosition));
        updatedValue = prevVal.replace(inputStr, fieldVal);
      } else {
        // If user updates current provided value
        const lastValIndex = prevVal?.indexOf(';', inputCursorPosition);
        const initValIndex = prevVal?.indexOf(',', inputCursorPosition);
        const strValIndex =
          !isMultiValueAllowed && initValIndex !== -1 && initValIndex < lastValIndex
            ? initValIndex
            : lastValIndex;
        inputStr = prevVal.substring(currentValuePos + 1, strValIndex);

        valArr.splice(currentValuePos + 1, inputStr.length, ` ${label}`);
        updatedValue = valArr.join('');
      }
    } else {
      const addedData = appendNewFieldValueSuggestion(
        prevVal,
        label,
        currentLabelPos,
        currentValuePos,
        currentSingleValuePos
      );
      updatedValue = addedData.updatedValue;
      fieldName = addedData.fieldName;
    }

    let field = 'field';

    if (currentLabelPos >= currentValuePos) {
      field = 'field';
    } else {
      field = getFieldName(currentFieldName);
    }

    updateSelectedQuerySuggestionData(field, label);

    form.setFieldValue('searchFieldValue', updatedValue);
    setCurrentFieldName(fieldName);
    setAutocompleteSearchString('');

    inputRef?.current?.focus();
    // inputRef?.current?.setSelectionRange(inputCursorPosition || 0, inputCursorPosition + 1 || 0);
    form.validateFields(['searchFieldValue']);
  };

  // Method to get & filter Field Name based on Search Keyword
  const getFieldNameOptions = (): ReactNode => {
    const filteredList = suggestionList
      .filter((item: FieldType) => {
        return (
          item?.label?.toLowerCase().includes(searchString?.toLowerCase()) &&
          !querySuggestionData[getFieldName(item?.label?.replace(/ /g, ''))] &&
          !querySuggestionData[currentFieldName]?.includes(item?.label?.toLowerCase())
        );
      })
      .map((item: FieldType) => (
        <li key={item.label} onClick={() => handleSuggestionDataClick(item.label)}>
          {item.label}
        </li>
      ));

    if (filteredList.length < 1) {
      return <span>{textConstants.NO_MATCH_FOUND}</span>;
    }

    return filteredList;
  };

  const handleSearchInputChange = (evt: React.ChangeEvent<any>): void => {
    const value: string = evt.target.value;
    let searchString = '';
    let fieldName = '';

    if (value === '') {
      resetData();
      setInputCursorPosition(0);
      searchString = '';
    } else {
      const currentLabelPos = value?.lastIndexOf(';', evt.target.selectionEnd);
      const currentSingleValuePos = value?.lastIndexOf('=', evt.target.selectionEnd);
      const currentMultiValuePos = value?.lastIndexOf(',', evt.target.selectionEnd);
      const currentValuePos =
        isMultiValueAllowed && currentMultiValuePos > currentSingleValuePos
          ? currentMultiValuePos
          : currentSingleValuePos;

      const currentCharIndex =
        currentValuePos > currentLabelPos ? currentValuePos : currentLabelPos;

      setTimeout(() => {
        handleSelectedQuerySuggestionDataChange(value.trim().replace(/, /g, ','));
      }, 500);

      const inputStr = value
        .substring(currentCharIndex + 1, evt.target.selectionEnd)
        .replace(/^\s+/g, '');
      searchString = inputStr;

      if (currentValuePos > currentLabelPos) {
        const labelName = value
          .substring(currentLabelPos + 1, currentSingleValuePos)
          .replace(/ /g, '');
        fieldName = getFieldName(labelName);
      } else {
        fieldName = 'field';
      }
    }

    setCurrentFieldName(fieldName);
    // Will Set Search String State
    setAutocompleteSearchString(searchString);
    onChange(value);
  };

  const handleSearchInputFocus = (evt: any): void => {
    const value: string = evt.target.value;
    const cursorPos: number = evt.target.selectionEnd;
    const currentLabelPos = value?.lastIndexOf(';', cursorPos - 1);
    const currentValuePos = value?.lastIndexOf('=', cursorPos - 1);

    let fieldName = '';
    if (currentLabelPos >= currentValuePos) {
      fieldName = 'field';
    } else {
      fieldName = evt.target.value.substring(currentLabelPos + 1, currentValuePos).trim();
      fieldName = getFieldName(fieldName.replace(/ /g, ''));
    }
    setCurrentFieldName(fieldName);
    setAutocompleteVisibility(true);
  };

  const handleSearchInputBlur = (evt: any): void => {
    const fieldValueArr = evt.target.value.split(';').map((item: string) => item.trim());
    setInputCursorPosition(evt?.target?.selectionEnd ?? 0);

    const formattedArr = suggestionData.field
      .filter((item: FieldType) => !ignoreFieldList.includes(item.name))
      .map((field: FieldType) => ({
        [field.name]: undefined
      }));

    const formattedObj = Object.assign({}, ...formattedArr);

    form.setFieldsValue({
      ...formattedObj
    });

    fieldValueArr.forEach((element: string) => {
      const valueData = element.split('=').map((item: string) => item.trim());
      const inputFieldName = valueData[0].replace(/[@]/g, '');
      const inputFieldValue = valueData[1];
      const field: FieldType = suggestionData.field.find(
        (item: any) => item?.label?.toLowerCase() === inputFieldName?.toLowerCase()
      );

      if (field) {
        form.setFieldValue(field?.name, inputFieldValue);
      }
      // else {
      //   console.log(`${inputFieldName} not available`);
      // }
    });
  };

  /**
   * Method to Submit Search Query on Ctrl + Click
   * @param {any} evt
   *
   **/
  const handleKeypress = (evt: any): void => {
    if (evt.ctrlKey && evt.keyCode === 13) {
      evt.preventDefault();
      handleSearchInputBlur(evt);
      form.submit();
      setAutocompleteVisibility(false);
    }
  };

  useEffect(() => {
    if (!isComponentVisible) {
      setCustomSearchModeStatus(false);
    }
    setAutocompleteVisibility(isComponentVisible);
  }, [isComponentVisible]);

  useEffect(() => {
    if (!isAutoCompleteVisible) {
      setAutocompleteSearchString('');
      setCustomSearchModeStatus(false);
    }
  }, [isAutoCompleteVisible]);

  useEffect(() => {
    if (!value) {
      setCurrentFieldName('field');
      setQuerySuggestionData({});
    } else {
      handleSelectedQuerySuggestionDataChange(value);
    }
  }, [value]);

  useEffect(() => {
    setSuggestionList(suggestionData[currentFieldName] || []);
  }, [currentFieldName, searchString, querySuggestionData]);

  return (
    <AutoCompleteInputWrapper ref={ref}>
      <SearchOutlined />
      <Input.TextArea
        placeholder={textConstants.LOG_EXCEPTION_SEARCH_PLACEHOLDER}
        onChange={handleSearchInputChange}
        onBlur={handleSearchInputBlur}
        onMouseUp={handleSearchInputFocus}
        onKeyDown={(e) => handleKeypress(e)}
        onFocus={() => {
          setAutocompleteVisibility(true);
        }}
        disabled={isDisabled}
        value={value}
        ref={inputRef}
        autoComplete="off"
        data-testid="autocompleteInput"
        allowClear
        autoSize={{ minRows: 1, maxRows: 2 }}
      />
      {isAutoCompleteVisible && (
        <>
          <span className='query-submit-text'>{textConstants.SEARCH_QUERY_KEYPRESS_SUBMIT_LABEL}</span>
          <AutoCompletePopover className="fade-in" data-testid="autoCompleteSuggestionWrapper">
            <ul>{getFieldNameOptions()}</ul>
          </AutoCompletePopover>
        </>
      )}
    </AutoCompleteInputWrapper>
  );
};

CustomSearchInput.defaultProps = {
  isMultiValueAllowed: false
};

CustomSearchInput.propTypes = {
  isDisabled: PropTypes.bool,
  isMultiValueAllowed: PropTypes.bool,
  form: PropTypes.object.isRequired,
  resetData: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  ignoreFieldList: PropTypes.array.isRequired,
  suggestionData: PropTypes.object,
  setCustomSearchModeStatus: PropTypes.func
};

export default CustomSearchInput;
