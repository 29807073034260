import React, { useState } from 'react';
import type { FC } from 'react';
import { ReadMoreButton } from '../../../assets/styles/commonStyled';

interface ReadMoreProps {
  className?: string
  children: any
}

const ReadMore: FC<ReadMoreProps> = ({ className, children }) => {
  const [isExpanded, setExpandedStatus] = useState(false);

  const content = isExpanded ? children : children?.toString().slice(0, 200);

  const toggleContentStatus = (): void => {
    setExpandedStatus(!isExpanded);
  };

  return (
    <div className={className}>
        {content}
        {content?.length >= 200 && (
            <>
              {isExpanded ? '' : '...'}
              <ReadMoreButton type="link" onClick={toggleContentStatus}>
                  {isExpanded ? 'Read less' : 'Read more'}
              </ReadMoreButton>
            </>
        )}
    </div>
  );
};

export default ReadMore;
