import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { FormOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import Tabs from '../../../../common/Tabs';
import GroupAccessTab from '../../UserManagement/GroupAccessTab';
import { AdminBasicTabWrapper } from '../../../../../assets/styles/commonStyled';
import UserListTabContent from './UserListTabContent';
import { EditIconButton } from '../../../../common/Footer/Buttons.style';

interface UserManagementTabContentProps {
  applicationName: string
  isEditable?: boolean
}

const UserManagementTabContent: FC<UserManagementTabContentProps> = ({ applicationName, isEditable }) => {
  const userAccessInfo = useSelector((state: any) => state.userAccess?.userAccessInfo);
  const [isApplicationDetailInEditMode, setApplicationDetailInEditMode] = useState(isEditable ?? false);

  const toggleEditMode = (isEdit: boolean): void => {
    if (!isEditable) {
      setApplicationDetailInEditMode(isEdit);
    }
  }

  return (
    <AdminBasicTabWrapper className='user-management-tab user-managemnet-list' data-testid='user-management-tab'>
      <Tabs
        tabClassName='basic-theme'
        defaultActiveTab="summary"
        tabExtraContent={{
          right: (
            !isEditable && userAccessInfo?.adminAccess?.toUpperCase() === 'Y' && (
              <EditIconButton
                data-testid='editUserManagementBtn'
                icon={<FormOutlined />}
                onClick={() => setApplicationDetailInEditMode(true)}
                type="text"
              >
                Edit
              </EditIconButton>
            )
          )
        }}
        tabSize="small"
        tabType='line'
        tabItems={[
          {
            key: 'userList',
            label: 'User List',
            children: (
              <UserListTabContent
                applicationName={applicationName}
                isEditable={isApplicationDetailInEditMode}
              />
            )
          },
          {
            key: 'groupList',
            label: 'Group List',
            children: (
              <GroupAccessTab
                appName={applicationName}
                isEditable={isApplicationDetailInEditMode}
                onToggleEditMode={toggleEditMode}
              />
            )
          }
        ]}
      />
    </AdminBasicTabWrapper>
  );
};

UserManagementTabContent.propTypes = {
  applicationName: PropTypes.string.isRequired,
  isEditable: PropTypes.bool
};

export default UserManagementTabContent;
