import styled from 'styled-components';

export const FloatingLabelWrapper = styled.div`
    position: relative;

    textarea {
        padding-top: 10px;
    }

    textarea + label {
        top: 25px;
    }

    .ant-input[disabled],.ant-select-disabled, .multiselect-disabled, .ant-input-number-disabled {
        & + .float-label::after {
            content: '';
            position: absolute;
            bottom: 0;
            height: 7px;
            background: rgba(0, 0, 0, 0.04);
            z-index: 9999;
            left: 0;
            right: 0;

            @media screen and (min-width: 1600px) {
                height: 11px;
            }
        }
    }

    .ant-input-affix-wrapper {
        border-color: var(--form-field-border-color);
        font-size: var(--font-size-sm);
        height: 40px;

        @media screen and (min-width: 1920px) {
            height: 48px;
        }

        .ant-input {
            height: 100%;
        }

        &:focus, &-focused, :focus-within {
            box-shadow: none!important;
            border-color: var(--form-field-focus-border-color);
        }

        &:hover {
            border-color: var(--form-field-focus-border-color);
        }
    }

    .ant-input-suffix .anticon-info-circle {
        color: var(--gray-525);
    }
`

export const Label = styled.label`
    font-size: var(--font-size-sm);
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s ease all;
    z-index: 9;

    &.float-label {
        font-size: var(--font-size-sm);
        top: -4px;
        transform: none;
        left: 10px;
        background: var(--white);
        padding: 0 7px;
        line-height: 1;
        z-index: 1;
    }

    span {
        color: var(--error-color);
        font-weight: var(--font-weight-medium);
    }
`
