import { call, put, fork, all, takeLatest } from 'redux-saga/effects';
import type { AllEffect, CallEffect, ForkEffect, PutEffect } from 'redux-saga/effects';
import { createLogger } from 'redux-logger';
import { updateDataLoadingStatus } from '../../actions/CommonConfig/commonConfigActions';
import { setReportConfigList } from '../../actions/Admin/reportConfigActions';
import { DELETE_REPORT_CONFIG, GET_REPORT_CONFIG_LIST, SAVE_REPORT_CONFIG } from '../../actions/ActionTypes/Admin/reportConfigTypes';
import { API_SUCCESS_STATUS_CODE } from '../../../constants/commonConstants';
import { REPORT_CONFIG_TAB_KEY, REPORT_CONFIG_ID } from '../../../constants/KeyLabels/commonKeyConstants';
import { successNotification } from '../../../components/common/Notification';
import { apiRequest } from '../../../services/axiosInterceptor';
import apiURL from '../../../constants/apiEndpointConstants';
import textConstants from '../../../constants/textConstants';
import routeConstants from '../../../constants/routeConstants';

const callEffect: any = call;

export function * fetchReportConfigListSaga (): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: 'get',
      url: apiURL.GET_REPORT_CONFIG_LIST
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    const payload = {
      data: response?.data,
      action: 'NEW'
    };

    if (response) {
      yield put(setReportConfigList(payload));
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * saveReportConfigSaga ({ payload }: any): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: payload?.isAdd ? 'POST' : 'PUT',
      url: apiURL.SAVE_UPDATE_REPORT_CONFIG,
      data: payload?.data
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        data: response?.data,
        action: payload?.isAdd ? 'ADD' : 'UPDATE',
        ...(!payload?.isAdd && {
          keyName: REPORT_CONFIG_ID,
          keyValue: response.data[REPORT_CONFIG_ID]
        })
      };

      yield put(setReportConfigList(storePayload));

      successNotification(`Report Configuration ${payload?.isAdd ? 'added' : 'updated'} successfully`);

      if (payload.callBackFunc) {
        payload.callBackFunc();
      }
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * deleteReportConfigSaga ({ payload }: any): any {
  try {
    const config = {
      method: 'DELETE',
      url: apiURL.DELETE_REPORT_CONFIG,
      data: payload?.data
    };

    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        action: 'DELETE',
        keyName: REPORT_CONFIG_ID,
        keyValue: response.data[0][REPORT_CONFIG_ID]
      };

      yield put(setReportConfigList(storePayload));

      if (payload?.history) {
        payload.history.push({
          pathname: routeConstants.ADMIN_REPORT_CONFIG,
          state: {
            activeTab: REPORT_CONFIG_TAB_KEY
          }
        });
      }

      successNotification(textConstants.REPORT_CONFIG_DELETE_SUCCESS_MESSAGE);
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * getReportConfigList (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_REPORT_CONFIG_LIST, fetchReportConfigListSaga);
}

export function * deleteReportConfigItem (): Generator<any> {
  yield takeLatest(DELETE_REPORT_CONFIG, deleteReportConfigSaga);
}

export function * saveReportConfigItem (): Generator<any> {
  yield takeLatest(SAVE_REPORT_CONFIG, saveReportConfigSaga);
}

export default function * root (): Generator<AllEffect<any>> {
  yield all([
    fork(getReportConfigList),
    fork(deleteReportConfigItem),
    fork(saveReportConfigItem)
  ]);
}
