import styled from 'styled-components';
import { Button, Upload } from 'antd';
const { Dragger } = Upload;

export const FileUploadTitle = styled.h1`
    text-align: left;
    color: var(--blue-color-700);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-regular);
    margin: 0
`;

export const FileUploadPreviewWrapper = styled.div`
    display: flex;
`;

export const FileUploadPreviewIcon = styled.div`
    img {
        max-width: 48px;
        vertical-align: bottom;
    }
`;

export const FileUploadPreviewDetails = styled.div`
    padding: 0 0 0 15px;
    flex: 1;

    label {
        color: var(--gray-900);
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-bold);
    }

    .ant-progress {
        margin-bottom: 0;

        .ant-progress-bg {
            background: var(--blue-color-775);
        }
    }
`;

export const FileErrorLabel = styled.div`
    width: 100%;
    color: var(--red-100);
    font-size: var(--font-size-sm);
    margin-top: -1px;
`

export const FileUploadSubmitButton = styled(Button)`
    margin-top: 2px;
`

export const FileSizeLabel = styled.span`
    display: block;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-sm);
    position: relative;

    .anticon-exclamation-circle {
        position: absolute;
        top: -2px;
        right: 5px;
        color: var(--red-100);
        font-size: var(--font-size-xl);
    }
`;

export const FileUploadDraggerWrapper = styled.div`
    position: relative;
`;

export const FileUploadDragger = styled(Dragger)`
    &.ant-upload-wrapper {
        position: relative;

        .ant-upload-drag {
            background: var(--blue-color-300);
            border: 1px dashed var(--blue-color);
            border-radius: 4px;

            .ant-upload {
                padding: 5px 20px;
            }

            p.ant-upload-drag-icon {
                margin-bottom: 0;
                margin-top: -8px;
                position: relative;
                top: -5px;

                .anticon {
                    color: var(--blue-color);
                    transform: scale(.88);
                }
            }

            p.ant-upload-text {
                color: var(--gray-900);
                font-size: var(--font-size-md);
                font-weight: var(--font-weight-medium);
                margin-bottom: 2px;
            }

            p.upload-file-message {
                color: var(--gray-900);
                font-size: var(--font-size-sm);
                margin: 0;
            }

            a {
                color: var(--btn-link-text-color);
            }

            p.ant-upload-hint {
                color: var(--gray-525);
                font-size: var(--font-size-sm);
                margin-bottom: 5px;

                span {
                    display: inline-block;
                    width: 100%;
                }
            }
        }

        .ant-upload-list {
            .ant-upload-list-item-container {
                box-shadow: 0px 3px 6px var(--black-200);
                background: var(--white);
                border-radius: 6px;
                position: absolute;
                top: 3px;
                left: 50%;
                transform: translateX(-50%);
                bottom: 3px;
                min-width: 36vw;
                padding: 5px 15px;

                .ant-upload-list-item:hover {
                    background: transparent;
                }

                .ant-tooltip {
                    display: none;
                }

                .ant-upload-list-item {
                    margin: 0;
                    position: absolute;
                    right: 10px;
                    top: 22px;

                    &-name, .ant-upload-icon,  &.ant-upload-list-item-undefined {
                        display: none;
                    }
                }

                .ant-upload-list-item-actions {
                    position: absolute;
                    right: 13px;
                    top: 0;

                    .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
                        background: transparent;
                    }

                    .anticon-close {
                        font-size: var(--font-size-sm);
                        color: var(--gray-550);
                    }
                }
            }
        }
    }
`;
