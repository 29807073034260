import { call, put, fork, all, takeLatest } from 'redux-saga/effects';
import type { AllEffect, CallEffect, ForkEffect, PutEffect } from 'redux-saga/effects';
import { createLogger } from 'redux-logger';
import { updateDataLoadingStatus } from '../../../actions/CommonConfig/commonConfigActions';
import {
  DELETE_USER_LIST,
  GET_USERS_LIST,
  SAVE_USERS_LIST
} from '../../../actions/ActionTypes/AccessManagement/UserAccess/userAccessListTypes';
import { API_SUCCESS_STATUS_CODE } from '../../../../constants/commonConstants';
import { USER_ID_KEY } from '../../../../constants/KeyLabels/commonKeyConstants';
import { successNotification } from '../../../../components/common/Notification';
import { apiRequest } from '../../../../services/axiosInterceptor';
import apiURL from '../../../../constants/apiEndpointConstants';
import textConstants from '../../../../constants/textConstants';
import { setUserList } from '../../../actions/AccessManagement/UserAccess/userAccessListActions';

const callEffect: any = call;

export function * fetchUserListSaga (): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: 'get',
      url: apiURL.GET_USER_LIST
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    const payload = {
      data: response?.data,
      action: 'NEW'
    };

    if (response.status === API_SUCCESS_STATUS_CODE) {
      yield put(setUserList(payload));
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * saveUserListSaga ({ payload }: any): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: payload?.isAdd ? 'POST' : 'PUT',
      url: apiURL.SAVE_UPDATE_USER_LIST,
      data: payload?.data
    };

    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        data: response?.data,
        action: payload?.isAdd ? 'ADD' : 'UPDATE',
        ...(!payload?.isAdd && {
          keyName: USER_ID_KEY,
          keyValue: response.data[USER_ID_KEY]
        })
      };
      yield put(setUserList(storePayload));

      if (payload?.callBackFunc) {
        payload.callBackFunc();
      }
      successNotification(`User ${payload?.isAdd ? 'added' : 'updated'} successfully`);
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * deleteUserListSaga ({ payload }: any): any {
  try {
    const config = {
      method: 'DELETE',
      url: apiURL.DELETE_USER_LIST,
      data: payload?.data
    };

    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        action: 'DELETE',
        keyName: USER_ID_KEY,
        keyValue: response.data[0][USER_ID_KEY]
      };

      yield put(setUserList(storePayload));

      if (payload?.callBackFunc) {
        payload.callBackFunc();
      }

      successNotification(textConstants.USER_ACCESS_LIST_DELETE_SUCCESS_MESSAGE);
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * saveUserList (): Generator<any> {
  yield takeLatest(SAVE_USERS_LIST, saveUserListSaga);
}

export function * deleteUserList (): Generator<any> {
  yield takeLatest(DELETE_USER_LIST, deleteUserListSaga);
}

export function * getUserList (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_USERS_LIST, fetchUserListSaga);
}

export default function * root (): Generator<AllEffect<any>> {
  yield all([
    fork(getUserList),
    fork(saveUserList),
    fork(deleteUserList)
  ]);
}
