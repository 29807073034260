import styled from 'styled-components';

export const BlobMetricSearchPanelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0px;
  height: 41px;
  background: var(--white);
  box-shadow: 0px 0px 15px var(--black-50);
  border: 1px solid var(--gray-225);

  @media screen and (min-width: 1600px) {
    height: 50px;

    .ant-select-single .ant-select-selector .ant-select-selection-item {
      line-height: 48px;
    }
  }

  .react-dropdown-select {
    border: none;
    border-bottom: 1px solid var(--white);
    height: 100%;
    border-radius: 0;

    &:hover {
      border-bottom: 1px solid var(--blue-color-150);
    }

    &-dropdown.react-dropdown-select-dropdown-position-bottom {
      top: 46px;
    }

    .ant-input {
      height: auto;
    }

    .react-dropdown-select-content {
      color: var(--black-100);
      font-size: var(--font-size-md);

      span {
        > span {
          max-width: 105px;
        }
      
        &:only-child > span {
          max-width: 100%;
        }
      }
    }

    .react-dropdown-select-dropdown-handle {
      order: -1;
      padding-left: 10px;
      padding-right: 10px;
      height: 20px;
    }

    .anticon-search {
      color: var(--blue-color-500);
      width: 17px;
    }
  }

  .ant-select {
    border-left: 1px solid var(--gray-225);
    border-bottom: 1px solid var(--white);

    &:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
      height: 100%;
    }

    &:hover, &:focus-within {
      border-left-color: var(--gray-225);
      border-bottom-color: var(--form-field-focus-border-color);
    }
  }

  .ant-picker {
    height: 100%;
    border-top: none;
    border-right: none;
    border-left: 1px solid var(--gray-225);
    border-bottom: 1px solid var(--white);
    border-radius: 0;

    &:hover {
      border-left-color: var(--gray-225);
      border-bottom-color: var(--form-field-focus-border-color);
    }
  }

  .ant-row, .ant-form {
    width: 100%;
    height: 100%;
  }
`;
