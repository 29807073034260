import React from 'react';
import { type FC } from 'react';
import { useHistory } from 'react-router-dom';
import { AdminMainBreadCrumbWrapper, AdminMainLayoutWrapper } from '../../../assets/styles/commonStyled';
import ConfigurationListTable from '../../../components/AdditionalComponents/ConfigurationListTable';
import { GROUP_LIST_COLUMNS } from '../../../constants/TableColumns/AccessManagement/GroupListColumns';
import { getGroupList } from '../../../redux/actions/AccessManagement/GroupAccess/groupAccessActions';
import textConstants from '../../../constants/textConstants';
import routeConstants from '../../../constants/routeConstants';

const GroupAccessList: FC = () => {
  const history = useHistory();

  const showDetailPageHandler = (rowData: any, isEditMode: boolean = true, isAdd: boolean = false): void => {
    history.push({
      pathname: routeConstants.ADMIN_GROUP_ACCESS_DETAIL,
      state: {
        isAdd,
        isEditable: isEditMode,
        data: rowData
      }
    });
  }

  return (
    <AdminMainLayoutWrapper data-testid="usergroupList" className='group-access-wrapper'>
      <AdminMainBreadCrumbWrapper>
        {textConstants.GROUP_LIST_PAGE_TITLE}
      </AdminMainBreadCrumbWrapper>
      <ConfigurationListTable
        columnDefs={GROUP_LIST_COLUMNS}
        getConfigList={getGroupList}
        gridName={textConstants.GROUP_GRID_TITLE}
        onCreate={() => showDetailPageHandler(null, true, true)}
        onEdit={showDetailPageHandler}
        onRowDoubleClicked={(params: { data: any }) => showDetailPageHandler(params.data, false)}
        className='right-side-actions-small'
        gridRowId='groupName'
        hasRowAction
        listName='groupConfig'
        hasFilter={false}
        hasDelete={false}
        hasCreate
      />
    </AdminMainLayoutWrapper>
  );
};

export default GroupAccessList;
