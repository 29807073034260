import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { store } from './redux/store/store';
import { ConfigProvider } from 'antd';
import AppWithRouterAccess from './components/AppWithRouterAccess';
import GlobalStyle from './assets/styles/globalStyles';

const toastConfig = {
  autoClose: 3000,
  draggable: true,
  pauseOnHover: true
}
const persistor = persistStore(store);

function App (): JSX.Element {
  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Roboto, sans-serif'
          }
        }}
      >
      <PersistGate loading={null} persistor={persistor}>
        <GlobalStyle />
        <ToastContainer {...toastConfig} theme='colored' />
        <Router>
          <AppWithRouterAccess />
        </Router>
      </PersistGate>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
