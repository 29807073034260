import {
  APPLICATION_DOMAIN_KEY,
  APPLICATION_NAME_KEY,
  BUSINESS_ID_KEY,
  BUSINESS_ID_TWO_KEY,
  COMPONENT_NAME_KEY,
  EXCEPTION_CATEGORY_KEY,
  EXCEPTION_SEVERITY_KEY,
  EXCEPTION_STATUS_KEY,
  EXCEPTION_TYPE_KEY,
  HOST_NAME_KEY,
  ISSUE_GROUP_KEY,
  REPLAY_COUNT_KEY,
  REPLAY_DESTINATION_KEY,
  RESOLVED_KEY,
  SERVICE_NAME_KEY,
  TIMEMARK_KEY,
  TRANSACTION_DOMAIN_KEY,
  TRANSACTION_ID_KEY,
  TRANSACTION_TYPE_KEY
} from '../KeyLabels/commonKeyConstants';
import {
  APPLICATION_NAME_LABEL,
  COMPONENT_NAME_LABEL,
  SERVICE_NAME_LABEL,
  TIME_OCCURED_LABEL,
  HOST_NAME_LABEL,
  BUSINESS_ID_LABEL,
  BUSINESS_ID_TWO_LABEL,
  APPLICATION_DOMAIN_LABEL,
  TRANSACTION_DOMAIN_LABEL,
  TRANSACTION_TYPE_LABEL,
  REPLAY_STATUS_LABEL,
  CATEGORY_LABEL,
  SEVERITY_LABEL,
  TRANSACTION_ID_LABEL,
  TYPE_LABEL,
  ISSUE_GROUP_LABEL,
  REPLAY_COUNT_LABEL,
  REPLAY_DESTINATION_LABEL,
  RESOLVED_LABEL
} from '../KeyLabels/labelConstants';
import { checkBoxFieldCol } from './tableCheckboxColumn';
import ExceptionStatusRenderer from './customRendererForColumns/ExceptionStatusRenderer';
import { convertTimeToTimeZoneForDisplay } from '../../helpers/commonHelper';

const additionalCommonFieldProperties = {
  minWidth: 170,
  filter: true,
  sortable: true,
  suppressMovable: true
};

export const ExceptionSearchResultColumns: any[] = [
  checkBoxFieldCol,
  {
    field: APPLICATION_NAME_KEY,
    headerName: APPLICATION_NAME_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: COMPONENT_NAME_KEY,
    headerName: COMPONENT_NAME_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: SERVICE_NAME_KEY,
    headerName: SERVICE_NAME_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: EXCEPTION_STATUS_KEY,
    headerName: REPLAY_STATUS_LABEL,
    isVisible: true,
    cellRenderer: ExceptionStatusRenderer,
    ...additionalCommonFieldProperties
  },
  {
    field: TIMEMARK_KEY,
    headerName: TIME_OCCURED_LABEL,
    isVisible: true,
    cellRenderer: (params: any) => {
      return convertTimeToTimeZoneForDisplay(params?.value);
    },
    ...additionalCommonFieldProperties
  },
  {
    field: EXCEPTION_CATEGORY_KEY,
    headerName: CATEGORY_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: EXCEPTION_TYPE_KEY,
    headerName: TYPE_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: EXCEPTION_SEVERITY_KEY,
    headerName: SEVERITY_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: TRANSACTION_ID_KEY,
    headerName: TRANSACTION_ID_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: HOST_NAME_KEY,
    headerName: HOST_NAME_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: BUSINESS_ID_KEY,
    headerName: BUSINESS_ID_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: BUSINESS_ID_TWO_KEY,
    headerName: BUSINESS_ID_TWO_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: APPLICATION_DOMAIN_KEY,
    headerName: APPLICATION_DOMAIN_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: TRANSACTION_DOMAIN_KEY,
    headerName: TRANSACTION_DOMAIN_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: TRANSACTION_TYPE_KEY,
    headerName: TRANSACTION_TYPE_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: ISSUE_GROUP_KEY,
    headerName: ISSUE_GROUP_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: REPLAY_COUNT_KEY,
    headerName: REPLAY_COUNT_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: REPLAY_DESTINATION_KEY,
    headerName: REPLAY_DESTINATION_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: RESOLVED_KEY,
    headerName: RESOLVED_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  }
];
