import { produce } from 'immer';
import { DEFAULT_SEARCH_TAB } from '../../../constants/defaultConstants';

export interface LogSearchDefaultTemplate {
  logTabList: any[]
  logTabNextCount?: number
  activeTab?: string
}

const initialState = {
  logTabList: [DEFAULT_SEARCH_TAB],
  logTabNextCount: 2,
  activeTab: 'DefaultSearch'
};

const LogSearchReducer = (state = initialState, action: any): LogSearchDefaultTemplate =>
  produce(state, (draft: LogSearchDefaultTemplate) => {
    switch (action.type) {
      case 'ADD_LOG_TAB':
        draft.logTabList.push(action.payload);
        draft.logTabNextCount = state.logTabNextCount + 1;
        draft.activeTab = action.payload?.key;
        break;
      case 'UPDATE_LOG_TAB_LIST':
        draft.logTabList = action.payload;
        break;
      case 'REMOVE_LOG_TAB':
        draft.logTabList = state.logTabList.filter(
          (tabItem: any) => tabItem.key !== action.payload?.key
        );
        if (state.activeTab === action.payload.key) {
          draft.activeTab = draft.logTabList[draft.logTabList.length - 1]?.key;
        }
        break;
      case 'CHANGE_ACTIVE_LOG_TAB':
        draft.activeTab = action.payload?.key;
        break;
      case 'STORE_LOG_SEARCHED_DATA': {
        const tabIndex: number = state.logTabList.findIndex(
          (tabItem: any) => tabItem.key === action.payload.searchedTabKey
        );
        draft.logTabList[tabIndex].searchResults =
          state.logTabList[tabIndex].searchResults &&
          state.logTabList[tabIndex].searchResults.length > 0
            ? [...state.logTabList[tabIndex].searchResults, ...action.payload.searchResults]
            : [...action.payload.searchResults];
        draft.logTabList[tabIndex].isSearched = true;
        draft.logTabList[tabIndex].payloadForNextCall = action.payload?.payloadForNextCall;
        draft.logTabList[tabIndex].hasNextPage = action.payload?.hasNextPage;
        break;
      }
      case 'STORE_LOG_SEARCHED_QUERY': {
        const tabIndex: number = state.logTabList.findIndex(
          (tabItem: any) => tabItem.key === action.payload.searchedTabKey
        );
        if (tabIndex >= 0) {
          draft.logTabList[tabIndex].searchQuery = action.payload.searchQuery;
          draft.logTabList[tabIndex].searchRecordCount = action.payload.recordCount;
          draft.logTabList[tabIndex].searchResults = [];
        }
        break;
      }
      case 'RESET_LOG_SEARCHED_DATA': {
        const tabIndex: number = state.logTabList.findIndex(
          (tabItem: any) => tabItem.key === action.payload.searchedTabKey
        );
        draft.logTabList[tabIndex].searchResults = [];
        draft.logTabList[tabIndex].firstCallPayload = action.payload.updatedSearchQuery;
        break;
      }
      default:
        return state;
    }
  });

export default LogSearchReducer;
