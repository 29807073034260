import React, { type FC, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox } from 'antd';
import { LogColumn } from '../../../../constants/DetailViewColumns/LogColumnForConfigure';
import { ExceptionColumn } from '../../../../constants/DetailViewColumns/ExceptionColumnForConfigure';
import { DetailBodyItemWrapper, DetailConfigureColumnFooter, DetailConfigureContentWrapper, DetailConfigureRemainingFieldWrapper } from './style';
import { AdditionalInfoText, ErrorMsgText } from '../../../../assets/styles/commonStyled';
import { onlyTwoColumnsCanBeSelectedErrorMsg, popOverButtonSize } from '../../../../constants/commonConstants';
import { defaultSelectedItemsForViewByPopOver } from '../../../../constants/DetailViewColumns/PopOverDefaults';
// import { CalendarIconButton } from '../../../common/Buttons/style';
// import {
//   CalendarOutlined,
//   HourglassOutlined
// } from '@ant-design/icons';
const maxColumnSelectionLimit = 2;

const LogExceptionDetailItemColumnConfigPopOver: FC<{
  logExceptionType?: string
  selectedColumns: Array<{ label: string, value: string }>
  setSelectedColumnsToParent?: any
  dateFormatType?: string
  updateDateFormatTypeFn?: any
}> = ({ logExceptionType, selectedColumns, setSelectedColumnsToParent, dateFormatType, updateDateFormatTypeFn }) => {
  const [isErrorMsgVisible, setErrorMsgVisible] = useState(false);
  const [selectedValueFromCheckBox, setSelectedColumnsFromCheckBox] = useState<Array<{ label: string, value: string }>>([...selectedColumns]);
  const columnsToRender = logExceptionType && logExceptionType?.toString().toLowerCase() === 'log' ? LogColumn : ExceptionColumn;

  const onConfirmBtnHanlder = (): void => {
    if (selectedValueFromCheckBox.length > maxColumnSelectionLimit) {
      setErrorMsgVisible(true);
    } else {
      setErrorMsgVisible(false);
      setSelectedColumnsToParent(selectedValueFromCheckBox);
    }
  }
  const onResetBtnHanlder = (): void => {
    setSelectedColumnsFromCheckBox(defaultSelectedItemsForViewByPopOver);
    setSelectedColumnsToParent(defaultSelectedItemsForViewByPopOver);
  }

  const onColumnCheckBoxChangeHandler = (columnChanged: any, isChecked: boolean): void => {
    if (isChecked) {
      setSelectedColumnsFromCheckBox([...selectedValueFromCheckBox, columnChanged]);
    } else {
      setSelectedColumnsFromCheckBox(selectedValueFromCheckBox.filter((elementInArray: any) => elementInArray.value !== columnChanged.value))
    }
  }
  return (<DetailConfigureContentWrapper>
    {
      columnsToRender.mandatoryFields?.map((elementInArray: any,
        elementIter: number) => {
        return (<DetailBodyItemWrapper
          key={`${String(elementInArray.value)}-${elementIter}`}
          className={elementInArray.value === 'timemark' ? 'justify-content-between' : ''}>
          <span>{elementInArray.label}</span>
          {/* {
            elementInArray.value === 'timemark' &&
            <DetailViewCalendarBtnWrapper>
              <CalendarIconButton
                icon={<CalendarOutlined />}
                size={popOverButtonSize}
                className={dateFormatType === 'calendar' ? 'active' : ''}
                onClick={() => updateDateFormatTypeFn('calendar')}
              />

              <CalendarIconButton
                icon={<HourglassOutlined />}
                size={popOverButtonSize}
                className={dateFormatType === 'quick' ? 'active' : ''}
                onClick={() => updateDateFormatTypeFn('quick')}
              />
            </DetailViewCalendarBtnWrapper>
          } */}
        </DetailBodyItemWrapper>);
      })
    }
    <hr />
    <AdditionalInfoText textStyle="small">
      Select Columns: (Max: {maxColumnSelectionLimit})
    </AdditionalInfoText>
    <DetailConfigureRemainingFieldWrapper>
      {
        columnsToRender.fields.map(columnElement => {
          return (<Checkbox
            checked={selectedValueFromCheckBox.map((element: { value: string }) => element.value)?.includes(columnElement.value)}
            key={columnElement.value}
            onChange={e => { onColumnCheckBoxChangeHandler(columnElement, e.target.checked) }}
          >
            {columnElement.label}</Checkbox>)
        })
      }
    </DetailConfigureRemainingFieldWrapper>
    <>
      {
        isErrorMsgVisible &&
        <ErrorMsgText>{onlyTwoColumnsCanBeSelectedErrorMsg}</ErrorMsgText>
      }
    </>
    <DetailConfigureColumnFooter>
      <Button size={popOverButtonSize} onClick={() => onResetBtnHanlder()}>Reset</Button>
      <Button size={popOverButtonSize} type="primary" onClick={() => onConfirmBtnHanlder()}>Confirm</Button>
    </DetailConfigureColumnFooter>
  </DetailConfigureContentWrapper >
  );
}

LogExceptionDetailItemColumnConfigPopOver.propTypes = {
  logExceptionType: PropTypes.string.isRequired,
  selectedColumns: PropTypes.array.isRequired,
  setSelectedColumnsToParent: PropTypes.func,
  dateFormatType: PropTypes.string,
  updateDateFormatTypeFn: PropTypes.func
};

export default LogExceptionDetailItemColumnConfigPopOver;
