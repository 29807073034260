import React from 'react';
import type { FC } from 'react';
import { AdminMainLayoutWrapper, AdminMainBreadCrumbWrapper } from '../../../assets/styles/commonStyled';
import ExceptionConfigList from '../../../components/AdditionalComponents/DetailSummaryPage/Admin/ExceptionConfig/ExceptionConfigList';
import textConstants from '../../../constants/textConstants';

const ExceptionConfig: FC = () => {
  return (
    <AdminMainLayoutWrapper data-testid="exceptionConfigList">
      <AdminMainBreadCrumbWrapper>
        {textConstants.EXCEPTION_CONFIG_PAGE_TITLE}
      </AdminMainBreadCrumbWrapper>
      <ExceptionConfigList />
    </AdminMainLayoutWrapper>
  );
};

export default ExceptionConfig;
