import styled from 'styled-components';

export const SummaryMainWrapper = styled.div`
    height: 100%;
`;

export const SummaryAppDetailTopWrapper = styled.div`
    padding: 0px 10px;
    margin: 5px;
`;
export const SummaryAppDetailHeaderBarWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
`;
export const SummaryAppDetailWrapper = styled.div`
    padding: 0px 5px;
`;
