import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { CountWrapper, LabelWrapper, ReplayStatusIconWrapper, StatusBarWrapper } from './style';
import { CheckCircleOutlined, HourglassOutlined, HistoryOutlined, ScheduleOutlined, StopOutlined } from '@ant-design/icons';

interface componentProps {
  replayData?: any
}

const replayResolvedKey = 'resolved';
const replayInProgressKey = 'progress';
const replayScheduleKey = 'schedule';
const replayPendingKey = 'pending';
const replayErrorKey = 'error';

const REPLAY_RESOLVED_LABEL = 'Resolved';
const REPLAY_IN_PROGRESS_LABEL = 'In Progress';
const REPLAY_SCHEDULE_LABEL = 'Schedule';
const REPLAY_PENDING_LABEL = 'Pending';
const REPLAY_ERROR_LABEL = 'Error';

const ReplayStatusCountBar: FC<componentProps> = ({ replayData }) => {
  const resolveKey = Object.keys(replayData).find(objectKey => objectKey.toString().toLowerCase().includes(replayResolvedKey))
  const progressKey = Object.keys(replayData).find(objectKey => objectKey.toString().toLowerCase().includes(replayInProgressKey))
  const scheduleKey = Object.keys(replayData).find(objectKey => objectKey.toString().toLowerCase().includes(replayScheduleKey))
  const pendingKey = Object.keys(replayData).find(objectKey => objectKey.toString().toLowerCase().includes(replayPendingKey))
  const errorKey = Object.keys(replayData).find(objectKey => objectKey.toString().toLowerCase().includes(replayErrorKey))

  return <StatusBarWrapper>
    {
      resolveKey &&
      <div>
        <ReplayStatusIconWrapper className={resolveKey}><CheckCircleOutlined /></ReplayStatusIconWrapper>
        <CountWrapper>{replayData[resolveKey]}</CountWrapper>
        <LabelWrapper>{REPLAY_RESOLVED_LABEL}</LabelWrapper>
      </div>
    }
    {
      progressKey &&
      <div>
        <ReplayStatusIconWrapper className={progressKey}>
          <HourglassOutlined />
          {/* <FieldTimeOutlined /> */}
        </ReplayStatusIconWrapper>
        <CountWrapper>{replayData[progressKey]}</CountWrapper>
        <LabelWrapper>{REPLAY_IN_PROGRESS_LABEL}</LabelWrapper>
      </div>
    }
    {
      scheduleKey &&
      <div>
        <ReplayStatusIconWrapper className={scheduleKey}><ScheduleOutlined /></ReplayStatusIconWrapper>
        <CountWrapper>{replayData[scheduleKey]}</CountWrapper>
        <LabelWrapper>{REPLAY_SCHEDULE_LABEL}</LabelWrapper>
      </div>
    }
    {
      pendingKey &&
      <div>
        <ReplayStatusIconWrapper className={pendingKey}><HistoryOutlined /></ReplayStatusIconWrapper>
        <CountWrapper>{replayData[pendingKey]}</CountWrapper>
        <LabelWrapper>{REPLAY_PENDING_LABEL}</LabelWrapper>
      </div>
    }
    {
      errorKey &&
      <div>
        <ReplayStatusIconWrapper className={errorKey}><StopOutlined /></ReplayStatusIconWrapper>
        <CountWrapper>{replayData[errorKey]}</CountWrapper>
        <LabelWrapper>{REPLAY_ERROR_LABEL}</LabelWrapper>
      </div>
    }
  </StatusBarWrapper >;
};

ReplayStatusCountBar.propTypes = {
  replayData: PropTypes.object
};

export default ReplayStatusCountBar;
