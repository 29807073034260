import React from 'react';
import XMLViewer from 'react-xml-viewer'
import JsonView from 'react18-json-view';
import { checkIfDataIsJSON } from './commonHelper';
import 'react18-json-view/src/style.css';
import { DETAIL_VIEW_DATA_MAX_LIMIT } from '../constants/commonConstants';

export const renderDataBasedOnFormat = (data: any, enableEdit: boolean, id: string, setContentRef: any): any => {
  if (data && data !== '') {
    if (data.startsWith('<?xml')) {
      return (
        <div
          contentEditable={enableEdit}
          suppressContentEditableWarning
          style={{ outline: 0 }}
          id={id}
          ref={(ref) => setContentRef(ref)}
        >
          <XMLViewer xml={data} />
        </div>
      );
    } else if (checkIfDataIsJSON(data)) {
      return !enableEdit
        ? (
          <JsonView
            // name={false}
            enableClipboard={false}
            src={JSON.parse(data)}
          // displayObjectSize={false}
          // displayDataTypes={false}
          />
          )
        : (
          <div
            ref={(ref) => setContentRef(ref)}
            contentEditable={enableEdit}
            className="out-line-none"
            suppressContentEditableWarning
            id={id}
          >
            {data}
          </div>
          )
    }
  }
  return (
    <div
      contentEditable={enableEdit}
      suppressContentEditableWarning
      ref={(ref) => setContentRef(ref)}
      id={id}
    >
      {
        data.toString().length > DETAIL_VIEW_DATA_MAX_LIMIT &&
        <>
          {data.toString().substring(0, DETAIL_VIEW_DATA_MAX_LIMIT)}...<a>Read More</a>
        </>
      }
      {
        data.toString().length < DETAIL_VIEW_DATA_MAX_LIMIT &&
        <>
          {data}
        </>
      }
    </div>)
}
