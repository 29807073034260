import { call, put, fork, all, takeLatest } from 'redux-saga/effects';
import type { AllEffect, CallEffect, ForkEffect, PutEffect } from 'redux-saga/effects';
import { createLogger } from 'redux-logger';
import { updateDataLoadingStatus } from '../../actions/CommonConfig/commonConfigActions';
import { setExceptionReportConfigList } from '../../actions/Admin/exceptionReportConfigActions';
import {
  DELETE_EXCEPTION_REPORT_CONFIG,
  GET_EXCEPTION_REPORT_CONFIG_LIST,
  SAVE_EXCEPTION_REPORT_CONFIG
} from '../../actions/ActionTypes/Admin/exceptionReportConfigTypes';
import { API_SUCCESS_STATUS_CODE } from '../../../constants/commonConstants';
import {
  EXCEPTION_REPORT_ID,
  EXCEPTION_REPORT_CONFIG_TAB_KEY
} from '../../../constants/KeyLabels/commonKeyConstants';
import { successNotification } from '../../../components/common/Notification';
import { apiRequest } from '../../../services/axiosInterceptor';
import apiURL from '../../../constants/apiEndpointConstants';
import textConstants from '../../../constants/textConstants';
import routeConstants from '../../../constants/routeConstants';

const callEffect: any = call;

export function * fetchExceptionReportConfigListSaga (): Generator<
CallEffect<any> | PutEffect<any>,
void
> {
  try {
    const config = {
      method: 'get',
      url: apiURL.GET_EXCEPTION_REPORT_CONFIG_LIST
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    const payload = {
      data: response?.data,
      action: 'NEW'
    };

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      yield put(setExceptionReportConfigList(payload));
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}
export function * saveExceptionReportConfigSaga ({
  payload
}: any): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: payload?.isAdd ? 'POST' : 'PUT',
      url: apiURL.SAVE_UPDATE_EXCEPTION_REPORT_CONFIG,
      data: payload?.data
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        data: response?.data,
        action: payload?.isAdd ? 'ADD' : 'UPDATE',
        ...(!payload?.isAdd && {
          keyName: EXCEPTION_REPORT_ID,
          keyValue: response.data[EXCEPTION_REPORT_ID]
        })
      };

      yield put(setExceptionReportConfigList(storePayload));

      if (payload?.callBackFunc) {
        payload.callBackFunc();
      }

      successNotification(`Exception Report ${payload?.isAdd ? 'added' : 'updated'} successfully`);
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * deleteExceptionReportConfigSaga ({ payload }: any): any {
  try {
    const config = {
      method: 'DELETE',
      url: apiURL.DELETE_EXCEPTION_REPORT_CONFIG,
      data: payload?.data
    };

    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        action: 'DELETE',
        keyName: EXCEPTION_REPORT_ID,
        keyValue: response.data[0][EXCEPTION_REPORT_ID]
      };

      yield put(setExceptionReportConfigList(storePayload));

      if (payload?.history) {
        payload.history.push({
          pathname: routeConstants.ADMIN_REPORT_CONFIG,
          state: {
            activeTab: EXCEPTION_REPORT_CONFIG_TAB_KEY
          }
        });
      }

      successNotification(textConstants.EXCEPTION_REPORT_CONFIG_DELETE_SUCCESS_MESSAGE);
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * getExceptionReportConfigList (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_EXCEPTION_REPORT_CONFIG_LIST, fetchExceptionReportConfigListSaga);
}

export function * deleteExceptionReportConfigItem (): Generator<any> {
  yield takeLatest(DELETE_EXCEPTION_REPORT_CONFIG, deleteExceptionReportConfigSaga);
}

export function * saveExceptionReportConfigItem (): Generator<any> {
  yield takeLatest(SAVE_EXCEPTION_REPORT_CONFIG, saveExceptionReportConfigSaga);
}

export default function * root (): Generator<AllEffect<any>> {
  yield all([
    fork(getExceptionReportConfigList),
    fork(deleteExceptionReportConfigItem),
    fork(saveExceptionReportConfigItem)
  ]);
}
