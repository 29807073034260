import {
  GET_APPLICATION_DETAILS,
  SET_APPLICATION_DETAILS,
  GET_APPLICATION_EXCEPTION_CATEGORY,
  GET_ALL_EXCEPTION_CATEGORY,
  GET_APPLICATION_EXCEPTION_SEVERITY,
  GET_ALL_EXCEPTION_SEVERITY,
  GET_APPLICATION_EXCEPTION_TYPE,
  GET_ALL_EXCEPTION_TYPE,
  SET_APPLICATION_CTS_DATA,
  GET_APPLICATION_SECTOR_LIST,
  SET_APPLICATION_SECTOR_LIST,
  DELETE_APPLICATION_DETAILS,
  SAVE_APPLICATION_DETAILS
} from '../ActionTypes/Admin/applicationConfigTypes';

interface GetApplicationDetailsAction {
  type: string
}

interface SetApplicationDetailsAction {
  type: string
  data: any
}

interface ApplicationDetailsAction {
  type: string
  data: any
}

export function getApplicationDetails (): GetApplicationDetailsAction {
  return {
    type: GET_APPLICATION_DETAILS
  };
}

export function applicationDataStore (data: any): SetApplicationDetailsAction {
  return {
    type: SET_APPLICATION_DETAILS,
    data
  };
}

export function getAllExceptionCategory (): any {
  return {
    type: GET_ALL_EXCEPTION_CATEGORY
  };
}

export function getApplicationExceptionCategory (data: any): ApplicationDetailsAction {
  return {
    type: GET_APPLICATION_EXCEPTION_CATEGORY,
    data
  };
}

export function getAllExceptionSeverity (): any {
  return {
    type: GET_ALL_EXCEPTION_SEVERITY
  };
}

export function getApplicationExceptionSeverity (data: any): ApplicationDetailsAction {
  return {
    type: GET_APPLICATION_EXCEPTION_SEVERITY,
    data
  };
}

export function getAllExceptionType (): any {
  return {
    type: GET_ALL_EXCEPTION_TYPE
  };
}

export function getApplicationExceptionType (data: any): ApplicationDetailsAction {
  return {
    type: GET_APPLICATION_EXCEPTION_TYPE,
    data
  };
}

export function setApplicationCTSData (payload: any): any {
  return {
    type: SET_APPLICATION_CTS_DATA,
    payload
  };
}

export function getApplicationSectorList (): GetApplicationDetailsAction {
  return {
    type: GET_APPLICATION_SECTOR_LIST
  };
}
export function setApplicationSectorList (data: any): any {
  return {
    type: SET_APPLICATION_SECTOR_LIST,
    data
  };
}

export function updateApplicationDetails (data: any, callBackFunc?: any, isAdd?: boolean): any {
  return {
    type: SAVE_APPLICATION_DETAILS,
    data,
    isAdd,
    callBackFunc
  };
}

export function deleteApplicationConfig (data: any): any {
  return {
    type: DELETE_APPLICATION_DETAILS,
    data
  };
}

export function addApplicationDetails (data: any, callBackFunc?: any, isAdd?: boolean): any {
  return {
    type: SAVE_APPLICATION_DETAILS,
    data,
    isAdd,
    callBackFunc
  };
}
