import React, { useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Radio, Row } from 'antd';
import FloatingLabel from '../../../../common/FloatingLabel';
import { columnAlertFormBasicFields, columnAlertFormIfFields, columnAlertFormNotificationFields, columnAlertFormThenFields } from '../../../../../constants/FormFields/AdminForm/columnAlertFormFields';
import { ApplicationFormItemsLayout } from '../../../../../constants/FormLayout/ApplicationFormLayout';
import textConstants from '../../../../../constants/textConstants';
import { applicationDataHelper } from '../../../../../helpers/applicationDataHelper';
import { RowCriteriaWithBorderWrapper, RowCriteriaWithOutBorderWrapper } from '../../../../../assets/styles/commonStyled';
import { FormWrapper, RadioFormItemRowWrapper } from '../style';
import { columnAlertColumnNameOptions, snowServiceNameOptions } from '../../../../../constants/DropDownOptions/adminConfig';
import type { AdminConfigFormPropsType } from '../../../../../constants/Interfaces/AdminFormConfigTypes';
import Footer from '../../../../common/Footer';

const columnSpan = ApplicationFormItemsLayout.columnSpan

const ColumnAlertConfigForm: FC<AdminConfigFormPropsType> = ({ data, isDrawerView, onSave, onCancel, isEditable }) => {
  const [form] = Form.useForm();
  const { enableSnowNotificationField, sysClassNameField, snowParameterField, enableGroupNotificationField, notificationGroupField, enableMailNotificationField, emailAddressField } = columnAlertFormNotificationFields;

  const applicationNameChangedValue = Form.useWatch('applicationName', form);

  const snowNotificationChangedValue = Form.useWatch(enableSnowNotificationField.name, form);
  const groupNotificationChangedValue = Form.useWatch(enableGroupNotificationField.name, form);
  const enableMailNotificationChangedValue = Form.useWatch(enableMailNotificationField.name, form);

  const onSaveHandler = (isClosable?: boolean): void => {
    form.validateFields()
      .then(async values => {
        onSave({ ...data, ...values }, isClosable);
      }).catch(error => console.log(error));
  }

  useEffect(() => {
    if (isEditable) {
      form.setFieldsValue({ ...data });
    }
  }, [data]);

  return (
        <>
            <FormWrapper>
                <Form
                    layout={'vertical'}
                    form={form}
                    initialValues={{
                      enableMailNotification: 0,
                      enableGroupNotification: 0,
                      enableSnowNotification: 0,
                      ...data
                    }}
                    data-testid="testid-column-alert-config-form"
                >
                    <Row gutter={ApplicationFormItemsLayout.rowGutter} >
                        {
                            columnAlertFormBasicFields?.map((formField: any) => {
                              return (
                                    <Col key={formField.name} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                                        <Form.Item name={formField.name} rules={formField.rules}>
                                            <FloatingLabel
                                                label={formField.label}
                                                id={formField.id}
                                                type={formField.type}
                                                disabled={(data?.applicationName ?? isEditable) && (formField.disableOnEdit ?? false)}
                                                required={formField.required}
                                                {...(formField.type === 'select' && { showSearch: true, options: applicationDataHelper.getApplicationNames([]) })}
                                            />
                                        </Form.Item>
                                    </Col>
                              )
                            })
                        }
                    </Row>
                    <RowCriteriaWithBorderWrapper gutter={16} data-title={textConstants.COLUMN_ALERT_CONFIG_DETAIL_IF_CRITERIA_LABEL}>
                        {
                            columnAlertFormIfFields.map((formField: any) => {
                              return (
                                    <Col key={formField.name} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                                        <Form.Item name={formField.name} rules={formField.rules}>
                                            <FloatingLabel
                                                label={formField.label}
                                                id={formField.id}
                                                type={formField.type}
                                                disabled={isEditable && (formField.disableOnEdit ?? false)}
                                                required={formField.required}
                                                {...(formField.type === 'select' && { options: applicationDataHelper.getDataFromApplicationList(applicationNameChangedValue, formField.name) })}
                                            />
                                        </Form.Item>
                                    </Col>
                              )
                            })
                        }
                    </RowCriteriaWithBorderWrapper>
                    <RowCriteriaWithOutBorderWrapper gutter={ApplicationFormItemsLayout.rowGutter} data-title={textConstants.COLUMN_ALERT_CONFIG_DETAIL_THEN_CRITERIA_LABEL}>
                        {
                            columnAlertFormThenFields.map((formField: any) => {
                              return (
                                    <Col key={formField.name} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                                        <Form.Item name={formField.name} rules={formField.rules}>
                                            <FloatingLabel
                                                label={formField.label}
                                                id={formField.id}
                                                type={formField.type}
                                                disabled={isEditable && (formField.disableOnEdit ?? false)}
                                                required={formField.required}
                                                {...(formField.type === 'select' && { options: columnAlertColumnNameOptions })}
                                            />
                                        </Form.Item>
                                    </Col>
                              )
                            })
                        }
                    </RowCriteriaWithOutBorderWrapper>
                    <RowCriteriaWithOutBorderWrapper className='conditional-row notification-main-row-wrapper'
                        style={{ display: 'block' }} data-title={textConstants.COLUMN_ALERT_CONFIG_NOTIFICATION_DETAIL_CRITERIA_LABEL}>
                        <RadioFormItemRowWrapper gutter={ApplicationFormItemsLayout.rowGutter}>
                            <Col key={enableSnowNotificationField.name} md={columnSpan.md} xxl={columnSpan.xxl}>
                                <Form.Item
                                    label={enableSnowNotificationField.label} name={enableSnowNotificationField.name}>
                                    <Radio.Group>
                                        <Radio value={1}>YES</Radio>
                                        <Radio value={0}>NO</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {
                                snowNotificationChangedValue === 1 &&
                                <>
                                    <Col key={sysClassNameField.name} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                                        <Form.Item name={sysClassNameField.name} rules={sysClassNameField.rules}>
                                            <FloatingLabel
                                                label={sysClassNameField.label}
                                                id={sysClassNameField.id}
                                                type={sysClassNameField.type}
                                                options={snowServiceNameOptions}
                                                required
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col key={snowParameterField.name} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                                        <Form.Item name={snowParameterField.name} rules={snowParameterField.rules}>
                                            <FloatingLabel
                                                label={snowParameterField.label}
                                                id={snowParameterField.id}
                                                type={snowParameterField.type}
                                                required
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                            }
                        </RadioFormItemRowWrapper>
                        <RadioFormItemRowWrapper gutter={ApplicationFormItemsLayout.rowGutter}>
                            <Col key={enableGroupNotificationField.name} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                                <Form.Item label={enableGroupNotificationField.label}
                                    name={enableGroupNotificationField.name}>
                                    <Radio.Group>
                                        <Radio value={1}>YES</Radio>
                                        <Radio value={0}>NO</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {
                                groupNotificationChangedValue === 1 &&
                                <Col key={notificationGroupField.name} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                                    <Form.Item name={notificationGroupField.name} rules={notificationGroupField.rules}>
                                        <FloatingLabel
                                            label={notificationGroupField.label}
                                            id={notificationGroupField.id}
                                            type={notificationGroupField.type}
                                            required
                                        />
                                    </Form.Item>
                                </Col>
                            }
                        </RadioFormItemRowWrapper>
                        <RadioFormItemRowWrapper gutter={ApplicationFormItemsLayout.rowGutter}>
                            <Col key={enableMailNotificationField.name} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                                <Form.Item label={enableMailNotificationField.label} name={enableMailNotificationField.name}>
                                    <Radio.Group>
                                        <Radio value={1}>YES</Radio>
                                        <Radio value={0}>NO</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {
                                enableMailNotificationChangedValue === 1 &&
                                <Col key={emailAddressField.name} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                                    <Form.Item name={emailAddressField.name} rules={emailAddressField.rules}>
                                        <FloatingLabel
                                            label={emailAddressField.label}
                                            id={emailAddressField.id}
                                            type={emailAddressField.type}
                                            required
                                        />
                                    </Form.Item>
                                </Col>
                            }
                        </RadioFormItemRowWrapper>
                    </RowCriteriaWithOutBorderWrapper>
                </Form>
            </FormWrapper>
            <Footer
                className={`grey-theme ${isDrawerView ? 'add-form-footer' : ''}`}
                save
                saveWithClose={!isEditable}
                cancel
                onSave={() => onSaveHandler(false)}
                onSaveClose={() => onSaveHandler(true)}
                onCancel={onCancel}
            />
        </>);
};

ColumnAlertConfigForm.defaultProps = {
  isEditable: true
};

ColumnAlertConfigForm.propTypes = {
  data: PropTypes.object,
  isDrawerView: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  isEditable: PropTypes.bool
};

export default ColumnAlertConfigForm;
