export const GET_LOG_TABS_LIST = 'GET_LOG_TABS_LIST';
export const ADD_LOG_TAB = 'ADD_LOG_TAB';
export const REMOVE_LOG_TAB = 'REMOVE_LOG_TAB';
export const CHANGE_ACTIVE_LOG_TAB = 'CHANGE_ACTIVE_LOG_TAB';
export const UPDATE_LOG_TAB_LIST = 'UPDATE_LOG_TAB_LIST';

export const FETCH_SEARCH_DATA = 'FETCH_SEARCH_DATA';
export const STORE_LOG_SEARCHED_DATA = 'STORE_LOG_SEARCHED_DATA';
export const STORE_LOG_SEARCHED_QUERY = 'STORE_LOG_SEARCHED_QUERY';
export const CANCEL_SEARCH_APICALL = 'CANCEL_SEARCH_APICALL';

export const RESET_LOG_SEARCHED_DATA = 'RESET_LOG_SEARCHED_DATA';
