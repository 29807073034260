import { APPLICATION_DOMAIN_KEY, APPLICATION_NAME_KEY, BUSINESS_ID_KEY, BUSINESS_ID_TWO_KEY, COMPONENT_NAME_KEY, HOST_NAME_KEY, LOG_LEVEL_KEY, SERVICE_NAME_KEY, STATUS_KEY, TIMEMARK_KEY, TIME_DURATION_KEY, TRANSACTION_DOMAIN_KEY, TRANSACTION_ID_KEY, TRANSACTION_TYPE_KEY } from '../KeyLabels/commonKeyConstants';
import { APPLICATION_DOMAIN_LABEL, APPLICATION_NAME_LABEL, BUSINESS_ID_LABEL, BUSINESS_ID_TWO_LABEL, COMPONENT_NAME_LABEL, HOST_NAME_LABEL, LOG_LEVEL_LABEL, SERVICE_NAME_LABEL, STATUS_LABEL, TIME_DURATION_LABEL, TIME_OCCURED_LABEL, TRANSACTION_DOMAIN_LABEL, TRANSACTION_ID_LABEL, TRANSACTION_TYPE_LABEL } from '../KeyLabels/labelConstants';

export const LogColumn = {
  mandatoryFields: [
    { label: APPLICATION_NAME_LABEL, value: APPLICATION_NAME_KEY },
    { label: TIME_OCCURED_LABEL, value: TIMEMARK_KEY }
  ],
  fields: [
    { label: COMPONENT_NAME_LABEL, value: COMPONENT_NAME_KEY },
    { label: SERVICE_NAME_LABEL, value: SERVICE_NAME_KEY },
    { label: STATUS_LABEL, value: STATUS_KEY },
    { label: HOST_NAME_LABEL, value: HOST_NAME_KEY },
    { label: BUSINESS_ID_LABEL, value: BUSINESS_ID_KEY },
    { label: BUSINESS_ID_TWO_LABEL, value: BUSINESS_ID_TWO_KEY },
    { label: TRANSACTION_ID_LABEL, value: TRANSACTION_ID_KEY },
    { label: APPLICATION_DOMAIN_LABEL, value: APPLICATION_DOMAIN_KEY },
    { label: TRANSACTION_DOMAIN_LABEL, value: TRANSACTION_DOMAIN_KEY },
    { label: TRANSACTION_TYPE_LABEL, value: TRANSACTION_TYPE_KEY },
    { label: LOG_LEVEL_LABEL, value: LOG_LEVEL_KEY },
    { label: TIME_DURATION_LABEL, value: TIME_DURATION_KEY }
  ]
};
