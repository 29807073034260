import { call, put, fork, all, takeLatest, takeEvery } from 'redux-saga/effects';
import type { AllEffect, CallEffect, ForkEffect, PutEffect } from 'redux-saga/effects';
import { createLogger } from 'redux-logger';
import { updateDataLoadingStatus } from '../../../actions/CommonConfig/commonConfigActions';
import {
  DELETE_GROUP_APPLICATION_ACCESS,
  GET_GROUP_APPLICATION_ACCESS_LIST,
  GET_GROUP_LIST,
  SAVE_GROUP_APPLICATION_ACCESS,
  SAVE_GROUP_CONFIG
} from '../../../actions/ActionTypes/AccessManagement/GroupAccess/groupAccessTypes';
import { API_SUCCESS_STATUS_CODE } from '../../../../constants/commonConstants';
import { GROUP_APPLICATION_ACCESS_KEY, GROUP_CONFIG_KEY } from '../../../../constants/KeyLabels/commonKeyConstants';
import { successNotification } from '../../../../components/common/Notification';
import { apiRequest } from '../../../../services/axiosInterceptor';
import apiURL from '../../../../constants/apiEndpointConstants';
import { getGroupList, setGroupApplicationAccessList, setGroupList } from '../../../actions/AccessManagement/GroupAccess/groupAccessActions';
import textConstants from '../../../../constants/textConstants';

const callEffect: any = call;

export function * fetchGroupListSaga (): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: 'get',
      url: apiURL.GET_GROUP_LIST
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    const payload = {
      data: response?.data,
      action: 'NEW'
    };

    if (response.status === API_SUCCESS_STATUS_CODE) {
      yield put(setGroupList(payload));
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * saveGroupConfigSaga ({
  payload
}: any): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: payload?.isAdd ? 'POST' : 'PUT',
      url: apiURL.GET_GROUP_LIST,
      data: payload.data
    };

    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        data: response?.data,
        action: payload?.isAdd ? 'ADD' : 'UPDATE',
        ...(!payload?.isAdd && {
          keyName: GROUP_CONFIG_KEY,
          keyValue: response.data[GROUP_CONFIG_KEY]
        })
      };
      yield put(setGroupList(storePayload));

      if (payload?.callBackFunc) {
        payload.callBackFunc();
      }

      successNotification(`Group ${payload?.isAdd ? 'Added' : 'Updated'} Successfully`);
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * saveGroupApplicationAccessSaga ({
  payload
}: any): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: payload?.isAdd ? 'POST' : 'PUT',
      url: apiURL.GROUP_ACCESS_CONFIG,
      data: payload.data
    };

    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        data: response?.data,
        action: payload?.isAdd ? 'ADD' : 'UPDATE_MULTIPLE',
        ...(!payload?.isAdd && {
          keyName: GROUP_APPLICATION_ACCESS_KEY
        })
      };
      yield put(setGroupApplicationAccessList(storePayload));

      if (payload?.callBackFunc) {
        if (payload?.isAdd) {
          yield put(getGroupList());
        }
        payload.callBackFunc();
      }

      successNotification(`Group Application Access ${payload?.isAdd ? 'Added' : 'Updated'} Successfully`);
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * deleteGroupApplicationAccessSaga ({
  payload
}: any): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: 'DELETE',
      url: apiURL.GROUP_ACCESS_CONFIG,
      data: payload.data
    };

    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        data: response?.data,
        action: 'DELETE_MULTIPLE',
        keyName: GROUP_APPLICATION_ACCESS_KEY
      };
      yield put(setGroupApplicationAccessList(storePayload));

      if (payload?.callBackFunc) {
        yield put(getGroupList());
        payload.callBackFunc();
      }
      successNotification(textConstants.GROUP_APPLICATION_ACCESS_LIST_DELETE_SUCCESS_MESSAGE);
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * fetchGroupApplicationAccessListSaga (): Generator<
CallEffect<any> | PutEffect<any>,
void
> {
  try {
    const config = {
      method: 'get',
      url: apiURL.GET_GROUP_ACCESS_LIST
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    const payload = {
      data: response?.data,
      action: 'NEW'
    };

    if (response.status === API_SUCCESS_STATUS_CODE) {
      yield put(setGroupApplicationAccessList(payload));
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * saveUpdateGroupApplicationAccessSaga (): Generator<ForkEffect<never>> {
  yield takeEvery(SAVE_GROUP_APPLICATION_ACCESS, saveGroupApplicationAccessSaga);
}

export function * saveUpdateGroupConfig (): Generator<ForkEffect<never>> {
  yield takeLatest(SAVE_GROUP_CONFIG, saveGroupConfigSaga);
}

export function * getGroupListConfig (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_GROUP_LIST, fetchGroupListSaga);
}

export function * getGroupApplicationAccessList (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_GROUP_APPLICATION_ACCESS_LIST, fetchGroupApplicationAccessListSaga);
}

export function * deleteGroupApplicationAccessListItem (): Generator<ForkEffect<never>> {
  yield takeLatest(DELETE_GROUP_APPLICATION_ACCESS, deleteGroupApplicationAccessSaga);
}

export default function * root (): Generator<AllEffect<any>> {
  yield all([
    fork(getGroupListConfig),
    fork(saveUpdateGroupConfig),
    fork(saveUpdateGroupApplicationAccessSaga),
    fork(getGroupApplicationAccessList),
    fork(deleteGroupApplicationAccessListItem)
  ]);
}
