import { produce } from 'immer';
import { DEFAULT_SEARCH_TAB } from '../../../constants/defaultConstants';

type IObjectKeys = Record<string, any>;

export interface ExceptionSearchDefaultTemplate extends IObjectKeys {
  exceptionTabList: any[]
  exceptionTabNextCount?: number
  activeTab?: string
}

const initialState = {
  exceptionTabList: [DEFAULT_SEARCH_TAB],
  exceptionTabNextCount: 2,
  activeTab: 'DefaultSearch'
};

const ExceptionSearchReducer = (
  state = initialState,
  action: any
): ExceptionSearchDefaultTemplate =>
  produce(state, (draft: ExceptionSearchDefaultTemplate) => {
    switch (action.type) {
      case 'ADD_EXCEPTION_TAB':
        draft.exceptionTabList.push(action.payload);
        draft.exceptionTabNextCount = state.exceptionTabNextCount + 1;
        draft.activeTab = action.payload?.key;
        break;
      case 'UPDATE_EXCEPTION_TAB_LIST':
        draft.exceptionTabList = action.payload;
        break;
      case 'REMOVE_EXCEPTION_TAB':
        draft.exceptionTabList = state.exceptionTabList.filter(
          (tabItem: any) => tabItem.key !== action.payload?.key
        );
        if (state.activeTab === action.payload.key) {
          draft.activeTab = draft.exceptionTabList[draft.exceptionTabList.length - 1]?.key;
        }
        break;
      case 'CHANGE_ACTIVE_EXCEPTION_TAB':
        draft.activeTab = action.payload?.key;
        break;
      case 'STORE_EXCEPTION_SEARCHED_DATA': {
        const tabIndex: number = state.exceptionTabList.findIndex(
          (tabItem: any) => tabItem.key === action.payload.searchedTabKey
        );
        draft.exceptionTabList[tabIndex].searchResults =
          state.exceptionTabList[tabIndex].searchResults &&
          state.exceptionTabList[tabIndex].searchResults.length > 0
            ? [...state.exceptionTabList[tabIndex].searchResults, ...action.payload.searchResults]
            : [...action.payload.searchResults];
        draft.exceptionTabList[tabIndex].isSearched = true;
        draft.exceptionTabList[tabIndex].payloadForNextCall = action.payload?.payloadForNextCall;
        draft.exceptionTabList[tabIndex].hasNextPage = action.payload?.hasNextPage;
        break;
      }
      case 'STORE_EXCEPTION_SEARCHED_QUERY': {
        const tabIndex: number = state.exceptionTabList.findIndex(
          (tabItem: any) => tabItem.key === action.payload.searchedTabKey
        );
        if (tabIndex >= 0) {
          draft.exceptionTabList[tabIndex].searchQuery = action.payload?.searchQuery;
          draft.exceptionTabList[tabIndex].searchRecordCount = action.payload.recordCount;
          draft.exceptionTabList[tabIndex].searchResults = [];
        }
        break;
      }
      case 'RESET_EXCEPTION_SEARCHED_DATA': {
        const tabIndex: number = state.exceptionTabList.findIndex(
          (tabItem: any) => tabItem.key === action.payload.searchedTabKey
        );
        draft.exceptionTabList[tabIndex].searchResults = [];
        draft.exceptionTabList[tabIndex].firstCallPayload = action.payload.updatedSearchQuery;
        break;
      }
      default:
        return state;
    }
  });

export default ExceptionSearchReducer;
