import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col, Collapse, Form, Radio, Row } from 'antd';
import type { AdminConfigFormPropsType } from '../../../../../constants/Interfaces/AdminFormConfigTypes';
import type { FormFieldType } from '../../../../../constants/Interfaces/FormFieldTypes';
import { userAccessFormFields } from '../../../../../constants/FormFields/AccessManagementForm/userAccessFormFields';
import FloatingLabel from '../../../../common/FloatingLabel';
import Footer from '../../../../common/Footer';
import ApplicationAccess from '../../../../common/AccessComponent/ApplicationAccess';
import { createApplicationAccessData, mergeApplicationAccess } from '../../../../../helpers/commonHelper';
import AddConfigDrawer from '../../../Drawer/AddConfigDrawer';
import { SelectGroupDrawerWrapper } from './style';
import UserApplicationGroupSelectionForm from './UserApplicationGroupSelectionForm';
import { getGroupApplicationAccessList } from '../../../../../redux/actions/AccessManagement/GroupAccess/groupAccessActions';
import textConstants from '../../../../../constants/textConstants';
import { mirrorUserAccessRequestHandler } from '../../../../../services/userServiceHandler';

const userApplicationAccessField = 'roleAccess'
const UserAccessForm: FC<AdminConfigFormPropsType> = ({ isEditable, data, onSave, onCancel }) => {
  const dispatch = useDispatch();
  const globalGroupState = useSelector((state: any) => state?.groupConfig);
  const [form] = Form.useForm();

  const [showSelectGroupSelectionModal, setShowSelectGroupSelectionModal] = useState(false);
  const [groupOptionListForModal, setGroupOptionListForModal] = useState([]);
  const [userDetails, setUserDetails] = useState<object>();
  const [isFormClosable, setFormClosableStatus] = useState<boolean>(false);
  const [isMirrorRequestCompleted, setMirrorAccessRequestStatus] = useState<boolean | null>(null);

  const [mirrorData, setMirrorData] = useState<any>({});

  const userApplicationAccessList = Form.useWatch(userApplicationAccessField, form);

  useEffect(() => {
    if (globalGroupState?.groupApplicationAccessList?.length === 0) {
      dispatch(getGroupApplicationAccessList());
    }
  }, []);

  const onSaveHandler = (isClosable: boolean): void => {
    form.validateFields()
      .then(async values => {
        values = {
          ...values,
          [userApplicationAccessField]: (values?.roleAccess?.length > 0) ? generateAccessPayload(values[userApplicationAccessField]) : {}
        }
        setFormClosableStatus(isClosable);
        generateGroupAccess(values, isClosable);
      }).catch(error => console.log(error));
  }

  const generateAccessPayload = (accessList: any[]): object => {
    const obj: any = {};
    accessList?.forEach((val: { applicationName: string, accessList: any[] }) => {
      obj[val.applicationName] = val.accessList;
    });
    return obj;
  }

  const generateGroupAccess = (payload: any, isClosable: boolean): any => {
    // function to generate group lists, if multiple groups are available for single application and role - open modal to select group
    // else select group name.
    const applicationAccessData: any = [];
    if (!isEditable && !isMirrorRequestCompleted) {
      Object.keys(payload.roleAccess).forEach((appNm) => {
        payload.roleAccess[appNm].forEach((access: string) => {
          const obj: any = {};
          obj.applicationName = appNm;
          obj.accessName = access;
          applicationAccessData.push(obj);
        });
      });
    } else {
      const userData = {
        ...data,
        ...(isMirrorRequestCompleted && { roleAccess: mirrorData?.roleAccess })
      }
      // edit details
      const newApplicationAccessObj: any = {};
      if (payload.roleAccess) {
        Object.keys(payload.roleAccess).forEach((role) => {
          if (
            userData?.roleAccess &&
            userData?.roleAccess[role]
          ) {
            payload.roleAccess[role].forEach((value: any) => {
              if (!userData?.roleAccess[role].includes(value)) {
                if (!newApplicationAccessObj[role]) {
                  newApplicationAccessObj[role] = [value];
                } else {
                  newApplicationAccessObj[role].push(value);
                }
              }
            });
          } else {
            newApplicationAccessObj[role] = payload.roleAccess[role];
          }
        });
      }
      if (Object.keys(newApplicationAccessObj).length > 0) {
        Object.keys(newApplicationAccessObj).forEach((appNm) => {
          newApplicationAccessObj[appNm].forEach((access: string) => {
            const obj: any = {};
            obj.applicationName = appNm;
            obj.accessName = access;
            applicationAccessData.push(obj);
          });
        });
      }
    }

    setUserDetails(payload);
    const applicationGroupList: any = [];
    applicationAccessData?.forEach((val: { applicationName: any, accessName: any }) => {
      const finalObj: any = {};
      const filterData = globalGroupState?.groupApplicationAccessList?.filter(
        (appData: { applicationName: any, accessName: any }) =>
          appData.applicationName === val.applicationName &&
          val.accessName === appData.accessName
      );

      finalObj.applicationName = val.applicationName;
      finalObj.accessName = val.accessName;
      finalObj.groupName = filterData;
      applicationGroupList.push(finalObj);
    });

    const selectGroupDataFromUser: any = [];
    const selectedRoleGroup: any[] = isMirrorRequestCompleted ? [...mirrorData.groupAccess] : [];
    let isSelectGroupModalOpen = false;
    // selecting group for each application
    applicationGroupList.forEach((applicationAccessElement: { groupName: string | any[], accessName: string, applicationName: string }) => {
      if (applicationAccessElement.groupName.length > 1) {
        selectGroupDataFromUser.push(applicationAccessElement);
        isSelectGroupModalOpen = true;
      }
      if (applicationAccessElement.groupName.length === 1) {
        selectedRoleGroup.push(applicationAccessElement.groupName[0]);
      }
      if (applicationAccessElement.groupName.length === 0) {
        const obj: any = {};
        obj.accessName = applicationAccessElement.accessName;
        obj.applicationName = applicationAccessElement.applicationName;
        obj.groupName =
          applicationAccessElement.applicationName +
          '_' +
          applicationAccessElement.accessName;
        selectedRoleGroup.push(obj);
      }
    });

    // if multiple group options are there open group selection modal
    if (isSelectGroupModalOpen) {
      form.setFieldValue('groupAccess', selectedRoleGroup);
      setGroupOptionListForModal(selectGroupDataFromUser);
      setShowSelectGroupSelectionModal(isSelectGroupModalOpen);
    } else {
      // save details, as there are no options for group selection.
      onSave({ ...payload, groupAccess: selectedRoleGroup }, isClosable)
    }
  }

  const onSaveGroupSelectionModalHandler = (groupSelectedData: any): void => {
    const groupAccessData = groupSelectedData?.map((groupItem: { selectedGroup: any }) => groupItem?.selectedGroup);

    setShowSelectGroupSelectionModal(false);
    onSave({
      ...userDetails,
      groupAccess: [...form.getFieldValue('groupAccess'), ...groupAccessData]
    }, isFormClosable);
  }

  const onCopyUserAccessHandler = async (mirrorId: string): Promise<any> => {
    setMirrorAccessRequestStatus(false);
    const respData = await mirrorUserAccessRequestHandler(mirrorId);
    const roleAccessData = createApplicationAccessData(respData?.roleAccess ?? []);

    const updatedAccessDataArr = mergeApplicationAccess(userApplicationAccessList, roleAccessData);

    setMirrorData(respData);
    setMirrorAccessRequestStatus(true);
    form.setFieldValue('roleAccess', updatedAccessDataArr);
  }

  const renderField = (field: FormFieldType): any => {
    switch (field.type) {
      case 'radio':
        return (
        <Form.Item name={field.name} label={field.label} className='mb-0'>
          <Radio.Group>
            <Radio value='Y'>Enable</Radio>
            <Radio value={'N'}>Disable</Radio>
          </Radio.Group>
        </Form.Item>
        );
      default:
        return (
        <Form.Item name={field.name} rules={field.rules}>
          <FloatingLabel
            label={field.label}
            type={field.type}
            id={field.id}
            disabled={isEditable && field.disableOnEdit}
            required={field?.required}
            data-testid={field?.id}
          />
        </Form.Item>
        );
    }
  };

  return (
    <>
      <>
        <Form
          form={form}
          initialValues={{
            adminAccess: 'N',
            bulkReply: 'N',
            ...data,
            ...(data?.roleAccess && { roleAccess: createApplicationAccessData(data?.roleAccess) })
          }}
          data-testid='userAccessForm'
          className='collapsible-form'
          layout='vertical'
        >
          <Collapse
            defaultActiveKey={['1']}
            expandIconPosition='end'
            ghost
            items={[{
              key: '1',
              label: '',
              children: (
                <Row
                  gutter={{ lg: 16, xl: 16, xxl: 28 }}
                >
                  {userAccessFormFields.map((formField: FormFieldType) => {
                    return (
                      <Col key={formField.id} xs={24} md={6} xl={5}>
                        {renderField(formField)}
                      </Col>
                    );
                  })}
                </Row>
              )
            }]}
          />
          <ApplicationAccess
            formRef={form}
            fieldName={userApplicationAccessField}
            onCopyUserAccessHandler={onCopyUserAccessHandler}
            selectedApplication={userApplicationAccessList}
            isMirrorRequestCompleted={isMirrorRequestCompleted}
            isViewMode={false}
            isMultiSelect
          />
        </Form>
        <Footer
          className='grey-theme add-form-footer'
          onSave={() => onSaveHandler(false)}
          onSaveClose={() => onSaveHandler(true)}
          onCancel={onCancel}
          saveWithClose={!isEditable}
          save
          cancel
        />
      </>
      {
        showSelectGroupSelectionModal &&
        <SelectGroupDrawerWrapper>
          <AddConfigDrawer
            isOpen={showSelectGroupSelectionModal}
            dataTestId='test-id-group-config-drawer'
            title={textConstants.USER_DETAIL_GROUP_MODAL_TITLE}
            onCancel={() => setShowSelectGroupSelectionModal(false)}
          >
            <UserApplicationGroupSelectionForm
              applicationGroupAllList={groupOptionListForModal}
              onSave={onSaveGroupSelectionModalHandler}
              onCancel={() => setShowSelectGroupSelectionModal(false)}
            />
          </AddConfigDrawer>
        </SelectGroupDrawerWrapper>
      }
    </>
  );
};

UserAccessForm.defaultProps = {
  isEditable: true
};

UserAccessForm.propTypes = {
  isEditable: PropTypes.bool,
  data: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default UserAccessForm;
