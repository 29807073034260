import React, { useState } from 'react';
import type { FC } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AdminMainLayoutWrapper } from '../../../../assets/styles/commonStyled';
import textConstants from '../../../../constants/textConstants';
import LogReportConfigSummary from '../../../../components/AdditionalComponents/DetailSummaryPage/Admin/LogReportConfig/LogReportConfigSummary';
import { saveLogReportConfig, deleteLogReportConfig } from '../../../../redux/actions/Admin/logReportConfigActions';
import { LOG_REPORT_CONFIG_TAB_KEY } from '../../../../constants/KeyLabels/commonKeyConstants';
import ConfigDetailCardHeader from '../../../../components/AdditionalComponents/ConfigDetailCardHeader';
import LogReportConfigForm from '../../../../components/AdditionalComponents/UpsertForms/Admin/LogReportConfig';
import AdminDetailBreadCrumb from '../../../../components/common/BreadCrumb/AdminDetailBreadCrumb';
import routeConstants from '../../../../constants/routeConstants';
import { ConfigDetailWrapper } from '../../../../components/AdditionalComponents/DetailSummaryPage/Admin/style';
import { errorNotification } from '../../../../components/common/Notification';
import { checkIfExistsInArray } from '../../../../helpers/commonHelper';
import { logReportConfigItemKey } from '../../../../constants/KeyLabels/KeysToCheckForDuplicateItems';

const LogReportConfigDetail: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { data, isEditable } = location?.state as { isEditable?: boolean, data: any } ?? {};

  const [isConfigDetailInEditMode, setConfigDetailInEditMode] = useState(isEditable);
  const [configData, setConfigData] = useState(data);

  const logReportConfigList = useSelector(
    (state: any) => state.logReportConfig.logReportConfigList
  );

  const onFormSaveCancelHandler = (): void => {
    if (isEditable) {
      history.push({
        pathname: routeConstants.ADMIN_REPORT_CONFIG,
        state: {
          activeTab: LOG_REPORT_CONFIG_TAB_KEY
        }
      })
    } else {
      setConfigDetailInEditMode(false);
    }
  }

  const onLogReportConfigSaveHandler = (data: any): void => {
    const filteredLogReportConfigList = logReportConfigList.filter((item: any) => item.logConfigId !== data.logConfigId)
    if (!checkIfExistsInArray(filteredLogReportConfigList, data, logReportConfigItemKey)) {
      dispatch(saveLogReportConfig(data, onFormSaveCancelHandler));
      setConfigData(data);
    } else {
      errorNotification(textConstants.LOG_REPORT_CONFIG_ALREADY_EXIST_MESSAGE);
    }
  }

  const onLogReportConfigDeleteHandler = (): void => {
    dispatch(deleteLogReportConfig(
      [data],
      history.push({
        pathname: routeConstants.ADMIN_REPORT_CONFIG,
        state: {
          activeTab: LOG_REPORT_CONFIG_TAB_KEY
        }
      })
    ));
  }

  return (
    <AdminMainLayoutWrapper data-testid="logReportConfigDetail">
      <AdminDetailBreadCrumb
        mainPageText={textConstants.LOG_REPORT_CONFIG_PAGE_TITLE}
        currentPageText={data?.applicationName.join(', ')}
        onBackCallback={() => history.push({
          pathname: routeConstants.ADMIN_REPORT_CONFIG,
          state: {
            activeTab: LOG_REPORT_CONFIG_TAB_KEY
          }
        })}
      />
      <ConfigDetailWrapper>
        <ConfigDetailCardHeader
          onDelete={onLogReportConfigDeleteHandler}
          onEdit={() => setConfigDetailInEditMode(true)}
          isEditEnabled={!isConfigDetailInEditMode}
          title={textConstants.LOG_REPORT_CONFIG_DETAIL_PAGE_TITLE}
          configName={textConstants.LOG_REPORT_DELETE_CONFIG_TITLE}
        />

        {isConfigDetailInEditMode && (
          <LogReportConfigForm
            data={configData}
            onSave={onLogReportConfigSaveHandler}
            onCancel={onFormSaveCancelHandler}
          />
        )}
        {!isConfigDetailInEditMode && (
          <LogReportConfigSummary
            data={configData}
          />
        )}
      </ConfigDetailWrapper>
    </AdminMainLayoutWrapper>
  );
};

export default LogReportConfigDetail;
