export const blobMetricsSummaryDateRangeOptions = [
  {
    label: '1 Day',
    value: 'daily'
  },
  {
    label: 'Last 7 Day\'s',
    value: 'weekly'
  },
  {
    label: 'Last 30 Day\'s',
    value: 'monthly'
  }
];

export const blobMetricTypeOptions = [
  { label: 'Log', value: 'Log' },
  { label: 'Exception', value: 'Exception' }
];

export const replayModeOptions = [
  {
    label: 'HTTP',
    value: 'HTTP'
  },
  {
    label: 'JMS',
    value: 'JMS'
  },
  {
    label: 'KAFKA',
    value: 'KAFKA'
  },
  {
    label: 'HTTP OAUTH',
    value: 'HTTP_OAUTH'
  }
];

export const replayTypeOptions = [
  { label: 'Automatic', value: 'Automatic' },
  { label: 'Manual', value: 'Manual' }
];

export const snowServiceNameOptions = [
  {
    label: 'Calculated Application Service',
    value: 'cmdb_ci_service_calculated'
  },
  {
    label: 'Application Service',
    value: 'cmdb_ci_service_discovered'
  },
  {
    label: 'Tag-Based Application Service',
    value: 'cmdb_ci_service_by_tags'
  },
  {
    label: 'Dynamic CI Group',
    value: 'cmdb_ci_query_based_service'
  }
];

export const columnAlertColumnNameOptions = [
  { label: 'Service Name', value: 'SERVICENAME' },
  { label: 'Component Name', value: 'COMPONENTNAME' },
  { label: 'Transaction Domain', value: 'TRANSACTIONDOMAIN' },
  { label: 'Transaction Type', value: 'TRANSACTIONTYPE' },
  { label: 'Application Domain', value: 'APPLICATIONDOMAIN' }
];

export const alertTypeNamesOptions = [
  { label: 'Both', value: 'Both' },
  { label: 'Email', value: 'Email' },
  { label: 'None', value: 'None' },
  { label: 'ServiceNow', value: 'ServiceNow' }
];

export const thresholdDaysOptions = [
  { label: 'SUNDAY', value: '1' },
  { label: 'MONDAY', value: '2' },
  { label: 'TUESDAY', value: '3' },
  { label: 'WEDNESDAY', value: '4' },
  { label: 'THURSDAY', value: '5' },
  { label: 'FRIDAY', value: '6' },
  { label: 'SATURDAY', value: '7' }
];

export const tableTypes = [
  {
    label: 'Log',
    value: 'log'
  },
  {
    label: 'Exception',
    value: 'exception'
  }
];

export const metricTypeOptions = [
  { label: 'Service', value: 'Service' },
  { label: 'TransactionDomain', value: 'TransactionDomain' }
];

export const exceptionReportTypes = [
  {
    label: 'Daily',
    value: 'Daily'
  },
  {
    label: 'Daily Count',
    value: 'DailyCount'
  },
  {
    label: 'Hourly',
    value: 'Hourly'
  }
];

export const exceptionReportHourlyOptionList = [
  {
    label: '2',
    value: 2
  },
  {
    label: '4',
    value: 4
  },
  {
    label: '6',
    value: 6
  }
]

export const chargeBackYearOptions = [
  { value: 2024, label: 2024 },
  { value: 2025, label: 2025 },
  { value: 2026, label: 2026 },
  { value: 2027, label: 2027 },
  { value: 2028, label: 2028 },
  { value: 2029, label: 2029 },
  { value: 2030, label: 2030 }
];

export const volumeMetricTypeOptions = [
  { label: 'Log', value: 'log' },
  { label: 'Exception', value: 'exception' }
];
