export const columnAlertFormBasicFields: Record<string, any> = [
  {
    label: 'Application Name',
    name: 'applicationName',
    id: 'applicationName',
    required: true,
    rules: [{ required: true, message: 'Application is mandatory!' }],
    type: 'select',
    disableOnEdit: true
  },
  {
    label: 'Description',
    name: 'description',
    id: 'description',
    type: 'text'
  }
];

export const columnAlertFormIfFields = [
  {
    label: 'Category',
    name: 'exceptionCategoryName',
    id: 'exceptionCategoryName',
    required: true,
    rules: [{ required: true, message: 'Category is mandatory!' }],
    type: 'select',
    disableOnEdit: true
  },
  {
    label: 'Type',
    name: 'exceptionTypeName',
    id: 'exceptionTypeName',
    required: true,
    rules: [{ required: true, message: 'Type is mandatory!' }],
    type: 'select',
    disableOnEdit: true
  },
  {
    label: 'Severity',
    name: 'exceptionSeverityName',
    id: 'exceptionSeverityName',
    required: true,
    rules: [{ required: true, message: 'Severity is mandatory!' }],
    type: 'select',
    disableOnEdit: true
  }
];

export const columnAlertFormThenFields = [
  {
    label: 'Column Name',
    name: 'columnName',
    id: 'columnName',
    required: true,
    rules: [{ required: true, message: 'Column Name is mandatory!' }],
    type: 'select',
    disableOnEdit: true
  },
  {
    label: 'Column Value',
    name: 'columnValue',
    id: 'columnValue',
    required: true,
    rules: [{ required: true, message: 'Column Value is mandatory!' }],
    type: 'text'
  }
];

export const columnAlertFormNotificationFields = {
  enableSnowNotificationField: {
    label: 'Enable Snow Notification',
    name: 'enableSnowNotification',
    id: 'enableSnowNotification'
  },
  sysClassNameField: {
    label: 'Snow Service Class',
    name: 'sysClassName',
    id: 'sysClassName',
    type: 'select',
    required: true,
    rules: [{ required: true, message: 'Snow Service Class is Mandatory!' }]
  },
  snowParameterField: {
    label: 'Snow Service Value',
    name: 'snowParam1',
    id: 'snowParam1',
    type: 'text',
    required: true,
    rules: [{ required: true, message: 'Snow Service Value is Mandatory!' }]
  },
  enableGroupNotificationField: {
    label: 'Enable Group Notification',
    name: 'enableGroupNotification',
    id: 'enableGroupNotification'
  },
  notificationGroupField: {
    label: 'Assignment Group',
    name: 'notificationGroup',
    id: 'notificationGroup',
    type: 'text',
    required: true,
    rules: [{ required: true, message: 'Assignment Group is Mandatory!' }]
  },
  enableMailNotificationField: {
    label: 'Enable Email Notification',
    name: 'enableMailNotification',
    id: 'enableMailNotification'
  },
  emailAddressField: {
    label: 'Email Notification',
    name: 'emailAddress',
    id: 'emailAddress',
    type: 'text',
    required: true,
    rules: [{ required: true, message: 'Email Notification is Mandatory!' }]
  }
};
