import React, { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import LoadingBar from 'react-top-loading-bar';
import type { LoadingBarRef } from 'react-top-loading-bar';
import FloatingLabel from '../../../../common/FloatingLabel';
import { chargeBackYearOptions } from '../../../../../constants/DropDownOptions/adminConfig';
import { exportAPIRequestHandler } from '../../../../../services/logExceptionServiceHandler';
import apiEndpointConstants from '../../../../../constants/apiEndpointConstants';
import textConstants from '../../../../../constants/textConstants';

const ChargebackCostAnalysisForm: FC = () => {
  const [form] = Form.useForm();
  const loadingBarRef = useRef<LoadingBarRef>(null);

  const [monthOptions, setMonthOptions] = useState<any>([]);

  const onExportHandler = (): void => {
    form
      .validateFields()
      .then(async (values) => {
        exportAPIRequestHandler(
          values,
          apiEndpointConstants.EXPORT_COST_ANALYSIS_REPORT,
          false,
          'Cost-analysis-report',
          loadingBarRef
        );
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    const tempMonthOptions = [];

    for (let i = 1; i <= 12; i++) {
      tempMonthOptions.push({
        value: i,
        label: new Date(0, i - 1).toLocaleString('default', { month: 'long' })
      });
    }
    setMonthOptions(tempMonthOptions);
  }, []);

  return (
    <>
    <LoadingBar
        ref={loadingBarRef}
        containerClassName="fixed-progress-bar"
        height={3}
        color="#23A0DD"
      />
      <Form
        form={form}
        data-testid="chargebackCostAnalysisForm"
        initialValues={{ month: 1, year: 2024 }}
        className="form-lg"
      >
        <Row gutter={{ lg: 16, xl: 16, xxl: 26 }}>
          <Col xs={24} md={6} xl={4}>
            <Form.Item name="month" rules={[{ required: true, message: 'Month is Mandatory!' }]}>
              <FloatingLabel
                label='Month'
                type="select"
                id="month"
                options={monthOptions}
                required
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6} xl={4}>
            <Form.Item name="year" rules={[{ required: true, message: 'Year is Mandatory!' }]}>
              <FloatingLabel
                label='Year'
                type="select"
                id="year"
                options={chargeBackYearOptions}
                required
              />
            </Form.Item>
          </Col>
          <Col>
            <Button
              icon={<DownloadOutlined />}
              type="primary"
              onClick={onExportHandler}
              className='btn-lg-icon'
            >
              {textConstants.EXPORT_BUTTON_LABEL}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ChargebackCostAnalysisForm;
