import dayjs from 'dayjs';

export const calculateTimeDiff = (dateToCalculateDiffWith: any): string => {
  if (dayjs().diff(dateToCalculateDiffWith, 'day') >= 1) {
    if (dayjs().diff(dateToCalculateDiffWith, 'day') === 1) {
      return `${dayjs().diff(dateToCalculateDiffWith, 'day')} day ago`;
    }
    return `${dayjs().diff(dateToCalculateDiffWith, 'day')} days ago`;
  } else if (dayjs().diff(dateToCalculateDiffWith, 'hour') >= 1) {
    if (dayjs().diff(dateToCalculateDiffWith, 'hour') === 1) {
      return `${dayjs().diff(dateToCalculateDiffWith, 'hour')} hour ago`;
    }
    return `${dayjs().diff(dateToCalculateDiffWith, 'hour')} hours ago`;
  } else if (dayjs().diff(dateToCalculateDiffWith, 'minute') >= 1) {
    if (dayjs().diff(dateToCalculateDiffWith, 'minute') === 1) {
      return `${dayjs().diff(dateToCalculateDiffWith, 'minute')} minute ago`;
    }
    return `${dayjs().diff(dateToCalculateDiffWith, 'minute')} minutes ago`;
  }
  return '';
};
