export const DEFAULT_SEARCH_TAB = {
  label: 'Search 1',
  key: 'DefaultSearch',
  closable: true,
  searchResults: [],
  searchQuery: null,
  isSearched: false,
  hasNextPage: false,
  payloadForNextCall: null,
  firstCallPayload: []
};
