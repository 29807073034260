import { convertTimeToTimeZoneForDisplay } from '../../helpers/commonHelper';

export const AUDIT_LOG_COLUMNS: any[] = [
  {
    field: 'timemark',
    headerName: 'Timemark',
    cellRenderer: (params: any) => {
      return convertTimeToTimeZoneForDisplay(params?.value);
    }
  },
  {
    field: 'status',
    headerName: 'Status'
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
    wrapText: true,
    autoHeight: true
  }
];
