export const getRandomColor = (): string => {
  // return '#' + Math.floor(Math.random() * 16777215).toString(16);
  return `#${(Math.floor(Math.random() * 118) + 40).toString(16)}${(
    Math.floor(Math.random() * 118) + 40).toString(16)}${(Math.floor(Math.random() * 118) + 40).toString(16)}`;
};

const useGenerateColoredList = (value: any): any[] => {
  const valueWithColorsList: Array<{ color: string, content: string }> = [];

  const storedColors = localStorage.getItem('tagColors');
  if (storedColors) {
    const storedColorsValues = JSON.parse(storedColors);
    if (typeof value === 'string') {
      if (!storedColorsValues[value]) {
        const randomColor = getRandomColor();
        storedColorsValues[value] = randomColor;
        valueWithColorsList.push({ color: randomColor, content: value });
      } else {
        valueWithColorsList.push({ color: storedColorsValues[value], content: value });
      }
    } else if (typeof value === 'object' && value.length > 0) {
      value.forEach((elementInArray: string) => {
        if (!storedColorsValues[elementInArray]) {
          const randomColor = getRandomColor();
          storedColorsValues[elementInArray] = randomColor;
          valueWithColorsList.push({ color: randomColor, content: elementInArray });
        } else {
          valueWithColorsList.push({
            color: storedColorsValues[elementInArray],
            content: elementInArray
          });
        }
      });
    }
    localStorage.setItem('tagColors', JSON.stringify(storedColorsValues));
  } else {
    const colors: Record<string, string> = {};
    if (typeof value === 'string') {
      const randomColor = getRandomColor();
      colors[value] = randomColor;
      valueWithColorsList.push({ color: randomColor, content: value });
    } else if (typeof value === 'object' && value.length > 0) {
      value.forEach((elementInArray: string) => {
        const randomColor = getRandomColor();
        colors[elementInArray] = randomColor;
        valueWithColorsList.push({ color: randomColor, content: elementInArray });
      });
    }
    localStorage.setItem('tagColors', JSON.stringify(colors));
  }
  return valueWithColorsList;
};
export default useGenerateColoredList;
