import { convertTimeToTimeZoneForDisplay } from '../../../helpers/commonHelper';
const additionalColumnProp = {
  minWidth: 150,
  flex: 1,
  valueFormatter: (params: { value: any }) => {
    return params?.value ? (params?.value?.toString().trim().length === 0 ? '-' : params.value) : '-';
  }
};
export const TABLE_CONFIG_COLUMNS = [
  {
    field: 'tableName',
    headerName: 'Table Name',
    sortable: true,
    ...additionalColumnProp,
    flex: 'auto'
  },
  {
    field: 'dataType',
    headerName: 'Table Type',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'retentionDays',
    headerName: 'Retention Days',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'topicName',
    headerName: 'Topic Name',
    sortable: true,
    ...additionalColumnProp,
    flex: 'auto'
  },
  {
    field: 'recordCount',
    headerName: 'Record Count',
    sortable: true
  },
  {
    field: 'tableSize',
    headerName: 'Table Size',
    sortable: true
  },
  {
    field: 'solrCoreSize',
    headerName: 'Solr Core Size',
    sortable: true
  },
  {
    field: 'sector',
    headerName: 'Sector',
    sortable: true
  },
  {
    field: 'lastUpdated',
    headerName: 'Last Updated',
    sortable: true,
    flex: 'auto',
    cellRenderer: (params: any) => {
      return convertTimeToTimeZoneForDisplay(params?.value);
    }
  },
  {
    field: 'spaceOccupied',
    headerName: 'Space Occupied',
    sortable: true,
    ...additionalColumnProp
  }
];
