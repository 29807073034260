/**
 * This is Functional  component used for Okta Authentication
*/
import React, { useEffect } from 'react';
import type { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import routeConstants from '../../constants/routeConstants';
import Loader from '../common/Loader';

const OktaAuthenticator: FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const history = useHistory();

  const login = async (): Promise<void> => {
    await oktaAuth.signInWithRedirect();
  };

  useEffect(() => {
    if (authState) {
      if (!authState.isAuthenticated) {
        console.log(
          'is user authenticated',
          authState.isAuthenticated
        );
        login().catch(err => {
          console.log('login err', err)
        });
      } else {
        history.push(routeConstants.DASHBOARD);
      }
    }
  }, [authState]); // Update if authState changes

  if (!authState) {
    return <Loader />;
  }

  return null;
};

export default OktaAuthenticator;
