import { pickBy } from 'lodash';
import dayjs from 'dayjs';

export const validateSearchQuery = (value: string, fieldDataArr: any[]): string => {
  let errorMessage = '';
  const valueFields = value
    ? value.split(';').filter((item: string) => item?.replace(/^\s+/g, ''))
    : [];

  let fieldNameObj: any = {};
  const invalidFields: string[] = [];
  let invalidValuePattern = false;

  valueFields.forEach((item: string) => {
    const fieldName = item.split('=')[0]
      ? item.split('=')[0].replace(/^\s+/g, '').toLowerCase()
      : '';
    const fieldValue = item.split('=')[1] ? item.split('=')[1].replace(/^\s+/g, '') : '';

    if (!fieldName) {
      fieldNameObj = {
        ...fieldNameObj,
        [fieldName]: 'fieldName missing'
      };
    } else if (fieldName in fieldNameObj) {
      const value: number = fieldNameObj[fieldName];
      fieldNameObj = {
        ...fieldNameObj,
        [fieldName]: value + 1
      };
    } else if (!fieldValue) {
      fieldNameObj = {
        ...fieldNameObj,
        [fieldName]: 'fieldValue missing'
      };
    } else if (!/^[^\s,]+(?:,\s*[^\s,]+)*$/.test(fieldValue.replace(/ /g, ''))) {
      invalidValuePattern = true;
    } else {
      const invalidField = fieldDataArr.find(
        (item: any) => item.label.toLowerCase() === fieldName.toLowerCase()
      );

      if (!invalidField) {
        invalidFields.push(fieldName);
      }

      fieldNameObj = {
        ...fieldNameObj,
        [fieldName]: 1
      };
    }
  });

  const duplicateFields = pickBy(fieldNameObj, (value) => value > 1);
  const valueMissingFields = pickBy(fieldNameObj, (value) => value === 'fieldValue missing');
  const missingFieldNames = pickBy(fieldNameObj, (value) => value === 'fieldName missing');

  if (Object.keys(missingFieldNames).length > 0) {
    errorMessage = 'Field Name is missing. Please provide a vaild field name.';
  } else if (invalidFields.length > 0) {
    errorMessage = `${invalidFields
      .join('')
      .toUpperCase()} field not available. Please provide valid field name.`;
  } else if (Object.keys(duplicateFields).length > 0) {
    errorMessage = `Duplicate Field (${Object.keys(duplicateFields).join('').toUpperCase()})`;
  } else if (Object.keys(valueMissingFields).length > 0) {
    errorMessage = `Value is missing for (${Object.keys(valueMissingFields)
      .join('')
      .toUpperCase()}). Please provide comma separated values`;
  } else if (invalidValuePattern) {
    errorMessage = 'Invalid Query. Please provide value before and after comma';
  }

  return errorMessage;
};

export const checkIfDateRangeIsValid = async (
  _: any,
  inputDateValues: any,
  isDateTimeHoursRestriction: boolean
): Promise<any> => {
  if (
    isDateTimeHoursRestriction &&
    dayjs(inputDateValues[1]).diff(dayjs(inputDateValues[0]), 'hour') > 24
  ) {
    return await Promise.reject(new Error('Date field allowed 24hrs only!'));
  }
  return await Promise.resolve();
};
