import React, { useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Radio, Row } from 'antd';
import type { AdminConfigFormPropsType } from '../../../../../constants/Interfaces/AdminFormConfigTypes';
import type { FormFieldType } from '../../../../../constants/Interfaces/FormFieldTypes';
import { reportConfigFormFields } from '../../../../../constants/FormFields/AdminForm/reportConfigFormFields';
import FloatingLabel from '../../../../common/FloatingLabel';
import Footer from '../../../../common/Footer';
import { applicationDataHelper } from '../../../../../helpers/applicationDataHelper';
import {
  alertTypeNamesOptions,
  thresholdDaysOptions
} from '../../../../../constants/DropDownOptions/adminConfig';
import { REPORT_CONFIG_ID } from '../../../../../constants/KeyLabels/commonKeyConstants';

const ReportConfigForm: FC<AdminConfigFormPropsType> = ({ isDrawerView, isEditable, data, onSave, onCancel }) => {
  const [form] = Form.useForm();

  const setReportConfigFormData = (): void => {
    form.setFieldsValue({ ...data });

    if (data?.thresholdDays && Object.keys(data?.thresholdDays).length > 0) {
      const selectedThresholdDays: any = [];
      Object.entries(data?.thresholdDays).forEach(([key, value]: any) => {
        if (value) {
          selectedThresholdDays.push(thresholdDaysOptions[key - 1]);
        }
      });
      form.setFieldValue('thresholdDays', selectedThresholdDays);
    } else {
      form.setFieldValue('thresholdDays', []);
    }
  }

  useEffect(() => {
    if (isEditable) {
      setReportConfigFormData();
    }
  }, [data[REPORT_CONFIG_ID]]);

  const onSaveHandler = (isClose: boolean): void => {
    form
      .validateFields()
      .then((values) => {
        const thresholdDaysObj = values?.thresholdDays?.reduce(
          (obj: any, item: any) => ({ ...obj, [item.value]: true }),
          {}
        );

        values = {
          ...values,
          thresholdDays: thresholdDaysObj
        };
        onSave(values, isClose);
      })
      .catch((error) => console.log('err', error));
  };

  const getOptionList = (fieldName: string): any[] => {
    let optionList = [];

    switch (fieldName) {
      case 'alertType':
        optionList = alertTypeNamesOptions;
        break;
      case 'thresholdDays':
        optionList = thresholdDaysOptions;
        break;
      default:
        optionList = applicationDataHelper.getApplicationNames([]);
        break;
    }

    return optionList;
  };

  const renderField = (field: FormFieldType): any => {
    switch (field.type) {
      case 'radio':
        return (
          <Form.Item name={field.name} label={field.label}>
            <Radio.Group>
              <Radio value>Enable</Radio>
              <Radio value={false}>Disable</Radio>
            </Radio.Group>
          </Form.Item>
        );
      default:
        return (
          <Form.Item name={field.name} rules={field.rules}>
            <FloatingLabel
              label={field.label}
              type={field.type}
              id={field.id}
              disabled={isEditable && field.disableOnEdit}
              required={field?.required}
              data-testid={field?.id}
              {...(field.type === 'select' && {
                options: getOptionList(field.id),
                showSearch: true
              })}
              {...(field.type === 'multiSelect' && {
                options: getOptionList(field.id),
                multi: true,
                searchInputPlaceholder: `Search ${field.label}`,
                portal: typeof document !== 'undefined' && document?.body
              })}
              {...(field.type === 'number' && { min: 0 })}
            />
          </Form.Item>
        );
    }
  };

  return (
    <>
      <Form
        form={form}
        initialValues={{
          allowBreach: false
        }}
        data-testid="reportConfigForm"
        className="form-lg"
        layout="vertical"
      >
        <Row gutter={{ lg: 16, xl: 16, xxl: 28 }}>
          {reportConfigFormFields.filter((item: FormFieldType) => (isEditable || item.id !== 'reportConfigId')).map((formField: any, index: number) => {
            return (
              <Col key={formField.id} xs={24} md={6} xxl={5}>
                {renderField(formField)}
              </Col>
            );
          })}
        </Row>
      </Form>
      <Footer
        className={`grey-theme ${isDrawerView ? 'add-form-footer' : ''}`}
        onSave={() => onSaveHandler(false)}
        onSaveClose={() => onSaveHandler(true)}
        onCancel={onCancel}
        saveWithClose={!isEditable}
        save
        cancel
      />
    </>
  );
};

ReportConfigForm.defaultProps = {
  isEditable: true
};

ReportConfigForm.propTypes = {
  isDrawerView: PropTypes.bool,
  isEditable: PropTypes.bool,
  data: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ReportConfigForm;
