import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import Footer from '../../../../common/Footer';
import { Col, Form, Row } from 'antd';
import { ApplicationCategoryFormFields, ApplicationSeverityFormFields, ApplicationTypeFormFields } from '../../../../../constants/FormFields/AdminForm/listFormFields';
import { ApplicationListFormItemsLayout } from '../../../../../constants/FormLayout/ApplicationFormLayout';
import FloatingLabel from '../../../../common/FloatingLabel';

interface componentProps {
  onSave?: any
  onCancel?: any
  data?: any
  type?: string
}
const columnSpan = ApplicationListFormItemsLayout.columnSpan
const ListConfigForm: FC<componentProps> = ({ data, onSave, onCancel, type }) => {
  const [form] = Form.useForm();
  const onSaveHandler = (isClosable?: boolean): void => {
    form.validateFields()
      .then(async values => {
        onSave(values, isClosable);
      }).catch(error => console.log(error));
  }

  return (
    <>
      <Form
        form={form}
        initialValues={data}
        data-testid="testid-application-detail-list-form">
        <Row gutter={ApplicationListFormItemsLayout.rowGutter} >
          {
            ((type === 'category' ? ApplicationCategoryFormFields : null) ??
              (type === 'severity' ? ApplicationSeverityFormFields : null) ??
              (type === 'type' ? ApplicationTypeFormFields : null))?.map((formField: any) => {
              return (
                  <Col key={formField.name} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                    <Form.Item name={formField.name} rules={formField.rules}>
                      <FloatingLabel
                        label={formField.label}
                        id={formField.id}
                        type={formField.type}
                        disabled={formField.disabled}
                        required={formField.required}
                        {
                        ...(formField?.additionalProps && { ...formField?.additionalProps })
                        }
                      />
                    </Form.Item>
                  </Col>
              )
            })
          }
        </Row>
      </Form>
      <Footer
        className={'grey-theme add-form-footer'}
        save saveWithClose
        cancel
        onSaveClose={() => onSaveHandler(true)}
        onSave={() => onSaveHandler(false)}
        onCancel={onCancel}
      />
    </>)
}

ListConfigForm.propTypes = {
  data: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  type: PropTypes.string
};

export default ListConfigForm;
