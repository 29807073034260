export const lineChartConfig = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index' as const,
    intersect: false
  },
  stacked: false,
  plugins: {
    datalabels: {
      display: false
    },
    tooltip: {
      position: 'nearest' as const,
      titleFont: {
        size: 10
      },
      bodyFont: {
        size: 10
      },
      enabled: true,
      itemSort: function (a: any, b: any) {
        return b.raw - a.raw;
      }
    },
    legend: {
      display: false
    }
  },
  elements: {
    line: {
      borderWidth: 1.4,
      tension: 0.3,
      fill: false
    }
  },
  scales: {
    y: {
      title: {
        color: '#636363',
        display: true,
        text: 'Count'
      },
      backgroundColor: 'white',
      grid: {
        color: '#e2e2e2',
        tickColor: '#e2e2e2',
        lineWidth: 0.65
      },
      border: {
        dash: [4, 4]
      },
      ticks: {
        precision: 0,
        font: {
          size: 9
        },
        callback: function (value: any) {
          if (value < 1e3) {
            return value;
          } else if (value >= 1e3 && value < 1e6) {
            return value / 1e3 + 'K'
          } else if (value >= 1e6 && value < 1e9) {
            return value / 1e6 + 'M';
          } else {
            return value / 1e9 + 'B';
          }
        }
      }
    },
    x: {
      backgroundColor: 'white',
      offset: false,
      grid: {
        color: '#e2e2e2',
        tickColor: '#e2e2e2',
        lineWidth: 0.65
      },
      border: {
        dash: [4, 4]
      },
      ticks: {
        font: {
          size: 9
        }
      }
    }
  }
};
