import { call, put, fork, all, takeLatest } from 'redux-saga/effects';
import type { AllEffect, CallEffect, ForkEffect, PutEffect } from 'redux-saga/effects';
import { createLogger } from 'redux-logger';
import { updateDataLoadingStatus } from '../../actions/CommonConfig/commonConfigActions';
import { setSolmanStagingConfigList } from '../../actions/Admin/solmanStagingConfigActions';
import { GET_SOLMAN_STAGING_CONFIG_LIST } from '../../actions/ActionTypes/Admin/solmanStagingConfigTypes';
import { API_SUCCESS_STATUS_CODE } from '../../../constants/commonConstants';
import { apiRequest } from '../../../services/axiosInterceptor';
import apiURL from '../../../constants/apiEndpointConstants';

const callEffect: any = call;

export function * fetchSolmanStagingConfigListSaga (): Generator<
CallEffect<any> | PutEffect<any>,
void
> {
  try {
    const config = {
      method: 'get',
      url: apiURL.GET_SOLMAN_STAGING_CONFIG_LIST
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    const payload = {
      data: response?.data,
      action: 'NEW'
    };

    if (response.status === API_SUCCESS_STATUS_CODE) {
      yield put(setSolmanStagingConfigList(payload));
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * getsolmanStagingConfigList (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_SOLMAN_STAGING_CONFIG_LIST, fetchSolmanStagingConfigListSaga);
}

export default function * root (): Generator<AllEffect<any>> {
  yield all([fork(getsolmanStagingConfigList)]);
}
