import { produce } from 'immer';
import { SET_LOG_REPORT_CONFIG_LIST } from '../../../actions/ActionTypes/Admin/logReportConfigTypes';
import { processOperation } from '../../../../helpers/reducerHelper';

export interface LogReportConfigState {
  logReportConfigList: any[]
}

const initialState = {
  logReportConfigList: []
};

const logReportConfigReducer = (state = initialState, action: any): LogReportConfigState =>
  produce(state, (draft: LogReportConfigState) => {
    switch (action.type) {
      case SET_LOG_REPORT_CONFIG_LIST:
        draft.logReportConfigList = processOperation(action.data, [...draft.logReportConfigList]);
        break;
    }
  });

export default logReportConfigReducer;
