import styled, { css } from 'styled-components';

export const MultiSelectWrapper = styled.div<{ dropdownPanelSize: string | undefined }>`
    height: 100%;

    & > div {
        height: 100%;
    }

    &.multiselect-disabled {
        cursor: not-allowed;

        .react-dropdown-select-clear {
            display: none;
        }
    }

    .react-dropdown-select {
        min-height: 32px;
        border-radius: 6px;

        &:hover, &:focus-within {
            border-color: var(--form-field-focus-border-color);
            box-shadow: none;
        }

        .react-dropdown-select-dropdown {
            width: ${props => props.dropdownPanelSize === 'large' ? '890px' : '100%'};
            border-color: var(--gray-250);
            max-height: initial;
            z-index: 1111;

            &-handle svg {
                width: 20px;
                height: 20px;
            }
        }

        .react-dropdown-select-dropdown-position-bottom {
            box-shadow: 0px 10px 13px var(--black-200);
            border-radius: 0;
            padding-bottom: 0;
        }

        &-dropdown-handle {
            padding-right: 5px;

            svg {
                width: 20px;
                height: 20px;
            }
        }

        &-content {
            color: var(--gray-color);

            span {
                &:only-child > span {
                    max-width: 145px;
                }

                > span {
                    max-width: 55px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        .ant-input {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 1px;
        }

        .tag-count {
            background: var(--pepsi-primary-color);
            color: var(--white);
            padding: 2px 8px;
        }

        &-clear {
            background: var(--gray-300);
            width: 12px;
            height: 12px;
            font-size: var(--font-size-sm);
            border-radius: 50%;
            line-height: 1;
            text-align: center;
            color: var(--white);
            margin: 0 3px 0 0;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                color: var(--white);
                background-color: var(--gray-400);
            }
        }
    }
`

export const MutliSelectButtonWrapper = styled.div`
    text-align: right;
    margin-top: 5px;
    padding-top: 10px;
    padding-right: 10px;
    border-top: 1px solid var(--gray-275);
`;

export const MultiSelectCheckboxWrapper = styled.span<{ isPointerNone: boolean }>`
    cursor: pointer;
    ${({ isPointerNone }) =>
    isPointerNone &&
    css`
        label {
            pointer-events: none;
        }
    `}
`;

export const MultiSelectInnerContainer = styled.div`
    padding-bottom: 10px;
`

export const MultiSelectTag = styled.span<{ isSingleSelection?: boolean }>`
    border-radius: 20px;
    padding: 6px;
    font-size: var(--font-size-sm);
    background: var(--tag-bg-color);
    color: var(--white);
    line-height: 1;
    margin-bottom: 2px;
    display: inline-flex;
    align-items: center;
    z-index: 1;
    margin-right: 5px;
    border: 1px solid var(--tag-border-color);
    color: var(--tag-text-color);
    ${({ isSingleSelection }) =>
    isSingleSelection &&
    css`
        background: transparent;
        border: none;
        color: var(--black-100);
        margin-bottom: 0;

        span:last-child {
            display: none;
        }
    `}
`

export const MultiSelectTagClear = styled.span`
    vertical-align: middle;
    font-weight: var(--font-weight-semibold);
    position: relative;
    top: -1px;
`

export const MultiSelectTagLabel = styled.span`
    padding-right: 5px;
`

export const MultiSelectHeaderWrapper = styled.div<{ isSelectedTagsVisible: boolean | undefined }>`
    padding: ${props => props.isSelectedTagsVisible ? '5px 15px 10px' : 0};
    background: var(--blue-color-300);
    max-height: 15vh;
    overflow: auto;
    .react-dropdown-select-option {
        margin: 0 5px 0 0;
    }
`
export const SelectedTagListWrapper = styled.div`
    margin: 5px 0;
`

export const MultiSelectHeaderInfo = styled.div`
    font-size: var(--font-size-sm);
    color: var(--black-100);
`

export const MultiSelectOptionHeaderPanel = styled.div<{ isSelectedTagsVisible: boolean | undefined }>`
    display: flex;
    justify-content: space-between;
    background: var(--gray-100);
    color: var(--pepsi-primary-color);
    padding: 5px 12px;
    margin: 0;
    border-top: ${props => props.isSelectedTagsVisible ? '1px solid var(--blue-color)' : 'none'};

    .ant-checkbox-wrapper {
        color: var(--pepsi-primary-color);
        font-weight: var(--font-weight-semibold);
        font-size: var(--font-size-md);
    }
`

export const MultiSelectOptionListWrapper = styled.div<{ dropdownPanelSize: string | undefined }>`
    padding: 10px 0 0;
    display: flex;
    max-height: ${props => props.dropdownPanelSize === 'medium' ? '55vh' : '235px'};
    overflow-y: auto;
    flex-flow: ${props => props.dropdownPanelSize === 'large' ? 'row wrap' : 'column'};
`

export const MultiSelectOptionItem = styled.div<{ dropdownPanelSize: string | undefined, hasSingleSelection?: boolean }>`
    padding: 0 13px 8px;
    flex: 0 0 ${props => props.dropdownPanelSize === 'large' ? '33%' : '100%'};

    &:last-child {
        padding-bottom: 0;
    }

    ${({ hasSingleSelection }) =>
    hasSingleSelection &&
    css`
        padding-left: 5px;
        padding-right: 5px;

        > span, label {
            width: 100%;

            .ant-checkbox {
                display: none;
            }

            .ant-checkbox-wrapper-checked {
                background: var(--blue-color-100);
                color: var(--white);
                border-radius: 4px;
            }
        }
    `}

    label {
        font-size: var(--font-size-sm);
        line-height: 2;
    }

    @media screen and (max-width: 991px) {
        flex-basis: 100%;
    }
`
