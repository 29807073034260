import { call, put, fork, all, takeLatest } from 'redux-saga/effects';
import type { AllEffect, CallEffect, ForkEffect, PutEffect } from 'redux-saga/effects';
import { createLogger } from 'redux-logger';
import { updateDataLoadingStatus } from '../../actions/CommonConfig/commonConfigActions';
import { setExceptionConfigList, getExceptionConfigList } from '../../actions/Admin/exceptionConfigActions';
import {
  DELETE_EXCEPTION_CONFIG,
  GET_EXCEPTION_CONFIG_LIST,
  UPLOAD_EXCEPTION_CONFIG,
  SAVE_EXCEPTION_CONFIG
} from '../../actions/ActionTypes/Admin/exceptionConfigTypes';
import { API_SUCCESS_STATUS_CODE } from '../../../constants/commonConstants';
import { successNotification } from '../../../components/common/Notification';
import { apiRequest } from '../../../services/axiosInterceptor';
import { EXCEPTION_ID } from '../../../constants/KeyLabels/commonKeyConstants';
import apiURL from '../../../constants/apiEndpointConstants';
import textConstants from '../../../constants/textConstants';

const callEffect: any = call;

export function * fetchExceptionConfigListSaga (): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: 'GET',
      url: apiURL.GET_EXCEPTION_CONFIG_LIST
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    const payload = {
      data: response?.data,
      action: 'NEW'
    };

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      yield put(setExceptionConfigList(payload));
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * saveExceptionConfigSaga ({ payload }: any): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: payload?.isAdd ? 'POST' : 'PUT',
      url: apiURL.SAVE_UPDATE_EXCEPTION_CONFIG,
      data: payload?.data
    };

    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    const storePayload = {
      data: response?.data,
      action: payload?.isAdd ? 'ADD' : 'UPDATE',
      ...(!payload?.isAdd && {
        keyName: EXCEPTION_ID,
        keyValue: response.data[EXCEPTION_ID]
      })
    };

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      yield put(setExceptionConfigList(storePayload));

      if (payload?.callBackFunc) {
        payload.callBackFunc();
      }

      successNotification(`Exception Configuration ${payload?.isAdd ? 'Added' : 'Updated'} Successfully`);
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * deleteExceptionConfigSaga ({ payload }: any): any {
  try {
    const config = {
      method: 'DELETE',
      url: apiURL.DELETE_EXCEPTION_CONFIG,
      data: payload?.data
    };

    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        action: 'DELETE',
        keyName: EXCEPTION_ID,
        keyValue: response.data[0][EXCEPTION_ID]
      };

      yield put(setExceptionConfigList(storePayload));

      if (payload?.callBackFunc) {
        payload.callBackFunc();
      }

      successNotification(textConstants.EXCEPTION_CONFIG_DELETE_SUCCESS_MESSAGE);
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * uploadExceptionConfigSaga (payload: any): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: 'POST',
      url: apiURL.UPLOAD_EXCEPTION_CONFIG,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: payload?.file,
      responseType: 'blob'
    };

    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      yield put(getExceptionConfigList());
      successNotification(textConstants.EXCEPTION_CONFIG_UPLOAD_SUCCESS_MESSAGE);
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * getExceptionConfigListData (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_EXCEPTION_CONFIG_LIST, fetchExceptionConfigListSaga);
}

export function * deleteExceptionConfigItem (): Generator<any> {
  yield takeLatest(DELETE_EXCEPTION_CONFIG, deleteExceptionConfigSaga);
}

export function * saveExceptionConfigItem (): Generator<any> {
  yield takeLatest(SAVE_EXCEPTION_CONFIG, saveExceptionConfigSaga);
}

export function * uploadExceptionConfigData (): Generator<any> {
  yield takeLatest(UPLOAD_EXCEPTION_CONFIG, uploadExceptionConfigSaga);
}

export default function * root (): Generator<AllEffect<any>> {
  yield all([
    fork(getExceptionConfigListData),
    fork(deleteExceptionConfigItem),
    fork(saveExceptionConfigItem),
    fork(uploadExceptionConfigData)
  ]);
}
