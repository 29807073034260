import { checkBoxFieldCol } from '../tableCheckboxColumn';

export const REPLAY_CONFIG_COLUMNS = [
  checkBoxFieldCol,
  {
    field: 'applicationName',
    headerName: 'Application',
    flex: 1,
    sortable: true
  },
  {
    field: 'applicationDomain',
    headerName: 'Application Domain',
    flex: 1,
    sortable: true
  },
  {
    field: 'replayHost',
    headerName: 'Host URL',
    flex: 1,
    sortable: true
  },
  {
    field: 'replayMode',
    headerName: 'Replay Mode',
    flex: 1,
    sortable: true
  },
  {
    field: 'username',
    headerName: 'User Name',
    flex: 1,
    sortable: true
  },
  {
    field: 'description',
    headerName: 'Remarks',
    flex: 1,
    sortable: true
  }
];
