import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import ConfigurationListTable from '../../../ConfigurationListTable';
import { ListConfigMainWrapper, ListConfigTableSectionWrapper, ListConfigTableSectionHeaderWrapper } from './style';
import { filterListByApplicationName } from '../../../../../helpers/applicationDataHelper';
import { EXCEPTION_CATEGORY_COLUMN_CONFIG, EXCEPTION_SEVERITIES_COLUMN_CONFIG, EXCEPTION_TYPES_COLUMN_CONFIG } from '../../../../../constants/TableColumns/Admin/ListConfigColumns';
import { AddButton } from '../../../../common/Buttons/buttons';
import AddConfigDrawer from '../../../Drawer/AddConfigDrawer';
import textConstants from '../../../../../constants/textConstants';
import ListConfigForm from '../../../UpsertForms/Admin/ListConfig/ListConfigForm';
import { ApplicationTabContentMainWrapper } from '../../../../../assets/styles/commonStyled';
import { errorNotification, successNotification } from '../../../../common/Notification';
import { checkIfExistsInArray } from '../../../../../helpers/commonHelper';
import { EXCEPTION_CATEGORY_ID, EXCEPTION_CATEGORY_NAME_KEY, EXCEPTION_SEVERITY_ID, EXCEPTION_SEVERITY_NAME_KEY, EXCEPTION_TYPE_ID, EXCEPTION_TYPE_NAME_KEY } from '../../../../../constants/KeyLabels/commonKeyConstants';
import { deleteApplicationExceptionCategory, deleteApplicationExceptionSeverity, deleteApplicationExceptionType, saveApplicationExceptionCategory, saveApplicationExceptionSeverity, saveApplicationExceptionType } from '../../../../../redux/actions/Admin/applicationListConfigActions';

interface componentProps {
  applicationDetailData?: any
}

const ListConfigTabContent: FC<componentProps> = ({ applicationDetailData }) => {
  const dispatch = useDispatch();

  const globalListConfigState = useSelector((state: any) => state?.listConfig);
  const [isAddDrawerVisible, setAddDrawerVisibility] = useState(false);
  const [addDrawerType, setAddDrawerType] = useState<string | undefined>();
  const [editConfigData, setEditConfigData] = useState<any>(null);

  const categroyList = filterListByApplicationName(globalListConfigState?.categories, applicationDetailData?.applicationName);
  const severityList = filterListByApplicationName(globalListConfigState?.severities, applicationDetailData?.applicationName);
  const typeList = filterListByApplicationName(globalListConfigState?.types, applicationDetailData?.applicationName);

  const closeAddConfigDrawer = (): void => {
    setAddDrawerVisibility(false);
    setEditConfigData(null);
  }

  const onSaveHandler = (data: any, isClosable?: boolean): void => {
    if (addDrawerType === 'category') {
      if (editConfigData && !checkIfExistsInArray(categroyList, { ...editConfigData, ...data }, EXCEPTION_CATEGORY_NAME_KEY, EXCEPTION_CATEGORY_ID, { ...editConfigData, ...data }[EXCEPTION_CATEGORY_ID])) {
        // category edit
        dispatch(saveApplicationExceptionCategory({ ...editConfigData, ...data }, false));
        successNotification(textConstants.CATEGORY_CONFIG_UPDATE_SUCCESS_MESSAGE)
      } else if (!checkIfExistsInArray(categroyList, data, EXCEPTION_CATEGORY_NAME_KEY)) {
        // category add
        dispatch(saveApplicationExceptionCategory(data, true));
        successNotification(textConstants.CATEGORY_CONFIG_ADD_SUCCESS_MESSAGE)
      } else {
        errorNotification(textConstants.CATEGORY_CONFIG_ALREADY_EXIST_MESSAGE);
      }
    }
    if (addDrawerType === 'severity') {
      if (editConfigData && !checkIfExistsInArray(severityList, { ...editConfigData, ...data }, EXCEPTION_SEVERITY_NAME_KEY, EXCEPTION_SEVERITY_ID, { ...editConfigData, ...data }[EXCEPTION_SEVERITY_ID])) {
        // type edit
        dispatch(saveApplicationExceptionSeverity({ ...editConfigData, ...data }, false));
        successNotification(textConstants.SEVERITY_CONFIG_UPDATE_SUCCESS_MESSAGE)
      } else if (!checkIfExistsInArray(severityList, data, EXCEPTION_SEVERITY_NAME_KEY)) {
        // type add
        dispatch(saveApplicationExceptionSeverity(data, true));
        successNotification(textConstants.SEVERITY_CONFIG_ADD_SUCCESS_MESSAGE)
      } else {
        errorNotification(textConstants.SEVERITY_CONFIG_ALREADY_EXIST_MESSAGE);
      }
    }
    if (addDrawerType === 'type') {
      if (editConfigData && !checkIfExistsInArray(typeList, { ...editConfigData, ...data }, EXCEPTION_TYPE_NAME_KEY, EXCEPTION_TYPE_ID, { ...editConfigData, ...data }[EXCEPTION_TYPE_ID])) {
        // type edit
        dispatch(saveApplicationExceptionType({ ...editConfigData, ...data }, false));
        successNotification(textConstants.TYPE_CONFIG_UPDATE_SUCCESS_MESSAGE)
      } else if (!checkIfExistsInArray(typeList, data, EXCEPTION_TYPE_NAME_KEY)) {
        // type add
        dispatch(saveApplicationExceptionType(data, true));
        successNotification(textConstants.TYPE_CONFIG_ADD_SUCCESS_MESSAGE)
      } else {
        errorNotification(textConstants.TYPE_CONFIG_ALREADY_EXIST_MESSAGE);
      }
    }
    if (isClosable) {
      closeAddConfigDrawer()
    }
  }
  return (
    <>
      <ListConfigMainWrapper data-testid="testid-application-detail-list-config-tab">
        <ListConfigTableSectionWrapper>
          <ConfigurationListTableComponentWrapper
            columnDefs={EXCEPTION_CATEGORY_COLUMN_CONFIG}
            rowData={categroyList}
            onAdd={() => { setAddDrawerVisibility(true); setAddDrawerType('category') }}
            onEdit={(data: any) => { setAddDrawerType('category'); setAddDrawerVisibility(true); setEditConfigData(data) }}
            onDelete={deleteApplicationExceptionCategory}
            testId={'test-id-category'}
          />
        </ListConfigTableSectionWrapper>
        <ListConfigTableSectionWrapper>
          <ConfigurationListTableComponentWrapper
            columnDefs={EXCEPTION_SEVERITIES_COLUMN_CONFIG}
            rowData={severityList}
            onAdd={() => { setAddDrawerType('severity'); setAddDrawerVisibility(true) }}
            onEdit={(data: any) => { setAddDrawerType('severity'); setAddDrawerVisibility(true); setEditConfigData(data) }}
            onDelete={deleteApplicationExceptionSeverity}
            testId={'test-id-severity'}
          />
        </ListConfigTableSectionWrapper>
        <ListConfigTableSectionWrapper>
          <ConfigurationListTableComponentWrapper
            columnDefs={EXCEPTION_TYPES_COLUMN_CONFIG}
            rowData={typeList}
            onAdd={() => { setAddDrawerType('type'); setAddDrawerVisibility(true) }}
            onEdit={(data: any) => { setAddDrawerType('type'); setAddDrawerVisibility(true); setEditConfigData(data) }}
            onDelete={deleteApplicationExceptionType}
            testId={'test-id-type'}
          />
        </ListConfigTableSectionWrapper>
      </ListConfigMainWrapper>
      {
        isAddDrawerVisible &&
        <AddConfigDrawer
          isOpen={isAddDrawerVisible}
          dataTestId='test-id-list-config-drawer'
          title={(addDrawerType === 'category' ? textConstants.LIST_CATEGORY_CONFIG_ADD_DRAWER_TITLE : null) ??
            (addDrawerType === 'type' ? textConstants.LIST_TYPE_CONFIG_ADD_DRAWER_TITLE : null) ??
            (addDrawerType === 'severity' ? textConstants.LIST_SEVERITY_CONFIG_ADD_DRAWER_TITLE : null) ?? ''
          }
          onCancel={closeAddConfigDrawer}
        >
          <ListConfigForm
            data={editConfigData ?? { applicationName: applicationDetailData?.applicationName }}
            onSave={onSaveHandler}
            onCancel={closeAddConfigDrawer}
            type={addDrawerType}
          />
        </AddConfigDrawer>
      }
    </>
  );
};

ListConfigTabContent.propTypes = {
  applicationDetailData: PropTypes.object
};

const ConfigurationListTableComponentWrapper: React.FC<{
  columnDefs: any[]
  rowData: any[]
  onAdd: any
  onEdit: any
  onDelete: any
  testId: string
}> = ({
  columnDefs,
  rowData,
  onAdd,
  onEdit,
  onDelete,
  testId
}) => {
  const userAccessInfo = useSelector((state: any) => state.userAccess?.userAccessInfo);

  return (
    <ApplicationTabContentMainWrapper data-testid={testId + '-wrapper'}>
      {userAccessInfo?.adminAccess?.toUpperCase() === 'Y' && (
        <ListConfigTableSectionHeaderWrapper>
          <AddButton onClick={onAdd} testId={testId + '-addBtn'} />
        </ListConfigTableSectionHeaderWrapper>
      )}
      <ConfigurationListTable
        height={'45vh'}
        columnDefs={columnDefs}
        rowData={rowData}
        getConfigList={undefined}
        listName={''}
        showSearchBar={false}
        showResultsCount={false}
        hasRowAction
        className={'right-side-actions-small'}
        onEdit={onEdit}
        onConfigDelete={onDelete}
      />
    </ApplicationTabContentMainWrapper>
  );
}
ConfigurationListTableComponentWrapper.propTypes = {
  columnDefs: PropTypes.array.isRequired,
  rowData: PropTypes.array.isRequired,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  testId: PropTypes.string.isRequired
};
export default ListConfigTabContent;
