import {
  GET_FILE_REPLAY_DASHBOARD_DATA,
  SET_FILE_REPLAY_DASHBOARD_DATA,
  GET_REPLAY_FILE_DETAILS,
  SET_REPLAY_FILE_DETAILS
} from '../ActionTypes/FileReplay/fileReplayTypes';

interface GetApplicationDetailsAction {
  type: string
}

interface SetApplicationDetailsAction {
  type: string
  data: any
}

export function getFileReplayDashboardData (): GetApplicationDetailsAction {
  return {
    type: GET_FILE_REPLAY_DASHBOARD_DATA
  };
}

export function setFileReplayDashboardData (data: any): SetApplicationDetailsAction {
  return {
    type: SET_FILE_REPLAY_DASHBOARD_DATA,
    data
  };
}

export function getReplayFileDetails (replayID: string): any {
  return {
    type: GET_REPLAY_FILE_DETAILS,
    replayID
  };
}

export function setReplayFileDetails (data: any): SetApplicationDetailsAction {
  return {
    type: SET_REPLAY_FILE_DETAILS,
    data
  };
}
