import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import {
  logReportConfigFieldlabels
} from '../../../../../constants/FormFields/AdminForm/logReportConfigFormFields';
import { LabelValueWrapper, LabelWrapper } from '../../../../../assets/styles/commonStyled';
import { SummaryViewRow } from '../style';

interface ConfigSummaryProps {
  data?: any
}

const columnSpan = {
  xs: 24,
  md: 12,
  xxl: 8
}

const LogReportConfigSummary: FC<ConfigSummaryProps> = ({ data }) => {
  return (
    <>
      <SummaryViewRow
        gutter={[16, 8]}
        className='mb-15'
      >
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{logReportConfigFieldlabels.logConfigId}</LabelWrapper>
          <LabelValueWrapper>{data?.logConfigId}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{logReportConfigFieldlabels.applicationName}</LabelWrapper>
          <LabelValueWrapper>{data?.applicationName.join(', ')}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{logReportConfigFieldlabels.mailID}</LabelWrapper>
          <LabelValueWrapper>{data?.mailID}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{logReportConfigFieldlabels.status}</LabelWrapper>
          <LabelValueWrapper>{data?.status?.join(', ')}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{logReportConfigFieldlabels.enableNotification}</LabelWrapper>
          <LabelValueWrapper>{data?.enableNotification ? 'Yes' : 'No'}</LabelValueWrapper>
        </Col>
      </SummaryViewRow>
    </>
  );
};

LogReportConfigSummary.propTypes = {
  data: PropTypes.object
};

export default LogReportConfigSummary;
