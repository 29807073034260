import { call, fork, all, takeLatest, put } from 'redux-saga/effects';
import type {
  AllEffect,
  CallEffect,
  ForkEffect,
  PutEffect
} from 'redux-saga/effects';
import apiURL from '../../../constants/apiEndpointConstants';
import { apiRequest } from '../../../services/axiosInterceptor';
import { createLogger } from 'redux-logger';
import { GET_TABLE_META_DATA_LIST, SAVE_TABLE_CONFIG } from '../../actions/ActionTypes/Admin/tableConfigTypes';
import {
  setTableConfigList
} from '../../actions/Admin/tableConfigActions';
import { updateDataLoadingStatus } from '../../actions/CommonConfig/commonConfigActions';
import { API_SUCCESS_STATUS_CODE } from '../../../constants/commonConstants';
import { TABLE_CONFIG_ID } from '../../../constants/KeyLabels/commonKeyConstants';
import { successNotification } from '../../../components/common/Notification';

export function * fetchTableMetaDataList (
  payload: any
): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const apiConfig = {
      method: 'GET',
      url: apiURL.TABLE_META_CONFIG
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield call(apiRequest, apiConfig);
    if (response?.status === API_SUCCESS_STATUS_CODE) {
      yield put(
        setTableConfigList({
          data: response?.data,
          action: 'NEW'
        })
      );
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * saveTableConfigSaga ({ payload }: any): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: payload?.isAdd ? 'POST' : 'PUT',
      url: apiURL.SAVE_UPDATE_TABLE_META_CONFIG,
      data: payload?.data
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield call(apiRequest, config);

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        data: response?.data,
        action: payload?.isAdd ? 'ADD' : 'UPDATE',
        ...(!payload?.isAdd && {
          keyName: TABLE_CONFIG_ID,
          keyValue: response.data[TABLE_CONFIG_ID]
        })
      };

      yield put(setTableConfigList(storePayload));

      successNotification(`Table Configuration ${payload?.isAdd ? 'added' : 'updated'} successfully`);

      if (payload.callBackFunc) {
        payload.callBackFunc();
      }
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * getTableMetaDataList (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_TABLE_META_DATA_LIST, fetchTableMetaDataList);
}

export function * saveTableConfigData (): Generator<any> {
  yield takeLatest(SAVE_TABLE_CONFIG, saveTableConfigSaga);
}

export default function * root (): Generator<AllEffect<any>> {
  yield all([
    fork(getTableMetaDataList),
    fork(saveTableConfigData)
  ]);
}
