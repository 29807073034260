import { DATE_TIME_KEY, CONFIG_TABLE_SEARCH_FILTER_KEY } from '../constants/KeyLabels/commonKeyConstants';
import { RECENT_DATE_TIME_LOCAL_STORAGE_LIMIT } from '../constants/commonConstants';

export const saveRecentTimeInLocalStorage = (searchQuery: any): void => {
  const newDate = JSON.stringify(searchQuery?.[DATE_TIME_KEY]);
  let recentDateTimeFromLocal = JSON.parse(localStorage.getItem('recentDateTime') ?? '[]');

  if (!recentDateTimeFromLocal.some((obj: any) => obj.toString() === newDate)) {
    if (recentDateTimeFromLocal.length === RECENT_DATE_TIME_LOCAL_STORAGE_LIMIT) {
      recentDateTimeFromLocal.pop();
    }
    recentDateTimeFromLocal = [newDate, ...recentDateTimeFromLocal];
    localStorage.setItem('recentDateTime', JSON.stringify(recentDateTimeFromLocal));
  }
};
export const getRecentTimeInLocalStorage = (): any => {
  return JSON.parse(localStorage.getItem('recentDateTime') ?? '[]');
};

export const getLastSelectedApplicationDetailTab = (): string | null => {
  return localStorage.getItem('selectedApplicationDetailTab');
};

export const setLastSelectedApplicationDetailTab = (selectedKey: string): void => {
  localStorage.setItem('selectedApplicationDetailTab', selectedKey);
};

export const resetLastSelectedApplicationDetailTab = (): void => {
  localStorage.removeItem('selectedApplicationDetailTab');
};

export const setKeyDataInLocalStorage = (key: string, data: any): void => {
  localStorage.setItem(key, JSON.stringify(data));
}

export const getCachedTableFilterData = (key?: string): any => {
  const filterData = JSON.parse(localStorage.getItem(CONFIG_TABLE_SEARCH_FILTER_KEY) ?? '{}');
  return key ? filterData[key] : filterData;
};
