export const userAccessFormFields = [
  {
    label: 'User ID',
    name: 'userID',
    id: 'userID',
    required: true,
    rules: [{ required: true, message: 'User ID is mandatory!' }],
    type: 'text',
    disableOnEdit: true
  },
  {
    label: 'First Name',
    name: 'firstName',
    id: 'firstName',
    required: true,
    rules: [{ required: true, message: 'First Name is mandatory!' }],
    type: 'text'
  },
  {
    label: 'Last Name',
    name: 'lastName',
    required: true,
    rules: [{ required: true, message: 'Last Name is mandatory!' }],
    id: 'lastName',
    type: 'text'
  },
  {
    label: 'Email',
    name: 'email',
    id: 'email',
    rules: [
      { required: true, message: 'Email is mandatory!' },
      {
        type: 'email',
        message: 'Email is not valid!'
      }
    ],
    required: true,
    type: 'text'
  },
  {
    label: 'Admin Access',
    name: 'adminAccess',
    id: 'adminAccess',
    type: 'radio'
  },
  {
    label: 'File Replay Access',
    name: 'bulkReply',
    id: 'bulkReply',
    type: 'radio'
  }
];
