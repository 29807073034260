import React from 'react';
import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import AdminDetailBreadCrumb from '../../../../components/common/BreadCrumb/AdminDetailBreadCrumb';
import SolmanConfigSummary from '../../../../components/AdditionalComponents/DetailSummaryPage/Admin/SolmanConfig/SolmanConfigSummary';
import ConfigDetailCardHeader from '../../../../components/AdditionalComponents/ConfigDetailCardHeader';
import textConstants from '../../../../constants/textConstants';
import { AdminMainLayoutWrapper } from '../../../../assets/styles/commonStyled';
import { ConfigDetailWrapper } from '../../../../components/AdditionalComponents/DetailSummaryPage/Admin/style';

const SolmaStagingConfigDetail: FC = () => {
  const location = useLocation();
  const { data } = location?.state as { data: any } ?? {};

  return (
    <AdminMainLayoutWrapper data-testid="solmanStagingConfigDetail">
      <AdminDetailBreadCrumb
        mainPageText={textConstants.SOLMAN_CONFIG_PAGE_TITLE}
        currentPageText={data?.applicationName}
      />
      <ConfigDetailWrapper>
        <ConfigDetailCardHeader
          isDeleteEnabled={false}
          title={textConstants.SOLMAN_CONFIG_DETAIL_PAGE_TITLE}
          configName={textConstants.SOLMAN_CONFIG_PAGE_TITLE}
        />
        <SolmanConfigSummary data={data} />
      </ConfigDetailWrapper>
    </AdminMainLayoutWrapper>
  );
}

export default SolmaStagingConfigDetail;
