import styled from 'styled-components';

export const RangePickerPanelWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const RangePickerOrgPanelWrapper = styled.div``;
export const RangePickerAdditionalPanelWrapper = styled.div`
  max-width: 250px;
  border-right: 1px solid var(--antd-default-border);
`;

export const AdditionalPanelHeader = styled.div`
  text-align: center;
  font-weight: 600;
  border-bottom: 1px solid var(--antd-default-border);
  padding: 10px;
  font-size: 13px;
`;

export const AdditionalPanelItemWrapper = styled.div`
  padding: 5px 10px;
  display: flex;
  flex-wrap: wrap;
`;

export const AdditionalPanelItem = styled.div`
  padding: 5px 10px;
  background: #f6f6f6;
  margin: 5px 5px;
  border-radius: 5px;
`;

export const RangePickerAdditionalFooter = styled.div`
  padding: 5px 10px;
  >*{
    margin-right:20px;
    line-height: 25px;
  }
`;
export const RangePickerAdditionalFooterHeader = styled.div`
`;
