import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { USER_LIST_COLUMNS } from '../../../../../../constants/TableColumns/AccessManagement/UserListColumns';
import ConfigurationListTable from '../../../../ConfigurationListTable';
import { useDispatch, useSelector } from 'react-redux';
import { getUserList } from '../../../../../../redux/actions/AccessManagement/UserAccess/userAccessListActions';
import { GroupSelectorWrapper, UserListContentWrapper, UserListWithGroupWrapper } from './style';
import { checkBoxFieldCol } from '../../../../../../constants/TableColumns/tableCheckboxColumn';
import { getGroupApplicationAccessList, getGroupList, saveGroupConfig } from '../../../../../../redux/actions/AccessManagement/GroupAccess/groupAccessActions';
import { filterListByApplicationName } from '../../../../../../helpers/applicationDataHelper';
import { getUserDataByIds, getUsersNotInList } from '../../../../../../helpers/commonHelper';
import FloatingLabel from '../../../../../common/FloatingLabel';

interface componentProps {
  applicationName?: any
  isEditable?: boolean
}

const UserListTabContent: FC<componentProps> = ({ applicationName, isEditable }) => {
  const dispatch = useDispatch();

  const globalUserListData = useSelector((state: any) => state.userConfig?.userConfigList ?? []);
  const globalGroupApplicationAccessList = useSelector((state: any) => state.groupConfig?.groupApplicationAccessList ?? []);
  const globalGroupListData = useSelector((state: any) => state.groupConfig?.groupConfigList ?? []);

  const [isAddUserListVisible, setAddUserListVisible] = useState(false);
  const [groupNameList, setGroupNameList] = useState<string[]>([]);
  const [userIdList, setUserIdList] = useState<string[]>([]);
  const [selectedGroupName, setSelectedGroupName] = useState('All');

  useEffect(() => {
    if (globalUserListData?.length === 0) {
      dispatch(getUserList());
    }
    if (globalGroupApplicationAccessList?.length === 0) {
      dispatch(getGroupApplicationAccessList());
    }
    if (globalGroupListData?.length === 0) {
      dispatch(getGroupList());
    }
    loadUserDataForSelectedGroup('All');
  }, []);
  useEffect(() => {
    if (globalGroupApplicationAccessList?.length > 1 && applicationName) {
      const groupList: string[] = filterListByApplicationName(globalGroupApplicationAccessList, applicationName)?.map(groupObj => groupObj?.groupName);
      setGroupNameList(groupList);
    }
  }, [globalGroupApplicationAccessList]);
  useEffect(() => {
    loadUserDataForSelectedGroup(selectedGroupName);
  }, [globalGroupListData, globalUserListData, groupNameList])

  const loadUserDataForSelectedGroup = (selectedGroup: string): void => {
    const groupListWithUserList = globalGroupListData?.filter((groupObj: any) => groupNameList?.includes(groupObj?.groupName));
    let userIdTempList: string[] = [];
    if (selectedGroup === 'All') {
      groupListWithUserList.forEach((groupObjItem: any) => {
        userIdTempList = [...userIdTempList, ...groupObjItem.userList]
      })
    } else {
      const selectedGroupObj = groupListWithUserList.filter((groupObj: any) => groupObj?.groupName?.toLowerCase() === selectedGroup.toLowerCase());
      userIdTempList = selectedGroupObj.length > 0 ? selectedGroupObj[0]?.userList : [];
    }
    setUserIdList(userIdTempList);
  }

  const addSelectedUsersToList = (data: any): void => {
    const userIdsToAdd = data.map((item: any) => item.userID);
    const selectedGroupObj = globalGroupListData?.filter((groupObj: any) => groupObj?.groupName?.toLowerCase() === selectedGroupName.toLowerCase());
    dispatch(saveGroupConfig({ ...selectedGroupObj[0], userList: [...(selectedGroupObj[0].userList ?? []), ...userIdsToAdd] }));
    setAddUserListVisible(false);
    loadUserDataForSelectedGroup(selectedGroupName);
  }

  const removeSelectedUsersFromGroup = (data: any): void => {
    const userIdsToRemove = data.map((item: any) => item.userID);
    const selectedGroupObj = globalGroupListData?.filter((groupObj: any) => groupObj?.groupName?.toLowerCase() === selectedGroupName.toLowerCase());
    const updatedUserIds = selectedGroupObj[0]?.userList?.filter((userId: string) => !userIdsToRemove.includes(userId));

    dispatch(saveGroupConfig({ ...selectedGroupObj[0], userList: updatedUserIds }));
    loadUserDataForSelectedGroup(selectedGroupName);
  }

  return (
    <>
      <UserListContentWrapper data-testid='test-id-user-mgmnt-user-content-main-wrapper'>
        {
          !isAddUserListVisible &&
          <UserListWithGroupWrapper>
            <GroupSelectorWrapper>
              <FloatingLabel
                type={'select'}
                options={['All', ...groupNameList]?.map(x => ({ label: x, value: x }))}
                onSelect={(changedVal: string) => { loadUserDataForSelectedGroup(changedVal); setSelectedGroupName(changedVal); }}
                label={''}
                defaultValue={'All'}
                value={selectedGroupName}
                data-testid='test-id-user-mgmnt-group-selector'
                id={'groupSelectorId'}
              />
            </GroupSelectorWrapper>
            <ConfigurationListTable
              columnDefs={selectedGroupName !== 'All' ? [checkBoxFieldCol, ...USER_LIST_COLUMNS] : USER_LIST_COLUMNS}
              rowData={getUserDataByIds(globalUserListData, userIdList)}
              getConfigList={undefined}
              listName={'userConfig'}
              gridRowId='userID'
              hasFilter={false}
              onCreate={() => setAddUserListVisible(true)}
              hasCreate={selectedGroupName !== 'All' && isEditable}
              creatBtnLabel='Add Users'
              createBtnClass='add-user-without-bg-btn-class'
              hasCustomDelete
              deleteBtnLabel='Remove Users'
              onConfigDelete={removeSelectedUsersFromGroup}
              rowSelection='multiple'
              rowMultiSelectWithClick
              hasHeaderDelete={selectedGroupName !== 'All' && isEditable}
            />
          </UserListWithGroupWrapper>
        }
        {
          isAddUserListVisible &&
          <ConfigurationListTable
            columnDefs={[checkBoxFieldCol, ...USER_LIST_COLUMNS]}
            rowData={getUsersNotInList(globalUserListData, userIdList)}
            getConfigList={undefined}
            listName={'userConfig'}
            gridRowId='userID'
            hasFilter={false}
            onCreate={addSelectedUsersToList}
            hasCreate
            hasCustomAdd
            createBtnClass='btn-secondary'
            rowSelection='multiple'
            rowMultiSelectWithClick
            hasClose
            onClose={() => setAddUserListVisible(false)}
          />
        }
      </UserListContentWrapper>
    </>
  );
};

UserListTabContent.propTypes = {
  applicationName: PropTypes.string,
  isEditable: PropTypes.bool
};

export default UserListTabContent;
