import styled, { css } from 'styled-components';

export const ConfigDetailHeaderWrapper = styled.div<{ hasRightGap?: boolean | undefined }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  min-height: 30px;
  width: 100%;

  &.mb-0 {
    margin-bottom: 0;
  }

  ${({ hasRightGap }) =>
    hasRightGap &&
      css`
        & > div {
          padding-right: 20px;
        }
      `}
`;
