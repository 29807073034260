import React from 'react';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import routeConstants from '../../routeConstants';

interface componentProps {
  value?: any
}

const ReplayDetailLinkRenderer: FC<componentProps> = ({ ...params }) => {
  return (
    <Link
      to={
        {
          pathname: routeConstants.EXCEPTION_FILE_REPLAY_DETAIL,
          state: {
            replayID: params?.value
          }
        }
      }
      >{params?.value}</Link>
  );
};

export default ReplayDetailLinkRenderer;
