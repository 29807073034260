import { apiRequest } from './axiosInterceptor';
import textConstants from '../constants/textConstants';

export const fileReplayUploadAPIRequestHandler = (
  request: any,
  url: string,
  setUploadCompletion: any
): void => {
  const config = {
    method: 'POST',
    data: request,
    headers: { 'Content-Type': 'multipart/form-data' },
    url
  };

  apiRequest(config)
    .then((response: any) => {
      if (response) {
        setUploadCompletion(true, textConstants.FILE_REPLAY_SUCCESS_MESSAGE);
      }
    })
    .catch(() => {
      setUploadCompletion(true, '');
    });
};
