import { call, put, fork, all, takeLatest } from 'redux-saga/effects';
import type { AllEffect, CallEffect, ForkEffect, PutEffect } from 'redux-saga/effects';
import { createLogger } from 'redux-logger';
import { updateDataLoadingStatus } from '../../actions/CommonConfig/commonConfigActions';
import { setLogReportConfigList } from '../../actions/Admin/logReportConfigActions';
import { DELETE_LOG_REPORT_CONFIG, GET_LOG_REPORT_CONFIG_LIST, SAVE_LOG_REPORT_CONFIG } from '../../actions/ActionTypes/Admin/logReportConfigTypes';
import { API_SUCCESS_STATUS_CODE } from '../../../constants/commonConstants';
import { LOG_REPORT_ID } from '../../../constants/KeyLabels/commonKeyConstants';
import { successNotification } from '../../../components/common/Notification';
import { apiRequest } from '../../../services/axiosInterceptor';
import apiURL from '../../../constants/apiEndpointConstants';
import textConstants from '../../../constants/textConstants';

const callEffect: any = call;

export function * fetchLogReportConfigListSaga (): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: 'get',
      url: apiURL.GET_LOG_REPORT_CONFIG_LIST
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    const payload = {
      data: response?.data,
      action: 'NEW'
    };

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      yield put(setLogReportConfigList(payload));
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * saveLogReportConfigSaga ({ payload }: any): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: payload?.isAdd ? 'POST' : 'PUT',
      url: apiURL.SAVE_UPDATE_LOG_REPORT_CONFIG,
      data: payload?.data
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        data: response?.data,
        action: payload?.isAdd ? 'ADD' : 'UPDATE',
        ...(!payload?.isAdd && {
          keyName: LOG_REPORT_ID,
          keyValue: response.data[LOG_REPORT_ID]
        })
      };

      yield put(setLogReportConfigList(storePayload));

      if (payload?.callBackFunc) {
        payload.callBackFunc();
      }

      successNotification(`Log Report ${payload?.isAdd ? 'added' : 'updated'} successfully`);
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * deleteLogReportConfigSaga ({ payload }: any): any {
  try {
    const config = {
      method: 'DELETE',
      url: apiURL.DELETE_LOG_REPORT_CONFIG,
      data: payload?.data
    };

    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        action: 'DELETE',
        keyName: LOG_REPORT_ID,
        keyValue: response.data[0][LOG_REPORT_ID]
      };

      yield put(setLogReportConfigList(storePayload));

      if (payload?.callBackFunc) {
        payload.callBackFunc();
      }
      successNotification(textConstants.LOG_REPORT_CONFIG_DELETE_SUCCESS_MESSAGE);
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * getLogReportConfigList (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_LOG_REPORT_CONFIG_LIST, fetchLogReportConfigListSaga);
}

export function * deleteLogReportConfigItem (): Generator<any> {
  yield takeLatest(DELETE_LOG_REPORT_CONFIG, deleteLogReportConfigSaga);
}

export function * saveLogReportConfigItem (): Generator<any> {
  yield takeLatest(SAVE_LOG_REPORT_CONFIG, saveLogReportConfigSaga);
}

export default function * root (): Generator<AllEffect<any>> {
  yield all([
    fork(getLogReportConfigList),
    fork(deleteLogReportConfigItem),
    fork(saveLogReportConfigItem)
  ]);
}
