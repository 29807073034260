import styled from 'styled-components';

export const ExportFieldWrapper = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    max-height: 42vh;
    overflow-y: auto;
`;

export const ExportPopoverWrapper = styled.div`
    padding: 0 5px;

    h1 {
        font-size: var(--popover-title-font-size);
        font-weight: var(--popover-title-font-weight);
        color: var(--popover-title-color);
        margin-bottom: 10px;
    }

    .ant-checkbox-wrapper {
        color: var(--gray-800);
        font-size: var(--font-size-sm);
        margin-bottom: 7px;
    }

    .ant-btn {
        text-transform: uppercase;
    }
`;

export const ExportReplayField = styled.div`
    border-bottom: 1px solid var(--gray-250);
    padding-bottom: 6px;
    margin-bottom: 5px;

    .ant-checkbox-wrapper {
        color: var(--blue-color);
        font-size: var(--font-size-md);
        margin-bottom: 0;
    }
`;
