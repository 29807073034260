import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfigurationListTable from '../../../ConfigurationListTable';
import { BLOB_METRICS_SUMMARY_COLUMNS } from '../../../../../constants/TableColumns/Admin/BlobMetricsColumns';
import {
  getBlobMetricSummaryLogData,
  getBlobMetricSummaryExceptionData
} from '../../../../../redux/actions/Admin/blobMetricsActions';
import BlobMetricsCountBar from '../../../BlobMetrics/BlobMetricsCountBar';
import { filterTableData, getMetricTypeCount } from '../../../../../helpers/blobMetricsHelper';
import type { BlobMetricSummaryFilterdList, BlobMetricSummaryFilters, BlobSummaryListItem } from '../../../../../constants/Interfaces/BlobMetricsTypes';
import BlobMetricSearchPanel from '../../../BlobMetrics/BlobMetricSearchPanel';

const SummaryTab: FC = () => {
  const dispatch = useDispatch();
  const blobSummaryLogList = useSelector(
    (state: any) => state.blobMetrics.logSummaryList
  );
  const blobSummaryExceptionList = useSelector(
    (state: any) => state.blobMetrics.exceptionSummaryList
  );
  const [blobSummaryList, setBlobSummaryList] = useState<BlobSummaryListItem[]>([]);
  const [blobSummaryFilteredData, setBlobSummaryFilteredData] = useState<BlobMetricSummaryFilterdList>([]);
  const [appliedFilters, setAppliedFilters] = useState<BlobMetricSummaryFilters>({
    application: null,
    type: null
  })

  const onDataFilterChange = (selectedValue: any[], fieldName: string): void => {
    const filterConfig = { ...appliedFilters };

    if (fieldName === 'blobMetricType') {
      filterConfig.type = selectedValue;
    } else {
      filterConfig.application = selectedValue.map((item: any) => item?.label);
    }

    setAppliedFilters(filterConfig);
  }

  const onDateChangeHandler = (value: string): void => {
    dispatch(getBlobMetricSummaryLogData(value));
    dispatch(getBlobMetricSummaryExceptionData(value));
  };

  useEffect(() => {
    onDateChangeHandler('daily');
  }, []);

  useEffect(() => {
    setBlobSummaryList([...blobSummaryLogList, ...blobSummaryExceptionList]);
    const filteredData = filterTableData([...blobSummaryLogList, ...blobSummaryExceptionList], appliedFilters);
    setBlobSummaryFilteredData(filteredData);
  }, [blobSummaryLogList, blobSummaryExceptionList]);

  useEffect(() => {
    const filteredData = filterTableData(blobSummaryList, appliedFilters);

    setBlobSummaryFilteredData(filteredData);
  }, [appliedFilters]);

  return (
    <>
      <BlobMetricSearchPanel
        onFilterChange={onDataFilterChange}
        onDateRangeChange={onDateChangeHandler}
      />
      <BlobMetricsCountBar
        data={{
          totalCount: blobSummaryFilteredData?.length,
          logCount: getMetricTypeCount(blobSummaryFilteredData, 'Log'),
          exceptionCount: getMetricTypeCount(blobSummaryFilteredData, 'Exception')
        }}
      />
      <ConfigurationListTable
        columnDefs={BLOB_METRICS_SUMMARY_COLUMNS}
        getConfigList={null}
        listName='BlobConfig'
        rowData={blobSummaryFilteredData}
        hasFilter={false}
        showResultsCount={false}
      />
    </>
  );
};

export default SummaryTab;
