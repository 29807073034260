import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import LogExceptionSearchPanel from '../LogExceptionSearchPanel';
import Tabs from '../../../common/Tabs';
import { TabContentWrapper, TopSearchContentWrapper, LogSearchBottomTableContentWrapper, SearchTabWrapper } from './style';
import RecentSearchTableList from '../../TabContents/RecentSavedSearch/RecentSearchTableList';
import SavedSearchTableList from '../../TabContents/RecentSavedSearch/SavedSearch';
import LogExpTable from '../LogExpSearchResultTable';
import { LogSearchResultColumns } from '../../../../constants/TableColumns/LogSearchResultColumns';
import { ExceptionSearchResultColumns } from '../../../../constants/TableColumns/ExceptionSearchResultColumns';
import { generatePayloadForLogExceptionSearch, getRecordsCountByType } from '../../../../helpers/ServiceHelpers/logExceptionSearchServiceHelper';
import { fetchSearchData } from '../../../../redux/actions/LogExceptionSearch/logExceptionSearchActions';
import { storeLogSearchQuery } from '../../../../redux/actions/LogSearch/logSearchActions';
import { storeExceptionSearchQuery } from '../../../../redux/actions/ExceptionSearch/exceptionSearchActions';
import { infoNotification } from '../../../common/Notification';
import { saveRecentSearchQuery } from '../../../../helpers/saveRecentSearchHelper';
import textConstants from '../../../../constants/textConstants';
import Loader from '../../../common/Loader';
import { saveRecentTimeInLocalStorage } from '../../../../helpers/localStorageHelper';

interface componentProps {
  activeTabName: string
  applicationList: any
  categoryList?: any[]
  fetchAppplicationList: any
  fetchExceptionCategory?: any
  fetchExceptionSeverity?: any
  fetchExceptionType?: any
  severityList?: any[]
  userAccessInfo?: string
  logExceptionType: string
  timeZone: boolean
  typeList?: any[]
}

const SearchTabComponent: FC<componentProps> = ({
  activeTabName,
  applicationList,
  categoryList,
  fetchAppplicationList,
  fetchExceptionCategory,
  fetchExceptionSeverity,
  fetchExceptionType,
  logExceptionType,
  severityList,
  userAccessInfo,
  timeZone,
  typeList
}) => {
  const [isSearchClicked, setSearchClickStatus] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [searchedRecordCount, setSearchRecordCount] = useState<number | undefined>();
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const [selectedTabData, setSelectedTabData] = useState<any | undefined>();
  const globalLogTabList = useSelector((state: any) => state?.logSearchReducer?.logTabList);
  const globalExceptionTabList = useSelector((state: any) => state?.exceptionSearchReducer?.exceptionTabList);
  const dispatch = useDispatch();

  const fetchExceptionCategoryTypeSeverity = (): void => {
    if (categoryList && categoryList.length < 1 && !sessionStorage.getItem('applicationCategoryData')) {
      fetchExceptionCategory();
    }

    if (typeList && typeList.length < 1 && !sessionStorage.getItem('applicationTypeData')) {
      fetchExceptionType();
    }

    if (severityList && severityList.length < 1 && !sessionStorage.getItem('applicationSeverityData')) {
      fetchExceptionSeverity();
    }
  }

  const fetchAppplication = useCallback(() => {
    fetchAppplicationList();
  }, [])

  useEffect(() => {
    if (logExceptionType !== 'Log') {
      fetchExceptionCategoryTypeSeverity();
    }
    return () => {
      // cancel search api calls
      // dispatch(cancelSearchApiCall());
    }
  }, []);

  // updating latest state when tab is changed.
  useEffect(() => {
    let selectedTab: any;
    if (logExceptionType && logExceptionType?.toString().toLowerCase() === 'log' && globalLogTabList && globalLogTabList.length > 0) {
      selectedTab = globalLogTabList?.find((tabItem: any) => tabItem.key === activeTabName);
    } else {
      selectedTab = globalExceptionTabList?.find((tabItem: any) => tabItem.key === activeTabName);
    }

    if (selectedTab && Object.keys(selectedTab).length > 0) {
      setSearchRecordCount(selectedTab?.searchRecordCount);
      setSearchResults(selectedTab?.searchResults);
      setSearchQuery(selectedTab?.searchQuery);
      setIsSearched(selectedTab?.searchResults?.length > 0 ? true : selectedTab?.isSearched);
      setSelectedTabData(selectedTab);
    }
  }, [activeTabName, globalLogTabList, globalExceptionTabList]);

  // function to handle search button click
  const searchDataHandler = useCallback(async (newSearchQuery: any): Promise<void> => {
    setSearchClickStatus(true);
    setShowLoader(true);
    setSearchQuery(JSON.stringify(newSearchQuery));
    saveRecentSearchQuery(logExceptionType, newSearchQuery);
    saveRecentTimeInLocalStorage(newSearchQuery);
    sessionStorage.setItem(activeTabName, JSON.stringify(newSearchQuery));
    const countResponse = await getRecordsCountByType(logExceptionType, newSearchQuery);
    setSearchRecordCount(countResponse.totalCount);
    if (logExceptionType?.toString().toLowerCase() === 'log') {
      dispatch(storeLogSearchQuery(activeTabName, JSON.stringify(newSearchQuery), countResponse.totalCount));
    } else if (logExceptionType?.toString().toLowerCase() === 'exception') {
      dispatch(storeExceptionSearchQuery(activeTabName, JSON.stringify(newSearchQuery), countResponse.totalCount));
    }
    if (countResponse.totalCount > 0) {
      setSearchClickStatus(false);
      setShowLoader(false);
      setIsSearched(true);
      dispatch(fetchSearchData(countResponse.generatedPayloadWithRecordCount, logExceptionType, activeTabName, countResponse.totalCount));
    } else {
      setShowLoader(false);
      // notification for 0 records
      infoNotification('No Records found!')
    }
  }, [])

  return (
    <SearchTabWrapper>
      {showLoader && !isSearched && (
        <Loader
          isOverlayLoader
          loadingMessage={textConstants.DATA_LOADING_MESSAGE}
          size='small'
        >
          <></>
        </Loader>
      )}
      <TabContentWrapper>
        <TopSearchContentWrapper>
          <LogExceptionSearchPanel
            logExceptionSearchType={logExceptionType}
            applicationList={applicationList}
            fetchAppplicationList={fetchAppplication}
            userAccessInfo={userAccessInfo}
            timeZone={timeZone}
            searchDataHandler={searchDataHandler}
            initialSearchCriteria={searchQuery}
          />
        </TopSearchContentWrapper>
      </TabContentWrapper>
      <LogSearchBottomTableContentWrapper>
        {
          !isSearched &&
          <Tabs
            tabClassName='basic-theme'
            defaultActiveTab="RecentSearch"
            tabSize="small"
            tabType='line'
            tabItems={[
              {
                key: 'RecentSearch',
                label: 'Recent Search',
                children: <RecentSearchTableList logExceptionType={logExceptionType} />
              },
              {
                key: 'SavedSearch',
                label: 'Saved Search',
                children: <SavedSearchTableList logExceptionType={logExceptionType} showDeleteBtn />
              }
            ]}
          />
        }
        {
          isSearched &&
          <LogExpTable
            key={logExceptionType + '-' + activeTabName}
            activeTabName={activeTabName}
            showResultsCount
            totalRecordCount={searchedRecordCount}
            showSaveCriteriaButton
            showHeaderColumnConfigureButton
            showHeaderFilterButton
            showHeaderExportButton
            rowData={searchResults}
            logExceptionType={logExceptionType}
            columnDefs={logExceptionType === 'Log' ? LogSearchResultColumns : ExceptionSearchResultColumns}
            searchQuery={searchQuery}
            userAccessInfo={userAccessInfo}
            isSearchChanged={isSearchClicked}
            selectedTabData={selectedTabData}
          />
        }
      </LogSearchBottomTableContentWrapper>
    </SearchTabWrapper>
  );
};

SearchTabComponent.propTypes = {
  categoryList: PropTypes.array,
  severityList: PropTypes.array,
  logExceptionType: PropTypes.string.isRequired,
  activeTabName: PropTypes.string.isRequired,
  fetchAppplicationList: PropTypes.func.isRequired,
  fetchExceptionCategory: PropTypes.func,
  fetchExceptionSeverity: PropTypes.func,
  fetchExceptionType: PropTypes.func,
  typeList: PropTypes.array
};

export default SearchTabComponent;
