import { call, put, fork, all, takeLatest } from 'redux-saga/effects';
import type { AllEffect, CallEffect, ForkEffect, PutEffect } from 'redux-saga/effects';
import { createLogger } from 'redux-logger';
import { updateDataLoadingStatus } from '../../actions/CommonConfig/commonConfigActions';
import { setReplayConfigList } from '../../actions/Admin/replayConfigActions';
import { DELETE_REPLAY_CONFIG, GET_REPLAY_CONFIG_LIST, SAVE_REPLAY_CONFIG } from '../../actions/ActionTypes/Admin/replayConfigTypes';
import { API_SUCCESS_STATUS_CODE } from '../../../constants/commonConstants';
import { REPLAY_ID } from '../../../constants/KeyLabels/commonKeyConstants';
import { successNotification } from '../../../components/common/Notification';
import { apiRequest } from '../../../services/axiosInterceptor';
import apiURL from '../../../constants/apiEndpointConstants';
import textConstants from '../../../constants/textConstants';

const callEffect: any = call;

export function * fetchReplayConfigListSaga (): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: 'get',
      url: apiURL.GET_REPLAY_CONFIG_LIST
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    const payload = {
      data: response?.data,
      action: 'NEW'
    };

    if (response) {
      yield put(setReplayConfigList(payload));
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * saveReplayConfigSaga ({ payload }: any): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: payload?.isAdd ? 'POST' : 'PUT',
      url: apiURL.SAVE_UPDATE_REPLAY_CONFIG,
      data: payload?.data
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    const storePayload = {
      data: response?.data,
      action: payload?.isAdd ? 'ADD' : 'UPDATE',
      ...(!payload?.isAdd && {
        keyName: REPLAY_ID,
        keyValue: response.data[REPLAY_ID]
      })
    };

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      yield put(setReplayConfigList(storePayload));

      if (payload?.callBackFunc) {
        payload.callBackFunc();
      }

      successNotification(`Replay Configuration ${payload?.isAdd ? 'Added' : 'Updated'} Successfully`);
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * deleteReplayConfigSaga ({ payload }: any): any {
  try {
    const config = {
      method: 'DELETE',
      url: apiURL.DELETE_REPLAY_CONFIG,
      data: payload?.data
    };

    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        action: 'DELETE',
        keyName: REPLAY_ID,
        keyValue: response.data[0][REPLAY_ID]
      };

      yield put(setReplayConfigList(storePayload));

      if (payload?.callBackFunc) {
        payload.callBackFunc();
      }

      successNotification(textConstants.REPLAY_CONFIG_DELETE_SUCCESS_MESSAGE);
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * getReplayConfigList (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_REPLAY_CONFIG_LIST, fetchReplayConfigListSaga);
}

export function * deleteReplayConfigItem (): Generator<any> {
  yield takeLatest(DELETE_REPLAY_CONFIG, deleteReplayConfigSaga);
}

export function * saveReplayConfigItem (): Generator<any> {
  yield takeLatest(SAVE_REPLAY_CONFIG, saveReplayConfigSaga);
}

export default function * root (): Generator<AllEffect<any>> {
  yield all([
    fork(getReplayConfigList),
    fork(deleteReplayConfigItem),
    fork(saveReplayConfigItem)
  ]);
}
