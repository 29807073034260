import { apiRequest } from './axiosInterceptor';

export const commonApiRequestHandler = async (apiConfig: any): Promise<any> => {
  const response = await apiRequest(apiConfig);
  return response;
};

export const apiRequestHandler = async (apiConfig: any): Promise<any> => {
  const response = await apiRequest(apiConfig);
  return response?.data;
};
