import { APPLICATION_NAME_KEY, BUSINESS_ID_KEY, BUSINESS_ID_TWO_KEY, COMPONENT_NAME_KEY, DUMP_ANALYSIS_KEY, ERROR_CODE_KEY, EXCEPTION_CATEGORY_KEY, EXCEPTION_SEVERITY_KEY, EXCEPTION_STATUS_KEY, EXCEPTION_TYPE_KEY, HOST_NAME_KEY, JMS_HEADER_KEY, MSG_KEY, RESOLVED_KEY, SERVICE_NAME_KEY, TIMEMARK_KEY, TRANSACTION_DATA_KEY, TRANSACTION_DOMAIN_KEY, TRANSACTION_ID_KEY, TRANSACTION_TYPE_KEY } from '../KeyLabels/commonKeyConstants';
import { APPLICATION_NAME_LABEL, BUSINESS_ID_LABEL, BUSINESS_ID_TWO_LABEL, CATEGORY_LABEL, CODE_LABEL, COMPONENT_NAME_LABEL, DUMP_ANALYSIS_LABEL, HOST_NAME_LABEL, JSM_HEADER_LABEL, MESSAGE_LABEL, RESOLVED_LABEL, SERVICE_NAME_LABEL, SEVERITY_LABEL, STATUS_LABEL, TIME_OCCURED_LABEL, TRANSACTION_DATA_LABEL, TRANSACTION_DOMAIN_LABEL, TRANSACTION_ID_LABEL, TRANSACTION_TYPE_LABEL, TYPE_LABEL } from '../KeyLabels/labelConstants';

const exceptionFields = [
  {
    field: APPLICATION_NAME_KEY,
    label: APPLICATION_NAME_LABEL
  },
  {
    field: TIMEMARK_KEY,
    label: TIME_OCCURED_LABEL
  },
  {
    field: COMPONENT_NAME_KEY,
    label: COMPONENT_NAME_LABEL
  },
  {
    field: SERVICE_NAME_KEY,
    label: SERVICE_NAME_LABEL
  },
  {
    field: EXCEPTION_STATUS_KEY,
    label: STATUS_LABEL
  },
  {
    field: TRANSACTION_DOMAIN_KEY,
    label: TRANSACTION_DOMAIN_LABEL
  },
  {
    field: HOST_NAME_KEY,
    label: HOST_NAME_LABEL
  },
  {
    field: BUSINESS_ID_KEY,
    label: BUSINESS_ID_LABEL
  },
  {
    field: BUSINESS_ID_TWO_KEY,
    label: BUSINESS_ID_TWO_LABEL
  },
  {
    field: TRANSACTION_ID_KEY,
    label: TRANSACTION_ID_LABEL
  },
  {
    field: EXCEPTION_CATEGORY_KEY,
    label: CATEGORY_LABEL
  },
  {
    field: EXCEPTION_TYPE_KEY,
    label: TYPE_LABEL
  },
  {
    field: EXCEPTION_SEVERITY_KEY,
    label: SEVERITY_LABEL
  },
  {
    field: TRANSACTION_DATA_KEY,
    label: TRANSACTION_DATA_LABEL
  },

  {
    field: RESOLVED_KEY,
    label: RESOLVED_LABEL
  },
  {
    field: JMS_HEADER_KEY,
    label: JSM_HEADER_LABEL
  },
  {
    field: TRANSACTION_TYPE_KEY,
    label: TRANSACTION_TYPE_LABEL
  },
  {
    field: DUMP_ANALYSIS_KEY,
    label: DUMP_ANALYSIS_LABEL
  },
  {
    field: MSG_KEY,
    label: MESSAGE_LABEL
  },
  {
    field: ERROR_CODE_KEY,
    label: CODE_LABEL
  }
];

export default exceptionFields;
