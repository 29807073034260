import React, { useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import textConstants from '../../../constants/textConstants';

interface ConfirmationPopupProps {
  confirmBtnLabel?: string
  cancelBtnLabel?: string
  confirmationTitle?: string
  isConfirmOpen?: boolean
  isCentered?: boolean
  message?: string
  onConfirmHandler?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onCancelHandler?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const ConfirmationPopup: FC<ConfirmationPopupProps> = ({
  isCentered,
  isConfirmOpen,
  message,
  onConfirmHandler,
  onCancelHandler,
  confirmBtnLabel,
  cancelBtnLabel,
  confirmationTitle
}) => {
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    if (isConfirmOpen) {
      modal.confirm({
        title: confirmationTitle ?? textConstants.CONFIRM_POPUP_TITLE,
        icon: null,
        content: message,
        onOk: onConfirmHandler,
        onCancel: onCancelHandler,
        okButtonProps: {
          'data-testid': 'confirmBtn',
          className: 'btn-regular'
        },
        cancelButtonProps: {
          'data-testid': 'cancelBtn',
          className: 'btn-regular'
        },
        okText: confirmBtnLabel ?? textConstants.CONFIRM_OK_TEXT,
        cancelText: cancelBtnLabel ?? textConstants.CONFIRM_CANCEL_TEXT,
        centered: isCentered,
        className: 'confirmation-popup',
        closable: true
      });
    }
  }, []);

  return <>{contextHolder}</>;
};

ConfirmationPopup.defaultProps = {
  message: '',
  isConfirmOpen: false,
  isCentered: true
};

ConfirmationPopup.propTypes = {
  confirmBtnLabel: PropTypes.string,
  cancelBtnLabel: PropTypes.string,
  confirmationTitle: PropTypes.string,
  isConfirmOpen: PropTypes.bool,
  isCentered: PropTypes.bool,
  message: PropTypes.string,
  onConfirmHandler: PropTypes.func,
  onCancelHandler: PropTypes.func
};

export default ConfirmationPopup;
