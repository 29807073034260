import * as types from '../ActionTypes/ExceptionSearch/ExceptionSearchTypes';

interface dataInterface {
  type: string
  exceptionTabList?: []
  payload?: any
}

export function getExceptionSearchTabList (): dataInterface {
  return {
    type: types.GET_EXCEPTION_TABS_LIST
  };
}

export function addNewExceptionTab (newExceptionTabData: any): dataInterface {
  return {
    type: types.ADD_EXCEPTION_TAB,
    payload: { ...newExceptionTabData }
  };
}

export function updateExceptionTabList (newExceptionList: any): dataInterface {
  return {
    type: types.UPDATE_EXCEPTION_TAB_LIST,
    payload: newExceptionList
  };
}

export function removeExceptionTab (key: string): dataInterface {
  return {
    type: types.REMOVE_EXCEPTION_TAB,
    payload: { key }
  };
}

export function changeExceptionActiveTab (key: any): dataInterface {
  return {
    type: types.CHANGE_ACTIVE_EXCEPTION_TAB,
    payload: { key }
  };
}

export function storeExceptionSearchData (searchedTabKey: string, searchResults: any[], payloadForNextCall: string, hasNextPage: boolean): dataInterface {
  return {
    type: types.STORE_EXCEPTION_SEARCHED_DATA,
    payload: { searchedTabKey, searchResults, payloadForNextCall, hasNextPage }
  };
}

export function storeExceptionSearchQuery (searchedTabKey: string, searchQuery: any, recordCount: number): dataInterface {
  return {
    type: types.STORE_EXCEPTION_SEARCHED_QUERY,
    payload: { searchedTabKey, searchQuery, recordCount }
  };
}

export function resetExceptionSearchData (searchedTabKey: string, updatedSearchQuery: any): dataInterface {
  return {
    type: types.RESET_EXCEPTION_SEARCHED_DATA,
    payload: { searchedTabKey, updatedSearchQuery }
  };
}
