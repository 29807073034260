import { formatBytes } from './commonHelper';
import type {
  BlobDetailListItem,
  BlobSummaryListItem,
  // BlobMetricSummaryFilters,
  MetricType
} from '../constants/Interfaces/BlobMetricsTypes';

export const getMetricTypeCount = (data: Array<BlobSummaryListItem | BlobDetailListItem>, metricType: MetricType): number => {
  return data.filter((item: any) => item.type === metricType).length
}

export const getMaxDataSize = (dataList: BlobDetailListItem[]): string => {
  const dataWithMaxSize: BlobDetailListItem = dataList.sort((num1: BlobDetailListItem, num2: BlobDetailListItem): number => num2.data_size - num1.data_size)[0];

  return dataWithMaxSize ? (formatBytes(dataWithMaxSize.data_size) + ` (${dataWithMaxSize.application_nm})`) : '0 Bytes';
}

export const filterTableData = (dataList: Array<BlobSummaryListItem | BlobDetailListItem>, filterConfig: any): Array<BlobSummaryListItem | BlobDetailListItem> => {
  return dataList.filter((item: any) => Object.keys(filterConfig).every((key: string) => !filterConfig[key] || filterConfig[key].includes(item[key])))
};
