import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import type { FormFieldType } from '../../../../../constants/Interfaces/FormFieldTypes';
import {
  LabelValueWrapper,
  LabelWrapper
} from '../../../../../assets/styles/commonStyled';
import { getLabelValueFormatter } from '../../../../../helpers/commonHelper';
import { groupAccessFormFields } from '../../../../../constants/FormFields/AccessManagementForm/groupAccessFormFields';
import { SummaryViewRow } from '../../Admin/style';

interface ConfigSummaryProps {
  data?: Record<string, any>
}

const columnSpan = {
  xs: 24,
  md: 7,
  xl: 5
}

const GroupAccessSummary: FC<ConfigSummaryProps> = ({ data }) => {
  return (
    <>
      <SummaryViewRow
        gutter={[16, 8]}
        data-testid="groupAccessSummary"
      >
        {groupAccessFormFields.map((formField: FormFieldType) => {
          return (
            <Col key={formField.id} xs={columnSpan.xs} md={columnSpan.md} xl={columnSpan.xl}>
              <LabelWrapper>{formField.label}</LabelWrapper>
              <LabelValueWrapper>{getLabelValueFormatter(data?.[formField.name], formField?.type)}</LabelValueWrapper>
            </Col>
          );
        })}
      </SummaryViewRow>
    </>
  );
};

GroupAccessSummary.propTypes = {
  data: PropTypes.object
};

export default GroupAccessSummary;
