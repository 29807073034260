import React from 'react';
import type { ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getApplicationDetails } from '../../redux/actions/Admin/applicationConfigActions';
import LogExceptionSearch from '../../pages/LogExceptionSearch';

const LogSearchContainer = (): ReactElement => {
  const applicationList = useSelector((state: any) => state.applicationConfig.applicationConfigList);
  const userAccessInfo = useSelector((state: any) => state.userAccess.userAccessInfo);
  const timeZone = useSelector((state: any) => state.commonConfig.isCstTimeZone);
  const logTabList = useSelector((state: any) => state?.logSearchReducer?.logTabList);
  const activeTab = useSelector((state: any) => state?.logSearchReducer?.activeTab);
  const dispatch = useDispatch();

  const fetchAppplicationList = (): void => {
    dispatch(getApplicationDetails());
  };

  return (
    <LogExceptionSearch
      activeTab={activeTab}
      applicationList={applicationList}
      userAccessInfo={userAccessInfo}
      timeZone={timeZone}
      fetchAppplicationList={fetchAppplicationList}
      logExceptionType="Log"
      searchTabList={logTabList}
    />
  );
};

export default LogSearchContainer;
