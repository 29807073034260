import type {
  DeleteConfigActionType,
  GetConfigDetailsActionType,
  SetConfigDetailsActionType
} from '../../../constants/Interfaces/AdminConfigActionTypes';
import {
  DELETE_REPORT_CONFIG,
  GET_REPORT_CONFIG_LIST,
  SAVE_REPORT_CONFIG,
  SET_REPORT_CONFIG_LIST
} from '../ActionTypes/Admin/reportConfigTypes';

interface SaveConfigDataAction {
  type: string
  payload: {
    data: any[]
    callBackFunc?: any
    isAdd?: boolean
  }
}

export function getReportConfigList (): GetConfigDetailsActionType {
  return {
    type: GET_REPORT_CONFIG_LIST
  };
}

export function setReportConfigList (data: any): SetConfigDetailsActionType {
  return {
    type: SET_REPORT_CONFIG_LIST,
    data
  };
}

export function deleteReportConfig (data: any, history?: any): DeleteConfigActionType {
  return {
    type: DELETE_REPORT_CONFIG,
    payload: {
      data,
      history
    }
  };
}

export function saveReportConfig (
  data: any,
  callBackFunc?: any,
  isAdd?: boolean
): SaveConfigDataAction {
  return {
    type: SAVE_REPORT_CONFIG,
    payload: {
      data,
      callBackFunc,
      isAdd
    }
  };
}
