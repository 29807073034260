export const exceptionFields = [
  {
    label: 'Transaction ID',
    name: 'transactionID',
    id: 'sel_transactionID',
    type: 'text'
  },
  {
    label: 'Business ID',
    name: 'businessID',
    id: 'sel_businessID',
    type: 'text'
  },
  {
    label: 'Business ID2',
    name: 'businessID2',
    id: 'sel_businessID2',
    type: 'text'
  },
  {
    label: 'Category',
    name: 'exceptionCategory',
    id: 'sel_exceptionCategory',
    type: 'select'
  },
  {
    label: 'Type',
    name: 'exceptionType',
    id: 'sel_exceptionType',
    type: 'select'
  },
  {
    label: 'Severity',
    name: 'exceptionSeverity',
    id: 'sel_exceptionSeverity',
    type: 'select'
  },
  {
    label: 'Component',
    name: 'componentName',
    id: 'sel_componentName',
    type: 'text'
  },
  {
    label: 'Service',
    name: 'serviceName',
    id: 'sel_serviceName',
    type: 'text'
  },
  {
    label: 'Name',
    name: 'messageName',
    id: 'sel_messageName',
    type: 'text'
  },
  {
    label: 'Value',
    name: 'messageValue',
    id: 'sel_messageValue',
    type: 'text'
  },
  {
    label: 'HostName',
    name: 'hostname',
    id: 'sel_hostname',
    type: 'text'
  },
  {
    label: 'Application Domain',
    name: 'applicationDomain',
    id: 'sel_applicationDomain',
    type: 'text'
  },
  {
    label: 'Transaction Domain',
    name: 'transactionDomain',
    id: 'sel_transactionDomain',
    type: 'text'
  },
  {
    label: 'Transaction Type',
    name: 'transactionType',
    id: 'sel_transactionType',
    type: 'text'
  },
  {
    label: 'Transaction Data',
    name: 'transactionData',
    id: 'sel_transactionData',
    type: 'text'
  },
  {
    label: 'Dump Analysis',
    name: 'dumpAnalysis',
    id: 'sel_dumpAnalysis',
    type: 'text',
    tabIndex: '11'
  },
  {
    label: 'Code',
    name: 'errorCode',
    id: 'sel_code',
    type: 'text'
  },
  {
    label: 'Resolution Description',
    name: 'resolutionDescription',
    id: 'sel_resolutionDescription',
    type: 'text'
  },
  {
    label: 'Message',
    name: 'message',
    id: 'sel_message',
    type: 'text'
  },
  {
    label: 'Reply Destination',
    name: 'replayDestination',
    id: 'sel_replayDestination',
    type: 'text'
  }
];
