import React, { useEffect, useLayoutEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form, Radio } from 'antd';
import FloatingLabel from '../../../../common/FloatingLabel';
import Footer from '../../../../common/Footer';
import type { AdminConfigFormPropsType } from '../../../../../constants/Interfaces/AdminFormConfigTypes';
import { snowServiceNameOptions, replayModeOptions, replayTypeOptions } from '../../../../../constants/DropDownOptions/adminConfig';
import { applicationDataHelper } from '../../../../../helpers/applicationDataHelper';
import { exceptionConfigFieldLabels } from '../../../../../constants/FormFields/AdminForm/exceptionConfigFormFields';
import { RowCriteriaWithBorderWrapper, RowCriteriaWithOutBorderWrapper } from '../../../../../assets/styles/commonStyled';
import textConstants from '../../../../../constants/textConstants';
import { EXCEPTION_ID } from '../../../../../constants/KeyLabels/commonKeyConstants';

const columnSpanForBasicDetails = {
  xs: 24,
  md: 6,
  xxl: 5
}

const columnSpanForOtherDetails = {
  xs: 24,
  md: 6,
  xxl: 5
}

const ExceptionConfigForm: FC<AdminConfigFormPropsType> = ({ data, isDrawerView, isEditable, onSave, onCancel }) => {
  const [form] = Form.useForm();
  const applicationNames = applicationDataHelper.getApplicationNames([]);
  const snowNotificationChangedValue = Form.useWatch('enableSnowNotification', form);
  const mailNotificationChangedValue = Form.useWatch('enableMailNotification', form);

  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const [typeOptions, setTypeOptions] = useState<any[]>([]);
  const [severityOptions, setSeverityOptions] = useState<any[]>([]);

  const onApplicationChangeHandler = (value: any): void => {
    setCategoryOptions(applicationDataHelper.getDataFromApplicationList(value, 'exceptionCategoryName'));
    setTypeOptions(applicationDataHelper.getDataFromApplicationList(value, 'exceptionTypeName'));
    setSeverityOptions(applicationDataHelper.getDataFromApplicationList(value, 'exceptionSeverityName'));

    form.setFieldsValue({
      ...form.getFieldsValue(),
      exceptionCategoryName: undefined,
      exceptionTypeName: undefined,
      exceptionSeverityName: undefined
    });
  };

  const onSaveHandler = (isClosable: boolean): void => {
    form.validateFields()
      .then(async values => {
        onSave(values, isClosable);
      }).catch(error => console.log(error));
  }

  useEffect(() => {
    if (!isEditable && data?.applicationName) {
      onApplicationChangeHandler(data?.applicationName);
    }
  }, [data?.applicationName])

  useLayoutEffect(() => {
    form.setFieldsValue(data);
  }, [data[EXCEPTION_ID]]);

  return (
    <>
      <Form
        form={form}
        initialValues={{
          enableMailNotification: 0,
          enableGroupNotification: 0,
          enableSnowNotification: 0,
          autoReplayInterval: 0,
          ...data
        }}
        data-testid="exceptionConfigForm"
        layout='vertical'
      >
        <Row
          gutter={{ lg: 16, xl: 16, xxl: 28 }}
          className='mb-10'
        >
          {isEditable && (
            <Col xs={columnSpanForBasicDetails.xs} md={columnSpanForBasicDetails.md} xxl={columnSpanForBasicDetails.xxl}>
              <Form.Item name='exceptionConfigId'>
                <FloatingLabel
                  label={exceptionConfigFieldLabels.exceptionConfigId}
                  type='text'
                  id='exceptionConfigId'
                  disabled
                />
              </Form.Item>
            </Col>
          )}
          <Col xs={columnSpanForBasicDetails.xs} md={columnSpanForBasicDetails.md} xxl={columnSpanForBasicDetails.xxl}>
            <Form.Item
              name='applicationName'
              rules={[{ required: true, message: 'Application is mandatory!' }]}
            >
              <FloatingLabel
                label={exceptionConfigFieldLabels.applicationName}
                type='select'
                id='applicationName'
                options={applicationNames}
                onChange={onApplicationChangeHandler}
                {...((data?.applicationName ?? isEditable) && { disabled: true })}
                required
                showSearch
              />
            </Form.Item>
          </Col>
          <Col xs={columnSpanForBasicDetails.xs} md={columnSpanForBasicDetails.md} xxl={columnSpanForBasicDetails.xxl}>
            <Form.Item name='applicationDomain' rules={[{ required: true, message: 'Application Domain is mandatory!' }]}>
              <FloatingLabel
                label={exceptionConfigFieldLabels.applicationDomain}
                type='text'
                id='applicationDomain'
                required
              />
            </Form.Item>
          </Col>
          <Col xs={columnSpanForBasicDetails.xs} md={columnSpanForBasicDetails.md} xxl={columnSpanForBasicDetails.xxl}>
            <Form.Item name='description'>
              <FloatingLabel
                label={exceptionConfigFieldLabels.description}
                type='text'
                id='description'
              />
            </Form.Item>
          </Col>
        </Row>
        <RowCriteriaWithBorderWrapper
          gutter={{ lg: 16, xl: 16, xxl: 28 }}
          data-title={textConstants.EXCEPTION_CONFIG_DETAIL_IF_CRITERIA_LABEL}
        >
          <Col xs={columnSpanForOtherDetails.xs} md={columnSpanForOtherDetails.md} xxl={columnSpanForOtherDetails.xxl}>
            <Form.Item name='exceptionCategoryName' rules={[{ required: true, message: 'Category is mandatory!' }]}>
              <FloatingLabel
                label={exceptionConfigFieldLabels.exceptionCategoryName}
                type='select'
                id='exceptionCategoryName'
                options={categoryOptions}
                {...(isEditable && { disabled: true })}
                required
              />
            </Form.Item>
          </Col>
          <Col xs={columnSpanForOtherDetails.xs} md={columnSpanForOtherDetails.md} xxl={columnSpanForOtherDetails.xxl}>
            <Form.Item name='exceptionTypeName' rules={[{ required: true, message: 'Type is mandatory!' }]}>
              <FloatingLabel
                label={exceptionConfigFieldLabels.exceptionTypeName}
                type='select'
                id='exceptionTypeName'
                options={typeOptions}
                {...(isEditable && { disabled: true })}
                required
              />
            </Form.Item>
          </Col>
          <Col xs={columnSpanForOtherDetails.xs} md={columnSpanForOtherDetails.md} xxl={columnSpanForOtherDetails.xxl}>
            <Form.Item name='exceptionSeverityName' rules={[{ required: true, message: 'Severity is mandatory!' }]}>
              <FloatingLabel
                label={exceptionConfigFieldLabels.exceptionSeverityName}
                type='select'
                id='exceptionSeverityName'
                options={severityOptions}
                {...(isEditable && { disabled: true })}
                required
              />
            </Form.Item>
          </Col>
        </RowCriteriaWithBorderWrapper>
        <RowCriteriaWithOutBorderWrapper
          gutter={{ lg: 16, xl: 16, xxl: 28 }}
          data-title={textConstants.EXCEPTION_CONFIG_DETAIL_THEN_CRITERIA_LABEL}
        >
          <Col xs={columnSpanForOtherDetails.xs} md={columnSpanForOtherDetails.md} xxl={columnSpanForOtherDetails.xxl}>
            <Form.Item name='replayType' rules={[{ required: true, message: 'Replay Type is mandatory!' }]}>
              <FloatingLabel
                label={exceptionConfigFieldLabels.replayType}
                type='select'
                id='replayType'
                options={replayTypeOptions}
                required
              />
            </Form.Item>
          </Col>
          <Col xs={columnSpanForOtherDetails.xs} md={columnSpanForOtherDetails.md} xxl={columnSpanForOtherDetails.xxl}>
            <Form.Item name='replyMode' rules={[{ required: true, message: 'Replay Mode is mandatory!' }]}>
              <FloatingLabel
                label={exceptionConfigFieldLabels.replyMode}
                type='select'
                id='replyMode'
                options={replayModeOptions}
                required
              />
            </Form.Item>
          </Col>
          <Col xs={columnSpanForOtherDetails.xs} md={columnSpanForOtherDetails.md} xxl={columnSpanForOtherDetails.xxl}>
            <Form.Item name='maxAutoReplay'>
              <FloatingLabel
                label={exceptionConfigFieldLabels.maxAutoReplay}
                type='text'
                id='maxAutoReplay'
              />
            </Form.Item>
          </Col>
          <Col xs={columnSpanForOtherDetails.xs} md={columnSpanForOtherDetails.md} xxl={columnSpanForOtherDetails.xxl}>
            <Form.Item
              name='autoReplayInterval'
              rules={[
                () => ({
                  async validator (_, value) {
                    if (value > 10080) {
                      return await Promise.reject(new Error('Replay Interval cannot exceeds more than 7 days (10080 minutes)'));
                    }
                    return await Promise.resolve();
                  }
                })
              ]}
            >
              <FloatingLabel
                label={exceptionConfigFieldLabels.autoReplayInterval}
                type='number'
                id='autoReplayInterval'
                min={-1}
              />
            </Form.Item>
          </Col>
          <Col xs={columnSpanForOtherDetails.xs} md={columnSpanForOtherDetails.md} xxl={columnSpanForOtherDetails.xxl}>
            <Form.Item name='replyDestination'>
              <FloatingLabel
                label={exceptionConfigFieldLabels.replyDestination}
                type='text'
                id='replyDestination'
              />
            </Form.Item>
          </Col>
        </RowCriteriaWithOutBorderWrapper>
        <RowCriteriaWithOutBorderWrapper
          gutter={{ lg: 16, xl: 16, xxl: 28 }}
          className='mt-5'
          data-title={textConstants.EXCEPTION_CONFIG_DETAIL_NOTIFICATION_CRITERIA_LABEL}
        >
          <Col xs={24} md={6} xl={5}>
            <Form.Item label={exceptionConfigFieldLabels.enableMailNotification} name="enableMailNotification">
              <Radio.Group >
                <Radio value={1}>Yes</Radio>
                <Radio value={0}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} md={6} xl={5}>
            <Form.Item label={exceptionConfigFieldLabels.enableGroupNotification} name="enableGroupNotification">
              <Radio.Group >
                <Radio value={1}>Yes</Radio>
                <Radio value={0}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} md={8} xl={14}>
            <Form.Item label={exceptionConfigFieldLabels.enableSnowNotification} name="enableSnowNotification">
              <Radio.Group >
                <Radio value={1}>Yes</Radio>
                <Radio value={0}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} md={6} xl={5}>
            <Form.Item
              name='emailAddress'
              {...(mailNotificationChangedValue === 1 && {
                rules: [
                  { required: true, message: 'Notification Email ID is mandatory!' }
                  // { pattern: /^([a-z][a-z0-9_.]+@([a-z0-9-]+\.)+[a-z]{2,6}(, |,)*[^,|, ])+$/, message: 'Email is not valid!' }
                ]
              })}
            >
              <FloatingLabel
                label={exceptionConfigFieldLabels.emailAddress}
                type='text'
                id='emailAddress'
                required={mailNotificationChangedValue === 1}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6} xl={5}>
            <Form.Item name='snowAssignmentGroup'>
              <FloatingLabel
                label={exceptionConfigFieldLabels.snowAssignmentGroup}
                type='text'
                id='snowAssignmentGroup'
              />
            </Form.Item>
          </Col>
          {snowNotificationChangedValue === 1 && (
            <>
              <Col xs={24} md={6} xl={5}>
                <Form.Item name='sysClassName' rules={[{ required: true, message: `${exceptionConfigFieldLabels.sysClassName} is mandatory!` }]}>
                  <FloatingLabel
                    label={exceptionConfigFieldLabels.sysClassName}
                    type='select'
                    options={snowServiceNameOptions}
                    id='sysClassName'
                    required
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={6} xl={5}>
                <Form.Item
                  name='snowParam'
                  {...(snowNotificationChangedValue === 1 && {
                    rules: [{ required: true, message: `${exceptionConfigFieldLabels.snowParam} is mandatory!` }]
                  })}
                >
                  <FloatingLabel
                    label={exceptionConfigFieldLabels.snowParam}
                    type='text'
                    id='snowParam'
                    required
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </RowCriteriaWithOutBorderWrapper>
      </Form>
      <Footer
        className={`grey-theme ${isDrawerView ? 'add-form-footer' : ''}`}
        onSave={() => onSaveHandler(false)}
        onSaveClose={() => onSaveHandler(true)}
        onCancel={onCancel}
        saveWithClose={!isEditable}
        save
        cancel
      />
    </>
  );
};

ExceptionConfigForm.defaultProps = {
  isEditable: true
};

ExceptionConfigForm.propTypes = {
  data: PropTypes.object,
  isDrawerView: PropTypes.bool,
  isEditable: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ExceptionConfigForm;
