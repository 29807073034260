import { APPLICATION_NAME_KEY, BUSINESS_ID_KEY, BUSINESS_ID_TWO_KEY, COMPONENT_NAME_KEY, DATA_ENCODING_KEY, HOST_NAME_KEY, JMS_HEADER_KEY, LOG_MSG_KEY, SERVICE_NAME_KEY, STATUS_KEY, TIMEMARK_KEY, TIME_DURATION_KEY, TRANSACTION_AFTER_KEY, TRANSACTION_BEFORE_KEY, TRANSACTION_DOMAIN_KEY, TRANSACTION_ID_KEY, TRANSACTION_TYPE_KEY } from '../KeyLabels/commonKeyConstants';
import { APPLICATION_NAME_LABEL, BUSINESS_ID_LABEL, BUSINESS_ID_TWO_LABEL, COMPONENT_NAME_LABEL, DATA_ENCODING_LABEL, HOST_NAME_LABEL, JSM_HEADER_LABEL, MESSAGE_LABEL, SERVICE_NAME_LABEL, STATUS_LABEL, TIME_DURATION_LABEL, TIME_OCCURED_LABEL, TRANSACTION_AFTER_LABEL, TRANSACTION_BEFORE_LABEL, TRANSACTION_DOMAIN_LABEL, TRANSACTION_ID_LABEL, TRANSACTION_TYPE_LABEL } from '../KeyLabels/labelConstants';

const logFields = [
  {
    field: APPLICATION_NAME_KEY,
    label: APPLICATION_NAME_LABEL
  },
  {
    field: TIMEMARK_KEY,
    label: TIME_OCCURED_LABEL
  },
  {
    field: COMPONENT_NAME_KEY,
    label: COMPONENT_NAME_LABEL
  },
  {
    field: DATA_ENCODING_KEY,
    label: DATA_ENCODING_LABEL
  },
  {
    field: SERVICE_NAME_KEY,
    label: SERVICE_NAME_LABEL
  },
  {
    field: STATUS_KEY,
    label: STATUS_LABEL
  },
  {
    field: TRANSACTION_DOMAIN_KEY,
    label: TRANSACTION_DOMAIN_LABEL
  },
  {
    field: HOST_NAME_KEY,
    label: HOST_NAME_LABEL
  },
  {
    field: BUSINESS_ID_KEY,
    label: BUSINESS_ID_LABEL
  },
  {
    field: BUSINESS_ID_TWO_KEY,
    label: BUSINESS_ID_TWO_LABEL
  },
  {
    field: TRANSACTION_ID_KEY,
    label: TRANSACTION_ID_LABEL
  },
  {
    field: TIME_DURATION_KEY,
    label: TIME_DURATION_LABEL
  },

  {
    field: TRANSACTION_BEFORE_KEY,
    label: TRANSACTION_BEFORE_LABEL
  },
  {
    field: JMS_HEADER_KEY,
    label: JSM_HEADER_LABEL
  },
  {
    field: TRANSACTION_AFTER_KEY,
    label: TRANSACTION_AFTER_LABEL
  },
  {
    field: LOG_MSG_KEY,
    label: MESSAGE_LABEL
  },
  {
    field: TRANSACTION_TYPE_KEY,
    label: TRANSACTION_TYPE_LABEL
  }
];

export default logFields;
