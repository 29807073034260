import styled from 'styled-components';

export const ReplayFileStatusCountBarWrapper = styled.div`
  background: var(--white);
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  margin: 5px 0px;
  border-radius: 4px;
  > div {
    flex-basis: 25%;
    margin: 5px;
    padding: 10px;
    border-right: 1.5px solid var(--gray-275);
    margin-right: 4vw;

    &:nth-child(2) {
      flex-basis: 13%;
    }
  }
  
  > div:last-child {
    border: 0px;
  }
`;
export const ReplayFileCountItemWrapper = styled.div``;

export const ReplayFileCountLabelWrapper = styled.div`
  color: var(--gray-color);
  font-size: var(--font-size-md);
  margin-bottom: 10px;
`;

export const ReplayFileCountValueWrapper = styled.div`
  color: var(--blue-color-800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  
  &.status-btn {
    border-radius: 4px;
    padding: 5px 6px;
  }
`;
