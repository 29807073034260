import { produce } from 'immer';
import { SET_FILE_REPLAY_DASHBOARD_DATA, SET_REPLAY_FILE_DETAILS } from '../../actions/ActionTypes/FileReplay/fileReplayTypes';

export interface FileReplayDashboardState {
  dashboard: any
  fileReplayDetails: any
}

const initialState = {
  dashboard: {},
  fileReplayDetails: {}
};

const fileReplayConfigReducer = (state = initialState, action: any): FileReplayDashboardState =>
  produce(state, (draft: FileReplayDashboardState) => {
    switch (action.type) {
      case SET_FILE_REPLAY_DASHBOARD_DATA:
        draft.dashboard = action.data;
        break;
      case SET_REPLAY_FILE_DETAILS:
        draft.fileReplayDetails = action.data;
        break;
    }
  });

export default fileReplayConfigReducer;
