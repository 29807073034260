import styled from 'styled-components';

export const MainLayoutWrapper = styled.div`
    min-height: 100%;
`
export const MainContentWrapper = styled.div`
    overflow: auto;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
`;
