import styled from 'styled-components';

export const UserListContentWrapper = styled.div`
    overflow: hidden;

    > div {
        margin: 2px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item {
        line-height: 32px;
    }

    .ant-layout {
        border-radius: 0px;
        border: 0px;
        box-shadow: none;
        height: calc(100vh - 220px);

        main {
            box-shadow: 0px 2px 2px var(--black-400);
            border: 1px solid var(--gray-225);
            border-top: none;
        }
    }
`;
export const UserListWithGroupWrapper = styled.div`
    position: relative;
`;
export const GroupSelectorWrapper = styled.div`
    position: absolute;
    right: 10px;
    top: 3px;
    min-width: 250px;
    max-width: 250px;
`;
