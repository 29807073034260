import {
  GET_EXCEPTION_REPORT_CONFIG_LIST,
  SET_EXCEPTION_REPORT_CONFIG_LIST,
  DELETE_EXCEPTION_REPORT_CONFIG,
  SAVE_EXCEPTION_REPORT_CONFIG
} from '../ActionTypes/Admin/exceptionReportConfigTypes';
import type {
  DeleteConfigActionType,
  GetConfigDetailsActionType,
  SetConfigDetailsActionType
} from '../../../constants/Interfaces/AdminConfigActionTypes';

interface SaveConfigDetailAction {
  type: string
  payload: {
    data: any[]
    callBackFunc?: any
    isAdd?: boolean
  }
}

export function getExceptionReportConfigList (applicationName: string): GetConfigDetailsActionType {
  return {
    type: GET_EXCEPTION_REPORT_CONFIG_LIST,
    applicationName
  };
}

export function setExceptionReportConfigList (data: any): SetConfigDetailsActionType {
  return {
    type: SET_EXCEPTION_REPORT_CONFIG_LIST,
    data
  };
}

export function deleteExceptionReportConfig (data: any, history?: any): DeleteConfigActionType {
  return {
    type: DELETE_EXCEPTION_REPORT_CONFIG,
    payload: {
      data,
      history
    }
  };
}

export function saveExceptionReportConfig (data: any, callBackFunc?: any, isAdd?: boolean): SaveConfigDetailAction {
  return {
    type: SAVE_EXCEPTION_REPORT_CONFIG,
    payload: {
      data,
      callBackFunc,
      isAdd
    }
  };
}
