import _, { uniqBy } from 'lodash';
import apiEndpointConstants from '../../constants/apiEndpointConstants';
import { apiRequestHandler } from '../../services/CommonServiceHandler';
import {
  LOG_TABLE_KEY,
  EXCEPTION_TABLE_KEY,
  LOG_RECENT_SEARCH_KEY,
  EXCEPTION_RECENT_SEARCH_KEY
} from '../../constants/KeyLabels/commonKeyConstants';
import { convertTimeToTimeZone } from '../commonHelper';

/**
 * Function to Search Log or Exception Data
 * @param {string} logOrExceptionType whether it is log or exception search type
 * @param {object | any} searchQuery inputs from user to search
 * @param {any} cancelToken to cancel/abort the api call
 * @return {array} generated payload;
 *
 * */
export const getRecordsCountByType = async (
  logOrExceptionType: string,
  searchQuery: any
): Promise<any> => {
  const apiCallUrl =
    logOrExceptionType?.toLowerCase() === 'log'
      ? apiEndpointConstants.GET_LOG_COUNT
      : apiEndpointConstants.GET_EXCEPTION_COUNT;

  const generatedPayload = generatePayloadForLogExceptionSearch(searchQuery, logOrExceptionType);
  try {
    const generatedApiCalls = generatedPayload.map(
      async (payloadObject) =>
        await apiRequestHandler({
          method: 'POST',
          data: payloadObject,
          url: apiCallUrl
        })
    );

    const countResponseList = await Promise.all(generatedApiCalls);
    let totalRecords = 0;
    const generatedPayloadWithRecordCount = generatedPayload.map((payloadObj, payloadObjIter) => {
      totalRecords = totalRecords + countResponseList[payloadObjIter]
      return { ...payloadObj, recordCount: countResponseList[payloadObjIter] }
    })

    return {
      totalCount: totalRecords,
      generatedPayloadWithRecordCount
    }
    // return countResponseList.reduce(
    //   (prevValue: number, curValue: number) => prevValue + curValue,
    //   0
    // );
  } catch (e) {
    console.log(e);
    return 0;
  }
};

/**
 * Common Function between count and search api call to generate payload
 * @param {object} searchQuery inputs from user to search
 * @param {string} logOrExceptionType whether it is log or exception search type
 * @return {array} generated payload;
 *
 * */
export const generatePayloadForLogExceptionSearch = (
  searchQuery: any,
  logOrExceptionType: string
): any[] => {
  const table = logOrExceptionType.toLowerCase() === 'log' ? LOG_TABLE_KEY : EXCEPTION_TABLE_KEY;
  const updatedSearchQuery = {
    ...searchQuery,
    startTime: convertTimeToTimeZone(searchQuery?.dateTime?.[0]),
    endTime: convertTimeToTimeZone(searchQuery?.dateTime?.[1])
  };
  const apiRequest: any = {
    sortColumns: 'timestamp',
    sortOrder: 'desc',
    ..._.pickBy(updatedSearchQuery, _.identity)
  };
  const application = apiRequest.appName;

  const appGroup = application.reduce((r: any, a: any) => {
    r[a[table]] = [...(r[a[table]] || []), a];
    return r;
  }, {});
  const payloadTemp: any[] = [];
  Object.keys(appGroup).forEach((val) => {
    const applicationName = appGroup[val].map((app: { value: any }) => app.value).join(' ');
    const newQuery = { ...apiRequest, table: val, applicationName };
    payloadTemp.push(newQuery);
  });

  return payloadTemp;
};

export const generateCustomInputSearchStringFromFormObject = (
  formObj: any,
  fieldColumnArray: any[],
  ignoreFieldKeys: any[]
): string => {
  const customString =
    Object.keys(formObj).reduce((obj: string, key, counter) => {
      const commaVal = counter === Object.keys(formObj).length - 1 ? '' : '; ';
      if (key && key !== 'searchFieldValue' && formObj[key] && !ignoreFieldKeys.includes(key)) {
        const fieldName: any = fieldColumnArray.find((item: any) => item.name === key);
        obj += `${String(fieldName?.label)}= ${String(formObj[key])}${commaVal}`;
      }
      return obj;
    }, '') ?? '';

  return customString;
};

export const getSearchQuerySuggestionList = (
  selectedApplication: any,
  ignoreKeyList: any,
  searchType: string
): any => {
  const suggestionList: any = {};
  const searchTypeKey =
    searchType?.toString().toLowerCase() === 'log'
      ? LOG_RECENT_SEARCH_KEY
      : EXCEPTION_RECENT_SEARCH_KEY;

  const searchQueryList = JSON.parse(localStorage.getItem(searchTypeKey) ?? '[]');

  searchQueryList.forEach((item: any) => {
    const queryItem = JSON.parse(item.searchQuery);

    if (selectedApplication?.length > 0) {
      const appNameList = queryItem.applicationName.split(',');

      const isAppAvailable = selectedApplication.find((item: any) =>
        appNameList.includes(item.label)
      );

      if (!isAppAvailable) {
        return;
      }
    }

    for (const [key, value] of Object.entries(queryItem)) {
      if (!ignoreKeyList.includes(key)) {
        const itemObj = {
          label: value,
          value
        };

        if (suggestionList[key]) {
          suggestionList[key] = uniqBy([...suggestionList[key], itemObj], 'value');
        } else {
          suggestionList[key] = [itemObj];
        }
      }
    }
  });

  return suggestionList;
};
