import styled, { css } from 'styled-components';
import { Popover, Drawer, Row, Button, Input } from 'antd';
import ReadMore from '../../components/common/ReadMore';
import type { MetricType } from '../../constants/Interfaces/BlobMetricsTypes';

interface AdminLayoutProps {
  isTabContentList?: boolean
}

export const CardStyle = css`
    box-shadow: 1px 2px 2px 2px var(--default-border-color);
    border-radius: 3px;
    margin: 10px;
    background: var(--white);
`;

export const HeaderTitle = styled.span`
    font-size: var(--font-size-lg); //rem
    font-weight: var(--font-weight-medium);
    display: inline-block;
    margin-top: 5px;
`;

export const SubHeaderTitle = styled.span`
    color: var(--blue-color-700);
    font-size: 13px; //rem
    font-weight: 600;
`;
export const IconWrapper = styled.span`
    color: var(--blue-color-800);
    cursor: pointer;
`;

export const PopOverWrapper = styled(Popover)``;

export const AdditionalInfoText = styled.div<{ textStyle?: string }>`
    color: var(--gray-color);
    padding: 2px;
    margin-bottom: 2px;
    font-size: ${(props) =>
        props.textStyle === 'medium' ? 'var(--font-size-md)' : 'var(--font-size-sm)'};
`;

export const ErrorMsgText = styled.div<{ textStyle?: string }>`
    color: var(--error-color);
    font-weight: 600;
    font-size: ${(props) =>
        props.textStyle === 'medium' ? 'var(--font-size-md)' : 'var(--font-size-sm)'};
`;

export const AntdDrawer = styled(Drawer)`
    .ant-drawer {
        border: 0px;
    }
    .ant-drawer-header {
        padding: 10px 15px;
    }
    .ant-drawer-body {
        padding: 0px;
    }
    .ant-drawer-close {
        position: absolute;
        right: 5px;
        background: var(--gray-250);
        color: var(--gray-600);
        border-radius: 50%;
        padding: 4px;
        font-size: 13px;
    }
    .ant-drawer-title {
        color: var(--blue-color-700);
    }
    &.file-replay-drawer {
        .ant-drawer-body {
            padding: 10px;
            background: var(--white-300);
        }
    }
`;

export const FileReplayStatusTag = styled.div`
    border-radius: 4px;
    padding: 6px;
    line-height: 1;
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-sm);
    text-transform: capitalize;
    letter-spacing: 0.034rem;
    display: inline;
    &[class*='resolve'],
    &[class*='completed'] {
        background: var(--replay-resolved-color);
        color: var(--white);
    }
    &[class*='progress'],
    &[class*='active'] {
        background: var(--replay-progress-bg-color);
        color: var(--white);
    }
    &[class*='pending'] {
        background: var(--replay-pending-color);
        color: var(--yellow-100);
    }
    &[class*='schedule'],
    &[class*='scheduled'] {
        background: var(--replay-schedule-bg-color);
        color: var(--white);
    }
    &[class*='error'] {
        background: var(--replay-error-color);
        color: var(--white);
    }
`;

export const BlobMetricTypeTag = styled.span<{ type: MetricType }>`
    display: inline-block;
    padding: 0 5px;
    border-radius: 4px;
    color: var(--white);
    height: 22px;
    line-height: 22px;
    font-size: var(--font-size-sm);

    ${({ type }) =>
        type &&
        css`
            background: ${type === 'Log' ? 'var(--blue-color)' : 'var(--blue-color-725)'};
        `}
`;

export const CardContainerWrapper = styled.div`
    ${CardStyle}
`;

export const AdminMainLayoutWrapper = styled.div<AdminLayoutProps>`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    ${({ isTabContentList }) =>
        isTabContentList &&
        css`
            & > div {
                margin-top: 0;
            }
        `}

    .fixed-progress-bar {
        top: 94px !important;

        @media screen and (min-width: 1600px) {
            top: 97px !important;
        }

        > div > div {
            visibility: hidden;
        }
    }

    &.group-access-wrapper {
        .right-side-actions-small .ag-pinned-right-cols-container .ag-row .ag-cell {
            padding-left: 27px;
        }
    }

    .add-edit-config-drawer {
        .ant-drawer-title {
            display: flex;
            align-items:center;
        }
        
        .react-dropdown-select {
            min-height: 40px;
            border-color: var(--form-field-border-color);

            &[disabled] {
                opacity: 1;
                background-color: var(--gray-100);
            }

            &-content {
                padding-top: 5px;

                span > span {
                    max-width: 73px;
                }
            }

            input {
                height: 100%;
            }

            .react-dropdown-select-dropdown-handle svg {
                width: 15px;
                height: 15px;
                color: var(--blue-color);
            }
        }

        .ant-form-item-has-error {
            .react-dropdown-select {
                border-color: var(--red-50);
            }
        }

        .ant-form-item {
            margin-bottom: 15px;

            label {
                font-size: var(--font-size-sm);
            }

            &-label {
                padding-bottom: 0;
            }
        }
    }

    .criteria-separator-wrapper,
    .criteria-separator-wrapper-without-border {
        position: relative;
        border: 1px solid var(--gray-275);
        padding: 25px 0 6px;

        &:before {
            content: attr(data-title);
            font-size: var(--font-size-md);
            font-weight: var(--font-weight-medium);
            color: var(--blue-color-700);
            position: absolute;
            top: -10px;
            background: var(--white);
            left: 10px;
            padding: 0 10px;
        }
    }

    .criteria-separator-wrapper-without-border {
        margin-top: 20px;
        border: none;

        @media screen and (min-width: 1600px) {
            margin-top: 25px;
        }

        &:before {
            left: 0;
        }
    }

    .collapsible-form .application-access .ant-form-item {
        margin: 10px 0px 5px;
    }
`;

export const AdminPageMainLayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const AdminDetailContentWrapper = styled.div`
    background: var(--white);
    margin: 10px;
    padding: 10px;
    height: 100%;
`;

export const AdminMainBreadCrumbWrapper = styled.div`
    padding: 10px 20px;
    background: var(--blue-color-600);
    border: 1px solid var(--blue-color-275);
    color: var(--blue-color-700);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-semibold);
    cursor: default;

    a:first-child {
        margin-right: 5px;
    }
`;

export const CurrentPageBreadCrumbLabel = styled.span`
    color: var(--black-100);
    max-width: 290px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
`;

export const ConfigurationListTableWrapper = styled.div`
    margin: 10px 7px 5px;
    flex: 1;

    & > .ant-layout {
        box-shadow: 0px 2px 2px var(--black-400);
        border: 1px solid var(--gray-225);
        border-radius: 5px;
        overflow: hidden;
    }

    .right-side-actions-small .ag-pinned-right-cols-container .ag-row .ag-cell {
        padding: 0 10px;
    }
`;

export const AdminBasicTabWrapper = styled.div<{ isSecondary?: boolean }>`
    ${CardStyle}
    .ant-tabs {
        height: 100%;
        .ant-tabs-content {
            padding: 0px;
            height: 100%;
            .ant-tabs-tabpane {
                height: 100%;
            }
        }
    }
    .ant-tabs .ant-tabs-tab {
        margin: 0 10px 0 10px;
    }
    .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
        color: var(--black-100);
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-medium);
    }
    .ant-tabs-nav {
        margin: 5px;
    }
    margin: 10px 5px 5px;
    border-radius: 0px;
    overflow: auto;
    box-shadow: 0px 2px 2px var(--black-400);
    flex: 1;

    ${({ isSecondary }) =>
    isSecondary &&
        css`
            background: transparent;
            margin: 10px 10px 5px;

            .ant-tabs {
                .ant-tabs-nav {
                    background: var(--white);
                    margin: 0 0 5px;
                }
                .ant-tabs-content .ant-tabs-tabpane:not(.ant-tabs-tabpane-hidden) {
                    display: flex;
                    flex-direction: column;

                    > div:last-child {
                        margin: 2px 0 0;
                    }
                }
            }
        `}

    > div {
        height: 100%;
    }

    &.user-management-tab {
        padding: 0 15px;
        height: 100%;
        margin: 0;

        .add-form-footer {
            position: fixed;
            left: 5px;
            right: 5px;
            bottom: 5px;
            width: calc(100% - 30px);
        }

        .application-access {
            padding-bottom: 50px;
            margin: -10px 0 0;
            border-top: none;
        }
    }

    &.group-access-tab {
        border-top: 1px solid var(--gray-275);
        box-shadow: none;
        padding-bottom: 5px;
        margin: 10px 0 0;
        overflow: initial;

        .ant-tabs {
            .ant-tabs-nav {
                margin: 3px 5px 5px;
            }

            .ant-tabs-content .ant-tabs-tabpane {
                padding: 0;
                position: relative;

                > div {
                    height: 100%;
                    margin: 5px 0 0;
                }

                .application-access {
                    border-top: 0;
                    margin: -5px 0 0;
                    padding: 0;
                }
            }
        }
    }
`;

export const NoDataAvailableWrapper = styled.label`
    font-size: var(--font-size-md);
    color: var(--black);
`;

export const SlideDownWrapper = styled.section`
    animation: slideDown .5s;
    display: flex;
    height: 100%;

    > div {
        margin: 5px 0 0;
    }
`;

export const LabelWrapper = styled.label`
    font-size: var(--font-size-sm);
    color: var(--gray-850);
    cursor: default;
    display: inline-block;
`;

export const LabelValueWrapper = styled(ReadMore)<{ fullWidth?: boolean }>`
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-regular);
    word-break: break-all;
    cursor: default;
    margin-top: 2px;
    max-width:  ${(props) => props.fullWidth ? '100%' : '90%'};
    color: var(--black);

    &.blue-theme {
        color: var(--blue-color-700);
    }
`;

export const ReadMoreButton = styled(Button)`
  &.ant-btn {
    padding: 0;
    margin-left: 7px;
    font-size: var(--font-size-md);

    &-link:not(:disabled):not(.ant-btn-disabled):hover {
        color: var(--btn-link-text-color);
    }
  }
`

export const RowCriteriaWithBorderWrapper = styled(Row)`
    position: relative;
    border: 1px solid var(--gray-275);
    padding: 20px 0 10px;
    margin: 10px;
    &:before {
        content: attr(data-title);
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-medium);
        color: var(--blue-color-700);
        position: absolute;
        top: -10px;
        background: var(--white);
        left: 10px;
        padding: 0 10px;
    }

    @media screen and (min-width: 1600px) {
        padding: 30px 0px 10px;
    }
`;

export const RowCriteriaWithOutBorderWrapper = styled(Row)`
    border: none;
    position: relative;
    padding: 20px 0px 0px;
    margin-top: 20px;
    &.notification-main-row-wrapper {
        padding: 10px;
        .ant-row {
            margin-bottom: 15px;
            padding: 0px;
        }
    }

    &:before {
        content: attr(data-title);
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-medium);
        color: var(--blue-color-700);
        position: absolute;
        top: -10px;
        background: var(--white);
        left: 0;
        padding: 0 10px;
    }
    &.notification-main-row-wrapper {
        &:before {
            margin: -8px;
        }
    }
    @media screen and (min-width: 1600px) {
        padding: 30px 0px 10px;
    }
`;

export const ApplicationTabContentMainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    > div {
        margin: 0px;
    }
`;
export const NoDataTitleWrapper = styled.div`
    color: var(--gray-575);
    text-align: center;
    font-size: var(--font-size-sm);
`;

export const SearchQueryApplicationWrapper = styled.div`
    &.application-wrap {
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
        max-height: 50px;
    }
    max-width: 40vw;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const SearchInput = styled(Input)`
    .ant-input {
        height: inherit;
    }

    &.ant-input-affix-wrapper {
        margin: 3px 0;
        border-radius: 3px;
        padding: 6px 10px;
        font-size: var(--font-size-sm);
        min-width: 220px;

        @media screen and (min-width: 1600px) {
            padding: 9px 10px;
            margin: 4px 0;
        }
    }
`;

export const AddEditConfigDrawerHeaderActionWrapper = styled.div`
    margin-left: 20px;
`
