import styled from 'styled-components';

export const TabWrapper = styled.div`
  //Cards Style
  .basic-card-theme .ant-tabs-tab {
    .ant-tabs-tab-btn {
      color: var(--black-100);
      font-size: var(--font-size-sm);
    }
    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: var(--black-100);
      font-size: var(--font-size-md);
    }
  }

  .basic-card-theme {
    .ant-tabs-nav-add {
      color: var(--gray-color);
      border: none;
      // background: var(--white);
      // border: 1px solid #f0f0f0;
      // border-bottom-color: #ffffff;
      // padding: 0px;
      // min-width: 30px;
    }
    &.disable-new-tab-add-btn-class .ant-tabs-nav-add {
      background: var(--gray-225);
      color: var(--gray-550);
      cursor: not-allowed;
      &:hover,
      &:focus,
      &:active {
        color: var(--gray-550);
      }
    }
  }
  .tab-wrapper-custom-position.ant-tabs {
    // position: relative;
    // top: -35px;

    & > .ant-tabs-nav > .ant-tabs-nav-wrap {
      padding-left: 150px;
    }

    & > .ant-tabs-nav > .ant-tabs-nav-wrap .ant-tabs-nav-list {
      max-width: 80vw;
    }

    & > .ant-tabs-nav .ant-tabs-tab {
      background: var(--white-300);
      padding: 5px 10px;
    }

    & > .ant-tabs-nav .ant-tabs-tab-active {
      background: var(--white);
    }

    .ant-tabs-tab .anticon {
      margin-right: 2px;
      margin-left: 5px;
      color: var(--gray-color);
      font-size: var(--font-size-sm);
    }
    .ant-tabs-tab-remove {
      margin: 0px;
      padding: 0px;
      padding-left: 3px;
      color: var(--gray-color);
      font-size: var(--font-size-sm);
    }
    .ant-tabs-tab-remove .anticon-close {
      margin-left: 2px;
    }
  }

  //basic theme
  .basic-theme.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--blue-color-700);
  }
  .basic-theme .ant-tabs-tab-btn {
    color: var(--gray-color);
  }
  .basic-theme .ant-tabs .ant-tabs-ink-bar {
    color: var(--blue-color);
  }

  //Blue theme (detail view)
  .blue-theme {
    .ant-tabs-nav {
      margin: 0px;
      .ant-tabs-nav-wrap {
        background: var(--gray-150);
      }
    }
    &.ant-tabs {
      .ant-tabs-nav-wrap {
        .ant-tabs-tab.ant-tabs-tab-active {
          background: var(--blue-color);
          .ant-tabs-tab-btn {
            color: var(--white);
          }
        }
        .ant-tabs-ink-bar {
          display: none;
        }
      }
      .ant-tabs-tab {
        margin: 0px;
        margin-right: 10px;
        color: var(--gray-color);
        padding: 7px 10px;
        font-weight: 600;
      }
    }
  }
`;

export const TabWrapperBlueThemed = styled.div`
  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 0;
  }
  .ant-tabs {
    margin: 10px 5px 5px;
  }

  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 0.25rem 0.25rem 0 0;
    background-color: var(--tabs-nav-bg);
    font-size: var(--tabs-nav-font-size);
    border-color: transparent transparent var(--tabs-nav-hover-border-color);
    &:hover,
    &:focus {
      border-width: var(--tabs-border-width);
      color: rgba(var(--black), 0.85);
      border-color: var(--tabs-nav-hover-border-color);
    }
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
    background: var(--tabs-nav-active-bg);
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--tabs-nav-active-text-color);
    font-weight: var(--font-weight-bold);
  }
`;
