import React, { useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CommonTableComponent from '../../../common/Table/CommonTable';
import { COLUMNS, DELETECOLUMN } from '../../../../constants/TableColumns/SavedSearchColumns';
import { getSavedSearchList } from '../../../../redux/actions/SavedSearch/savedSearchActions';
import { openSearchQueryInCurrentTab } from '../../../../helpers/searchTabHelper';
interface componentProps {
  showDeleteBtn?: boolean
  logExceptionType?: string
  canOccupyFullScreen?: boolean
}

const SavedSearchTableList: FC<componentProps> = ({ logExceptionType, showDeleteBtn, canOccupyFullScreen }) => {
  const dispatch = useDispatch();
  const globalSavedSearch = useSelector((state: any) => state.savedSearch);
  const rowDataList = globalSavedSearch?.savedSearchDataList?.filter((obj: any) => obj.searchType?.toLowerCase() === logExceptionType?.toLowerCase())
  useEffect(() => {
    if (globalSavedSearch?.savedSearchDataList?.length === 0) {
      dispatch(getSavedSearchList());
    }
  }, [])
  return (
    <div data-testid="saved-search-list-component" >
      <CommonTableComponent
        height={canOccupyFullScreen ? 'calc(75vh)' : 'calc(75vh - 100px)'}
        rowData={rowDataList ?? []}
        columnDefs={showDeleteBtn ? [...COLUMNS, ...DELETECOLUMN] : COLUMNS}
        isColSizeAutoFit
        showSearchBar
        hasTotalCount
        showResultsCount
        className={'secondary-theme'}
        tableSeparateHeader={canOccupyFullScreen ? 'Search Criteria' : undefined}
        onRowDoubleClicked={(params: { data: any }) => {
          openSearchQueryInCurrentTab(params?.data?.searchType, params?.data?.criteriaJson)
        }}
      />
    </div>
  );
};

SavedSearchTableList.propTypes = {
  showDeleteBtn: PropTypes.bool,
  canOccupyFullScreen: PropTypes.bool
};

export default SavedSearchTableList;
