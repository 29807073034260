import styled from 'styled-components';

export const SummaryMainWrapper = styled.div`
    padding: 0px;

    .ant-row {
        margin-bottom: 20px;
        padding: 10px 5px;
    }
    .conditional-row {
        padding: 20px 10px;
    }
`;
