import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  DrawerDetailItemsListWrapper, DrawerDetailItemsListHeaderWrapper, DetailCardBody, DetailCardFooter, DetailCardHeader, DetailCardItem, DrawerDetailListItemsWrapper, PopOverStyle, DetailBodyItemWrapper, DetailBodyItemLabel, DetailBodyItemValue, DetailCardFooterRelativeTimeWrapper
} from './style';
import dayjs from 'dayjs';
import { SettingFilled } from '@ant-design/icons';
import { IconWrapper, PopOverWrapper, SubHeaderTitle } from '../../../../assets/styles/commonStyled';
import LogExceptionDetailItemColumnConfigPopOver from './LogExceptionDetailItemColumnConfigPopOver';
import { defaultSelectedItemsForViewByPopOver } from '../../../../constants/DetailViewColumns/PopOverDefaults';
import { dateTimeFormatLabel } from '../../../../constants/commonConstants';
import { LOG_ID_KEY, EXCEPTION_ID_KEY, APPLICATION_NAME_KEY, TIMEMARK_KEY } from '../../../../constants/KeyLabels/commonKeyConstants';
import { calculateTimeDiff } from '../../../../helpers/timeHelper';

interface componentProps {
  selectedRows?: any
  logExceptionType?: string
  currentIndex: number
  onCardClickFn: any
}

const dateFormatType = 'calendar';

const LogExceptionDetailLeftPanel: FC<componentProps> = ({ selectedRows, logExceptionType, currentIndex, onCardClickFn }) => {
  const [selectedColumnsForItem, setSelectedColumnsForItem] = useState(defaultSelectedItemsForViewByPopOver);
  const [configColPopOverOpen, setConfigColPopOverOpen] = useState(false);
  const [selectedDateFormatType, setSelectedDateFormatType] = useState(dateFormatType);

  return <DrawerDetailItemsListWrapper>
    <DrawerDetailItemsListHeaderWrapper>
      <SubHeaderTitle>{logExceptionType} List</SubHeaderTitle>
      <>
        <PopOverWrapper
          open={configColPopOverOpen}
          className='log-detail-configure-column-popover'
          overlayClassName='log-detail-configure-column-overlay-classname'
          overlayInnerStyle={PopOverStyle}
          content={<LogExceptionDetailItemColumnConfigPopOver
            logExceptionType={logExceptionType}
            dateFormatType={selectedDateFormatType}
            updateDateFormatTypeFn={(formatType: string) => { setSelectedDateFormatType(formatType); setConfigColPopOverOpen(false) }}
            selectedColumns={selectedColumnsForItem}
            setSelectedColumnsToParent={(updatedCols: Array<{ value: string, label: string }>) => { setSelectedColumnsForItem(updatedCols); setConfigColPopOverOpen(false) }} />}
          title={<SubHeaderTitle>View By</SubHeaderTitle>} placement="bottomRight"
          trigger="click">
          <IconWrapper onClick={() => setConfigColPopOverOpen(!configColPopOverOpen)}><SettingFilled /></IconWrapper>
        </PopOverWrapper>
      </>

    </DrawerDetailItemsListHeaderWrapper>
    <DrawerDetailListItemsWrapper>
      {
        selectedRows?.map((listItem: any, cardIter: number) => {
          return (
            <DetailCardItem
              onClick={() => onCardClickFn(cardIter)}
              key={`${String(listItem[LOG_ID_KEY] || listItem[EXCEPTION_ID_KEY])}-item`}
              id={`${String(listItem[LOG_ID_KEY] || listItem[EXCEPTION_ID_KEY])}-item`}
              data-testid={`testid-card-item-${cardIter}`}
              className={
                logExceptionType?.toString().toLowerCase() === 'log'
                  ? (listItem[LOG_ID_KEY] === selectedRows[currentIndex][LOG_ID_KEY]) ? 'active' : ''
                  : (listItem[EXCEPTION_ID_KEY] === selectedRows[currentIndex][EXCEPTION_ID_KEY]) ? 'active' : ''}>
              <DetailCardHeader>{listItem[APPLICATION_NAME_KEY]}</DetailCardHeader>
              <DetailCardBody key={`${String(listItem[LOG_ID_KEY] || listItem[EXCEPTION_ID_KEY])}-body`}>
                {
                  selectedColumnsForItem?.length > 0 &&
                  selectedColumnsForItem?.map(elementInArray => {
                    return (<DetailBodyItemWrapper key={`${elementInArray.label}-${elementInArray.value}`}>
                      <DetailBodyItemLabel>{elementInArray.label}</DetailBodyItemLabel>
                      <DetailBodyItemValue>{listItem[elementInArray.value] ?? 'N/A'}</DetailBodyItemValue>
                    </DetailBodyItemWrapper>)
                  })
                }
              </DetailCardBody>
              <DetailCardFooter>
                <span>{dayjs(listItem[TIMEMARK_KEY]).format(dateTimeFormatLabel)}</span>
                <DetailCardFooterRelativeTimeWrapper>{calculateTimeDiff(dayjs(listItem[TIMEMARK_KEY]))}</DetailCardFooterRelativeTimeWrapper>
              </DetailCardFooter>
            </DetailCardItem>
          )
        })

      }
    </DrawerDetailListItemsWrapper>
  </DrawerDetailItemsListWrapper>;
};

LogExceptionDetailLeftPanel.propTypes = {
  selectedRows: PropTypes.any,
  logExceptionType: PropTypes.string.isRequired,
  currentIndex: PropTypes.number.isRequired,
  onCardClickFn: PropTypes.any
};

export default React.memo(LogExceptionDetailLeftPanel);
