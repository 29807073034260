import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import Tabs from '../../../../components/common/Tabs';
import { AdminBasicTabWrapper, AdminMainLayoutWrapper } from '../../../../assets/styles/commonStyled';
import { ADMIN_APPLICATION_DETAIL_TEXT } from '../../../../constants/breadCrumbTextConstants';
import { APPLICATION_NAME_ID } from '../../../../constants/KeyLabels/commonKeyConstants';
import SummaryTabContent from '../../../../components/AdditionalComponents/TabContents/ApplicationDetail/SummaryTab/SummaryTabContent';
import ListConfigTabContent from '../../../../components/AdditionalComponents/TabContents/ApplicationDetail/ListConfigTab/ListConfigTabContent';
import ExceptionConfigTabContent from '../../../../components/AdditionalComponents/TabContents/ApplicationDetail/ExceptionConfigTab';
import ReplayConfigTabContent from '../../../../components/AdditionalComponents/TabContents/ApplicationDetail/ReplayConfigTab';
import ColumnAlertConfigTabContent from '../../../../components/AdditionalComponents/TabContents/ApplicationDetail/ColumnAlertConfigTab';
import AdminDetailBreadCrumb from '../../../../components/common/BreadCrumb/AdminDetailBreadCrumb';
import UserManagementTabContent from '../../../../components/AdditionalComponents/TabContents/ApplicationDetail/UserManagementTab';
import { getLastSelectedApplicationDetailTab, setLastSelectedApplicationDetailTab } from '../../../../helpers/localStorageHelper';
interface componentProps {
  prop1?: string
}

const ApplicationDetail: FC<componentProps> = ({ prop1 }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(getLastSelectedApplicationDetailTab() ?? 'summary');
  const { applicationDetailData, isEditMode } = location?.state as any

  return (
    <AdminMainLayoutWrapper data-testid={'testid-application-detail'}>
      <AdminDetailBreadCrumb
        isFromApplicationConfig={false}
        mainPageText={ADMIN_APPLICATION_DETAIL_TEXT}
        currentPageText={applicationDetailData?.[APPLICATION_NAME_ID]}
      />
      <AdminBasicTabWrapper>
        <Tabs
          tabClassName='basic-theme'
          defaultActiveTab="summary"
          tabSize="small"
          tabType='line'
          activeTab={activeTab}
          onTabChangeHandler={key => {
            setActiveTab(key);
            setLastSelectedApplicationDetailTab(key);
          }}
          tabItems={[
            {
              key: 'summary',
              label: 'Summary',
              children: <SummaryTabContent applicationDetailData={applicationDetailData} isEditMode={isEditMode} />
            },
            {
              key: 'listConfig',
              label: 'List Configuration',
              children: <ListConfigTabContent applicationDetailData={applicationDetailData} />
            },
            {
              key: 'exceptionConfig',
              label: 'Exception Configuration',
              children: <ExceptionConfigTabContent applicationDetailData={applicationDetailData} />
            },
            {
              key: 'replayConfig',
              label: 'Replay Configuration',
              children: <ReplayConfigTabContent applicationDetailData={applicationDetailData} />
            },
            {
              key: 'columnAlert',
              label: 'Column Alert',
              children: <ColumnAlertConfigTabContent applicationDetailData={applicationDetailData} />
            },
            {
              key: 'userManagement',
              label: 'User Management',
              children: (
                <UserManagementTabContent
                  applicationName={applicationDetailData?.[APPLICATION_NAME_ID]}
                  isEditable={isEditMode}
                />
              )
            }
          ]}
        />
      </AdminBasicTabWrapper>
    </AdminMainLayoutWrapper>
  );
};

ApplicationDetail.propTypes = {
  prop1: PropTypes.string
};

export default ApplicationDetail;
