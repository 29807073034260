export const logFields = [
  {
    label: 'Transaction ID',
    name: 'transactionID',
    id: 'sel_transactionID',
    type: 'text'
  },
  {
    label: 'Business ID',
    name: 'businessID',
    id: 'sel_businessID',
    type: 'text'
  },
  {
    label: 'Business ID2',
    name: 'businessID2',
    id: 'sel_businessID2',
    type: 'text'
  },
  {
    label: 'Service',
    name: 'serviceName',
    id: 'sel_serviceName',
    type: 'text'
  },
  {
    label: 'Component',
    name: 'componentName',
    id: 'sel_componentName',
    type: 'text'
  },
  {
    label: 'Name',
    name: 'messageName',
    id: 'sel_messageName',
    type: 'text'
  },
  {
    label: 'Value',
    name: 'messageValue',
    id: 'sel_messageValue',
    type: 'text'
  },
  {
    label: 'Application Domain',
    name: 'applicationDomain',
    id: 'sel_applicationDomain',
    type: 'text'
  },
  {
    label: 'Before Data',
    name: 'transactionBefore',
    id: 'sel_transactionBefore',
    type: 'text'
  },
  {
    label: 'After Data',
    name: 'transactionAfter',
    id: 'sel_transactionAfter',
    type: 'text'
  },
  {
    label: 'Transaction Domain',
    name: 'transactionDomain',
    id: 'sel_transactionDomain',
    type: 'text'
  },
  {
    label: 'Status',
    name: 'logStatus',
    id: 'sel_status',
    type: 'text'
  },
  {
    label: 'Category',
    name: 'logCategory',
    id: 'sel_logCategory',
    type: 'text'
  },
  {
    label: 'Transaction Type',
    name: 'transactionType',
    id: 'sel_transactionType',
    type: 'text'
  },
  {
    label: 'HostName',
    name: 'hostname',
    id: 'sel_hostname',
    type: 'text'
  }
];
