import { getSelectedDaysLabel } from '../../../helpers/commonHelper';

export const reportConfigFormFields = [
  {
    label: 'Report Config ID',
    name: 'reportConfigId',
    id: 'reportConfigId',
    type: 'text',
    disableOnEdit: true
  },
  {
    label: 'Application Name',
    name: 'applicationName',
    id: 'applicationName',
    rules: [{ required: true, message: 'Application is mandatory!' }],
    required: true,
    disableOnEdit: true,
    type: 'select'
  },
  {
    label: 'Service Name',
    name: 'serviceName',
    id: 'serviceName',
    required: true,
    rules: [{ required: true, message: 'Service Name is mandatory!' }],
    type: 'text',
    disableOnEdit: true
  },
  {
    label: 'Email',
    name: 'email',
    id: 'email',
    required: true,
    rules: [
      { required: true, message: 'Email is mandatory!' }
      // { pattern: /^([a-z][a-z0-9_.]+@([a-z0-9-]+\.)+[a-z]{2,6}(, |,)*[^,|, ])+$/, message: 'Email is not valid!' }
    ],
    type: 'text'
  },
  {
    label: 'Email Message',
    name: 'emailMsg',
    id: 'emailMsg',
    type: 'text'
  },
  {
    label: 'Message Code',
    name: 'messageCode',
    id: 'messageCode',
    type: 'text'
  },
  {
    label: 'Message Fct',
    name: 'messsageFct',
    id: 'messsageFct',
    type: 'text'
  },
  {
    label: 'Message Type',
    name: 'messageType',
    id: 'messageType',
    type: 'text'
  },
  {
    label: 'Assignment Group',
    name: 'assignmentGroup',
    id: 'assignmentGroup',
    type: 'text'
  },
  {
    label: 'Alert Type',
    name: 'alertType',
    id: 'alertType',
    type: 'select'
  },
  {
    label: 'Threshold Low Limit',
    name: 'thresholdLowLimit',
    id: 'thresholdLowLimit',
    required: true,
    rules: [{ required: true, message: 'Threshold Low Limit is mandatory!' }],
    type: 'number'
  },
  {
    label: 'Threshold High Limit',
    name: 'thresholdHighLimit',
    id: 'thresholdHighLimit',
    required: true,
    rules: [{ required: true, message: 'Threshold High Limit is mandatory!' }],
    type: 'number'
  },
  {
    label: 'Threshold Days',
    name: 'thresholdDays',
    id: 'thresholdDays',
    type: 'multiSelect',
    formatter: getSelectedDaysLabel
  },
  {
    label: 'Allow Breach',
    name: 'allowBreach',
    id: 'allowBreach',
    type: 'radio'
  }
];
