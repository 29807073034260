import React, { useEffect } from 'react';
import type { FC } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ReplayStatusCountBar from '../../../components/common/ReplayStatusCountBar';
import ReplayFileStatusCountBar from '../../../components/AdditionalComponents/FileReplay/ReplayFileStatusCountBar';
import CommonTableComponent from '../../../components/common/Table/CommonTable';
import { getReplayFileDetails } from '../../../redux/actions/FileReplay/fileReplayActions';
import { fileReplayDetailColumns } from '../../../constants/TableColumns/FileReplayDetailTableColumns';
import textConstants from '../../../constants/textConstants';
import routeConstants from '../../../constants/routeConstants';

import {
  FileReplayContainer,
  FileReplayHeader,
  FileReplayWrapper,
  FileReplayTableWrapper
} from '../style';

const FileReplayDrawer: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { replayID } = location.state as { replayID?: string } ?? {};
  const replayDashboardDetail = useSelector((state: any) => state.fileReplay.fileReplayDetails);

  useEffect(() => {
    if (replayID) {
      dispatch(getReplayFileDetails(replayID));
    }
  }, []);

  return (
    <FileReplayWrapper className='h-100' data-testid="fileReplayDetailWrapper">
      <FileReplayHeader>
        <Link to={routeConstants.EXCEPTION_FILE_REPLAY}><ArrowLeftOutlined /></Link>&nbsp;
        <Link to={routeConstants.EXCEPTION_FILE_REPLAY}>{textConstants.FILE_REPLAY_DASHBOARD_TITLE}</Link> / <span className='replay-header-info'>Detail / {replayID}</span>
      </FileReplayHeader>
      <FileReplayContainer>
        {/* <FileReplayDetailSubheader>
          <span className='detail-title'> {textConstants.FILE_REPLAY_DETAIL_SUBHEADER_TITLE}</span>
        </FileReplayDetailSubheader> */}
        <ReplayStatusCountBar replayData={{
          resolved: replayDashboardDetail?.resolvedReplay || 0,
          progress: replayDashboardDetail?.inProgressReplay || 0,
          schedule: replayDashboardDetail?.scheduledReplay || 0,
          pending: replayDashboardDetail?.pendingReplay || 0,
          error: replayDashboardDetail?.erroredReplay || 0
        }} />
        <ReplayFileStatusCountBar data={{
          replayId: replayID,
          exceptionCount: replayDashboardDetail?.exceptionCount,
          overAllStatus: replayDashboardDetail?.fileReplayStatus
        }} />
        <FileReplayTableWrapper>
          <CommonTableComponent
            filterBy={{
              fieldName: 'replay_status',
              fieldLabel: 'Status'
            }}
            rowData={replayDashboardDetail.fileReplayStatuses}
            columnDefs={fileReplayDetailColumns}
            className={'primary-theme'}
            tableTitle={textConstants.FILE_REPLAY_DETAIL_TABLE_TITLE}
            hasTotalCount
            showHeaderFilterButton
            showResultsCount
            showSearchBar
          />
        </FileReplayTableWrapper>
      </FileReplayContainer>
    </FileReplayWrapper>
  );
};

export default FileReplayDrawer;
