import { call, put, fork, all, takeLatest } from 'redux-saga/effects';
import type { AllEffect, CallEffect, ForkEffect, PutEffect } from 'redux-saga/effects';
import { createLogger } from 'redux-logger';
import { apiRequest } from '../../../services/axiosInterceptor';
import { setExceptionDetailBlobMetricData, setLogDetailBlobMetricData, setLogSummaryBlobMetricData, setExceptionSummaryBlobMetricData } from '../../actions/Admin/blobMetricsActions';
import { GET_LOG_SUMMARY_BLOB_METRIC_DATA, GET_EXCEPTION_SUMMARY_BLOB_METRIC_DATA, GET_LOG_DETAIL_BLOB_METRIC_DATA, GET_EXCEPTION_DETAIL_BLOB_METRIC_DATA } from '../../actions/ActionTypes/Admin/blobMetricsTypes';
import { updateDataLoadingStatus } from '../../actions/CommonConfig/commonConfigActions';
import { appendArrayWithKeyValue } from '../../../helpers/commonHelper';
import { API_SUCCESS_STATUS_CODE } from '../../../constants/commonConstants';
import { PAGE_ID_KEY } from '../../../constants/KeyLabels/commonKeyConstants';
import apiURL from '../../../constants/apiEndpointConstants';

const callEffect: any = call;

export function * fetchBlobMetricsLogSummarySaga ({ timeRange }: any): Generator<
CallEffect<any> | PutEffect<any>,
void
> {
  try {
    const config = {
      method: 'GET',
      url: `${apiURL.GET_LOG_SUMMARY_BLOB_METRIC_DATA}${timeRange}`
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response.status === API_SUCCESS_STATUS_CODE) {
      yield put(setLogSummaryBlobMetricData(appendArrayWithKeyValue(response.data, 'type', 'Log')));
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * fetchBlobMetricsExceptionSummarySaga ({ timeRange }: any): Generator<
CallEffect<any> | PutEffect<any>,
void
> {
  try {
    const config = {
      method: 'GET',
      url: `${apiURL.GET_EXCEPTION_SUMMARY_BLOB_METRIC_DATA}${timeRange}`
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response.status === API_SUCCESS_STATUS_CODE) {
      yield put(setExceptionSummaryBlobMetricData(appendArrayWithKeyValue(response.data, 'type', 'Exception')));
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * fetchBlobMetricsLogDetailSaga (payload: any): Generator<
CallEffect<any> | PutEffect<any>,
void
> {
  try {
    const config: any = {
      method: 'POST',
      url: apiURL.GET_LOG_DETAILS_BLOB_METRIC_DATA,
      data: payload.data
    };
    const logDataList: any[] = [];

    while (config.data) {
      const response: any = yield callEffect(apiRequest, config);

      if (response.status === API_SUCCESS_STATUS_CODE) {
        logDataList.push(...response.data.data);
        yield put(setLogDetailBlobMetricData(appendArrayWithKeyValue(logDataList, 'type', 'Log')));
        if (response.data[PAGE_ID_KEY]) {
          config.data[PAGE_ID_KEY] = response.data[PAGE_ID_KEY];
        } else {
          break;
        }
      }
    }
  } catch (err: any) {
    createLogger(err);
  }
}

export function * fetchBlobMetricsExceptionDetailSaga (payload: any): Generator<
CallEffect<any> | PutEffect<any>,
void
> {
  try {
    const config = {
      method: 'POST',
      url: apiURL.GET_EXCEPTION_DETAILS_BLOB_METRIC_DATA,
      data: payload.data
    };

    const exceptionDataList: any[] = [];

    while (config.data) {
      const response: any = yield callEffect(apiRequest, config);

      if (response.status === API_SUCCESS_STATUS_CODE) {
        exceptionDataList.push(...response.data.data);
        yield put(setExceptionDetailBlobMetricData(appendArrayWithKeyValue(exceptionDataList, 'type', 'Exception')));
        if (response.data[PAGE_ID_KEY]) {
          console.log('response', response.data);
          config.data[PAGE_ID_KEY] = response.data[PAGE_ID_KEY];
        } else {
          break;
        }
      }
    }
  } catch (err: any) {
    createLogger(err);
  }
}

export function * getBlobMetricsLogSummaryData (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_LOG_SUMMARY_BLOB_METRIC_DATA, fetchBlobMetricsLogSummarySaga);
}
export function * getBlobMetricsExceptionSummaryData (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_EXCEPTION_SUMMARY_BLOB_METRIC_DATA, fetchBlobMetricsExceptionSummarySaga);
}

export function * getBlobMetricsLogDetailData (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_LOG_DETAIL_BLOB_METRIC_DATA, fetchBlobMetricsLogDetailSaga);
}
export function * getBlobMetricsExceptionDetailData (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_EXCEPTION_DETAIL_BLOB_METRIC_DATA, fetchBlobMetricsExceptionDetailSaga);
}

export default function * root (): Generator<AllEffect<any>> {
  yield all([
    fork(getBlobMetricsLogSummaryData),
    fork(getBlobMetricsExceptionSummaryData),
    fork(getBlobMetricsLogDetailData),
    fork(getBlobMetricsExceptionDetailData)
  ]);
}
