import { clone } from 'lodash';
import { getUserStatus } from './commonHelper';

export const findIndex = (arrayToFindIn: any[], key: string, valueToFind: string): number => {
  if (arrayToFindIn && arrayToFindIn.length > 0) {
    return arrayToFindIn.findIndex((stateObject) => stateObject[key] === valueToFind);
  }
  return -1;
};
export const formatData = (data: any, prevState: any[], id?: string): any[] => {
  const availData = prevState;
  if (data) {
    switch (data.action) {
      case 'NEW':
        return data.data;
      case 'ADD':
        availData.push(data.data);
        return availData;
      default:
        return availData;
    }
  }
  return [];
};

export const processOperation = (newPayload: any, stateData: any[]): any[] => {
  let availData = clone(stateData);
  if (newPayload) {
    switch (newPayload.action) {
      case 'NEW':
        return newPayload.data;
      case 'ADD':
        return Array.isArray(newPayload?.data) ? [...availData, ...newPayload.data] : [...availData, newPayload.data];
      case 'UPDATE': {
        if (availData && availData.length > 0 && newPayload.keyName && newPayload.keyValue) {
          const indexInArray = findIndex(availData, newPayload.keyName, newPayload.keyValue);
          if (indexInArray > -1) {
            availData[indexInArray] = newPayload.data;
          }
        }
        return availData;
      }
      case 'UPDATE_MULTIPLE':
        if (availData && availData.length > 0 && newPayload.keyName) {
          availData = availData.map(item => {
            const item2 = newPayload.data.find((updatedItem: any) => updatedItem[newPayload.keyName] === item[newPayload.keyName]);
            return item2 ? { ...item, ...item2 } : item;
          });
        }
        return availData;
      case 'DELETE': {
        if (availData && availData.length > 0 && newPayload.keyName && newPayload.keyValue) {
          const indexInArray = findIndex(availData, newPayload.keyName, newPayload.keyValue);
          if (indexInArray > -1) {
            availData.splice(indexInArray, 1);
          }
          // return availData.filter((stateObject) => stateObject[keyName] !== keyValue);
        }
        return availData;
      }
      case 'DELETE_MULTIPLE': {
        if (availData && availData.length > 0 && newPayload.keyName) {
          const deleteItems = newPayload.data.map((item: any) => item[newPayload.keyName]);

          availData = availData.filter((item) => !deleteItems.includes(item[newPayload.keyName]));
        }
        return availData;
      }
      default:
        return availData;
    }
  }
  return [];
};

export const getUserDataWithActiveStatus = (data: any[]): any[] => {
  return data.map((item) => ({
    ...item,
    status: getUserStatus(item?.lastLogin)
  }))
}
