export const GET_APPLICATION_DETAILS = 'GET_APPLICATION_DETAILS';
export const SET_APPLICATION_DETAILS = 'SET_APPLICATION_DETAILS';
export const GET_APPLICATION_EXCEPTION_CATEGORY = 'GET_APPLICATION_EXCEPTION_CATEGORY';
export const GET_ALL_EXCEPTION_CATEGORY = 'GET_ALL_EXCEPTION_CATEGORY';
export const GET_APPLICATION_EXCEPTION_SEVERITY = 'GET_APPLICATION_EXCEPTION_SEVERITY';
export const GET_ALL_EXCEPTION_SEVERITY = 'GET_ALL_EXCEPTION_SEVERITY';
export const GET_APPLICATION_EXCEPTION_TYPE = 'GET_APPLICATION_EXCEPTION_TYPE';
export const GET_ALL_EXCEPTION_TYPE = 'GET_ALL_EXCEPTION_TYPE';
export const SET_APPLICATION_CTS_DATA = 'SET_APPLICATION_CTS_DATA';
export const GET_APPLICATION_SECTOR_LIST = 'GET_APPLICATION_SECTOR_LIST';
export const SET_APPLICATION_SECTOR_LIST = 'SET_APPLICATION_SECTOR_LIST';
export const UPDATE_APPLICATION_DETAILS = 'UPDATE_APPLICATION_DETAILS';
export const DELETE_APPLICATION_DETAILS = 'DELETE_APPLICATION_DETAILS';
export const SAVE_APPLICATION_DETAILS = 'SAVE_APPLICATION_DETAILS';

// application list
export const SAVE_APPLICATION_EXCEPTION_CATEGORY = 'SAVE_APPLICATION_EXCEPTION_CATEGORY';
export const DELETE_APPLICATION_EXCEPTION_CATEGORY = 'DELETE_APPLICATION_EXCEPTION_CATEGORY';

export const SAVE_APPLICATION_EXCEPTION_TYPE = 'SAVE_APPLICATION_EXCEPTION_TYPE';
export const DELETE_APPLICATION_EXCEPTION_TYPE = 'DELETE_APPLICATION_EXCEPTION_TYPE';

export const SAVE_APPLICATION_EXCEPTION_SEVERITY = 'SAVE_APPLICATION_EXCEPTION_SEVERITY';
export const DELETE_APPLICATION_EXCEPTION_SEVERITY = 'DELETE_APPLICATION_EXCEPTION_SEVERITY';
