import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { applicationFormFields } from '../../../../../constants/FormFields/AdminForm/applicationFormFields';
import { LabelValueWrapper, LabelWrapper } from '../../../../../assets/styles/commonStyled';
import { type FormFieldType } from '../../../../../constants/Interfaces/FormFieldTypes';
import { ApplicationFormItemsLayout } from '../../../../../constants/FormLayout/ApplicationFormLayout';

interface componentProps {
  applicationDetailData?: any
}
const columnSpan = ApplicationFormItemsLayout.columnSpan
const SummaryApplicationDetail: FC<componentProps> = ({ applicationDetailData }) => {
  return <Row gutter={[16, 8]} data-testid="testid-application-detail-summary-details">
    {
      applicationFormFields.map((formField: FormFieldType) => {
        return (
          <Col key={formField.id} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
            <LabelWrapper>{formField.label}</LabelWrapper>
            <LabelValueWrapper>
              {(applicationDetailData?.[formField.name] ? applicationDetailData?.[formField.name] : 'N/A')}
            </LabelValueWrapper>
          </Col>);
      })
    }
  </Row>;
};

SummaryApplicationDetail.propTypes = {
  applicationDetailData: PropTypes.object
};

export default SummaryApplicationDetail;
