import React, { useRef, useState } from 'react';
import type { FC } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Tabs from '../../../../components/common/Tabs';
import ConfigDetailCardHeader from '../../../../components/AdditionalComponents/ConfigDetailCardHeader';
import {
  AdminMainLayoutWrapper,
  AdminBasicTabWrapper
} from '../../../../assets/styles/commonStyled';
import {
  ConfigDetailMainWrapper,
  ConfigDetailWrapper
} from '../../../../components/AdditionalComponents/DetailSummaryPage/Admin/style';
import AdminDetailBreadCrumb from '../../../../components/common/BreadCrumb/AdminDetailBreadCrumb';
import textConstants from '../../../../constants/textConstants';
import {
  deleteGroupApplicationAccess,
  saveGroupApplicationAccess,
  saveGroupConfig
} from '../../../../redux/actions/AccessManagement/GroupAccess/groupAccessActions';
import { checkIfExistsInArray } from '../../../../helpers/commonHelper';
import { errorNotification } from '../../../../components/common/Notification';
import GroupAccessForm from '../../../../components/AdditionalComponents/UpsertForms/AccessManagement/GroupAccess';
import ApplicationAccessTab from '../../../../components/AdditionalComponents/TabContents/GroupAccess/ApplicationAccessTab';
import GroupAccessSummary from '../../../../components/AdditionalComponents/DetailSummaryPage/AccessManagement/GroupAccess/GroupAccessSummary';
import UserListTab from '../../../../components/AdditionalComponents/TabContents/GroupAccess/UserListTab';
import { isEqual } from 'lodash';
import { APPLICATION_ACCESS_LIST_TAB_KEY, USER_LIST_TAB_KEY } from '../../../../constants/KeyLabels/commonKeyConstants';
import routeConstants from '../../../../constants/routeConstants';
import { type GroupApplicationAccessType } from '../../../../constants/Interfaces/GroupAccessTypes';

const GroupAccessDetail: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const groupConfigList = useSelector((state: any) => state.groupConfig.groupConfigList);
  const { data, isAdd, isEditable } =
    (location?.state as { isAdd?: boolean, isEditable?: boolean, data: any }) ?? {};

  const { applicationCount, ...initialGroupConfig } = data ?? {};
  const [isConfigDetailInEditMode, setConfigDetailInEditMode] = useState(isEditable ?? false);
  const [configData, setConfigData] = useState(initialGroupConfig);
  const [userListValidationError, setUserListValidationError] = useState('');
  const [currentActiveTab, setActiveTabName] = useState(USER_LIST_TAB_KEY);
  const applicationAccessRef = useRef<any>();

  const onFormSaveCancelHandler = (): void => {
    if (isEditable) {
      history.push(routeConstants.ADMIN_GROUP_ACCESS);
    } else {
      setConfigDetailInEditMode(false);
    }
  };

  const onTabChange = (tabName: string): void => {
    setActiveTabName(tabName);
  }

  const saveApplicationAccessConfig = (isGroupConfigChanged: boolean, groupName: string): void => {
    const appAccessData = applicationAccessRef?.current?.getApplicationAccess();

    if (!appAccessData && isGroupConfigChanged && !isAdd) {
      onFormSaveCancelHandler();
    }

    if (appAccessData?.added && appAccessData.added?.length > 0) {
      let appAccessItems = appAccessData?.added ?? [];

      if (isAdd) {
        appAccessItems = appAccessItems.map((item: GroupApplicationAccessType) => ({ ...item, groupName }))
      }

      dispatch(saveGroupApplicationAccess(appAccessItems, !isAdd ? onFormSaveCancelHandler : null, true));
    }

    if (!isAdd && appAccessData?.updated && appAccessData?.updated?.length > 0) {
      dispatch(saveGroupApplicationAccess(appAccessData?.updated, onFormSaveCancelHandler));
    }

    if (!isAdd && appAccessData?.deleted && appAccessData?.deleted?.length > 0) {
      dispatch(deleteGroupApplicationAccess(appAccessData?.deleted, onFormSaveCancelHandler));
    }
  }

  const saveData = (groupConfig: any, isFormValid: boolean, isClosable?: boolean): void => {
    const isGroupConfigUnchanged = isEqual(initialGroupConfig, groupConfig);

    if (isFormValid) {
      setConfigData(groupConfig);

      if (!isGroupConfigUnchanged) {
        dispatch(saveGroupConfig(groupConfig, isClosable ? onFormSaveCancelHandler : null, isAdd));
      }
      saveApplicationAccessConfig(isAdd ? false : isGroupConfigUnchanged, groupConfig.groupName);
    }
  }

  const onSaveHandler = async (groupConfigData: any, isClosable?: boolean): Promise<void> => {
    const userListIds = configData?.userList ?? [];
    const groupData = {
      ...groupConfigData,
      userList: userListIds
    };

    const userLengthValidationError = userListIds.length < 1 ? textConstants.GROUP_USER_LENGTH_VALIDATION_ERROR : '';
    const applicationAccessValidationError = await applicationAccessRef?.current?.checkIsApplicationAccessFormValid();
    setUserListValidationError(userLengthValidationError);

    if (!userLengthValidationError && applicationAccessValidationError) {
      setActiveTabName(APPLICATION_ACCESS_LIST_TAB_KEY);
    }

    if (isAdd) {
      if (!checkIfExistsInArray(groupConfigList, groupConfigData, 'groupName')) {
        saveData(groupData, !userLengthValidationError && !applicationAccessValidationError, isClosable);
      } else {
        errorNotification(textConstants.GROUP_ALREADY_EXIST_MESSAGE);
      }
    } else {
      saveData(groupData, !userLengthValidationError && !applicationAccessValidationError, true);
    }
  };

  const handleRemoveUserFromGroup = (ignoreUserIDList: any): void => {
    const updatedUserList = configData?.userList.filter((user: string) => !ignoreUserIDList.includes(user));
    setConfigData({
      ...configData,
      userList: updatedUserList
    });
  }

  const handleAddUserInGroup = (userIds: string[]): void => {
    setUserListValidationError('')
    setConfigData({
      ...configData,
      userList: configData?.userList ? [...configData.userList, ...userIds] : [...userIds]
    })
  }

  return (
    <AdminMainLayoutWrapper data-testid="groupAccessDetail">
      <AdminDetailBreadCrumb
        mainPageText={textConstants.GROUP_LIST_PAGE_TITLE}
        currentPageText={isAdd ? textConstants.GROUP_DETAIL_CREATE_GROUP_TITLE : data?.groupName}
      />
      <ConfigDetailMainWrapper hasBottomGap={isConfigDetailInEditMode}>
        <ConfigDetailWrapper>
          <ConfigDetailCardHeader
            isDeleteEnabled={false}
            onEdit={() => setConfigDetailInEditMode(true)}
            isEditEnabled={!isAdd && !isConfigDetailInEditMode}
            title={textConstants.GROUP_DETAIL_PAGE_TITLE}
          />
          {isConfigDetailInEditMode && (
            <GroupAccessForm
              data={configData}
              onSave={onSaveHandler}
              onCancel={onFormSaveCancelHandler}
              isEditable={!isAdd}
            />
          )}
          {!isConfigDetailInEditMode && (
            <GroupAccessSummary
              data={configData}
            />
          )}
          <AdminBasicTabWrapper className='group-access-tab'>
            <Tabs
              activeTab={currentActiveTab}
              tabClassName="basic-theme"
              defaultActiveTab="userList"
              onTabChangeHandler={onTabChange}
              animated={false}
              tabSize="small"
              tabType="line"
              tabItems={[
                {
                  key: USER_LIST_TAB_KEY,
                  label: 'User List',
                  children: (
                    <UserListTab
                      userIdList={configData?.userList}
                      onUserAdd={handleAddUserInGroup}
                      onUserDelete={handleRemoveUserFromGroup}
                      isEditable={isConfigDetailInEditMode}
                    />
                  )
                },
                {
                  key: APPLICATION_ACCESS_LIST_TAB_KEY,
                  label: 'Application List',
                  children: (
                    <ApplicationAccessTab
                      isEditable={isConfigDetailInEditMode}
                      isAddView={isAdd}
                      groupName={configData?.groupName}
                      ref={applicationAccessRef}
                    />
                  )
                }
              ]}
            />
          </AdminBasicTabWrapper>
          {userListValidationError && isConfigDetailInEditMode && currentActiveTab === USER_LIST_TAB_KEY && (
            <div className='error-text'>
              {userListValidationError}
            </div>
          )}
        </ConfigDetailWrapper>
      </ConfigDetailMainWrapper>
    </AdminMainLayoutWrapper>
  );
};

export default GroupAccessDetail;
