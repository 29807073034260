import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import type { FormFieldType } from '../../../../../constants/Interfaces/FormFieldTypes';
import {
  LabelValueWrapper,
  LabelWrapper
} from '../../../../../assets/styles/commonStyled';
import { SummaryViewRow } from '../../Admin/style';
import { getLabelValueFormatter } from '../../../../../helpers/commonHelper';
import { userAccessFormFields } from '../../../../../constants/FormFields/AccessManagementForm/userAccessFormFields';
import ApplicationAccessSummary from '../../../../common/AccessComponent/ApplicationAccessSummary';

interface ConfigSummaryProps {
  data?: Partial<Record<string, string>>
}

const UserAccessSummary: FC<ConfigSummaryProps> = ({ data }) => {
  return (
    <>
      <SummaryViewRow
        gutter={[{ md: 12, xl: 16, xxl: 28 }, 2]}
        data-testid="userAccessSummary"
      >
        {userAccessFormFields.map((formField: FormFieldType) => {
          return (
            <Col key={formField.id} xs={24} md={6} xl={6} xxl={6}>
              <LabelWrapper>{formField.label}</LabelWrapper>
              <LabelValueWrapper fullWidth>{getLabelValueFormatter(data?.[formField.name], formField?.type)}</LabelValueWrapper>
            </Col>
          );
        })}
      </SummaryViewRow>
      <ApplicationAccessSummary
        data={data}
        fieldName={'roleAccess'}
        showAppAdminAccess={false}
      />
    </>
  );
};

UserAccessSummary.propTypes = {
  data: PropTypes.object
};

export default UserAccessSummary;
