import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import LogExceptionDetailsBasicInfo from '../../LogException/LogExceptionDetailsBasicInfo';
import LogExceptionDetailsTabContent from '../../LogException/LogExceptionDetailsTabContent';
import {
  DrawerContentWrapper, DrawerRightSideDetailWraper, DrawerFooterWrapper, DrawerBasicDetailWrapper, DrawerTabContentWrapper
} from './style';
import LogExceptionDetailLeftPanel from './LogExceptionDetailLeftPanel';
import { Button } from 'antd';
import { ReplayButton } from '../../../common/Footer/Buttons.style';
import { exceptionReplayAllowedStatus } from '../../../../constants/DropDownOptions/logExceptionSearch';
import { EXCEPTION_ID_KEY, LOG_ID_KEY } from '../../../../constants/KeyLabels/commonKeyConstants';
import { AntdDrawer } from '../../../../assets/styles/commonStyled';
import textConstants from '../../../../constants/textConstants';

interface componentProps {
  hasDownload?: boolean
  handleItemNavigation?: any
  handleExceptionReplay?: any
  isDrawerOpen?: boolean
  isMetricDrawer?: boolean
  isReplayAllowed?: boolean
  onClose?: any
  logExceptionType: string
  selectedRows: any[]
  selectedItemDetails: any
  selectedItemIndex: number
  updateItemDetailTransactionData: any
}
const LogExceptionDetailDrawer: FC<componentProps> = ({
  handleItemNavigation,
  handleExceptionReplay,
  hasDownload,
  isDrawerOpen,
  isMetricDrawer,
  isReplayAllowed,
  onClose,
  logExceptionType,
  selectedRows,
  selectedItemDetails,
  selectedItemIndex,
  updateItemDetailTransactionData
}) => {
  return (
    <AntdDrawer
      title={`${logExceptionType} ${isMetricDrawer ? 'Metrics' : ''} Details`}
      placement="right"
      onClose={onClose}
      open={isDrawerOpen}
      getContainer={false}
      width={selectedRows.length > 1 ? '100%' : '80%'}
      maskClosable
      data-testid="testid-detail-drawer"
    >
      <DrawerContentWrapper>
        {
          selectedRows &&
          selectedRows.length > 1 &&
          <LogExceptionDetailLeftPanel
            key="log-exception-left-panel-for-detail"
            selectedRows={selectedRows}
            logExceptionType={logExceptionType}
            currentIndex={selectedItemIndex}
            onCardClickFn={(selectedIndex: number) => handleItemNavigation(selectedIndex)}
          />
        }
        <DrawerRightSideDetailWraper data-testid="test-id-log-exception-detail-wrapper">
          <div>
            <DrawerBasicDetailWrapper>
              <LogExceptionDetailsBasicInfo
                data={selectedItemDetails}
                logExceptionType={logExceptionType}
              />
            </DrawerBasicDetailWrapper>
            <DrawerTabContentWrapper>
              <LogExceptionDetailsTabContent
                data={selectedItemDetails}
                logExceptionType={logExceptionType}
                updateItemDetailTransactionData={updateItemDetailTransactionData}
                hasDownload={hasDownload}
                isMetricView={isMetricDrawer}
              />
            </DrawerTabContentWrapper>
          </div>
          <DrawerFooterWrapper>
            {selectedRows && selectedRows.length > 1 && (
              <>
                <Button
                  data-testid="test-id-log-exception-detail-prev-btn"
                  disabled={selectedItemIndex === 0}
                  type="text"
                  onClick={() => {
                    handleItemNavigation(selectedItemIndex - 1);
                    if (selectedRows && selectedRows.length > 0 && selectedRows[(selectedItemIndex - 1)]) {
                      document.getElementById(`${String(selectedRows[(selectedItemIndex - 1)][LOG_ID_KEY] || selectedRows[(selectedItemIndex - 1)][EXCEPTION_ID_KEY])}-item`)?.scrollIntoView()
                    }
                  }}
                >
                  {textConstants.PREVIOUS_BUTTON_LABEL}
                </Button>
                <Button
                  data-testid="test-id-log-exception-detail-next-btn"
                  disabled={selectedItemIndex === selectedRows.length - 1}
                  type="primary" onClick={() => {
                    handleItemNavigation(selectedItemIndex + 1);
                    if (selectedRows && selectedRows.length > 0 && selectedRows[(selectedItemIndex + 1)]) {
                      document.getElementById(`${String(selectedRows[(selectedItemIndex + 1)][LOG_ID_KEY] || selectedRows[(selectedItemIndex + 1)][EXCEPTION_ID_KEY])}-item`)?.scrollIntoView()
                    }
                  }}
                >
                  {textConstants.NEXT_BUTTON_LABEL}
                </Button>
              </>
            )}
            {
              isReplayAllowed && exceptionReplayAllowedStatus.includes(selectedItemDetails?.exception_status) &&
              <ReplayButton onClick={handleExceptionReplay} data-testid="replayBtn">
                {textConstants.REPLAY_BUTTON_LABEL}
              </ReplayButton>
            }
          </DrawerFooterWrapper>
        </DrawerRightSideDetailWraper>
      </DrawerContentWrapper>
    </AntdDrawer >
  );
};

LogExceptionDetailDrawer.defaultProps = {
  logExceptionType: 'Log',
  isMetricDrawer: false
}

LogExceptionDetailDrawer.propTypes = {
  handleItemNavigation: PropTypes.func,
  handleExceptionReplay: PropTypes.func,
  hasDownload: PropTypes.bool,
  isDrawerOpen: PropTypes.bool,
  isMetricDrawer: PropTypes.bool,
  isReplayAllowed: PropTypes.bool,
  onClose: PropTypes.func,
  logExceptionType: PropTypes.string.isRequired,
  selectedRows: PropTypes.array.isRequired,
  selectedItemDetails: PropTypes.object.isRequired,
  selectedItemIndex: PropTypes.number.isRequired,
  updateItemDetailTransactionData: PropTypes.func
};

export default React.memo(LogExceptionDetailDrawer);
