import { store } from '../redux/store/store';
import {
  addNewLogTab,
  removeLogTab,
  storeLogSearchQuery
} from '../redux/actions/LogSearch/logSearchActions';
import {
  addNewExceptionTab,
  removeExceptionTab,
  storeExceptionSearchQuery
} from '../redux/actions/ExceptionSearch/exceptionSearchActions';
import { SEARCH_TAB_MAX_LIMIT } from '../constants/commonConstants';

export const createNewSearchTabByType = (
  logOrExceptionType: string,
  searchCriteria: string | null = null
): void => {
  const globalStore = store.getState();
  if (logOrExceptionType?.toString().toLowerCase() === 'log') {
    const globalLogSearchRedcuer = globalStore?.logSearchReducer;
    if (globalLogSearchRedcuer?.logTabList?.length !== SEARCH_TAB_MAX_LIMIT) {
      store.dispatch(
        addNewLogTab({
          label: `Search ${String(globalLogSearchRedcuer?.logTabNextCount)}`,
          key: `Search-${String(globalLogSearchRedcuer?.logTabNextCount)}`,
          closable: true,
          searchQuery: searchCriteria
        })
      );
    }
  } else if (logOrExceptionType?.toString().toLowerCase() === 'exception') {
    const globalExpSearchRedcuer = globalStore?.exceptionSearchReducer;
    if (globalExpSearchRedcuer?.exceptionTabList?.length !== SEARCH_TAB_MAX_LIMIT) {
      store.dispatch(
        addNewExceptionTab({
          label: `Search ${String(globalExpSearchRedcuer?.exceptionTabNextCount)}`,
          key: `Search ${String(globalExpSearchRedcuer?.exceptionTabNextCount)}`,
          closable: true,
          searchQuery: searchCriteria
        })
      );
    }
  }
};

export const removeSearchTabByType = (logOrExceptionType: string, keyToRemove: string): void => {
  if (logOrExceptionType?.toString().toLowerCase() === 'log') {
    store.dispatch(removeLogTab(keyToRemove));
  } else if (logOrExceptionType?.toString().toLowerCase() === 'exception') {
    store.dispatch(removeExceptionTab(keyToRemove));
  }
};

export const openSearchQueryInCurrentTab = (
  logOrExceptionType: string,
  searchCriteria: string | null = null
): void => {
  const globalStore = store.getState();
  if (logOrExceptionType?.toString().toLowerCase() === 'log') {
    store.dispatch(
      storeLogSearchQuery(globalStore?.logSearchReducer?.activeTab, searchCriteria, 0)
    );
  } else if (logOrExceptionType?.toString().toLowerCase() === 'exception') {
    store.dispatch(
      storeExceptionSearchQuery(globalStore?.exceptionSearchReducer?.activeTab, searchCriteria, 0)
    );
  }
};
