import styled, { css } from 'styled-components';

export const SearchPanelWrapper = styled.div<{ isFormInvalid?: boolean }>`
    display: flex;
    background: var(--white);
    box-shadow: 0px 0px 30px var(--black-50);
    border: 1px solid var(--gray-225);

    ${({ isFormInvalid }) =>
    isFormInvalid &&
    css`
        margin-bottom: 12px;
    `}

    .ant-form-item {
        margin-bottom: 0;
    }

    .ant-btn-primary.ant-btn-icon-only {
        height: auto;
    }

    .ant-select-single, .ant-select-multiple { 
        &:not(.ant-select-customize-input) {
            border-bottom: 1px solid transparent;

            .ant-select-selector {
                border-radius: 0;
                border-width: 0;
                border-color: var(--white);
                height: 100%;
            }

            &:hover {
                border-bottom: 1px solid var(--blue-color-150);
            }

            .ant-select-arrow {
                color: var(--black-100);
                font-size: var(--font-size-lg);
            }
        }
    }

    .ant-form-item-control-input .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 100%;
    }

    @media screen and (min-width: 1600px) {
        .ant-btn-primary.ant-btn-icon-only {
            min-width: 70px;
            width: 70px;
        }

        .ant-select-single .ant-select-selector .ant-select-selection-item {
            line-height: 55px;
        }

        .ant-form-item .ant-form-item-control-input {
            min-height: 55px;
            line-height: 55px;

            &-content {
                height: 100%;
            }
        }
    }
`;

export const ApplicationSelectionWrapper = styled.div`
    flex: 1;
    
    .react-dropdown-select {
        min-height: 100%;
        border-radius: 0;
        border-width: 0 0 1px 0;
        border-color: var(--white);

        &-dropdown-handle {
            order: -1;
            padding-left: 10px;
            padding-right: 10px;
            height: 20px;
        }

        &:hover {
            border-bottom: 1px solid var(--blue-color-150);
        }

        &-content {
            padding-top: 2px;
            font-size: var(--font-size-sm);
        }

        &-dropdown-position-bottom {
            top: 103%;
        }

        .ant-input {
            height: 100%;
        }

        .anticon-search {
            color: var(--blue-color-500);
            width: 17px;
        }
    }

    .ant-form-item-explain-error {
        position: absolute;
    }

    .ant-form-item-has-error {
        .react-dropdown-select {
            border-color: var(--form-field-error-color);
        }
    }
`;

export const DatePickerWrapper = styled.div`
    width: 100%;

    .ant-form-item, .ant-form-item-row, .ant-col, .ant-form-item-control-input, .ant-form-item-control-input-content, .ant-picker {
        height: 100%;
    }

    .ant-picker {
        border: none;
        height: 100%;
    }
`;

export const SearchByWrapper = styled.div`
    max-width: 170px;
    width: 100%;
    border-left: 1px solid var(--gray-250);
`;

export const DateTimePickerWrapper = styled.div<{ isFormInvalid?: boolean }>`
    border-left: 1px solid var(--gray-250);
    max-width: 220px;
    width: 100%;
    display: flex;
    border-bottom: 1px solid transparent;

    &:hover, &:focus {
        border-bottom: 1px solid var(--blue-color-150);
    }

    ${({ isFormInvalid }) =>
    isFormInvalid &&
    css`
        border-bottom: 1px solid var(--red-50);
    `}

    .ant-form-item .ant-form-item-control-input {
        min-height: 40px;

        .ant-form-item-control-input-content, .ant-picker-range {
            height: 100%;
        }
    }

    .ant-radio-group {
        display: flex;
        align-self: center;
        padding-left: 10px;

        .ant-radio-button-wrapper {
            padding-inline: 10px;
            color: var(--gray-400);

            &:first-child {
                border-right-color: var(--blue-color);
            }

            &:hover, &-checked:not(.ant-radio-button-wrapper-disabled) {
                color: var(--blue-color);
                overflow: hidden;
                border-color: var(--blue-color);
            }

            & > .ant-radio-button {
                &.ant-radio-button-checked {
                    background: var(--white-100);
                }
            }
        }
    }

    .ant-form-item-explain-error {
        position: absolute;
        top: 96%;
    }
`;

export const QuickTimePickerWrapper = styled.div`
    width: 100%;

    .ant-select-single:not(.ant-select-customize-input) {
        border-bottom: none;

        &:hover {
            border: none;
        }
    }
`;
