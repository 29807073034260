export const GROUP_LIST_COLUMNS = [
  {
    field: 'groupName',
    headerName: 'Group Name',
    sortable: true,
    flex: 'auto'
  },
  {
    field: 'applicationCount',
    headerName: 'No of Applications',
    sortable: true,
    flex: 'auto'
  },
  {
    field: 'userList',
    headerName: 'No of Users',
    sortable: true,
    cellRenderer: (params: any) => {
      return params?.value?.length;
    },
    comparator: (valueA: string[], valueB: string[]) => {
      return valueA.length - valueB.length;
    },
    flex: 'auto'
  },
  {
    field: 'groupDescription',
    headerName: 'Description',
    sortable: false,
    flex: 1
  }
];
