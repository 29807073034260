import React, { Fragment } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { DataLabelWrapper, DataValueWrapper, TabContentHeader, TabContentWrapper } from './style';
import { JMS_HEADER_COLUMNS, JMS_PROPERTIES_COLUMNS } from '../../../../constants/DetailViewColumns/JmsColumns';
const mainColSpan = 12;
const labelColSpan = 8;
const valueColSpan = 16;
const JmsTabContent: FC<{ data?: any, logExceptionType?: string }> = ({ data, logExceptionType }) => {
  const JSM_DATA = JSON.parse(data);
  return (
        <TabContentWrapper>
            <Row >
                <Col span={mainColSpan}>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <TabContentHeader>
                                JMS Header
                            </TabContentHeader>
                        </Col>
                        {JSM_DATA?.JMSHeader &&
                            JMS_HEADER_COLUMNS.map((columnInArray, i) => {
                              return (<Fragment key={`JMS_HEADER_COLUMNS-${i}`}>
                                    <Col span={labelColSpan}>
                                        <DataLabelWrapper>{columnInArray.headerName}</DataLabelWrapper></Col>
                                    <Col span={valueColSpan}>
                                        <DataValueWrapper>{JSM_DATA.JMSHeader[columnInArray.field]}</DataValueWrapper>
                                    </Col>
                                </Fragment>)
                            })
                        }
                    </Row>
                </Col>
                <Col span={mainColSpan}>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <TabContentHeader>JMS Properties</TabContentHeader>
                        </Col>
                        {JSM_DATA?.JMSProperties &&
                            JMS_PROPERTIES_COLUMNS.map((columnInArray, i) => {
                              return (<Fragment key={`JMS_PROPERTIES_COLUMNS-${i}`}>
                                    <Col span={labelColSpan}>
                                        <DataLabelWrapper>{columnInArray.headerName}</DataLabelWrapper>
                                    </Col>
                                    <Col span={valueColSpan}>
                                        <DataValueWrapper>{JSM_DATA.JMSProperties[columnInArray.field]}</DataValueWrapper>
                                    </Col>
                                </Fragment>)
                            })
                        }
                    </Row>
                </Col>
            </Row>
        </TabContentWrapper>
  );
};
JmsTabContent.propTypes = {
  data: PropTypes.any,
  logExceptionType: PropTypes.string
};
export default JmsTabContent;
