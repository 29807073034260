import { getSnowServiceClassName } from '../../../helpers/commonHelper';
import { checkBoxFieldCol } from '../tableCheckboxColumn';

const cellStyles = {
  textAlign: 'center'
};

const additionalColumnProp = {
  minWidth: 150,
  flex: 1,
  valueFormatter: (params: { value: any }) => {
    return params?.value ? params?.value?.toString().trim().length === 0 ? '-' : params.value : '-';
  }
};
export const COLUMN_ALERT_CONFIG_COLUMNS = [
  checkBoxFieldCol,
  {
    field: 'applicationName',
    headerName: 'Application',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'exceptionCategoryName',
    headerName: 'Category',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'exceptionTypeName',
    headerName: 'Type',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'exceptionSeverityName',
    headerName: 'Severity',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'columnName',
    headerName: 'Column Name',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'columnValue',
    headerName: 'Column Value',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'description',
    headerName: 'Description',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'emailAddress',
    headerName: 'Email',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'notificationGroup',
    headerName: 'Notification Group',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'enableMailNotification',
    headerName: 'Email Notification',
    cellStyle: cellStyles,
    sortable: true,
    cellRenderer: function (params: any) {
      return params.value === 1 ? 'Y' : 'N';
    },
    width: 150
  },
  {
    field: 'enableGroupNotification',
    headerName: 'Group Notification',
    cellStyle: cellStyles,
    sortable: true,
    cellRenderer: function (params: any) {
      return params.value === 1 ? 'Y' : 'N';
    },
    width: 150
  },
  {
    field: 'enableSnowNotification',
    headerName: 'Snow Notification',
    cellStyle: cellStyles,
    sortable: true,
    cellRenderer: function (params: any) {
      return params.value === 1 ? 'Y' : 'N';
    },
    width: 150
  },
  {
    field: 'sysClassName',
    headerName: 'Snow Service Class',
    sortable: true,
    ...additionalColumnProp,
    cellRenderer: function (params: any) {
      return getSnowServiceClassName(params.value);
    }
  },
  {
    field: 'snowParam1',
    headerName: 'Snow Service Value',
    sortable: true,
    ...additionalColumnProp
  }
];
