import { produce } from 'immer';
import { getUserDataWithActiveStatus, processOperation } from '../../../../helpers/reducerHelper';
import { SET_USERS_LIST } from '../../../actions/ActionTypes/AccessManagement/UserAccess/userAccessListTypes';

export interface userConfigListState {
  userConfigList: any[]
}

const initialState = {
  userConfigList: []
};

const userAccessReducer = (state = initialState, action: any): userConfigListState =>
  produce(state, (draft: userConfigListState) => {
    switch (action.type) {
      case SET_USERS_LIST:
        draft.userConfigList = getUserDataWithActiveStatus(processOperation(action.data, [...draft.userConfigList]));
        break;
    }
  });

export default userAccessReducer;
