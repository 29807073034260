export const checkBoxFieldCol = {
  field: '',
  headerName: '',
  width: 60,
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
  checkboxSelection: true,
  sortable: false,
  pinned: 'left',
  filter: false,
  suppressMenu: true,
  lockPosition: 'left',
  lockPinned: true
};
