import React, { Fragment } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { TabContentWrapper, TabContentHeader } from './style';
import CommonTableComponent from '../../../common/Table/CommonTable';
import { AUDIT_LOG_COLUMNS } from '../../../../constants/TableColumns/AuditLogsColumns';

const AuditLogTabContent: FC<{ data?: any }> = ({ data }) => {
  const auditgroups = _.groupBy(data, 'request_id');
  const groupByUserData = Object.entries(auditgroups).map(([, value]) =>
    _.groupBy(value, 'user_id')
  );

  return (
        <TabContentWrapper data-testid='test-id-audit-tab'>
            {
                groupByUserData.length > 0 &&
                groupByUserData.map((elementInArray, elementIter) => <Fragment key={'audit-log-item-wrapper-' + String(elementIter)}>
                    {
                        Object.keys(elementInArray).map(propertyInObject => {
                          return <Fragment key={'audit-log-content-wrapper-' + propertyInObject}>
                                <TabContentHeader className='text-align-left'>
                                    {
                                        propertyInObject.toString().toLowerCase() === 'system' &&
                                        <span>Automatic Replay</span>
                                    }
                                    {
                                        propertyInObject.toString().toLowerCase() !== 'system' &&
                                        <span>Request Replay triggered by ({propertyInObject})</span>
                                    }
                                </TabContentHeader>
                                <div>
                                    <CommonTableComponent
                                        rowData={elementInArray[propertyInObject]}
                                        columnDefs={AUDIT_LOG_COLUMNS}
                                        className={'primary-theme content-height'} />
                                </div>
                            </Fragment>
                        })
                    }
                </Fragment>)
            }

        </TabContentWrapper>
  );
};

AuditLogTabContent.propTypes = {
  data: PropTypes.any
};

export default AuditLogTabContent;
