import type {
  GetConfigDetailsActionType,
  SetConfigDetailsActionType
} from '../../../constants/Interfaces/AdminConfigActionTypes';
import {
  GET_SOLMAN_STAGING_CONFIG_LIST,
  SET_SOLMAN_STAGING_CONFIG_LIST
} from '../ActionTypes/Admin/solmanStagingConfigTypes';

export function getSolmanStagingConfigList (): GetConfigDetailsActionType {
  return {
    type: GET_SOLMAN_STAGING_CONFIG_LIST
  };
}

export function setSolmanStagingConfigList (data: any): SetConfigDetailsActionType {
  return {
    type: SET_SOLMAN_STAGING_CONFIG_LIST,
    data
  };
}
