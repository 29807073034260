import { produce } from 'immer';
import { SET_TABLE_CONFIG_LIST } from '../../../actions/ActionTypes/Admin/tableConfigTypes';
import { processOperation } from '../../../../helpers/reducerHelper';

export interface TableConfigState {
  tableConfigList: any[]
}

const initialState = {
  tableConfigList: []
};

const tableConfigReducer = (state = initialState, action: any): TableConfigState =>
  produce(state, (draft: TableConfigState) => {
    switch (action.type) {
      case SET_TABLE_CONFIG_LIST:
        draft.tableConfigList = processOperation(action.data, [...draft.tableConfigList]);
        break;
    }
  });

export default tableConfigReducer;
