import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { ApplicationTabContentMainWrapper } from '../../../../../assets/styles/commonStyled';
import ExceptionConfigList from '../../../DetailSummaryPage/Admin/ExceptionConfig/ExceptionConfigList';
import { APPLICATION_NAME_ID } from '../../../../../constants/KeyLabels/commonKeyConstants';

interface componentProps {
  applicationDetailData?: any
}

const ExceptionConfigTabContent: FC<componentProps> = ({ applicationDetailData }) => {
  return (
    <ApplicationTabContentMainWrapper data-testid="testid-application-detail-exception-config-tab">
      <ExceptionConfigList
        applicationName={applicationDetailData?.[APPLICATION_NAME_ID]}
        isFromApplicationConfig
      />
    </ApplicationTabContentMainWrapper>
  );
};

ExceptionConfigTabContent.propTypes = {
  applicationDetailData: PropTypes.any
};

export default ExceptionConfigTabContent;
