import styled from 'styled-components';

export const SaveCriteriaPopoverWrapper = styled.div`
    .ant-btn {
        text-transform: uppercase;
    }

    .ant-form label {
        font-size: var(--font-size-md);
    }

    textarea {
        resize: none;
    }
`;

export const SearchCriteriaPopoverTitle = styled.div`
    font-size: var(--popover-title-font-size);
    color: var(--popover-title-color);
    font-weight: var(--popover-title-font-weight);
`;

export const SearchCriteriaWrapper = styled.div`
    background: var(--white-100);
    padding: 10px 15px;
    margin: 10px 0;
    border-radius: 5px;
    font-size: var(--font-size-md);

    h3 {
        color: var(--gray-color);
        font-weight: var(--font-weight-regular);
        font-size: var(--font-size-md);
        margin-bottom: 5px;
    }

    p {
        margin: 0;
        font-size: var(--font-size-sm);
        color: var(--gray-800);
    }
`;

export const AppNameTag = styled.span`
    display: inline-block;
    padding: 1px 3px;
    background: var(--yellow-100);
    margin-right: 2px;
    border-radius: 4px;

    &:nth-child(2n - 1) {
        background: var(--blue-color-250);
        color: var(--blue-color-700);
    }

    &:nth-child(2n) {
        background: var(--voilet);
        color: var(--blue-color-750);
    }

    &:nth-child(3n) {
        color: var(--yellow-100);
        background: var(--yellow-300);
    }
`;

export const ValueTag = styled.span`
    font-weight: var(--font-weight-medium);
`;
