/**
 * Contains all available pages route & their route configuration
 */
import { LoginCallback } from '@okta/okta-react';
import routeConstants from '../constants/routeConstants';
import OktaAuthenticator from '../components/OktaAuthenticator';
import ApplicationConfig from '../pages/Admin/ApplicationConfig';
import SolmanStagingConfig from '../pages/Admin/SolmanStagingConfig';
import SolmanStagingConfigDetail from '../pages/Admin/SolmanStagingConfig/SolmanStagingConfigDetail';
import ScheduleConfig from '../pages/Admin/ScheduleConfig';
import ScheduleConfigDetail from '../pages/Admin/ScheduleConfig/ScheduleConfigDetail';
import ColumnAlertConfig from '../pages/Admin/ColumnAlertConfig';
import ExceptionConfig from '../pages/Admin/ExceptionConfig';
import ExceptionConfigDetail from '../pages/Admin/ExceptionConfig/ExceptionConfigDetail';
import ReplayConfig from '../pages/Admin/ReplayConfig';
import ReplayConfigDetail from '../pages/Admin/ReplayConfig/ReplayConfigDetail';
import TableConfig from '../pages/Admin/TableConfig';
import TableConfigDetail from '../pages/Admin/TableConfig/TableConfigDetail';
import FileReplay from '../pages/FileReplay';
import FileReplayDetail from '../pages/FileReplay/FileReplayDetail';
import Dashboard from '../pages/Dashboard';
import Unauthorized from '../components/common/Unauthorized';
import LogSearch from '../containers/Log';
import ExceptionSearch from '../containers/Exception';
import LogSavedSearch from '../pages/LogSavedSearch';
import ExceptionSavedSearch from '../pages/ExceptionSavedSearch';
import ApplicationDetail from '../pages/Admin/ApplicationConfig/ApplicationDetail/ApplicationDetail';
import ColumnAlertConfigDetail from '../pages/Admin/ColumnAlertConfig/ColumnAlertConfigDetail';
import ReportConfig from '../pages/Admin/ReportConfig';
import ReportConfigDetail from '../pages/Admin/ReportConfig/ReportConfigDetail';
import LogReportConfigDetail from '../pages/Admin/ReportConfig/LogReportConfigDetail';
import ExceptionReportConfigDetail from '../pages/Admin/ReportConfig/ExceptionReportConfigDetail';
import ChargebackCostAnalysis from '../pages/Admin/ChargebackCostAnalysis';
import UserAccessList from '../pages/AccessManagement/UserAccess';
import UserAccessDetail from '../pages/AccessManagement/UserAccess/UserAccessDetail';
import GroupAccessList from '../pages/AccessManagement/GroupAccess';
import GroupAccessDetail from '../pages/AccessManagement/GroupAccess/GroupAccessDetail';
import VolumeMetrics from '../pages/Admin/VolumeMetrics';
import BlobMetrics from '../pages/Admin/BlobMetrics';

const routes = [
  {
    component: OktaAuthenticator,
    isExact: true,
    isPrivate: false,
    path: routeConstants.OKTA_AUTHENTICATE,
    isAdminPath: false
  },
  {
    component: LoginCallback,
    isExact: true,
    isPrivate: false,
    path: routeConstants.LOGIN_CALLBACK,
    isAdminPath: false
  },
  {
    component: ApplicationConfig,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_APPLICATION_CONFIG,
    isAdminPath: false
  },
  {
    component: BlobMetrics,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_BLOB_METRICS,
    isAdminPath: true
  },
  {
    component: ChargebackCostAnalysis,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_COST_ANALYSIS,
    isAdminPath: true
  },
  {
    component: VolumeMetrics,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_LOG_EXCEPTION_VOLUME_METRICS,
    isAdminPath: true
  },
  {
    component: ScheduleConfig,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_SCHEDULE_CONFIG,
    isAdminPath: true
  },
  {
    component: ScheduleConfigDetail,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_SCHEDULE_CONFIG_DETAIL,
    isAdminPath: true
  },
  {
    component: SolmanStagingConfig,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_SOLMAN_STAGING,
    isAdminPath: true
  },
  {
    component: SolmanStagingConfigDetail,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_SOLMAN_CONFIG_DETAIL,
    isAdminPath: true
  },
  {
    component: ApplicationDetail,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_APPLICATION_DETAIL,
    isAdminPath: false
  },
  {
    component: ApplicationDetail,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_APPLICATION_NEW_CONFIG,
    isAdminPath: true
  },
  {
    component: ColumnAlertConfig,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_COLUMN_ALERTS,
    isAdminPath: true
  },
  {
    component: ColumnAlertConfigDetail,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_COLUMN_ALERTS_DETAIL,
    isAdminPath: false
  },
  {
    component: ReplayConfig,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_REPLAY_CONFIG,
    isAdminPath: true
  },
  {
    component: ReplayConfigDetail,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_REPLAY_CONFIG_DETAIL,
    isAdminPath: false
  },
  {
    component: ExceptionConfig,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_EXCEPTION_CONFIG,
    isAdminPath: true
  },
  {
    component: ExceptionConfigDetail,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_EXCEPTION_CONFIG_DETAIL,
    isAdminPath: false
  },
  {
    component: TableConfig,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_TABLE_CONFIG,
    isAdminPath: true
  },
  {
    component: TableConfigDetail,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_TABLE_CONFIG_DETAIL,
    isAdminPath: true
  },
  {
    component: ReportConfig,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_REPORT_CONFIG,
    isAdminPath: true
  },
  {
    component: ReportConfigDetail,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_REPORT_CONFIG_DETAIL,
    isAdminPath: true
  },
  {
    component: LogReportConfigDetail,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_LOG_REPORT_CONFIG_DETAIL,
    isAdminPath: true
  },
  {
    component: ExceptionReportConfigDetail,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_EXCEPTION_REPORT_CONFIG_DETAIL,
    isAdminPath: true
  },
  {
    component: Unauthorized,
    isExact: false,
    isPrivate: false,
    path: routeConstants.UNAUTHORIZED
  },
  {
    component: FileReplay,
    isExact: true,
    isPrivate: true,
    path: routeConstants.EXCEPTION_FILE_REPLAY
  },
  {
    component: FileReplayDetail,
    isExact: true,
    isPrivate: true,
    path: routeConstants.EXCEPTION_FILE_REPLAY_DETAIL
  },
  {
    component: Dashboard,
    isExact: true,
    isPrivate: true,
    path: routeConstants.DASHBOARD
  },
  {
    component: LogSearch,
    isExact: true,
    isPrivate: true,
    path: routeConstants.LOG_SEARCH
  },
  {
    component: ExceptionSearch,
    isExact: true,
    isPrivate: true,
    path: routeConstants.EXCEPTION_SEARCH
  },
  {
    component: ExceptionSavedSearch,
    isExact: true,
    isPrivate: true,
    path: routeConstants.EXCEPTION_SAVED_SEARCH
  },
  {
    component: LogSavedSearch,
    isExact: true,
    isPrivate: true,
    path: routeConstants.LOG_SAVED_SEARCH
  },
  // User Routes
  {
    component: UserAccessList,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_USER_ACCESS,
    isAdminPath: true
  },
  {
    component: UserAccessDetail,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_USER_ACCESS_DETAIL,
    isAdminPath: true
  },
  {
    component: GroupAccessList,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_GROUP_ACCESS,
    isAdminPath: true
  },
  {
    component: GroupAccessDetail,
    isExact: true,
    isPrivate: true,
    path: routeConstants.ADMIN_GROUP_ACCESS_DETAIL,
    isAdminPath: true
  },
  {
    component: OktaAuthenticator,
    isExact: false,
    isPrivate: false,
    path: '*'
  }
];

export default routes;
