const API_URL = 'cle-web-preprod.apps.pcf.dev.azure.intra.pepsico.com';

const BASE_URL = window.location.origin + '/';

const ADMIN_DATA_API_URL = window.location.origin + '/admin/data/api/v1/';

const EXCEPTION_DATA_API_URL = window.location.origin + '/exception/data/api/v1/';

const LOG_DATA_API_URL = window.location.origin + '/log/data/api/v1/';

const METRIC_DATA_API_URL = window.location.origin + '/metric/data/api/v1/';

const DOWNLOAD_DATA_API_URL = window.location.origin + '/export/api/v1';

const REPLAY_DATA_API_URL = window.location.origin + '/replay/data/api/v1/';

const USER_DATA_API_URL = window.location.origin + '/user/data/api/v1/';

export {
  API_URL,
  ADMIN_DATA_API_URL,
  BASE_URL,
  DOWNLOAD_DATA_API_URL,
  EXCEPTION_DATA_API_URL,
  LOG_DATA_API_URL,
  METRIC_DATA_API_URL,
  REPLAY_DATA_API_URL,
  USER_DATA_API_URL
};
