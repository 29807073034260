import React from 'react';
import type { FC } from 'react';
import { ExportOutlined } from '@ant-design/icons';
import { createNewSearchTabByType } from '../../../helpers/searchTabHelper';

interface componentProps {
  value?: any
  data?: any
}
const OpenSearchCriteriaButtonRenderer: FC<componentProps> = ({ ...params }) => {
  const data = params?.data
  const onOpenCriteria = (): void => {
    createNewSearchTabByType(data?.type ?? data?.searchType, data?.criteriaJson ?? data?.searchQuery)
  }
  return (
    <>
      <a onClick={() => { onOpenCriteria() }}><ExportOutlined /></a>
    </>
  );
};

export default OpenSearchCriteriaButtonRenderer;
