import React, { useState, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Input, Popover } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { checkIfAllObjectPropertyFalsy, checkIfAllObjectPropertyTruthy } from '../../../helpers/commonHelper';
import { mapValues, mergeWith, orderBy, uniq } from 'lodash';

import textConstants from '../../../constants/textConstants';
import {
  FilterButtonWrapper,
  FilterCategoryListWrapper,
  FilterCategorySearchWrapper,
  FilterCategorySelectAllWrapper,
  FilterColumnItemWrapper,
  FilterPopoverWrapper,
  FilterSingleColumWrapper
} from '../FilterPopover/style';

interface FilterByType {
  fieldName: string
  fieldLabel: string
}

interface SingleColumnFilterPopoverProps {
  appliedFilters: any
  dataList?: any[]
  filterBy: FilterByType
  children?: ReactNode
  handlePopoverVisibility: any
  isOpen: boolean
  isApplicationFilter?: boolean
  initialTableData: any[]
  onFilterApply: any
  tableData: any[]
  isFilterColDefaultChecked?: boolean
}

const SingleColumnFilterPopover: FC<SingleColumnFilterPopoverProps> = ({
  appliedFilters,
  children,
  dataList,
  filterBy,
  handlePopoverVisibility,
  isOpen,
  initialTableData,
  isApplicationFilter,
  isFilterColDefaultChecked,
  onFilterApply,
  tableData
}) => {
  const [isAllItemSelected, setAllItemSelectedStatus] = useState<boolean>(
    isFilterColDefaultChecked ?? false
  );
  const [columnItemConfig, setColumnItemConfig] = useState<any>([]);
  const [initialColumnItemConfig, setInitialColumnItemConfig] = useState<any>({});
  const [tableRowData, setTableRowData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const onCategorySearchChange = (value: string): void => {
    setSearchValue(value);
  };

  const getSortedColumnItemConfig = (columnItems: any): any => {
    return Object.entries(columnItems).sort((a: any, b: any) => b[1] - a[1]).reduce((obj, [key, value]) => ({
      ...obj, [key]: value
    }), {});
  }

  const filterTableData = (selectedItem: any): any[] => {
    const filteredTableData = initialTableData.filter(
      (item) => {
        if (item[filterBy.fieldName].constructor === Array) {
          return item[filterBy.fieldName].some((item: any) => {
            return item in selectedItem && selectedItem[item]
          });
        }

        return item[filterBy.fieldName] in selectedItem && selectedItem[item[filterBy.fieldName]]
      }
    );

    return filteredTableData;
  };

  const onColumnItemChangeSelection = (
    evt: CheckboxChangeEvent,
    isSelectAllToggle?: boolean
  ): void => {
    const { id, checked } = evt.target;
    let updatedColumnItem = { ...columnItemConfig };

    if (isSelectAllToggle) {
      Object.keys(updatedColumnItem).forEach((val: any) => {
        updatedColumnItem[val] = !isAllItemSelected;
      });
      setAllItemSelectedStatus(!isAllItemSelected);
    } else {
      updatedColumnItem = {
        ...columnItemConfig,
        ...(id && { [id]: checked })
      };
      let isAllSelected = checkIfAllObjectPropertyTruthy(updatedColumnItem);

      if (isApplicationFilter) {
        isAllSelected = dataList?.length === Object.keys(updatedColumnItem).length ? isAllSelected : false;
      }

      setAllItemSelectedStatus(isAllSelected);
    }
    let data = filterTableData(updatedColumnItem);

    if (isApplicationFilter && checkIfAllObjectPropertyFalsy(updatedColumnItem)) {
      data = initialTableData;
    }

    setTableRowData(data);
    setColumnItemConfig(updatedColumnItem);
  };

  /* Method to reset applied filters */
  const onReset = (): void => {
    const updatedColumnItem = mapValues(columnItemConfig, () => false);
    setColumnItemConfig(updatedColumnItem);
    setTableRowData(initialTableData);
    setAllItemSelectedStatus(false);
    onFilterApply(initialTableData, {});
  }

  /* Method to apply selected filters */
  const onApply = (): void => {
    let ignoreIfAllUnchecked = false;

    if (isApplicationFilter) {
      ignoreIfAllUnchecked = Object.values(columnItemConfig).every((v) => v === false);
    }

    onFilterApply(tableRowData, ignoreIfAllUnchecked ? {} : columnItemConfig);
    handlePopoverVisibility(false);
  };

  useEffect(() => {
    const categoryItemData = uniq(
      initialTableData?.filter((row: any) => filterBy.fieldName in row && row[filterBy.fieldName])
    );

    const sortedItemData = orderBy(
      categoryItemData,
      [(column: any) => column[filterBy.fieldName].toString().toLowerCase()],
      'asc'
    ).map((row: any) => {
      return {
        [row[filterBy.fieldName]]: Object.keys(appliedFilters).length > 0 ? appliedFilters[filterBy.fieldName] : isFilterColDefaultChecked
      };
    });

    const itemObject = Object.assign({}, ...sortedItemData);

    setInitialColumnItemConfig(itemObject);

    if (!isOpen && Object.keys(appliedFilters).length > 0) {
      const isAllItemSelected = Object.values(appliedFilters).every((v) => v === true);
      setAllItemSelectedStatus(isAllItemSelected);

      const filteredData = filterTableData(appliedFilters);
      setTimeout(() => {
        onFilterApply(filteredData, appliedFilters);
      }, 500)
    }
  }, [initialTableData])

  useEffect(() => {
    if (isOpen) {
      const tableDataList = isApplicationFilter ? dataList : initialTableData;
      const categoryItemData = uniq(
        tableDataList?.filter((row: any) => filterBy.fieldName in row && row[filterBy.fieldName])
      );

      const sortedItemData = orderBy(
        categoryItemData,
        [(column: any) => column[filterBy.fieldName].toString().toLowerCase()],
        'asc'
      ).map((row: any) => {
        return {
          [row[filterBy.fieldName]]: Object.keys(appliedFilters).length > 0 ? appliedFilters[filterBy.fieldName] : isFilterColDefaultChecked
        };
      });

      const itemObject = mergeWith({}, ...sortedItemData, appliedFilters || {}, (a: any, b: any) => b || false);

      setColumnItemConfig(getSortedColumnItemConfig(itemObject));
      setTableRowData(tableData);
    }
  }, [tableData, isOpen, appliedFilters]);

  useEffect(() => {
    if (Object.keys(appliedFilters).length > 0) {
      const mergedObj = mergeWith({}, initialColumnItemConfig, appliedFilters, (a, b) => b || false);
      setColumnItemConfig(getSortedColumnItemConfig(mergedObj));
    }
  }, [appliedFilters, tableData]);

  useEffect(() => {
    if (!isOpen && isApplicationFilter) {
      onCategorySearchChange('');
      setColumnItemConfig({});
    }
  }, [isOpen]);

  const renderColumnItems = (): any => {
    return columnItemConfig && Object.entries(columnItemConfig).filter(([field]: any) => field.toLowerCase().includes(searchValue?.toLowerCase())).map(([fieldKey, value]: any) => {
      return (
        <FilterColumnItemWrapper key={`${fieldKey}-${value}`}>
          <Checkbox
            data-testid={fieldKey}
            id={fieldKey}
            checked={value}
            onChange={onColumnItemChangeSelection}
          >
            {fieldKey}
          </Checkbox>
        </FilterColumnItemWrapper>
      );
    });
  };

  return (
    <Popover
      open={isOpen}
      onOpenChange={handlePopoverVisibility}
      placement="bottomRight"
      trigger="click"
      content={
        <FilterPopoverWrapper data-testid="filterContentWrapper">
          <h1>
            {textConstants.FILTER_DATA_BY_TITLE} {filterBy.fieldLabel}
          </h1>
          <FilterCategoryListWrapper>
            <FilterCategorySelectAllWrapper>
              <Checkbox
                data-testid="selectAll"
                id="selectAll"
                checked={isAllItemSelected}
                onChange={(evt: CheckboxChangeEvent) => onColumnItemChangeSelection(evt, true)}
              >
                {textConstants.FILTER_DATA_SELECT_ALL_LABEL}
              </Checkbox>
            </FilterCategorySelectAllWrapper>
            {dataList && dataList.length > 0 && (
              <FilterCategorySearchWrapper>
                <Input
                  name={filterBy.fieldName}
                  data-testid={filterBy.fieldName}
                  placeholder={`Search ${filterBy.fieldLabel}`}
                  className={isFilterColDefaultChecked ? '' : 'pr-15'}
                  addonBefore={<SearchOutlined />}
                  onChange={(evt) => onCategorySearchChange(evt.target.value)}
                  allowClear
                />
              </FilterCategorySearchWrapper>
            )}
            <FilterSingleColumWrapper className="category-data-wrapper">
              {renderColumnItems()}
            </FilterSingleColumWrapper>
          </FilterCategoryListWrapper>
          <FilterButtonWrapper className={`text-right ${isApplicationFilter ? 'single-col-btn-wrapper' : ''}`}>
            {isApplicationFilter && (
              <Button
                type="text"
                className="mr-10 btn-regular"
                onClick={onReset}
                data-testid="resetFilterBtn"
              >
                {textConstants.RESET_BUTTON_LABEL}
              </Button>
            )}
            <Button
              className="btn-regular"
              type="primary"
              onClick={onApply}
              data-testid="applyFilterBtn"
            >
              {textConstants.APPLY_BUTTON_LABEL}
            </Button>
          </FilterButtonWrapper>
        </FilterPopoverWrapper>
      }
      destroyTooltipOnHide
      rootClassName={`filter-popover single-filter-column-popover ${
        isFilterColDefaultChecked ? '' : 'popover-lg'
      }`}
    >
      {children}
    </Popover>
  );
};

SingleColumnFilterPopover.defaultProps = {
  isApplicationFilter: false
};

SingleColumnFilterPopover.propTypes = {
  appliedFilters: PropTypes.object.isRequired,
  children: PropTypes.node,
  dataList: PropTypes.array,
  filterBy: PropTypes.shape({
    fieldName: PropTypes.string.isRequired,
    fieldLabel: PropTypes.string.isRequired
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  isApplicationFilter: PropTypes.bool,
  isFilterColDefaultChecked: PropTypes.bool,
  initialTableData: PropTypes.array.isRequired,
  handlePopoverVisibility: PropTypes.func.isRequired,
  onFilterApply: PropTypes.func.isRequired,
  tableData: PropTypes.array.isRequired
};

export default SingleColumnFilterPopover;
