import styled, { css } from 'styled-components';

export const HeaderStyle = css`
  font-weight: 600;
  margin-bottom: 5px;
`;

export const FlexSpaceBetween = css`
  display: flex;
  justify-content: space-between;
`;

export const CalendarMainWrapper = styled.div`
  max-width: 25vw;
  padding: 5px;
`;

export const CalendarHeaderWrapper = styled.div`
  border-bottom: 1px solid var(--default-border-color);
  margin-bottom: 5px;
  > *{
    margin:5px;
  }
  .ant-form-inline {
    justify-content: space-between;
  }
  .ant-form-item{
    margin-inline-end: 0px;
    width: calc(50% - 5px);
  }
`;

export const CalendarCenterContentWrapper = styled.div`
  ${FlexSpaceBetween}
  margin-bottom: 5px;
  max-height: 80%
`;

export const CalendarCenterMainWrapper = styled.div`
  .ant-picker-panel {
    border: 0px;
  }
  .ant-picker-calendar .ant-picker-calendar-header .ant-radio-group{
    display:none;
  }
`;
export const CalendarCenterTimePickerPanelWrapper = styled.div`
  ${FlexSpaceBetween}
  min-width: 30%;
  border-left: 1px solid var(--default-border-color);
  flex-direction: column;
  padding: 10px;
`;

export const CalendarTimePickerFooterWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

export const CalendarFooterWrapper = styled.div`
  border-top: 1px solid var(--default-border-color);
`;

export const CalendarFooterHeaderWrapper = styled.div`
  ${HeaderStyle}
  margin-top: 5px;
`;
export const CalendarFooterContentWrapper = styled.div``;

export const RecentUsedCalendarWrapper = styled.div``;

export const RecentUsedItem = styled.div`
  margin-bottom: 5px;
  color: var(--blue-color-800);
  font-size:var(--font-size-sm);
`;

export const QuickPickerMainWrapper = styled.div`
  padding: 5px;
  width: 20vw;
`;
export const QuickSelectHeaderWrapper = styled.div`
  ${HeaderStyle}
`;
export const QuickSelectContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  > * {
    margin-right: 5px;
  }
`;

export const QuickPickerCommonUsedWrapper = styled.div`
  margin-top: 10px;
`;

export const CalendarItemHeaderWrapper = styled.div`
  ${HeaderStyle}
  margin: 5px 0px;
`;

export const CommonUsedItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-height: 25vh;
  overflow: auto;
`;

export const CommonUsedItem = styled.div`
  width: 45%;
  margin-bottom: 5px;
  color: var(--blue-color-800);
  font-size:var(--font-size-sm);
`;
