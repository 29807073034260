import type {
  ChartType,
  DashboardMetricData,
  DashboardReplayRequestData,
  DashboardRequestData,
  GetDashboardDataAction,
  GetReplayMetricDataAction,
  SetDashboardDataAction
} from '../../../constants/Interfaces/DashboardTypes';
import {
  GET_DASHBOARD_LOG_METRIC_DATA,
  GET_DASHBOARD_EXCEPTION_METRIC_DATA,
  SET_DASHBOARD_METRIC_DATA,
  GET_DASHBOARD_REPLAY_METRIC_DATA
} from '../ActionTypes/Dashboard/dashboardTypes';

export function getDashboardLogMetricData (data: DashboardRequestData): GetDashboardDataAction {
  return {
    type: GET_DASHBOARD_LOG_METRIC_DATA,
    payload: {
      data
    }
  };
}

export function getDashboardExceptionMetricData (data: DashboardRequestData): GetDashboardDataAction {
  return {
    type: GET_DASHBOARD_EXCEPTION_METRIC_DATA,
    payload: {
      data
    }
  };
}

export function getDashboardReplayMetricData (data: DashboardReplayRequestData): GetReplayMetricDataAction {
  return {
    type: GET_DASHBOARD_REPLAY_METRIC_DATA,
    payload: {
      data
    }
  };
}

export function setDashboardMetricData (data: DashboardMetricData, chartType: ChartType): SetDashboardDataAction {
  return {
    type: SET_DASHBOARD_METRIC_DATA,
    chartType,
    data
  };
}
