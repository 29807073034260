import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import {
  exceptionReportConfigFieldlabels
} from '../../../../../constants/FormFields/AdminForm/exceptionReportConfigFormFields';
import { LabelValueWrapper, LabelWrapper } from '../../../../../assets/styles/commonStyled';
import { SummaryViewRow } from '../style';

interface ConfigSummaryProps {
  data?: any
}
const columnSpan = {
  xs: 24,
  md: 12,
  xxl: 8
}
const ExceptionReportConfigSummary: FC<ConfigSummaryProps> = ({ data }) => {
  return (
    <>
      <SummaryViewRow
        gutter={[16, 8]}
        className='mb-15'
      >
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionReportConfigFieldlabels.configID}</LabelWrapper>
          <LabelValueWrapper>{data?.configID}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionReportConfigFieldlabels.applicationName}</LabelWrapper>
          <LabelValueWrapper>{data?.applicationName.join(', ')}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionReportConfigFieldlabels.mailID}</LabelWrapper>
          <LabelValueWrapper>{data?.mailID}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionReportConfigFieldlabels.metricType}</LabelWrapper>
          <LabelValueWrapper>{data?.metricType}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionReportConfigFieldlabels.reportType}</LabelWrapper>
          <LabelValueWrapper>{data?.reportType ?? 'Daily'}</LabelValueWrapper>
        </Col>
        {data?.reportType === 'Hourly' && (
          <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
            <LabelWrapper>{exceptionReportConfigFieldlabels.hours}</LabelWrapper>
            <LabelValueWrapper>{data?.hour}</LabelValueWrapper>
          </Col>
        )}
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionReportConfigFieldlabels.enableNotification}</LabelWrapper>
          <LabelValueWrapper>{data?.enableNotification ? 'Yes' : 'No'}</LabelValueWrapper>
        </Col>
      </SummaryViewRow>
    </>
  );
};

ExceptionReportConfigSummary.propTypes = {
  data: PropTypes.object
};

export default ExceptionReportConfigSummary;
