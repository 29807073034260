import styled from 'styled-components';
import { Collapse } from 'antd';

export const FilterCategoryContainer = styled(Collapse)`
  max-height: 50.5vh;
  overflow-y: auto;

  &.ant-collapse {
    border: none;
    background: var(--white);
    overflow-x: hidden;
    margin-right: -12px;
    padding-right: 17px;
    border-radius: 0px;
    
    > .ant-collapse-item {
      background: var(--white);
      border: none;
      margin-right: 7px;
      > .ant-collapse-header {
        padding: 0;

        .ant-collapse-expand-icon {
          position: absolute;
          right: 0;
          padding-right: 0;
          color: var(--blue-color);
          top: 4px;
        }
      }
    }

    .ant-collapse-content {
      border: none;

      > .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
`;

export const FilterCategoryListWrapper = styled.div`
  .ant-btn {
    position: absolute;
    right: 20px;
    top: 50%;
    text-transform: initial;
    height: auto;
    font-size: var(--font-size-sm);
  }
`;

export const FilterCategoryTitle = styled.h4`
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  color: var(--filter-popover-category-title-text-color);
  border-bottom: 1px dashed var(--filter-popover-category-title-border-color);
  padding-bottom: 7px;
  padding-top: 7px;
  margin-bottom: 0;
`;

export const FilterCategoryItemWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 3px;
  max-height: 190px;
  overflow-y: auto;
`;

export const FilterCategorySearchWrapper = styled.div`
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;

  .ant-btn.ant-btn-link {
    height: auto;
    text-transform: initial;
    position: absolute;
    right: 8px;
    transform: translateY(-50%);
    top: 50%;
    z-index: 99;
    padding: 0;
  }

  .ant-input-group {
    .ant-input-affix-wrapper {
      padding-left: 27px;
      padding-right: 75px;
      height: 35px;
      border-radius: 3px;

      .ant-input {
        height: 100%;
        font-size: var(--font-size-sm);
        color: var(--gray-color);
      }
    }

    &-wrapper.pr-15 {
      .ant-input-affix-wrapper {
        padding-right: 15px;
        height: 30px;
      }
    }

    .ant-input-group-addon {
      background: transparent;
      border: none;
      position: absolute;
      z-index: 99;
      top: 50%;
      left: -2px;
      transform: translateY(-50%);
      color: var(--gray-225);

      .anticon-search {
        font-size: var(--font-size-lg);
      }
    }
  }
`;

export const FilterPopoverWrapper = styled.div`
  padding: 0;

  h1 {
    font-size: var(--popover-title-font-size);
    font-weight: var(--popover-title-font-weight);
    color: var(--popover-title-color);
    margin-bottom: 5px;
  }

  .ant-checkbox-wrapper {
    color: var(--gray-800);
    font-size: var(--font-size-sm);
    margin-bottom: 7px;
    flex: 0 0 50%;
    max-width: 50%;
    white-space: pre-wrap;
    word-break: break-word;

    .ant-checkbox {
      align-self: start;
      margin-top: 1px;
    }
  }

  .ant-btn {
    text-transform: uppercase;
  }
`;

export const FilterButtonWrapper = styled.div`
  margin-top: 10px;

  &.single-col-btn-wrapper {
    margin-top: 0;
    padding-top: 10px;
    border-top: 1px dashed var(--filter-popover-category-title-border-color);
  }
`;

export const FilterColumnItemWrapper = styled.div`
  word-break: break-word;
  padding-right: 5px;
`;

export const FilterSingleColumWrapper = styled.div`
  max-height: 22vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: -12px;

  &.height-lg {
    max-height: 36.7vh;
  }

  .ant-checkbox-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 5px;

    .ant-checkbox + span {
      padding-top: 2px;
    }
  }
`;

export const FilterCategorySelectAllWrapper = styled.div`
  border-bottom: 1px dashed var(--gray-300);
  margin-bottom: 5px;

  .ant-checkbox + span {
    padding-top: 2px;
  }
`;
