import { APPLICATION_NAME_KEY, APPLICATION_DOMAIN_KEY, COMPONENT_NAME_KEY, BUSINESS_ID_KEY, BUSINESS_ID_TWO_KEY, SERVICE_NAME_KEY, TRANSACTION_ID_KEY, TRANSACTION_TYPE_KEY, HOST_NAME_KEY, TRANSACTION_DOMAIN_KEY, STATUS_KEY, LOG_LEVEL_KEY, TIME_DURATION_KEY, TIMEMARK_KEY } from '../KeyLabels/commonKeyConstants';
import { APPLICATION_NAME_LABEL, APPLICATION_DOMAIN_LABEL, COMPONENT_NAME_LABEL, BUSINESS_ID_LABEL, BUSINESS_ID_TWO_LABEL, SERVICE_NAME_LABEL, TRANSACTION_ID_LABEL, TRANSACTION_TYPE_LABEL, HOST_NAME_LABEL, TRANSACTION_DOMAIN_LABEL, STATUS_LABEL, LOG_LEVEL_LABEL, TIME_DURATION_LABEL, TIMEMARK_LABEL } from '../KeyLabels/labelConstants';

export const LogDetailColumn = [
  { label: APPLICATION_NAME_LABEL, key: APPLICATION_NAME_KEY },
  { label: APPLICATION_DOMAIN_LABEL, key: APPLICATION_DOMAIN_KEY },
  { label: COMPONENT_NAME_LABEL, key: COMPONENT_NAME_KEY },
  { label: BUSINESS_ID_LABEL, key: BUSINESS_ID_KEY },
  { label: BUSINESS_ID_TWO_LABEL, key: BUSINESS_ID_TWO_KEY },
  { label: SERVICE_NAME_LABEL, key: SERVICE_NAME_KEY },
  { label: TRANSACTION_ID_LABEL, key: TRANSACTION_ID_KEY },
  { label: TRANSACTION_TYPE_LABEL, key: TRANSACTION_TYPE_KEY },
  { label: HOST_NAME_LABEL, key: HOST_NAME_KEY },
  { label: TRANSACTION_DOMAIN_LABEL, key: TRANSACTION_DOMAIN_KEY },
  { label: STATUS_LABEL, key: STATUS_KEY },
  { label: LOG_LEVEL_LABEL, key: LOG_LEVEL_KEY },
  { label: TIME_DURATION_LABEL, key: TIME_DURATION_KEY },
  { label: TIMEMARK_LABEL, key: TIMEMARK_KEY }
];
