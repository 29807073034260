import type {
  DeleteConfigDataActionType,
  GetConfigDetailsActionType,
  SetConfigDetailsActionType
} from '../../../../constants/Interfaces/AdminConfigActionTypes';
import {
  GET_GROUP_APPLICATION_ACCESS_LIST,
  GET_GROUP_LIST,
  SET_GROUP_APPLICATION_ACCESS_LIST,
  SET_GROUP_LIST,
  SAVE_GROUP_CONFIG,
  SAVE_GROUP_APPLICATION_ACCESS,
  DELETE_GROUP_APPLICATION_ACCESS
} from '../../ActionTypes/AccessManagement/GroupAccess/groupAccessTypes';

interface SaveConfigDetailAction {
  type: string
  payload: {
    data: any[]
    callBackFunc?: any
    isAdd?: boolean
  }
}

export function getGroupList (): GetConfigDetailsActionType {
  return {
    type: GET_GROUP_LIST
  };
}

export function setGroupList (data: any): SetConfigDetailsActionType {
  return {
    type: SET_GROUP_LIST,
    data
  };
}

export function saveGroupConfig (data: any, callBackFunc?: any, isAdd?: boolean): SaveConfigDetailAction {
  return {
    type: SAVE_GROUP_CONFIG,
    payload: {
      data,
      callBackFunc,
      isAdd
    }
  };
}

export function getGroupApplicationAccessList (): GetConfigDetailsActionType {
  return {
    type: GET_GROUP_APPLICATION_ACCESS_LIST
  };
}

export function setGroupApplicationAccessList (data: any): SetConfigDetailsActionType {
  return {
    type: SET_GROUP_APPLICATION_ACCESS_LIST,
    data
  };
}

export function saveGroupApplicationAccess (data: any, callBackFunc?: any, isAdd?: boolean): SaveConfigDetailAction {
  return {
    type: SAVE_GROUP_APPLICATION_ACCESS,
    payload: {
      data,
      callBackFunc,
      isAdd
    }
  };
}

export function deleteGroupApplicationAccess (data: any, callBackFunc?: any): DeleteConfigDataActionType {
  return {
    type: DELETE_GROUP_APPLICATION_ACCESS,
    payload: {
      data,
      callBackFunc
    }
  };
}
