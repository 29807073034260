import { produce } from 'immer';
import { SET_DASHBOARD_METRIC_DATA } from '../../actions/ActionTypes/Dashboard/dashboardTypes';
import type { DashboardMetricData, SetDashboardDataAction } from '../../../constants/Interfaces/DashboardTypes';

export interface DashboardState {
  logs: DashboardMetricData
  exceptions: DashboardMetricData
  replays: DashboardMetricData | null
}

const initialState = {
  logs: {},
  exceptions: {},
  replays: null
};

const dashboardReducer = (state = initialState, action: SetDashboardDataAction): DashboardState =>
  produce(state, (draft: DashboardState) => {
    switch (action.type) {
      case SET_DASHBOARD_METRIC_DATA:
        draft[action.chartType] = action.data;
        break;
    }
  });

export default dashboardReducer;
