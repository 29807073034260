import React from 'react';
import type { FC } from 'react';
import { BlobMetricTypeTag } from '../../../assets/styles/commonStyled';

interface BlobMetricTypeTagRendererProps {
  value?: any
}

const BlobMetricTypeTagRenderer: FC<BlobMetricTypeTagRendererProps> = ({ ...params }) => {
  const metricType = params?.value?.toString();

  return <BlobMetricTypeTag type={metricType}>{metricType}</BlobMetricTypeTag>;
};

export default BlobMetricTypeTagRenderer;
