import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import textConstants from '../../../../../constants/textConstants';
import { findLabelByValue, getLabelValueFormatter } from '../../../../../helpers/commonHelper';
import { exceptionConfigFieldLabels } from '../../../../../constants/FormFields/AdminForm/exceptionConfigFormFields';
import { LabelValueWrapper, LabelWrapper } from '../../../../../assets/styles/commonStyled';
import { SummaryViewRow } from '../style';
import { snowServiceNameOptions } from '../../../../../constants/DropDownOptions/adminConfig';

interface ConfigSummaryProps {
  data?: any
}

const columnSpan = {
  xs: 24,
  md: 6,
  xxl: 5
}

const ExceptionConfigSummary: FC<ConfigSummaryProps> = ({ data }) => {
  return (
    <>
      <SummaryViewRow
        gutter={[16, 8]}
        className='mb-15'
      >
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionConfigFieldLabels.exceptionConfigId}</LabelWrapper>
          <LabelValueWrapper>{data?.exceptionConfigId}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionConfigFieldLabels.applicationName}</LabelWrapper>
          <LabelValueWrapper>{data?.applicationName}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionConfigFieldLabels.applicationDomain}</LabelWrapper>
          <LabelValueWrapper>{getLabelValueFormatter(data?.applicationDomain)}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionConfigFieldLabels.description}</LabelWrapper>
          <LabelValueWrapper>{getLabelValueFormatter(data?.description)}</LabelValueWrapper>
        </Col>
      </SummaryViewRow>
      <SummaryViewRow
        gutter={[16, 8]}
        data-title={textConstants.EXCEPTION_CONFIG_DETAIL_IF_CRITERIA_LABEL}
        className="criteria-separator-wrapper"
      >
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionConfigFieldLabels.exceptionCategoryName}</LabelWrapper>
          <LabelValueWrapper>{getLabelValueFormatter(data?.exceptionCategoryName)}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionConfigFieldLabels.exceptionTypeName}</LabelWrapper>
          <LabelValueWrapper>{getLabelValueFormatter(data?.exceptionTypeName)}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionConfigFieldLabels.exceptionSeverityName}</LabelWrapper>
          <LabelValueWrapper>{getLabelValueFormatter(data?.exceptionSeverityName)}</LabelValueWrapper>
        </Col>
      </SummaryViewRow>
      <SummaryViewRow
        gutter={[16, 8]}
        data-title={textConstants.EXCEPTION_CONFIG_DETAIL_THEN_CRITERIA_LABEL}
        className="criteria-separator-wrapper-without-border"
      >
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionConfigFieldLabels.replayType}</LabelWrapper>
          <LabelValueWrapper>{getLabelValueFormatter(data?.replayType)}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionConfigFieldLabels.replyMode}</LabelWrapper>
          <LabelValueWrapper>{getLabelValueFormatter(data?.replyMode)}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionConfigFieldLabels.maxAutoReplay}</LabelWrapper>
          <LabelValueWrapper>{getLabelValueFormatter(data?.maxAutoReplay)}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionConfigFieldLabels.autoReplayInterval}</LabelWrapper>
          <LabelValueWrapper>{getLabelValueFormatter(data?.autoReplayInterval)}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={8}>
          <LabelWrapper>{exceptionConfigFieldLabels.replyDestination}</LabelWrapper>
          <LabelValueWrapper>{getLabelValueFormatter(data?.replyDestination)}</LabelValueWrapper>
        </Col>
      </SummaryViewRow>
      <SummaryViewRow
        gutter={[16, 8]}
        data-title={textConstants.EXCEPTION_CONFIG_DETAIL_NOTIFICATION_CRITERIA_LABEL}
        className="criteria-separator-wrapper-without-border"
      >
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionConfigFieldLabels.enableMailNotification}</LabelWrapper>
          <LabelValueWrapper>{getLabelValueFormatter(data?.enableMailNotification, 'radio')}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionConfigFieldLabels.enableGroupNotification}</LabelWrapper>
          <LabelValueWrapper>{getLabelValueFormatter(data?.enableGroupNotification, 'radio')}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={8} xxl={14}>
          <LabelWrapper>{exceptionConfigFieldLabels.enableSnowNotification}</LabelWrapper>
          <LabelValueWrapper>{getLabelValueFormatter(data?.enableSnowNotification, 'radio')}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionConfigFieldLabels.emailAddress}</LabelWrapper>
          <LabelValueWrapper>{getLabelValueFormatter(data?.emailAddress)}</LabelValueWrapper>
        </Col>
        <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
          <LabelWrapper>{exceptionConfigFieldLabels.snowAssignmentGroup}</LabelWrapper>
          <LabelValueWrapper>{getLabelValueFormatter(data?.snowAssignmentGroup)}</LabelValueWrapper>
        </Col>
        {data?.enableSnowNotification === 1 && (
          <>
            <Col xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
              <LabelWrapper>{exceptionConfigFieldLabels.sysClassName}</LabelWrapper>
              <LabelValueWrapper>{findLabelByValue(snowServiceNameOptions, data?.sysClassName)}</LabelValueWrapper>
            </Col>
            <Col xs={columnSpan.xs} md={columnSpan.md} xxl={9}>
              <LabelWrapper>{exceptionConfigFieldLabels.snowParam}</LabelWrapper>
              <LabelValueWrapper>{getLabelValueFormatter(data?.snowParam)}</LabelValueWrapper>
            </Col>
          </>
        )}
      </SummaryViewRow>
    </>
  );
};

ExceptionConfigSummary.propTypes = {
  data: PropTypes.object
};

export default ExceptionConfigSummary;
