import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { ApplicationTabContentMainWrapper } from '../../../../../assets/styles/commonStyled';
import { APPLICATION_NAME_ID } from '../../../../../constants/KeyLabels/commonKeyConstants';
import ReplayConfigList from '../../../DetailSummaryPage/Admin/ReplayConfig/ReplayConfigList';

interface componentProps {
  applicationDetailData?: any
}

const ReplayConfigTabContent: FC<componentProps> = ({ applicationDetailData }) => {
  return (
    <ApplicationTabContentMainWrapper data-testid="testid-application-detail-replay-config-tab">
      <ReplayConfigList
        applicationName={applicationDetailData?.[APPLICATION_NAME_ID]}
        isFromApplicationConfig
      />
    </ApplicationTabContentMainWrapper>
  );
};

ReplayConfigTabContent.propTypes = {
  applicationDetailData: PropTypes.object
};

export default ReplayConfigTabContent;
