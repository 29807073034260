import React from 'react';
import type { FC } from 'react';
import { FileReplayStatusTag } from '../../../assets/styles/commonStyled';

interface componentProps {
  value?: any
}

const ReplayStatusTagRenderer: FC<componentProps> = ({ ...params }) => {
  const status = params?.value?.toString().toLowerCase();

  return <FileReplayStatusTag className={`${status} status-grid-btn`}>{status}</FileReplayStatusTag>;
};

export default ReplayStatusTagRenderer;
