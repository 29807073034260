import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DatePicker as AntdDatePicker } from 'antd';
import { dateTimeFormat } from '../../../constants/commonConstants';
import { RangePickerAdditionalFooter, RangePickerAdditionalFooterHeader } from './style';

interface DatePickerProps {
  value?: any
  showBorder?: boolean
  setDate?: any
  closePanel?: any
  openPanel?: boolean
  pickerRef?: any
  formRef?: any
}

const { RangePicker } = AntdDatePicker;

const AntdRangePicker: FC<DatePickerProps> = ({
  showBorder,
  setDate,
  closePanel,
  openPanel,
  pickerRef,
  formRef,
  value
}) => {
  const [focusType, setFocusType] = useState<string>('start');
  const [selectedDate, setSelectedDate] = useState<any>([dayjs(formRef.getFieldValue('dateTime')?.[0]), dayjs(formRef.getFieldValue('dateTime')?.[1])]);

  useEffect(() => {
    const selectedDateFromProps = formRef?.getFieldValue('dateTime');
    if (selectedDateFromProps) {
      setSelectedDate([dayjs(selectedDateFromProps[0]), dayjs(selectedDateFromProps[1])]);
    }
  }, [formRef.getFieldValue('dateTime')]);

  const onFooterSelectHandler = (selectedType: string): void => {
    const tempDates = selectedDate;
    if (selectedType === 'now' && focusType !== null && focusType !== undefined) {
      if (focusType.toLowerCase().includes('start')) {
        tempDates[0] = dayjs();
      } else {
        tempDates[1] = dayjs();
      }
    } else if (selectedType === 'startTime') {
      tempDates[0] = dayjs(selectedDate[0]).startOf('day');
    } else if (selectedType === 'endTime') {
      tempDates[1] = dayjs(selectedDate[1]).endOf('day');
    }
    setSelectedDate(tempDates);
    setDate(false, null, tempDates);
  };
  return (
    <RangePicker
      data-testid='testid-rangepicker'
      ref={pickerRef}
      showTime
      autoFocus
      style={{ width: '100%' }}
      className='log-exception-search-range-picker'
      popupClassName='log-exception-search-range-picker-popup'
      bordered={showBorder}
      allowClear={false}
      format={dateTimeFormat}
      suffixIcon={null}
      defaultValue={formRef.getFieldValue('dateTime')}
      value={formRef.getFieldValue('dateTime') ? [dayjs(formRef.getFieldValue('dateTime')?.[0]), dayjs(formRef.getFieldValue('dateTime')?.[1])] : undefined}
      onCalendarChange={(dates: any) => {
        setSelectedDate(dates);
        setDate(false, null, dates);
        if (focusType.toLowerCase().includes('start')) {
          setFocusType('end');
        }
      }}
      cellRender={(current: any, info: any) => {
        return (
          <span
            onClick={() => {
              const tempDates = selectedDate ?? [];
              if (info.type === 'date') {
                if (info.range === 'start') {
                  tempDates[0] = current;
                } else {
                  tempDates[1] = current;
                }
              } else if (info.type === 'time') {
                if (info.range === 'start') {
                  tempDates[0] = dayjs(tempDates[0]).set(info.subType, current)
                } else if (info.range === 'end') {
                  tempDates[1] = dayjs(tempDates[1]).set(info.subType, current)
                }
              }
              setSelectedDate(tempDates);
              setDate(false, null, tempDates);
            }}
          >
            {info.originNode}
          </span>
        );
      }}
      renderExtraFooter={(e) => {
        return (
          <RangePickerAdditionalFooter>
            <RangePickerAdditionalFooterHeader>Quick Time Select :</RangePickerAdditionalFooterHeader>
            <div className="cursor-pointer" >
              <a data-testid='testid-rangepicker-now-btn'
                onClick={() => onFooterSelectHandler('now')}>Now</a>
            </div>
            {focusType &&
              typeof focusType === 'string' &&
              focusType.toLowerCase().includes('start') && (
                <div className="cursor-pointer">
                  <a data-testid='testid-rangepicker-start-of-day'
                    onClick={() => onFooterSelectHandler('startTime')}>Start of the day</a>
                </div>
            )}
            {focusType &&
              typeof focusType === 'string' &&
              focusType.toLowerCase().includes('end') && (
                <div className="cursor-pointer">
                  <a data-testid='testid-rangepicker-end-of-day'
                    onClick={() => onFooterSelectHandler('endTime')}>End of the day</a>
                </div>
            )}
          </RangePickerAdditionalFooter>
        )
      }}
      onFocus={(e) => {
        setFocusType(e?.target?.placeholder);
      }}
    />
  );
};

AntdRangePicker.defaultProps = {
  showBorder: false
};

AntdRangePicker.propTypes = {
  showBorder: PropTypes.bool,
  setDate: PropTypes.func,
  closePanel: PropTypes.func,
  openPanel: PropTypes.bool,
  pickerRef: PropTypes.any,
  formRef: PropTypes.any,
  value: PropTypes.any
};

export default React.memo(AntdRangePicker);
