import { call, put, fork, all, takeLatest } from 'redux-saga/effects';
import type { AllEffect, CallEffect, ForkEffect, PutEffect } from 'redux-saga/effects';
import { createLogger } from 'redux-logger';
import { updateDataLoadingStatus } from '../../actions/CommonConfig/commonConfigActions';
import { GET_SCHEDULE_CONFIG_LIST } from '../../actions/ActionTypes/Admin/scheduleConfigTypes';
import { API_SUCCESS_STATUS_CODE } from '../../../constants/commonConstants';
import { apiRequest } from '../../../services/axiosInterceptor';
import apiURL from '../../../constants/apiEndpointConstants';
import { setScheduleConfigList } from '../../actions/Admin/scheduleConfigActions';

const callEffect: any = call;

export function * fetchScheduleConfigListSaga (): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: 'get',
      url: apiURL.GET_SCHEDULE_CONFIG_LIST
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    const payload = {
      data: response?.data,
      action: 'NEW'
    };

    if (response.status === API_SUCCESS_STATUS_CODE) {
      yield put(setScheduleConfigList(payload));
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * getScheduleConfigList (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_SCHEDULE_CONFIG_LIST, fetchScheduleConfigListSaga);
}

export default function * root (): Generator<AllEffect<any>> {
  yield all([
    fork(getScheduleConfigList)
  ]);
}
