import { Row } from 'antd';
import styled from 'styled-components';

export const FormWrapper = styled.div`
    padding: 10px 0 0px;
`;

export const RadioFormItemRowWrapper = styled(Row)`
    align-items: end;
    .ant-col {
        padding: 0px;
    }
    .ant-form-item-label > label,
    .ant-radio-wrapper {
        font-size: var(--font-size-md);
    }
`;
