import { produce } from 'immer';
import { SET_EXCEPTION_CONFIG_LIST } from '../../../actions/ActionTypes/Admin/exceptionConfigTypes';
import { processOperation } from '../../../../helpers/reducerHelper';

export interface ExceptionConfigState {
  exceptionConfigList: any[]
}

const initialState = {
  exceptionConfigList: []
};

const exceptionConfigReducer = (state = initialState, action: any): ExceptionConfigState =>
  produce(state, (draft: ExceptionConfigState) => {
    switch (action.type) {
      case SET_EXCEPTION_CONFIG_LIST:
        draft.exceptionConfigList = processOperation(action.data, [...draft.exceptionConfigList]);
        break;
    }
  });

export default exceptionConfigReducer;
