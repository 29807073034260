import routeConstants from '../../constants/routeConstants';

export const adminMenuItems = [
  {
    key: 'admin',
    label: 'Admin',
    children: [
      {
        key: routeConstants.ADMIN_APPLICATION_CONFIG,
        label: 'Application Management',
        path: routeConstants.ADMIN_APPLICATION_CONFIG
      },
      {
        key: routeConstants.ADMIN_EXCEPTION_CONFIG,
        label: 'Exception Configuration',
        path: routeConstants.ADMIN_EXCEPTION_CONFIG
      },
      {
        key: routeConstants.ADMIN_COLUMN_ALERTS,
        label: 'Column Alert Configuration',
        path: routeConstants.ADMIN_COLUMN_ALERTS
      },
      {
        key: routeConstants.ADMIN_REPLAY_CONFIG,
        label: 'Replay Configuration',
        path: routeConstants.ADMIN_REPLAY_CONFIG
      },
      {
        key: routeConstants.ADMIN_TABLE_CONFIG,
        label: 'Table Configuration',
        path: routeConstants.ADMIN_TABLE_CONFIG
      },
      {
        key: routeConstants.ADMIN_REPORT_CONFIG,
        label: 'Report Configuration',
        path: routeConstants.ADMIN_REPORT_CONFIG
      },
      {
        key: routeConstants.ADMIN_BLOB_METRICS,
        label: 'Blob Metrics',
        path: routeConstants.ADMIN_BLOB_METRICS
      },
      {
        key: routeConstants.ADMIN_COST_ANALYSIS,
        label: 'Chargeback Cost Analysis',
        path: routeConstants.ADMIN_COST_ANALYSIS
      },
      {
        key: routeConstants.ADMIN_SCHEDULE_CONFIG,
        label: 'Schedule Configuration',
        path: routeConstants.ADMIN_SCHEDULE_CONFIG
      },
      {
        key: routeConstants.ADMIN_SOLMAN_STAGING,
        label: 'Solman Staging',
        path: routeConstants.ADMIN_SOLMAN_STAGING
      },
      {
        key: routeConstants.ADMIN_LOG_EXCEPTION_VOLUME_METRICS,
        label: 'Volume Metrics',
        path: routeConstants.ADMIN_LOG_EXCEPTION_VOLUME_METRICS
      }
    ]
  },
  {
    key: 'accessManagement',
    label: 'Access Management',
    children: [
      {
        key: routeConstants.ADMIN_USER_ACCESS,
        label: 'User Access',
        path: routeConstants.ADMIN_USER_ACCESS
      },
      {
        key: routeConstants.ADMIN_GROUP_ACCESS,
        label: 'Group Access',
        path: routeConstants.ADMIN_GROUP_ACCESS
      }
    ]
  }
];

export const menuItems = [
  {
    key: routeConstants.DASHBOARD,
    label: 'Dashboard',
    path: routeConstants.DASHBOARD
  },
  {
    key: routeConstants.LOG_SEARCH,
    label: 'Log',
    path: routeConstants.LOG_SEARCH
  },
  {
    key: routeConstants.EXCEPTION_SEARCH,
    label: 'Exception',
    path: routeConstants.EXCEPTION_SEARCH
  },
  {
    key: routeConstants.EXCEPTION_FILE_REPLAY,
    label: 'File Replay',
    path: routeConstants.EXCEPTION_FILE_REPLAY
  }
];

export const nonAdminMenuItems = [
  {
    key: routeConstants.ADMIN_APPLICATION_CONFIG,
    label: 'Application Management',
    path: routeConstants.ADMIN_APPLICATION_CONFIG
  }
];

export default menuItems;
