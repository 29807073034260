export const userAccessRolesList = [
  { label: 'View', value: 'view' },
  { label: 'Replay', value: 'replay' },
  { label: 'Edit Header', value: 'edit_JMS_Header' },
  { label: 'Edit Payload', value: 'edit_JMS_Payload' }
];

export const AdminRoleList = [{ label: 'App Admin', value: 'app_admin' }];

export const userAccessRolesListForForm = [
  { label: '', value: 'view' },
  { label: '', value: 'replay' },
  { label: '', value: 'edit_JMS_Header' },
  { label: '', value: 'edit_JMS_Payload' }
];

export const AdminRoleListForForm = [{ label: '', value: 'app_admin' }];
