export const groupAccessFormFields = [
  {
    label: 'Group Name',
    name: 'groupName',
    id: 'groupName',
    required: true,
    rules: [{ required: true, message: 'Group Name is mandatory!' }],
    type: 'text',
    disableOnEdit: true
  },
  {
    label: 'Description',
    name: 'groupDescription',
    id: 'groupDescription',
    type: 'text'
  }
];
