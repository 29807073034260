import React from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Popover } from 'antd';
import FloatingLabel from '../../../common/FloatingLabel';
import textConstants from '../../../../constants/textConstants';
import {
  SearchCriteriaPopoverTitle,
  SearchCriteriaWrapper,
  SaveCriteriaPopoverWrapper
} from './style';
import SearchQueryRenderer from '../../../../constants/TableColumns/customRendererForColumns/SearchQueryRenderer';

interface SaveCriteriaProps {
  handleOpenChange: any
  searchCriteria: string
  isOpen: boolean
  onConfirm: any
  onCancel: () => void
  children?: ReactNode
}

const SaveCriteriaPopover: FC<SaveCriteriaProps> = ({
  handleOpenChange,
  isOpen,
  children,
  onConfirm,
  onCancel,
  searchCriteria
}) => {
  const [form] = Form.useForm();

  const onFormSubmit = (values: any): void => {
    onConfirm({
      ...values,
      criteriaJson: searchCriteria
    });
  }

  return (
    <Popover
      open={isOpen}
      onOpenChange={handleOpenChange}
      placement="bottomLeft"
      trigger='click'
      title={(
        <SearchCriteriaPopoverTitle>
          {textConstants.SAVE_CRITERIA_POPOVER_TITLE}
        </SearchCriteriaPopoverTitle>
      )}
      rootClassName='save-criteria-popover'
      content={(
        <SaveCriteriaPopoverWrapper>
          <SearchCriteriaWrapper>
            <h3>{textConstants.SAVE_CRITERIA_POPOVER_CRITERIA_TITLE}</h3>
            <SearchQueryRenderer searchQuery={searchCriteria} />
          </SearchCriteriaWrapper>
          <Form form={form} id="search-criteria-form" onFinish={onFormSubmit} scrollToFirstError>
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'Criteria Name is mandatory!' }]}
            >
              <FloatingLabel label={'Criteria Name'} type="text" data-testid="criteriaName" />
            </Form.Item>
            <Form.Item name="description">
              <FloatingLabel label={'Criteria Description'} rows={5} type="textarea" autoSize={false} />
            </Form.Item>
            <div className='text-right'>
              <Button type="text" className="mr-10 btn-regular" onClick={onCancel} data-testid="cancelBtn">
                {textConstants.CONFIRM_CANCEL_TEXT}
              </Button>
              <Button className='btn-regular' type="primary" htmlType="submit" data-testid="confirmBtn">
                {textConstants.CONFIRM_POPUP_TITLE}
              </Button>
            </div>
          </Form>
        </SaveCriteriaPopoverWrapper>
      )}
    >
      {children}
    </Popover>
  );
};

SaveCriteriaPopover.propTypes = {
  handleOpenChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.node,
  searchCriteria: PropTypes.string.isRequired
}

export default SaveCriteriaPopover;
