import React, { useState } from 'react';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { deleteSavedSearchCriteria } from '../../../redux/actions/SavedSearch/savedSearchActions';
import ConfirmationPopup from '../../../components/common/ConfirmationPopup';

interface componentProps {
  value?: any
  data?: any
}

const DeleteSearchCriteriaButtonRenderer: FC<componentProps> = ({
  ...params
}) => {
  const [isDeleteConfirmation, setIsDeleteConfirmation] =
    useState<boolean>(false);

  const dispatch = useDispatch();
  const onDeleteSavedSearchHandler = (): void => {
    dispatch(deleteSavedSearchCriteria([params?.data]));
    setIsDeleteConfirmation(false);
  };

  return (
    <>
      <a
        onClick={() => {
          setIsDeleteConfirmation(true);
        }}
        style={{ color: 'var(--error-color)' }}
        data-testid="deleteBtn"
      >
        <DeleteOutlined />
      </a>
      {isDeleteConfirmation && (
        <ConfirmationPopup
          isConfirmOpen={isDeleteConfirmation}
          message={'Do you want to delete?'}
          onConfirmHandler={onDeleteSavedSearchHandler}
          onCancelHandler={() => setIsDeleteConfirmation(false)}
        />
      )}
    </>
  );
};
export default DeleteSearchCriteriaButtonRenderer;
