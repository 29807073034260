
export const APPLICATION_NAME_LABEL = 'Application'
export const TIMEMARK_LABEL = 'Timestamp'

export const APPLICATION_DOMAIN_LABEL = 'Application Domain'
export const COMPONENT_NAME_LABEL = 'Component'
export const BUSINESS_ID_LABEL = 'Business ID'
export const BUSINESS_ID_TWO_LABEL = 'Business ID2'
export const SERVICE_NAME_LABEL = 'Service'
export const TRANSACTION_ID_LABEL = 'Transaction Id'
export const TRANSACTION_TYPE_LABEL = 'Transaction Type'
export const HOST_NAME_LABEL = 'Host Name'
export const TRANSACTION_DOMAIN_LABEL = 'Transaction Domain'
export const STATUS_LABEL = 'Status'
export const TIME_DURATION_LABEL = 'Time Duration'
export const LOG_LEVEL_LABEL = 'Level'

// Exception columns
export const CODE_LABEL = 'Code'
export const MESSAGE_LABEL = 'Message'
export const REPLAY_STATUS_LABEL = 'Replay Status'
export const RESOLUTION_DESCRIPTION_LABEL = 'Resolution Description'
export const REPLAY_DESTINATION_LABEL = 'Reply Destination'
export const REPLAY_COUNT_LABEL = 'Replay Count'
export const CATEGORY_LABEL = 'Category'
export const ISSUE_GROUP_LABEL = 'Issue Group'
export const TYPE_LABEL = 'Type'
export const SEVERITY_LABEL = 'Severity'

export const TIME_OCCURED_LABEL = 'Time Occured'

export const JSM_HEADER_LABEL = 'JMS Header'
export const DUMP_ANALYSIS_LABEL = 'Dump Analysis'
export const TRANSACTION_DATA_LABEL = 'Transaction Data'
export const RESOLVED_LABEL = 'Resolved'
export const TRANSACTION_BEFORE_LABEL = 'Transaction Before'
export const TRANSACTION_AFTER_LABEL = 'Transaction After'
export const DATA_ENCODING_LABEL = 'Data Encoding'

// JMS
export const JMS_DESTINATION_LABEL = 'JMS Destination'
export const JMS_REPLY_TO_LABEL = 'JMS Reply To'
export const JMS_DELIVERY_MODE_LABEL = 'JMSDeliveryMode'
export const JMS_MESSAGE_ID_LABEL = 'JMSMessageId'
export const JMS_TIMESTAMP_LABEL = 'JMSTimestamp'
export const JMS_EXPIRATION_LABEL = 'JMSExpiration'
export const JMS_REDELIVERED_LABEL = 'JMSRedelivered'
export const JMS_PRIORITY_LABEL = 'JMSPriority'
export const JMS_CORRELATION_ID_LABEL = 'JMSCorrelationID'
export const JMS_TYPE_LABEL = 'JMSType'

// JMSX
export const JMSX_USER_ID_LABEL = 'JMSXUserID'
export const JMSX_APP_ID_LABEL = 'JMSXAppID'
export const JMSX_PRODUCER_TIXID_LABEL = 'JMSXProducerTIXID'
export const JMSX_CONSUMER_TXID_LABEL = 'JMSXConsumerTXID'
export const JMSX_RCV_TIMESTAMP_LABEL = 'JMSXRcvTimestamp'
export const JMSX_DELIVERY_COUNT_LABEL = 'JMSXDeliveryCount'
export const JMSX_GROUP_ID_LABEL = 'JMSXGroupID'
export const JMSX_GROUP_SEQ_LABEL = 'JMSXGroupSeq'
