import styled from 'styled-components';

export const SpinWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    &.loader-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0,0, 0, .55);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99999;
    }
    &.large {
        .anticon.anticon-loading {
            font-size: 100px;
        }
    }
    &.medium {
        .anticon.anticon-loading {
            font-size: 70px;
        }
    }
    &.small {
        .anticon.anticon-loading {
            font-size: 40px;
        }
    }
`
