import { Button, Row } from 'antd';
import styled from 'styled-components';

export const LogExceptionSearchPanelWrapper = styled.div`
    color: var(--black);

    .ant-form {
        height: 100%;
        flex-flow: row;
        .ant-form-item-row {
            width: 100%;
        }

        .ant-form-item-explain-error {
            padding: 1px 0 5px;
        }
    }

    .ant-form-item, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        margin-bottom: 0;
        height: 100%;
        display: flex;
        align-items: center;
        
        .ant-select-selection-item {
            color: var(--black);
        }
    }

    .ant-select, .ant-form-item-row, .ant-form-item-control, .ant-form-item-control-input-content {
        height: 100%;

        .ant-form-item-control-input {
            min-height: 100%;
        }
    }

    .anticon-search, .anticon-appstore, .anticon-profile {
        color: var(--blue-color-500);
        font-size: var(--font-size-xxl);
    }

    @media screen and (min-width: 1600px) {
        .anticon-close-circle {
            font-size: 13px;
        }
    }
`;

export const FormRow = styled(Row)`
    height: var(--search-panel-height);
    background: var(--white);
    border: 1px solid var(--gray-250);
    box-shadow: 0px 0px 30px var(--black-50);

    &.form-row-with-error {
        margin-bottom: 10px;
    }

    @media screen and (min-width: 1600px) {
        height: 63px;
    }

    @media screen and (max-width: 767px) {
        height: auto;

        & > div {
            height: 45px;
        }
    }
`;

export const ApplicationNameWrapper = styled.div`
    flex: 0 0 21%;
    height: 100%;

    .react-dropdown-select {
        min-height: 100%;
        border-radius: 0;
        border-width: 0 0 1px 0;
        border-color: var(--white);

        &-dropdown-position-bottom {
            top: 103%;
        }

        &-dropdown-handle {
            order: -1;
            padding-left: 10px;
            padding-right: 10px;
            height: 20px;
        }

        &[aria-expanded="true"] .react-dropdown-select-clear {
            display: none;
        }
    }


    .react-dropdown-select {
        &:hover {
            border-bottom: 1px solid var(--blue-color-150);
        }
    }

    .ant-form-item-explain-error {
        position: absolute;
    }

    .ant-form-item-has-error {
        .react-dropdown-select {
            border-color: var(--form-field-error-color);
        }
    }

    @media screen and (max-width: 767px) {
        flex: 0 0 100%;
        border-width: 0 0 1px 0;
        
        .react-dropdown-select {
            border-color: var(--gray-250);
        }
    }
`;

export const CalendarButtonGroupWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content-center;
    // padding:10px;
    // border:1px solid var(--default-border-color);
    // border-radius: 3px;
`;

export const CalendarItemWrapper = styled.div`
    height: 100%;
    max-width: 20vw;
    display: flex;
`;
export const LogExceptionDateTimeWrapper = styled.div`
    border-left: 1px solid var(--gray-250);
    display: inline-flex;
    align-items: center;
    height: 100%;
    padding: 0 10px 0 15px;
    > div{
        height: 100%;
    }
    .ant-form-item-control-input-content{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    @media screen and (max-width: 767px) {
        flex: 0 0 100%;
        border-bottom: 1px solid var(--gray-250);
        border-width: 0 0 1px 0;
    }
`;

export const LogExceptionDateTimeLabel = styled.span`
    padding-left: 10px;
    color: var(--black);
    font-size: var(--font-size-sm);
    min-width: 7vw;
    display: flex;
`

export const SearchButton = styled(Button)`
    flex: 0 0 55px;

    &.ant-btn-icon-only {
        width: 55px;
        border-radius: 0;
        height: 100%;
        box-shadow: none;

        &:disabled {
            background-color: var(--btn-primary-disabled-bg);
        }

        .anticon-search {
            font-size: var(--font-size-xl);
            color: var(--white-200);
        }
    }

    @media screen and (max-width: 767px) {
        flex: 0 0 100%;
    }
`;

export const SearchFormPanelWrapper = styled.div`
    flex: 1;
    border-left: 1px solid var(--gray-250);
    border-top: 0;
    border-bottom: 0;
    height: 100%;
    position: relative;
    cursor: pointer;

    .anticon-search {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        z-index: 1;
    }

    .switch-form-item {
        position: absolute;
        z-index: 9;
        height: auto;
        top: 68%;
        transform: translateY(-60%);
        right: 10px;

        &::before {
            content: 'Advance';
            display: inline-block;
            color: var(--gray-color);
            font-size: var(--font-size-xs);
            position: absolute;
            top: -15px;
            left: -6px;

            @media screen and (min-width: 1600px) {
                left: -12px;
            }
        }
    }

    .ant-switch .ant-switch-handle {
        top: 2px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        .ant-select-selection-item {
            line-height: 40px;
        }
    }

    @media screen and (max-width: 767px) {
        flex: 0 0 100%;
        border-bottom: 1px solid var(--gray-250);
        border-width: 0 0 1px 0;
    }
`

export const SearchInputWrapper = styled.div`
    height: 100%;

    .ant-form-item .ant-form-item-control-input {
        border: none;
        border-bottom: 1px solid transparent;

        &:hover {
            border-bottom: 1px solid var(--blue-color-150);
        }

        .ant-input-affix-wrapper-focused {
            outline: none;
            box-shadow: none;
        }

        .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover, .ant-input-affix-wrapper-focuse {
            border: none;
            box-shadow: none;
        }

        .ant-input-affix-wrapper, .ant-input-affix-wrapper-disabled {
            height: 100%;
            padding-left: 40px;
            padding-right: 50px;
            background: transparent;
            border: none;
            font-size: var(--font-size-sm);
            color: var(--black-100);

            .ant-input[disabled] {
                color: var(--black);
                cursor: not-allowed;
                pointer-events: none;
            }

            @media screen and (min-width: 1600px) {
                padding-right: 50px;
            }
        }

        .ant-input::placeholder {
            color: var(--gray-color);
            opacity: .95;
        }

        .ant-input::-ms-input-placeholder {
            color: var(--gray-color);
            opacity: .95;
        }
    }

    .ant-form-item-has-error {
        &.ant-form-item .ant-form-item-control-input {
            border-bottom: 1px solid var(--form-field-error-color);
        }
    }
`
export const SearchAdvanceFormWrapper = styled.div`
    overflow: hidden;
`;

export const SearchAdvanceForm = styled.div`
    background: var(--white);
    padding: 15px 20px;
    margin: 10px;
    margin-bottom: 0px;
    border: 1px solid var(--gray-250);
    box-shadow: 0 2px 2px var(--black-400);
    z-index: 8;
    transition: margin-top .75s ease-in-out;

    &.slide-in {
        margin-top: 10px;
    }

    &.slide-out {
        margin-top: -100%;

        @media screen and (max-width: 767px) {
            margin-top: -140vh;
        }
    }
    
    .ant-form-item {
        margin-bottom: 15px;
    }

    .ant-form-item label {
        font-size: var(--font-size-sm);
    }
`

export const SearchAdvanceFormButtonWrapper = styled.div`
    text-align: right;
`

export const LogExceptionTypeWrapper = styled.div`
    border-left: 1px solid var(--gray-250);
    flex: 0 0 10%;
    position: relative;

    .ant-select-single, .ant-select-multiple { 
        &:not(.ant-select-customize-input) {
            border-bottom: 1px solid transparent;

            .ant-select-selector {
                border-radius: 0;
                border-width: 0;
                border-color: var(--white);
                padding-left: 43px;
                height: 100%;
            }

            &:hover {
                border-bottom: 1px solid var(--blue-color-150);
            }

            .ant-select-arrow {
                color: var(--black-100);
                font-size: var(--font-size-lg);
            }
        }
    }

    .ant-form-item-control-input .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 100%;
    }

    .anticon-profile {
        position: absolute;
        top: 50%;
        left: 7px;
        z-index: 1;
        transform: translateY(-50%);
    }

    .react-dropdown-select {
        min-height: 100%;
        border-radius: 0;
        border-width: 0 0 1px 0;
        border-color: var(--white);

        &:hover {
            border-color: var(--blue-color-150);
        }

        &-dropdown-position-bottom {
            top: 103%;
        }
        
        &-content {
            padding-left: 26px;

            span:not(.tag-count):only-child {
                span {
                    max-width: 55px;
                }
            }
            span:not(.tag-count) {
                span {
                    max-width: 25px;
                }
            }

            .tag-count {
                padding: 2px 6px;
                margin-right: 0;
            }
        }

        &-clear {
            margin-right: -6px;
        }
    }

    @media screen and (max-width: 767px) {
        flex: 0 0 100%;
        border-bottom: 1px solid var(--gray-250);
        border-width: 0 0 1px 0;
    }
`
