import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import { type FormFieldType } from '../../../../../constants/Interfaces/FormFieldTypes';
import { scheduleConfigFormFields } from '../../../../../constants/FormFields/AdminForm/scheduleConfigFormFields';
import { LabelValueWrapper, LabelWrapper } from '../../../../../assets/styles/commonStyled';
import { getLabelValueFormatter } from '../../../../../helpers/commonHelper';
import { SummaryViewRow } from '../style';

interface ConfigSummaryProps {
  data?: Partial<Record<string, string>>
}

const ScheduleConfigSummary: FC<ConfigSummaryProps> = ({ data }) => {
  return (
    <>
      <SummaryViewRow
        gutter={[16, 8]}
      >
        {scheduleConfigFormFields.map((formField: FormFieldType) => {
          return (
            <Col key={formField.id} xs={24} md={8} xxl={7}>
              <LabelWrapper>{formField.label}</LabelWrapper>
              <LabelValueWrapper>{getLabelValueFormatter(data?.[formField.name], formField?.type)}
              </LabelValueWrapper>
            </Col>
          );
        })}
      </SummaryViewRow>
    </>
  );
};

ScheduleConfigSummary.propTypes = {
  data: PropTypes.object
};

export default ScheduleConfigSummary;
