/**
 * This is Functional component for Unauthorized User Access
*/

import React from 'react';
import type { FC } from 'react';
import { Result } from 'antd';
import textConstants from '../../../constants/textConstants';

const Unauthorized: FC = () => {
  return (
    <Result
      status="403"
      subTitle={textConstants.UNAUTHORIZED_USER_MESSAGE}
    />
  );
};

export default Unauthorized;
