import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TableRowActions from '../../common/Table/TableRowActions';
import { HeaderTitle } from '../../../assets/styles/commonStyled';
import { ConfigDetailHeaderWrapper } from './style';

interface ConfigDetailCardHeaderProps {
  hasRightGap?: boolean
  className?: string
  title: string
  configName?: string
  isEditEnabled?: boolean
  isDeleteEnabled?: boolean
  onDelete?: any
  onEdit?: any
}

const ConfigDetailCardHeader: FC<ConfigDetailCardHeaderProps> = ({
  configName,
  className,
  hasRightGap,
  isDeleteEnabled,
  isEditEnabled,
  onDelete,
  onEdit,
  title
}) => {
  const userAccessInfo = useSelector((state: any) => state.userAccess?.userAccessInfo);

  return (
    <ConfigDetailHeaderWrapper hasRightGap={hasRightGap} className={className}>
      <HeaderTitle>{title}</HeaderTitle>
      {userAccessInfo?.adminAccess?.toUpperCase() === 'Y' && (
        <div>
          <TableRowActions
            onEdit={onEdit}
            onDelete={onDelete}
            hasEdit={isEditEnabled}
            tableGridName={configName}
            hasDelete={isDeleteEnabled}
            hasIconOnly={false}
          />
        </div>
      )}
    </ConfigDetailHeaderWrapper>
  );
};

ConfigDetailCardHeader.defaultProps = {
  isDeleteEnabled: true,
  hasRightGap: false
}

ConfigDetailCardHeader.propTypes = {
  className: PropTypes.string,
  configName: PropTypes.string,
  hasRightGap: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  title: PropTypes.string.isRequired,
  isEditEnabled: PropTypes.bool,
  isDeleteEnabled: PropTypes.bool
};

export default ConfigDetailCardHeader;
