import React, { useState } from 'react';
import type { FC } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { saveExceptionReportConfig, deleteExceptionReportConfig } from '../../../../redux/actions/Admin/exceptionReportConfigActions';
import { EXCEPTION_REPORT_CONFIG_TAB_KEY } from '../../../../constants/KeyLabels/commonKeyConstants';
import ConfigDetailCardHeader from '../../../../components/AdditionalComponents/ConfigDetailCardHeader';
import ExceptionReportConfigForm from '../../../../components/AdditionalComponents/UpsertForms/Admin/ExceptionReportConfig';
import AdminDetailBreadCrumb from '../../../../components/common/BreadCrumb/AdminDetailBreadCrumb';
import { AdminMainLayoutWrapper } from '../../../../assets/styles/commonStyled';
import { ConfigDetailWrapper } from '../../../../components/AdditionalComponents/DetailSummaryPage/Admin/style';
import textConstants from '../../../../constants/textConstants';
import routeConstants from '../../../../constants/routeConstants';
import { errorNotification } from '../../../../components/common/Notification';
import { checkIfExistsInArray } from '../../../../helpers/commonHelper';
import ExceptionReportConfigSummary from '../../../../components/AdditionalComponents/DetailSummaryPage/Admin/ExceptionReportConfig/ExceptionReportConfigSummary';
import { exceptionReportConfigItemKey } from '../../../../constants/KeyLabels/KeysToCheckForDuplicateItems';

const ExceptionReportConfigDetail: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { data, isEditable } = (location?.state as { isEditable?: boolean, data: any }) ?? {};

  const [isConfigDetailInEditMode, setConfigDetailInEditMode] = useState(isEditable);
  const [configData, setConfigData] = useState(data);

  const exceptionReportConfigList = useSelector(
    (state: any) => state.exceptionReportConfig.exceptionReportConfigList
  );

  const onFormSaveCancelHandler = (): void => {
    if (isEditable) {
      history.push({
        pathname: routeConstants.ADMIN_REPORT_CONFIG,
        state: {
          activeTab: EXCEPTION_REPORT_CONFIG_TAB_KEY
        }
      })
    } else {
      setConfigDetailInEditMode(false);
    }
  }

  const onExceptionReportConfigSaveHandler = (data: any): void => {
    const filteredExceptionReportConfigList = exceptionReportConfigList.filter((item: any) => item.configID !== data.configID);
    if (!checkIfExistsInArray(filteredExceptionReportConfigList, data, exceptionReportConfigItemKey)) {
      dispatch(saveExceptionReportConfig(data, onFormSaveCancelHandler));
      setConfigData(data);
    } else {
      errorNotification(textConstants.EXCEPTION_REPORT_CONFIG_ALREADY_EXIST_MESSAGE);
    }
  }

  const onExceptionReportConfigDeleteHandler = (): void => {
    dispatch(deleteExceptionReportConfig([data], history));
  };

  return (
    <AdminMainLayoutWrapper data-testid="exceptionReportConfigDetail">
      <AdminDetailBreadCrumb
        mainPageText={textConstants.EXCEPTION_REPORT_CONFIG_PAGE_TITLE}
        currentPageText={data?.applicationName.join(', ')}
        onBackCallback={() => history.push({
          pathname: routeConstants.ADMIN_REPORT_CONFIG,
          state: {
            activeTab: EXCEPTION_REPORT_CONFIG_TAB_KEY
          }
        })}
      />
      <ConfigDetailWrapper>
        <ConfigDetailCardHeader
          onDelete={onExceptionReportConfigDeleteHandler}
          onEdit={() => setConfigDetailInEditMode(true)}
          isEditEnabled={!isConfigDetailInEditMode}
          title={textConstants.EXCEPTION_REPORT_CONFIG_DETAIL_PAGE_TITLE}
          configName={textConstants.EXCEPTION_REPORT_DELETE_CONFIG_TITLE}
        />

        {isConfigDetailInEditMode && (
          <ExceptionReportConfigForm
            data={configData}
            onSave={onExceptionReportConfigSaveHandler}
            onCancel={onFormSaveCancelHandler}
          />
        )}
        {!isConfigDetailInEditMode && <ExceptionReportConfigSummary data={configData} />}
      </ConfigDetailWrapper>
    </AdminMainLayoutWrapper>
  );
};

export default ExceptionReportConfigDetail;
