import styled from 'styled-components';
import { Select } from 'antd';

export const TagInputWrapper = styled(Select)`
    &.ant-select-in-form-item.ant-select-multiple {
        &:not(.ant-select-customize-input) {
            .ant-select-selector {
                padding-left: 10px;
                padding-top: 4px;
                max-height: 80px;
                overflow-y: auto;
            }
        }

        .ant-select-selection-item {
            background-color: var(--yellow-400);
            border: 1px solid var(--yellow-200);
            height: 21px;
            line-height: 21px;
        }

        .ant-select-selection-search {
            input {
                font-size: var(--font-size-sm);
            }
        }
        
        .anticon-search {
            display: none;
        }
    }
`;
