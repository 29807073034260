import React, { Fragment } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import useGenerateColoredList from '../../../components/CustomHooks/generateColoredList';
import moment from 'moment';
import { dateTimeFormat } from '../../commonConstants';
import { TRANSACTION_ID_LABEL, BUSINESS_ID_LABEL, BUSINESS_ID_TWO_LABEL } from '../../KeyLabels/labelConstants';
import { SearchQueryApplicationWrapper } from '../../../assets/styles/commonStyled';

interface componentProps {
  searchQuery: any
  isParentTable?: boolean
}

const remainingColumns = [
  {
    col_key: 'transactionID', // TRANSACTION_ID_KEY,
    label: TRANSACTION_ID_LABEL
  },
  {
    col_key: 'businessID',
    label: BUSINESS_ID_LABEL
  },
  {
    col_key: 'businessID2',
    label: BUSINESS_ID_TWO_LABEL
  }
]
const SearchQueryRenderer: FC<componentProps> = ({ searchQuery, isParentTable }) => {
  let searchQueryInput = searchQuery;
  if (typeof searchQuery === 'string') {
    searchQueryInput = JSON.parse(searchQuery);
  }
  const applicationList = searchQueryInput?.applicationName?.split(',');
  const applicationColorData = useGenerateColoredList(applicationList);
  return <>
    {
      applicationColorData.length > 0 &&
      <SearchQueryApplicationWrapper className={ !isParentTable ? 'application-wrap' : ''}>
        Application: &nbsp; {applicationColorData?.map((applicationElement) => {
        return (
            <Tag key={applicationElement.content} color={applicationElement.color}>
              {applicationElement.content}
            </Tag>
        );
      })}
      </SearchQueryApplicationWrapper>
    }
    {
      searchQueryInput?.dateTime &&
      <> Time Frame :
        {moment(searchQueryInput?.dateTime[0]).format(dateTimeFormat)}-{moment(searchQueryInput?.dateTime[1]).format(dateTimeFormat)}
      </>
    }
    {
      remainingColumns?.map((elementInArray) => {
        return (searchQueryInput?.[elementInArray?.col_key]
          ? (
            <Fragment key={elementInArray?.col_key}>
              ,&nbsp;{elementInArray?.label}:{searchQueryInput?.[elementInArray?.col_key]}
            </Fragment>)
          : undefined
        )
      })
    }
  </>
}
SearchQueryRenderer.defaultProps = {
  isParentTable: false
}
SearchQueryRenderer.propTypes = {
  searchQuery: PropTypes.any,
  isParentTable: PropTypes.bool
};

export default SearchQueryRenderer;
