import {
  GET_TABLE_META_DATA_LIST,
  SET_TABLE_CONFIG_LIST,
  SAVE_TABLE_CONFIG
} from '../ActionTypes/Admin/tableConfigTypes';

interface GetActionInterface {
  type: string
}

interface SaveConfigDataAction {
  type: string
  payload: {
    data: any[]
    callBackFunc?: any
    isAdd?: boolean
  }
}

export function getLogAndExceptionTableMetaData (): GetActionInterface {
  return {
    type: GET_TABLE_META_DATA_LIST
  };
}

export function setTableConfigList (data: any): any {
  return {
    type: SET_TABLE_CONFIG_LIST,
    data
  };
}

export function saveTableConfig (data: any, callBackFunc?: any, isAdd?: boolean): SaveConfigDataAction {
  return {
    type: SAVE_TABLE_CONFIG,
    payload: {
      data,
      callBackFunc,
      isAdd
    }
  };
}
