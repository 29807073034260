export const applicationFormFields = [
  {
    label: 'Application Name',
    name: 'applicationName',
    id: 'applicationName',
    type: 'text',
    disableOnEdit: true
  },
  {
    label: 'Description',
    name: 'appDescription',
    id: 'description',
    type: 'text'
  },
  {
    label: 'Application Owner',
    name: 'appOwner',
    id: 'appOwner',
    type: 'text'
  },
  {
    label: 'Sector',
    name: 'appSector',
    id: 'appSector',
    type: 'select',
    fieldName: 'sectorName'
  },
  {
    label: 'Log Retain Days',
    name: 'logRetain',
    id: 'logRetain',
    type: 'text',
    disableOnEdit: true
  },
  {
    label: 'Log Topic',
    name: 'logTopic',
    id: 'logTopic',
    type: 'text',
    disableOnEdit: true
  },
  {
    label: 'Log Table Name',
    name: 'logTable',
    id: 'logTable',
    type: 'text' // drop down
  },
  {
    label: 'Exception Retain Days',
    name: 'exceptionRetain',
    id: 'exceptionRetain',
    type: 'text',
    disableOnEdit: true
  },
  {
    label: 'Exception Topic',
    name: 'exceptionTopic',
    id: 'exceptionTopic',
    type: 'text',
    disableOnEdit: true
  },
  {
    label: 'Exception Table Name',
    name: 'exceptionTable',
    id: 'exceptionTable',
    type: 'text'
  },
  {
    label: 'Team DL',
    name: 'teamDl',
    id: 'teamDl',
    type: 'text'
  },
  {
    label: 'Snow CI',
    name: 'snowCI',
    id: 'snowCI',
    type: 'text'
  },
  {
    label: 'Assignment Group',
    name: 'assignmentGroup',
    id: 'snowCassignmentGroupI',
    type: 'text'
  }
];
