import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'antd';
import type { AdminConfigFormPropsType } from '../../../../../constants/Interfaces/AdminFormConfigTypes';
import type { FormFieldType } from '../../../../../constants/Interfaces/FormFieldTypes';
import { groupAccessFormFields } from '../../../../../constants/FormFields/AccessManagementForm/groupAccessFormFields';
import FloatingLabel from '../../../../common/FloatingLabel';
import Footer from '../../../../common/Footer';

const columnSpan = {
  xs: 24,
  md: 7,
  xl: 5
}

const GroupAccessForm: FC<AdminConfigFormPropsType> = ({ isEditable, data, onSave, onCancel }) => {
  const [form] = Form.useForm();

  const onSaveHandler = (isClosable: boolean): void => {
    form
      .validateFields()
      .then(async (values) => {
        onSave(values, isClosable);
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <Form
        form={form}
        initialValues={{
          ...data
        }}
        data-testid="groupAccessForm"
      >
        <Row gutter={{ lg: 16, xl: 16, xxl: 28 }}>
          {groupAccessFormFields.map((formField: FormFieldType) => {
            return (
              <Col key={formField.id} xs={columnSpan.xs} md={columnSpan.md} xl={columnSpan.xl}>
                <Form.Item name={formField.name} rules={formField.rules} className='mb-5'>
                  <FloatingLabel
                    label={formField.label}
                    type={formField.type}
                    id={formField.id}
                    disabled={isEditable && formField.disableOnEdit}
                    required={formField?.required}
                  />
                </Form.Item>
              </Col>
            );
          })}
        </Row>
      </Form>
      <Footer
        className='grey-theme add-form-footer'
        onSave={() => onSaveHandler(false)}
        onSaveClose={() => onSaveHandler(true)}
        onCancel={onCancel}
        saveWithClose={!isEditable}
        save
        cancel
      />
    </>
  );
};

GroupAccessForm.defaultProps = {
  isEditable: true
};

GroupAccessForm.propTypes = {
  isEditable: PropTypes.bool,
  data: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default GroupAccessForm;
