import styled, { css } from 'styled-components';

export const CardStyle = css`
  box-shadow: 1px 2px 2px 2px var(--default-border-color);
  border-radius: 5px;
  margin: 10px;
  background: var(--white);
`;

export const PageTitleWrapper = styled.div`
  background: var(--blue-color-600);
  height: 43px;
  color: var(--blue-color-700);
  padding: 0 30px;
  display: flex;
  align-items: center;

  h1 {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-regular);
    margin: 0;
  }
`;

export const MainContentWrapper = styled.div`
  background: var(--main-content-wrapper-bg);
  overflow-x: hidden;
  min-height: calc(100vh - 64px);

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs .ant-tabs-content {
    padding: 0 5px;
  }

`;

export const SearchTabWrapper = styled.div`
animation: fadeIn 1.15s;
-webkit-animation: fadeIn 1.15s;
-moz-animation: fadeIn 1.15s;
-o-animation: fadeIn 1.15s;
-ms-animation: fadeIn 1.15s;
`;

export const TopSearchContentWrapper = styled.div`
  margin-bottom: 10px;
`;

export const TabContentWrapper = styled.div``;

export const LogSearchBottomTableContentWrapper = styled.div`
  ${CardStyle}
  .ant-tabs .ant-tabs-content {
    padding: 0px;
  }
  .ant-tabs .ant-tabs-tab{
    margin: 0 10px 0 10px;
  }
  .ant-tabs-nav{
    margin: 5px;
  }
  margin: 0 10px 0;
  border-radius: 0px;
  overflow: auto;
  box-shadow: 0px 2px 2px var(--black-400);
  border: 1px solid var(--gray-225);
`;
