import { APPLICATION_NAME_ID } from './commonKeyConstants';

export const columnAlertConfigItemKeys = [
  APPLICATION_NAME_ID,
  'exceptionCategoryName',
  'exceptionSeverityName',
  'exceptionTypeName',
  'columnName',
  'columnValue'
];

export const exceptionReportConfigItemKey = ['applicationName', 'metricType', 'reportType'];
export const logReportConfigItemKey = ['applicationName', 'mailID'];
export const reportConfigItemKey = ['applicationName', 'serviceName'];
