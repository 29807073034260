import type {
  DeleteConfigDataActionType,
  GetConfigDetailsActionType,
  SetConfigDetailsActionType
} from '../../../constants/Interfaces/AdminConfigActionTypes';
import {
  DELETE_REPLAY_CONFIG,
  GET_REPLAY_CONFIG_LIST,
  SET_REPLAY_CONFIG_LIST,
  SAVE_REPLAY_CONFIG
} from '../ActionTypes/Admin/replayConfigTypes';

interface SaveConfigDetailAction {
  type: string
  payload: {
    data: any[]
    callBackFunc?: any
    isAdd?: boolean
  }
}

export function getReplayConfigList (applicationName?: string): GetConfigDetailsActionType {
  return {
    type: GET_REPLAY_CONFIG_LIST,
    applicationName
  };
}

export function setReplayConfigList (data: any): SetConfigDetailsActionType {
  return {
    type: SET_REPLAY_CONFIG_LIST,
    data
  };
}

export function deleteReplayConfig (data: any, callBackFunc?: any): DeleteConfigDataActionType {
  return {
    type: DELETE_REPLAY_CONFIG,
    payload: {
      data,
      callBackFunc
    }
  };
}

export function saveReplayConfig (data: any, callBackFunc?: any, isAdd?: boolean): SaveConfigDetailAction {
  return {
    type: SAVE_REPLAY_CONFIG,
    payload: {
      data,
      callBackFunc,
      isAdd
    }
  };
}
