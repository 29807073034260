import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Security, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import oktaConfig from '../../config/oktaConfig';
import routes from '../../routes/routes';
import Authentication from '../../containers/Authentication';
import { useSelector } from 'react-redux';
import Unauthorized from '../common/Unauthorized';

const oktaAuth = new OktaAuth(oktaConfig);

function AppWithRouterAccess (): JSX.Element {
  const history = useHistory();
  const userAccessInfo = useSelector((state: any) => state.userAccess?.userAccessInfo);
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string): Promise<void> => {
    history?.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  useEffect(() => {
    if (
      history.location.pathname === '/callback' &&
      history.location.search.includes('error=access_denied')
    ) {
      history?.replace('/unauthorized');
    }
    // To redirect to main layout page if user directly tries to navigate to detail url
    if (history?.location?.pathname?.includes('detail') || history?.location?.pathname?.includes('new-')) {
      history?.replace(history?.location?.pathname?.substring(0, history?.location?.pathname?.lastIndexOf('/')))
    }
  }, []);

  const renderComponent = (route: any): JSX.Element => {
    if (route?.isAdminPath && userAccessInfo?.adminAccess === 'Y') {
      return <route.component />;
    } else if (!route?.isAdminPath && userAccessInfo) {
      return <route.component />;
    } else if (userAccessInfo === '' || (userAccessInfo?.adminAccess === 'N')) {
      return <Unauthorized />;
    } else {
      return <></>;
    }
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        {routes.map((route) => {
          return route.isPrivate
            ? (
              <SecureRoute exact={route.isExact} key={route.path} path={route.path}>
                <Authentication>
                  {renderComponent(route)}
                </Authentication>
              </SecureRoute>
              )
            : (
              <Route
                component={route.component}
                exact={route.isExact}
                key={route.path}
                path={route.path}
              />
              );
        })}
      </Switch>
    </Security>
  );
}

export default AppWithRouterAccess;
