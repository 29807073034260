import React from 'react';
import type { FC } from 'react';
import { Button, Tooltip } from 'antd';
import { CloseCircleOutlined, DeleteOutlined, EditOutlined, PlusCircleOutlined, FilterOutlined, UploadOutlined } from '@ant-design/icons';
import FileUploadButton from '../../FileUploadButton';
import textConstants from '../../../../constants/textConstants';

interface TableHeaderActionsProps {
  createBtnClass?: string
  creatBtnLabel?: string
  deleteBtnLabel?: string
  hasClose?: boolean
  hasCreate?: boolean
  hasDelete?: boolean
  hasImport?: boolean
  hasExport?: boolean
  hasFilter?: boolean
  hasModify?: boolean
  isExportDisabled?: boolean
  isDeleteDisabled?: boolean
  modifyBtnLabel?: string
  onClose?: any
  onCreate?: any
  onDelete?: any
  onImport?: any
  onExport?: any
  onFilter?: any
  onModify?: any
}

const TableHeaderActions: FC<TableHeaderActionsProps> = ({
  createBtnClass,
  creatBtnLabel,
  deleteBtnLabel,
  hasClose,
  hasCreate,
  hasDelete,
  hasImport,
  hasExport,
  hasFilter,
  hasModify,
  isExportDisabled,
  isDeleteDisabled,
  modifyBtnLabel,
  onClose,
  onCreate,
  onDelete,
  onImport,
  onExport,
  onFilter,
  onModify
}) => {
  return (
    <>
      {hasImport && (
        <FileUploadButton
          className='btn-regular'
          onUpload={onImport}
        />
      )}
      {hasExport && (
        <Tooltip placement="bottom" title={textConstants.EXPORT_BUTTON_TOOLTIP_MESSAGE}>
          <Button
            type="text"
            data-testid="exportBtn"
            disabled={isExportDisabled}
            className='btn-regular'
            icon={<UploadOutlined />}
            onClick={onExport}
          >
            Export
          </Button>
        </Tooltip>
      )}
      {hasFilter && (
        <Button
          type="text"
          data-testid="filterBtn"
          className='btn-regular'
          icon={<FilterOutlined />}
          onClick={onFilter}
        >
          Filters
        </Button>
      )}
      {hasDelete && (
        <Button
          type="text"
          data-testid="deleteBtn"
          className='btn-regular'
          icon={<DeleteOutlined />}
          onClick={onDelete}
          disabled={isDeleteDisabled}
        >
          {deleteBtnLabel}
        </Button>
      )}
      {hasClose && (
        <Button
          type="text"
          data-testid="closeBtn"
          className='btn-regular'
          icon={<CloseCircleOutlined />}
          onClick={onClose}
        >
          Close
        </Button>
      )}
      {hasModify && (
        <Button
          type="default"
          data-testid="modifyBtn"
          className='btn-secondary btn-modify'
          icon={<EditOutlined />}
          onClick={onModify}
        >
          {modifyBtnLabel}
        </Button>
      )}
      {hasCreate && (
        <Button
          type="primary"
          data-testid="createBtn"
          className={createBtnClass}
          icon={<PlusCircleOutlined />}
          onClick={onCreate}
        >
          {creatBtnLabel}
        </Button>
      )}
    </>
  );
};

TableHeaderActions.defaultProps = {
  creatBtnLabel: 'Add',
  deleteBtnLabel: 'Delete',
  modifyBtnLabel: 'Modify'
}

export default TableHeaderActions;
