import styled, { css } from 'styled-components';
import { AgGridReact } from 'ag-grid-react';
import { Layout } from 'antd';

export const CursorPointerStyle = css`
    cursor: pointer;
`;
export const CardStyle = css`
    box-shadow: 1px 2px 2px 0px var(--default-border-color);
    border-radius: 2px;
`;

export const LayoutWrapper = styled(Layout)`
    background: transparent;
    height: 100%;
    display: flex;

    .ant-layout-content {
        flex: 1;

        & > div,
        .ag-theme-material:not(.ag-popup) {
            height: 100%;
        }
    }

    .ant-layout {
        background: transparent;
    }

    .table-data-loader {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999999;
        background: rgba(255, 255, 255, 0.5);

        .ant-spin-nested-loading {
            position: static;
        }
    }
`;

export const LayoutDrawerParentWrapper = styled(Layout)`
    position: relative;

    .fixed-progress-bar {
        top: 160px !important;

        @media screen and (max-width: 1599px) {
            top: 148px !important;
        }

        @media screen and (max-width: 1280px) {
            top: 145px !important;
        }

        & > div > div {
            display: none !important;
        }
    }
`;
export const TableMainWrapper = styled.div`
    ${CardStyle}
    border-radius: 0px;
`;
export const CommonTableMainWrapper = styled.div``;
export const TableSeparateHeaderWrapper = styled.div`
    padding: 10px 0px 5px 20px;
`;

export const TableHeaderWrapper = styled.div`
    padding: 2px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--default-border-color);
    &.log-search-header {
        padding: 0;
        background: var(--common-table-header-top-control-bg-color);
    }

    &.config-list-grid {
        .ant-btn {
            font-weight: var(--font-weight-medium);

            &-text {
                color: var(--blue-color-800);
            }

            &.order-3 {
                order: 3;
            }

            &.ant-popover-open {
                border: 1px solid transparent;
                outline: none;
            }

            &.ant-btn-primary {
                order: 4;
            }
            &.add-user-without-bg-btn-class {
                color: var(--btn-primary-bg);
                background: transparent;
                border: 0px;
                margin-right: 270px;
            }
            &.add-user-without-bg-btn-class:not(:disabled):not(.ant-btn-disabled):hover {
                background: transparent;
            }
        }
    }
`;
export const HeaderLeftContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    div {
        margin-right: 10px;
        display: flex;
        align-items: center;
    }
    > div:first-child {
        margin-right: 30px;
    }
`;

export const HeaderTableTitleWrapper = styled.div`
    color: var(--blue-color-700);
    font-weight: var(--font-weight-semibold);
`;

export const HeaderSearchWrapper = styled.div`
    flex: 1;
    min-width: 15vw;
`;

export const HeaderRightContentWrapper = styled.div`
    display: flex;
    align-self: stretch;

    .ant-popover-open {
        position: relative;
        z-index: 1050;
    }

    .ant-upload-select,
    span .ant-btn {
        height: 100%;
    }

    & > div {
        text-align: center;
    }

    .btn-regular.ant-btn-text:disabled {
        color: var(--gray-500);
    }

    .ant-popover-open:first-child {
        border-left: 1px solid var(--gray-250);
        border-right: 1px solid var(--gray-250);
    }

    .ant-popover-open:nth-child(2) {
        border-left: 1px solid var(--gray-250);
        border-right: 1px solid var(--gray-250);
    }

    .ant-popover-open:last-child {
        border-left: 1px solid var(--gray-250);
    }

    .ant-popover-open:only-child {
        border: none;
    }
`;

export const TableIconButtonWrapper = styled.div`
    ${CursorPointerStyle}
    padding: 8px 10px;
    color: var(--blue-color-800);
    border: 1px solid transparent;
    min-width: 8.233vw;

    @media screen and (min-width: 1920px) {
        min-width: 6.5vw;
    }

    &.ant-popover-open {
        background: var(--white);
    }
`;

export const TableIconWrapper = styled.span`
    font-size: var(--font-size-lg);
    margin-right: 4px;
    &.rotate-90 .anticon {
        transform: rotate(90deg);
    }
    &.rotate-minus-90 .anticon {
        transform: rotate(-90deg);
    }
`;

export const AgGridReactComponent = styled(AgGridReact)`
    .ag-paging-panel {
        height: 40px;
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-medium);
    }
    .ag-root-wrapper {
        border: 0px;
    }
    &.no-header-border .ag-header {
        border: 0px !important;
    }
    &.primary-theme {
        .ag-header {
            background-color: var(--ag-grid-primary-theme-header-color);
        }
        .ag-header .ag-header-cell-text {
            color: var(--black-100);
        }

        .ag-cell[col-id='log_level'] {
            padding: 0;
            text-align: center;
        }
    }
    &.secondary-theme {
        .ag-header {
            background-color: var(--blue-color-600);
        }
        .ag-header .ag-header-active,
        .ag-header .ag-header-cell:hover {
            background-color: var(--blue-color-650) !important;
        }
        .ag-header .ag-header-cell-text {
            color: var(--blue-color-700);
        }
    }
    &.content-height {
        .ag-root-wrapper-body.ag-layout-normal {
            height: 100%;
        }
        .ag-body-viewport .ag-center-cols-clipper {
            height: auto !important;
        }
    }

    .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell),
    .ag-pinned-right-header {
        border-left-color: var(--gray-200);

        &.ag-cell-focus:not(.ag-cell-range-selected):focus-within {
            border-width: 0 0 0 1px;
            outline: none;
        }
    }
`;

export const TableFilteredElementBar = styled.div`
    background-color: var(--gray-150);
    display: flex;
    align-items: center;
`;
export const TableFilteredResetWrapper = styled.div`
    margin-left: 10px;
`;
export const TableFilteredTagWrapper = styled.div`
    display: flex;
    overflow: auto;
    padding: 6px 10px;
    margin-right: 10px;
    .ant-tag {
        label {
            color: var(--blue-color-900);
        }
    }
`;

export const ConfigurationListHeader = styled.div`
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-semibold);
    box-shadow: 0px 1px 2px var(--black-400);
    color: var(--blue-color-700);
    background-color: var(--white);
    padding: 10px 23px;
    margin: 10px 0;
`;
