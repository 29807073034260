import { apiRequest } from './axiosInterceptor';
import { successNotification } from '../components/common/Notification';
import { API_SUCCESS_STATUS_CODE } from '../constants/commonConstants';
import textConstants from '../constants/textConstants';

export const exportAPIRequestHandler = (
  request: any,
  url: string,
  isExportAll: boolean,
  fileName: string,
  loadingBarRef?: any,
  resolve?: any,
  reject?: any
): void => {
  loadingBarRef?.current?.continuousStart();

  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/octet-stream',
      'Content-Type': 'application/json'
    },
    responseType: 'blob',
    data: JSON.stringify(request),
    url
  };

  apiRequest(config)
    .then((response: any) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.download = `${fileName?.toLowerCase()}.xlsx`;
      document.body.appendChild(link);
      link.click();
      if (isExportAll) {
        return resolve();
      } else {
        loadingBarRef?.current?.complete();
        if (response?.status === API_SUCCESS_STATUS_CODE) {
          successNotification(`${fileName.replace(/-/g, ' ')} ${textConstants.DOWNLOAD_SUCCESS_MESSAGE}`);
        }
      }
    })
    .catch((error) => {
      if (isExportAll) {
        return reject(error);
      } else {
        loadingBarRef?.current?.complete();
      }
    });
};

export const APIPostRequestHandler = async (
  request: any,
  url: string
): Promise<any> => {
  const config = {
    method: 'POST',
    data: request,
    url
  };

  return await apiRequest(config)
    .then((response: any) => response?.data)
    .catch(() => {
      return null;
    });
};

export const exceptionReplayAPIRequestHandler = (
  request: any,
  url: string,
  apiCallback: any
): void => {
  const config = {
    method: 'POST',
    data: request,
    url
  };

  apiRequest(config)
    .then((response: any) => {
      apiCallback(response?.data, request);
    })
    .catch(() => {
      apiCallback(null, request);
    });
};
