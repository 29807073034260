import * as types from '../ActionTypes/LogSearch/logSearchTypes';

interface dataInterface {
  type: string
  logTabList?: []
  payload?: any
}

export function fetchSearchData (searchQuery: any, logOrExceptionType: string, searchedTabKey: string, recordCount: number): dataInterface {
  return {
    type: types.FETCH_SEARCH_DATA,
    payload: { searchQuery, logOrExceptionType, searchedTabKey, recordCount }
  };
}
export function cancelSearchApiCall (): dataInterface {
  return {
    type: types.CANCEL_SEARCH_APICALL
  };
}
