import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '../../../components/common/Tabs';
import {
  AdminBasicTabWrapper,
  AdminMainBreadCrumbWrapper,
  AdminMainLayoutWrapper
} from '../../../assets/styles/commonStyled';
import { LOG_REPORT_CONFIG_TAB_KEY, EXCEPTION_REPORT_CONFIG_TAB_KEY, REPORT_CONFIG_TAB_KEY } from '../../../constants/KeyLabels/commonKeyConstants';
import textConstants from '../../../constants/textConstants';
import LogReportConfigTab from '../../../components/AdditionalComponents/TabContents/ReportConfiguration/LogReportConfigTab';
import ReportConfigTab from '../../../components/AdditionalComponents/TabContents/ReportConfiguration/ReportConfigTab';
import ExceptionReportConfigTab from '../../../components/AdditionalComponents/TabContents/ReportConfiguration/ExceptionReportConfigTab';

const ReportConfig: FC = () => {
  const location = useLocation();
  const { activeTab } = location?.state as { activeTab?: string } ?? {};
  const [currentActiveTab, setActiveTabName] = useState(REPORT_CONFIG_TAB_KEY);

  const onTabChange = (tabName: string): void => {
    setActiveTabName(tabName);
  }

  useEffect(() => {
    setActiveTabName(activeTab ?? REPORT_CONFIG_TAB_KEY)
  }, [])

  return (
    <AdminMainLayoutWrapper data-testid='reportConfigTabWrapper'>
      <AdminMainBreadCrumbWrapper>
        {textConstants.REPORT_CONFIG_PAGE_TITLE}
      </AdminMainBreadCrumbWrapper>
      <AdminBasicTabWrapper>
        <Tabs
          tabClassName="basic-theme"
          activeTab={currentActiveTab}
          tabSize="small"
          tabType="line"
          onTabChangeHandler={onTabChange}
          tabItems={[
            {
              key: REPORT_CONFIG_TAB_KEY,
              label: 'Report Config',
              children: <ReportConfigTab />
            },
            {
              key: LOG_REPORT_CONFIG_TAB_KEY,
              label: 'Log Report',
              children: <LogReportConfigTab />
            },
            {
              key: EXCEPTION_REPORT_CONFIG_TAB_KEY,
              label: 'Exception Report',
              children: <ExceptionReportConfigTab />
            }
          ]}
        />
      </AdminBasicTabWrapper>
    </AdminMainLayoutWrapper>
  );
};

export default ReportConfig;
