import applicationConfigSaga from './Admin/applicationConfigSaga';
import blobMetricsSaga from './Admin/blobMetricsSaga';
import configurationSaga from './Admin/configurationSaga';
import columnAlertConfigSaga from './Admin/columnAlertConfigSaga';
import dashboardSaga from './Dashboard/dashboardSaga';
import exceptionConfigSaga from './Admin/exceptionConfigSaga';
import exceptionReportConfigSaga from './Admin/exceptionReportConfigSaga';
import filReplaySaga from './FileReplay/fileReplaySaga';
import groupAccessSaga from './AccessManagement/GroupAccess/groupAccessSaga';
import logExceptionSearchSaga from './LogExceptionSearch/logExceptionSearchSaga';
import logReportConfigSaga from './Admin/logReportConfigSaga';
import replayConfigSaga from './Admin/replayConfigSaga';
import reportConfigSaga from './Admin/reportConfigSaga';
import savedSearchCriteriaSaga from './SavedSearchCriteria/savedSearchCriteriaSaga';
import scheduleConfigSaga from './Admin/scheduleConfigSaga';
import solmanStagingConfigSaga from './Admin/solmanStagingConfigSaga';
import userProfileSaga from './UserProfile/userProfileSaga';
import userAccessSaga from './AccessManagement/UserAccess/userAccessSaga';
import tableConfigSaga from './Admin/tableConfigSaga';

export default [
  applicationConfigSaga,
  blobMetricsSaga,
  configurationSaga,
  dashboardSaga,
  solmanStagingConfigSaga,
  columnAlertConfigSaga,
  exceptionConfigSaga,
  scheduleConfigSaga,
  filReplaySaga,
  logExceptionSearchSaga,
  replayConfigSaga,
  tableConfigSaga,
  savedSearchCriteriaSaga,
  userAccessSaga,
  userProfileSaga,
  logReportConfigSaga,
  exceptionReportConfigSaga,
  reportConfigSaga,
  groupAccessSaga
];
