import {
  APPLICATION_DOMAIN_KEY,
  APPLICATION_NAME_KEY,
  BUSINESS_ID_KEY,
  BUSINESS_ID_TWO_KEY,
  COMPONENT_NAME_KEY,
  EXCEPTION_CATEGORY_KEY,
  EXCEPTION_SEVERITY_KEY,
  EXCEPTION_STATUS_KEY,
  EXCEPTION_TYPE_KEY,
  HOST_NAME_KEY,
  SERVICE_NAME_KEY,
  STATUS_KEY,
  TIMEMARK_KEY,
  TRANSACTION_DOMAIN_KEY,
  TRANSACTION_ID_KEY,
  TRANSACTION_TYPE_KEY
} from '../KeyLabels/commonKeyConstants';
import {
  APPLICATION_DOMAIN_LABEL,
  APPLICATION_NAME_LABEL,
  BUSINESS_ID_LABEL,
  BUSINESS_ID_TWO_LABEL,
  CATEGORY_LABEL,
  COMPONENT_NAME_LABEL,
  HOST_NAME_LABEL,
  REPLAY_STATUS_LABEL,
  SERVICE_NAME_LABEL,
  SEVERITY_LABEL,
  STATUS_LABEL,
  TIME_OCCURED_LABEL,
  TRANSACTION_DOMAIN_LABEL,
  TRANSACTION_ID_LABEL,
  TRANSACTION_TYPE_LABEL,
  TYPE_LABEL
} from '../KeyLabels/labelConstants';

export const ExceptionColumn = {
  mandatoryFields: [
    {
      label: APPLICATION_NAME_LABEL,
      value: APPLICATION_NAME_KEY
    },
    {
      label: TIME_OCCURED_LABEL,
      value: TIMEMARK_KEY
    }
  ],
  fields: [
    { label: COMPONENT_NAME_LABEL, value: COMPONENT_NAME_KEY },
    { label: SERVICE_NAME_LABEL, value: SERVICE_NAME_KEY },
    { label: STATUS_LABEL, value: STATUS_KEY },
    { label: HOST_NAME_LABEL, value: HOST_NAME_KEY },
    { label: BUSINESS_ID_LABEL, value: BUSINESS_ID_KEY },
    { label: BUSINESS_ID_TWO_LABEL, value: BUSINESS_ID_TWO_KEY },
    { label: TRANSACTION_ID_LABEL, value: TRANSACTION_ID_KEY },
    { label: APPLICATION_DOMAIN_LABEL, value: APPLICATION_DOMAIN_KEY },
    { label: TRANSACTION_DOMAIN_LABEL, value: TRANSACTION_DOMAIN_KEY },
    { label: TRANSACTION_TYPE_LABEL, value: TRANSACTION_TYPE_KEY },
    { label: REPLAY_STATUS_LABEL, value: EXCEPTION_STATUS_KEY },
    { label: CATEGORY_LABEL, value: EXCEPTION_CATEGORY_KEY },
    { label: TYPE_LABEL, value: EXCEPTION_TYPE_KEY },
    { label: SEVERITY_LABEL, value: EXCEPTION_SEVERITY_KEY }
  ]
};
