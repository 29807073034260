import { produce } from 'immer';
import { SET_SCHEDULE_CONFIG_LIST } from '../../../actions/ActionTypes/Admin/scheduleConfigTypes';
import { processOperation } from '../../../../helpers/reducerHelper';

export interface ScheduleConfigState {
  scheduleConfigList: any[]
}

const initialState = {
  scheduleConfigList: []
};

const scheduleConfigReducer = (state = initialState, action: any): ScheduleConfigState =>
  produce(state, (draft: ScheduleConfigState) => {
    switch (action.type) {
      case SET_SCHEDULE_CONFIG_LIST:
        draft.scheduleConfigList = processOperation(action.data, [...draft.scheduleConfigList]);
        break;
    }
  });

export default scheduleConfigReducer;
