import styled, { css } from 'styled-components';
const BasicTextStyle = css`
  font-size: var(--font-size-sm);
`;

export const defaulTabButtonStyle = css`
  color: var(--blue-color);
  margin-right: 10px;
  .anticon {
    font-size: var(--font-size-md);
    margin-right: 3px;
  }
  &:hover,
  &:focus,
  &:active {
    color: var(--blue-color);
  }
`;

export const DataMainContentWrapper = styled.div`
  padding: 5px 10px;
  font-size: var(--font-size-sm);
`;

export const DataTopContentWrapper = styled.div`
  float: right;
  padding: 10px;
`;

export const TabButton = styled.a`
  ${defaulTabButtonStyle}
`;

export const TabContentWrapper = styled.div`
  padding: 5px 15px;
`;

export const TabContentHeader = styled.div`
  text-align: center;
  font-weight: 600;
  padding: 5px 2px;
`;

export const DataLabelWrapper = styled.span`
  ${BasicTextStyle}
  color: var(--gray-800);
`;
export const DataValueWrapper = styled.span`
  ${BasicTextStyle}// color:var(--black-100);
`;
