import React from 'react';
import type { FC } from 'react';
import textConstants from '../../../constants/textConstants';
import { AdminMainLayoutWrapper, AdminMainBreadCrumbWrapper } from '../../../assets/styles/commonStyled';
import { ConfigDetailWrapper } from '../../../components/AdditionalComponents/DetailSummaryPage/Admin/style';
import ConfigDetailCardHeader from '../../../components/AdditionalComponents/ConfigDetailCardHeader';
import VolumeMetricsForm from '../../../components/AdditionalComponents/UpsertForms/Admin/VolumeMetrics';

const VolumeMetrics: FC = () => {
  return (
    <AdminMainLayoutWrapper data-testid="volumeMetrics">
      <AdminMainBreadCrumbWrapper>
        {textConstants.VOLUME_METRICS_TITLE}
      </AdminMainBreadCrumbWrapper>
      <ConfigDetailWrapper>
        <ConfigDetailCardHeader
          isDeleteEnabled={false}
          isEditEnabled={false}
          title={textConstants.VOLUME_METRICS_FORM_TITLE}
        />
        <VolumeMetricsForm />
      </ConfigDetailWrapper>
    </AdminMainLayoutWrapper>
  );
};

export default VolumeMetrics;
