import React, { useState, useEffect, forwardRef } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { Button, Layout } from 'antd';
import {
  AgGridReactComponent,
  HeaderLeftContentWrapper,
  HeaderRightContentWrapper,
  TableHeaderWrapper,
  HeaderSearchWrapper,
  LayoutWrapper,
  CommonTableMainWrapper,
  HeaderTableTitleWrapper,
  TableSeparateHeaderWrapper
} from './style';
import { findIfContainsInArrayUniversalSearch } from '../../../helpers/commonHelper';
import { getCachedTableFilterData, setKeyDataInLocalStorage } from '../../../helpers/localStorageHelper';
import SingleColumnFilterPopover from '../SingleColumnFilterPopover';
import Loader from '../Loader';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { CONFIG_TABLE_SEARCH_FILTER_KEY } from '../../../constants/KeyLabels/commonKeyConstants';
import textConstants from '../../../constants/textConstants';
import { SearchInput } from '../../../assets/styles/commonStyled';

const { Content } = Layout;

interface FilterByType {
  fieldName: string
  fieldLabel: string
}

interface componentProps {
  rowData?: any
  unSortIcon?: boolean
  hasTotalCount?: boolean
  height?: string
  headerRightMenuActions?: any
  columnDefs?: any
  isApplicationFilter?: boolean
  isColSizeAutoFit?: boolean
  isDataLoading?: boolean
  hasDefaultSearchFilter?: boolean
  filterBy?: FilterByType
  filterDataList?: any[]
  frameworkComponents?: any
  showSearchBar?: boolean
  showResultsCount?: boolean
  totalRecordCount?: number
  headerHeight?: number
  className?: string
  getRowId?: any
  ref?: any
  pagination?: boolean
  rowSelection?: any
  rowMultiSelectWithClick?: boolean
  onRowDoubleClicked?: any
  onRowDataChange?: any
  onSelectionChanged?: any
  onUpdateRowSelection?: () => void
  getRowClass?: any
  onGridReady?: any
  tableTitle?: string
  searchKeyName?: string
  showHeaderFilterButton?: boolean
  tableSeparateHeader?: string
  tableHeaderClass?: string
  isFilterColDefaultChecked?: boolean
  allowSearchFilterCache?: boolean
  accentedSort?: boolean
}

const CommonTableComponent: FC<componentProps> = forwardRef(function CommonTableComponent ({
  allowSearchFilterCache,
  className,
  filterBy,
  filterDataList,
  hasTotalCount,
  height,
  headerHeight,
  headerRightMenuActions,
  isApplicationFilter,
  isDataLoading,
  isFilterColDefaultChecked,
  pagination,
  rowData,
  unSortIcon,
  searchKeyName,
  showHeaderFilterButton,
  showResultsCount,
  showSearchBar,
  tableSeparateHeader,
  tableTitle,
  totalRecordCount,
  tableHeaderClass,
  onRowDataChange,
  onRowDoubleClicked,
  onUpdateRowSelection,
  hasDefaultSearchFilter,
  ...otherProps
}, ref) {
  const defaultColDef: Record<string, boolean> = {
    unSortIcon: unSortIcon ?? false,
    sortable: false,
    filter: false,
    resizable: true
  };
  const cachedFilter = searchKeyName ? getCachedTableFilterData(searchKeyName) : null
  const [filterValues, setFilterValues] = useState<any>(cachedFilter?.searchValue ?? null);
  const [isFilterPopoverOpen, setFilterPopoverVisibility] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<any>(cachedFilter?.appliedFilters ?? {});
  const [tableData, setTableData] = useState<any[]>([]);

  const filterTableData = (selectedItem: any): any[] => {
    const filteredTableData = rowData.filter(
      (item: any) => {
        if (filterBy && item[filterBy?.fieldName].constructor === Array) {
          return item[filterBy?.fieldName].some((item: any) => {
            return item in selectedItem && selectedItem[item]
          });
        }

        return filterBy && item[filterBy?.fieldName] in selectedItem && selectedItem[item[filterBy?.fieldName]];
      }
    );

    return filteredTableData;
  };

  const filterData = (): void => {
    let newFilteredData = rowData;

    if (filterValues !== null) {
      if (Object.keys(appliedFilters).length > 0) {
        newFilteredData = filterTableData(appliedFilters);
      }

      newFilteredData = findIfContainsInArrayUniversalSearch(newFilteredData, filterValues.trim());
    }

    if (searchKeyName && allowSearchFilterCache) {
      setKeyDataInLocalStorage(CONFIG_TABLE_SEARCH_FILTER_KEY, {
        ...getCachedTableFilterData(),
        [searchKeyName]: {
          searchValue: filterValues,
          appliedFilters
        }
      });
    }
    setTableData(newFilteredData);
  };

  const onSearchDebounce = debounce((value) => setFilterValues(value), 350);

  const onSearchInputChange = (value: string): void => {
    onSearchDebounce(value);
  }

  const onFilterApply = (data: any[], filters: any): void => {
    let filteredData = [...data];

    if (filterValues !== null) {
      filteredData = findIfContainsInArrayUniversalSearch(data, filterValues.trim());
    }

    setTableData(filteredData);
    setAppliedFilters(filters);

    if (searchKeyName && allowSearchFilterCache) {
      setKeyDataInLocalStorage(CONFIG_TABLE_SEARCH_FILTER_KEY, {
        ...getCachedTableFilterData(),
        [searchKeyName]: {
          searchValue: filterValues,
          appliedFilters: filters
        }
      });
    }
  };

  useEffect(() => {
    setTableData(rowData);
  }, [rowData]);

  useEffect(() => {
    if (!hasDefaultSearchFilter) {
      filterData();
    }
  }, [filterValues, rowData]);

  useEffect(() => {
    if (onRowDataChange) {
      onRowDataChange(tableData?.length);
    }

    if (onUpdateRowSelection) {
      onUpdateRowSelection();
    }
  }, [tableData]);

  return (
    <>
      <LayoutWrapper>
        {isDataLoading && (
          <Loader
            isOverlayLoader={false}
            loadingMessage={textConstants.DATA_LOADING_MESSAGE}
            wrapperClassName='table-data-loader'
            size='small'
          >
            <></>
          </Loader>
        )}
        {
          tableSeparateHeader &&
          <TableSeparateHeaderWrapper>
            <HeaderTableTitleWrapper>{tableSeparateHeader}</HeaderTableTitleWrapper>
          </TableSeparateHeaderWrapper>
        }
        {
          (showResultsCount ?? showSearchBar) &&
          <TableHeaderWrapper className={tableHeaderClass}>
            <HeaderLeftContentWrapper>
              {
                tableTitle &&
                <HeaderTableTitleWrapper>{tableTitle}</HeaderTableTitleWrapper>
              }
              {
                showResultsCount &&
                <div>{hasTotalCount ? `Showing ${tableData?.length ?? 0} Records` : `Showing ${rowData?.length} of ${totalRecordCount}`}</div>
              }
              {
                showSearchBar &&
                (
                  <HeaderSearchWrapper>
                    <SearchInput
                      placeholder='Search'
                      onChange={({ target }) => onSearchInputChange(target.value)}
                      data-testid="searchInput"
                      prefix={<SearchOutlined />}
                      defaultValue={filterValues ?? ''}
                      allowClear
                    />
                  </HeaderSearchWrapper>
                )
              }
            </HeaderLeftContentWrapper>
            <HeaderRightContentWrapper>
              {
                showHeaderFilterButton &&
                (
                  <SingleColumnFilterPopover
                    filterBy={filterBy ?? { fieldName: '', fieldLabel: '' }}
                    handlePopoverVisibility={(isOpen: boolean) => setFilterPopoverVisibility(isOpen)}
                    isOpen={isFilterPopoverOpen}
                    onFilterApply={onFilterApply}
                    appliedFilters={appliedFilters}
                    tableData={tableData ?? []}
                    initialTableData={rowData ?? []}
                    isApplicationFilter={isApplicationFilter}
                    isFilterColDefaultChecked={isFilterColDefaultChecked}
                    dataList={filterDataList}
                  >
                    <Button
                      data-testid="filterDataButton"
                      className='btn-regular order-3'
                      type="text"
                      onClick={() => setFilterPopoverVisibility(!isFilterPopoverOpen)}
                    >
                      <FilterOutlined /> {textConstants.FILTER_RESULT_BUTTON_LABEL}
                    </Button>
                  </SingleColumnFilterPopover>
                )}
                {headerRightMenuActions}
            </HeaderRightContentWrapper>
          </TableHeaderWrapper>
        }
        <Content>
          <CommonTableMainWrapper>
            <div className={'ag-theme-material'} style={{ height }}>
              <AgGridReactComponent
                {...otherProps}
                {...(hasDefaultSearchFilter && { quickFilterText: filterValues })}
                suppressDragLeaveHidesColumns
                ref={otherProps.ref}
                rowData={tableData}
                headerHeight={headerHeight === 0 ? 0 : headerHeight}
                pagination={pagination}
                defaultColDef={defaultColDef}
                className={`${String(className)} ${(headerHeight === 0 ? ' no-header-border' : '')}`}
                onRowDoubleClicked={onRowDoubleClicked}
                onGridReady={(params) => {
                  if (otherProps.onGridReady) {
                    otherProps.onGridReady(params)
                  }
                }}
                overlayLoadingTemplate={'<div className=\'ag-overlay-loading-center\'><span className=\'ag-grid-table-loader-text\'>Fetching data from server...</span></div>'}
              />
            </div>
          </CommonTableMainWrapper>
        </Content>
      </LayoutWrapper>
    </>
  );
});

CommonTableComponent.defaultProps = {
  hasTotalCount: false,
  isApplicationFilter: false,
  hasDefaultSearchFilter: false,
  isFilterColDefaultChecked: true,
  allowSearchFilterCache: true
}

CommonTableComponent.propTypes = {
  allowSearchFilterCache: PropTypes.bool,
  rowData: PropTypes.array,
  unSortIcon: PropTypes.bool,
  hasTotalCount: PropTypes.bool,
  height: PropTypes.string,
  columnDefs: PropTypes.any,
  filterBy: PropTypes.shape({
    fieldName: PropTypes.string.isRequired,
    fieldLabel: PropTypes.string.isRequired
  }),
  filterDataList: PropTypes.array,
  showSearchBar: PropTypes.bool,
  showResultsCount: PropTypes.bool,
  totalRecordCount: PropTypes.number,
  headerHeight: PropTypes.number,
  headerRightMenuActions: PropTypes.node,
  className: PropTypes.string,
  getRowId: PropTypes.any,
  isApplicationFilter: PropTypes.bool,
  hasDefaultSearchFilter: PropTypes.bool,
  isDataLoading: PropTypes.bool,
  isFilterColDefaultChecked: PropTypes.bool,
  ref: PropTypes.any,
  pagination: PropTypes.bool,
  rowSelection: PropTypes.any,
  onRowDoubleClicked: PropTypes.any,
  onRowDataChange: PropTypes.any,
  onSelectionChanged: PropTypes.any,
  onUpdateRowSelection: PropTypes.func,
  getRowClass: PropTypes.any,
  onGridReady: PropTypes.any,
  tableTitle: PropTypes.string,
  searchKeyName: PropTypes.string,
  showHeaderFilterButton: PropTypes.bool,
  tableSeparateHeader: PropTypes.string,
  tableHeaderClass: PropTypes.string
};

export default CommonTableComponent;
