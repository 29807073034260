import { useState, useEffect, useRef } from 'react';

export default function useDetectOutsideClick (initialIsVisible: boolean): any {
  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(initialIsVisible);
  const ref: React.Ref<any> = useRef(null);

  const handleClickOutside = (event: any): void => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    setIsComponentVisible(initialIsVisible);
  }, [initialIsVisible]);

  return { ref, isComponentVisible, setIsComponentVisible } as const;
}
