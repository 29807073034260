import { formatBytes } from '../../../helpers/commonHelper';
import BlobMetricTypeTagRenderer from '../customRendererForColumns/BlobMetricTypeTagRenderer';

export const BLOB_METRICS_SUMMARY_COLUMNS = [
  {
    field: 'application',
    headerName: 'Application',
    flex: 1,
    sortable: true,
    filter: true
  },
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    sortable: true
  },
  {
    field: 'count',
    headerName: 'Count',
    flex: 1,
    sortable: true
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 1,
    cellRenderer: BlobMetricTypeTagRenderer,
    sortable: true
  }
];

export const BLOB_METRICS_DETAIL_COLUMNS = [
  {
    field: 'application_nm',
    headerName: 'Application',
    flex: 1,
    sortable: true,
    filter: true
  },
  {
    field: 'date_text',
    headerName: 'Date',
    flex: 1,
    sortable: true
  },
  {
    field: 'data_size',
    cellRenderer: (params: any) => {
      return formatBytes(params?.value);
    },
    headerName: 'Data Size',
    flex: 1,
    sortable: true
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 1,
    cellRenderer: BlobMetricTypeTagRenderer,
    sortable: true
  }
];
