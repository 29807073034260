import React, { useRef } from 'react';
import type { FC } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import LoadingBar from 'react-top-loading-bar';
import dayjs from 'dayjs';
import type { LoadingBarRef } from 'react-top-loading-bar';
import FloatingLabel from '../../../../common/FloatingLabel';
import { exportAPIRequestHandler } from '../../../../../services/logExceptionServiceHandler';
import apiEndpointConstants from '../../../../../constants/apiEndpointConstants';
import { applicationDataHelper } from '../../../../../helpers/applicationDataHelper';
import { volumeMetricTypeOptions } from '../../../../../constants/DropDownOptions/adminConfig';
import textConstants from '../../../../../constants/textConstants';

const VolumeMetricsForm: FC = () => {
  const [form] = Form.useForm();
  const loadingBarRef = useRef<LoadingBarRef>(null);

  const applicationNames = applicationDataHelper.getApplicationNames([]);

  const onExport = (): void => {
    form
      .validateFields()
      .then(async (formData) => {
        const reqPayload = {
          application_name: formData.application_name,
          start_date: dayjs(formData.start_date).format('YYYY-MM-DD'),
          end_date: dayjs(formData.end_date).format('YYYY-MM-DD')
        };

        exportAPIRequestHandler(
          reqPayload,
          `${apiEndpointConstants.LOG_EXCEPTION_EXPORT_VOLUME_METRICS_REPORT}${formData.metricType}`,
          false,
          `Volume-metrics-${formData.metricType}`,
          loadingBarRef
        );
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <LoadingBar
        ref={loadingBarRef}
        containerClassName="fixed-progress-bar"
        height={3}
        color="#23A0DD"
      />
      <Form
        form={form}
        data-testid="VolumeMetricAnalysisForm"
        className="form-lg"
        initialValues={{
          metricType: 'log'
        }}
      >
        <Row gutter={{ lg: 16, xl: 16, xxl: 26 }}>
          <Col xs={24} md={5} xl={5} xxl={4}>
            <Form.Item name="application_name" rules={[{ required: true, message: 'Application Name is mandatory!' }]}>
              <FloatingLabel
                label='Application Name'
                type='select'
                id='applicationName'
                options={applicationNames}
                required
                showSearch
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={5} xl={5} xxl={4}>
            <Form.Item name="metricType" rules={[{ required: true, message: 'Volume Metric Type is mandatory!' }]}>
              <FloatingLabel
                label='Volume Metric Type'
                type='select'
                id='metricTypeName'
                options={volumeMetricTypeOptions}
                required
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={5} xl={5} xxl={4}>
            <Form.Item name="start_date" rules={[{ required: true, message: 'Start Date is mandatory!' }]}>
              <FloatingLabel
                label='Start Date'
                type='date'
                id='start_date'
                required
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={5} xl={5} xxl={4}>
            <Form.Item name="end_date" rules={[{ required: true, message: 'End Date is mandatory!' }]}>
              <FloatingLabel
                label='End Date'
                type='date'
                id='end_date'
                required
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={4} xl={4} xxl={4}>
            <Button
              icon={<DownloadOutlined />}
              type="primary"
              onClick={onExport}
              className='btn-lg-icon'
            >
              {textConstants.EXPORT_BUTTON_LABEL}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default VolumeMetricsForm;
