export const PAGE_ID_KEY = 'pageID';
// Column keys
export const LOG_ID_KEY = 'log_id';
export const EXCEPTION_ID_KEY = 'exception_id';
export const APPLICATION_NAME_KEY = 'application_nm';
export const TIMEMARK_KEY = 'timemark';
export const APPLICATION_NAME = 'applicationName';

export const APPLICATION_DOMAIN_KEY = 'application_domain';
export const COMPONENT_NAME_KEY = 'component_nm';
export const BUSINESS_ID_KEY = 'business_id';
export const BUSINESS_ID_TWO_KEY = 'business_id_two';
export const SERVICE_NAME_KEY = 'service_nm';
export const TRANSACTION_ID_KEY = 'trans_id';
export const TRANSACTION_TYPE_KEY = 'trans_type';
export const HOST_NAME_KEY = 'host_nm';
export const TRANSACTION_DOMAIN_KEY = 'trans_domain';
export const STATUS_KEY = 'log_status';
export const TIME_DURATION_KEY = 'time_duration';
export const LOG_LEVEL_KEY = 'log_level';
export const TRANSACTION_BEFORE_KEY = 'trans_before';
export const TRANSACTION_AFTER_KEY = 'trans_after';

// Exception Column Keys
export const ERROR_CODE_KEY = 'error_code';
export const MSG_KEY = 'msg';
export const EXCEPTION_STATUS_KEY = 'exception_status';
export const RESOLUTION_DESCRIPTION_KEY = 'resolution_description';
export const REPLAY_DESTINATION_KEY = 'reply_destination';
export const REPLAY_COUNT_KEY = 'replay_count';
export const EXCEPTION_CATEGORY_KEY = 'exception_category';
export const ISSUE_GROUP_KEY = 'issue_group';
export const EXCEPTION_TYPE_KEY = 'exception_type';
export const EXCEPTION_SEVERITY_KEY = 'exception_severity';
export const TRANSACTION_DATA_KEY = 'trans_data';
export const DUMP_ANALYSIS_KEY = 'dump_analysis';

export const MSG_PAIR_KEY = 'msg_pairs';
export const JMS_HEADER_KEY = 'jms_header';
export const RESOLVED_KEY = 'resolved';
export const LOG_MSG_KEY = 'log_msgs';
export const DATA_ENCODING_KEY = 'data_encoding';

// JMS
export const JMS_DESTINATION_KEY = 'JMSDestination';
export const JMS_REPLY_TO_KEY = 'JMSReplyTo';
export const JMS_DELIVERY_MODE_KEY = 'JMSDeliveryMode';
export const JMS_MESSAGE_ID_KEY = 'JMSMessageID';
export const JMS_TIMESTAMP_KEY = 'JMSTimestamp';
export const JMS_EXPIRATION_KEY = 'JMSExpiration';
export const JMS_REDELIVERED_KEY = 'JMSRedelivered';
export const JMS_PRIORITY_KEY = 'JMSPriority';
export const JMS_CORRELATION_ID_KEY = 'JMSCorrelationID';
export const JMS_TYPE_KEY = 'JMSTypeo';

// JMSX
export const JMSX_USER_ID_KEY = 'JMSXUserID';
export const JMSX_APP_ID_KEY = 'JMSXAppID';
export const JMSX_PRODUCER_TIXID_KEY = 'JMSXProducerTIXID';
export const JMSX_CONSUMER_TXID_KEY = 'JMSXConsumerTXID';
export const JMSX_RCV_TIMESTAMP_KEY = 'JMSXRcvTimestamp';
export const JMSX_DELIVERY_COUNT_KEY = 'JMSXDeliveryCount';
export const JMSX_GROUP_ID_KEY = 'JMSXGroupID';
export const JMSX_GROUP_SEQ_KEY = 'JMSXGroupSeq';

export const LOG_TABLE_KEY = 'logTable';
export const EXCEPTION_TABLE_KEY = 'exceptionTable';

export const SEARCH_CRITERIA_ID = 'searchCriteriaId';

export const REPLAY_ID = 'replayID';
export const COLUMN_ALERT_ID = 'columnAlertId';
export const COLUMN_ALERT_KEY = 'columnAlertKey';
export const EXCEPTION_ID = 'exceptionConfigId';
export const REPORT_CONFIG_ID = 'reportConfigId';
export const TABLE_CONFIG_ID = 'tableName';
export const APPLICATION_NAME_ID = 'applicationName';
export const LOG_REPORT_ID = 'logConfigId';
export const EXCEPTION_REPORT_ID = 'configID';
export const EXCEPTION_CATEGORY_ID = 'categoryID';
export const EXCEPTION_TYPE_ID = 'typeID';
export const EXCEPTION_SEVERITY_ID = 'severityID';

// Blob Metrics Summary Tab Keys
export const BLOB_METRIC_SUMMARY_TAB_KEY = 'blobMetricSummary';
export const BLOB_METRIC_DETAILS_TAB_KEY = 'blobMetricDetails';

// Dashboard Tab Keys
export const LOG_METRIC_TAB_KEY = 'logMetrics';
export const REPLAY_METRIC_TAB_KEY = 'replayMetrics';
export const EXCEPTION_METRIC_TAB_KEY = 'exceptionMetrics';

// Report Config Tab Keys
export const REPORT_CONFIG_TAB_KEY = 'reportConfig';
export const LOG_REPORT_CONFIG_TAB_KEY = 'logReportConfig';
export const EXCEPTION_REPORT_CONFIG_TAB_KEY = 'exceptionReportConfig';

// Group Access Tab Keys
export const USER_LIST_TAB_KEY = 'userList';
export const APPLICATION_ACCESS_LIST_TAB_KEY = 'applicationAccessList';

// keys for recent search
export const LOG_RECENT_SEARCH_KEY = 'logRecentSearchList';
export const EXCEPTION_RECENT_SEARCH_KEY = 'exceptionRecentSearchList';

// auditLog
export const AUDIT_LOG_KEY = 'audit_logs';

// user object related keys
export const USER_ID_KEY = 'userID';
export const USER_ADMIN_ACCESS_KEY = 'adminAccess';

export const GROUP_CONFIG_KEY = 'groupName';
export const GROUP_APPLICATION_ACCESS_KEY = 'groupAccessId';

// searchQuery
export const DATE_TIME_KEY = 'dateTime';

// Admin Search/Filter Config Key
export const CONFIG_TABLE_SEARCH_FILTER_KEY = 'gridFilterConfig';

// Application Exception Key
export const EXCEPTION_CATEGORY_NAME_KEY = 'exceptionCategoryName';
export const EXCEPTION_SEVERITY_NAME_KEY = 'exceptionSeverityName';
export const EXCEPTION_TYPE_NAME_KEY = 'exceptionTypeName';
