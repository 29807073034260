import React, { useState, useLayoutEffect } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import type { MenuItemGroupType } from 'antd/es/menu/hooks/useItems';
import UserSettingsMenu from './UserSettingsMenu';
import routeConstants from '../../constants/routeConstants';
import { menuItems, adminMenuItems, nonAdminMenuItems } from './menuItems';
import logo from '../../assets/images/pepsiCo-logo.png';
import { HeaderWrapper, Hamburger, LogoWrapper, LogoImg, AntdMenu } from './style';
import useWindowSize from '../CustomHooks/WindowSize';

interface NavMenuType extends MenuItemGroupType {
  path: string
}

const Header: FC = () => {
  const [screenWidth] = useWindowSize();
  const history = useHistory();
  const userAccessInfo = useSelector((state: any) => state.userAccess?.userAccessInfo);
  const [navItems, setNavItems] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [activeMenu, setActiveMenu] = useState('dashboard');

  /**
   * Method to handle Menu Item Click Navigation.
   * @param {event} evt
   */
  const onMenuItemClick = (evt: any): void => {
    setActiveMenu(evt.key);
    history.push(evt.key);
  };

  /**
   * Method to handle Menu Collapse on Hamburger Icon Click.
   */
  const toggleMenuCollapsed = (): void => {
    setCollapsed(!collapsed);
  };

  useLayoutEffect(() => {
    let clonedMenu: any = [...menuItems];
    let path = history.location.pathname;

    if (userAccessInfo?.userID !== null) {
      if (userAccessInfo?.adminAccess === 'Y') {
        clonedMenu = [...clonedMenu, ...adminMenuItems];
      } else {
        clonedMenu = [...clonedMenu, ...nonAdminMenuItems];
      }
      if (userAccessInfo?.bulkReply === 'N') {
        clonedMenu = clonedMenu.filter((item: NavMenuType) => item.key !== '/file-replay');
      }
    }

    if (path.includes('detail') || path.includes('new-')) {
      path = path.substring(0, path.lastIndexOf('/'))
    }

    setCollapsed(true);
    setActiveMenu(path);
    setNavItems(clonedMenu);
  }, [userAccessInfo]);

  return (
    <HeaderWrapper theme='light'>
      <LogoWrapper>
        <Hamburger onClick={toggleMenuCollapsed} data-testid="hamburgerIcon" />
        <Link to={routeConstants.LOG_SEARCH}>
          <LogoImg src={logo} className="w-100" alt="cle-logo" />
        </Link>
      </LogoWrapper>
      <AntdMenu
        mode="horizontal"
        selectedKeys={[activeMenu]}
        defaultSelectedKeys={[activeMenu]}
        key={`menu-${navItems.length}`}
        items={navItems}
        onClick={onMenuItemClick}
        theme='light'
        className={`${screenWidth < 992 && collapsed ? 'display-none' : ''}`}
        triggerSubMenuAction='click'
        disabledOverflow
      />
      <UserSettingsMenu />
    </HeaderWrapper>
  );
};

export default Header;
