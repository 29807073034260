import styled, { css } from 'styled-components';
import { Checkbox, Col, Radio, Row, Form } from 'antd';
import CheckOutlinedIconImg from '../../../assets/images/check_circle_outline.png';

export const DetailCardItemStyle = css`
    padding: 2px;
    font-size: var(--font-size-sm);
    margin-bottom: 2px;
`;

export const ApplicationStickyWrapper = styled.div`
    position: sticky;
    top: -1px;
    background: var(--white);
    z-index: 999;
`;

export const ApplicationAccessMainWrapper = styled.div`
    margin: 5px 0px;
    border-top: 1px solid var(--gray-275);
    padding: 5px 0px;
    color: var(--black);

    .ant-form-item {
        label {
            flex: 1;
            justify-content: center;
        }

        .ant-form-item-explain-error:first-letter {
            text-transform: uppercase;
        }
    }

    & .ant-row {
        width: 100%;
        align-items: center;

        .ant-select-selection-placeholder {
            align-items: center;
            display: flex;
            padding-inline-start: 5px;
        }
    }

    &.application-access {
        > .ant-row {
            padding: 0 15px;
        }
    }

    .ant-form-item.form-item-view-wrapper {
        margin-bottom: 0;

        @media screen and (min-width: 1600px) {
            margin-bottom: 5px;
        }
    }

    .application-name-col {
        font-size: var(--font-size-md);
    }
`;
export const ApplicationAccessNoApplicationWrapper = styled.div`
    min-height: 20vh;
    justify-content: center;
    align-items: center;
    display: flex;
    a {
        font-size: var(--font-size-lg);
        font-weight: var(--font-weight-semibold);
        .anticon {
            font-size: var(--font-size-lg);
            margin-right: 5px;
        }
    }
`;
export const ApplicationAccessTableHeaderWrapper = styled.div`
    padding: 7px 15px;
    background: #e5f5fc;
    border: 1px solid #9ddfff;
    color: #0047ba;
    font-weight: var(--font-weight-semibold);
    border-radius: 3px;
    font-size: var(--font-size-md);
    margin-bottom: 10px;
    .ant-col:first-child {
        text-align: left;
    }
    .ant-col {
        text-align: center;
        font-size: var(--font-size-md);
    }
    &.group-selection-drawer {
        .ant-col {
            text-align: left;
            padding-left: 15px;
        }
    }
`;

export const ApplicationAccessHeaderWrapper = styled.div`
    padding: 0 0 7px;

    @media screen and (min-width: 1600px) {
        padding: 0 0 10px;
    }
`;

export const ApplicationAccessSubHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .ant-input-affix-wrapper {
        max-width: 235px;
        border-color: var(--form-field-border-color);
        padding: 7px 10px;

        &:focus {
            box-shadow: none;
        }

        .ant-input {
            padding: 2px 3px 0;
        }

        @media screen and (min-width: 1600px) {
            padding: 9px 10px;
        }
    }
`;

export const ApplicationHeaderRightContainer = styled.div`
    display: flex;
    max-width: 500px;
    width: 100%;
    justify-content: flex-end;
    align-items: center;

    .ant-input-group-wrapper {
        max-width: 285px;

        .ant-btn-primary {
            background-color: var(--primary-color);
            border-color: var(--primary-color);

            &:not(:disabled):not(.ant-btn-disabled):hover {
                background-color: var(--primary-color);
                border-color: var(--primary-color);
            }
        }

        &.ant-input-search
            > .ant-input-group
            > .ant-input-group-addon:last-child
            .ant-input-search-button {
            border-radius: 0;
        }

        .ant-input-wrapper {
            display: flex;
            width: 100%;
            justify-content: end;
        }

        &.ant-input-search {
            .ant-input {
                height: auto;
                font-size: var(--font-size-sm);
                border-color: var(--form-field-border-color);
                padding: 2px 5px 0;
            }

            .ant-btn-loading .anticon-arrow-right {
                opacity: 0;
            }

            .ant-input-affix-wrapper {
                border-start-start-radius: 3px;
                border-end-start-radius: 3px;
                padding: 5px 10px;

                @media screen and (min-width: 1600px) {
                    padding: 9px 10px;
                }
            }

            .ant-input-group-addon {
                flex: 0 0 40px;

                @media screen and (min-width: 1600px) {
                    flex: 0 0 45px;
                }

                .ant-input-search-button {
                    height: 100%;
                    width: 100%;
                }
            }

            &.search-btn-disabled {
                .ant-input-group-addon {
                    cursor: not-allowed;

                    .ant-input-search-button {
                        pointer-events: none;
                    }
                }
            }
        }
    }
`;

export const ApplicationAccessCheckBoxGroup = styled(Checkbox.Group)`
    width: 100%;
    justify-content: space-around;
`;
export const ApplicationAccessRadioGroup = styled(Radio.Group)`
    display: flex;

    @media screen and (min-width: 1600px) {
        .ant-radio-inner {
            width: 20px;
            height: 20px;
        }

        .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
            transform: scale(0.5);
        }
    }

    &.radio-item-view .ant-radio-wrapper {
        pointer-events: none;

        .ant-radio-inner {
            border: none;

            &::before {
                content: '-';
                font-size: var(--font-size-md);
            }
        }


        .ant-radio-checked {
            .ant-radio-inner {
                background: none;
                background-image: url(${CheckOutlinedIconImg});
                background-size: 100%;
                width: 20px;
                height: 20px;
                position: relative;
                left: -6px;

                @media screen and (min-width: 1600px) {
                    width: 25px;
                    height: 25px;
                }

                &::before {
                    content: none;
                }

                &::after {
                    display: none;
                }
            }
        }
    }
    
    .ant-radio-wrapper {
        padding-left: 20px;
    }
`;
export const ApplicationNameTextWrapper = styled.div`
    margin-left: 2rem;
`;
export const ApplicationAccessSummaryRowWrapper = styled(Row)`
    margin: 10px 0px;
    margin-bottom: 15px;

    .application-name.ant-col {
        padding-left: 1.5rem !important;
    }

    .ant-col {
        font-size: var(--font-size-md);
    }
`;
export const ApplicationAccessSummaryAccessColWrapper = styled(Col)`
    text-align: center;

    .anticon-check-circle {
        color: var(--replay-resolved-color);
        font-size: var(--font-size-lg);
    }
`;

export const GroupAssignmentForm = styled(Form)`
    padding: 0 15px;
`;

export const GroupApplicationAccessForm = styled(Form)`
    overflow-x: visible;
`;
