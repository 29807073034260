import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { CloseOutlined, UploadOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import { Progress } from 'antd';
import { formatBytes } from '../../../helpers/commonHelper';
import FileError from '../../../assets/images/file-error.png';
import FileSuccess from '../../../assets/images/file-success.png';
import textConstants from '../../../constants/textConstants';
import {
  FileErrorLabel,
  FileSizeLabel,
  FileUploadDraggerWrapper,
  FileUploadTitle,
  FileUploadDragger,
  FileUploadPreviewIcon,
  FileUploadPreviewDetails,
  FileUploadPreviewWrapper,
  FileUploadSubmitButton
} from './style';

interface DraggableFileUploadProps {
  allowedExtension?: string
  fileUploadTitle?: string
  isMultiUploadAllowed?: boolean
  maxFileSize: number
  onFileUpload: (formData: any) => void
  isUploadCompleted: boolean
  message?: string
}

let interval: number;

const DraggableFileUpload: FC<DraggableFileUploadProps> = ({
  allowedExtension,
  fileUploadTitle,
  onFileUpload,
  isMultiUploadAllowed,
  maxFileSize,
  isUploadCompleted,
  message,
  ...otherProps
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileError, setFileError] = useState<string>('');
  const [uploading, setUploadingStatus] = useState<boolean>(false);
  const [uploadPercent, setUploadPercent] = useState<number>(0);

  const handleBeforeFileUpload = (file: RcFile): boolean => {
    let fileError: string = '';
    const isFileSizeExceeded = file.size / 1024 / 1024 > maxFileSize;

    if (isFileSizeExceeded) {
      fileError = `${textConstants.FILE_REPLAY_SIZE_VALIDATION_ERROR} ${maxFileSize} MB`;
    }

    if (allowedExtension) {
      const validExts = allowedExtension.split(',');
      const fileExt: string = file.name.substring(file.name.lastIndexOf('.'));
      if (!validExts.includes(fileExt)) {
        const acceptedFileStr = validExts.toString();
        fileError = `Invalid file selected, valid files are of  ${acceptedFileStr} types.`;
      }
    }

    setFileError(fileError);

    setFileList([...fileList, file]);

    return false;
  }

  const onFileRemove = (file: UploadFile): void => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const handleUpload = (): void => {
    setUploadingStatus(true);
    const formData = new FormData();
    if (isMultiUploadAllowed) {
      fileList.forEach((file) => {
        formData.append('files[]', file as RcFile);
      });
    } else {
      formData.append('file', fileList[0] as RcFile);
    }

    onFileUpload(formData);
  };

  useEffect(() => {
    if (isUploadCompleted) {
      setUploadPercent(100);
      setFileList([]);
    }
  }, [isUploadCompleted]);

  useEffect(() => {
    if (uploading) {
      interval = window.setInterval(() => {
        setUploadPercent((prev) => prev < 99 ? prev + 1 : prev);
      }, 3);
    } else {
      clearInterval(interval);
    }
  }, [uploading]);

  useEffect(() => {
    if (fileList.length < 1) {
      setUploadPercent(0);
      setUploadingStatus(false);
      clearInterval(interval);
    }
  }, [fileList])

  const renderFileUploadItems = (originNode: React.ReactElement, file: UploadFile<any>): React.ReactNode => {
    return (
      <>
        <div>
          {originNode}
        </div>
        <FileUploadPreviewWrapper>
          <FileUploadPreviewIcon>
            <img src={fileError ? FileError : FileSuccess} alt='file-image' />
          </FileUploadPreviewIcon>
          <FileUploadPreviewDetails>
            <label>{file.name}</label>
            <FileSizeLabel>{formatBytes(file?.size ?? 0)}/1 MB {fileError && <ExclamationCircleFilled />}</FileSizeLabel>
            {uploading && <Progress percent={uploadPercent} size="small" />}
            {fileError && <FileErrorLabel>{fileError}</FileErrorLabel>}
          </FileUploadPreviewDetails>
          {originNode.props.children[1]}
        </FileUploadPreviewWrapper>
        <div className='text-center'>
          <FileUploadSubmitButton
            type="primary"
            onClick={handleUpload}
            disabled={!!fileError}
            size='small'
          >
            {textConstants.SUBMIT_BUTTON_LABEL}
          </FileUploadSubmitButton>
        </div>
      </>
    )
  }

  return (
    <FileUploadDraggerWrapper data-testid="draggableFileUpload">
      <FileUploadDragger
        beforeUpload={handleBeforeFileUpload}
        multiple={isMultiUploadAllowed}
        fileList={fileList}
        onRemove={onFileRemove}
        showUploadList={{
          downloadIcon: 'Download',
          showRemoveIcon: true,
          removeIcon: <CloseOutlined />
        }}
        itemRender={renderFileUploadItems}
        {...(!isMultiUploadAllowed && { maxCount: 1 })}
        {...otherProps}
      >
        <FileUploadTitle>{fileUploadTitle}</FileUploadTitle>
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">Click to upload or Drag and drop it here</p>
        <p className='upload-file-message'>{message}</p>
      </FileUploadDragger>
    </FileUploadDraggerWrapper>
  );
};

DraggableFileUpload.defaultProps = {
  isMultiUploadAllowed: false,
  allowedExtension: ''
};

DraggableFileUpload.propTypes = {
  isMultiUploadAllowed: PropTypes.bool,
  isUploadCompleted: PropTypes.bool.isRequired,
  fileUploadTitle: PropTypes.string,
  maxFileSize: PropTypes.number.isRequired,
  message: PropTypes.string,
  onFileUpload: PropTypes.func.isRequired
};

export default DraggableFileUpload;
