export const ApplicationFormItemsLayout = {
  columnSpan: {
    xs: 24,
    md: 6,
    xxl: 5
  },
  rowGutter: 16
};

export const ApplicationListFormItemsLayout = {
  columnSpan: {
    xs: 24,
    md: 16,
    xxl: 8
  },
  rowGutter: 16
};
