import React, { useEffect } from 'react';
import type { FC } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from '../../components/MainLayout';
import Loader from '../../components/common/Loader';
import {
  getLoggedInUserAccessInfo,
  setOktaAuthenticatedStatus
} from '../../redux/actions/UserProfile/userProfileActions';
import {
  getAllExceptionCategory,
  getAllExceptionType,
  getAllExceptionSeverity,
  getApplicationDetails
} from '../../redux/actions/Admin/applicationConfigActions';
import routeConstants from '../../constants/routeConstants';

interface AuthenticationProps {
  children?: React.ReactNode | undefined
}

const Authentication: FC<AuthenticationProps> = ({ children }) => {
  const { authState } = useOktaAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const dataLoadingCount = useSelector((state: any) => state.commonConfig.dataLoadingCount)
  const userAccessInfo = useSelector((state: any) => state.userAccess?.userAccessInfo);
  const oktaAuthenticatedStatus = useSelector(
    (state: any) => state.userAccess?.oktaAuthenticatedStatus
  );

  useEffect(() => {
    if (authState?.isAuthenticated) {
      if (!oktaAuthenticatedStatus) {
        dispatch(setOktaAuthenticatedStatus(authState.isAuthenticated));
      }

      if (userAccessInfo === null || (userAccessInfo && userAccessInfo.email === null)) {
        dispatch(getLoggedInUserAccessInfo());
      } else {
        if (!sessionStorage.getItem('applicationDetails') && ![routeConstants.LOG_SEARCH, routeConstants.EXCEPTION_SEARCH].includes(location?.pathname)) {
          dispatch(getApplicationDetails());
        }
      }
    }
  }, []);

  useEffect(() => {
    if (userAccessInfo?.userID != null && !sessionStorage.getItem('applicationDetails') && ![routeConstants.LOG_SEARCH, routeConstants.EXCEPTION_SEARCH].includes(location?.pathname)) {
      dispatch(getApplicationDetails());
    }

    if (userAccessInfo?.userID != null && userAccessInfo?.adminAccess === 'Y' && !sessionStorage.getItem('applicationCategoryData')) {
      dispatch(getAllExceptionCategory());
    }

    if (userAccessInfo?.userID != null && userAccessInfo?.adminAccess === 'Y' && !sessionStorage.getItem('applicationTypeData')) {
      dispatch(getAllExceptionType());
    }

    if (userAccessInfo?.userID != null && userAccessInfo?.adminAccess === 'Y' && !sessionStorage.getItem('applicationSeverityData')) {
      dispatch(getAllExceptionSeverity());
    }
  }, [userAccessInfo]);

  return (
    <MainLayout>
      {children}
      {dataLoadingCount > 0 && (
        <Loader
          isOverlayLoader
        />
      )}
    </MainLayout>
  );
};

export default Authentication;
