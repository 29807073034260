import { checkBoxFieldCol } from '../tableCheckboxColumn';

export const LOG_REPORT_CONFIG_COLUMNS = [
  checkBoxFieldCol,
  {
    field: 'applicationName',
    headerName: 'Application',
    flex: 1,
    sortable: true,
    cellRenderer: function (params: any) {
      return params.value?.join(', ');
    }
  },
  {
    field: 'mailID',
    headerName: 'Email',
    flex: 1,
    sortable: true
  },
  {
    field: 'enableNotification',
    headerName: 'Enable Notification',
    flex: 1,
    sortable: true,
    cellRenderer: function (params: any) {
      return params.value ? 'Yes' : 'No';
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    sortable: true,
    cellRenderer: function (params: any) {
      return params.value?.join(', ');
    }
  }
];
