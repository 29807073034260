export const replayConfigIFBlockFormFields = [
  {
    label: 'Replay Config ID',
    name: 'replayID',
    id: 'replayID',
    type: 'text',
    disableOnEdit: true
  },
  {
    label: 'Application Name',
    name: 'applicationName',
    id: 'applicationName',
    required: true,
    rules: [{ required: true, message: 'Application is mandatory!' }],
    type: 'select',
    disableOnEdit: true
  },
  {
    label: 'Application Domain',
    name: 'applicationDomain',
    id: 'applicationDomain',
    required: true,
    rules: [{ required: true, message: 'Application Domain is mandatory!' }],
    type: 'text'
  }
];

export const replayConfigThenFormConfig = {
  replayMode: {
    label: 'Replay Mode',
    name: 'replayMode',
    id: 'replayMode',
    required: true,
    rules: [{ required: true, message: 'Replay Mode is mandatory!' }],
    type: 'select',
    disableOnEdit: true
  },
  replayHost: {
    label: 'Host URL',
    name: 'replayHost',
    id: 'replayHost',
    required: true,
    rules: [{ required: true, message: 'Host URL is mandatory!' }],
    type: 'text'
  },
  username: {
    label: 'User Name',
    name: 'username',
    id: 'username',
    required: true,
    rules: [{ required: true, message: 'User Name is mandatory!' }],
    type: 'text'
  },
  password: {
    label: 'Password',
    name: 'password',
    id: 'password',
    required: true,
    rules: [{ required: true, message: 'Password is mandatory!' }],
    type: 'password'
  },
  description: {
    label: 'Remarks',
    name: 'description',
    id: 'description',
    required: true,
    rules: [{ required: true, message: 'Remarks is mandatory!' }],
    type: 'text'
  }
};

export const getUpdatedReplayConfigThenFormConfig = (replayMode: string): any => {
  return {
    ...replayConfigThenFormConfig,
    ...(replayMode === 'HTTP_OAUTH' && {
      username: {
        ...replayConfigThenFormConfig.username,
        label: 'Client Id',
        rules: [{ required: true, message: 'Client Id is mandatory!' }]
      },
      password: {
        ...replayConfigThenFormConfig.password,
        label: 'Client Secret',
        rules: [{ required: true, message: 'Client Secret is mandatory!' }]
      },
      oktaIssuerUrl: {
        label: 'Token URL',
        name: 'oktaIssuerUrl',
        id: 'oktaIssuerUrl',
        required: true,
        rules: [{ required: true, message: 'Token URL is mandatory!' }],
        type: 'text'
      }
    })
  }
}
