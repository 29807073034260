import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { renderDataBasedOnFormat } from '../../../../helpers/renderDataHelper';
import { DataMainContentWrapper, DataTopContentWrapper, TabButton } from './style';
import { SaveOutlined, CopyOutlined, DownloadOutlined } from '@ant-design/icons';
import { checkIfUserHasAccess, copyDataToClipBoard } from '../../../../helpers/commonHelper';
import { APPLICATION_NAME_KEY } from '../../../../constants/KeyLabels/commonKeyConstants';
import { saveTransactionServiceHandler } from '../../../../helpers/logExceptionDetailHelper';
import { API_SUCCESS_STATUS_CODE } from '../../../../constants/commonConstants';
import { EditButton } from '../../../common/Buttons/buttons';

const LogExceptionTabContentDataRenderer: FC<{
  testId: string
  data?: any
  dataKeyName: string
  hasDownload?: boolean
  isMetricView?: boolean
  logExceptionType?: string
  recordData?: any
  updateItemDetailTransactionData?: any
}> =
  ({ testId, data, dataKeyName, hasDownload, isMetricView, logExceptionType, recordData, updateItemDetailTransactionData }) => {
    const [contentRef, setContentRef] = useState<any>();
    const [transEdit, setTransEdit] = useState(false);

    const downloadData = (): void => {
      saveAs(
        new Blob(
          [data],
          {
            type: 'text/plain;charset=utf-8'
          }
        ),
        `cle_${logExceptionType?.toLowerCase()}_${dataKeyName}.txt`
      );
    };

    const onSaveTransactionHandlerFunction = async (): Promise<any> => {
      const resp = await saveTransactionServiceHandler(recordData, contentRef?.textContent);
      if (resp === API_SUCCESS_STATUS_CODE) {
        updateItemDetailTransactionData(contentRef?.textContent);
        setTransEdit(false);
      }
    }

    return (
      <>
        <DataTopContentWrapper data-testid={`test-id-${testId}`}>
          {
            logExceptionType?.toLowerCase() !== 'log' && !isMetricView &&
            checkIfUserHasAccess(recordData?.[APPLICATION_NAME_KEY], 'edit') &&
            !transEdit &&
            <EditButton onClick={() => {
              setTransEdit(true);
            }} testId={'editTransBtn'} />
          }
          {
            transEdit &&
            <TabButton
              data-testid="saveTransBtn"
              onClick={onSaveTransactionHandlerFunction}
            >
              <SaveOutlined />
              Save
            </TabButton>
          }
          {!hasDownload && (
            <TabButton data-testid="copyClipboardBtn" onClick={() => { copyDataToClipBoard(data) }}>
              <CopyOutlined />
              Copy to Clipboard
            </TabButton>
          )}
          {hasDownload && (
            <TabButton data-testid="downloadBtn" onClick={downloadData}>
              <DownloadOutlined />
              Download
            </TabButton>
          )}
        </DataTopContentWrapper>
        <DataMainContentWrapper>
          {renderDataBasedOnFormat(data, transEdit, 'trans_before_data', setContentRef)}
        </DataMainContentWrapper>
      </>
    );
  };
LogExceptionTabContentDataRenderer.propTypes = {
  testId: PropTypes.string.isRequired,
  data: PropTypes.any,
  dataKeyName: PropTypes.string.isRequired,
  hasDownload: PropTypes.bool,
  isMetricView: PropTypes.bool,
  logExceptionType: PropTypes.string,
  recordData: PropTypes.any,
  updateItemDetailTransactionData: PropTypes.func
};
export default LogExceptionTabContentDataRenderer;
