/**
 * This is Functional  component for App Layout for Secured Components
 */
import React from 'react';
import type { FC } from 'react';
import Header from '../Header';
import { MainLayoutWrapper, MainContentWrapper } from './style';

interface MainLayoutProps {
  children?: React.ReactNode | undefined
}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  return (
    <MainLayoutWrapper>
      <Header />
      <MainContentWrapper>{children}</MainContentWrapper>
    </MainLayoutWrapper>
  );
};

export default MainLayout;
