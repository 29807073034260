import { createStore, compose, applyMiddleware } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import createSagaMiddleware from 'redux-saga';
// import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import rootSaga from '../sagas/rootSaga';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['userAccess']
};

const sagaMiddleware = createSagaMiddleware();

function configureStoreDev (initialState: any): any {
  const middlewares = [
    sagaMiddleware
    // createLogger()
  ];
  const pReducer = persistReducer(persistConfig, rootReducer());
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?? compose; // add support for Redux dev tools
  const store = createStore(
    pReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  /* istanbul ignore next */
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      store.replaceReducer(persistReducer(persistConfig, rootReducer));
    });
  }
  rootSaga.map((saga) => sagaMiddleware.run(saga));
  return store;
}

/* istanbul ignore next */
function configureStoreProd (initialState: any): any {
  const middlewares = [sagaMiddleware];
  const pReducer = persistReducer(persistConfig, rootReducer());
  const store = createStore(
    pReducer,
    initialState,
    compose(applyMiddleware(...middlewares))
  );
  rootSaga.map((saga) => sagaMiddleware.run(saga));
  return store;
}

/* istanbul ignore next */
const configureStore =
  process.env.NODE_ENV === 'production'
    ? configureStoreProd
    : configureStoreDev;

export default configureStore;
