import React from 'react';
import type { ReactElement } from 'react';
import SavedSearchTableList from '../../components/AdditionalComponents/TabContents/RecentSavedSearch/SavedSearch';
import { CardContainerWrapper } from '../../assets/styles/commonStyled';

const ExceptionSavedSearch = (): ReactElement => {
  return (
        <CardContainerWrapper data-testid="test-id-exception-saved-search-wrapper">
            <SavedSearchTableList showDeleteBtn logExceptionType='exception' canOccupyFullScreen />
        </CardContainerWrapper>
  );
};

export default ExceptionSavedSearch;
