import { produce } from 'immer';
import { SET_APPLICATION_CTS_DATA } from '../../../actions/ActionTypes/Admin/applicationConfigTypes';
import { processOperation } from '../../../../helpers/reducerHelper';
type IObjectKeys = Record<string, any>;

export interface InitialStateInterface extends IObjectKeys {
  categories: any[]
  types: any[]
  severities: any[]
}

const initialState = {
  categories: [],
  types: [],
  severities: []
};

const ApplicationListConfigReducer = (state = initialState, action: any): InitialStateInterface =>
  produce(state, (draft: InitialStateInterface) => {
    switch (action.type) {
      case SET_APPLICATION_CTS_DATA:
        draft[action.payload.listType] = processOperation(action.payload, [
          ...draft[action.payload.listType]
        ]);
        break;
    }
  });

export default ApplicationListConfigReducer;
