/**
 * Contains all text, label & other messages constants
 */

const textConstants = {
  BLOB_METRICS_PAGE_TITLE: 'Blob Metrics',
  BLOB_METRIC_ITEM_DETAIL_WARNING: 'You’re unable to access log blob details older than 14 days due to retention policies.',
  BLOB_METRIC_ITEM_WARNING_DETAIL_TITLE: 'Warning',
  CONFIGURE_COLUMN_BUTTON_LABEL: 'Columns',
  DATA_LOADING_MESSAGE: 'Please wait while we are processing your request...',
  DASHBOARD_METRIC_CHART_TITLE: 'Application Log & Exception Metrics',
  DASHBOARD_REPLAY_METRIC_CHART_TITLE: 'Exception Replay Metrics Count',
  DASHBOARD_REPLAY_METRIC_DATA_NOT_FOUND: 'Replay metrics data not available',
  DOWNLOAD_SUCCESS_MESSAGE: 'downloaded successfully',
  DELETE_CONFIRMATION_POPUP_CONFIRM_BUTTON_TEXT: 'Yes, Delete',
  DELETE_CONFIRMATION_POPUP_CANCEL_BUTTON_TEXT: 'No, Cancel',
  DELETE_CONFIRMATION_POPUP_CONTENT_TEXT: 'Are you sure you want to delete the ',
  EXPORT_BUTTON_LABEL: 'Export',
  EXPORT_BUTTON_TOOLTIP_MESSAGE:
        'Select particular records to export. By default all available data will be exported',
  FILTER_RESULT_BUTTON_LABEL: 'Filters',
  LOGOUT_MESSAGE: 'Are you sure you want to Logout?',
  MULTISELECT_SEARCH_INPUT_TITLE: 'Search and select:',
  MULTISELECT_SEARCH_INPUT_PLACEHOLDER: 'Search Application',
  MULTISELECT_RENDER_ALL_LABEL: 'All application',
  MULTISELECT_CLEAR_ALL_LABEL: 'Clear All',
  MULTISELECT_DONE_BUTTON_LABEL: 'DONE',
  MULTISELECT_SEARCH_NO_DATA_FOUND: 'No data found',
  MULTISELECT_SELECT_ALL_LABEL: 'Select All',
  UNAUTHORIZED_USER_MESSAGE: 'Sorry, you are not authorized to access this page.',
  TIMEZONE_CST_LABEL: 'CST',
  TIMEZONE_LOCAL_TIME_LABEL: 'Local',
  TIMEZONE_TIME_LABEL: 'Time',
  CONFIRM_CANCEL_TEXT: 'Cancel',
  CONFIRM_OK_TEXT: 'Confirm',
  CONFIRM_POPUP_TITLE: 'Confirm',
  LOG_SEARCH_PAGE_TITLE: 'Search Logs',
  EXCEPTION_SEARCH_PAGE_TITLE: 'Search Exception',
  EXPORT_DATA_TITLE: 'Export Data',
  FILTER_DATA_TITLE: 'Filter',
  FILTER_DATA_BY_TITLE: 'Filter By',
  FILTER_DATA_SELECT_ALL_LABEL: 'Select All',
  FILTER_DATA_DESELECT_ALL_LABEL: 'De-Select All',
  FILE_REPLAY_DASHBOARD_TITLE: 'File Replay',
  FILE_REPLAY_DASHBOARD_SUBHEADER_TITLE: 'Status of Replay Files',
  FILE_REPLAY_DASHBOARD_TABLE_TITLE: 'File Replay Data',
  FILE_REPLAY_DETAIL_TABLE_TITLE: 'Replay ID Data',
  FILE_REPLAY_DETAIL_SUBHEADER_TITLE: 'Detail Summary of Replay ID',
  FILE_REPLAY_NEW_REPLAY_EXCEPTION_LABEL: 'New Replay Exception',
  FILE_REPLAY_SIZE_VALIDATION_ERROR: 'The file size is more than',
  FILE_REPLAY_UPLOAD_ALLOWED_FILE_LABEL:
        '* The file should contain maximum of 10000 records & maximum file size is 1 MB',
  FILE_REPLAY_SUCCESS_MESSAGE: 'The file has been uploaded successfully',
  REPLAY_TEMPLATE_LABEL: 'Replay Template',
  INVALID_REPLAY_TITLE: 'Replay not allowed',
  REPLAY_CONFIRMATION_MESSAGE: 'Are you sure you want to resolve these items?',
  REPLAY_PROCESS_INIT_MESSAGE: 'Replay Process has been initiated',
  REPLAY_ACCESS_NOT_ALLOWED: "You don't have replay access for ",
  REPLAY_ALREADY_COMPLETED:
        'Selected records have been already resolved. Please check replay status',
  CONFIGURE_COLUMN_TITLE: 'List of Columns',
  CONFIGURE_COLUMN_HIDE_ALL_BUTTON_LABEL: 'Hide All',
  CONFIGURE_COLUMN_SHOW_ALL_BUTTON_LABEL: 'Show All',
  RELOAD_BUTTON_LABEL: 'RELOAD',
  REPLAY_BUTTON_LABEL: 'REPLAY',
  PREVIOUS_BUTTON_LABEL: 'PREVIOUS',
  NEXT_BUTTON_LABEL: 'NEXT',
  SUBMIT_BUTTON_LABEL: 'SUBMIT',
  CONFIRM_BUTTON_LABEL: 'CONFIRM',
  APPLY_BUTTON_LABEL: 'APPLY',
  DONE_BUTTON_LABEL: 'DONE',
  SEARCH_BUTTON_LABEL: 'SEARCH',
  SEARCH_QUERY_KEYPRESS_SUBMIT_LABEL: 'Press Ctrl + Enter to submit query',
  RESET_BUTTON_LABEL: 'RESET',
  ADVANCE_SEARCH_LABEL: 'Advance Search',
  LOG_EXCEPTION_SEARCH_PLACEHOLDER:
        'Search Criteria e.g: Transaction ID=123;Service=myservice;anycolumn=xyz',
  SAVE_CRITERIA_POPOVER_TITLE: 'Save Criteria',
  SAVE_CRITERIA_POPOVER_CRITERIA_TITLE: 'Search Criteria',
  SEARCH_CRITERIA_SAVE_SUCCESS_MESSAGE: 'Search criteria saved successfully',
  SEARCH_CRITERIA_DELETE_SUCCESS_MESSAGE: 'Search criteria deleted successfully',
  CHARGEBACK_COST_ANALYSIS_TITLE: 'Chargeback Cost Analysis',
  COLUMN_ALERT_CONFIG_PAGE_TITLE: 'Column Alert Managemenet',
  COLUMN_ALERT_CONFIG_DETAIL_PAGE_TITLE: 'Column Alert Configuration Details',
  COLUMN_ALERT_CONFIG_DELETE_SUCCESS_MESSAGE: 'Column Alert configuration deleted successfully',
  COLUMN_ALERT_CONFIG_UPDATE_SUCCESS_MESSAGE: 'Column Alert configuration updated successfully',
  EXCEPTION_CONFIG_DELETE_SUCCESS_MESSAGE: 'Exception configuration deleted successfully',
  EXCEPTION_CONFIG_UPLOAD_SUCCESS_MESSAGE: 'Exception configuration uploaded successfully',
  EXCEPTION_CONFIG_PAGE_TITLE: 'Exception Configuration',
  EXCEPTION_CONFIG_DETAIL_PAGE_TITLE: 'Exception Configuration Details',
  EXCEPTION_CONFIG_ADD_EDIT_DRAWER_TITLE: 'Exception Configuration',
  EXCEPTION_CONFIG_ALREADY_EXIST_MESSAGE: 'Exception Configuration already exist!',
  EXCEPTION_CONFIG_DETAIL_IF_CRITERIA_LABEL: 'If the Exception Criteria is:',
  EXCEPTION_CONFIG_DETAIL_THEN_CRITERIA_LABEL: 'Then the Replay Configuration details is:',
  EXCEPTION_CONFIG_DETAIL_NOTIFICATION_CRITERIA_LABEL:
        'And the Notification Configuration details is:',
  REPORT_CONFIG_PAGE_TITLE: 'Report Configuration',
  REPORT_CONFIG_ADD_EDIT_DRAWER_TITLE: 'Report Config',
  REPORT_CONFIG_ALREADY_EXIST_MESSAGE: 'Report Configuration already exist!',
  LOG_REPORT_CONFIG_ALREADY_EXIST_MESSAGE: 'Log Report Configuration already exist!',
  LOG_REPORT_CONFIG_ADD_EDIT_DRAWER_TITLE: 'Log Report Config',
  REPORT_CONFIG_DETAIL_PAGE_TITLE: 'Report Config Details',
  REPORT_CONFIG_GRID_NAME: 'Report Config',
  LOG_REPORT_CONFIG_GRID_NAME: ' Log Report Config',
  REPORT_CONFIG_DELETE_SUCCESS_MESSAGE: 'Report Config deleted successfully',
  LOG_REPORT_CONFIG_PAGE_TITLE: 'Log Report',
  LOG_REPORT_DELETE_CONFIG_TITLE: 'Log Report Config',
  LOG_REPORT_CONFIG_DELETE_SUCCESS_MESSAGE: 'Log Report deleted successfully',
  LOG_REPORT_CONFIG_DETAIL_PAGE_TITLE: 'Log Report Configuration Details',
  EXCEPTION_REPORT_CONFIG_PAGE_TITLE: 'Exception Report',
  EXCEPTION_REPORT_DELETE_CONFIG_TITLE: 'Exception Report Config',
  EXCEPTION_REPORT_CONFIG_DELETE_SUCCESS_MESSAGE: 'Exception Report deleted successfully',
  EXCEPTION_REPORT_CONFIG_DETAIL_PAGE_TITLE: 'Exception Report Configuration Details',
  EXCEPTION_REPORT_CONFIG_ALREADY_EXIST_MESSAGE: 'Exception Report Configuration already exist!',
  EXCEPTION_REPORT_CONFIG_ADD_EDIT_DRAWER_TITLE: 'Exception Report Config',
  REPLAY_CONFIG_PAGE_TITLE: 'Replay Configuration',
  SOLMAN_CONFIG_PAGE_TITLE: 'Solman Staging',
  SOLMAN_CONFIG_DETAIL_PAGE_TITLE: 'Solman Staging Details',
  COLUMN_ALERT_CONFIG_TITLE: 'Column Alert Config',
  SCHEDULE_CONFIG_PAGE_TITLE: 'Schedule Configuration',
  SCHEDULE_CONFIG_DETAIL_PAGE_TITLE: 'Schedule Configuration Details',
  REPLAY_CONFIG_DETAIL_PAGE_TITLE: 'Replay Configuration Details',
  REPLAY_CONFIG_ADD_EDIT_DRAWER_TITLE: 'Replay Configuration',
  REPLAY_CONFIG_ALREADY_EXIST_MESSAGE: 'Replay Configuration already exist!',
  REPLAY_CONFIG_DETAIL_IF_CRITERIA_LABEL: 'If the Replay Criteria is:',
  REPLAY_CONFIG_DETAIL_THEN_CRITERIA_LABEL: 'Then the Replay Configuration details is:',
  REPLAY_CONFIG_DELETE_SUCCESS_MESSAGE: 'Replay configuration deleted successfully',
  TABLE_CONFIG_PAGE_TITLE: 'Table Configuration',
  TABLE_CONFIG_ADD_DRAWER_TITLE: 'New Table Configuration',
  TABLE_CONFIG_INVALID_TABLE_NAME_MESSAGE: 'Table name should starts with ',
  TABLE_CONFIG_INVALID_TABLE_TOPIC_MESSAGE: 'Table topic name should start with pep-cgf-cle-',
  TABLE_CONFIG_DETAIL_PAGE_TITLE: 'Table Configuration Details',
  TABLE_CONFIG_ALREADY_EXIST_MESSAGE: 'Table Configuration already exist!',
  METRIC_DATA_NOT_AVAILABLE: 'Metric data not available',
  NO_MATCH_FOUND: 'No match found',
  NO_SUGGESTION_AVAILABLE: 'No Suggestion Available',
  NO_APPLICATION_ACCESS_ITEM: 'Access not added',
  DISCARD_TAB_CONFIRM_MSG: 'Are you sure you want to discard search tab?',
  APPLICATION_UPDATE_SUCCESS_MESSAGE: 'Application Details Updated Successfully',
  APPLICATION_DELETE_SUCCESS_MESSAGE: 'Application Deleted Successfully',
  COLUMN_ALERT_CONFIG_ADD_EDIT_DRAWER_TITLE: 'Column Alert Configuration',
  COLUMN_ALERT_CONFIG_ALREADY_EXIST_MESSAGE: 'Column Alert Configuration already exist!',
  COLUMN_ALERT_CONFIG_DETAIL_IF_CRITERIA_LABEL: 'If the Column Alert Criteria is:',
  COLUMN_ALERT_CONFIG_DETAIL_THEN_CRITERIA_LABEL: 'Then the Column Alert details is:',
  USER_LIST_PAGE_TITLE: 'User Access',
  USER_ADD_APPLICATION_ACCESS_BTN_LABEL: 'Add Application',
  USER_ADD_GROUP_ACCESS_BTN_LABEL: 'Add Group Access',
  USER_SEARCH_APPLICATION_INPUT_PLACEHOLDER: 'Search Application',
  USER_DETAIL_CREATE_USER_TITLE: 'New User',
  USER_DETAIL_PAGE_TITLE: 'User Details',
  USER_LIST_ALREADY_EXIST_MESSAGE: 'User already exist!',
  USER_LIST_ADD_DRAWER_TITLE: 'New User Access List',
  USER_GRID_TITLE: 'User',
  USER_LIST_CREATE_SUCCESS_MESSAGE: 'User Created Successfully',
  USER_LIST_UPDATE_SUCCESS_MESSAGE: 'User Updated Successfully',
  USER_ACCESS_LIST_DELETE_SUCCESS_MESSAGE: 'User deleted successfully',
  USER_DETAIL_GROUP_MODAL_TITLE: 'Application Access Group Selection',
  COLUMN_ALERT_CONFIG_NOTIFICATION_DETAIL_CRITERIA_LABEL:
        'And the Notification Configuration details is:',
  APPLICATION_ADD_DRAWER_TITLE: 'New Application Configuration',
  LIST_CATEGORY_CONFIG_ADD_DRAWER_TITLE: 'New Category Configuration',
  LIST_TYPE_CONFIG_ADD_DRAWER_TITLE: 'New Type Configuration',
  LIST_SEVERITY_CONFIG_ADD_DRAWER_TITLE: 'New Severity Configuration',
  APPLICATION_CONFIG_ALREADY_EXIST_MESSAGE: 'Application Configuration already exist!',
  CATEGORY_CONFIG_ALREADY_EXIST_MESSAGE: 'Category Configuration already exist!',
  CATEGORY_CONFIG_ADD_SUCCESS_MESSAGE: 'Category Configuration added Successfully!',
  CATEGORY_CONFIG_UPDATE_SUCCESS_MESSAGE: 'Category Configuration Updated Successfully!',
  TYPE_CONFIG_ALREADY_EXIST_MESSAGE: 'Type Configuration already exist!',
  TYPE_CONFIG_ADD_SUCCESS_MESSAGE: 'Type Configuration added Successfully!',
  TYPE_CONFIG_UPDATE_SUCCESS_MESSAGE: 'Type Configuration Updated Successfully!',
  SEVERITY_CONFIG_ALREADY_EXIST_MESSAGE: 'Severity Configuration already exist!',
  SEVERITY_CONFIG_ADD_SUCCESS_MESSAGE: 'Severity Configuration added Successfully!',
  SEVERITY_CONFIG_UPDATE_SUCCESS_MESSAGE: 'Severity Configuration Updated Successfully!',
  CONFIG_DELETE_SUCCESS_MESSAGE: 'Configuration deleted successfully',
  CONFIG_DELETE_FAILED_MESSAGE: 'Configuration deleted Failed, Please try again in sometime!',
  APPLICATION_ACCESS_HEADER: 'Application Access',
  GROUP_LIST_PAGE_TITLE: 'Group Access',
  GROUP_GRID_TITLE: 'Group',
  GROUP_USER_LIST_TITLE: 'Group User',
  GROUP_DETAIL_CREATE_GROUP_TITLE: 'New Group',
  GROUP_DETAIL_PAGE_TITLE: 'Group Details',
  GROUP_WITHOUT_USERS_LABEL: 'No Users added in the group',
  GROUP_USER_LENGTH_VALIDATION_ERROR: 'Atleast one user is mandatory!',
  GROUP_ALREADY_EXIST_MESSAGE: 'Group already exist!',
  GROUP_APPLICATION_ACCESS_LIST_DELETE_SUCCESS_MESSAGE: 'Group Application Access deleted successfully',
  USER_PROFIL_DETAILS_DRAWER_TITLE: 'Profile Details',
  VOLUME_METRICS_TITLE: 'Volume Metrics',
  VOLUME_METRICS_FORM_TITLE: 'Log/Exception Volume Metrics'
};

export default textConstants;
