import CustomSearchQueryRenderer from './customRendererForColumns/CustomSearchQueryRenderer';
import OpenSearchCriteriaButtonRenderer from './customRendererForColumns/OpenSearchCriteriaButtonRenderer';

export const RecentSearchColumns: any[] = [
  {
    field: 'searchQuery',
    cellRenderer: CustomSearchQueryRenderer,
    flex: 1
  },
  {
    field: '',
    headerName: '',
    minWidth: 50,
    maxWidth: 100,
    cellRenderer: OpenSearchCriteriaButtonRenderer
  }
];
