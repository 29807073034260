import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import CommonTableComponent from '../../../common/Table/CommonTable';
import { RecentSearchColumns } from '../../../../constants/TableColumns/RecentSearchColumns';
import { LOG_RECENT_SEARCH_KEY, EXCEPTION_RECENT_SEARCH_KEY } from '../../../../constants/KeyLabels/commonKeyConstants';
import { openSearchQueryInCurrentTab } from '../../../../helpers/searchTabHelper';

interface componentProps {
  logExceptionType?: string
}
const RecentSearchTableList: FC<componentProps> = ({ logExceptionType }) => {
  const searchTypeKey =
    logExceptionType?.toString().toLowerCase() === 'log'
      ? LOG_RECENT_SEARCH_KEY
      : EXCEPTION_RECENT_SEARCH_KEY;

  const localStoredRecentSearch = JSON.parse(localStorage.getItem(searchTypeKey) ?? '[]');
  return (
    <div data-testid="recent-search-list-component" >
      <CommonTableComponent
        height={'calc(80vh - 90px)'}
        rowData={localStoredRecentSearch}
        columnDefs={RecentSearchColumns}
        isColSizeAutoFit
        headerHeight={0}
        onRowDoubleClicked={(params: { data: any }) => {
          openSearchQueryInCurrentTab(params?.data?.type, params?.data?.searchQuery)
        }}
      />
    </div>
  );
};

RecentSearchTableList.propTypes = {
  logExceptionType: PropTypes.string
};

export default RecentSearchTableList;
