import React, { useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form, Radio } from 'antd';
import type { AdminConfigFormPropsType } from '../../../../../constants/Interfaces/AdminFormConfigTypes';
import { logReportConfigFieldlabels } from '../../../../../constants/FormFields/AdminForm/logReportConfigFormFields';
import { applicationDataHelper } from '../../../../../helpers/applicationDataHelper';
import FloatingLabel from '../../../../common/FloatingLabel';
import Footer from '../../../../common/Footer';
import { LOG_REPORT_ID } from '../../../../../constants/KeyLabels/commonKeyConstants';

const LogReportConfigForm: FC<AdminConfigFormPropsType> = ({ isDrawerView, isEditable, data, onSave, onCancel }) => {
  const [form] = Form.useForm();
  const applicationNameList = applicationDataHelper.getApplicationNames([]);

  const onSaveHandler = (isClose: boolean): void => {
    form.validateFields()
      .then(async values => {
        values = {
          ...values,
          applicationName: values.applicationName.map((item: any) => item.value),
          status: values.status.constructor === Array ? values.status : values.status.split(',').map((item: string) => item.trim())
        }
        onSave(values, isClose);
      }).catch(error => console.log(error));
  }

  const setLogReportFormData = (): void => {
    form.setFieldsValue({ ...data });
    if (data?.applicationName.length > 0) {
      const selectedAppNames = data.applicationName.map((item: any) => ({
        label: item,
        value: item
      }));
      form.setFieldValue('applicationName', selectedAppNames);
    }
  }

  useEffect(() => {
    if (isEditable) {
      setLogReportFormData();
    }
  }, [data[LOG_REPORT_ID]]);

  return (
    <>
      <Form
        form={form}
        initialValues={{
          enableNotification: false,
          ...data
        }}
        data-testid="logReportConfigForm"
        layout='vertical'
      >
        <Row
          gutter={16}
        >
          {
            isEditable && (<Col xs={24} md={6} xxl={5}>
            <Form.Item name='logConfigId'>
                <FloatingLabel
                  label={logReportConfigFieldlabels.logConfigId}
                  type='text'
                  id='logConfigId'
                  disabled
                />
              </Form.Item>
            </Col>)
          }
          <Col xs={24} md={6} xxl={5}>
            <Form.Item name='applicationName'
              rules={[{ required: true, message: 'Application is mandatory!' }]}
            >
              <FloatingLabel
                label={logReportConfigFieldlabels.applicationName}
                type='multiSelect'
                id='applicationName'
                options={applicationNameList}
                placeholder=''
                disabled={isEditable}
                required
                multi
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6} xxl={5}>
            <Form.Item
              name='mailID'
              rules={[
                { required: true, message: 'Email is mandatory!' }
              ]}
            >
              <FloatingLabel
                label={logReportConfigFieldlabels.mailID}
                type='text'
                id='mailID'
                disabled={isEditable}
                required
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6} xxl={5}>
            <Form.Item name='status' rules={[{ required: true, message: 'Status is mandatory!' }]}>
              <FloatingLabel
                label={logReportConfigFieldlabels.status}
                type='text'
                id='status'
                required
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xxl={12}>
            <Form.Item
              label={logReportConfigFieldlabels.enableNotification}
              name="enableNotification"
            >
              <Radio.Group >
                <Radio value>Enable</Radio>
                <Radio value={false}>Disable</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Footer
        className={`grey-theme ${isDrawerView ? 'add-form-footer' : ''}`}
        onSave={() => onSaveHandler(false)}
        onSaveClose={() => onSaveHandler(true)}
        onCancel={onCancel}
        saveWithClose={!isEditable}
        save
        cancel
      />
    </>
  );
};

LogReportConfigForm.defaultProps = {
  isEditable: true
};

LogReportConfigForm.propTypes = {
  data: PropTypes.object,
  isDrawerView: PropTypes.bool,
  isEditable: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default LogReportConfigForm;
