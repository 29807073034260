import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { AdminMainBreadCrumbWrapper, CurrentPageBreadCrumbLabel } from '../../../assets/styles/commonStyled';
import { ADMIN_APPLICATION_DETAIL_TEXT } from '../../../constants/breadCrumbTextConstants';
import { GoBackButton } from '../Buttons/buttons';

interface componentProps {
  isFromApplicationConfig?: boolean
  mainPageText?: string
  currentPageText?: string
  onBackCallback?: any
}

const AdminDetailBreadCrumb: FC<componentProps> = ({ isFromApplicationConfig, mainPageText, onBackCallback, currentPageText }) => {
  const history = useHistory();

  const onBackClickHandler = (): any => {
    if (onBackCallback) {
      return onBackCallback();
    } else {
      return history.goBack();
    }
  }

  return <>
    <AdminMainBreadCrumbWrapper data-testid="testid-application-detail-bread-crumb-wrapper">
      <GoBackButton onClick={onBackClickHandler} />
      {
        isFromApplicationConfig &&
        <a
          onClick={() => history.go(-2)}
          data-testid="testid-application-detail-bread-crumb-application-page-btn"
        >{ADMIN_APPLICATION_DETAIL_TEXT}  / </a>
      }
      <a onClick={onBackClickHandler}
        data-testid="testid-application-detail-bread-crumb-main-page-btn">
        {mainPageText}
      </a> / <CurrentPageBreadCrumbLabel>{currentPageText}</CurrentPageBreadCrumbLabel>
    </AdminMainBreadCrumbWrapper>
  </>;
};

AdminDetailBreadCrumb.propTypes = {
  isFromApplicationConfig: PropTypes.bool,
  mainPageText: PropTypes.string,
  currentPageText: PropTypes.string,
  onBackCallback: PropTypes.func
};

export default AdminDetailBreadCrumb;
