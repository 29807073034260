import React, { useCallback, useEffect, useLayoutEffect, useState, useRef } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Layout, Modal } from 'antd';
import { DatabaseOutlined, FilterOutlined, ExportOutlined, SaveOutlined } from '@ant-design/icons';
import LoadingBar from 'react-top-loading-bar';
import type { LoadingBarRef } from 'react-top-loading-bar';
import { cloneDeep, omit, xorBy } from 'lodash';
import {
  APIPostRequestHandler,
  exceptionReplayAPIRequestHandler,
  exportAPIRequestHandler
} from '../../../services/logExceptionServiceHandler';
import { applicationDataHelper } from '../../../helpers/applicationDataHelper';
import { checkifAnyObjectPropertyIsTruthy, formatNumberForDisplay } from '../../../helpers/commonHelper';
import logExportFields from '../../../constants/ExportDataFields/logField';
import exceptionExportFields from '../../../constants/ExportDataFields/exceptionField';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import {
  HeaderLeftContentWrapper,
  HeaderRightContentWrapper,
  TableHeaderWrapper,
  HeaderSearchWrapper,
  LayoutWrapper,
  LayoutDrawerParentWrapper,
  TableMainWrapper,
  TableIconWrapper,
  TableIconButtonWrapper,
  TableFilteredElementBar,
  TableFilteredTagWrapper,
  TableFilteredResetWrapper
} from '../../common/Table/style';

import ConfirmationPopup from '../../common/ConfirmationPopup';
import ConfigureColumnPopover from '../../common/ConfigureColumnPopover';
import ExportPopover from '../../common/ExportPopover';
import FilterPopover from '../../common/FilterPopover';
import SaveCriteriaPopover from '../popOver/SaveCriteriaPopover';
import LogExceptionDetailDrawer from '../Drawer/LogException/LogExceptionDetailDrawer';
import ExceptionStatusRenderer from '../../../constants/TableColumns/customRendererForColumns/ExceptionStatusRenderer';
import Tags from '../../common/Tags';
import { DOWNLOAD_DATA_API_URL } from '../../../services/setEndpointURL';
import textConstants from '../../../constants/textConstants';
import FooterComponent from '../../common/Footer';
import CommonTableComponent from '../../common/Table/CommonTable';
import { useDispatch } from 'react-redux';
import { saveSearchCriteria } from '../../../redux/actions/SavedSearch/savedSearchActions';
import apiEndpointConstants from '../../../constants/apiEndpointConstants';
import { checkSavedSearchCriteriaLimitIsReached } from '../../../helpers/saveRecentSearchHelper';
import NavigateToSavedSearchModal from '../CustomModal/NavigateToSavedSearchModal';
import { AUDIT_LOG_KEY, EXCEPTION_STATUS_KEY, TRANSACTION_DATA_KEY } from '../../../constants/KeyLabels/commonKeyConstants';
import { apiRequestController } from '../../../services/axiosInterceptor';
import { REPLAY_STATUS_DELAY_TIME, SEARCH_DATA_MAX_LIMIT } from '../../../constants/commonConstants';
import { fetchAuditLogServiceHanlder } from '../../../services/auditLogServiceHandler';
import { successNotification } from '../../common/Notification';
import { fetchSearchData } from '../../../redux/actions/LogExceptionSearch/logExceptionSearchActions';

const { Content } = Layout;
interface TablePropsType {
  activeTabName: string
  unSortIcon?: boolean
  isColSizeAutoFit?: boolean
  isSearchChanged?: boolean
  rowData?: any
  columnDefs?: any
  showResultsCount?: boolean
  totalRecordCount?: number
  showSearchBar?: boolean
  showHeaderColumnConfigureButton?: boolean
  showHeaderFilterButton?: boolean
  showHeaderExportButton?: boolean
  showSaveCriteriaButton?: boolean
  logExceptionType: string
  searchQuery?: any
  userAccessInfo?: any
  selectedTabData?: any
}

const LogExpTable: FC<TablePropsType> = ({ ...props }) => {
  const dispatch = useDispatch();
  const searchRequest = JSON.parse(props.searchQuery);
  let isReplayStatusAPICalled = false;

  const [modal, modalContext] = Modal.useModal();

  const loadingBarRef = useRef<LoadingBarRef>(null);
  const layoutWrapperRef = useRef<any>(null);
  const [isFilterPopoverOpen, setFilterPopoverVisibility] = useState(false);
  const [isColumnConfigPopoverOpen, setColumnConfigPopoverVisibility] = useState(false);
  const [isReplayConfirmation, setReplayConfirmationStatus] = useState(false);
  const [isSaveCriteriaPopoverOpen, setSaveCriteriaPopoverVisibility] = useState(false);
  const [isExportPopoverOpen, setExportPopoverVisibility] = useState(false);
  const [isDataLoading, setDataLoadingStatus] = useState(false);
  const [isDetailDrawerOpen, setDetailDrawerOpen] = useState(false);
  const [isDrawerReplayBtnEnabled, setDrawerReplayBtnStatus] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<any>({});
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedItemsDetail, setSelectedItemsDetail] = useState<any[]>([]);
  const [columnDefList, setColumnDefList] = useState<any[]>([]);
  const [tableRowData, setTableRowData] = useState<any[]>([]);
  const [selectedColumnItemCount, setSelectedColumnItemCount] = useState<any>({});
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);
  const [gridApi, setGridApi] = useState<any>();
  const [showSavedSearchCriteriaWarningModal, setShowSavedSearchCriteriaWarningModal] = useState(false);
  const exportAllData = (fields: string[]): void => {
    const searchRequestForDownload = JSON.parse(props.selectedTabData?.firstCallPayload ?? '[]');
    const exportUrl = `${DOWNLOAD_DATA_API_URL}/${props.logExceptionType?.toLowerCase()}/download/`;
    const multipleExportPromiseArr: any = [];

    // let appConfigList: any = [];
    // const tableName = props?.logExceptionType?.toLowerCase() === 'log' ? 'logTable' : 'exceptionTable';
    // searchRequestForDownload[0].appName.forEach((item: any) => {
    //   const appWithTableIndex = appConfigList.findIndex((appItem: any) => appItem[tableName] === item[tableName]);
    //   if (appWithTableIndex > -1) {
    //     appConfigList[appWithTableIndex].value = `${String(appConfigList[appWithTableIndex]?.value)}, ${String(item?.value)}`;
    //   } else {
    //     appConfigList = [...appConfigList, item];
    //   }
    // });
    // const exportMultipleSearchRequest = appConfigList?.map((item: any) => ({
    //   ...searchRequestForDownload,
    //   applicationName: item.value,
    //   startTime: searchRequest.dateTime[0],
    //   endTime: searchRequest.dateTime[1],
    //   table: props.logExceptionType === 'Log' ? item?.logTable : item?.exceptionTable
    // }));

    searchRequestForDownload.forEach((request: any) => {
      request.columns = fields;
      request.totalRecordCount = props.totalRecordCount ?? 0;
      const { appName, dateTime, ...requestData } = request;
      multipleExportPromiseArr.push(
        new Promise((resolve, reject) => {
          exportAPIRequestHandler(
            requestData,
            exportUrl,
            true,
            `${props.logExceptionType}s`,
            loadingBarRef,
            resolve,
            reject
          );
        })
      );
    });

    Promise.allSettled(multipleExportPromiseArr)
      .then(() => {
        successNotification(`${props.logExceptionType} ${textConstants.DOWNLOAD_SUCCESS_MESSAGE}`);
        loadingBarRef?.current?.complete();
      })
      .catch(() => {
        loadingBarRef?.current?.complete();
      });
  };

  // Method to handle export data for log/exception export
  const exportData = (fields: string[]): void => {
    let selectedRowData = selectedRows;

    if (Object.keys(appliedFilters).length > 0) {
      selectedRowData = selectedRowData.length > 0 ? selectedRowData : tableRowData;
    }

    // To export selected records
    if (selectedRowData.length > 0 || Object.keys(appliedFilters).length > 0) {
      const exportEndpointUrl = `${DOWNLOAD_DATA_API_URL}/${props.logExceptionType?.toLowerCase()}/download/selected/`;
      const reqPayload = {
        columns: fields,
        request: applicationDataHelper.getRequestPayload(
          selectedRowData,
          searchRequest,
          props.logExceptionType,
          props.userAccessInfo?.userID
        )
      };
      exportAPIRequestHandler(
        reqPayload,
        exportEndpointUrl,
        false,
        `${props.logExceptionType}s`,
        loadingBarRef
      );
    } else {
      exportAllData(fields);
    }

    setExportPopoverVisibility(false);
  };

  const getSearchItemsDetail = async (data: any, currentItemIndex: number, isDblClick?: boolean): Promise<void> => {
    setDataLoadingStatus(true);
    const url = props.logExceptionType === 'Log' ? apiEndpointConstants.GET_LOG_DETAILS : apiEndpointConstants.GET_EXCEPTION_DETAILS;
    const currentAppObj = searchRequest?.appName.find((item: any) => item.value === data?.application_nm) ?? (searchRequest?.appName?.length > 0 ? searchRequest?.appName?.[0] : null);
    const request = {
      application: data?.application_nm,
      table: props.logExceptionType === 'Log' ? currentAppObj?.logTable : currentAppObj?.exceptionTable,
      timemark: data.timemark,
      ...(props.logExceptionType === 'Log' && {
        logID: data.log_id,
        log_status: data.log_status
      }),
      ...(props.logExceptionType !== 'Log' && {
        exceptionID: data.exception_id,
        exception_status: data.exception_status
      })
    };

    let respData = await APIPostRequestHandler(request, url);
    if (respData) {
      if (props.logExceptionType.toLowerCase() === 'exception' && respData[EXCEPTION_STATUS_KEY].toString().toLowerCase() === 'resolved') {
        const auditLogsResponse = await fetchAuditLogServiceHanlder(request);
        respData = { ...respData, [AUDIT_LOG_KEY]: auditLogsResponse };
      }
      const detailItemArr = [...selectedItemsDetail];
      detailItemArr[currentItemIndex] = { ...respData, table: props.logExceptionType === 'Log' ? currentAppObj?.logTable : currentAppObj?.exceptionTable };
      const isReplayEnabled = props.logExceptionType === 'Exception' && applicationDataHelper.validateUserAccess(data?.application_nm || '', props.userAccessInfo, 'replay');
      if (isDblClick) {
        setSelectedRows([]);
      }
      setDrawerReplayBtnStatus(isReplayEnabled);
      setSelectedItemsDetail(detailItemArr);
      setDetailDrawerOpen(true);
    }
    setDataLoadingStatus(false);
  }

  const updateItemDetailTransactionData = useCallback((updatedTransData: any): void => {
    const detailItemArr = [...selectedItemsDetail];
    detailItemArr[selectedItemIndex] = { ...detailItemArr[selectedItemIndex], [TRANSACTION_DATA_KEY]: updatedTransData };
    setSelectedItemsDetail(detailItemArr);
  }, [selectedItemsDetail, selectedItemIndex]);

  const handleItemNavigation = useCallback((itemIndex: number): void => {
    setSelectedItemIndex(itemIndex);
    if (!selectedItemsDetail[itemIndex]) {
      void getSearchItemsDetail(selectedRows[itemIndex], itemIndex);
    }
  }, [selectedRows, selectedItemsDetail]);

  const onDetailReplayButtonClick = useCallback(() => {
    setReplayConfirmationStatus(true)
  }, [])

  const onDetailDrawerClose = useCallback(() => {
    setDetailDrawerOpen(false);
    setSelectedItemsDetail([]);
    setSelectedItemIndex(0);
  }, []);

  const handleExceptionReplayStatus = async (request: any): Promise<void> => {
    const replayRequest = [...request];
    const replayStatusUrl = apiEndpointConstants.EXCEPTION_REPLAY_STATUS;
    isReplayStatusAPICalled = true;
    const respData = await APIPostRequestHandler(replayRequest, replayStatusUrl);
    if (respData?.length > 0) {
      const clonedTableData = cloneDeep(tableRowData);
      respData.forEach((respItem: any) => {
        if (isDetailDrawerOpen) {
          const updatedSelectedItems = selectedItemsDetail.map((item: any) => {
            if (item.exception_id === respItem.exceptionID) {
              item.exception_status = respItem.replayStatus;
              item.resolution_description = respItem.resolution_description;
            }

            return item
          });

          setSelectedItemsDetail(updatedSelectedItems);
        }

        const rowItemIndex = props.rowData.findIndex((item: any) => item.exception_id === respItem.exceptionID);

        if (rowItemIndex > -1) {
          clonedTableData[rowItemIndex].exception_status = respItem.replayStatus;
        }

        if (respItem.replayStatus !== 'Scheduled') {
          const nonScheduleRecords = replayRequest.findIndex(
            (req) => req.exceptionID === respItem.exceptionID
          );
          if (nonScheduleRecords > -1) {
            replayRequest.splice(nonScheduleRecords, 1);
          }
        }
      })

      setTableRowData(clonedTableData);

      if (replayRequest.length > 0) {
        setTimeout(() => {
          void handleExceptionReplayStatus(replayRequest);
        }, REPLAY_STATUS_DELAY_TIME);
      } else {
        isReplayStatusAPICalled = false;
      }
    } else {
      void handleExceptionReplayStatus(replayRequest);
    }
  }

  const handleExceptionReplayResponse = (resp: any, request: any): void => {
    const clonedSelectedItemsDetail = cloneDeep(selectedItemsDetail);

    successNotification(textConstants.REPLAY_PROCESS_INIT_MESSAGE);

    if (resp) {
      if (['Running', 'Scheduled'].includes(resp.request_status)) {
        if (isDetailDrawerOpen) {
          clonedSelectedItemsDetail[selectedItemIndex].exception_status = 'Running';
          setSelectedItemsDetail(clonedSelectedItemsDetail);
        }

        if (request.length > 0) {
          const clonedTableData = cloneDeep(tableRowData);

          request?.forEach((rowItem: any) => {
            const rowItemIndex = props.rowData.findIndex((item: any) => item.exception_id === rowItem.exceptionID);

            if (rowItemIndex > -1) {
              clonedTableData[rowItemIndex].exception_status = 'Running';
            }
          });

          setTableRowData(clonedTableData);
        }
      }

      setTimeout(() => {
        void handleExceptionReplayStatus(request)
      }, 10000);
    }

    setDataLoadingStatus(false);
  }

  // Method to replay exception on Confirmation
  const onExceptionReplay = (): void => {
    setReplayConfirmationStatus(false);
    setDataLoadingStatus(true);
    let selectedData: any = [];

    if (isDetailDrawerOpen) {
      selectedData = [selectedItemsDetail[selectedItemIndex]];
    } else {
      selectedData = selectedRows;
    }

    const request = applicationDataHelper.getRequestPayload(
      selectedData,
      searchRequest,
      props.logExceptionType,
      props.userAccessInfo?.userID
    );

    exceptionReplayAPIRequestHandler(request, apiEndpointConstants.EXCEPTION_REPLAY, handleExceptionReplayResponse);
  }

  const onColumnConfigUpdate = (columns: any[]): void => {
    const mandatoryColumns = props.columnDefs.filter((item: any) => item.headerName === '');
    // gridApiRef?.current?.api.setColumnDefs([...mandatoryColumns, ...columns]);
    setColumnConfigPopoverVisibility(false);
    setColumnDefList([...mandatoryColumns, ...columns]);
    sessionStorage.setItem(`${props.logExceptionType}ConfigColumnList`, JSON.stringify(columns));
  };

  const onFilterApply = (data: any[], filters: any, selectedColumnItem: any): void => {
    setTableRowData(data);
    setAppliedFilters(filters);
    setSelectedColumnItemCount(selectedColumnItem);
    gridApi?.api?.setRowData(data);
  };

  const onFilterReset = (): void => {
    setSelectedColumnItemCount({});
    setAppliedFilters({});
    setTableRowData(props.rowData);
    gridApi?.api?.setRowData(props.rowData);
  }

  const onFilterTagClose = (category: string, categoryItem: string): void => {
    const clonedAppliedFilterData: any = cloneDeep(appliedFilters);
    let clonedColumnItemCountConfig = {
      ...selectedColumnItemCount
    }
    clonedAppliedFilterData[category].items[categoryItem] = false;

    if (checkifAnyObjectPropertyIsTruthy(clonedAppliedFilterData)) {
      setAppliedFilters(clonedAppliedFilterData);
      if (selectedColumnItemCount[category] > 1) {
        clonedColumnItemCountConfig = {
          ...clonedColumnItemCountConfig,
          [category]: selectedColumnItemCount[category] - 1
        }
      } else {
        clonedColumnItemCountConfig = omit(clonedColumnItemCountConfig, category);
      }
      setSelectedColumnItemCount(clonedColumnItemCountConfig)
    } else {
      onFilterReset();
    }
  };

  const getColDefList = (): any[] => {
    let columnFilteredList = columnDefList.filter(
      (item) => item.headerName === '' || item.isVisible
    );

    if (columnFilteredList.length <= 5) {
      columnFilteredList = columnFilteredList.map((item: any) => ({
        ...item,
        flex: 1
      }));
    }

    return columnFilteredList;
  };

  const checkIsReplayAllowedForSelectedRows = (): void => {
    const replayErrorMsg = applicationDataHelper.checkIsValidReplay(selectedRows, props.userAccessInfo);

    if (replayErrorMsg) {
      const config = {
        title: textConstants.INVALID_REPLAY_TITLE,
        content: <>{replayErrorMsg}</>
      }
      modal.warning(config);
    } else {
      setReplayConfirmationStatus(true);
    }
  }

  const getCategoryNameList = (): any[] => {
    return props.columnDefs
      .filter(
        (item: any) =>
          item.headerName !== '' && !['Time Occured', 'Time Duration'].includes(item.headerName)
      )
      .map((item: any) => ({
        headerName: item.headerName,
        field: item.field
      }));
  };

  useLayoutEffect(() => {
    let columnList = [];
    const cacheConfigColumnList = sessionStorage.getItem(
      `${props.logExceptionType}ConfigColumnList`
    );
    if (cacheConfigColumnList) {
      const mandatoryColumns = props.columnDefs.filter((item: any) => item.headerName === '');
      columnList = [...mandatoryColumns, ...JSON.parse(cacheConfigColumnList)];

      if (props.logExceptionType !== 'Log') {
        const exceptionStatusIndex = columnList.findIndex((item: any) => item.field === 'exception_status');
        columnList[exceptionStatusIndex] = {
          ...columnList[exceptionStatusIndex],
          cellRenderer: ExceptionStatusRenderer
        };
      }
    } else {
      columnList = [...props.columnDefs];
    }

    setColumnDefList(columnList);
  }, [props.columnDefs]);

  useEffect(() => {
    if (!props.isSearchChanged) {
      if (props.logExceptionType !== 'Log') {
        const changedRowData = xorBy(tableRowData, props.rowData, 'exception_id');
        setTableRowData([...tableRowData, ...changedRowData]);
      } else {
        setTableRowData(props.rowData);
      }
    }

    if (props?.rowData?.length === 0) {
      gridApi?.api?.showLoadingOverlay();
    } else {
      gridApi?.api?.hideOverlay();
    }
  }, [props.rowData]);

  useEffect(() => {
    if (props?.rowData?.length === 0) {
      gridApi?.api?.showLoadingOverlay();
    }
  }, [gridApi]);

  useEffect(() => {
    if (props.isSearchChanged) {
      setTableRowData([]);
      setDetailDrawerOpen(false);
      setSelectedColumnItemCount({});
      setAppliedFilters({});
    }
  }, [props.isSearchChanged]);

  useEffect(() => {
    return () => {
      if (props.logExceptionType !== 'Log' && isReplayStatusAPICalled) {
        apiRequestController.abort();
      }
    }
  }, []);

  const renderAppliedFilterTagList = (): any[] => {
    const appliedTagArr: any = [];
    Object.keys(appliedFilters).forEach((key: string) => {
      Object.keys(appliedFilters[key].items).forEach((item: string) => {
        if (appliedFilters[key].items[item]) {
          appliedTagArr.push(
            <Tags
              key={`${key}-${item}`}
              additionalInfo={appliedFilters[key].label}
              onClose={() => onFilterTagClose(key, item)}
              text={item}
              theme='blue-theme'
              isClosable
            />
          );
        }
      });
    })

    return appliedTagArr;
  };

  const onSaveSearchCriteriaHandler = (formValues: any): void => {
    setSaveCriteriaPopoverVisibility(false);
    // checking if savedSearch criteria save limit is not reached
    if (!checkSavedSearchCriteriaLimitIsReached()) {
      dispatch(saveSearchCriteria({
        ...formValues,
        searchType: props.logExceptionType
      }));
    } else {
      // ask user to delete some saved search criteria as limit is reached
      setShowSavedSearchCriteriaWarningModal(true);
    }
  }

  const showNextPageHandler = (): void => {
    setTableRowData([]);
    dispatch(fetchSearchData(JSON.parse(props?.selectedTabData?.payloadForNextCall), props?.logExceptionType, props?.activeTabName, props?.totalRecordCount ?? 0));
  }

  const tableHeight = `calc(100vh - ${layoutWrapperRef?.current?.getBoundingClientRect().top + 50 + (Object.keys(appliedFilters).length > 0 ? 45 : 0) + (!isDetailDrawerOpen && selectedRows.length > 0 ? 40 : 0)}px)`;

  return (
    <>
      <LayoutDrawerParentWrapper data-testid="logExceptionTable">
        <LoadingBar
          ref={loadingBarRef}
          containerClassName="fixed-progress-bar"
          height={3}
          color="#23A0DD"
        />
        <LayoutWrapper ref={layoutWrapperRef}>
          <TableHeaderWrapper className="log-search-header">
            <HeaderLeftContentWrapper>
              {props.showResultsCount && <div>Showing {formatNumberForDisplay(tableRowData?.length)} of {Object.keys(appliedFilters).length > 0 ? formatNumberForDisplay(tableRowData?.length) : formatNumberForDisplay(props.totalRecordCount)}</div>}
              {props.showSearchBar && (
                <HeaderSearchWrapper>
                  <Input placeholder={'Search'} />
                </HeaderSearchWrapper>
              )}
              {
                showSavedSearchCriteriaWarningModal &&
                <NavigateToSavedSearchModal
                  isOpen={showSavedSearchCriteriaWarningModal}
                  closeModal={() => setShowSavedSearchCriteriaWarningModal(false)}
                  logExceptionType={props.logExceptionType} />
              }
              {props.showSaveCriteriaButton && (
                <>
                  <SaveCriteriaPopover
                    isOpen={isSaveCriteriaPopoverOpen}
                    onConfirm={(values: any) => onSaveSearchCriteriaHandler(values)}
                    onCancel={() => setSaveCriteriaPopoverVisibility(false)}
                    handleOpenChange={(isOpen: boolean) => setSaveCriteriaPopoverVisibility(isOpen)}
                    searchCriteria={props.searchQuery}
                  >
                    <TableIconButtonWrapper
                      data-testid="saveCriteriaBtn"
                      onClick={() => setSaveCriteriaPopoverVisibility(!isSaveCriteriaPopoverOpen)}
                      className="minWidth-auto"
                    >
                      <TableIconWrapper>
                        <SaveOutlined />
                      </TableIconWrapper>
                      Save Criteria
                    </TableIconButtonWrapper>
                  </SaveCriteriaPopover>
                </>
              )}
              {
                props?.selectedTabData?.hasNextPage &&
                tableRowData?.length >= SEARCH_DATA_MAX_LIMIT &&
                <TableIconButtonWrapper
                  data-testid="test-id-showNextPage-btn"
                  onClick={() => showNextPageHandler()}
                  className="minWidth-auto"
                >
                  Show Previous Records
                </TableIconButtonWrapper>
              }
              {
                tableRowData?.length < Math.min(props?.totalRecordCount ?? SEARCH_DATA_MAX_LIMIT, SEARCH_DATA_MAX_LIMIT) &&
                <TableIconButtonWrapper
                  className="disabled-btn"
                >
                  Loading Records...
                </TableIconButtonWrapper>
              }
            </HeaderLeftContentWrapper>
            <HeaderRightContentWrapper>
              {props.showHeaderColumnConfigureButton && (
                <ConfigureColumnPopover
                  isOpen={isColumnConfigPopoverOpen}
                  onColumnConfigApply={(columns: any) => onColumnConfigUpdate(columns)}
                  initialColumnList={props.columnDefs}
                  columnList={columnDefList}
                  mandatoryColumnList={['application_nm', 'timemark']}
                  handleOpenChange={(isOpen: boolean) => setColumnConfigPopoverVisibility(isOpen)}
                >
                  <TableIconButtonWrapper
                    data-testid="configColumnButton"
                    onClick={() => setColumnConfigPopoverVisibility(true)}
                  >
                    <TableIconWrapper className="rotate-90">
                      <DatabaseOutlined />
                    </TableIconWrapper>
                    {textConstants.CONFIGURE_COLUMN_BUTTON_LABEL}
                  </TableIconButtonWrapper>
                </ConfigureColumnPopover>
              )}
              {props.showHeaderExportButton && (
                <ExportPopover
                  availableColumns={props.logExceptionType === 'Exception' ? [...exceptionExportFields] : [...logExportFields]}
                  allowReplayExport={props.logExceptionType === 'Exception'}
                  handleOpenChange={(isOpen: boolean) => setExportPopoverVisibility(isOpen)}
                  isOpen={isExportPopoverOpen}
                  mandatorySelectedFields={['application_nm', 'timemark']}
                  onConfirm={exportData}
                  onCancel={() => setExportPopoverVisibility(false)}
                >
                  <TableIconButtonWrapper
                    onClick={() => setExportPopoverVisibility(true)}
                    data-testid="exportButton"
                  >
                    <TableIconWrapper>
                      <ExportOutlined />
                    </TableIconWrapper>
                    {textConstants.EXPORT_BUTTON_LABEL}
                  </TableIconButtonWrapper>
                </ExportPopover>
              )}
              {props.showHeaderFilterButton && (
                <FilterPopover
                  categoryNameList={getCategoryNameList()}
                  handlePopoverVisibility={(isOpen: boolean) => setFilterPopoverVisibility(isOpen)}
                  isOpen={isFilterPopoverOpen}
                  onFilterApply={onFilterApply}
                  onFilterReset={onFilterReset}
                  appliedFilters={appliedFilters}
                  tableData={tableRowData ?? []}
                  initialTableData={props.rowData ?? []}
                  selectedColumnItemCount={selectedColumnItemCount}
                >
                  <TableIconButtonWrapper
                    data-testid="filterDataButton"
                    onClick={() => setFilterPopoverVisibility(!isFilterPopoverOpen)}
                  >
                    <TableIconWrapper>
                      <FilterOutlined />
                    </TableIconWrapper>
                    {textConstants.FILTER_RESULT_BUTTON_LABEL}
                  </TableIconButtonWrapper>
                </FilterPopover>
              )}
            </HeaderRightContentWrapper>
          </TableHeaderWrapper>

          {Object.keys(appliedFilters).length > 0 && (
            <TableFilteredElementBar data-testid="appliedFilterTagWrapper">
              <TableFilteredTagWrapper>{renderAppliedFilterTagList()}</TableFilteredTagWrapper>
              <TableFilteredResetWrapper>
                <Button
                  type="text"
                  className="mr-10 btn-regular"
                  onClick={onFilterReset}
                  data-testid="resetFilterBtn"
                >
                  {textConstants.MULTISELECT_CLEAR_ALL_LABEL}
                </Button>
              </TableFilteredResetWrapper>
            </TableFilteredElementBar>
          )}
          <Content>
            <TableMainWrapper data-testid="testid-log-exception-aggrid-table">
              <CommonTableComponent
                {...props}
                isDataLoading={isDataLoading}
                showSearchBar={false}
                showResultsCount={false}
                rowData={tableRowData}
                columnDefs={getColDefList()}
                getRowId={(data: { data: any }) =>
                  data?.data?.log_id ? `ag-grid-row-${String(data?.data?.log_id)}` : `ag-grid-row-${String(data?.data?.exception_id)}`
                }
                pagination={false}
                rowSelection="multiple"
                rowMultiSelectWithClick
                className='primary-theme'
                height={tableHeight}
                onRowDoubleClicked={(params: { data: any }) => {
                  gridApi?.api?.deselectAll();
                  setSelectedItemIndex(0);
                  void getSearchItemsDetail(params?.data, 0, true);
                }}
                onGridReady={(params: any) => {
                  setGridApi(params)
                }}
                getRowClass={(params: { data: any }) => {
                  const rowData: any = params.data;
                  return rowData.log_level?.toLowerCase() === 'error'
                    ? 'log-level-error-row-style'
                    : undefined;
                }}
                onSelectionChanged={(gridOptions: { api: { getSelectedRows: () => React.SetStateAction<any[]> } }) => {
                  setTimeout(() => {
                    if (gridOptions?.api) {
                      setSelectedRows(gridOptions?.api?.getSelectedRows());
                    }
                  }, 250)
                }}
              />
            </TableMainWrapper>
          </Content>
          {isDetailDrawerOpen && (
            <LogExceptionDetailDrawer
              isDrawerOpen={isDetailDrawerOpen}
              isReplayAllowed={isDrawerReplayBtnEnabled}
              logExceptionType={props.logExceptionType}
              selectedRows={selectedRows}
              selectedItemDetails={selectedItemsDetail[selectedItemIndex]}
              handleItemNavigation={handleItemNavigation}
              handleExceptionReplay={onDetailReplayButtonClick}
              selectedItemIndex={selectedItemIndex}
              onClose={onDetailDrawerClose}
              updateItemDetailTransactionData={updateItemDetailTransactionData}
            />
          )}
        </LayoutWrapper>
      </LayoutDrawerParentWrapper>
      {selectedRows.length > 0 && !isDetailDrawerOpen && (
        <FooterComponent
          additionalInfo={
            `${(selectedRows.length < 10 ? `0${selectedRows?.length}` : selectedRows.length)} ${props.logExceptionType} Selected`
          }
          cancel
          onCancel={() => {
            setSelectedRows([]);
            gridApi?.api?.deselectAll();
          }}
          viewDetails
          onViewDetails={async () => await getSearchItemsDetail(selectedRows[0], selectedItemIndex)}
          hasReplay={props.logExceptionType === 'Exception' && applicationDataHelper.validateSelectedItemsReplayAccess(selectedRows, props.userAccessInfo)}
          onReplay={checkIsReplayAllowedForSelectedRows}
        />
      )}
      {isReplayConfirmation && (
        <ConfirmationPopup
          isConfirmOpen={isReplayConfirmation}
          message={textConstants.REPLAY_CONFIRMATION_MESSAGE}
          onConfirmHandler={onExceptionReplay}
          onCancelHandler={() => setReplayConfirmationStatus(false)}
        />
      )}
      {modalContext}
    </>
  );
};

LogExpTable.propTypes = {
  activeTabName: PropTypes.string.isRequired,
  unSortIcon: PropTypes.bool,
  isColSizeAutoFit: PropTypes.bool,
  showResultsCount: PropTypes.bool,
  totalRecordCount: PropTypes.number,
  showSearchBar: PropTypes.bool,
  showHeaderColumnConfigureButton: PropTypes.bool,
  showHeaderFilterButton: PropTypes.bool,
  showHeaderExportButton: PropTypes.bool,
  showSaveCriteriaButton: PropTypes.bool,
  logExceptionType: PropTypes.string.isRequired,
  userAccessInfo: PropTypes.object,
  isSearchChanged: PropTypes.bool,
  selectedTabData: PropTypes.object
};

export default LogExpTable;
