import * as types from '../ActionTypes/LogSearch/logSearchTypes';

interface dataInterface {
  type: string
  logTabList?: []
  payload?: any
}

export function getLogSearchTabList (): dataInterface {
  return {
    type: types.GET_LOG_TABS_LIST
  };
}

export function addNewLogTab (newLogTabData: any): dataInterface {
  return {
    type: types.ADD_LOG_TAB,
    payload: { ...newLogTabData }
  };
}

export function updateLogTabList (newLogList: any): dataInterface {
  return {
    type: types.UPDATE_LOG_TAB_LIST,
    payload: newLogList
  };
}

export function removeLogTab (key: string): dataInterface {
  return {
    type: types.REMOVE_LOG_TAB,
    payload: { key }
  };
}

export function changeLogActiveTab (key: any): dataInterface {
  return {
    type: types.CHANGE_ACTIVE_LOG_TAB,
    payload: { key }
  };
}

export function storeLogSearchData (searchedTabKey: string, searchResults: any[], payloadForNextCall: string, hasNextPage: boolean): dataInterface {
  return {
    type: types.STORE_LOG_SEARCHED_DATA,
    payload: { searchedTabKey, searchResults, payloadForNextCall, hasNextPage }
  };
}

export function storeLogSearchQuery (searchedTabKey: string, searchQuery: any, recordCount: number): dataInterface {
  return {
    type: types.STORE_LOG_SEARCHED_QUERY,
    payload: { searchedTabKey, searchQuery, recordCount }
  };
}

export function resetLogSearchData (searchedTabKey: string, updatedSearchQuery?: any): dataInterface {
  return {
    type: types.RESET_LOG_SEARCHED_DATA,
    payload: { searchedTabKey, updatedSearchQuery }
  };
}
