import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { ArrowRightOutlined, SearchOutlined } from '@ant-design/icons';
import textConstants from '../../../constants/textConstants';
import { HeaderTitle, SearchInput } from '../../../assets/styles/commonStyled';
import { ApplicationAccessHeaderWrapper, ApplicationAccessSubHeaderWrapper, ApplicationHeaderRightContainer } from './style';
import { AddButton } from '../Buttons/buttons';

interface ApplicationAccessHeaderProps {
  accessFieldType?: string
  applicationSearchHandler: any
  mirrorInputValue?: string
  searchValue?: string
  hasAddBtn?: boolean
  isCompleted?: boolean | null
  isViewMode: boolean
  onAdd?: any
  onMirrorAccess?: any
  onMirrorInputChange?: any
  hasAccessTitle?: boolean
  hasMirrorAccess?: boolean
}

const ApplicationAccessHeader: FC<ApplicationAccessHeaderProps> = ({
  accessFieldType,
  applicationSearchHandler,
  mirrorInputValue,
  searchValue,
  hasAccessTitle,
  hasAddBtn,
  hasMirrorAccess,
  isCompleted,
  isViewMode,
  onAdd,
  onMirrorAccess,
  onMirrorInputChange
}) => {
  return (
    <ApplicationAccessHeaderWrapper>
      {hasAccessTitle && (
        <HeaderTitle>
          {textConstants.APPLICATION_ACCESS_HEADER}
        </HeaderTitle>
      )}
      <ApplicationAccessSubHeaderWrapper>
        <SearchInput
          placeholder={`Search ${accessFieldType}`}
          onChange={({ target }) => applicationSearchHandler(target.value)}
          data-testid="searchInput"
          prefix={<SearchOutlined />}
          defaultValue={searchValue}
          allowClear
        />
        {!isViewMode && (
          <ApplicationHeaderRightContainer>
            {hasAddBtn && (
              <AddButton
                btnText={accessFieldType === 'application' ? textConstants.USER_ADD_APPLICATION_ACCESS_BTN_LABEL : textConstants.USER_ADD_GROUP_ACCESS_BTN_LABEL}
                onClick={onAdd}
                testId='test-id-user-access-application-btn'
              />
            )}
            {hasMirrorAccess && (
              <Input.Search
                prefix={<SearchOutlined />}
                data-testid="mirrorAccessInput"
                placeholder="Mirror Id"
                value={mirrorInputValue}
                onChange={(evt) => onMirrorInputChange(evt.target.value)}
                onSearch={onMirrorAccess}
                enterButton={<ArrowRightOutlined />}
                className={`${!mirrorInputValue ? 'search-btn-disabled' : ''}`}
                loading={isCompleted === false}
              />
            )}
          </ApplicationHeaderRightContainer>
        )}
      </ApplicationAccessSubHeaderWrapper>
    </ApplicationAccessHeaderWrapper>
  );
};

ApplicationAccessHeader.defaultProps = {
  accessFieldType: 'application',
  hasAccessTitle: true,
  hasMirrorAccess: true
};

ApplicationAccessHeader.propTypes = {
  accessFieldType: PropTypes.string,
  applicationSearchHandler: PropTypes.func,
  onAdd: PropTypes.func,
  onMirrorAccess: PropTypes.func,
  hasAccessTitle: PropTypes.bool,
  hasAddBtn: PropTypes.bool,
  isCompleted: PropTypes.bool,
  isViewMode: PropTypes.bool.isRequired,
  searchValue: PropTypes.string
};

export default ApplicationAccessHeader;
