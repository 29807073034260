import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { ApplicationAccessMainWrapper, ApplicationAccessSummaryAccessColWrapper, ApplicationAccessSummaryRowWrapper, ApplicationAccessTableHeaderWrapper, ApplicationStickyWrapper } from './style';
import { userAccessRolesList, AdminRoleList } from '../../../constants/FormFields/AccessManagementForm/userAccessRoleList';
import { AccessFormItemsLayout } from '../../../constants/FormLayout/AccessFormLayout';
import ApplicationAccessHeader from './ApplicationAccessHeader';

interface componentProps {
  data?: any
  fieldName: string
  showAppAdminAccess?: boolean
}

const columnSpan = AccessFormItemsLayout.columnSpan;
const ApplicationAccessSummary: FC<componentProps> = ({ data, fieldName, showAppAdminAccess }) => {
  const [searchValue, setSearchInputValue] = useState('');

  return (
    <ApplicationAccessMainWrapper>
      <ApplicationStickyWrapper>
        <ApplicationAccessHeader
          searchValue={searchValue}
          applicationSearchHandler={setSearchInputValue}
          isViewMode
        />
        <ApplicationAccessTableHeaderWrapper>
          <Row gutter={AccessFormItemsLayout.rowGutter}>
            <Col
              key={'applicationName'}
              xs={columnSpan.xs}
              md={AccessFormItemsLayout.applicationColMdSpan}
              xxl={columnSpan.xxl}
            >
              Application Name
            </Col>
            {
              [...userAccessRolesList, ...(showAppAdminAccess ? AdminRoleList : [])]?.map(accessElementItem => {
                return (
                  <Col
                    key={accessElementItem.value}
                    xs={columnSpan.xs}
                    md={columnSpan.md}
                    xxl={columnSpan.xxl}
                  >
                    {accessElementItem.label}
                  </Col>
                )
              })
            }
            <Col key={'actions'} xs={columnSpan.xs} md={AccessFormItemsLayout.actionColSpan} xxl={AccessFormItemsLayout.actionColSpan} />
          </Row>
        </ApplicationAccessTableHeaderWrapper>
      </ApplicationStickyWrapper>
      {
        data?.[fieldName] &&
        Object.keys(data?.[fieldName])?.filter((roleItem) => roleItem.toLowerCase().includes(searchValue.toLowerCase()))?.map((roleItem, iter) => {
          return (
            <ApplicationAccessSummaryRowWrapper key={'summary-role-' + iter.toString()} gutter={AccessFormItemsLayout.rowGutter} >
              <Col key={'applicationName'} xs={columnSpan.xs}
                md={AccessFormItemsLayout.applicationColMdSpan}
                xxl={columnSpan.xxl} className='application-name'>
                {roleItem}
              </Col>
              {
                [...userAccessRolesList, ...(showAppAdminAccess ? AdminRoleList : [])]?.map(accessElementItem => {
                  return (<ApplicationAccessSummaryAccessColWrapper
                    key={accessElementItem.value}
                    xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                    {data?.[fieldName][roleItem]?.includes(accessElementItem.value) ? <CheckCircleOutlined /> : '-'}
                  </ApplicationAccessSummaryAccessColWrapper>)
                })
              }
              <Col key={'actions'} xs={columnSpan.xs} md={AccessFormItemsLayout.actionColSpan} xxl={AccessFormItemsLayout.actionColSpan} />
            </ApplicationAccessSummaryRowWrapper>
          )
        })
      }
    </ApplicationAccessMainWrapper>
  );
};

ApplicationAccessSummary.propTypes = {
  data: PropTypes.object,
  fieldName: PropTypes.string.isRequired,
  showAppAdminAccess: PropTypes.bool
};

export default ApplicationAccessSummary;
