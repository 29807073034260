import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { EXCEPTION_REPORT_CONFIG_COLUMNS } from '../../../../../constants/TableColumns/Admin/ExceptionReportConfigColumns';
import {
  getExceptionReportConfigList,
  deleteExceptionReportConfig,
  saveExceptionReportConfig
} from '../../../../../redux/actions/Admin/exceptionReportConfigActions';
import type { SelectedRows } from '../../../../../constants/Interfaces/AdminConfigListTypes';
import { EXCEPTION_REPORT_ID } from '../../../../../constants/KeyLabels/commonKeyConstants';
import { exceptionReportConfigItemKey } from '../../../../../constants/KeyLabels/KeysToCheckForDuplicateItems';
import { AdminMainLayoutWrapper } from '../../../../../assets/styles/commonStyled';
import { checkIfExistsInArray } from '../../../../../helpers/commonHelper';
import { errorNotification } from '../../../../common/Notification';
import ExceptionReportConfigForm from '../../../UpsertForms/Admin//ExceptionReportConfig';
import textConstants from '../../../../../constants/textConstants';
import routeConstants from '../../../../../constants/routeConstants';
import AddEditConfigDrawer from '../../../Drawer/AddConfigDrawer';
import ConfigurationListTable from '../../../ConfigurationListTable';

const ExceptionReportConfigTab: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const exceptionReportConfigList = useSelector(
    (state: any) => state.exceptionReportConfig.exceptionReportConfigList
  );

  const [isAddEditDrawerOpen, setAddEditDrawerVisibility] = useState<boolean>(false);
  const [isMultipleModify, setMultipleModifyState] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<SelectedRows>([]);
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);

  const handleItemNavigation = (currentIndex: number): void => {
    setActiveItemIndex(currentIndex);
  }

  const onAddEditDrawerClose = (): void => {
    setAddEditDrawerVisibility(false);
    setMultipleModifyState(false);
    setActiveItemIndex(0);
  }

  const onCreateBtnClick = (): void => {
    setSelectedRows([]);
    setAddEditDrawerVisibility(true);
  }

  const onMultipleModify = (selectedList: SelectedRows): void => {
    setMultipleModifyState(true);
    setSelectedRows(selectedList);
    setAddEditDrawerVisibility(true);
  }

  const toggleAddDrawerVisibility = (isOpen: boolean): void => {
    setAddEditDrawerVisibility(isOpen);
  };

  const onExceptionReportConfigSave = (data: any, isClosable?: boolean): void => {
    const filteredExceptionReportConfigList = exceptionReportConfigList.filter((item: any) => item.configID !== data.configID);

    if (!isMultipleModify && !checkIfExistsInArray(exceptionReportConfigList, data, exceptionReportConfigItemKey)) {
      dispatch(
        saveExceptionReportConfig(data, isClosable ? toggleAddDrawerVisibility(false) : null, true)
      );
    } else if (isMultipleModify && !checkIfExistsInArray(filteredExceptionReportConfigList, data, exceptionReportConfigItemKey)) {
      dispatch(saveExceptionReportConfig(data, null, false));
    } else {
      errorNotification(textConstants.EXCEPTION_REPORT_CONFIG_ALREADY_EXIST_MESSAGE);
    }
  };

  useEffect(() => {
    if (isMultipleModify) {
      const updatedConfigId = selectedRows[activeItemIndex][EXCEPTION_REPORT_ID];

      const updatedConfigData = exceptionReportConfigList.find((item: any) => item[EXCEPTION_REPORT_ID] === updatedConfigId);

      const clonedSelectedRows = cloneDeep(selectedRows);
      clonedSelectedRows[activeItemIndex] = updatedConfigData;
      setSelectedRows(clonedSelectedRows);
    }
  }, [exceptionReportConfigList, isMultipleModify]);

  return (
    <AdminMainLayoutWrapper data-testid="exceptionReportConfigList" isTabContentList>
      <ConfigurationListTable
        columnDefs={EXCEPTION_REPORT_CONFIG_COLUMNS}
        getConfigList={getExceptionReportConfigList}
        gridItemId={EXCEPTION_REPORT_ID}
        onConfigDelete={deleteExceptionReportConfig}
        onCreate={onCreateBtnClick}
        gridName={textConstants.EXCEPTION_REPORT_CONFIG_PAGE_TITLE}
        onMultipleModify={onMultipleModify}
        onRowDoubleClicked={(params: { data: any }) => {
          history.push({
            pathname: routeConstants.ADMIN_EXCEPTION_REPORT_CONFIG_DETAIL,
            state: {
              isEditable: false,
              data: params?.data
            }
          });
        }}
        onEdit={(data: any) => {
          history.push({
            pathname: routeConstants.ADMIN_EXCEPTION_REPORT_CONFIG_DETAIL,
            state: {
              isEditable: true,
              data
            }
          });
        }}
        listName="exceptionReportConfig"
        rowSelection="multiple"
        rowMultiSelectWithClick
        hasRowAction
        hasCreate
      />
      {isAddEditDrawerOpen && (
        <AddEditConfigDrawer
          isOpen={isAddEditDrawerOpen}
          dataTestId="exceptionReportConfigAddDrawer"
          title={`${isMultipleModify ? 'Modify' : 'New'} ${textConstants.EXCEPTION_REPORT_CONFIG_ADD_EDIT_DRAWER_TITLE}`}
          onCancel={onAddEditDrawerClose}
          handleItemNavigation={handleItemNavigation}
          currentItemIndex={activeItemIndex}
          selectedItemsCount={selectedRows.length - 1}
        >
          <ExceptionReportConfigForm
            data={{
              ...(isMultipleModify && { ...selectedRows[activeItemIndex] })
            }}
            onCancel={onAddEditDrawerClose}
            onSave={onExceptionReportConfigSave}
            isEditable={isMultipleModify}
            isDrawerView
          />
        </AddEditConfigDrawer>
      )}
    </AdminMainLayoutWrapper>
  );
};

export default ExceptionReportConfigTab;
