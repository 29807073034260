import { produce } from 'immer';
import { SET_EXCEPTION_REPORT_CONFIG_LIST } from '../../../actions/ActionTypes/Admin/exceptionReportConfigTypes';
import { processOperation } from '../../../../helpers/reducerHelper';

export interface ExceptionReportConfigState {
  exceptionReportConfigList: any[]
}

const initialState = {
  exceptionReportConfigList: []
};

const exceptionReportConfigReducer = (state = initialState, action: any): ExceptionReportConfigState =>
  produce(state, (draft: ExceptionReportConfigState) => {
    switch (action.type) {
      case SET_EXCEPTION_REPORT_CONFIG_LIST:
        draft.exceptionReportConfigList = processOperation(action.data, [...draft.exceptionReportConfigList]);
        break;
    }
  });

export default exceptionReportConfigReducer;
