import styled from 'styled-components';
import { Input } from 'antd';

export const ConfigColumnListWrapper = styled.div`
  margin: 10px 0 15px -12px;
  max-height: 41vh;
  overflow-y: auto;
  padding-left: 12px;
  padding-right: 5px;

  label {
    display: flex;
    justify-content: space-between;
    color: var(--gray-800);
    font-size: var(--font-size-sm);
    margin-bottom: 5px;
    cursor: move;
    position: relative;
    align-items: center;
    min-height: 20px;
    margin-right:10px;
    &:hover {
      &:before {
        content: ':::';
        position: absolute;
        transform: rotate(90deg);
        left: -12px;
        top: 3px;
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-semibold);
        line-height: 1;
        color: var(--gray-425);

        @media screen and (min-width: 1600px) {
            font-size: var(--font-size-sm);
        }
      }
    }
    span {
      color: var(--blue-color);
      font-size: 13px;
      cursor: pointer;
    }

    .anticon-eye-invisible {
      opacity: 0.85;
    }
  }
`;

export const ConfigColumnTitleWrapper = styled.div`
  position: realtive;

  .ant-btn.ant-btn-link {
    color: var(--blue-color);
    position: absolute;
    right: 10px;
    top: 11px;
    font-size: var(--font-size-sm);
    text-transform: capitalize;
    min-height: auto;

    &:hover {
      color: var(--blue-color);
    }
  }
`;

export const ConfigColumnSearchInput = styled(Input)`
  .ant-input-group {
    .ant-input-affix-wrapper {
      padding-left: 27px;
      height: 35px;
      border-radius: 3px;

      .ant-input {
        height: 100%;
        font-size: var(--font-size-sm);
        color: var(--gray-color);
      }
    }

    .ant-input-group-addon {
      background: transparent;
      border: none;
      position: absolute;
      z-index: 99;
      top: 50%;
      left: -2px;
      transform: translateY(-50%);
      color: var(--gray-225);

      .anticon-search {
        font-size: var(--font-size-lg);
      }
    }
  }
`;

export const ConfigColumnPopoverWrapper = styled.div`
  padding: 0 5px;

  h1 {
    font-size: var(--popover-title-font-size);
    font-weight: var(--popover-title-font-weight);
    color: var(--popover-title-color);
    margin-bottom: 10px;
  }

  .ant-checkbox-wrapper {
    color: var(--gray-800);
    font-size: var(--font-size-sm);
    margin-bottom: 7px;
  }

  .ant-btn {
    text-transform: uppercase;
  }
`;
