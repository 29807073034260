const additionalColumnProp = {
  minWidth: 150,
  flex: 1,
  valueFormatter: (params: { value: any }) => {
    console.log(params, '--', params.value);
    return params?.value
      ? params?.value?.toString().trim().length === 0
        ? '-'
        : params.value
      : '-';
  }
};
export const SOLMAN_STAGING_CONFIG_COLUMNS = [
  {
    field: 'applicationName',
    headerName: 'Application Name',
    sortable: true,
    ...additionalColumnProp
  },

  {
    field: 'interfaceID',
    headerName: 'Interface',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'division',
    headerName: 'Divison',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'busErrorCode',
    headerName: 'Bus Error Code',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'busErrorGroup',
    headerName: 'Bus Error Group',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'enable',
    headerName: 'Enable',
    sortable: true,
    cellRenderer: function (params: any) {
      return params.value ? 'Yes' : 'No';
    }
  },
  {
    field: 'useTechErrCode',
    headerName: 'Use Tech Error Code',
    sortable: true,
    cellRenderer: function (params: any) {
      return params.value ? 'Yes' : 'No';
    }
  }
];
