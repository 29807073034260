export const ApplicationCategoryFormFields: Record<string, any> = [
  {
    label: 'Application Name',
    name: 'applicationName',
    id: 'applicationName',
    required: true,
    rules: [{ required: true, message: 'Application is mandatory!' }],
    type: 'select',
    disabled: true
  },
  {
    label: 'Category Name',
    name: 'exceptionCategoryName',
    id: 'exceptionCategoryName',
    type: 'text',
    required: true,
    rules: [{ required: true, message: 'Category Name is mandatory!' }]
  },
  {
    label: 'Category Description',
    name: 'exceptionCategoryDescription',
    id: 'exceptionCategoryDescription',
    type: 'text',
    required: true,
    rules: [{ required: true, message: 'Category Description is mandatory!' }]
  }
];

export const ApplicationTypeFormFields: Record<string, any> = [
  {
    label: 'Application Name',
    name: 'applicationName',
    id: 'applicationName',
    required: true,
    rules: [{ required: true, message: 'Application is mandatory!' }],
    type: 'select',
    disabled: true
  },
  {
    label: 'Type Name',
    name: 'exceptionTypeName',
    id: 'exceptionTypeName',
    type: 'text',
    required: true,
    rules: [{ required: true, message: 'Type Name is mandatory!' }]
  },
  {
    label: 'Type Description',
    name: 'exceptionTypeDescription',
    id: 'exceptionTypeDescription',
    type: 'text',
    required: true,
    rules: [{ required: true, message: 'Type Description is mandatory!' }]
  }
];

export const ApplicationSeverityFormFields: Record<string, any> = [
  {
    label: 'Application Name',
    name: 'applicationName',
    id: 'applicationName',
    required: true,
    rules: [{ required: true, message: 'Application is mandatory!' }],
    type: 'select',
    disabled: true
  },
  {
    label: 'Severity',
    name: 'exceptionSeverityName',
    id: 'exceptionSeverityName',
    type: 'number',
    required: true,
    rules: [{ required: true, message: 'Severity Name is mandatory!' }],
    additionalProps: {
      min: 1
    }
  },
  {
    label: 'Severity Description',
    name: 'exceptionSeverityDescription',
    id: 'exceptionSeverityDescription',
    type: 'text',
    required: true,
    rules: [{ required: true, message: 'Severity Description is mandatory!' }]
  }
];
