import { call, fork, put, all, takeLatest } from 'redux-saga/effects';
import type { AllEffect, CallEffect, ForkEffect, PutEffect } from 'redux-saga/effects';
import { createLogger } from 'redux-logger';
import {
  setApplicationCTSData,
  setApplicationSectorList
} from '../../actions/Admin/applicationConfigActions';
import { apiRequest } from '../../../services/axiosInterceptor';
import * as types from '../../actions/ActionTypes/Admin/applicationConfigTypes';
import apiURL from '../../../constants/apiEndpointConstants';
import {
  DELETE_APPLICATION_EXCEPTION_TYPE,
  GET_APPLICATION_SECTOR_LIST,
  SAVE_APPLICATION_EXCEPTION_CATEGORY,
  SAVE_APPLICATION_EXCEPTION_SEVERITY,
  SAVE_APPLICATION_EXCEPTION_TYPE
} from '../../actions/ActionTypes/Admin/applicationConfigTypes';
import {
  EXCEPTION_CATEGORY_ID,
  EXCEPTION_SEVERITY_ID,
  EXCEPTION_TYPE_ID
} from '../../../constants/KeyLabels/commonKeyConstants';
import { API_SUCCESS_STATUS_CODE } from '../../../constants/commonConstants';
import { successNotification } from '../../../components/common/Notification';
import textConstants from '../../../constants/textConstants';
import { processOperationOnStorage } from '../../../helpers/sessionStorageHelper';

const callEffect: any = call;

// export function * fetchExceptionCategoryByAppName (payload) {
//   try {
//     const SECOND = 1000;
//     const applicationCategoryData = yield retry(3, 10 * SECOND, defaultAction, payload);
//     if (applicationCategoryData) {
//       let categories = JSON.parse(sessionStorage.getItem('applicationCategoryData'));

//       categories = categories ? [...categories, ...applicationCategoryData] : [...applicationCategoryData];

//       sessionStorage.setItem(
//         'applicationCategoryData',
//         JSON.stringify(categories)
//       );
//     }
//     yield put(updateExceptionCSTData({ data: applicationCategoryData, type: 'categories' }));
//   } catch (e) {
//     logger(e);
//     toast.error(`Oops! Something went wrong ${e}`);
//   }
// }

// export function * getApplicationExceptionCategory () {
//   yield takeLatest(types.GET_APPLICATION_EXCEPTION_CATEGORY, fetchExceptionCategoryByAppName);
// }

export function * fetchUserAccessExceptionCategory (): Generator<
CallEffect<any> | PutEffect<any>,
void
> {
  try {
    const config = {
      method: 'get',
      url: apiURL.GET_EXCEPTION_CATEGORY
    };

    const response: any = yield callEffect(apiRequest, config);
    if (response) {
      sessionStorage.setItem('applicationCategoryData', JSON.stringify(response.data));
      yield put(
        setApplicationCTSData({
          action: 'NEW',
          data: response.data,
          listType: 'categories'
        })
      );
    }
  } catch (err: any) {
    createLogger(err);
  }
}

export function * getUserAccessExceptionCategory (): Generator<ForkEffect<never>> {
  yield takeLatest(types.GET_ALL_EXCEPTION_CATEGORY, fetchUserAccessExceptionCategory);
}

// export function * fetchExceptionSeverityByAppName (payload) {
//   try {
//     const SECOND = 1000;
//     const applicationSeverityData = yield retry(3, 10 * SECOND, defaultAction, payload);
//     if (applicationSeverityData) {
//       let severities = JSON.parse(sessionStorage.getItem('applicationSeverityData'));

//       severities = severities ? [...severities, ...applicationSeverityData] : [...applicationSeverityData];
//       sessionStorage.setItem(
//         'applicationSeverityData',
//         JSON.stringify(severities)
//       );
//     }
//     yield put(updateExceptionCSTData({ data: applicationSeverityData, type: 'severities' }));
//   } catch (e) {
//     logger(e);
//     toast.error(`Oops! Something went wrong ${e}`);
//   }
// }

// export function * getApplicationExceptionSeverity () {
//   yield takeLatest(types.GET_APPLICATION_EXCEPTION_SEVERITY, fetchExceptionSeverityByAppName);
// }

export function * fetchUserAccessExceptionSeverity (): Generator<
CallEffect<any> | PutEffect<any>,
void
> {
  try {
    const config = {
      method: 'get',
      url: apiURL.GET_EXCEPTION_SEVERITY
    };

    const response: any = yield callEffect(apiRequest, config);

    if (response) {
      sessionStorage.setItem('applicationSeverityData', JSON.stringify(response.data));
      yield put(
        setApplicationCTSData({
          action: 'NEW',
          data: response.data,
          listType: 'severities'
        })
      );
    }
  } catch (err: any) {
    createLogger(err);
  }
}

export function * getUserAccessExceptionSeverity (): Generator<ForkEffect<never>> {
  yield takeLatest(types.GET_ALL_EXCEPTION_SEVERITY, fetchUserAccessExceptionSeverity);
}

// export function * fetchExceptionTypeByAppName (payload) {
//   try {
//     const SECOND = 1000;
//     const applicationTypeData = yield retry(3, 10 * SECOND, defaultAction, payload);
//     if (applicationTypeData) {
//       let types = JSON.parse(sessionStorage.getItem('applicationTypeData'));
//       types = types ? [...types, ...applicationTypeData] : [...applicationTypeData];
//       sessionStorage.setItem(
//         'applicationTypeData',
//         JSON.stringify(types)
//       );
//     }
//     yield put(updateExceptionCSTData({ data: applicationTypeData, type: 'types' }));
//   } catch (e) {
//     logger(e);
//     toast.error(`Oops! Something went wrong ${e}`);
//   }
// }

// export function * getApplicationExceptionType () {
//   yield takeLatest(types.GET_APPLICATION_EXCEPTION_TYPE, fetchExceptionTypeByAppName);
// }

export function * fetchUserAccessExceptionType (): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: 'get',
      url: apiURL.GET_EXCEPTION_TYPE
    };
    const response: any = yield callEffect(apiRequest, config);

    if (response) {
      sessionStorage.setItem('applicationTypeData', JSON.stringify(response.data));
      yield put(
        setApplicationCTSData({ action: 'NEW', data: response.data, listType: 'types' })
      );
    }
  } catch (err: any) {
    createLogger(err);
  }
}

export function * getUserAccessExceptionType (): Generator<ForkEffect<never>> {
  yield takeLatest(types.GET_ALL_EXCEPTION_TYPE, fetchUserAccessExceptionType);
}

export function * fetchApplicationSectorList (
  payload: any
): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const apiConfig = {
      method: 'get',
      url: apiURL.GET_APPLICATION_SECTOR_LIST
    };
    const response: any = yield callEffect(apiRequest, apiConfig);

    if (response && response.status === 200) {
      yield put(setApplicationSectorList(response?.data));
    }
  } catch (err: any) {
    createLogger(err);
  }
}
export function * getApplicationSectorList (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_APPLICATION_SECTOR_LIST, fetchApplicationSectorList);
}

export function * saveApplicationCategorySaga (
  payload: any
): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: payload?.isAdd ? 'POST' : 'PUT',
      url: apiURL.APPLICATION_EXCEPTION_CATEGORY_CONFIG,
      data: payload.data
    };
    const response: any = yield callEffect(apiRequest, config);
    if (response.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        action: payload?.isAdd ? 'ADD' : 'UPDATE',
        data: response?.data,
        listType: 'categories',
        ...(!payload?.isAdd && {
          keyName: EXCEPTION_CATEGORY_ID,
          keyValue: response.data[EXCEPTION_CATEGORY_ID]
        })
      };
      yield put(setApplicationCTSData(storePayload));
      processOperationOnStorage('applicationCategoryData', payload?.isAdd ? 'ADD' : 'UPDATE', response?.data, EXCEPTION_CATEGORY_ID);
    }
  } catch (err: any) {
    console.log(err);
    createLogger(err);
  }
}

export function * saveApplicationCategory (): Generator<ForkEffect<never>> {
  yield takeLatest(SAVE_APPLICATION_EXCEPTION_CATEGORY, saveApplicationCategorySaga);
}

export function * deleteApplicationCategorySaga (
  payload: any
): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: 'DELETE',
      url: apiURL.APPLICATION_EXCEPTION_CATEGORY_CONFIG,
      data: payload.data
    };
    const response: any = yield callEffect(apiRequest, config);
    if (response.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        action: 'DELETE',
        listType: 'categories',
        keyName: EXCEPTION_CATEGORY_ID,
        keyValue: response.data[0][EXCEPTION_CATEGORY_ID]
      };
      yield put(setApplicationCTSData(storePayload));
      successNotification(textConstants.CONFIG_DELETE_SUCCESS_MESSAGE);
      processOperationOnStorage('applicationCategoryData', 'remove', response?.data, EXCEPTION_CATEGORY_ID);
    }
  } catch (err: any) {
    console.log(err);
    createLogger(err);
  }
}
export function * deleteApplicationCategory (): Generator<ForkEffect<never>> {
  yield takeLatest(types.DELETE_APPLICATION_EXCEPTION_CATEGORY, deleteApplicationCategorySaga);
}

export function * saveApplicationTypeSaga (
  payload: any
): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: payload?.isAdd ? 'POST' : 'PUT',
      url: apiURL.APPLICATION_EXCEPTION_TYPE_CONFIG,
      data: payload.data
    };
    const response: any = yield callEffect(apiRequest, config);
    if (response.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        action: payload?.isAdd ? 'ADD' : 'UPDATE',
        data: response?.data,
        listType: 'types',
        ...(!payload?.isAdd && {
          keyName: EXCEPTION_TYPE_ID,
          keyValue: response.data[EXCEPTION_TYPE_ID]
        })
      };
      yield put(setApplicationCTSData(storePayload));
      processOperationOnStorage('applicationTypeData', payload?.isAdd ? 'ADD' : 'UPDATE', response?.data, EXCEPTION_TYPE_ID);
    }
  } catch (err: any) {
    console.log(err);
    createLogger(err);
  }
}

export function * saveApplicationType (): Generator<ForkEffect<never>> {
  yield takeLatest(SAVE_APPLICATION_EXCEPTION_TYPE, saveApplicationTypeSaga);
}

export function * deleteApplicationTypeSaga (
  payload: any
): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: 'DELETE',
      url: apiURL.APPLICATION_EXCEPTION_TYPE_CONFIG,
      data: payload.data
    };
    const response: any = yield callEffect(apiRequest, config);
    if (response.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        action: 'DELETE',
        keyName: EXCEPTION_TYPE_ID,
        keyValue: response.data[0][EXCEPTION_TYPE_ID],
        listType: 'types'
      };
      yield put(setApplicationCTSData(storePayload));
      processOperationOnStorage('applicationTypeData', 'remove', response?.data, EXCEPTION_TYPE_ID);
      successNotification(textConstants.CONFIG_DELETE_SUCCESS_MESSAGE);
    }
  } catch (err: any) {
    console.log(err);
    createLogger(err);
  }
}

export function * deleteApplicationType (): Generator<ForkEffect<never>> {
  yield takeLatest(DELETE_APPLICATION_EXCEPTION_TYPE, deleteApplicationTypeSaga);
}

export function * saveApplicationSeveritySaga (
  payload: any
): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: payload?.isAdd ? 'POST' : 'PUT',
      url: apiURL.APPLICATION_EXCEPTION_SEVERITY_CONFIG,
      data: payload.data
    };
    const response: any = yield callEffect(apiRequest, config);
    if (response.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        action: payload?.isAdd ? 'ADD' : 'UPDATE',
        data: response?.data,
        listType: 'severities',
        ...(!payload?.isAdd && {
          keyName: EXCEPTION_SEVERITY_ID,
          keyValue: response.data[EXCEPTION_SEVERITY_ID]
        })
      };
      yield put(setApplicationCTSData(storePayload));
      processOperationOnStorage('applicationSeverityData', payload?.isAdd ? 'ADD' : 'UPDATE', response?.data, EXCEPTION_SEVERITY_ID);
    }
  } catch (err: any) {
    console.log(err);
    createLogger(err);
  }
}

export function * saveApplicationSeverity (): Generator<ForkEffect<never>> {
  yield takeLatest(SAVE_APPLICATION_EXCEPTION_SEVERITY, saveApplicationSeveritySaga);
}

export function * deleteApplicationSeveritySaga (
  payload: any
): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: 'DELETE',
      url: apiURL.APPLICATION_EXCEPTION_SEVERITY_CONFIG,
      data: payload.data
    };
    const response: any = yield callEffect(apiRequest, config);
    if (response.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        action: 'DELETE',
        keyName: EXCEPTION_SEVERITY_ID,
        keyValue: response.data[0][EXCEPTION_SEVERITY_ID],
        listType: 'severities'
      };
      yield put(setApplicationCTSData(storePayload));
      successNotification(textConstants.CONFIG_DELETE_SUCCESS_MESSAGE);
      processOperationOnStorage('applicationSeverityData', 'remove', response?.data, EXCEPTION_SEVERITY_ID);
    }
  } catch (err: any) {
    console.log(err);
    createLogger(err);
  }
}

export function * deleteApplicationSeverity (): Generator<ForkEffect<never>> {
  yield takeLatest(types.DELETE_APPLICATION_EXCEPTION_SEVERITY, deleteApplicationSeveritySaga);
}

export default function * root (): Generator<AllEffect<any>> {
  yield all([
    // fork(getApplicationExceptionCategory),
    fork(getUserAccessExceptionCategory),
    // fork(getApplicationExceptionSeverity),
    fork(getUserAccessExceptionSeverity),
    // fork(getApplicationExceptionType),
    fork(getUserAccessExceptionType),
    fork(getApplicationSectorList),
    fork(saveApplicationCategory),
    fork(deleteApplicationCategory),
    fork(saveApplicationType),
    fork(deleteApplicationType),
    fork(saveApplicationSeverity),
    fork(deleteApplicationSeverity)
  ]);
}
