import { produce } from 'immer';
import { SET_SOLMAN_STAGING_CONFIG_LIST } from '../../../actions/ActionTypes/Admin/solmanStagingConfigTypes';
import { processOperation } from '../../../../helpers/reducerHelper';

export interface SolmanStagingConfigState {
  solmanConfigList: any[]
}
const initialState = {
  solmanConfigList: []
};

const solmanStagingConfigReducer = (state = initialState, action: any): SolmanStagingConfigState =>
  produce(state, (draft: SolmanStagingConfigState) => {
    switch (action.type) {
      case SET_SOLMAN_STAGING_CONFIG_LIST:
        draft.solmanConfigList = processOperation(action.data, [...draft.solmanConfigList]);
        break;
    }
  });
export default solmanStagingConfigReducer;
