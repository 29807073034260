export const processOperationOnStorage = (
  key: string,
  action: string,
  data?: any,
  indexKey?: string
): void => {
  const existingData = JSON.parse(sessionStorage.getItem(key) ?? '[]');
  if (action.toLowerCase() === 'add') {
    existingData.push(data);
    sessionStorage.setItem(key, JSON.stringify(existingData));
  } else if (action.toLowerCase() === 'update') {
    if (existingData.length > 0 && indexKey) {
      const indexToUpdate = existingData.findIndex(
        (stateObject: any) => stateObject[indexKey] === data[indexKey]
      );
      existingData[indexToUpdate] = data;
      sessionStorage.setItem(key, JSON.stringify(existingData));
    }
  } else if (action.toLowerCase() === 'remove') {
    if (existingData.length > 0 && indexKey) {
      const indexToRemove = existingData.findIndex(
        (stateObject: any) => stateObject[indexKey] === data[indexKey]
      );
      existingData.splice(indexToRemove, 1);
      sessionStorage.setItem(key, JSON.stringify(existingData));
    }
  } else if (action.toLowerCase() === 'clear') {
    sessionStorage.removeItem(key);
  }
};
