import { produce } from 'immer';
import { SET_REPORT_CONFIG_LIST } from '../../../actions/ActionTypes/Admin/reportConfigTypes';
import { processOperation } from '../../../../helpers/reducerHelper';

export interface ReportConfigState {
  reportConfigList: any[]
}

const initialState = {
  reportConfigList: []
};

const reportConfigReducer = (state = initialState, action: any): ReportConfigState =>
  produce(state, (draft: ReportConfigState) => {
    switch (action.type) {
      case SET_REPORT_CONFIG_LIST :
        draft.reportConfigList = processOperation(action.data, [...draft.reportConfigList]);
        break;
    }
  });

export default reportConfigReducer;
