import React from 'react';
import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import AdminDetailBreadCrumb from '../../../../components/common/BreadCrumb/AdminDetailBreadCrumb';
import TableConfigSummary from '../../../../components/AdditionalComponents/DetailSummaryPage/Admin/TableConfig/TableConfigSummary';
import ConfigDetailCardHeader from '../../../../components/AdditionalComponents/ConfigDetailCardHeader';
import { ConfigDetailWrapper } from '../../../../components/AdditionalComponents/DetailSummaryPage/Admin/style';
import { AdminMainLayoutWrapper } from '../../../../assets/styles/commonStyled';
import textConstants from '../../../../constants/textConstants';

const TableConfigDetail: FC = () => {
  const location = useLocation();
  const { data } = location?.state as { data: any } ?? {};

  return (
    <AdminMainLayoutWrapper data-testid="tableConfigDetail">
      <AdminDetailBreadCrumb
        mainPageText={textConstants.TABLE_CONFIG_PAGE_TITLE}
        currentPageText={data?.tableName}
      />
      <ConfigDetailWrapper>
        <ConfigDetailCardHeader
          isDeleteEnabled={false}
          title={textConstants.TABLE_CONFIG_DETAIL_PAGE_TITLE}
          configName={textConstants.TABLE_CONFIG_PAGE_TITLE}
        />
        <TableConfigSummary
          data={data}
        />
      </ConfigDetailWrapper>
    </AdminMainLayoutWrapper>
  );
}

export default TableConfigDetail;
