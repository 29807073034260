import React from 'react';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import type { DashboardMetricData } from '../../../constants/Interfaces/DashboardTypes';
import Tabs from '../../common/Tabs';
import { LOG_METRIC_TAB_KEY, EXCEPTION_METRIC_TAB_KEY, REPLAY_METRIC_TAB_KEY } from '../../../constants/KeyLabels/commonKeyConstants';
import { DashboardMetricHeader, DashboardMetricTitle, DashboardMetricWrapper } from './style';
import LineChart from '../../common/LineChart';
import PieChart from '../../common/PieChart';
import { convertDataForLineChartMetrics, convertDataForPieChartMetrics } from '../../../helpers/dashboardHelper';
import textConstants from '../../../constants/textConstants';

interface DashboardMetricProps {
  hasReplayMetrics: boolean
  metricSearchType?: string
  data: DashboardMetricData
  onMetricTabChange: (tabName: string) => void
  title?: string
}

const DashboardMetric: FC<DashboardMetricProps> = ({ data, hasReplayMetrics, metricSearchType, onMetricTabChange, title }) => {
  return (
    <DashboardMetricWrapper>
      <Tabs
        tabClassName={`basic-theme h-100 ${hasReplayMetrics ? 'tab-with-replay-metrics' : ''}`}
        tabSize="small"
        tabType="line"
        tabExtraContent={{
          left: (
            <DashboardMetricHeader>
              <DashboardMetricTitle>{title}</DashboardMetricTitle>
            </DashboardMetricHeader>
          )
        }}
        onTabChangeHandler={onMetricTabChange}
        wrapperClassName="h-100"
        animated
        tabItems={
          hasReplayMetrics
            ? [{
                key: REPLAY_METRIC_TAB_KEY,
                label: 'Replay',
                children: (
                  <PieChart
                    data={convertDataForPieChartMetrics(data.replayData)}
                    emptyDataMessage={textConstants.DASHBOARD_REPLAY_METRIC_DATA_NOT_FOUND}
                  />
                )
              }]
            : [{
                key: LOG_METRIC_TAB_KEY,
                label: 'Logs',
                children: (
                  <LineChart
                    data={convertDataForLineChartMetrics(data.logData)}
                    legendPlaceholder={`Filter ${metricSearchType ?? 'Application'}s`}
                    chartType='logs'
                  />
                )
              },
              {
                key: EXCEPTION_METRIC_TAB_KEY,
                label: 'Exceptions',
                children: (
                  <LineChart
                    data={convertDataForLineChartMetrics(data.exceptionData)}
                    legendPlaceholder={`Filter ${metricSearchType ?? 'Application'}s`}
                    chartType='exceptions'
                  />
                )
              }]
        }
      />
    </DashboardMetricWrapper>
  )
}

DashboardMetric.propTypes = {
  data: PropTypes.any.isRequired,
  hasReplayMetrics: PropTypes.bool.isRequired,
  metricSearchType: PropTypes.string,
  onMetricTabChange: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default DashboardMetric;
