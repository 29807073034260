import { produce } from 'immer';
import { SET_COLUMN_ALERT_CONFIG_LIST } from '../../../actions/ActionTypes/Admin/columnAlertConfigTypes';
import { processOperation } from '../../../../helpers/reducerHelper';

export interface ColumnAlertConfigState {
  columnAlertConfigList: any[]
}

const initialState = {
  columnAlertConfigList: []
};

const columnAlertConfigReducer = (state = initialState, action: any): ColumnAlertConfigState =>
  produce(state, (draft: ColumnAlertConfigState) => {
    switch (action.type) {
      case SET_COLUMN_ALERT_CONFIG_LIST:
        draft.columnAlertConfigList = processOperation(action.data, [...draft.columnAlertConfigList]);
        break;
    }
  });

export default columnAlertConfigReducer;
