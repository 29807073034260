import styled from 'styled-components';

export const ListConfigMainWrapper = styled.div`
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;
    +.add-edit-config-drawer {
        .ant-drawer-content-wrapper {
            max-width: 50vw;
        }
    }
`;
export const ListConfigTableSectionWrapper = styled.div`
    margin: 5px;
    > div > div {
        margin-bottom: 5px;
    }
`;
export const ListConfigTableSectionHeaderWrapper = styled.div`
    display: flex;
    justify-content: end;
`;
