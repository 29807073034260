import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { deleteGroupApplicationAccess, getGroupApplicationAccessList, getGroupList, saveGroupApplicationAccess } from '../../../../../redux/actions/AccessManagement/GroupAccess/groupAccessActions';
import ApplicationAccess from '../../../../common/AccessComponent/ApplicationAccess';
import { GroupApplicationAccessForm } from '../../../../common/AccessComponent/style';
import Footer from '../../../../common/Footer';
import { getUpdatedApplicationAccessConfig } from '../../../../../helpers/applicationDataHelper';

interface GroupAccessTabProps {
  isEditable?: boolean
  appName: string
  onToggleEditMode: any
}

const GroupAccessTab: FC<GroupAccessTabProps> = ({ appName, isEditable, onToggleEditMode }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const applicationAccessList = Form.useWatch('appAccessList', form);

  const groupApplicationAccessList = useSelector((state: any) => state.groupConfig.groupApplicationAccessList);
  const groupConfigList = useSelector((state: any) => state.groupConfig.groupConfigList);
  const [applicationAccessDataList, setGroupApplicationAccessDataList] = useState([]);

  const groupList = groupConfigList?.map((item: any) => ({
    groupName: item.groupName,
    label: item.groupName,
    value: item.groupName
  }));

  const onReset = (): void => {
    form.setFieldValue('appAccessList', applicationAccessDataList);
  }

  const onSaveHandler = (): void => {
    form
      .validateFields()
      .then(async (values) => {
        const data = getUpdatedApplicationAccessConfig(applicationAccessDataList, values?.appAccessList, 'groupName', 'accessName', '', appName);

        if (data.added && data.added.length > 0) {
          dispatch(saveGroupApplicationAccess(data.added, null, true));
        }

        if (data?.updated && data?.updated?.length > 0) {
          dispatch(saveGroupApplicationAccess(data.updated, null));
        }

        if (data?.deleted && data?.deleted?.length > 0) {
          dispatch(deleteGroupApplicationAccess(data.deleted, null));
        }
        onToggleEditMode(false);
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    if (groupApplicationAccessList.length < 1) {
      dispatch(getGroupApplicationAccessList());
    }

    if (groupConfigList.length < 1) {
      dispatch(getGroupList());
    }
  }, []);

  useEffect(() => {
    if (appName) {
      const groupApplicationAccessData = groupApplicationAccessList.filter((item: any) => item?.applicationName === appName);

      setGroupApplicationAccessDataList(groupApplicationAccessData);
      form.setFieldValue('appAccessList', groupApplicationAccessData);
    }
  }, [groupApplicationAccessList])

  return (
    <GroupApplicationAccessForm
      data-testid="group-application-access-form"
      form={form}
    >
      <ApplicationAccess
        accessType='group'
        formRef={form}
        fieldName={'appAccessList'}
        selectedApplication={applicationAccessList}
        isViewMode={!isEditable}
        allowClear={false}
        isMultiSelect={false}
        hasAccessTitle={false}
        hasMirrorAccess={false}
        optionList={groupList}
      />
      {isEditable && (
        <Footer
          className='grey-theme add-form-footer'
          onSave={onSaveHandler}
          onReset={onReset}
          save
          reset
        />
      )}
    </GroupApplicationAccessForm>
  )
}

GroupAccessTab.propTypes = {
  appName: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onToggleEditMode: PropTypes.func.isRequired
};

export default GroupAccessTab;
