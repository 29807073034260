import styled, { css } from 'styled-components';
import { Button } from 'antd';

const textUpperCaseStyle = css`
  text-transform: uppercase;
`;
export const CancelButton = styled(Button)`
  ${textUpperCaseStyle}
`;
export const ViewDetailButton = styled(Button)`
  ${textUpperCaseStyle}
  &.ant-btn {
    border-radius: 2px;
  }
`;

const basicButtonStyle = css`
  &.ant-btn-default {
    color: var(--white);
  }
  &:hover,
  &:focus,
  &:active {
    color: var(--white) !important;
  }
  border: 0px;
`;

export const ReplayButton = styled(Button)`
  ${textUpperCaseStyle}
  ${basicButtonStyle}
    background: var(--green-color-100);
  box-shadow: 0px 2px 3px #0047ba61;
  border: 1px solid var(--green-color-100);
  &.ant-btn-default {
    border-color: var(--green-color-100);
  }

  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background: var(--green-color-100);
    border: 1px solid var(--green-color-100);
    color: var(--white);
  }
`;

export const SaveButton = styled(Button)<{ isSecondary?: boolean }>`
  ${textUpperCaseStyle}
  &.ant-btn {
    border-radius: 2px;
  }

  ${({ isSecondary }) =>
    isSecondary &&
    css`
      background: var(--green-color-100);
      box-shadow: 0px 2px 3px #0047ba61;
      border: 1px solid var(--green-color-100);

      &.ant-btn-default {
        border-color: var(--green-color-100);
        color: var(--white);
      }

      &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
        background: var(--green-color-100);
        border: 1px solid var(--green-color-100);
        color: var(--white);
      }
    `}
`;

export const SearchButton = styled(Button)`
  background-color: var(--blue-search-color);
  border-color: var(--blue-search-color) !important;
  color: var(--white);
`;

export const ExportButton = styled(Button)`
  background-color: var(--gray-color);
  border-color: var(--gray-color) !important;
  color: var(--white);
`;

export const DefaultButton = styled(Button)`
  border-color: var(--primary-color);
  color: var(--primary-color);
  margin-right: 5px;
  &:hover,
  &:focus,
  &:active {
    border-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
  }
  &:disabled {
    border-color: var(--default-border-color) !important;
    color: rgba(0, 0, 0, 0.25) !important;
  }
`;

export const DefaultWithBackgroundButton = styled(Button)`
  background-color: var(--primary-color);
  border-color: var(--primary-color) !important;
  color: var(--white);
  &:hover,
  &:focus,
  &:active {
    color: var(--white) !important;
  }
`;

export const EditIconButton = styled(Button)`
  color: var(--primary-color);

  &.ant-btn.ant-btn-icon-only {
    width: 30px;
    height: 30px;
    padding: 0;
  }

  &.ant-btn-icon-only .anticon {
    font-size: calc(var(--font-size-md) * 1.1);
  }
`;

export const DeleteIconButton = styled(Button)`
  color: var(--primary-color);

  &.ant-btn.ant-btn-icon-only {
    width: 30px;
    height: 30px;
    padding: 0;
  }

  &.ant-btn-icon-only .anticon {
    font-size: calc(var(--font-size-md) * 1.1);
  }
`;
