const additionalColumnProp = {
  minWidth: 150,
  flex: 1,
  valueFormatter: (params: { value: any }) => {
    return params?.value ? params?.value?.toString().trim().length === 0 ? '-' : params.value : '-';
  }
};
export const SCHEDULE_CONFIG_COLUMNS = [
  {
    field: 'applicationName',
    headerName: 'Application Name',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'service',
    headerName: 'Service',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'component',
    headerName: 'Component',
    sortable: true,
    ...additionalColumnProp,
    minWidth: 200
  },
  {
    field: 'category',
    headerName: 'Category',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'type',
    headerName: 'Type',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'code',
    headerName: 'Code',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'message',
    headerName: 'Message',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'messageNVP',
    headerName: 'Message NVP',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'dumpAnalysis',
    headerName: 'Dump Analysis',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'replayEnabled',
    headerName: 'Replay Enabled',
    sortable: true,
    cellRenderer: function (params: any) {
      return params.value ? 'Yes' : 'No';
    }
  },
  {
    field: 'scheduledTime',
    headerName: 'Scheduled Time',
    sortable: true
  },
  {
    field: 'searchWindow',
    headerName: 'Search Window',
    sortable: true
  }
]
