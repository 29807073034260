import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { BlobMetricsCountBarWrapper, MetricsCountItemWrapper, MetricsCountLabel, MetricsCountValue } from './style';
import type { BlobMetricsCountBarData, BlobMetricsCountLabelKeys } from '../../../../constants/Interfaces/BlobMetricsTypes';

interface BlobMetricsCountBarProps {
  data?: BlobMetricsCountBarData
}

const blobMetricsCountLabelKeys: BlobMetricsCountLabelKeys = {
  totalCount: 'Total Records',
  exceptionCount: 'Total Exceptions',
  logCount: 'Total Logs',
  maxDataSize: 'Maximum data size'
}

const BlobMetricsCountBar: FC<BlobMetricsCountBarProps> = ({ data }) => {
  return (
    <BlobMetricsCountBarWrapper>
      {
        data && Object.keys(data).map((keyInObject: string) => {
          return (
            <MetricsCountItemWrapper key={keyInObject}>
              <MetricsCountValue>
                {data[keyInObject as keyof BlobMetricsCountLabelKeys] ?? 0}
              </MetricsCountValue>
              <MetricsCountLabel>
                {blobMetricsCountLabelKeys[keyInObject as keyof BlobMetricsCountLabelKeys]}
              </MetricsCountLabel>
            </MetricsCountItemWrapper>
          )
        })
      }
    </BlobMetricsCountBarWrapper>
  );
};

BlobMetricsCountBar.propTypes = {
  data: PropTypes.any
};

export default BlobMetricsCountBar;
