import styled from 'styled-components';
import { Tag } from 'antd';

export const CustomTag = styled.div`
    // margin: 3px 5px;
    // padding: 4px 10px;
    // border-radius:15px;
    // font-size: var(--font-size-md);
    // .ant-tag-close-icon {
    //     margin-left: 15px;
    //     padding: 4px;
    //     background: var(--gray-color);
    //     border-radius: 50%;
    // }
    // &.blue-theme{
    //     padding: 4px 8px;
    //     border-radius:0px;
    //     border: 1px dashed var(--blue-search-color);
    //     background:var(--white-300);
    //     .ant-tag-close-icon {
    //         background-color: transparent;
    //         border:0px;
    //         color: var(--tag-text-color);
    //         margin-left: 10px;
    //         padding: 4px;
    //     }
    // }
`;
export const TagWrapper = styled(Tag)`
    position: relative;
    margin: 3px 5px;
    padding: 4px 10px;
    border-radius: 15px;
    font-size: var(--font-size-md);

    .ant-tag-close-icon {
        margin-left: 15px;
        padding: 4px;
        background: var(--gray-color);
        border-radius: 50%;
    }

    &.blue-theme {
        padding: 5px 8px;
        padding-bottom: 3px;
        border-radius: 2px;
        border: 1px dashed var(--blue-color-900);
        background: var(--white-300);
        display: flex;
        align-items: flex-end;
        font-size: var(--font-size-sm);

        label {
            background: var(--gray-150);
            color: var(--tag-text-color-secondary);
        }

        .ant-tag-close-icon {
            background-color: transparent;
            border: 0px;
            color: var(--tag-text-color-secondary);
            margin-left: 5px;
            padding: 0 2px 6px;
        }
    }
`;

export const FloatingTagWrapper = styled.div`
    height: 20px;

    span {
        position: relative;
        left: 3px;
        top: -15px;
        font-size: 11px;
        padding: 0px 3px;
        line-height: 1;
        background: var(--gray-150);
        color: var(--tag-text-color-secondary);
    }

    .tag-text-wrapper {
        color: var(--black-100);
        font-weight: var(--font-weight-medium);
        top: -20px;
        position: relative;
    }
`;
