import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  CategoryScale,
  LinearScale,
  Chart as ChartJS,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import type { LegendItem } from 'chart.js';
import { Button, Empty } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { lineChartConfig } from './lineChartConfig';
import FloatingLabel from '../FloatingLabel';
import { ChartControlsWrapper } from '../../AdditionalComponents/DashboardMetric/style';
import textConstants from '../../../constants/textConstants';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface LineChartProps {
  chartType?: string
  data: any
  legendPlaceholder?: string
}

const LineChart: FC<LineChartProps> = ({ chartType, data, legendPlaceholder }) => {
  const chartRef = useRef<any>(null);
  const [selectedList, setSelectedList] = useState<LegendItem[]>([]);
  const [legendOptions, setLegendData] = useState<LegendItem[]>([]);

  const handleLegendClick = (selectedData: LegendItem[]): void => {
    const chartInstance = chartRef?.current;

    const selectedItems = selectedData.map((item: LegendItem) => item.text);

    legendOptions.forEach(function (item: LegendItem) {
      chartInstance.getDatasetMeta(item.datasetIndex).hidden = !selectedItems.includes(item.text);
    });

    chartInstance.update(); // re-draw chart to hide dataset
    setSelectedList(selectedData);
  };

  const onVisibilityChange = (): void => {
    chartRef?.current?.update();
  }

  const setCanvasBackground = (): void => {
    const chart = chartRef?.current;
    chart.ctx.save();
    chart.ctx.globalCompositeOperation = 'destination-over';
    chart.ctx.fillStyle = 'white';
    chart.ctx.fillRect(0, 0, chart.canvas.width, chart.canvas.height);
    chart.ctx.restore();
  }

  const onExport = (): void => {
    setCanvasBackground();
    const link = document.createElement('a');
    link.download = `${chartType}-chart.png`;
    link.href = chartRef?.current.toBase64Image('image/png', 1);
    link.click();
  }

  useEffect(() => {
    const legendItems = chartRef?.current?.legend?.legendItems.map((item: LegendItem) => {
      return {
        ...item,
        label: item.text,
        value: item.datasetIndex
      };
    });
    setSelectedList(legendItems);
    setLegendData(legendItems ?? []);
  }, [data]);

  useLayoutEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => document.removeEventListener('visibilitychange', onVisibilityChange);
  }, []);

  return (
    <>
      {legendOptions.length > 0 && (
        <ChartControlsWrapper>
          <FloatingLabel
            type="multiSelect"
            id="applicationName"
            options={legendOptions}
            value={selectedList}
            onChange={handleLegendClick}
            label={''}
            searchInputPlaceholder={legendPlaceholder}
            showSelectedTags={false}
            multi
          />
          <Button onClick={onExport} icon={<DownloadOutlined />}>
            Export
          </Button>
        </ChartControlsWrapper>
      )}
      {data?.labels.length < 1
        ? <Empty description={textConstants.METRIC_DATA_NOT_AVAILABLE} />
        : (
          <Line
            data-testid="lineChart"
            ref={chartRef}
            options={lineChartConfig}
            data={data}
          />
          )
      }
    </>
  );
};

LineChart.defaultProps = {
  chartType: 'metric'
}

LineChart.propTypes = {
  chartType: PropTypes.string,
  data: PropTypes.any,
  legendPlaceholder: PropTypes.string
};

export default LineChart;
