import { type MouseEventHandler } from 'react';
import { Modal } from 'antd';

const { confirm, info } = Modal;

export const confirmToast = (title?: string, content?: string, onOk?: MouseEventHandler, onCancel?: MouseEventHandler): void => {
  confirm({
    wrapClassName: 'light',
    title,
    content,
    onOk,
    onCancel
  });
}

export const infoToast = (title?: string, content?: string, onOk?: MouseEventHandler): void => {
  info({
    wrapClassName: 'light',
    title,
    content,
    onOk
  });
}
