import React, { useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import type { AdminConfigFormPropsType } from '../../../../../constants/Interfaces/AdminFormConfigTypes';
import { tableConfigFormLabels } from '../../../../../constants/FormFields/AdminForm/tableConfigFormFields';
import { getApplicationSectorList } from '../../../../../redux/actions/Admin/applicationConfigActions';
import { getTimeStamp } from '../../../../../helpers/commonHelper';
import FloatingLabel from '../../../../common/FloatingLabel';
import Footer from '../../../../common/Footer';
import { tableTypes } from '../../../../../constants/DropDownOptions/adminConfig';
import textConstants from '../../../../../constants/textConstants';

const TableConfigForm: FC<AdminConfigFormPropsType> = ({ isEditable, data, onSave, onCancel }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const tableTypeValue = Form.useWatch('dataType', form);
  const tableType = tableTypeValue === 'log' ? 'logs' : 'exceptions';

  const appSectorList = useSelector((state: any) => state.applicationConfig.applicationSectorList);

  const displayLastUpdatedValue = (value: any): string => {
    return moment(value).format('YYYY-MM-DD HH:mm:ss');
  };

  const onTableTypeChange = (selectedVal: string): void => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      dataType: selectedVal,
      retentionDays: selectedVal === 'log' ? 28 : 35,
      topicName: '',
      tableName: ''
    });
  };

  const onSaveHandler = (isClose: boolean): void => {
    form.validateFields()
      .then(async values => {
        values = {
          ...values,
          lastUpdated: getTimeStamp(values.lastUpdated)
        };

        onSave(values, isClose);
      }).catch(error => console.log(error));
  }

  const getSectorDropdownOptions = (sectorList: any[]): any[] => {
    return sectorList.map((item) => ({
      label: item.sectorName,
      value: item.sectorName
    }));
  };

  useEffect(() => {
    if (appSectorList.length === 0) {
      dispatch(getApplicationSectorList());
    }
  }, []);

  return (
    <>
      <Form
        form={form}
        initialValues={{
          dataType: 'log',
          retentionDays: 28,
          recordCount: 0,
          tableSize: 0,
          solrCoreSize: 0,
          spaceOccupied: 0,
          lastUpdated: displayLastUpdatedValue(moment())
        }}
        data-testid="tableConfigForm"
        className='form-lg'
      >
        <Row
          gutter={{ lg: 16, xl: 16, xxl: 28 }}
        >
          <Col xs={24} md={6} xl={5}>
            <Form.Item name='dataType' rules={[{ required: true, message: 'Table Type is mandatory!' }]}>
              <FloatingLabel
                label={tableConfigFormLabels.dataType}
                type='select'
                id='dataType'
                onChange={(val: string) => onTableTypeChange(val)}
                options={tableTypes}
                required
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6} xl={5}>
            <Form.Item
              name='tableName'
              rules={[
                { required: true, message: `${tableConfigFormLabels.tableName} is mandatory!` },
                {
                  pattern: new RegExp(`^${tableType}_`),
                  message: `${textConstants.TABLE_CONFIG_INVALID_TABLE_NAME_MESSAGE}${tableType}_`
                }
              ]}
            >
              <FloatingLabel
                label={tableConfigFormLabels.tableName}
                type='text'
                id='tableName'
                suffix={
                  <Tooltip
                    placement="bottom"
                    title={`${textConstants.TABLE_CONFIG_INVALID_TABLE_NAME_MESSAGE}${tableType}_`}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                }
                required
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6} xl={5}>
            <Form.Item
              name='topicName'
              rules={[
                { required: true, message: `${tableConfigFormLabels.topicName} is mandatory!` },
                {
                  pattern: new RegExp(`^pep-cgf-cle-${tableType}-`),
                  message: `${textConstants.TABLE_CONFIG_INVALID_TABLE_TOPIC_MESSAGE}${tableType}-`
                }
              ]}
            >
              <FloatingLabel
                label={tableConfigFormLabels.topicName}
                type='text'
                id='topicName'
                suffix={
                  <Tooltip
                    placement="bottom"
                    title={`${textConstants.TABLE_CONFIG_INVALID_TABLE_TOPIC_MESSAGE}${tableType}-`}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                }
                required
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6} xl={5}>
            <Form.Item name='sector' rules={[{ required: true, message: `${tableConfigFormLabels.sector} is mandatory!` }]}>
              <FloatingLabel
                label={tableConfigFormLabels.sector}
                type='select'
                id='sector'
                options={getSectorDropdownOptions(appSectorList)}
                required
                showSearch
              />
            </Form.Item>
          </Col>
        </Row>
        <Row
          gutter={{ lg: 16, xl: 16, xxl: 28 }}
        >
          <Col xs={24} md={6} xl={5}>
            <Form.Item name='retentionDays' rules={[{ required: true, message: `${tableConfigFormLabels.retentionDays} is mandatory!` }]}>
              <FloatingLabel
                label={tableConfigFormLabels.retentionDays}
                type='number'
                id='retentionDays'
                required
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6} xl={5}>
            <Form.Item name='recordCount'>
              <FloatingLabel
                label={tableConfigFormLabels.recordCount}
                type='number'
                id='recordCount'
                disabled
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6} xl={5}>
            <Form.Item name='tableSize'>
              <FloatingLabel
                label={tableConfigFormLabels.tableSize}
                type='number'
                id='tableSize'
                disabled
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6} xl={5}>
            <Form.Item name='solrCoreSize'>
              <FloatingLabel
                label={tableConfigFormLabels.solrCoreSize}
                type='number'
                id='solrCoreSize'
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Row
          gutter={{ lg: 16, xl: 16, xxl: 28 }}
        >
          <Col xs={24} md={6} xl={5}>
            <Form.Item name='spaceOccupied'>
              <FloatingLabel
                label={tableConfigFormLabels.spaceOccupied}
                type='number'
                id='spaceOccupied'
                disabled
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6} xl={5}>
            <Form.Item name='lastUpdated'>
              <FloatingLabel
                label={tableConfigFormLabels.lastUpdated}
                type='text'
                id='lastUpdated'
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Footer
        className='grey-theme add-form-footer'
        onSave={() => onSaveHandler(false)}
        onSaveClose={() => onSaveHandler(true)}
        onCancel={onCancel}
        saveWithClose={!isEditable}
        save
        cancel
      />
    </>
  );
};

TableConfigForm.defaultProps = {
  isEditable: true
};

TableConfigForm.propTypes = {
  isEditable: PropTypes.bool,
  data: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default TableConfigForm;
