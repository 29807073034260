import React from 'react';
import PropTypes from 'prop-types';
import { ArrowLeftOutlined, FormOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { EditStyledButton, DefaultStyledButton } from './style';

export const EditButton: React.FC<{ onClick?: any, testId?: string }> = ({
  onClick,
  testId
}) => {
  return <EditStyledButton onClick={onClick} data-testid={testId} >
    <FormOutlined />
    Edit
  </EditStyledButton >
}

EditButton.propTypes = {
  onClick: PropTypes.func,
  testId: PropTypes.string
}

export const AddButton: React.FC<{ onClick?: any, testId?: string, btnText?: string, disabled?: boolean }> = ({
  onClick,
  testId,
  btnText,
  disabled
}) => {
  return <DefaultStyledButton onClick={onClick} data-testid={testId} >
    <PlusCircleOutlined />
    <span>{btnText}</span>
  </DefaultStyledButton >
}
AddButton.defaultProps = {
  btnText: 'Add'
}
AddButton.propTypes = {
  onClick: PropTypes.func,
  testId: PropTypes.string
}

export const GoBackButton: React.FC<{ onClick?: any, testId?: string }> = ({
  onClick,
  testId
}) => {
  return <DefaultStyledButton onClick={onClick} data-testid={testId} >
    <ArrowLeftOutlined />
  </DefaultStyledButton >
}

GoBackButton.propTypes = {
  onClick: PropTypes.func,
  testId: PropTypes.string
}
