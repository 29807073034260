import React from 'react';
import type { FC } from 'react';
import { useHistory } from 'react-router-dom';
import ConfigurationListTable from '../../../components/AdditionalComponents/ConfigurationListTable';
import { SOLMAN_STAGING_CONFIG_COLUMNS } from '../../../constants/TableColumns/Admin/SolmanStagingConfigColumns';
import {
  AdminMainLayoutWrapper,
  AdminMainBreadCrumbWrapper
} from '../../../assets/styles/commonStyled';
import textConstants from '../../../constants/textConstants';
import { getSolmanStagingConfigList } from '../../../redux/actions/Admin/solmanStagingConfigActions';
import routeConstants from '../../../constants/routeConstants';

const SolmanStagingConfig: FC = () => {
  const history = useHistory();

  return (
    <AdminMainLayoutWrapper data-testid="solmanConfigList">
      <AdminMainBreadCrumbWrapper>
        {textConstants.SOLMAN_CONFIG_PAGE_TITLE}
      </AdminMainBreadCrumbWrapper>
      <ConfigurationListTable
        columnDefs={SOLMAN_STAGING_CONFIG_COLUMNS}
        getConfigList={getSolmanStagingConfigList}
        gridName="SolmanStaging Config"
        onRowDoubleClicked={(params: { data: any }) => {
          history.push({
            pathname: routeConstants.ADMIN_SOLMAN_CONFIG_DETAIL,
            state: {
              isEditable: false,
              data: params?.data
            }
          });
        }}
        listName="solmanConfig"
      />
    </AdminMainLayoutWrapper>
  );
};

export default SolmanStagingConfig;
