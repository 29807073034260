import styled, { css } from 'styled-components';

const detailViewSidePanelWidth = '20vw';
const popOverRemainingColumnMaxHeight = '22vh';

export const DetailCardItemStyle = css`
  padding: 2px;
  font-size: var(--font-size-sm);
  margin-bottom: 2px;
`;
export const DrawerContentWrapper = styled.div`
  height: 100%;
  display: flex;

  .status-btn {
    top: 0;
  }
`;

export const DrawerDetailItemsListWrapper = styled.div`
  width: ${detailViewSidePanelWidth};
  // background: var(--gray-50);
  border-right: 1px solid var(--gray-250);
`;

export const DrawerDetailItemsListHeaderWrapper = styled.div`
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 1px 2px 0px var(--gray-250);
`;

export const DrawerDetailListItemsWrapper = styled.div`
  padding: 10px 5px;
  overflow: auto;
  max-height: 90%;
`;
export const DetailCardItem = styled.div`
  border: 1px solid var(--gray-250);
  border-radius: 5px;
  margin-bottom: 7px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &.active {
    border: 1px solid var(--blue-color);
    box-shadow: 0px 2px 0px 0px var(--blue-color-675);
    // border-bottom: 2px solid var(--pepsi-primary-color);
  }
`;

export const DetailCardHeader = styled.div`
  padding: 5px;
  border-bottom: 1px solid var(--gray-250);
  color: var(--blue-color-675);
  font-weight: 600;
`;

export const DetailCardBody = styled.div`
  padding: 5px;
  flex: 1;
`;
export const DetailBodyItemWrapper = styled.div`
  ${DetailCardItemStyle}
`;
export const DetailBodyItemLabel = styled.div`
  color: var(--gray-550);
`;
export const DetailBodyItemValue = styled.div`
  word-wrap: break-word;
`;
export const DetailCardFooter = styled.div`
  padding: 5px;
  border-top: 1px solid var(--gray-250);
  font-size: var(--font-size-sm);
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
export const DetailCardFooterRelativeTimeWrapper = styled.div`
  font-size: var(--font-size-xs);
  color: var(--gray-500);
`;
export const DetailConfigureContentWrapper = styled.div`
  width: 100%;
`;
export const DetailConfigureRemainingFieldWrapper = styled.div`
  max-height: ${popOverRemainingColumnMaxHeight};
  overflow: auto;
  display: flex;
  flex-direction: column;
  .ant-checkbox-group {
    flex-direction: column;
  }
  .ant-checkbox-wrapper {
    ${DetailCardItemStyle}
  }
`;

export const DetailConfigureColumnFooter = styled.div`
  text-align: right;
  padding: 5px 0px;
  .ant-btn {
    min-height: inherit;
  }
  .ant-btn-default {
    margin-right: 5px;
  }
`;
export const DetailViewCalendarBtnWrapper = styled.div`
  .ant-btn {
    min-height: 24px; //need to update to rem
    max-height: 24px;
  }
`;
export const PopOverStyle = {
  borderRadius: '3px',
  width: '100%',
  padding: '5px 10px',
  minWidth: 'calc(' + detailViewSidePanelWidth + ' - 1vw)'
};
export const DrawerRightSideDetailWraper = styled.div<{ isDetailScreenMultiView?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: calc(80vw - 20px);
  > div:first-child {
    flex: 1;
    overflow: auto;
  }
`;

export const DrawerFooterWrapper = styled.div`
  text-align: right;
  background: var(--gray-150);
  padding: 5px 10px;
  .ant-btn {
    min-height: inherit;
    margin-right: 7px;
  }
`;

export const DrawerBasicDetailWrapper = styled.div`
  padding: 10px;
`;

export const DrawerTabContentWrapper = styled.div`
  margin-top: 5px;
`;
