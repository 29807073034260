import { produce } from 'immer';
import { SET_OKTA_AUTHENTICATED_STATUS, SET_USER_ACCESS_INFO } from '../../actions/ActionTypes/userProfileTypes';

export interface UserProfileState {
  userAccessInfo: object | null
  redirectURL: string | null
  exceptionresponse: object | null
  oktaAuthenticatedStatus: boolean
}

const initialState: UserProfileState = {
  userAccessInfo: null,
  redirectURL: null,
  exceptionresponse: null,
  oktaAuthenticatedStatus: false
};

const userProfileReducer = (state = initialState, action: any): UserProfileState =>
  produce(state, (draft: UserProfileState) => {
    switch (action.type) {
      case SET_OKTA_AUTHENTICATED_STATUS:
        draft.oktaAuthenticatedStatus = action.status;
        break;
      case SET_USER_ACCESS_INFO:
        draft.userAccessInfo = action.userAccessData;
        break;
    }
  });

export default userProfileReducer;
