
export const scheduleConfigFormFields = [
  {
    label: 'Application Name',
    name: 'applicationName',
    id: 'applicationName',
    type: 'text'
  },
  {
    label: 'Service',
    name: 'service',
    id: 'service',
    type: 'text'
  },
  {
    label: 'Component',
    name: 'component',
    id: 'component',
    type: 'text'
  },
  {
    label: 'Category',
    name: 'category',
    id: 'category',
    type: 'text'
  },
  {
    label: 'Type',
    name: 'type',
    id: 'type',
    type: 'text'
  },
  {
    label: 'Code',
    name: 'code',
    id: 'code',
    type: 'text'
  },
  {
    label: 'Message',
    name: 'message',
    id: 'message',
    type: 'text'
  },

  {
    label: 'Message NVP',
    name: 'messageNVP',
    id: 'messageNVP',
    type: 'text'
  },
  {
    label: 'Dump Analysis',
    name: 'dumpAnalysis',
    id: 'dumpAnalysis',
    type: 'text'
  },
  {
    label: 'Replay Enabled',
    name: 'replayEnabled',
    id: 'replayEnabled',
    type: 'radio'
  },
  {
    label: 'Scheduled Time',
    name: 'scheduledTime',
    id: 'scheduledTime',
    type: 'number'
  },
  {
    label: 'Search Window',
    name: 'searchWindow',
    id: 'searchWindow',
    type: 'number'
  }
];
