import { notification } from 'antd';

const { success, info, warning, error } = notification;

export const successNotification = (message?: string, description?: string): void => {
  success({
    message,
    description
  });
};

export const infoNotification = (message?: string, description?: string): void => {
  info({
    message,
    description
  });
};

export const warningNotification = (message?: string, description?: string): void => {
  warning({
    message,
    description
  });
};
export const errorNotification = (message?: string, description?: string): void => {
  error({
    message,
    description
  });
};
