import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import Footer from '../../../../common/Footer';
import { Col, Form, Row } from 'antd';
import FloatingLabel from '../../../../common/FloatingLabel';
import { ApplicationAccessTableHeaderWrapper, GroupAssignmentForm } from '../../../../common/AccessComponent/style';

interface componentProps {
  onSave?: any
  onCancel?: any
  applicationGroupAllList?: any
  type?: string
}

const UserApplicationGroupSelectionForm: FC<componentProps> = ({ applicationGroupAllList, onSave, onCancel, type }) => {
  const [form] = Form.useForm();
  const [applicationGroupList, setApplicationGroupList] = useState(applicationGroupAllList);
  const onSaveHandler = (): void => {
    form.validateFields()
      .then(() => {
        onSave(applicationGroupList);
      }).catch(error => console.log(error));
  }

  const onSelectGroupDropDownHandler = (selectedGroup: any, selectedIndex: number): void => {
    const tempApplicationGroupList = applicationGroupList;
    tempApplicationGroupList[selectedIndex].selectedGroup = selectedGroup;
    setApplicationGroupList(tempApplicationGroupList);
  }

  return (
    <>
      <ApplicationAccessTableHeaderWrapper className='group-selection-drawer'>
        <Row gutter={16}>
          <Col key={'applicationName'}
            span={8}>
            Application Name
          </Col>
          <Col key={'accessType'}
            span={8}>
            Access Type
          </Col>
          <Col key={'groupOptions'}
            span={8}>
            Group Options
          </Col>
        </Row>
      </ApplicationAccessTableHeaderWrapper>
      <GroupAssignmentForm
        form={form}
        data-testid="testid-user-application-group-list-form"
      >
        {
          applicationGroupList?.map((groupElement: any, groupIter: number) => {
            return (<Row gutter={16} key={`group-selection-row-${groupIter}`}>
              <Col span={8}>
                {groupElement.applicationName}
              </Col>
              <Col span={8}>
                {groupElement.accessName}
              </Col>
              <Col span={8}>
                <Form.Item
                  name={['groupName', groupIter]}
                  rules={[{ required: true, message: 'Group Name is Mandatory!' }]}
                >
                  <FloatingLabel
                    label=''
                    type='select'
                    id={`groupName-${groupIter}`}
                    fieldNames={{ label: 'groupName', value: 'groupName' }}
                    options={groupElement.groupName}
                    onChange={(val: any, selectedOption: any) => onSelectGroupDropDownHandler(selectedOption, groupIter)}
                  />
                </Form.Item>
              </Col>
            </Row>
            )
          })
        }
      </GroupAssignmentForm>
      <Footer
        className={'grey-theme add-form-footer'}
        save
        cancel
        onSave={onSaveHandler}
        onCancel={onCancel}
      />
    </>)
}

UserApplicationGroupSelectionForm.propTypes = {
  applicationGroupAllList: PropTypes.array,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  type: PropTypes.string
};

export default UserApplicationGroupSelectionForm;
