import { DELETE_APPLICATION_EXCEPTION_CATEGORY, DELETE_APPLICATION_EXCEPTION_SEVERITY, DELETE_APPLICATION_EXCEPTION_TYPE, SAVE_APPLICATION_EXCEPTION_CATEGORY, SAVE_APPLICATION_EXCEPTION_SEVERITY, SAVE_APPLICATION_EXCEPTION_TYPE } from '../ActionTypes/Admin/applicationConfigTypes';
interface ApplicationListItemsAction {
  type: string
  data: any
  isAdd?: boolean
}

interface OperationActionInterface {
  type: string
  data: any[]
  callBackFunc?: any
}

export function saveApplicationExceptionCategory (
  data: any,
  isAdd?: boolean
): ApplicationListItemsAction {
  return {
    type: SAVE_APPLICATION_EXCEPTION_CATEGORY,
    data,
    isAdd
  };
}

export function deleteApplicationExceptionCategory (data: any, callBackFunc?: any): OperationActionInterface {
  return {
    type: DELETE_APPLICATION_EXCEPTION_CATEGORY,
    data,
    callBackFunc
  };
}

export function saveApplicationExceptionType (
  data: any,
  isAdd?: boolean
): ApplicationListItemsAction {
  return {
    type: SAVE_APPLICATION_EXCEPTION_TYPE,
    data,
    isAdd
  };
}

export function deleteApplicationExceptionType (data: any, callBackFunc?: any): OperationActionInterface {
  return {
    type: DELETE_APPLICATION_EXCEPTION_TYPE,
    data,
    callBackFunc
  };
}

export function saveApplicationExceptionSeverity (
  data: any,
  isAdd?: boolean
): ApplicationListItemsAction {
  return {
    type: SAVE_APPLICATION_EXCEPTION_SEVERITY,
    data,
    isAdd
  };
}

export function deleteApplicationExceptionSeverity (data: any, callBackFunc?: any): OperationActionInterface {
  return {
    type: DELETE_APPLICATION_EXCEPTION_SEVERITY,
    data,
    callBackFunc
  };
}
