import React, { Fragment, useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'antd';
import FloatingLabel from '../../../../common/FloatingLabel';
import { useSelector } from 'react-redux';
import { ApplicationFormItemsLayout } from '../../../../../constants/FormLayout/ApplicationFormLayout';
import { generateOptionsFromList, generateTableMetaDataOptionsFromList } from '../../../../../helpers/commonHelper';
import Footer from '../../../../common/Footer';
import { applicationNameRules, fieldRequiredRule } from '../../../../../constants/FormFields/ValidationRules/applicationConfig';
interface componentProps {
  applicationDetailData?: any
  isEditable?: boolean
  onSave?: any
  onCancel?: any
}
const columnSpan = ApplicationFormItemsLayout.columnSpan
const ApplicationConfigForm: FC<componentProps> = ({ applicationDetailData, isEditable, onSave, onCancel }) => {
  const [form] = Form.useForm();
  const [logTableDataOptions, setLogTableDataOptions] = useState<any>([]);
  const [exceptionTableDataOptions, setExceptionTableDataOptions] = useState([]);

  const globalApplicationState = useSelector((state: any) => state?.applicationConfig);
  const globalApplicationSectorList = globalApplicationState?.applicationSectorList;
  const tableMetaConfigList = useSelector((state: any) => state?.tableConfig?.tableConfigList);
  const globalLogExceptionTableMetaData = generateTableMetaDataOptionsFromList(tableMetaConfigList);

  useEffect(() => {
    const logTableData = globalLogExceptionTableMetaData?.filter((item: { table: string, sector: string }) => (item.table === 'log' && item.sector === applicationDetailData?.appSector));
    const exceptionTableData = globalLogExceptionTableMetaData?.filter((item: { table: string, sector: string }) => (item.table === 'exception' && item.sector === applicationDetailData?.appSector));
    setLogTableDataOptions(logTableData);
    setExceptionTableDataOptions(exceptionTableData);
  }, [applicationDetailData])

  const onSectorSelectHandler = (selectedVal: string): void => {
    try {
      const logTableData = globalLogExceptionTableMetaData?.filter((item: { table: string, sector: string }) => (item.table === 'log' && item.sector === selectedVal));
      const exceptionTableData = globalLogExceptionTableMetaData?.filter((item: { table: string, sector: string }) => (item.table === 'exception' && item.sector === selectedVal));
      setLogTableDataOptions(logTableData);
      setExceptionTableDataOptions(exceptionTableData);
      form.setFieldsValue({
        ...form.getFieldsValue(),
        appSector: selectedVal,
        logTable: logTableData.length > 0 ? logTableData[0].value : '',
        logRetain: logTableData.length > 0 ? logTableData[0].retentionDays : 0,
        logTopic: logTableData.length > 0 ? logTableData[0].topic : '',
        exceptionTable: exceptionTableData.length > 0 ? exceptionTableData[0].value : '',
        exceptionRetain: exceptionTableData.length > 0 ? exceptionTableData[0].retentionDays : 0,
        exceptionTopic: exceptionTableData.length > 0 ? exceptionTableData[0].topic : ''
      });
    } catch (e) {
      console.log(e)
    }
  }

  const onTableSelectChangeHandler = (selectedVal: string, selectedOption: any, selectName: string): void => {
    form?.setFieldsValue({
      ...form.getFieldsValue(),
      [selectName]: selectedVal,
      [selectName === 'logTable' ? 'logRetain' : 'exceptionRetain']: selectedOption.retentionDays,
      [selectName === 'logTable' ? 'logTopic' : 'exceptionTopic']: selectedOption.topic
    });
  };

  const onSaveHandler = (isClosable?: boolean): void => {
    form.validateFields()
      .then(async values => {
        onSave(values, isClosable);
      }).catch(error => console.log(error));
  }
  return (
        <>
            <Form
                form={form}
                initialValues={applicationDetailData}
                data-testid="testid-application-detail-summary-form">
                <Row gutter={[{ md: 16, xxl: 24 }, { md: 12, xl: 12, xxl: 16 }]}>
                    <Col key={'applicationName'} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                        <Form.Item name={'applicationName'} rules={applicationNameRules(isEditable ?? false)}>
                            <FloatingLabel
                                label={'Application Name'}
                                id={'applicationName'}
                                type={'text'}
                                {...(isEditable && {
                                  disabled: true
                                })}
                                required
                            />
                        </Form.Item>
                    </Col>
                    <Col key={'appDescription'} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                        <Form.Item name={'appDescription'} rules={fieldRequiredRule('Description')}>
                            <FloatingLabel
                                label={'Description'}
                                id={'appDescription'}
                                type={'text'}
                                required
                            />
                        </Form.Item>
                    </Col>
                    <Col key={'appOwner'} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                        <Form.Item name={'appOwner'}>
                            <FloatingLabel
                                label={'Application Owner'}
                                id={'appOwner'}
                                type={'text'}
                            />
                        </Form.Item>
                    </Col>
                    <Col key={'appSector'} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                        <Form.Item name={'appSector'} rules={fieldRequiredRule('Sector')}>
                            <FloatingLabel
                                data-testid="testid-application-form-sector-input"
                                name={'appSector'}
                                label={'Sector'}
                                id={'appSector'}
                                type={'select'}
                                options={generateOptionsFromList(globalApplicationSectorList, 'sectorName')}
                                onSelect={(changedVal: string) => onSectorSelectHandler(changedVal)}
                                required
                            />
                        </Form.Item>
                    </Col>
                    <Col key={'logRetain'} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                        <Form.Item name={'logRetain'} rules={fieldRequiredRule('Log Retain Days')}>
                            <FloatingLabel
                                label={'Log Retain Days'}
                                id={'logRetain'}
                                type={'text'}
                                disabled
                                required
                            />
                        </Form.Item>
                    </Col>
                    <Col key={'logTopic'} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                        <Form.Item name={'logTopic'} rules={fieldRequiredRule('Log Topic')}>
                            <FloatingLabel
                                label={'Log Topic'}
                                id={'logTopic'}
                                type={'text'}
                                disabled
                                required
                            />
                        </Form.Item>
                    </Col>
                    <Col key={'logTable'} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                        <Form.Item name={'logTable'} rules={fieldRequiredRule('Log Table')}>
                            <FloatingLabel
                                data-testid="testid-application-form-log-table-name-select-input"
                                className={'log-table-name'}
                                label={'Log Table Name'}
                                id={'logTable'}
                                type={'select'}
                                options={logTableDataOptions}
                                onSelect={(val: string, option: any) => onTableSelectChangeHandler(val, option, 'logTable')}
                                required
                            />
                        </Form.Item>
                    </Col>
                    <Col key={'exceptionRetain'} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                        <Form.Item name={'exceptionRetain'} rules={fieldRequiredRule('Exception Retain Days')}>
                            <FloatingLabel
                                label={'Exception Retain Days'}
                                id={'exceptionRetain'}
                                type={'text'}
                                disabled
                                required
                            />
                        </Form.Item>
                    </Col>
                    <Col key={'exceptionTopic'} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                        <Form.Item name={'exceptionTopic'} rules={fieldRequiredRule('Exception Topic')}>
                            <FloatingLabel
                                label={'Exception Topic'}
                                id={'exceptionTopic'}
                                type={'text'}
                                disabled
                                required
                            />
                        </Form.Item>
                    </Col>
                    <Col key={'exceptionTable'} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                        <Form.Item name={'exceptionTable'} rules={fieldRequiredRule('Exception Table')}>
                            <FloatingLabel
                                data-testid="testid-application-form-exception-table-name-select-input"
                                className={'exception-table-name'}
                                label={'Exception Table Name'}
                                id={'exceptionTable'}
                                type={'select'}
                                options={exceptionTableDataOptions}
                                onSelect={(val: string, option: any) => onTableSelectChangeHandler(val, option, 'exceptionTable')}
                                required
                            />
                        </Form.Item>
                    </Col>
                    <Col key={'teamDl'} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                        <Form.Item name={'teamDl'}>
                            <FloatingLabel
                                label={'Team DL'}
                                id={'teamDl'}
                                type={'text'}
                            />
                        </Form.Item>
                    </Col>
                    <Col key={'snowCI'} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                        <Form.Item name={'snowCI'}>
                            <FloatingLabel
                                label={'Snow CI'}
                                id={'snowCI'}
                                type={'text'}
                            />
                        </Form.Item>
                    </Col>
                    <Col key={'assignmentGroup'} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                        <Form.Item name={'assignmentGroup'}>
                            <FloatingLabel
                                label={'Assignment Group'}
                                id={'assignmentGroup'}
                                type={'text'}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Footer
                className={`grey-theme ${!isEditable ? 'add-form-footer' : ''}`}
                save
                saveWithClose={!isEditable}
                cancel
                onSaveClose={() => onSaveHandler(true)}
                onSave={() => onSaveHandler(false)}
                onCancel={onCancel}
            />
        </>
  )
};

ApplicationConfigForm.propTypes = {
  applicationDetailData: PropTypes.object,
  isEditable: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
};

export default ApplicationConfigForm;
