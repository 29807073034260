import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Tabs as AntdTabs } from 'antd';
import { TabWrapper } from './style';

interface TabsProps {
  wrapperClassName?: string
  defaultActiveTab?: string
  animated?: boolean | undefined
  destroyInactiveTab?: boolean
  onTabChangeHandler?: (activeKey: string) => void
  onTabEditHandler?: (targetKey: React.MouseEvent | React.KeyboardEvent | string, action: 'add' | 'remove') => void
  tabClassName?: string
  tabItems: any[]
  tabExtraContent?: {
    left?: React.ReactNode
    right?: React.ReactNode
  }
  tabSize?: 'small' | 'middle' | 'large' | undefined
  tabType?: 'line' | 'card' | 'editable-card'
  activeTab?: string
}

const Tabs: FC<TabsProps> = ({
  animated,
  defaultActiveTab,
  destroyInactiveTab,
  onTabChangeHandler,
  tabClassName,
  tabItems,
  tabExtraContent,
  tabSize,
  tabType,
  onTabEditHandler,
  activeTab,
  wrapperClassName
}) => (
  <TabWrapper className={wrapperClassName}>
    <AntdTabs
      animated={animated}
      size={tabSize}
      defaultActiveKey={defaultActiveTab}
      onChange={onTabChangeHandler}
      className={tabClassName}
      items={tabItems}
      tabBarExtraContent={tabExtraContent}
      type={tabType}
      onEdit={onTabEditHandler}
      activeKey={activeTab}
      destroyInactiveTabPane={destroyInactiveTab}
    />
  </TabWrapper>
)

Tabs.defaultProps = {
  animated: true,
  destroyInactiveTab: false,
  tabType: 'card',
  tabSize: 'small'
}

Tabs.propTypes = {
  animated: PropTypes.bool,
  destroyInactiveTab: PropTypes.bool,
  defaultActiveTab: PropTypes.string,
  tabClassName: PropTypes.string,
  onTabChangeHandler: PropTypes.func,
  tabItems: PropTypes.array.isRequired,
  tabSize: PropTypes.any,
  tabExtraContent: PropTypes.shape({
    left: PropTypes.node,
    right: PropTypes.node
  }),
  tabType: PropTypes.any,
  onTabEditHandler: PropTypes.func,
  activeTab: PropTypes.string,
  wrapperClassName: PropTypes.string
}

export default Tabs
