import React, { useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form, Radio } from 'antd';
import { exceptionReportConfigFieldlabels } from '../../../../../constants/FormFields/AdminForm/exceptionReportConfigFormFields';
import { applicationDataHelper } from '../../../../../helpers/applicationDataHelper';
import FloatingLabel from '../../../../common/FloatingLabel';
import Footer from '../../../../common/Footer';
import type { AdminConfigFormPropsType } from '../../../../../constants/Interfaces/AdminFormConfigTypes';
import { exceptionReportHourlyOptionList, exceptionReportTypes, metricTypeOptions } from '../../../../../constants/DropDownOptions/adminConfig';
import { EXCEPTION_REPORT_ID } from '../../../../../constants/KeyLabels/commonKeyConstants';

const ExceptionReportConfigForm: FC<AdminConfigFormPropsType> = ({
  isDrawerView,
  isEditable,
  data,
  onSave,
  onCancel
}) => {
  const [form] = Form.useForm();
  const applicationNameList = applicationDataHelper.getApplicationNames([]);
  const reportTypeUpdatedValue = Form.useWatch('reportType', form);

  const onSaveHandler = (isClose: boolean): void => {
    form
      .validateFields()
      .then(async (values) => {
        values = {
          ...values,
          applicationName: values.applicationName.map((item: any) => item.value)
        };
        onSave(values, isClose);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (isEditable) {
      form.setFieldsValue({ ...data, reportType: data?.reportType || 'Daily' });
      if (data?.applicationName.length > 0) {
        const selectedAppNames = data.applicationName.map((item: any) => ({
          label: item,
          value: item
        }));
        form.setFieldValue('applicationName', selectedAppNames);
      }
    }
  }, [data[EXCEPTION_REPORT_ID]]);

  return (
    <>
      <Form
        form={form}
        initialValues={{
          enableNotification: false,
          reportType: 'Daily',
          ...data
        }}
        data-testid="exceptionReportConfigForm"
        layout="vertical"
      >
        <Row gutter={16}>
          {isEditable
            ? (
            <Col xs={24} md={6} xxl={5}>
              <Form.Item name="configID">
                <FloatingLabel
                  label={exceptionReportConfigFieldlabels.configID}
                  type="text"
                  id="configID"
                  disabled
                />
              </Form.Item>
            </Col>
              )
            : null}
          <Col xs={24} md={6} xxl={5}>
            <Form.Item
              name="applicationName"
              rules={[{ required: true, message: 'Application is mandatory!' }]}
            >
              <FloatingLabel
                label={exceptionReportConfigFieldlabels.applicationName}
                type="multiSelect"
                id="applicationName"
                options={applicationNameList}
                placeholder=""
                disabled={isEditable}
                required
                multi
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6} xxl={5}>
            <Form.Item
              name="mailID"
              rules={[
                { required: true, message: 'Email is mandatory!' }
              ]}
            >
              <FloatingLabel
                label={exceptionReportConfigFieldlabels.mailID}
                type="text"
                id="mailID"
                required
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6} xxl={5}>
            <Form.Item
              name="metricType"
              rules={[{ required: true, message: 'Metric Type is mandatory!' }]}
            >
              <FloatingLabel
                label={exceptionReportConfigFieldlabels.metricType}
                type="select"
                id="metricType"
                options={metricTypeOptions}
                required
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6} xxl={5}>
            <Form.Item
              name="reportType"
              rules={[{ required: true, message: 'Report Type is mandatory!' }]}
            >
              <FloatingLabel
                label={exceptionReportConfigFieldlabels.reportType}
                type="select"
                id="reportType"
                options={exceptionReportTypes}
                required
              />
            </Form.Item>
          </Col>
          {reportTypeUpdatedValue === 'Hourly' && (
            <Col xs={24} md={6} xxl={5}>
              <Form.Item
                name="hour"
                rules={[{ required: true, message: 'Hours is mandatory!' }]}
              >
                <FloatingLabel
                  label={exceptionReportConfigFieldlabels.hours}
                  type="select"
                  id="hour"
                  options={exceptionReportHourlyOptionList}
                  required
                />
              </Form.Item>
            </Col>
          )}
          <Col xs={24} md={12} xxl={12}>
            <Form.Item
              label={exceptionReportConfigFieldlabels.enableNotification}
              name="enableNotification"
            >
              <Radio.Group>
                <Radio value>Enable</Radio>
                <Radio value={false}>Disable</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Footer
        className={`grey-theme ${isDrawerView ? 'add-form-footer' : ''}`}
        onCancel={onCancel}
        onSave={() => onSaveHandler(false)}
        onSaveClose={() => onSaveHandler(true)}
        saveWithClose={!isEditable}
        save
        cancel
      />
    </>
  );
};

ExceptionReportConfigForm.defaultProps = {
  isEditable: true
};

ExceptionReportConfigForm.propTypes = {
  data: PropTypes.object,
  isDrawerView: PropTypes.bool,
  isEditable: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ExceptionReportConfigForm;
