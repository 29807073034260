import React, { useState } from 'react';
import type { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ConfigurationListTable from '../../../components/AdditionalComponents/ConfigurationListTable';
import { TABLE_CONFIG_COLUMNS } from '../../../constants/TableColumns/Admin/TableConfigColumns';
import { getLogAndExceptionTableMetaData, saveTableConfig } from '../../../redux/actions/Admin/tableConfigActions';
import {
  AdminMainLayoutWrapper,
  AdminMainBreadCrumbWrapper
} from '../../../assets/styles/commonStyled';
import AddConfigDrawer from '../../../components/AdditionalComponents/Drawer/AddConfigDrawer';
import TableConfigForm from '../../../components/AdditionalComponents/UpsertForms/Admin/TableConfig';
import { checkIfExistsInArray } from '../../../helpers/commonHelper';
import { errorNotification } from '../../../components/common/Notification';
import { TABLE_CONFIG_ID } from '../../../constants/KeyLabels/commonKeyConstants';
import textConstants from '../../../constants/textConstants';
import routeConstants from '../../../constants/routeConstants';

const TableConfig: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isAddDrawerOpen, setAddDrawerVisibility] = useState(false);

  const tableConfigList = useSelector((state: any) => state.tableConfig.tableConfigList);

  const toggleAddDrawerVisibility = (isOpen: boolean): void => {
    setAddDrawerVisibility(isOpen);
  }

  const onTableConfigSave = (data: any, isClosable?: boolean): void => {
    if (!checkIfExistsInArray(tableConfigList, data, TABLE_CONFIG_ID)) {
      dispatch(saveTableConfig(data, isClosable ? toggleAddDrawerVisibility(false) : null, true));
    } else {
      errorNotification(textConstants.TABLE_CONFIG_ALREADY_EXIST_MESSAGE);
    }
  }

  return (
    <AdminMainLayoutWrapper data-testid="tableConfigList">
      <AdminMainBreadCrumbWrapper>
        {textConstants.TABLE_CONFIG_PAGE_TITLE}
      </AdminMainBreadCrumbWrapper>
      <ConfigurationListTable
        getConfigList={getLogAndExceptionTableMetaData}
        columnDefs={TABLE_CONFIG_COLUMNS}
        gridName={textConstants.TABLE_CONFIG_PAGE_TITLE}
        onRowDoubleClicked={(params: { data: any }) => {
          history.push({
            pathname: routeConstants.ADMIN_TABLE_CONFIG_DETAIL,
            state: {
              isEditable: false,
              data: params?.data
            }
          });
        }}
        onCreate={() => toggleAddDrawerVisibility(true)}
        listName="tableConfig"
        hasFilter={false}
        hasCreate
      />
      {isAddDrawerOpen && (
        <AddConfigDrawer
          isOpen={isAddDrawerOpen}
          dataTestId='tableConfigAddDrawer'
          title={textConstants.TABLE_CONFIG_ADD_DRAWER_TITLE}
          onCancel={() => toggleAddDrawerVisibility(false)}
        >
          <TableConfigForm
            onCancel={() => toggleAddDrawerVisibility(false)}
            onSave={onTableConfigSave}
            isEditable={false}
          />
        </AddConfigDrawer>
      )}
    </AdminMainLayoutWrapper>
  );
};

export default TableConfig;
