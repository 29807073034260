import { checkBoxFieldCol } from '../tableCheckboxColumn';
import { getSelectedDaysLabel } from '../../../helpers/commonHelper';

const additionalColumnProp = {
  minWidth: 150,
  flex: 1,
  valueFormatter: (params: { value: any }) => {
    return params?.value ? (params?.value?.toString().trim().length === 0 ? '-' : params.value) : '-';
  }
};
export const REPORT_CONFIG_COLUMNS = [
  checkBoxFieldCol,
  {
    field: 'applicationName',
    headerName: 'Application Name',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'email',
    headerName: 'Email',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'emailMsg',
    headerName: 'Email Message',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'messageCode',
    headerName: 'Message Code',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'messageType',
    headerName: 'Message Type',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'messsageFct',
    headerName: 'Messsage Fct',
    sortable: true,
    ...additionalColumnProp
  },
  {
    field: 'serviceName',
    headerName: 'Service',
    sortable: true
  },
  {
    field: 'thresholdDays',
    headerName: 'Threshold Days',
    sortable: true,
    cellRenderer: function (params: any) {
      return getSelectedDaysLabel(params.value);
    }
  },
  {
    field: 'thresholdLowLimit',
    headerName: 'Threshold Low Limit',
    sortable: true
  },
  {
    field: 'thresholdHighLimit',
    headerName: 'Threshold High Limit',
    sortable: true
  },
  {
    field: 'allowBreach',
    headerName: 'Allow Breach',
    sortable: true,
    cellRenderer: function (params: any) {
      return params.value ? 'Yes' : 'No';
    }
  },
  {
    field: 'assignmentGroup',
    headerName: 'Assignment Group',
    sortable: true
  },
  {
    field: 'alertType',
    headerName: 'Alert Type',
    sortable: true
  }
];
