export const AccessFormItemsLayout = {
  columnSpan: {
    xs: 24,
    md: 4,
    xxl: 4
  },
  actionColSpan: 2,
  applicationColMdSpan: 6,
  rowGutter: 16
};
