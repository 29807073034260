import React from 'react';
import type { ReactElement } from 'react';
import SavedSearchTableList from '../../components/AdditionalComponents/TabContents/RecentSavedSearch/SavedSearch';
import { CardContainerWrapper } from '../../assets/styles/commonStyled';

const LogSavedSearch = (): ReactElement => {
  return (
        <CardContainerWrapper data-testid="test-id-log-saved-search-wrapper">
            <SavedSearchTableList showDeleteBtn logExceptionType='Log' canOccupyFullScreen />
        </CardContainerWrapper>
  );
};

export default LogSavedSearch;
