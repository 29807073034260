import apiEndpointConstants from '../constants/apiEndpointConstants';
import { API_SUCCESS_STATUS_CODE } from '../constants/commonConstants';
import { commonApiRequestHandler } from './CommonServiceHandler';

export const fetchAuditLogServiceHanlder = async (request: any): Promise<any> => {
  try {
    const url = apiEndpointConstants.REPLAY_AUDIT;
    const apiRequestConfig = {
      method: 'POST',
      data: request,
      url
    };
    const auditLogsResponse = await commonApiRequestHandler(apiRequestConfig);
    if (auditLogsResponse.status === API_SUCCESS_STATUS_CODE) {
      return auditLogsResponse?.data;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
};
