export const applicationNameRules = (isEditable: boolean): any[] => {
  return [
    { required: true, message: 'Application Name is Mandatory!' },
    {
      pattern: !isEditable ? /^[A-Za-z0-9_-]+$/ : '',
      message:
                'Invalid Application Name! Only hyphens and underscore as a special characters are allowed.'
    }
  ];
};

export const fieldRequiredRule = (fieldName: string): [any] => [
  { required: true, message: `${fieldName} is Mandatory!` }
];
