import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import { type FormFieldType } from '../../../../../constants/Interfaces/FormFieldTypes';
import {
  tableConfigFormFields
} from '../../../../../constants/FormFields/AdminForm/tableConfigFormFields';
import { getLabelValueFormatter } from '../../../../../helpers/commonHelper';
import { LabelValueWrapper, LabelWrapper } from '../../../../../assets/styles/commonStyled';
import { SummaryViewRow } from '../style';

interface ConfigSummaryProps {
  data?: Partial<Record<string, string>>
}

const TableConfigSummary: FC<ConfigSummaryProps> = ({ data }) => {
  return (
    <>
      <SummaryViewRow
        gutter={[16, 8]}
      >
        {tableConfigFormFields.map((formField: FormFieldType) => {
          return (
            <Col key={formField.id} xs={24} md={8} xxl={7}>
              <LabelWrapper>{formField.label}</LabelWrapper>
              <LabelValueWrapper>{formField?.formatter ? formField.formatter(data?.[formField.name]) : getLabelValueFormatter(data?.[formField.name], formField?.type)}</LabelValueWrapper>
            </Col>
          );
        })}
      </SummaryViewRow>
    </>
  );
};

TableConfigSummary.propTypes = {
  data: PropTypes.object
};

export default TableConfigSummary;
