import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { ApplicationTabContentMainWrapper } from '../../../../../assets/styles/commonStyled';
import ColumnAlertConfigList from '../../../DetailSummaryPage/Admin/ColumnAlertConfig/ColumnAlertConfigList';
import { APPLICATION_NAME_ID } from '../../../../../constants/KeyLabels/commonKeyConstants';

interface componentProps {
  applicationDetailData?: any
}

const ColumnAlertConfigTabContent: FC<componentProps> = ({ applicationDetailData }) => {
  return <ApplicationTabContentMainWrapper data-testid='testid-application-detail-column-alert-config-tab'>
    <ColumnAlertConfigList
      isFromApplicationConfig
      applicationName={applicationDetailData?.[APPLICATION_NAME_ID]}
    />
  </ApplicationTabContentMainWrapper>;
};

ColumnAlertConfigTabContent.propTypes = {
  applicationDetailData: PropTypes.object
};

export default ColumnAlertConfigTabContent;
