import React from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Button, Drawer } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { AddEditConfigDrawerHeaderActionWrapper } from '../../../../assets/styles/commonStyled';

interface AddEditConfigDrawerProps {
  isOpen: boolean
  children: ReactNode
  currentItemIndex?: number
  dataTestId?: string
  handleItemNavigation?: (currentIndex: number) => void
  onCancel: () => void
  selectedItemsCount?: number
  title: string
  className?: string
}

const AddEditConfigDrawer: FC<AddEditConfigDrawerProps> = ({
  className,
  currentItemIndex,
  isOpen,
  onCancel,
  children,
  dataTestId,
  handleItemNavigation,
  selectedItemsCount,
  title
}) => {
  return (
    <Drawer
      title={
        <>
          {title}
          {selectedItemsCount && selectedItemsCount > 0 && (
            <AddEditConfigDrawerHeaderActionWrapper>
              <Button
                data-testid="prevBtn"
                className="mr-10"
                disabled={currentItemIndex === 0}
                onClick={() => handleItemNavigation?.((currentItemIndex ?? 0) - 1)}
              >
                <LeftOutlined />
              </Button>
              <Button
                data-testid="nextBtn"
                disabled={currentItemIndex === selectedItemsCount}
                onClick={() => handleItemNavigation?.((currentItemIndex ?? 0) + 1)}
              >
                <RightOutlined />
              </Button>
            </AddEditConfigDrawerHeaderActionWrapper>
          )}
        </>
      }
      placement="right"
      onClose={onCancel}
      open={isOpen}
      getContainer={false}
      rootClassName={`add-edit-config-drawer ${className}`}
      width="100%"
      data-testid={dataTestId}
    >
      {children}
    </Drawer>
  );
};

AddEditConfigDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.element,
  currentItemIndex: PropTypes.number,
  dataTestId: PropTypes.string,
  handleItemNavigation: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  selectedItemsCount: PropTypes.number,
  title: PropTypes.string.isRequired
};

export default AddEditConfigDrawer;
