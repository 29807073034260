import { checkBoxFieldCol } from '../tableCheckboxColumn';

export const EXCEPTION_REPORT_CONFIG_COLUMNS = [
  checkBoxFieldCol,
  {
    field: 'applicationName',
    headerName: 'Application',
    flex: 1,
    sortable: true,
    cellRenderer: function (params: any) {
      return params.value?.join(', ');
    }
  },
  {
    field: 'mailID',
    headerName: 'Email',
    flex: 1,
    sortable: true
  },
  {
    field: 'metricType',
    headerName: 'Metric Type',
    flex: 1,
    sortable: true
  },
  {
    field: 'reportType',
    headerName: 'Report Type',
    flex: 1,
    sortable: true,
    cellRenderer: function (params: any) {
      return params.value ?? 'Daily';
    }
  },
  {
    field: 'enableNotification',
    headerName: 'Enable Notification',
    flex: 1,
    sortable: true,
    cellRenderer: function (params: any) {
      return params.value ? 'Yes' : 'No';
    }
  }
];
