import type { BlobMetricDetailFormData } from '../../../constants/Interfaces/BlobMetricsTypes';
import {
  GET_LOG_DETAIL_BLOB_METRIC_DATA,
  GET_LOG_SUMMARY_BLOB_METRIC_DATA,
  GET_EXCEPTION_DETAIL_BLOB_METRIC_DATA,
  GET_EXCEPTION_SUMMARY_BLOB_METRIC_DATA,
  RESET_LOG_EXCEPTION_DETAIL_METRIC_DATA,
  SET_EXCEPTION_DETAIL_BLOB_METRIC_DATA,
  SET_LOG_DETAIL_BLOB_METRIC_DATA,
  SET_LOG_SUMMARY_BLOB_METRIC_DATA,
  SET_EXCEPTION_SUMMARY_BLOB_METRIC_DATA
} from '../ActionTypes/Admin/blobMetricsTypes';

export function getBlobMetricSummaryLogData (timeRange: string): any {
  return {
    type: GET_LOG_SUMMARY_BLOB_METRIC_DATA,
    timeRange
  };
}

export function getBlobMetricSummaryExceptionData (timeRange: string): any {
  return {
    type: GET_EXCEPTION_SUMMARY_BLOB_METRIC_DATA,
    timeRange
  };
}

export function getBlobMetricDetailLogData (data: BlobMetricDetailFormData): any {
  return {
    type: GET_LOG_DETAIL_BLOB_METRIC_DATA,
    data
  };
}

export function getBlobMetricDetailExceptionData (data: BlobMetricDetailFormData): any {
  return {
    type: GET_EXCEPTION_DETAIL_BLOB_METRIC_DATA,
    data
  };
}

export function resetLogExceptionDetailMetricData (): any {
  return {
    type: RESET_LOG_EXCEPTION_DETAIL_METRIC_DATA
  };
}

export function setLogSummaryBlobMetricData (data: any): any {
  return {
    type: SET_LOG_SUMMARY_BLOB_METRIC_DATA,
    data
  };
}
export function setExceptionSummaryBlobMetricData (data: any): any {
  return {
    type: SET_EXCEPTION_SUMMARY_BLOB_METRIC_DATA,
    data
  };
}

export function setLogDetailBlobMetricData (data: any): any {
  return {
    type: SET_LOG_DETAIL_BLOB_METRIC_DATA,
    data
  };
}
export function setExceptionDetailBlobMetricData (data: any): any {
  return {
    type: SET_EXCEPTION_DETAIL_BLOB_METRIC_DATA,
    data
  };
}
