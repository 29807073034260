import React, { useState, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Popover } from 'antd';
import textConstants from '../../../constants/textConstants';
import { ExportFieldWrapper, ExportPopoverWrapper, ExportReplayField } from './style';

interface ExportPopoverProps {
  availableColumns: any[]
  children?: ReactNode
  allowReplayExport: boolean
  handleOpenChange: any
  mandatorySelectedFields: string[]
  // dataCount?: number
  isOpen: boolean
  onCancel: () => void
  onConfirm: (selectedFields: string[]) => void
}

const ExportPopover: FC<ExportPopoverProps> = ({
  availableColumns,
  children,
  isOpen,
  allowReplayExport,
  handleOpenChange,
  mandatorySelectedFields,
  // dataCount,
  onCancel,
  onConfirm
}) => {
  const [isReplayExport, setReplayExport] = useState(false);
  const [selectedFields, setSelectedFields] = useState(mandatorySelectedFields);
  const [isAllChecked, setSelectAllState] = useState(false);

  /* Method to set replay template for exception export */
  const onReplayCheckboxChange = (checked: boolean): void => {
    const updatedFields = checked
      ? [...mandatorySelectedFields, 'exception_id']
      : [...mandatorySelectedFields];
    setReplayExport(checked);
    setSelectedFields(mandatorySelectedFields);
    setSelectAllState(!checked);
    setSelectedFields([...updatedFields]);
  };

  /* Method to handle select all field checkbox change for export */
  const onSelectAllCheckboxHandler = (checked: boolean): void => {
    const updatedFields = checked
      ? availableColumns.map((x) => x.field)
      : [...mandatorySelectedFields];
    setSelectAllState(checked);
    setSelectedFields(updatedFields);
  };

  /* Method to handle on checkbox selection change */
  const handleChange = (checked: boolean, field: string): void => {
    if (checked) {
      setSelectedFields([...selectedFields, field]);
    } else {
      const updatedFields = selectedFields.filter((item) => item !== field);
      setSelectedFields(updatedFields);
    }
  };

  const renderFields = (): React.ReactNode => {
    return availableColumns.map((item: any) => {
      return (
        <Checkbox
          key={item.field}
          id={item.field}
          value={item.field}
          checked={selectedFields.includes(item.field)}
          onChange={(e) => handleChange(e.target.checked, item.field)}
          disabled={mandatorySelectedFields.includes(item.field) || isReplayExport}
        >
          {item.label}
        </Checkbox>
      );
    });
  };

  useEffect(() => {
    if (availableColumns.length === selectedFields.length) {
      setSelectAllState(true);
    } else {
      setSelectAllState(false);
    }
  }, [selectedFields]);

  return (
    <Popover
      arrow={false}
      open={isOpen}
      onOpenChange={handleOpenChange}
      placement="bottom"
      trigger="click"
      content={
        <ExportPopoverWrapper>
          <h1>{textConstants.EXPORT_DATA_TITLE}</h1>
          {allowReplayExport && (
            <ExportReplayField>
              <Checkbox
                checked={isReplayExport}
                onChange={(evt) => onReplayCheckboxChange(evt.target.checked)}
              >
                {textConstants.REPLAY_TEMPLATE_LABEL}
              </Checkbox>
            </ExportReplayField>
          )}
          <ExportFieldWrapper>
            <Checkbox
              checked={isAllChecked}
              onChange={(evt) => onSelectAllCheckboxHandler(evt.target.checked)}
              disabled={isReplayExport}
            >
              {textConstants.MULTISELECT_SELECT_ALL_LABEL}
            </Checkbox>
            {renderFields()}
          </ExportFieldWrapper>
          <div className="text-right">
            <Button type="text" className="mr-10 btn-regular" onClick={onCancel} data-testid="cancelBtn">
              {textConstants.CONFIRM_CANCEL_TEXT}
            </Button>
            <Button
              className='btn-regular'
              type="primary"
              onClick={() => onConfirm(selectedFields)}
              data-testid="confirmBtn"
            >
              {textConstants.CONFIRM_BUTTON_LABEL}
            </Button>
          </div>
        </ExportPopoverWrapper>
      }
      destroyTooltipOnHide
      rootClassName="export-popover"
    >
      {children}
    </Popover>
  );
};

ExportPopover.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  allowReplayExport: PropTypes.bool.isRequired,
  handleOpenChange: PropTypes.func.isRequired,
  mandatorySelectedFields: PropTypes.array.isRequired,
  // dataCount: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default ExportPopover;
