import styled, { css } from 'styled-components';
import { Button } from 'antd';
export const defaulButtonStyle = css`
  color: var(--blue-color);
  margin-right: 10px;
  .anticon {
    font-size: var(--font-size-md);
    margin-right: 3px;
  }
  &:hover,
  &:focus,
  &:active {
    color: var(--blue-color);
  }
`;

export const CalendarIconButton = styled(Button)`
  &.ant-btn {
    border-color: var(--gray-400);
    color: var(--gray-400);

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:nth-child(2) {
      border-radius: 0 3px 3px 0;
      border-left: none;
    }

    &:hover {
      border-color: var(--gray-400);
      color: var(--gray-400);
    }

    &.active {
      background: var(--white-100);
      color: var(--blue-color);
    }
  }
`;
export const EditStyledButton = styled.a`
  ${defaulButtonStyle}
`;

export const DefaultStyledButton = styled.a`
  ${defaulButtonStyle}
`;
