import { call, put, fork, all, takeLatest, type AllEffect } from 'redux-saga/effects';
import {
  DELETE_SAVED_SEARCH_LIST,
  GET_SAVED_SEARCH_LIST,
  SAVE_SEARCH_CRITERIA
} from '../../actions/ActionTypes/SavedSearch/SavedSearchTypes';
import apiEndpointConstants from '../../../constants/apiEndpointConstants';
import { apiRequest } from '../../../services/axiosInterceptor';
import { API_SUCCESS_STATUS_CODE } from '../../../constants/commonConstants';
import { storeSavedSearchList } from '../../actions/SavedSearch/savedSearchActions';
import { SEARCH_CRITERIA_ID } from '../../../constants/KeyLabels/commonKeyConstants';
import { successNotification } from '../../../components/common/Notification';
import textConstants from '../../../constants/textConstants';

const callEffect: any = call;

export function * fetchSavedSearchCriteriaSagaFn (): Generator<any> {
  try {
    const apiCallConfig = {
      method: 'GET',
      url: apiEndpointConstants.SAVED_SEARCH
    };
    const apiResponse: any = yield callEffect(apiRequest, apiCallConfig);
    if (apiResponse?.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        data: apiResponse.data,
        action: 'NEW'
      };
      yield put(storeSavedSearchList(storePayload));
    }
  } catch (e) {
    console.log(e);
  }
}

export function * savedSearchListSagaFn (payload: any): any {
  try {
    const apiCallConfig = {
      method: 'POST',
      url: apiEndpointConstants.SAVED_SEARCH,
      data: payload.payload
    };
    const apiResponse: any = yield callEffect(apiRequest, apiCallConfig);
    if (apiResponse.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        data: apiResponse.data,
        action: 'ADD'
      };
      successNotification(textConstants.SEARCH_CRITERIA_SAVE_SUCCESS_MESSAGE);
      yield put(storeSavedSearchList(storePayload));
    }
  } catch (e) {
    console.log(e);
  }
}

export function * deleteSavedSearchListSagaFn (payload: any): any {
  try {
    const apiCallConfig = {
      method: 'DELETE',
      url: apiEndpointConstants.SAVED_SEARCH,
      data: payload.payload
    };
    const apiResponse: any = yield callEffect(apiRequest, apiCallConfig);

    if (apiResponse.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        action: 'DELETE',
        keyName: SEARCH_CRITERIA_ID,
        keyValue: apiResponse.data[0][SEARCH_CRITERIA_ID]
      };
      successNotification(textConstants.SEARCH_CRITERIA_DELETE_SUCCESS_MESSAGE);
      yield put(storeSavedSearchList(storePayload));
    }
  } catch (e) {
    console.log(e);
  }
}

export function * fetchSavedSearchList (): Generator<any> {
  yield takeLatest(GET_SAVED_SEARCH_LIST, fetchSavedSearchCriteriaSagaFn);
}

export function * savedSearchCriteria (): Generator<any> {
  yield takeLatest(SAVE_SEARCH_CRITERIA, savedSearchListSagaFn);
}
export function * deleteSavedSearchCriteria (): Generator<any> {
  yield takeLatest(DELETE_SAVED_SEARCH_LIST, deleteSavedSearchListSagaFn);
}
export default function * root (): Generator<AllEffect<any>> {
  yield all([
    fork(fetchSavedSearchList),
    fork(savedSearchCriteria),
    fork(deleteSavedSearchCriteria)
  ]);
}
