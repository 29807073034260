import React from 'react';
import type { FC } from 'react';
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons/lib/icons';

interface componentProps {
  value?: any
}
const LogLevelIconRenderer: FC<componentProps> = ({ ...params }) => {
  const logLevel = params?.value?.toString();
  return (
    <>
      {
        logLevel.toLowerCase() === 'error' &&
        <ExclamationCircleOutlined style={{ color: 'var(--error-color)' }} />
      }
      {
        logLevel.toLowerCase() !== 'error' &&
        <CheckCircleOutlined style={{ color: 'var(--primary-color)' }} />
      }
    </>
  );
};

export default LogLevelIconRenderer;
