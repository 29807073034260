import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import ConfigurationListTable from '../../../ConfigurationListTable';
import { deleteReportConfig, getReportConfigList, saveReportConfig } from '../../../../../redux/actions/Admin/reportConfigActions';
import { REPORT_CONFIG_COLUMNS } from '../../../../../constants/TableColumns/Admin/ReportConfigColumns';
import { checkIfExistsInArray } from '../../../../../helpers/commonHelper';
import { errorNotification } from '../../../../common/Notification';
import type { SelectedRows } from '../../../../../constants/Interfaces/AdminConfigListTypes';
import {
  AdminMainLayoutWrapper
} from '../../../../../assets/styles/commonStyled';
import AddEditConfigDrawer from '../../../Drawer/AddConfigDrawer';
import ReportConfigForm from '../../../UpsertForms/Admin/ReportConfig';
import routeConstants from '../../../../../constants/routeConstants';
import textConstants from '../../../../../constants/textConstants';
import { REPORT_CONFIG_ID } from '../../../../../constants/KeyLabels/commonKeyConstants';
import { reportConfigItemKey } from '../../../../../constants/KeyLabels/KeysToCheckForDuplicateItems';

const ReportConfigTab: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const reportConfigList = useSelector((state: any) => state.reportConfig.reportConfigList);

  const [isAddEditDrawerOpen, setAddEditDrawerVisibility] = useState<boolean>(false);
  const [isMultipleModify, setMultipleModifyState] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<SelectedRows>([]);
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);

  const handleItemNavigation = (currentIndex: number): void => {
    setActiveItemIndex(currentIndex);
  }

  const onAddEditDrawerClose = (): void => {
    setAddEditDrawerVisibility(false);
    setMultipleModifyState(false);
    setActiveItemIndex(0);
  }

  const onCreateBtnClick = (): void => {
    setSelectedRows([]);
    setAddEditDrawerVisibility(true);
  }

  const onMultipleModify = (configList: SelectedRows): void => {
    setMultipleModifyState(true);
    setSelectedRows(configList);
    setAddEditDrawerVisibility(true);
  }

  const toggleAddDrawerVisibility = (isOpen: boolean): void => {
    setAddEditDrawerVisibility(isOpen);
  }

  const onReportConfigSave = (data: any, isClosable?: boolean): void => {
    if (!isMultipleModify && !checkIfExistsInArray(reportConfigList, data, reportConfigItemKey)) {
      dispatch(saveReportConfig(data, isClosable ? toggleAddDrawerVisibility(false) : null, true));
    } else if (isMultipleModify) {
      dispatch(saveReportConfig(data, null, false));
    } else {
      errorNotification(textConstants.REPORT_CONFIG_ALREADY_EXIST_MESSAGE);
    }
  }

  useEffect(() => {
    if (isMultipleModify) {
      const updatedConfigId = selectedRows[activeItemIndex][REPORT_CONFIG_ID];

      const updatedConfigData = reportConfigList.find((item: any) => item[REPORT_CONFIG_ID] === updatedConfigId);

      const clonedSelectedRows = cloneDeep(selectedRows);
      clonedSelectedRows[activeItemIndex] = updatedConfigData;
      setSelectedRows(clonedSelectedRows);
    }
  }, [reportConfigList, isMultipleModify]);

  return (
      <AdminMainLayoutWrapper data-testid="reportConfigList" isTabContentList>
        <ConfigurationListTable
          columnDefs={REPORT_CONFIG_COLUMNS}
          getConfigList={getReportConfigList}
          gridItemId={REPORT_CONFIG_ID}
          onCreate={onCreateBtnClick}
          onConfigDelete={deleteReportConfig}
          onMultipleModify={onMultipleModify}
          onRowDoubleClicked={(params: { data: any }) => {
            history.push({
              pathname: routeConstants.ADMIN_REPORT_CONFIG_DETAIL,
              state: {
                isEditable: false,
                data: params?.data
              }
            });
          }}
          onEdit={(data: any) => {
            history.push({
              pathname: routeConstants.ADMIN_REPORT_CONFIG_DETAIL,
              state: {
                isEditable: true,
                data
              }
            });
          }}
          gridName={textConstants.REPORT_CONFIG_GRID_NAME}
          listName="reportConfig"
          rowSelection="multiple"
          rowMultiSelectWithClick
          hasRowAction
          hasCreate
        />
        {isAddEditDrawerOpen && (
          <AddEditConfigDrawer
            isOpen={isAddEditDrawerOpen}
            dataTestId='reportConfigAddDrawer'
            title={`${isMultipleModify ? 'Modify' : 'New'} ${textConstants.REPORT_CONFIG_ADD_EDIT_DRAWER_TITLE}`}
            onCancel={onAddEditDrawerClose}
            handleItemNavigation={handleItemNavigation}
            currentItemIndex={activeItemIndex}
            selectedItemsCount={selectedRows.length - 1}
          >
            <ReportConfigForm
              data={{
                ...(isMultipleModify && { ...selectedRows[activeItemIndex] })
              }}
              onCancel={onAddEditDrawerClose}
              onSave={onReportConfigSave}
              isEditable={isMultipleModify}
              isDrawerView
            />
          </AddEditConfigDrawer>
        )}
      </AdminMainLayoutWrapper>
  );
};

export default ReportConfigTab;
