import styled from 'styled-components';

export const DashboardMetricWrapper = styled.div`
  background: var(--white);
  padding: 10px;
  box-shadow: 0px 0px 2px var(--black-550);
  height: 100%;
  margin-top: 6px;

  .tab-with-replay-metrics {
    .ant-tabs-nav-list {
      opacity: 0;
      pointer-events: none;
    }
  }

  .react-dropdown-select {
    width: 260px;
    margin-right: 10px;
    
    &-dropdown {
      label.ant-checkbox-wrapper {
        span:not(.ant-checkbox) {
          word-break: break-word;
        }
      }
    }

    .tag-count {
      margin-right: 0;
    }

    .react-dropdown-select-content span {
      margin-bottom: 0;

      > span {
        max-width: 50px;
      }

      &:only-child > span {
        max-width: 175px;
      }
    }

    .anticon-search {
      width: 15px;
      color: var(--blue-color);
    }
  }

  .ant-tabs {
    .ant-tabs-extra-content {
      flex: 1;
    }
  
    & > .ant-tabs-nav {
      margin-bottom: 5px;

      &::before {
        content: none;
      }

      .ant-tabs-ink-bar {
        display: none;
      }

      .ant-tabs-nav-wrap {
        flex-grow: initial;
      }
    }

    .ant-tabs-content, .ant-tabs-tabpane-active {
      height: 100%;
    }

    .ant-tabs-tab {
      background: transparent;
      border: 1px solid var(--gray-225);
      border-block-start-width: calc(1px + 0.02px);
      border-inline-start-width: 0;
      border-inline-end-width: 1px;
      cursor: pointer;
      min-width: 70px;
      min-height: 29px;
      padding: 0 10px;
      margin: 0;
      text-align: center;
      font-size: var(--font-size-sm);

      &:first-child {
        border-radius: 2px 0px 0px 2px;
        border-left-width: 1px;
      }

      &:nth-last-child(2) {
        border-radius: 0px 2px 2px 0px;
      }

      .ant-tabs-tab-btn {
        width: 100%;
        // color: var(--black);
      }
  
      &.ant-tabs-tab-active {
        background-color: var(--blue-color-550);
      }
    }

    .ant-empty {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  }
`;

export const ChartControlsWrapper = styled.div`
  position: absolute;
  top: -35px;
  right: 155px;
  display: flex;

  .react-dropdown-select {
    height: 31px;
  }

  .ant-btn {
    height: 30px;
  }

  @media screen and (min-width: 1600px) {
    top: -41px;
    height: 36px;
    right: 165px;

    .ant-btn, .react-dropdown-select {
      height: 36px;
    }
  }
`;

export const DashboardMetricHeader = styled.div`
 display: flex;
 justify-content: space-between;
 padding-right: 25px;
`;

export const DashboardMetricTitle = styled.h1`
  font-size: var(--font-size-md);
  color: var(--black-100);
`;
