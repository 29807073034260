import { APPLICATION_NAME_KEY, APPLICATION_DOMAIN_KEY, SERVICE_NAME_KEY, COMPONENT_NAME_KEY, BUSINESS_ID_KEY, BUSINESS_ID_TWO_KEY, TRANSACTION_ID_KEY, TRANSACTION_TYPE_KEY, TRANSACTION_DOMAIN_KEY, ISSUE_GROUP_KEY, ERROR_CODE_KEY, EXCEPTION_CATEGORY_KEY, EXCEPTION_SEVERITY_KEY, EXCEPTION_STATUS_KEY, EXCEPTION_TYPE_KEY, HOST_NAME_KEY, MSG_KEY, REPLAY_COUNT_KEY, REPLAY_DESTINATION_KEY, RESOLUTION_DESCRIPTION_KEY, TIMEMARK_KEY } from '../KeyLabels/commonKeyConstants';
import { APPLICATION_NAME_LABEL, APPLICATION_DOMAIN_LABEL, SERVICE_NAME_LABEL, COMPONENT_NAME_LABEL, BUSINESS_ID_LABEL, BUSINESS_ID_TWO_LABEL, TRANSACTION_ID_LABEL, TRANSACTION_TYPE_LABEL, TRANSACTION_DOMAIN_LABEL, ISSUE_GROUP_LABEL, CODE_LABEL, MESSAGE_LABEL, HOST_NAME_LABEL, TIMEMARK_LABEL, REPLAY_STATUS_LABEL, RESOLUTION_DESCRIPTION_LABEL, REPLAY_DESTINATION_LABEL, REPLAY_COUNT_LABEL, CATEGORY_LABEL, TYPE_LABEL, SEVERITY_LABEL } from '../KeyLabels/labelConstants';

export const ExceptionDetailColumn = [
  { label: APPLICATION_NAME_LABEL, key: APPLICATION_NAME_KEY },
  { label: APPLICATION_DOMAIN_LABEL, key: APPLICATION_DOMAIN_KEY },
  { label: SERVICE_NAME_LABEL, key: SERVICE_NAME_KEY },
  { label: COMPONENT_NAME_LABEL, key: COMPONENT_NAME_KEY },
  { label: BUSINESS_ID_LABEL, key: BUSINESS_ID_KEY },
  { label: BUSINESS_ID_TWO_LABEL, key: BUSINESS_ID_TWO_KEY },
  { label: TRANSACTION_ID_LABEL, key: TRANSACTION_ID_KEY },
  { label: TRANSACTION_TYPE_LABEL, key: TRANSACTION_TYPE_KEY },
  { label: TRANSACTION_DOMAIN_LABEL, key: TRANSACTION_DOMAIN_KEY },
  { label: ISSUE_GROUP_LABEL, key: ISSUE_GROUP_KEY },
  { label: CODE_LABEL, key: ERROR_CODE_KEY },
  { label: MESSAGE_LABEL, key: MSG_KEY },
  { label: HOST_NAME_LABEL, key: HOST_NAME_KEY },
  { label: TIMEMARK_LABEL, key: TIMEMARK_KEY },
  { label: REPLAY_STATUS_LABEL, key: EXCEPTION_STATUS_KEY },
  { label: RESOLUTION_DESCRIPTION_LABEL, key: RESOLUTION_DESCRIPTION_KEY },
  { label: REPLAY_DESTINATION_LABEL, key: REPLAY_DESTINATION_KEY },
  { label: REPLAY_COUNT_LABEL, key: REPLAY_COUNT_KEY },
  { label: CATEGORY_LABEL, key: EXCEPTION_CATEGORY_KEY },
  { label: TYPE_LABEL, key: EXCEPTION_TYPE_KEY },
  { label: SEVERITY_LABEL, key: EXCEPTION_SEVERITY_KEY }
];
