import React from 'react';
import type { FC } from 'react';
import SearchQueryRenderer from './SearchQueryRenderer';
interface componentProps {
  value?: any
  data: any
}

const CustomSearchQueryRenderer: FC<componentProps> = ({ ...params }) => {
  const searchQuery = typeof (params.value) === 'string' ? JSON.parse(params.value) : params.value;
  return (
    <span style={{ display: 'flex' }}>
  <SearchQueryRenderer searchQuery={searchQuery} isParentTable />
    </span >
  );
};
export default CustomSearchQueryRenderer;
