import React, { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardWrapper } from './style';
import DashboardMetric from '../../components/AdditionalComponents/DashboardMetric';
import SearchPanel from '../../components/common/SearchPanel';
import { getDashboardExceptionMetricData, getDashboardLogMetricData, getDashboardReplayMetricData } from '../../redux/actions/Dashboard/dashboardActions';
import type { DashboardReplayRequestData, DashboardRequestData, SearchFormData, TimeRangeFormData } from '../../constants/Interfaces/DashboardTypes';
import { EXCEPTION_METRIC_TAB_KEY, LOG_METRIC_TAB_KEY, REPLAY_METRIC_TAB_KEY } from '../../constants/KeyLabels/commonKeyConstants';
import textConstants from '../../constants/textConstants';
import { searchByTypeOptions } from '../../constants/DropDownOptions/dashboard';

const Dashboard: FC = () => {
  const dispatch = useDispatch();
  const exceptionData = useSelector((state: any) => state.dashboard.exceptions);
  const replayData = useSelector((state: any) => state.dashboard.replays);
  const logData = useSelector((state: any) => state.dashboard.logs);

  const [dashboardFormPayload, setDashboardFormPayload] = useState<DashboardRequestData & DashboardReplayRequestData>({
    applications: [],
    timeRangeType: 'hourly',
    hours: 24
  });

  const [activeMetricTabName, setActiveMetricTabName] = useState<string>(LOG_METRIC_TAB_KEY);
  const [searchByTypeLabel, setSearchByTypeLabel] = useState<string>('');

  const getSelectedTimeRange = (selectedValue: string | number, isAllApplicationSelected: boolean): TimeRangeFormData => {
    let data = null;

    if (typeof selectedValue === 'string') {
      data = {
        timeRangeType: selectedValue
      }
    } else {
      data = {
        hours: selectedValue,
        timeRangeType: isAllApplicationSelected ? 'hourly' : 'daily'
      };
    }

    return data;
  }

  const fetchMetricDataByTabName = (tabName: string): void => {
    setSearchByTypeLabel(dashboardFormPayload?.dashboardType ? `by ${dashboardFormPayload.dashboardType}` : '');
    if (tabName === EXCEPTION_METRIC_TAB_KEY) {
      dispatch(getDashboardExceptionMetricData(dashboardFormPayload));
    } else if (tabName === REPLAY_METRIC_TAB_KEY) {
      const { dashboardType, ...replayMetricData } = dashboardFormPayload;
      dispatch(getDashboardReplayMetricData(replayMetricData));
    } else {
      dispatch(getDashboardLogMetricData(dashboardFormPayload));
    }
  }

  const onMetricTabChangeHandler = (tabName: string): void => {
    setActiveMetricTabName(tabName);
    fetchMetricDataByTabName(tabName);
  }

  const searchFormData = useMemo((): SearchFormData => {
    const data = {
      applicationName: dashboardFormPayload.application ?? '',
      ...(dashboardFormPayload.application && {
        searchByType: dashboardFormPayload.dashboardType,
        ...(dashboardFormPayload.startTime && { startTime: dashboardFormPayload.startTime }),
        ...(dashboardFormPayload.endTime && { endTime: dashboardFormPayload.endTime }),
        ...(dashboardFormPayload.selectedDate && { selectedDate: dashboardFormPayload.selectedDate })
      }),
      ...(dashboardFormPayload.hours && { quickTime: dashboardFormPayload.hours }),
      ...(dashboardFormPayload.weekly && { quickTime: 'weekly' }),
      ...(dashboardFormPayload.monthly && { quickTime: 'monthly' }),
      ...(dashboardFormPayload.yearly && { quickTime: 'yearly' })
    }

    return data;
  }, [activeMetricTabName]);

  const onSearchSubmit = (formData: SearchFormData): void => {
    let currentMetricTab = activeMetricTabName;

    const reqPayload = {
      ...(!formData.applicationName && {
        applications: []
      }),
      ...(formData.applicationName && {
        application: formData.applicationName,
        dashboardType: formData.searchByType,
        ...(formData.searchByType === 'Replay' && {
          startTime: formData.startTime,
          endTime: formData.endTime
        }),
        ...(formData.searchByType !== 'Replay' && {
          ...(formData.selectedDate && {
            selectedDate: formData.selectedDate,
            timeRangeType: 'daily'
          })
        })
      }),
      ...(formData.quickTime && getSelectedTimeRange(formData.quickTime, !formData.applicationName))
    }

    if (formData.searchByType !== 'Replay' && activeMetricTabName === REPLAY_METRIC_TAB_KEY) {
      currentMetricTab = LOG_METRIC_TAB_KEY;
    } else if (formData.searchByType === 'Replay') {
      currentMetricTab = REPLAY_METRIC_TAB_KEY;
    }

    setActiveMetricTabName(currentMetricTab);
    setDashboardFormPayload(reqPayload);
  }

  useEffect(() => {
    if (dashboardFormPayload) {
      fetchMetricDataByTabName(activeMetricTabName);
    }
  }, [dashboardFormPayload]);

  return (
    <DashboardWrapper data-testid="dashboardPage">
      <SearchPanel
        formData={searchFormData}
        onSearchSubmitHandler={onSearchSubmit}
        searchByOptionList={searchByTypeOptions}
        extraSearchType='Replay'
        hasSearchByType
      />
      <DashboardMetric
        metricSearchType={dashboardFormPayload.dashboardType}
        onMetricTabChange={onMetricTabChangeHandler}
        data={{
          exceptionData,
          logData,
          replayData
        }}
        title={dashboardFormPayload.dashboardType === 'Replay' ? textConstants.DASHBOARD_REPLAY_METRIC_CHART_TITLE : `${textConstants.DASHBOARD_METRIC_CHART_TITLE} ${searchByTypeLabel}`}
        hasReplayMetrics={dashboardFormPayload.dashboardType === 'Replay'}
      />
    </DashboardWrapper>
  );
};

export default Dashboard;
