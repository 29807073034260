import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import QuickPickerContent from './QuickPickerContent';
import { PopOverWrapper } from '../../../../assets/styles/commonStyled';

interface ComponentProps {
  contentType?: string
  children?: any
  handleOpenChange: any
  isPopOverOpen?: boolean
  closePopOver?: any
  setDate?: any
}

const CalendarPopOver: FC<ComponentProps> = ({ contentType, children, handleOpenChange, isPopOverOpen, closePopOver, setDate }) => {
  return (
    <PopOverWrapper
      data-testid="testid-popover"
      placement="bottom"
      content={
        contentType === 'QuickPicker' && <QuickPickerContent closePopOver={closePopOver} setDateToParent={setDate} />
      }
      trigger="click"
      onOpenChange={handleOpenChange}
      open={isPopOverOpen}
    >
      {children}
    </PopOverWrapper>
  );
};

CalendarPopOver.propTypes = {
  contentType: PropTypes.string,
  children: PropTypes.any,
  handleOpenChange: PropTypes.func.isRequired,
  isPopOverOpen: PropTypes.bool,
  closePopOver: PropTypes.func,
  setDate: PropTypes.func
};

export default CalendarPopOver;
