import styled from 'styled-components';

export const Footer = styled.div`
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 100%;
    z-index: 1;
    background-color: var(--white);
    border: 1px solid var(--gray-250);
    padding: 7px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 99;

    &.hasAdditionalInfo {
        justify-content: space-between;
    }
    &.grey-theme {
        background-color: var(--gray-150);
        border: 1px solid var(--gray-275);
        box-shadow: 0px 0px 3px 1px var(--gray-250);
    }

    &.add-form-footer {
        position: absolute;
        box-shadow: none;
    }
`;

export const FooterBtnWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    > * {
        margin-right: 7px;
    }
    // > .ant-btn:not(:disabled):hover,
    // .ant-btn:not(:disabled):focus,
    // .ant-btn:not(:disabled):active {
    //   color: #fff; //will change according to background color
    // }
`;

export const AdditionalInfoWrapper = styled.div`
    margin-left: 3vw;
    color: var(--black-100);
`;
