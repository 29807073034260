import type {
  GetConfigDetailsActionType,
  SetConfigDetailsActionType,
  DeleteConfigDataActionType
} from '../../../../constants/Interfaces/AdminConfigActionTypes';
import {
  GET_USERS_LIST,
  SET_USERS_LIST,
  SAVE_USERS_LIST,
  DELETE_USER_LIST
} from '../../ActionTypes/AccessManagement/UserAccess/userAccessListTypes';

interface SaveConfigDetailAction {
  type: string
  payload: {
    data: any[]
    callBackFunc?: any
    isAdd?: boolean
  }
}

export function getUserList (): GetConfigDetailsActionType {
  return {
    type: GET_USERS_LIST
  };
}

export function setUserList (data: any): SetConfigDetailsActionType {
  return {
    type: SET_USERS_LIST,
    data
  };
}

export function saveUserList (data: any, callBackFunc?: any, isAdd?: boolean): SaveConfigDetailAction {
  return {
    type: SAVE_USERS_LIST,
    payload: {
      data,
      callBackFunc,
      isAdd
    }
  };
}

export function deleteUserList (data: any, callBackFunc?: any): DeleteConfigDataActionType {
  return {
    type: DELETE_USER_LIST,
    payload: {
      data,
      callBackFunc
    }
  };
}
