/**
 * Configuration for Okta
 */

declare global {
  interface Window {
    appConfig?: {
      REACT_APP_CLIENT_ID: string
      REACT_APP_ISSUER: string
    }
  }
}

const CLIENT_ID = window?.appConfig?.REACT_APP_CLIENT_ID ?? '';
const ISSUER =
    window?.appConfig?.REACT_APP_ISSUER ?? '';
const DISABLE_HTTPS_CHECK = true;
const PKCE = true;
const REDIRECT_URI = window.location.origin + '/callback';
const config = {
  clientId: CLIENT_ID,
  issuer: ISSUER,
  redirectUri: REDIRECT_URI,
  scopes: ['openid', 'profile', 'email'],
  pkce: PKCE,
  disableHttpsCheck: DISABLE_HTTPS_CHECK
};

export default config;
