import type {
  AddConfigDetailsActionType,
  GetConfigDetailsActionType,
  SetConfigDetailsActionType
} from '../../../constants/Interfaces/AdminConfigActionTypes';
import {
  DELETE_COLUMN_ALERT_CONFIG,
  GET_COLUMN_ALERT_CONFIG_LIST,
  SET_COLUMN_ALERT_CONFIG_LIST,
  SAVE_COLUMN_ALERT_CONFIG
} from '../ActionTypes/Admin/columnAlertConfigTypes';

interface DeleteConfigAction {
  type: string
  data: any[]
  callBackFunc: any
}

export function getColumnAlertConfigList (applicationName?: string): GetConfigDetailsActionType {
  return {
    type: GET_COLUMN_ALERT_CONFIG_LIST,
    applicationName
  };
}

export function setColumnAlertConfigList (data: any): SetConfigDetailsActionType {
  return {
    type: SET_COLUMN_ALERT_CONFIG_LIST,
    data
  };
}

export function deleteColumnAlertConfig (data: any, callBackFunc: any): DeleteConfigAction {
  return {
    type: DELETE_COLUMN_ALERT_CONFIG,
    data,
    callBackFunc
  };
}

export function updateColumnAlertConfig (data: any): SetConfigDetailsActionType {
  return {
    type: SAVE_COLUMN_ALERT_CONFIG,
    data
  };
}

export function addColumnAlertConfig (data: any, isAdd?: boolean): AddConfigDetailsActionType {
  return {
    type: SAVE_COLUMN_ALERT_CONFIG,
    data,
    isAdd
  };
}
