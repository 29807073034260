import React from 'react';
import type { FC } from 'react';
import { BasicInfoValue } from '../../../components/AdditionalComponents/LogException/style';

interface componentProps {
  value?: any
}

const ExceptionStatusRenderer: FC<componentProps> = ({ ...params }) => {
  const replayStatus = params?.value?.toString().toLowerCase();

  return (
    <BasicInfoValue className={`status-grid-btn ${replayStatus}-btn`}>
      {replayStatus}
    </BasicInfoValue>
  );
};

export default ExceptionStatusRenderer;
