import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import type { FC } from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import DraggableFileUpload from '../../components/common/DraggableFileUpload';
import ReplayStatusCountBar from '../../components/common/ReplayStatusCountBar';
import { fileReplayUploadAPIRequestHandler } from '../../services/fileReplayServiceHandler';
import { getFileReplayDashboardData } from '../../redux/actions/FileReplay/fileReplayActions';
import apiEndpointConstants from '../../constants/apiEndpointConstants';
import textConstants from '../../constants/textConstants';
import CommonTableComponent from '../../components/common/Table/CommonTable';
import { fileReplayDashboardTableColumns } from '../../constants/TableColumns/FileReplayDashboardTableColumns';
import { convertTimeToTimeZoneForDisplay } from '../../helpers/commonHelper';
import {
  FileReplayContainer,
  FileReplayHeader,
  FileReplayLastUpdatedLabel,
  FileReplayStatusHeader,
  FileReplayTableWrapper,
  FileReplayWrapper
} from './style';

const FileReplay: FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const replayDashboardData = useSelector((state: any) => state.fileReplay.dashboard);

  const layoutWrapperRef = useRef<any>(null);
  const [uploadStatus, setUploadFileStatus] = useState<boolean>(false);
  const [tableHeight, setTableHeight] = useState<string>('');

  const reloadFileStatus = (): void => {
    dispatch(getFileReplayDashboardData());
  }

  const onUploadFileComplete = (isCompleted: boolean, message: string): void => {
    if (message) {
      api.success({ message });
      reloadFileStatus();
    }
    setUploadFileStatus(isCompleted);
  }

  const uploadFileHandler = (formData: any): void => {
    setUploadFileStatus(false);
    fileReplayUploadAPIRequestHandler(formData, apiEndpointConstants.BULK_REPLAY_DATA_UPLOAD, onUploadFileComplete);
  };

  useEffect(() => {
    reloadFileStatus();
  }, []);

  useLayoutEffect(() => {
    const height = `calc(100vh - ${layoutWrapperRef?.current?.getBoundingClientRect().top + 62}px)`;
    setTableHeight(height);
  })

  return (
    <FileReplayWrapper>
      <FileReplayHeader>
        <ReloadOutlined /> {textConstants.FILE_REPLAY_DASHBOARD_TITLE}
      </FileReplayHeader>
      <FileReplayContainer>
        <DraggableFileUpload
          allowedExtension='.xlsx, .xls'
          fileUploadTitle={textConstants.FILE_REPLAY_NEW_REPLAY_EXCEPTION_LABEL}
          onFileUpload={uploadFileHandler}
          isUploadCompleted={uploadStatus}
          maxFileSize={1}
          message={textConstants.FILE_REPLAY_UPLOAD_ALLOWED_FILE_LABEL}
        />
        <FileReplayStatusHeader>
          <div>
            <h3>{textConstants.FILE_REPLAY_DASHBOARD_SUBHEADER_TITLE}</h3>
            <FileReplayLastUpdatedLabel><span>Last Updated</span> {convertTimeToTimeZoneForDisplay(replayDashboardData?.lasUpdatedOn)}</FileReplayLastUpdatedLabel>
          </div>
          <Button
            className='btn-regular'
            type="primary"
            onClick={reloadFileStatus}
            icon={<ReloadOutlined />}
            data-testid="reloadBtn"
          >
            {textConstants.RELOAD_BUTTON_LABEL}
          </Button>
        </FileReplayStatusHeader>
        <ReplayStatusCountBar
          replayData={{
            resolved: replayDashboardData?.resolvedReplay,
            progress: replayDashboardData?.inProgressReplay,
            schedule: replayDashboardData?.scheduledReplay,
            pending: replayDashboardData?.pendingReplay,
            error: replayDashboardData?.erroredReplay
          }}
        />
        <FileReplayTableWrapper ref={layoutWrapperRef}>
          <CommonTableComponent
            filterBy={{
              fieldName: 'status',
              fieldLabel: 'Status'
            }}
            height={tableHeight}
            rowData={replayDashboardData.fileReplaysFileReplayResponses}
            columnDefs={fileReplayDashboardTableColumns}
            showSearchBar
            className={'primary-theme'}
            tableTitle={textConstants.FILE_REPLAY_DASHBOARD_TABLE_TITLE}
            showHeaderFilterButton
          />
        </FileReplayTableWrapper>
      </FileReplayContainer>
      {contextHolder}
    </FileReplayWrapper>
  );
};

export default FileReplay;
