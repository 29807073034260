import { produce } from 'immer';
import {
  RESET_LOG_EXCEPTION_DETAIL_METRIC_DATA,
  SET_EXCEPTION_DETAIL_BLOB_METRIC_DATA,
  SET_LOG_DETAIL_BLOB_METRIC_DATA,
  SET_LOG_SUMMARY_BLOB_METRIC_DATA,
  SET_EXCEPTION_SUMMARY_BLOB_METRIC_DATA
} from '../../../actions/ActionTypes/Admin/blobMetricsTypes';

export interface BlobMetricsState {
  logSummaryList: any[]
  exceptionSummaryList: any[]
  logDetailList: any[]
  exceptionDetailList: any[]
}

const initialState = {
  logSummaryList: [],
  exceptionSummaryList: [],
  logDetailList: [],
  exceptionDetailList: []
};

const blobMetricsReducer = (state = initialState, action: any): BlobMetricsState =>
  produce(state, (draft: BlobMetricsState) => {
    switch (action.type) {
      case RESET_LOG_EXCEPTION_DETAIL_METRIC_DATA:
        draft.logDetailList = [];
        draft.exceptionDetailList = [];
        break;
      case SET_LOG_SUMMARY_BLOB_METRIC_DATA:
        draft.logSummaryList = action.data;
        break;
      case SET_EXCEPTION_SUMMARY_BLOB_METRIC_DATA:
        draft.exceptionSummaryList = action.data;
        break;
      case SET_LOG_DETAIL_BLOB_METRIC_DATA:
        draft.logDetailList = action.data;
        break;
      case SET_EXCEPTION_DETAIL_BLOB_METRIC_DATA:
        draft.exceptionDetailList = action.data;
        break;
    }
  });

export default blobMetricsReducer;
