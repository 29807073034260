
export const solmanStagingFormFields = [
  {
    label: 'Application Name',
    name: 'applicationName',
    id: 'applicationName',
    type: 'text'
  },
  {
    label: 'Interface',
    name: 'interfaceID',
    id: 'interfaceID',
    type: 'text'
  },
  {
    label: 'Division',
    name: 'division',
    id: 'division',
    type: 'text'
  },
  {
    label: 'Bus Error Code',
    name: 'busErrorCode',
    id: 'busErrorCode',
    type: 'text'
  },
  {
    label: 'Bus Error Group',
    name: 'busErrorGroup',
    id: 'busErrorGroup',
    type: 'text'
  },
  {
    label: 'Enable',
    name: 'enable',
    id: 'enable',
    type: 'radio'
  },
  {
    label: 'Use Tech Error Code',
    name: 'useTechErrCode',
    id: 'useTechErrCode',
    type: 'radio'
  }
];
