import { JMSX_APP_ID_KEY, JMSX_CONSUMER_TXID_KEY, JMSX_DELIVERY_COUNT_KEY, JMSX_GROUP_ID_KEY, JMSX_GROUP_SEQ_KEY, JMSX_PRODUCER_TIXID_KEY, JMSX_RCV_TIMESTAMP_KEY, JMSX_USER_ID_KEY, JMS_CORRELATION_ID_KEY, JMS_DELIVERY_MODE_KEY, JMS_DESTINATION_KEY, JMS_EXPIRATION_KEY, JMS_MESSAGE_ID_KEY, JMS_PRIORITY_KEY, JMS_REDELIVERED_KEY, JMS_REPLY_TO_KEY, JMS_TIMESTAMP_KEY, JMS_TYPE_KEY } from '../KeyLabels/commonKeyConstants';
import { JMSX_APP_ID_LABEL, JMSX_CONSUMER_TXID_LABEL, JMSX_DELIVERY_COUNT_LABEL, JMSX_GROUP_ID_LABEL, JMSX_GROUP_SEQ_LABEL, JMSX_PRODUCER_TIXID_LABEL, JMSX_RCV_TIMESTAMP_LABEL, JMSX_USER_ID_LABEL, JMS_CORRELATION_ID_LABEL, JMS_DELIVERY_MODE_LABEL, JMS_DESTINATION_LABEL, JMS_EXPIRATION_LABEL, JMS_MESSAGE_ID_LABEL, JMS_PRIORITY_LABEL, JMS_REDELIVERED_LABEL, JMS_REPLY_TO_LABEL, JMS_TIMESTAMP_LABEL, JMS_TYPE_LABEL } from '../KeyLabels/labelConstants';

export const JMS_HEADER_COLUMNS = [
  { field: JMS_DESTINATION_KEY, headerName: JMS_DESTINATION_LABEL },
  { field: JMS_REPLY_TO_KEY, headerName: JMS_REPLY_TO_LABEL },
  { field: JMS_DELIVERY_MODE_KEY, headerName: JMS_DELIVERY_MODE_LABEL },
  { field: JMS_MESSAGE_ID_KEY, headerName: JMS_MESSAGE_ID_LABEL },
  { field: JMS_TIMESTAMP_KEY, headerName: JMS_TIMESTAMP_LABEL },
  { field: JMS_EXPIRATION_KEY, headerName: JMS_EXPIRATION_LABEL },
  { field: JMS_REDELIVERED_KEY, headerName: JMS_REDELIVERED_LABEL },
  { field: JMS_PRIORITY_KEY, headerName: JMS_PRIORITY_LABEL },
  { field: JMS_CORRELATION_ID_KEY, headerName: JMS_CORRELATION_ID_LABEL },
  { field: JMS_TYPE_KEY, headerName: JMS_TYPE_LABEL }
];
export const JMS_PROPERTIES_COLUMNS = [
  { field: JMSX_USER_ID_KEY, headerName: JMSX_USER_ID_LABEL },
  { field: JMSX_APP_ID_KEY, headerName: JMSX_APP_ID_LABEL },
  { field: JMSX_PRODUCER_TIXID_KEY, headerName: JMSX_PRODUCER_TIXID_LABEL },
  { field: JMSX_CONSUMER_TXID_KEY, headerName: JMSX_CONSUMER_TXID_LABEL },
  { field: JMSX_RCV_TIMESTAMP_KEY, headerName: JMSX_RCV_TIMESTAMP_LABEL },
  { field: JMSX_DELIVERY_COUNT_KEY, headerName: JMSX_DELIVERY_COUNT_LABEL },
  { field: JMSX_GROUP_ID_KEY, headerName: JMSX_GROUP_ID_LABEL },
  { field: JMSX_GROUP_SEQ_KEY, headerName: JMSX_GROUP_SEQ_LABEL }
];
