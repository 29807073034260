import React, { useEffect } from 'react';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';
import textConstants from '../../../constants/textConstants';
import {
  AdminMainBreadCrumbWrapper,
  AdminMainLayoutWrapper,
  AdminBasicTabWrapper
} from '../../../assets/styles/commonStyled';
import Tabs from '../../../components/common/Tabs';
import {
  BLOB_METRIC_SUMMARY_TAB_KEY,
  BLOB_METRIC_DETAILS_TAB_KEY
} from '../../../constants/KeyLabels/commonKeyConstants';
import { resetLogExceptionDetailMetricData } from '../../../redux/actions/Admin/blobMetricsActions';
import DetailTab from '../../../components/AdditionalComponents/TabContents/BlobMetrics/DetailTab';
import SummaryTab from '../../../components/AdditionalComponents/TabContents/BlobMetrics/SummaryTab';

const BlobMetrics: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(resetLogExceptionDetailMetricData());
  }, []);

  return (
    <AdminMainLayoutWrapper data-testid="blobMetricsWrapper">
      <AdminMainBreadCrumbWrapper>
        {textConstants.BLOB_METRICS_PAGE_TITLE}
      </AdminMainBreadCrumbWrapper>
      <AdminBasicTabWrapper isSecondary>
        <>
          <Tabs
            tabClassName="basic-theme"
            tabSize="small"
            tabType="line"
            tabItems={[
              {
                key: BLOB_METRIC_SUMMARY_TAB_KEY,
                label: 'Summary',
                children: <SummaryTab />
              },
              {
                key: BLOB_METRIC_DETAILS_TAB_KEY,
                label: 'Detail View',
                children: <DetailTab />
              }
            ]}
          />
        </>
      </AdminBasicTabWrapper>
    </AdminMainLayoutWrapper>
  );
};

export default BlobMetrics;
