import type {
  GetConfigDetailsActionType,
  SetConfigDetailsActionType
} from '../../../constants/Interfaces/AdminConfigActionTypes';
import {
  GET_SCHEDULE_CONFIG_LIST,
  SET_SCHEDULE_CONFIG_LIST
} from '../ActionTypes/Admin/scheduleConfigTypes';

export function getScheduleConfigList (): GetConfigDetailsActionType {
  return {
    type: GET_SCHEDULE_CONFIG_LIST
  };
}

export function setScheduleConfigList (data: any): SetConfigDetailsActionType {
  return {
    type: SET_SCHEDULE_CONFIG_LIST,
    data
  };
}
