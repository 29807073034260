import * as types from '../ActionTypes/userProfileTypes';

interface GetUserAccessInfoAction {
  type: string
}
interface SetOktaAuthenticatedStatusAction {
  type: string
  status: boolean
}

interface SetUserAccessInfoAction {
  type: string
  userAccessData: object
}

export function setOktaAuthenticatedStatus (status: boolean): SetOktaAuthenticatedStatusAction {
  return {
    type: types.SET_OKTA_AUTHENTICATED_STATUS,
    status
  };
}

export function getLoggedInUserAccessInfo (): GetUserAccessInfoAction {
  return {
    type: types.GET_USER_ACCESS_INFO
  };
}

export function setUserAccessInfo (userAccessData: object): SetUserAccessInfoAction {
  return {
    type: types.SET_USER_ACCESS_INFO,
    userAccessData
  };
}
