import { call, fork, put, all, takeLatest } from 'redux-saga/effects';
import type { AllEffect, CallEffect, ForkEffect, PutEffect } from 'redux-saga/effects';
import { createLogger } from 'redux-logger';
import { GET_USER_ACCESS_INFO } from '../../actions/ActionTypes/userProfileTypes';
import apiURL from '../../../constants/apiEndpointConstants';
import { updateDataLoadingStatus } from '../../actions/CommonConfig/commonConfigActions';
import { setUserAccessInfo } from '../../actions/UserProfile/userProfileActions';
import { apiRequest } from '../../../services/axiosInterceptor';

const callEffect: any = call;

export function * fetchUserAccessInfo (): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: 'get',
      url: apiURL.USER_AUTHENITCATE,
      headers: {
        userFromNewUI: true
      }
    };
    yield put(updateDataLoadingStatus(1));
    const userAccessData: any = yield callEffect(apiRequest, config);

    if (userAccessData?.data?.roleAccess) {
      if (!userAccessData.data.roleAccess.COMMONLE) {
        userAccessData.data.roleAccess.COMMONLE = ['view'];
      }
    }
    yield put(setUserAccessInfo(userAccessData.data));
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    yield put(updateDataLoadingStatus(-1));
    createLogger(err);
  }
}

export function * getLoggedInUserAccessInfo (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_USER_ACCESS_INFO, fetchUserAccessInfo);
}

export default function * root (): Generator<AllEffect<any>> {
  yield all([fork(getLoggedInUserAccessInfo)]);
}
