import { call, put, fork, all, takeLatest } from 'redux-saga/effects';
import type { AllEffect, CallEffect, ForkEffect, PutEffect } from 'redux-saga/effects';
import { createLogger } from 'redux-logger';
import { setFileReplayDashboardData, setReplayFileDetails } from '../../actions/FileReplay/fileReplayActions';
import { updateDataLoadingStatus } from '../../actions/CommonConfig/commonConfigActions';
import { GET_FILE_REPLAY_DASHBOARD_DATA, GET_REPLAY_FILE_DETAILS } from '../../actions/ActionTypes/FileReplay/fileReplayTypes';
import { apiRequest } from '../../../services/axiosInterceptor';
import apiURL from '../../../constants/apiEndpointConstants';

const callEffect: any = call;

export function * fetchFileReplayDashboardData (): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: 'get',
      url: apiURL.GET_FILE_REPLAY_DASHBOARD_DATA
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response) {
      yield put(setFileReplayDashboardData(response?.data));
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    yield put(updateDataLoadingStatus(-1));
    createLogger(err);
  }
}

export function * fetchReplayFileDetails (data: any): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: 'get',
      url: `${apiURL.GET_REPLAY_FILE_DETAILS}/${data?.replayID}/`
    };

    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response) {
      yield put(setReplayFileDetails(response?.data));
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    yield put(updateDataLoadingStatus(-1));
    createLogger(err);
  }
}

export function * getFileReplayDashboardData (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_FILE_REPLAY_DASHBOARD_DATA, fetchFileReplayDashboardData);
}

export function * getReplayFileDetails (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_REPLAY_FILE_DETAILS, fetchReplayFileDetails);
}

export default function * root (): Generator<AllEffect<any>> {
  yield all([fork(getFileReplayDashboardData), fork(getReplayFileDetails)]);
}
