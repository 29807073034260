import { combineReducers } from 'redux';
import applicationConfigReducer from './Admin';
import blobMetricsReducer from './Admin/BlobMetrics';
import columnAlertConfigReducer from './Admin/ColumnAlertConfig';
import exceptionConfigReducer from './Admin/ExceptionConfig';
import replayConfigReducer from './Admin/ReplayConfig';
import reportConfigReducer from './Admin/ReportConfig';
import scheduleConfigReducer from './Admin/ScheduleConfig';
import solmanStagingConfigReducer from './Admin/SolmanConfig';
import fileReplayConfigReducer from './FileReplay';
import tableConfigReducer from './Admin/TableConfig';
import logReportConfigReducer from './Admin/LogReportConfig';
import exceptionReportConfigReducer from './Admin/ExceptionReportConfig';
import userProfileReducer from './UserProfile';
import LogSearchReducer from './LogSearch';
import commonConfigReducer from './CommonConfig';
import userAccessReducer from './AccessManagement/UserAccess';
import ExceptionSearchReducer from './ExceptionSearch';
import SavedSearchReducer from './SavedSearchCriteria';
import ApplicationListConfigReducer from './Admin/ApplicationListConfig';
import groupAccessReducer from './AccessManagement/GroupAccess';
import dashboardReducer from './Dashboard';

const rootReducer = (): any =>
  combineReducers({
    applicationConfig: applicationConfigReducer,
    blobMetrics: blobMetricsReducer,
    columnAlertConfig: columnAlertConfigReducer,
    dashboard: dashboardReducer,
    exceptionConfig: exceptionConfigReducer,
    fileReplay: fileReplayConfigReducer,
    solmanConfig: solmanStagingConfigReducer,
    replayConfig: replayConfigReducer,
    reportConfig: reportConfigReducer,
    scheduleConfig: scheduleConfigReducer,
    tableConfig: tableConfigReducer,
    logReportConfig: logReportConfigReducer,
    exceptionReportConfig: exceptionReportConfigReducer,
    userAccess: userProfileReducer,
    commonConfig: commonConfigReducer,
    userConfig: userAccessReducer,
    logSearchReducer: LogSearchReducer,
    exceptionSearchReducer: ExceptionSearchReducer,
    savedSearch: SavedSearchReducer,
    listConfig: ApplicationListConfigReducer,
    groupConfig: groupAccessReducer
  });

export default rootReducer;
