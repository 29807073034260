import React from 'react';
import type { FC } from 'react';
import { AdminMainLayoutWrapper, AdminMainBreadCrumbWrapper } from '../../../assets/styles/commonStyled';
import textConstants from '../../../constants/textConstants';
import ReplayConfigList from '../../../components/AdditionalComponents/DetailSummaryPage/Admin/ReplayConfig/ReplayConfigList';

const ReplayConfig: FC = () => {
  return (
    <AdminMainLayoutWrapper data-testid="replayConfigList">
      <AdminMainBreadCrumbWrapper>
        {textConstants.REPLAY_CONFIG_PAGE_TITLE}
      </AdminMainBreadCrumbWrapper>
      <ReplayConfigList />
    </AdminMainLayoutWrapper>
  );
};

export default ReplayConfig;
