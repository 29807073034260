import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import ConfigurationListTable from '../../../ConfigurationListTable';
import { LOG_REPORT_CONFIG_COLUMNS } from '../../../../../constants/TableColumns/Admin/LogReportConfigColumns';
import { checkIfExistsInArray } from '../../../../../helpers/commonHelper';
import { errorNotification } from '../../../../common/Notification';
import {
  getLogReportConfigList,
  deleteLogReportConfig,
  saveLogReportConfig
} from '../../../../../redux/actions/Admin/logReportConfigActions';
import { AdminMainLayoutWrapper } from '../../../../../assets/styles/commonStyled';
import AddEditConfigDrawer from '../../../Drawer/AddConfigDrawer';
import textConstants from '../../../../../constants/textConstants';
import routeConstants from '../../../../../constants/routeConstants';
import LogReportConfigForm from '../../../UpsertForms/Admin/LogReportConfig';
import type { SelectedRows } from '../../../../../constants/Interfaces/AdminConfigListTypes';
import { LOG_REPORT_ID } from '../../../../../constants/KeyLabels/commonKeyConstants';
import { logReportConfigItemKey } from '../../../../../constants/KeyLabels/KeysToCheckForDuplicateItems';

const LogReportConfigTab: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const logReportConfigList = useSelector((state: any) => state.logReportConfig.logReportConfigList);

  const [isAddEditDrawerOpen, setAddEditDrawerVisibility] = useState<boolean>(false);
  const [isMultipleModify, setMultipleModifyState] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<SelectedRows>([]);
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);

  const handleItemNavigation = (currentIndex: number): void => {
    setActiveItemIndex(currentIndex);
  }

  const onAddEditDrawerClose = (): void => {
    setAddEditDrawerVisibility(false);
    setMultipleModifyState(false);
    setActiveItemIndex(0);
  }

  const onCreateBtnClick = (): void => {
    setSelectedRows([]);
    setAddEditDrawerVisibility(true);
  }

  const onMultipleModify = (configList: SelectedRows): void => {
    setMultipleModifyState(true);
    setSelectedRows(configList);
    setAddEditDrawerVisibility(true);
  }

  const toggleAddDrawerVisibility = (isOpen: boolean): void => {
    setAddEditDrawerVisibility(isOpen);
  }

  const onLogReportConfigSave = (data: any, isClosable?: boolean): void => {
    if (!isMultipleModify && !checkIfExistsInArray(logReportConfigList, data, logReportConfigItemKey)) {
      dispatch(saveLogReportConfig(data, isClosable ? toggleAddDrawerVisibility(false) : null, true));
    } else if (isMultipleModify) {
      dispatch(saveLogReportConfig(data, null, false));
    } else {
      errorNotification(textConstants.LOG_REPORT_CONFIG_ALREADY_EXIST_MESSAGE);
    }
  }

  useEffect(() => {
    if (isMultipleModify) {
      const updatedConfigId = selectedRows[activeItemIndex][LOG_REPORT_ID];

      const updatedConfigData = logReportConfigList.find((item: any) => item[LOG_REPORT_ID] === updatedConfigId);

      const clonedSelectedRows = cloneDeep(selectedRows);
      clonedSelectedRows[activeItemIndex] = updatedConfigData;
      setSelectedRows(clonedSelectedRows);
    }
  }, [logReportConfigList, isMultipleModify]);

  return (
    <AdminMainLayoutWrapper data-testid="logReportConfigList" isTabContentList>
      <ConfigurationListTable
        columnDefs={LOG_REPORT_CONFIG_COLUMNS}
        getConfigList={getLogReportConfigList}
        gridItemId={LOG_REPORT_ID}
        onCreate={onCreateBtnClick}
        onConfigDelete={deleteLogReportConfig}
        onMultipleModify={onMultipleModify}
        onRowDoubleClicked={(params: { data: any }) => {
          history.push({
            pathname: routeConstants.ADMIN_LOG_REPORT_CONFIG_DETAIL,
            state: {
              isEditable: false,
              data: params?.data
            }
          });
        }}
        onEdit={(data: any) => {
          history.push({
            pathname: routeConstants.ADMIN_LOG_REPORT_CONFIG_DETAIL,
            state: {
              isEditable: true,
              data
            }
          });
        }}
        gridName={textConstants.LOG_REPORT_CONFIG_GRID_NAME}
        listName="logReportConfig"
        rowSelection="multiple"
        rowMultiSelectWithClick
        hasRowAction
        hasCreate
      />
       {isAddEditDrawerOpen && (
          <AddEditConfigDrawer
            isOpen={isAddEditDrawerOpen}
            dataTestId='logReportConfigAddDrawer'
            title={`${isMultipleModify ? 'Modify' : 'New'} ${textConstants.LOG_REPORT_CONFIG_ADD_EDIT_DRAWER_TITLE}`}
            onCancel={onAddEditDrawerClose}
            handleItemNavigation={handleItemNavigation}
            currentItemIndex={activeItemIndex}
            selectedItemsCount={selectedRows.length - 1}
          >
            <LogReportConfigForm
              data={{
                ...(isMultipleModify && { ...selectedRows[activeItemIndex] })
              }}
              onCancel={onAddEditDrawerClose}
              onSave={onLogReportConfigSave}
              isEditable={isMultipleModify}
              isDrawerView
            />
          </AddEditConfigDrawer>
       )}
    </AdminMainLayoutWrapper>
  );
};

export default LogReportConfigTab;
