export const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
export const dateTimeFormatLabel = 'MMM DD, YYYY @HH:mm';

// Error Msgs
export const onlyTwoColumnsCanBeSelectedErrorMsg = 'Please Select Only Two Columns!';

// button size in PopOver (small, large, default - for default use undefined)
export const popOverButtonSize = 'small';

// default values
// search max cap limit for log n exception
export const SEARCH_DATA_MAX_LIMIT = 10000;
export const RECENT_SEARCH_MAX_LIMIT = 10;
export const SAVED_SEARCH_MAX_LIMIT = 20;
export const SEARCH_TAB_MAX_LIMIT = 5;
export const RECENT_DATE_TIME_LOCAL_STORAGE_LIMIT = 5;

// milliseconds for search delay between next call
export const SEARCH_RECALL_TIME = 500;

// Multi Column Filter Max Item Limit
export const FILTER_CATEGORY_MAX_LIMIT = 50;

// Replay Status API Call Delay
export const REPLAY_STATUS_DELAY_TIME = 10000;

export const API_SUCCESS_STATUS_CODE = 200;

export const CST_TIMEZONE_LOCATION = 'America/Chicago';
export const IST_TIMEZONE_LOCATION = 'Asia/Kolkata';
export const DETAIL_VIEW_DATA_MAX_LIMIT = 400000;
