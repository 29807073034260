import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import { cloneDeep } from 'lodash';
import type { LoadingBarRef } from 'react-top-loading-bar';
import AddEditConfigDrawer from '../../../Drawer/AddConfigDrawer';
import ExceptionConfigForm from '../../../UpsertForms/Admin/ExceptionConfig';
import ConfigurationListTable from '../../../ConfigurationListTable';
import { deleteExceptionConfig, getExceptionConfigList, saveExceptionConfig, uploadExceptionConfig } from '../../../../../redux/actions/Admin/exceptionConfigActions';
import { exportAPIRequestHandler } from '../../../../../services/logExceptionServiceHandler';
import { errorNotification } from '../../../../common/Notification';
import { checkIfExistsInArray } from '../../../../../helpers/commonHelper';
import textConstants from '../../../../../constants/textConstants';
import apiEndpointConstants from '../../../../../constants/apiEndpointConstants';
import { EXCEPTION_CONFIG_COLUMNS } from '../../../../../constants/TableColumns/Admin/ExceptionConfigColumns';
import type { SelectedRows } from '../../../../../constants/Interfaces/AdminConfigListTypes';
import routeConstants from '../../../../../constants/routeConstants';
import { EXCEPTION_ID } from '../../../../../constants/KeyLabels/commonKeyConstants';

interface ConfigListProps {
  isFromApplicationConfig?: boolean
  applicationName?: string
}

const ExceptionConfigList: FC<ConfigListProps> = ({ applicationName, isFromApplicationConfig }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const exceptionConfigList = useSelector((state: any) => state.exceptionConfig.exceptionConfigList);

  const [isAddEditDrawerOpen, setAddEditDrawerVisibility] = useState(false);
  const [isMultipleModify, setMultipleModifyState] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<SelectedRows>([]);
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);

  const loadingBarRef = useRef<LoadingBarRef>(null);

  const handleItemNavigation = (currentIndex: number): void => {
    setActiveItemIndex(currentIndex);
  }

  const toggleAddEditDrawerVisibility = (isOpen: boolean): void => {
    setAddEditDrawerVisibility(isOpen);
  }

  const onAddEditDrawerClose = (): void => {
    toggleAddEditDrawerVisibility(false);
    setMultipleModifyState(false);
    setActiveItemIndex(0);
  }

  const onCreateBtnClick = (): void => {
    setSelectedRows([]);
    toggleAddEditDrawerVisibility(true);
  }

  const onMultipleModify = (configList: SelectedRows): void => {
    setMultipleModifyState(true);
    setSelectedRows(configList);
    setAddEditDrawerVisibility(true);
  }

  const showDetailPageHandler = (rowData: any, isEditMode: boolean = true): void => {
    history.push({
      pathname: routeConstants.ADMIN_EXCEPTION_CONFIG_DETAIL,
      state: {
        isEditable: isEditMode,
        data: rowData,
        isFromApplicationConfig
      }
    });
  }

  const onExceptionConfigSave = (data: any, isClosable?: boolean): void => {
    if (!isMultipleModify && !checkIfExistsInArray(exceptionConfigList, data, ['applicationName', 'exceptionCategoryName', 'exceptionSeverityName', 'exceptionTypeName'])) {
      dispatch(saveExceptionConfig(data, isClosable ? toggleAddEditDrawerVisibility(false) : null, true));
    } else if (isMultipleModify) {
      dispatch(saveExceptionConfig(data, null, false));
    } else {
      errorNotification(textConstants.EXCEPTION_CONFIG_ALREADY_EXIST_MESSAGE);
    }
  }

  const onExceptionUpload = (blobData: any): void => {
    const data: any = new FormData();
    data.append('fileParts', blobData.file);
    dispatch(uploadExceptionConfig(data));
  }

  const onExportExceptionConfig = (data: any): void => {
    exportAPIRequestHandler(data, apiEndpointConstants.DOWNLOAD_EXCEPTION_CONFIG, false, 'Exception-Config', loadingBarRef)
  }

  useEffect(() => {
    if (isMultipleModify) {
      const updatedConfigId = selectedRows[activeItemIndex][EXCEPTION_ID];

      const updatedConfigData = exceptionConfigList.find((item: any) => item[EXCEPTION_ID] === updatedConfigId);

      const clonedSelectedRows = cloneDeep(selectedRows);
      clonedSelectedRows[activeItemIndex] = updatedConfigData;
      setSelectedRows(clonedSelectedRows);
    }
  }, [exceptionConfigList, isMultipleModify])

  return (
    <>
      <LoadingBar
        ref={loadingBarRef}
        containerClassName="fixed-progress-bar"
        height={3}
        color="#23A0DD"
      />
      <ConfigurationListTable
        columnDefs={EXCEPTION_CONFIG_COLUMNS}
        gridItemId={EXCEPTION_ID}
        gridName={textConstants.EXCEPTION_CONFIG_PAGE_TITLE}
        getConfigList={getExceptionConfigList}
        onConfigDelete={deleteExceptionConfig}
        onImport={onExceptionUpload}
        onExport={onExportExceptionConfig}
        onRowDoubleClicked={(params: { data: any }) => showDetailPageHandler(params.data, false)}
        onEdit={showDetailPageHandler}
        onCreate={onCreateBtnClick}
        onMultipleModify={onMultipleModify}
        rowSelection="multiple"
        listName='exceptionConfig'
        filterDataByApplicationName={applicationName}
        hasFilter={!applicationName}
        hasImport={!applicationName}
        rowMultiSelectWithClick
        hasRowAction
        hasCreate
        hasExport
      />
      {isAddEditDrawerOpen && (
        <AddEditConfigDrawer
          isOpen={isAddEditDrawerOpen}
          dataTestId='exceptionConfigAddDrawer'
          title={`${isMultipleModify ? 'Modify' : 'New'} ${textConstants.EXCEPTION_CONFIG_ADD_EDIT_DRAWER_TITLE}`}
          onCancel={onAddEditDrawerClose}
          handleItemNavigation={handleItemNavigation}
          currentItemIndex={activeItemIndex}
          selectedItemsCount={selectedRows.length - 1}
        >
          <ExceptionConfigForm
            data={{
              applicationName,
              ...(isMultipleModify && { ...selectedRows[activeItemIndex] })
            }}
            onCancel={onAddEditDrawerClose}
            onSave={onExceptionConfigSave}
            isEditable={isMultipleModify}
            isDrawerView
          />
        </AddEditConfigDrawer>
      )}
    </>
  );
};

ExceptionConfigList.defaultProps = {
  isFromApplicationConfig: false
}

ExceptionConfigList.propTypes = {
  applicationName: PropTypes.string,
  isFromApplicationConfig: PropTypes.bool
}

export default ExceptionConfigList;
