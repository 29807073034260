import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import type { FormFieldType } from '../../../../../constants/Interfaces/FormFieldTypes';
import {
  reportConfigFormFields
} from '../../../../../constants/FormFields/AdminForm/reportConfigFormFields';
import {
  LabelValueWrapper,
  LabelWrapper
} from '../../../../../assets/styles/commonStyled';
import { SummaryViewRow } from '../style';
import { getLabelValueFormatter } from '../../../../../helpers/commonHelper';

interface ConfigSummaryProps {
  data?: any
}
const columnSpan = {
  xs: 24,
  md: 8,
  xl: 4,
  xxl: 5
}
const ReportConfigSummary: FC<ConfigSummaryProps> = ({ data }) => {
  return (
    <>
      <SummaryViewRow
        gutter={[16, 8]}
        data-testid="reportConfigSummary"
      >
        {reportConfigFormFields.map((formField: FormFieldType) => {
          return (
            <Col key={formField.id} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
              <LabelWrapper>{formField.label}</LabelWrapper>
              <LabelValueWrapper>{formField?.formatter ? formField.formatter(data?.[formField.name]) : getLabelValueFormatter(data?.[formField.name], formField?.type)}</LabelValueWrapper>
            </Col>
          );
        })}
      </SummaryViewRow>
    </>
  );
};

ReportConfigSummary.propTypes = {
  data: PropTypes.object
};

export default ReportConfigSummary;
