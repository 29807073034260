export const searchByTypeOptions = [
  { label: 'Component', value: 'Component' },
  { label: 'Service', value: 'Service' }
];

export const quickTimePickerOptions = [
  { label: 'Last 1 Hour', value: 1 },
  { label: 'Last 3 Hours', value: 3 },
  { label: 'Last 6 Hours', value: 6 },
  { label: 'Last 12 Hours', value: 12 },
  { label: 'Last 24 Hours', value: 24 },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'yearly' }
];

export const lineBorderColorList = [
  'rgb(42, 159, 67)',
  'rgb(219, 79, 55)',
  'rgb(18, 80, 188)',
  'rgb(171, 10, 186)',
  'rgb(0, 99, 132)',
  'rgb(148, 13, 13)',
  'rgb(128, 128, 128)',
  'rgb(255, 51, 135)',
  'rgb(160,82, 45)',
  'rgb(0, 128, 128)',
  'rgb(50, 115, 220)',
  'rgb(255, 49, 49)',
  'rgb(143, 0, 255)'
];
