import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { ReplayFileCountItemWrapper, ReplayFileCountLabelWrapper, ReplayFileCountValueWrapper, ReplayFileStatusCountBarWrapper } from './style';

interface componentProps {
  data?: any
}

const fileReplayStatusKeyLabel: any = {
  replayId: 'Replay Id',
  exceptionCount: 'No. of Exception',
  overAllStatus: 'Overall Status'
}

const ReplayFileStatusCountBar: FC<componentProps> = ({ data }) => {
  return <ReplayFileStatusCountBarWrapper>
    {
      Object.keys(data).map(keyInObject => {
        return (
          <ReplayFileCountItemWrapper key={keyInObject}>
            <ReplayFileCountLabelWrapper>
              {fileReplayStatusKeyLabel[keyInObject] || 'Label'}
            </ReplayFileCountLabelWrapper>
            <ReplayFileCountValueWrapper
              className={keyInObject.toString().toLowerCase().includes('status') ? String(data[keyInObject]).toLowerCase() + '-btn status-btn' : ''}>
              {data[keyInObject] || 0}
            </ReplayFileCountValueWrapper>
          </ReplayFileCountItemWrapper>
        )
      })
    }
  </ReplayFileStatusCountBarWrapper >;
};

ReplayFileStatusCountBar.propTypes = {
  data: PropTypes.any
};

export default ReplayFileStatusCountBar;
