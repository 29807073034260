import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Select, InputNumber, Button } from 'antd';
import { QuickPickerMainWrapper, QuickSelectHeaderWrapper, QuickSelectContentWrapper, QuickPickerCommonUsedWrapper, CalendarItemHeaderWrapper, CommonUsedItemWrapper, CommonUsedItem, RecentUsedCalendarWrapper, RecentUsedItem } from './style';
import { getRecentTimeInLocalStorage } from '../../../../helpers/localStorageHelper';
import { dateTimeFormat } from '../../../../constants/commonConstants';
import { NoDataTitleWrapper } from '../../../../assets/styles/commonStyled';

interface componentProps {
  closePopOver?: any
  setDateToParent?: any
}

const minsListOptions = [15, 30, 45, 60];
const hoursListOptions = [2, 4, 6, 10, 24, 48];
const daysListOptions = [7, 15, 30, 60, 90];
const quickSelectOptions = ['minute', 'hour', 'day', 'week', 'month', 'year'];

const QuickPickerContent: FC<componentProps> = ({ closePopOver, setDateToParent }) => {
  const [quickSelectTime, setQuickSelectTime] = useState<string | number | null>(5);
  const [quickSelectType, setQuickSelectType] = useState<any>(quickSelectOptions[0]);

  const onQuickSelectApplyHandler = (): void => {
    if (quickSelectTime !== null && typeof quickSelectTime === 'number') {
      const quickSelectTypeTemp: any = `${String(quickSelectType?.toString().charAt(0).toUpperCase())}${String(quickSelectType.slice(1))}`;
      setDateToParent(true, `Last ${quickSelectTime} ${String(quickSelectTypeTemp)}s`, [
        dayjs()
          .subtract(quickSelectTime, quickSelectType)
          .second(0),
        dayjs()
      ]);
      closePopOver();
    }
  };

  const onCommonItemsClickHandler = (selectedType: any, selectedValue: number): void => {
    if (selectedType === 'today') {
      const selectedTypeTemp = `${String(selectedType.toString().charAt(0).toUpperCase())}${String(selectedType
        .slice(1))}`;
      setDateToParent(true, `${selectedTypeTemp}`, [
        dayjs().hour(0).minute(0).second(0),
        dayjs().hour(23).minute(59).second(59)
      ]);
    } else if (selectedType !== 'today') {
      const selectedTypeTemp = `${String(selectedType.toString().charAt(0).toUpperCase())}${String(selectedType
        .slice(1))}`;
      setDateToParent(true, `Last ${selectedValue} ${selectedTypeTemp}s`, [
        dayjs().subtract(selectedValue, selectedType).second(0),
        dayjs()
      ]);
    }
    closePopOver();
  };

  const onRecentUsedDateSelectHandler = (selectedDateString: string): void => {
    setDateToParent(false, '', JSON.parse(selectedDateString));
    closePopOver();
  };

  return (
    <QuickPickerMainWrapper>
      <>
        <QuickSelectHeaderWrapper>Quick Select</QuickSelectHeaderWrapper>
        <QuickSelectContentWrapper>
          <span>Last</span>
          <InputNumber
            min={1}
            max={100}
            value={quickSelectTime}
            size='small'
            onChange={(value: number | string | null) => setQuickSelectTime(value)}
          />
          <Select
            value={quickSelectType}
            onChange={(value) => setQuickSelectType(value)}
            options={quickSelectOptions.map((item: string) => ({
              label: item.slice(0, 1).toUpperCase() + item.slice(1),
              value: item
            }))}
            size='small'
          />
          <Button onClick={onQuickSelectApplyHandler} type="primary" size='small' data-testid="quickselect-apply-btn">
            Apply
          </Button>
        </QuickSelectContentWrapper>
      </>
      <hr />
      <QuickPickerCommonUsedWrapper>
        <CalendarItemHeaderWrapper>Commonly Used</CalendarItemHeaderWrapper>
        <CommonUsedItemWrapper>
          <CommonUsedItem>
            <span data-testid="quickselect-commonly-used-today-btn"
              className="cursor-pointer" onClick={() => onCommonItemsClickHandler('today', 0)}>
              {' '}
              Today{' '}
            </span>
          </CommonUsedItem>
          <>
            {minsListOptions?.map((minsItem: number) => {
              return (
                <CommonUsedItem key={minsItem.toString() + '-mins'}>
                  <span
                    className="cursor-pointer"
                    onClick={() => onCommonItemsClickHandler('minute', minsItem)}
                  >
                    {' '}
                    Last {minsItem} Minutes{' '}
                  </span>
                </CommonUsedItem>
              );
            })}
          </>
          <>
            {hoursListOptions?.map((hourItem: number) => {
              return (
                <CommonUsedItem key={hourItem.toString() + '-hour'}>
                  <span
                    data-testid="quickselect-commonly-used-hour-btn"
                    className="cursor-pointer"
                    onClick={() => onCommonItemsClickHandler('hour', hourItem)}
                  >
                    Last {hourItem} Hours{' '}
                  </span>
                </CommonUsedItem>
              );
            })}
          </>
          <>
            {daysListOptions?.map((dayItem: number) => {
              return (
                <CommonUsedItem key={dayItem.toString() + '-day'}>
                  <span
                    className="cursor-pointer"
                    onClick={() => onCommonItemsClickHandler('day', dayItem)}
                  >
                    Last {dayItem} Days
                  </span>
                </CommonUsedItem>
              );
            })}
          </>
        </CommonUsedItemWrapper>
      </QuickPickerCommonUsedWrapper>
      <hr />
      <RecentUsedCalendarWrapper>
        <CalendarItemHeaderWrapper>Recently Used Dates : </CalendarItemHeaderWrapper>
        <RecentUsedCalendarWrapper>
          {getRecentTimeInLocalStorage()?.length > 0 &&
            getRecentTimeInLocalStorage()?.map((recentDateItem: any, recentDateItemIter: number) => {
              const recentDate = JSON.parse(recentDateItem)
              return (
                <RecentUsedItem
                  data-testid={`quickselect-recently-used-dates-btn-${recentDateItemIter}`}
                  key={recentDateItemIter.toString()}
                  className="cursor-pointer"
                  onClick={() => onRecentUsedDateSelectHandler(recentDateItem)}
                >
                  {dayjs(recentDate[0]).format(dateTimeFormat)}-{dayjs(recentDate[1]).format(dateTimeFormat)}
                </RecentUsedItem>
              );
            })}
          {getRecentTimeInLocalStorage()?.length === 0 &&
            <NoDataTitleWrapper>
              No Recent Dates
            </NoDataTitleWrapper>}
        </RecentUsedCalendarWrapper>
      </RecentUsedCalendarWrapper>
    </QuickPickerMainWrapper>
  );
};

QuickPickerContent.propTypes = {
  closePopOver: PropTypes.func,
  setDateToParent: PropTypes.func
};

export default QuickPickerContent;
