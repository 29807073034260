import React from 'react';
import type { FC } from 'react';
import { useHistory } from 'react-router-dom';
import ConfigurationListTable from '../../../components/AdditionalComponents/ConfigurationListTable';
import { SCHEDULE_CONFIG_COLUMNS } from '../../../constants/TableColumns/Admin/ScheduleConfigColumns';
import { AdminMainLayoutWrapper, AdminMainBreadCrumbWrapper } from '../../../assets/styles/commonStyled';
import { getScheduleConfigList } from '../../../redux/actions/Admin/scheduleConfigActions';
import textConstants from '../../../constants/textConstants';
import routeConstants from '../../../constants/routeConstants';

const ScheduleConfig: FC = () => {
  const history = useHistory();

  return (
    <AdminMainLayoutWrapper data-testid="scheduleConfigList">
      <AdminMainBreadCrumbWrapper>
        {textConstants.SCHEDULE_CONFIG_PAGE_TITLE}
      </AdminMainBreadCrumbWrapper>
      <ConfigurationListTable
        columnDefs={SCHEDULE_CONFIG_COLUMNS}
        getConfigList={getScheduleConfigList}
        gridName={textConstants.SCHEDULE_CONFIG_PAGE_TITLE}
        onRowDoubleClicked={(params: { data: any }) => {
          history.push({
            pathname: routeConstants.ADMIN_SCHEDULE_CONFIG_DETAIL,
            state: {
              isEditable: false,
              data: params?.data
            }
          });
        }}
        listName='scheduleConfig'
      />
    </AdminMainLayoutWrapper>
  );
};

export default ScheduleConfig
