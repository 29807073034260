import { produce } from 'immer';
import { processOperation } from '../../../../helpers/reducerHelper';
import { SET_GROUP_APPLICATION_ACCESS_LIST, SET_GROUP_LIST } from '../../../actions/ActionTypes/AccessManagement/GroupAccess/groupAccessTypes';

export interface groupConfigListState {
  groupConfigList: any[]
  groupApplicationAccessList: any[]
}

const initialState = {
  groupConfigList: [],
  groupApplicationAccessList: []
};

const groupAccessReducer = (state = initialState, action: any): groupConfigListState =>
  produce(state, (draft: groupConfigListState) => {
    switch (action.type) {
      case SET_GROUP_LIST:
        draft.groupConfigList = processOperation(action.data, [...draft.groupConfigList]);
        break;
      case SET_GROUP_APPLICATION_ACCESS_LIST:
        draft.groupApplicationAccessList = processOperation(action.data, [...draft.groupApplicationAccessList]);
        break;
    }
  });

export default groupAccessReducer;
