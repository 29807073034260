import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import UserAccessDetail from '../../../../pages/AccessManagement/UserAccess/UserAccessDetail';
import ConfigDrawer from '../AddConfigDrawer';
import textConstants from '../../../../constants/textConstants';

interface UserProfileDrawerProps {
  data?: any
  isOpen: boolean
  onClose: any
}

const UserProfileDrawer: FC<UserProfileDrawerProps> = ({ data, isOpen, onClose }) => {
  return (
    <ConfigDrawer
      isOpen={isOpen}
      dataTestId='userProfileDrawer'
      title={textConstants.USER_PROFIL_DETAILS_DRAWER_TITLE}
      onCancel={onClose}
      className='summary-drawer'
    >
      <UserAccessDetail userData={data} isProfileView />
    </ConfigDrawer>
  );
};

UserProfileDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.any,
  onClose: PropTypes.any.isRequired
};

export default UserProfileDrawer;
