import { produce } from 'immer';
import { processOperation } from '../../../helpers/reducerHelper';

export interface savedSearchCriteriaTemplate {
  savedSearchDataList: any[]
}

const initialState = {
  savedSearchDataList: []
};

const SavedSearchReducer = (state = initialState, action: any): savedSearchCriteriaTemplate =>
  produce(state, (draft: savedSearchCriteriaTemplate) => {
    switch (action.type) {
      case 'STORE_SAVED_SEARCH_LIST':
        draft.savedSearchDataList = processOperation(action.payload, state.savedSearchDataList);
        break;
      default:
        return state;
    }
  });

export default SavedSearchReducer;
