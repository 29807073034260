import styled from 'styled-components';
import { Row } from 'antd';

export const ConfigDetailMainWrapper = styled.div<{ hasBottomGap: boolean | undefined }>`
  flex: 1;
  overflow-y: auto;
  padding-bottom: ${props => props.hasBottomGap ? '40px' : '0'};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const ConfigDetailWrapper = styled.div<{ hasRightGap?: boolean | undefined }>`
  background: var(--white);
  margin: 10px;
  padding: 15px 20px 20px;
  padding-right: ${props => props.hasRightGap ? '40px' : '20px'};
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 2px var(--black-400);
  
  .ant-form-item-control-input {
    .react-dropdown-select {
      min-height: 40px;
      border-color: var(--form-field-border-color);

      @media screen and (min-width: 1920px) {
        min-height: 48px;
      }

      &[disabled] {
        opacity: 1;
        background-color: var(--gray-100);
      }

      &-content {
        padding-top: 5px;

        span > span {
          max-width: 73px;
        }
      }

      input {
        height: 100%;
      }

      .react-dropdown-select-dropdown-handle svg {
        width: 15px;
        height: 15px;
        color: var(--blue-color);
      }
    }
  }

  .ant-form-item {
    margin-bottom: 15px;

    &.mb-0 {
      margin-bottom: 0;
    }
  
    &.mb-5 {
      margin-bottom: 5px;
    }

    &-label {
      padding-bottom: 0;
    }
    
    label {
      font-size: var(--font-size-sm);
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      line-height: 40px;
    }
  }
`;

export const SummaryViewRow = styled(Row)`
  &.criteria-separator-wrapper, &.criteria-separator-wrapper-without-border {
    padding: 15px 10px 5px;

    @media screen and (min-width: 1600px) {
      padding: 30px 10px 10px;
    }
  }

  &.criteria-separator-wrapper {
    @media screen and (min-width: 1600px) {
      margin-top: 20px;
      margin-bottom: 35px;
    }
  }

  label {
    margin-bottom: 5px;
  }

  .ant-col > div {
    margin-bottom: 10px;
  }
`
