import styled, { keyframes } from 'styled-components';
import { Modal, Button } from 'antd';

const shakeAnimation = keyframes`
    0% { transform: rotate(0deg); }
    25% { transform: rotate(5deg); }
    50% { transform: rotate(0eg); }
    75% { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
`;

export const PageTitleWrapper = styled.div<({ isException: boolean })>`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  background: ${props => props.isException ? 'var(--yellow-500)' : 'var(--blue-color-600)'};
  color: var(--blue-color-700);
  padding: 12px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-regular);
    margin: 0;
  }
`;

export const MainContentWrapper = styled.div`
  // min-height: calc(100vh - 64px);
  position:relative;
  z-index: 1;
  .ant-tabs-nav {
    margin-bottom: 0;
    z-index: 1;
    margin-top: 5px;
  }

  .ant-tabs .ant-tabs-content {
    padding: 0;
    z-index: 1;
  }
  
  &.fullscreen-mode{
    position: absolute;
    inset: 0px;
  }
`;

export const FullScreenModal = styled(Modal)`
  &.ant-modal{
    height:100vh;
    width: 100vw !important;
    top: 0px;
    max-width:100vw;
  }
  .ant-modal-content{
    border-radius:0px;
    padding:0px;
    height: 100vh;
    width: 100vw;
    background: var(--main-content-wrapper-bg);
  }
`;

export const DefaultSmallButton = styled(Button)`
  margin-top: -3px;
  background: var(--blue-color);
  z-index: 100;
  height: 20px;
  width: 20px;
  color: var(--white);
  border: 0px;
  color: var(--white) !important;

  &.ant-btn.ant-btn-sm {
    border-radius: 50%;
    height: 20px;
    min-height: 20px;
    padding: 0;
    font-size: var(--font-size-xs);

    &:not(:disabled):not(.ant-btn-disabled) {
      &:hover,
      &:focus {
        background: var(--blue-color);
      }
    }
  }
`;

export const FullScreenButton = styled.div`
  margin-left: 7px;
  display:inline-block;
  animation-name: ${shakeAnimation};
  animation-duration: 0.35s;
  animation-iteration-count: 5;
  animation-timing-function: ease-in-out;
`;
