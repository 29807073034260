import { call, put, fork, all, takeLatest, select } from 'redux-saga/effects';
import type { AllEffect, CallEffect, ForkEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { createLogger } from 'redux-logger';
import { updateDataLoadingStatus } from '../../actions/CommonConfig/commonConfigActions';
import { applicationDataStore } from '../../actions/Admin/applicationConfigActions';
import {
  DELETE_APPLICATION_DETAILS,
  GET_APPLICATION_DETAILS,
  SAVE_APPLICATION_DETAILS
} from '../../actions/ActionTypes/Admin/applicationConfigTypes';
import { apiRequest } from '../../../services/axiosInterceptor';
import apiURL from '../../../constants/apiEndpointConstants';
import { API_SUCCESS_STATUS_CODE } from '../../../constants/commonConstants';
import { APPLICATION_NAME_ID } from '../../../constants/KeyLabels/commonKeyConstants';
import textConstants from '../../../constants/textConstants';
import { successNotification } from '../../../components/common/Notification';
import { getDataFromSessionStorage, ignoreInvaildValueFromArray } from '../../../helpers/commonHelper';
import { processOperation } from '../../../helpers/reducerHelper';

const callEffect: any = call;

const updateApplicationConfigInSessionStorage = (payload: any): void => {
  const appConfigs = getDataFromSessionStorage('applicationDetails');
  const updatedAppConfigs = processOperation(payload, [...appConfigs]);
  sessionStorage.setItem('applicationDetails', JSON.stringify(updatedAppConfigs));
}
export const userAccessInfo = (state: any): any => state.userAccess?.userAccessInfo;

export function * fetchApplicationDetails (): Generator<CallEffect<any> | PutEffect<any> | SelectEffect, void> {
  try {
    const userAccess: any = yield select(userAccessInfo);
    const config = {
      method: 'get',
      url: userAccess.adminAccess === 'Y' ? apiURL.GET_APPLICATION_CONFIG_DETAILS : apiURL.GET_NON_ADMIN_APPLICATION_CONFIG_DETAILS
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    const payload = {
      data: response?.data,
      action: 'NEW'
    };

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      sessionStorage.setItem('applicationDetails', JSON.stringify(ignoreInvaildValueFromArray(response.data)));
      yield put(applicationDataStore(payload));
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    yield put(updateDataLoadingStatus(-1));
    createLogger(err);
  }
}
export function * getApplicationConfig (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_APPLICATION_DETAILS, fetchApplicationDetails);
}

export function * saveApplicationDetail (
  payload: any
): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const apiConfig = {
      method: payload?.isAdd ? 'POST' : 'PUT',
      url: apiURL.GET_APPLICATION_CONFIG_DETAILS,
      data: payload.data
    };
    yield put(updateDataLoadingStatus(1));
    const apiResponse: any = yield callEffect(apiRequest, apiConfig);
    if (apiResponse?.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        action: payload?.isAdd ? 'ADD' : 'UPDATE',
        data: apiResponse?.data,
        ...(!payload?.isAdd && {
          keyName: APPLICATION_NAME_ID,
          keyValue: apiResponse.data[APPLICATION_NAME_ID]
        })
      };
      yield put(applicationDataStore(storePayload));

      if (payload?.callBackFunc) {
        payload.callBackFunc();
      }

      updateApplicationConfigInSessionStorage(storePayload);
      successNotification(`Application Configuration ${payload?.isAdd ? 'Added' : 'Updated'} Successfully`);
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    yield put(updateDataLoadingStatus(-1));
    createLogger(err);
  }
}

export function * deleteApplicationDetail (
  payload: any
): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: 'DELETE',
      url: apiURL.GET_APPLICATION_CONFIG_DETAILS,
      data: payload.data
    };

    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        action: 'DELETE',
        keyName: APPLICATION_NAME_ID,
        keyValue: response.data[0][APPLICATION_NAME_ID]
      };
      yield put(applicationDataStore(storePayload));

      updateApplicationConfigInSessionStorage(storePayload);
      successNotification(textConstants.APPLICATION_DELETE_SUCCESS_MESSAGE);
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * deleteApplicationConfig (): Generator<ForkEffect<never>> {
  yield takeLatest(DELETE_APPLICATION_DETAILS, deleteApplicationDetail);
}

export function * saveApplicationConfig (): Generator<ForkEffect<never>> {
  yield takeLatest(SAVE_APPLICATION_DETAILS, saveApplicationDetail);
}

export default function * root (): Generator<AllEffect<any>> {
  yield all([
    fork(getApplicationConfig),
    fork(saveApplicationConfig),
    fork(deleteApplicationConfig)
  ]);
}
