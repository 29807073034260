import { convertTimeToTimeZoneForDisplay } from '../../helpers/commonHelper';
import {
  APPLICATION_DOMAIN_KEY,
  APPLICATION_NAME_KEY,
  BUSINESS_ID_KEY,
  BUSINESS_ID_TWO_KEY,
  COMPONENT_NAME_KEY,
  HOST_NAME_KEY,
  LOG_LEVEL_KEY,
  SERVICE_NAME_KEY,
  STATUS_KEY,
  TIMEMARK_KEY,
  TIME_DURATION_KEY,
  TRANSACTION_DOMAIN_KEY,
  TRANSACTION_ID_KEY,
  TRANSACTION_TYPE_KEY
} from '../KeyLabels/commonKeyConstants';
import {
  APPLICATION_DOMAIN_LABEL,
  APPLICATION_NAME_LABEL,
  BUSINESS_ID_LABEL,
  BUSINESS_ID_TWO_LABEL,
  COMPONENT_NAME_LABEL,
  HOST_NAME_LABEL,
  LOG_LEVEL_LABEL,
  SERVICE_NAME_LABEL,
  STATUS_LABEL,
  TIME_DURATION_LABEL,
  TIME_OCCURED_LABEL,
  TRANSACTION_DOMAIN_LABEL,
  TRANSACTION_ID_LABEL,
  TRANSACTION_TYPE_LABEL
} from '../KeyLabels/labelConstants';
import LogLevelIconRenderer from './customRendererForColumns/LogLevelIconRenderer';
import { checkBoxFieldCol } from './tableCheckboxColumn';

const additionalCommonFieldProperties = {
  minWidth: 170,
  filter: true,
  sortable: true,
  suppressMovable: true
};

export const LogSearchResultColumns: any[] = [
  checkBoxFieldCol,
  {
    field: LOG_LEVEL_KEY,
    headerName: '',
    ...additionalCommonFieldProperties,
    minWidth: 50,
    maxWidth: 70,
    cellRenderer: LogLevelIconRenderer,
    isVisible: true
  },
  {
    field: APPLICATION_NAME_KEY,
    headerName: APPLICATION_NAME_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: COMPONENT_NAME_KEY,
    headerName: COMPONENT_NAME_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: SERVICE_NAME_KEY,
    headerName: SERVICE_NAME_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: STATUS_KEY,
    headerName: STATUS_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: TIMEMARK_KEY,
    headerName: TIME_OCCURED_LABEL,
    isVisible: true,
    cellRenderer: (params: any) => {
      return convertTimeToTimeZoneForDisplay(params?.value);
    },
    ...additionalCommonFieldProperties
  },
  {
    field: HOST_NAME_KEY,
    headerName: HOST_NAME_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: BUSINESS_ID_KEY,
    headerName: BUSINESS_ID_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: BUSINESS_ID_TWO_KEY,
    headerName: BUSINESS_ID_TWO_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: TRANSACTION_ID_KEY,
    headerName: TRANSACTION_ID_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: TIME_DURATION_KEY,
    headerName: TIME_DURATION_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: APPLICATION_DOMAIN_KEY,
    headerName: APPLICATION_DOMAIN_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: TRANSACTION_DOMAIN_KEY,
    headerName: TRANSACTION_DOMAIN_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: TRANSACTION_TYPE_KEY,
    headerName: TRANSACTION_TYPE_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  },
  {
    field: LOG_LEVEL_KEY,
    headerName: LOG_LEVEL_LABEL,
    isVisible: true,
    ...additionalCommonFieldProperties
  }
];
