import { convertTimeToTimeZoneForDisplay } from '../../../helpers/commonHelper';

export const tableConfigFormLabels = {
  dataType: 'Table Type',
  tableName: 'Table Name',
  topicName: 'Topic Name',
  sector: 'Sector',
  retentionDays: 'Retention Days',
  recordCount: 'Record Count',
  solrCoreSize: 'Solr Core Size',
  spaceOccupied: 'Space Occupied',
  tableSize: 'Table Size',
  lastUpdated: 'Last Updated'
}

export const tableConfigFormFields = [
  {
    label: tableConfigFormLabels.dataType,
    name: 'dataType',
    id: 'dataType',
    type: 'select',
    required: true
  },
  {
    label: tableConfigFormLabels.tableName,
    name: 'tableName',
    id: 'tableName',
    type: 'text',
    required: true
  },
  {
    label: tableConfigFormLabels.topicName,
    name: 'topicName',
    id: 'topicName',
    type: 'text',
    required: true
  },
  {
    label: tableConfigFormLabels.sector,
    name: 'sector',
    id: 'sector',
    type: 'select',
    required: true
  },
  {
    label: tableConfigFormLabels.retentionDays,
    name: 'retentionDays',
    id: 'retentionDays',
    type: 'number'
  },
  {
    label: tableConfigFormLabels.recordCount,
    name: 'recordCount',
    id: 'recordCount',
    type: 'text',
    disableOnEdit: true
  },
  {
    label: tableConfigFormLabels.solrCoreSize,
    name: 'solrCoreSize',
    id: 'solrCoreSize',
    type: 'text',
    disableOnEdit: true
  },
  {
    label: tableConfigFormLabels.tableSize,
    name: 'tableSize',
    id: 'tableSize',
    type: 'text',
    disableOnEdit: true
  },
  {
    label: tableConfigFormLabels.spaceOccupied,
    name: 'spaceOccupied',
    id: 'spaceOccupied',
    type: 'text',
    disableOnEdit: true
  },
  {
    label: tableConfigFormLabels.lastUpdated,
    name: 'lastUpdated',
    id: 'lastUpdated',
    type: 'text',
    disableOnEdit: true,
    formatter: convertTimeToTimeZoneForDisplay
  }
];
