import { call, put, fork, all, takeLatest } from 'redux-saga/effects';
import type { AllEffect, CallEffect, ForkEffect, PutEffect } from 'redux-saga/effects';
import { createLogger } from 'redux-logger';
import { updateDataLoadingStatus } from '../../actions/CommonConfig/commonConfigActions';
import { setColumnAlertConfigList } from '../../actions/Admin/columnAlertConfigActions';
import {
  DELETE_COLUMN_ALERT_CONFIG,
  GET_COLUMN_ALERT_CONFIG_LIST,
  SAVE_COLUMN_ALERT_CONFIG
} from '../../actions/ActionTypes/Admin/columnAlertConfigTypes';
import { API_SUCCESS_STATUS_CODE } from '../../../constants/commonConstants';
import { COLUMN_ALERT_ID } from '../../../constants/KeyLabels/commonKeyConstants';
import { successNotification } from '../../../components/common/Notification';
import { apiRequest } from '../../../services/axiosInterceptor';
import apiURL from '../../../constants/apiEndpointConstants';
import textConstants from '../../../constants/textConstants';

const callEffect: any = call;

export function * fetchColumnAlertConfigListSaga (): Generator<
CallEffect<any> | PutEffect<any>,
void
> {
  try {
    const config = {
      method: 'get',
      url: apiURL.GET_COLUMN_ALERT_CONFIG_LIST
    };
    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    const payload = {
      data: response?.data,
      action: 'NEW'
    };

    if (response.status === API_SUCCESS_STATUS_CODE) {
      yield put(setColumnAlertConfigList(payload));
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * deleteColumnAlertConfigSaga (payload: any): any {
  try {
    const config = {
      method: 'DELETE',
      url: apiURL.COLUMN_ALERT_CONFIG,
      data: payload.data
    };

    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        action: 'DELETE',
        keyName: COLUMN_ALERT_ID,
        keyValue: response.data[0][COLUMN_ALERT_ID]
      };
      yield put(setColumnAlertConfigList(storePayload));
      if (payload.callBackFunc) {
        payload.callBackFunc();
      }
      successNotification(textConstants.COLUMN_ALERT_CONFIG_DELETE_SUCCESS_MESSAGE);
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * saveColumnAlertConfigSaga (payload: any): any {
  try {
    const config = {
      method: payload?.isAdd ? 'POST' : 'PUT',
      url: apiURL.COLUMN_ALERT_CONFIG,
      data: payload.data
    };

    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response.status === API_SUCCESS_STATUS_CODE) {
      const storePayload = {
        action: payload?.isAdd ? 'ADD' : 'UPDATE',
        data: response?.data,
        ...(!payload?.isAdd && {
          keyName: COLUMN_ALERT_ID,
          keyValue: response.data[COLUMN_ALERT_ID]
        })
      };
      yield put(setColumnAlertConfigList(storePayload));
      successNotification(`Column Alert configuration ${payload?.isAdd ? 'added' : 'updated'} successfully`);
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    createLogger(err);
    yield put(updateDataLoadingStatus(-1));
  }
}

export function * saveColumnAlertConfig (): Generator<ForkEffect<never>> {
  yield takeLatest(SAVE_COLUMN_ALERT_CONFIG, saveColumnAlertConfigSaga);
}

export function * getColumnAlertConfigList (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_COLUMN_ALERT_CONFIG_LIST, fetchColumnAlertConfigListSaga);
}

export function * deleteColumnAlertConfigItem (): Generator<any> {
  yield takeLatest(DELETE_COLUMN_ALERT_CONFIG, deleteColumnAlertConfigSaga);
}

export default function * root (): Generator<AllEffect<any>> {
  yield all([
    fork(getColumnAlertConfigList),
    fork(deleteColumnAlertConfigItem),
    fork(saveColumnAlertConfig)
  ]);
}
