import styled, { css } from 'styled-components';

export const basicInfoTextStyle = css`
  font-size: var(--font-size-sm);
`;

export const BasicInfoLabel = styled.span`
  ${basicInfoTextStyle}
  color: var(--gray-800);
  font-weight: var(--font-weight-slight-medium);
`;

export const BasicInfoValue = styled.span`
  ${basicInfoTextStyle}

  &.status-grid-btn {
    display: inline-block;
    height: auto;
    position: relative;
    line-height: 1;
    border-radius: 4px;
    color: var(--white);
    padding: 6px;
    position: relative;
    top: -1px;
    text-transform: capitalize;
  }
`;

export const TabContentMaxHeightWrapper = styled.div`
  // min-height: 25vh;
  // max-height: 30vh;
  max-width: calc(80vw - 10px);
  word-break: break-word;
  overflow: auto;
  margin: 5px;
`;
