import React, { useState } from 'react';
import type { FC } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import textConstants from '../../../../constants/textConstants';
import { saveReportConfig, deleteReportConfig } from '../../../../redux/actions/Admin/reportConfigActions';
import ConfigDetailCardHeader from '../../../../components/AdditionalComponents/ConfigDetailCardHeader';
import ReportConfigForm from '../../../../components/AdditionalComponents/UpsertForms/Admin/ReportConfig';
import ReportConfigSummary from '../../../../components/AdditionalComponents/DetailSummaryPage/Admin/ReportConfig/ReportConfigSummary';
import { AdminMainLayoutWrapper } from '../../../../assets/styles/commonStyled';
import { ConfigDetailMainWrapper, ConfigDetailWrapper } from '../../../../components/AdditionalComponents/DetailSummaryPage/Admin/style';
import { REPORT_CONFIG_TAB_KEY } from '../../../../constants/KeyLabels/commonKeyConstants';
import routeConstants from '../../../../constants/routeConstants';
import AdminDetailBreadCrumb from '../../../../components/common/BreadCrumb/AdminDetailBreadCrumb';

const ReportConfigDetail: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { data, isEditable } = location?.state as { isEditable?: boolean, data: any } ?? {};

  const [isConfigDetailInEditMode, setConfigDetailInEditMode] = useState(isEditable);
  const [configData, setConfigData] = useState(data);

  const onFormSaveCancelHandler = (): void => {
    if (isEditable) {
      history.push({
        pathname: routeConstants.ADMIN_REPORT_CONFIG,
        state: {
          activeTab: REPORT_CONFIG_TAB_KEY
        }
      })
    } else {
      setConfigDetailInEditMode(false);
    }
  }

  const onReportConfigSaveHandler = (data: any): void => {
    dispatch(
      saveReportConfig(
        data,
        onFormSaveCancelHandler
      )
    );
    setConfigData(data);
  }

  const onReportConfigDeleteHandler = (): void => {
    dispatch(deleteReportConfig([data], history));
  }

  return (
    <AdminMainLayoutWrapper data-testid="reportConfigDetail">
      <AdminDetailBreadCrumb
        mainPageText={textConstants.REPORT_CONFIG_PAGE_TITLE}
        currentPageText={data?.applicationName}
        onBackCallback={() => history.push({
          pathname: routeConstants.ADMIN_REPORT_CONFIG,
          state: {
            activeTab: REPORT_CONFIG_TAB_KEY
          }
        })}
      />
      <ConfigDetailMainWrapper hasBottomGap={isConfigDetailInEditMode}>
        <ConfigDetailWrapper>
          <ConfigDetailCardHeader
            onDelete={onReportConfigDeleteHandler}
            onEdit={() => setConfigDetailInEditMode(true)}
            isEditEnabled={!isConfigDetailInEditMode}
            title={textConstants.REPORT_CONFIG_DETAIL_PAGE_TITLE}
            configName={textConstants.REPORT_CONFIG_GRID_NAME}
          />
          {isConfigDetailInEditMode && (
            <ReportConfigForm
              data={configData}
              onSave={onReportConfigSaveHandler}
              onCancel={onFormSaveCancelHandler}
            />
          )}
          {!isConfigDetailInEditMode && (
            <ReportConfigSummary
              data={configData}
            />
          )}
        </ConfigDetailWrapper>
      </ConfigDetailMainWrapper>
    </AdminMainLayoutWrapper>
  );
};

export default ReportConfigDetail;
