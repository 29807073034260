import _ from 'lodash';
import { exceptionAlreadyReplayedStatus } from '../constants/DropDownOptions/logExceptionSearch';
import textConstants from '../constants/textConstants';
import { APPLICATION_NAME_ID } from '../constants/KeyLabels/commonKeyConstants';
import { type ApplicationAccessType, type GroupApplicationAccessType } from '../constants/Interfaces/GroupAccessTypes';
import { checkIfExistsInArray } from './commonHelper';

export const applicationDataHelper = {
  getApplicationNames: function (applicationList: any[]) {
    let applicationNames: any[] = [];
    const applicationData =
            applicationList && applicationList.length > 0
              ? applicationList
              : JSON.parse(sessionStorage.getItem('applicationDetails') ?? '[]') || [];
    applicationData.length > 0 &&
            applicationData.forEach((app: any) => {
              applicationNames.push({
                label: app.applicationName,
                value: app.applicationName,
                logTable: app.logTable,
                exceptionTable: app.exceptionTable,
                applicationName: app.applicationName
              });
            });
    applicationNames = _.uniqBy(_.orderBy(applicationNames, 'label', 'asc'), 'label');

    return applicationNames;
  },
  getApplicationNamesForUser: function (userAccessData: any, applicationList: any[]) {
    let applicationNames = [];
    const applicationData =
            applicationList && applicationList.length > 0
              ? applicationList
              : JSON.parse(sessionStorage.getItem('applicationDetails') ?? '[]') || [];
    const applicationAccess = userAccessData?.roleAccess;
    for (const key in applicationAccess) {
      for (let j = 0; j < applicationData.length; j++) {
        if (key === applicationData[j].applicationName) {
          applicationNames.push({
            label: applicationData[j].applicationName,
            value: applicationData[j].applicationName,
            logTable: applicationData[j].logTable,
            exceptionTable: applicationData[j].exceptionTable
          });
        }
      }
    }
    applicationNames = _.orderBy(applicationNames, 'label', 'asc');
    return applicationNames;
  },

  getTableNameByApplication: function (applicationName: string, metricType: string) {
    const applicationData =
      JSON.parse(sessionStorage.getItem('applicationDetails') ?? '[]') || [];
    const applicationInfo = applicationData.find((item: Record<string, any>) => item.applicationName === applicationName);
    return applicationInfo
      ? (metricType.toLowerCase() === 'log'
          ? applicationInfo?.logTable
          : applicationInfo?.exceptionTable)
      : '';
  },
  getDataFromApplicationList: function (applications: any | string, fieldName: string): any[] {
    let applicationNameList: string | any[] = [];
    if (typeof applications === 'string') {
      applicationNameList = [applications];
    } else {
      applicationNameList = applications?.map((item: any) => item.value) || [];
    }

    let sessionStorageKey = '';
    if (fieldName === 'exceptionSeverityName') {
      sessionStorageKey = 'applicationSeverityData';
    } else if (fieldName === 'exceptionCategoryName') {
      sessionStorageKey = 'applicationCategoryData';
    } else if (fieldName === 'exceptionTypeName') {
      sessionStorageKey = 'applicationTypeData';
    }
    const sessionStorageData = JSON.parse(sessionStorage.getItem(sessionStorageKey) ?? '[]');
    let sessionStorageDataFilteredList = sessionStorageData.filter((item: any) =>
      applicationNameList.includes(item.applicationName)
    );
    if (fieldName) {
      sessionStorageDataFilteredList = sessionStorageDataFilteredList.map((item: any) => ({
        ...item,
        label: item[fieldName],
        value: item[fieldName]
      }));
    }
    sessionStorageDataFilteredList = _.uniqBy(sessionStorageDataFilteredList, fieldName);
    return _.orderBy(sessionStorageDataFilteredList, fieldName, 'asc');
  },
  getRequestPayload: (
    data: any,
    searchRequest: any,
    searchType: string,
    userId: string
  ): void => {
    return data.map((val: any) => {
      const obj: any = {
        application: val.application_nm,
        timemark: val.timemark,
        user: userId,
        status: val.status,
        ...(searchType === 'Log' && { logID: val.log_id }),
        ...(searchType !== 'Log' && { exceptionID: val.exception_id })
      };

      if (searchRequest?.appName?.length > 0) {
        const appDetail = searchRequest.appName.find(
          (item: any) => item.value === val.application_nm
        );
        if (appDetail) {
          obj.table =
                        searchType === 'Log' ? appDetail.logTable : appDetail.exceptionTable;
        }
      }

      return obj;
    });
  },
  validateUserAccess: (appName: string, userAccessInfo: any, accessType: string) => {
    // console.log('appNAme', appName, userAccessInfo)
    if (userAccessInfo.adminAccess === 'Y') {
      return true;
    }

    if (appName) {
      if (
        accessType === 'replay' &&
                userAccessInfo.roleAccess[appName] &&
                userAccessInfo.roleAccess[appName].includes('replay')
      ) {
        return true;
      }
    }

    return false;
  },
  validateSelectedItemsReplayAccess: (selectedItems: any, userAccessInfo: any): boolean => {
    let isValidReplay = true;

    if (userAccessInfo.adminAccess === 'Y') {
      return true;
    }

    const applicationList = _.uniq(selectedItems.map((item: any) => item.application_nm));
    const appWithReplayAccess = applicationList.filter((item: any) =>
      userAccessInfo.roleAccess[item]?.includes('replay')
    );

    if (appWithReplayAccess.length < 1) {
      isValidReplay = false;
    }

    return isValidReplay;
  },
  checkIsValidReplay: (selectedItems: any, userAccessInfo: any) => {
    let replayAccessMsg = '';
    const applicationList = _.uniq(selectedItems.map((item: any) => item.application_nm));
    const appAlreadyReplayedList = _.uniq(
      selectedItems
        .filter((item: any) =>
          exceptionAlreadyReplayedStatus.includes(item.exception_status)
        )
        .map((item: any) => item.application_nm)
    );
    const appWithoutReplayAccess = applicationList.filter(
      (item: any) => !userAccessInfo.roleAccess[item]?.includes('replay')
    );

    if (userAccessInfo.adminAccess !== 'Y' && appWithoutReplayAccess.length > 0) {
      replayAccessMsg = `${
                textConstants.REPLAY_ACCESS_NOT_ALLOWED
            }: ${appWithoutReplayAccess.join(', ')}`;
    } else if (appAlreadyReplayedList.length > 0) {
      replayAccessMsg = textConstants.REPLAY_ALREADY_COMPLETED;
    }

    return replayAccessMsg;
  },
  filterOutSelectedApplication: (applicationList: any[], applicationNamesToIgnore: any[]) => {
    return applicationList?.filter(applicationElement => !applicationNamesToIgnore?.some(ignoreElement => ignoreElement?.applicationName === applicationElement?.applicationName))
  }
};

export const filterListByApplicationName = (
  originalArray: any[],
  applicationName: string
): any[] => {
  if (applicationName) {
    return originalArray?.filter(
      (objectInArray) =>
        objectInArray?.[APPLICATION_NAME_ID]?.toString().toLowerCase() ===
                applicationName.toLowerCase()
    );
  }
  return originalArray;
};

export const getUpdatedApplicationAccessConfig = (initialList: any[], currentList: any[], key: string, key2: string, groupName?: string, applicationName?: string): GroupApplicationAccessType => {
  const added: ApplicationAccessType[] = [];
  const deleted: ApplicationAccessType[] = [];
  const updated: ApplicationAccessType[] = [];

  initialList.forEach((initialConfig) => {
    const newObjectFound = currentList.find((currentConfig) => currentConfig[key] === initialConfig[key]);
    if (newObjectFound) {
      if (!_.isEqual(initialConfig, newObjectFound)) {
        updated.push(newObjectFound);
      }
    } else {
      deleted.push(initialConfig);
    }
  });

  currentList.forEach((currentConfig) => {
    const ifNotExists = !initialList.some((initialConfig) => {
      return currentConfig[key] === initialConfig[key] && currentConfig[key2] === initialConfig[key2];
    });
    if (ifNotExists && !checkIfExistsInArray(updated, currentConfig, [key, key2])) {
      added.push({
        applicationName: applicationName ?? currentConfig?.applicationName,
        accessName: currentConfig?.accessName,
        groupName: groupName || currentConfig?.groupName
      });
    }
  });
  return { added, updated, deleted };
};
