import styled from 'styled-components';
import { Avatar } from 'antd';

export const UserSettingsMenuWrapper = styled.div`
    padding: 10px 0;
    .timezone-toggle-wrapper {
        & > span {
            padding-right: 9px;
            color: var(--white-100);

            @media screen and (max-width: 450px) {
               display: none;
            }
        }

        .anticon {
            font-size: var(--font-size-xl);
            position: relative;
            top: 2px;
            margin-right: 3px;
        }
    }
`

export const UserAvatar = styled(Avatar)`
    border-radius: 50%;
    border: 2px solid var(--border-blue-color);
    width: 38px;
    height: 38px;

    @media screen and (max-width: 991px) {
        width: 34px;
        height: 34px;
    }
`
