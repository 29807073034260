import { call, fork, all, takeLatest, delay, put } from 'redux-saga/effects';
import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import {
  CANCEL_SEARCH_APICALL,
  FETCH_SEARCH_DATA
} from '../../actions/ActionTypes/LogSearch/logSearchTypes';
import { apiRequest } from '../../../services/axiosInterceptor';
import apiEndpointConstants from '../../../constants/apiEndpointConstants';
import {
  API_SUCCESS_STATUS_CODE,
  SEARCH_DATA_MAX_LIMIT,
  SEARCH_RECALL_TIME
} from '../../../constants/commonConstants';
import { PAGE_ID_KEY } from '../../../constants/KeyLabels/commonKeyConstants';
import { resetLogSearchData, storeLogSearchData } from '../../actions/LogSearch/logSearchActions';
import axios, { type CancelTokenSource } from 'axios';
import {
  resetExceptionSearchData,
  storeExceptionSearchData
} from '../../actions/ExceptionSearch/exceptionSearchActions';

const callEffect: any = call;
let searchCancelToken: CancelTokenSource;

export function * fetchSearchDataSagaFn (action: any): Generator<any> {
  try {
    if (action?.payload?.logOrExceptionType?.toLowerCase() === 'log') {
      yield put(resetLogSearchData(action?.payload?.searchedTabKey, JSON.stringify(action.payload.searchQuery)));
    } else {
      yield put(resetExceptionSearchData(action?.payload?.searchedTabKey, JSON.stringify(action.payload.searchQuery)));
    }
  } catch (e) {
    console.log(e);
  }
  try {
    searchCancelToken = axios.CancelToken.source();
    const { payload } = action;

    let payloadList: any = payload.searchQuery.filter((payloadItem: any) => payloadItem.recordCount !== 0);

    const apiCallUrl =
            action?.payload?.logOrExceptionType?.toLowerCase() === 'log'
              ? apiEndpointConstants.GET_LOG_SEARCH_DATA
              : apiEndpointConstants.GET_EXCEPTION_SEARCH_DATA;
    const allRecords: any = [];
    const totalRecordsCount =
            action.payload.recordCount <= SEARCH_DATA_MAX_LIMIT
              ? action.payload.recordCount
              : SEARCH_DATA_MAX_LIMIT;

    while (allRecords.length < totalRecordsCount) {
      payloadList = payloadList.filter((payloadItem: any) => payloadItem[PAGE_ID_KEY] !== null)

      const requestsList = payloadList
        .map((payloadItem: any) => {
          return callEffect(apiRequest, {
            method: 'POST',
            data: {
              ...payloadItem,
              [PAGE_ID_KEY]:
                                payloadItem[PAGE_ID_KEY] === 1
                                  ? undefined
                                  : payloadItem[PAGE_ID_KEY]
            },
            url: apiCallUrl,
            cancelToken: searchCancelToken.token
          });
        });

      const responseList: any = yield all(requestsList);
      const tempSearchResponseList: any[] = [];
      // let isSearchScheduled: boolean = true;
      let hasNextPage: boolean = false;
      responseList.forEach((responseItem: any, responseIter: number) => {
        if (responseItem.status === API_SUCCESS_STATUS_CODE) {
          allRecords.push(...responseItem.data.data);
          tempSearchResponseList.push(...responseItem.data.data);
          // isSearchScheduled = !!responseItem.data?.[PAGE_ID_KEY];
          const copyObject = {
            ...payloadList[responseIter],
            [PAGE_ID_KEY]: responseItem?.data?.[PAGE_ID_KEY]
          };
          payloadList[responseIter] = copyObject;
          hasNextPage = !!responseItem?.data?.[PAGE_ID_KEY];
        }
      });
      if (action?.payload?.logOrExceptionType?.toLowerCase() === 'log') {
        yield put(
          storeLogSearchData(
            action?.payload?.searchedTabKey,
            tempSearchResponseList,
            JSON.stringify(payloadList),
            hasNextPage
          )
        );
      } else {
        yield put(
          storeExceptionSearchData(
            action?.payload?.searchedTabKey,
            tempSearchResponseList,
            JSON.stringify(payloadList),
            hasNextPage
          )
        );
      }

      // if (!isSearchScheduled) {
      //   break;
      // }

      yield delay(SEARCH_RECALL_TIME);
    }
  } catch (err: any) {
    console.log(err);
  }
}

export function * cancelSearchApiCallSagaFn (): any {
  if (searchCancelToken) {
    yield call(searchCancelToken.cancel);
  }
  // yield call(action.payload.cancelToken.cancel);
}
export function * fetchSearhData (): Generator<ForkEffect<never>> {
  yield takeLatest(FETCH_SEARCH_DATA, fetchSearchDataSagaFn);
}
export function * cancelApiCalls (): Generator<any> {
  yield takeLatest(CANCEL_SEARCH_APICALL, cancelSearchApiCallSagaFn);
}
export default function * root (): Generator<AllEffect<any>> {
  yield all([fork(fetchSearhData), fork(cancelApiCalls)]);
}
