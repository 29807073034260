import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import Tabs from '../../common/Tabs';
import LogExceptionTabContentDataRenderer from '../TabContents/LogExceptionDetail/DataTabContent';
import JmsTabContent from '../TabContents/LogExceptionDetail/JmsTabContent';
import MessagePropertiesTabContent from '../TabContents/LogExceptionDetail/MessagePropertiesTabContent';
import { AUDIT_LOG_KEY, DUMP_ANALYSIS_KEY, JMS_HEADER_KEY, MSG_PAIR_KEY, TRANSACTION_AFTER_KEY, TRANSACTION_BEFORE_KEY, TRANSACTION_DATA_KEY } from '../../../constants/KeyLabels/commonKeyConstants';
import { DUMP_ANALYSIS_LABEL } from '../../../constants/KeyLabels/labelConstants';
import { TabContentMaxHeightWrapper } from './style';
import AuditLogTabContent from '../TabContents/LogExceptionDetail/AuditLogTabContent';

interface componentProps {
  data?: any
  hasDownload?: boolean
  isMetricView?: boolean
  logExceptionType?: string
  updateItemDetailTransactionData: any
}

const LogExceptionDetailsTabContent: FC<componentProps> = ({ data, hasDownload, isMetricView, logExceptionType, updateItemDetailTransactionData }) => {
  return <>
    <Tabs
      tabSize="small"
      tabClassName={'blue-theme'}
      tabType="line"
      tabItems={
        [...(logExceptionType?.toString().toLowerCase() === 'log'
          ? [
              data?.[TRANSACTION_BEFORE_KEY] && {
                key: TRANSACTION_BEFORE_KEY,
                label: 'Before Data',
                children: (
                  <TabContentMaxHeightWrapper>
                    <LogExceptionTabContentDataRenderer
                      testId={'tab-content-beforeData'}
                      recordData={data}
                      data={data[TRANSACTION_BEFORE_KEY]}
                      dataKeyName={TRANSACTION_BEFORE_KEY}
                      logExceptionType={logExceptionType}
                      hasDownload={hasDownload}
                    />
                </TabContentMaxHeightWrapper>
                )
              },
              data?.[TRANSACTION_AFTER_KEY] && {
                key: TRANSACTION_AFTER_KEY,
                label: 'After Data',
                children: (
                  <TabContentMaxHeightWrapper>
                    <LogExceptionTabContentDataRenderer
                      testId={'tab-content-afterData'}
                      recordData={data}
                      data={data[TRANSACTION_AFTER_KEY]}
                      dataKeyName={TRANSACTION_AFTER_KEY}
                      logExceptionType={logExceptionType}
                      hasDownload={hasDownload}
                    />
                  </TabContentMaxHeightWrapper>
                )
              }
            ]
          : [
              data?.[AUDIT_LOG_KEY]?.length > 0 &&
            {
              key: AUDIT_LOG_KEY,
              label: 'Audit Logs',
              children: (
                <TabContentMaxHeightWrapper >
                  <AuditLogTabContent data={data[AUDIT_LOG_KEY]} />
                </TabContentMaxHeightWrapper>
              )
            },
              data?.[TRANSACTION_DATA_KEY] && {
                key: TRANSACTION_DATA_KEY,
                label: 'Transaction Data',
                children: (
                  <TabContentMaxHeightWrapper>
                    <LogExceptionTabContentDataRenderer
                      testId={'tab-content-trans-data'}
                      recordData={data}
                      data={data[TRANSACTION_DATA_KEY]}
                      dataKeyName={TRANSACTION_DATA_KEY}
                      logExceptionType={logExceptionType}
                      updateItemDetailTransactionData={updateItemDetailTransactionData}
                      isMetricView={isMetricView}
                      hasDownload={hasDownload}
                    />
                  </TabContentMaxHeightWrapper>
                )
              },
              data?.[DUMP_ANALYSIS_KEY] && {
                key: DUMP_ANALYSIS_KEY,
                label: DUMP_ANALYSIS_LABEL,
                children: (
                  <TabContentMaxHeightWrapper>
                  <LogExceptionTabContentDataRenderer
                    testId={'tab-content-dump-analysis'}
                    recordData={data}
                    data={data[DUMP_ANALYSIS_KEY]}
                    dataKeyName={DUMP_ANALYSIS_KEY}
                    logExceptionType={logExceptionType}
                    isMetricView={isMetricView}
                    hasDownload={hasDownload}
                  />
                </TabContentMaxHeightWrapper>
                )
              }
            ]),
        data?.[MSG_PAIR_KEY] && {
          key: MSG_PAIR_KEY,
          label: 'Message Properties',
          children: (
            <TabContentMaxHeightWrapper>
              <MessagePropertiesTabContent data={data[MSG_PAIR_KEY]} />
            </TabContentMaxHeightWrapper>
          )
        },
        data?.[JMS_HEADER_KEY] && data?.[JMS_HEADER_KEY] !== 'null' && {
          key: JMS_HEADER_KEY,
          label: 'JMS Properties',
          children: (
            <TabContentMaxHeightWrapper>
              <JmsTabContent data={data[JMS_HEADER_KEY]} />
            </TabContentMaxHeightWrapper>
          )
        }
        ]
      } />
  </>;
};

LogExceptionDetailsTabContent.propTypes = {
  data: PropTypes.any,
  hasDownload: PropTypes.bool,
  isMetricView: PropTypes.bool,
  logExceptionType: PropTypes.string,
  updateItemDetailTransactionData: PropTypes.func
};

export default LogExceptionDetailsTabContent;
