import { commonApiRequestHandler } from './CommonServiceHandler';
import { API_SUCCESS_STATUS_CODE } from '../constants/commonConstants';
import apiEndpointConstants from '../constants/apiEndpointConstants';
import { type ApplicationAccessType } from '../constants/Interfaces/GroupAccessTypes';

export const mirrorUserAccessRequestHandler = async (
  mirrorId: string
): Promise<any> => {
  try {
    const apiRequestConfig = {
      method: 'GET',
      data: mirrorId,
      url: `${apiEndpointConstants.GET_MIRROR_USER_ACCESS}/${mirrorId}`
    };

    const response = await commonApiRequestHandler(apiRequestConfig);

    if (response.status === API_SUCCESS_STATUS_CODE) {
      const roleAccessData = response?.data?.roleAccess ?? {};
      const groupAccessData = response?.data?.groupAccess.filter((item: ApplicationAccessType) => roleAccessData[item.applicationName]?.includes(item.accessName));

      return {
        ...response.data,
        groupAccess: groupAccessData
      };
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};
