import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import { type FormFieldType } from '../../../../../constants/Interfaces/FormFieldTypes';
import {
  replayConfigIFBlockFormFields,
  getUpdatedReplayConfigThenFormConfig
} from '../../../../../constants/FormFields/AdminForm/replayConfigFormFields';
import textConstants from '../../../../../constants/textConstants';
import {
  LabelValueWrapper,
  LabelWrapper
} from '../../../../../assets/styles/commonStyled';
import { SummaryViewRow } from '../style';
import { getLabelValueFormatter } from '../../../../../helpers/commonHelper';

interface ConfigSummaryProps {
  data?: any
}

const ReplayConfigSummary: FC<ConfigSummaryProps> = ({ data }) => {
  const replayThenFormConfig = getUpdatedReplayConfigThenFormConfig(data?.replayMode);

  return (
    <>
      <SummaryViewRow
        gutter={[16, 8]}
        data-title={textConstants.REPLAY_CONFIG_DETAIL_IF_CRITERIA_LABEL}
        className="criteria-separator-wrapper"
      >
        {replayConfigIFBlockFormFields.map((formField: FormFieldType) => {
          return (
            <Col key={formField.id} xs={24} md={6}>
              <LabelWrapper>{formField.label}</LabelWrapper>
              <LabelValueWrapper>{getLabelValueFormatter(data?.[formField.name], formField.type)}</LabelValueWrapper>
            </Col>
          );
        })}
      </SummaryViewRow>
      <SummaryViewRow
        gutter={[16, 8]}
        data-title={textConstants.REPLAY_CONFIG_DETAIL_THEN_CRITERIA_LABEL}
        className="criteria-separator-wrapper-without-border"
      >
        {Object.keys(replayThenFormConfig).map((field: any) => {
          return (
            <Col key={replayThenFormConfig[field]?.id} xs={24} md={8} xxl={6}>
              <LabelWrapper>
                {replayThenFormConfig[field]?.label}
              </LabelWrapper>
              <LabelValueWrapper>
                {getLabelValueFormatter(data?.[replayThenFormConfig[field]?.name], field.type)}
              </LabelValueWrapper>
            </Col>
          );
        })}
      </SummaryViewRow>
    </>
  );
};

ReplayConfigSummary.propTypes = {
  data: PropTypes.object
};

export default ReplayConfigSummary;
