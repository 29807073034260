import styled from 'styled-components';

export const AutoCompletePopover = styled.div`
    background: var(--white);
    max-height: 350px;
    box-shadow: var(--popover-box-shadow);
    color: var(--black);
    padding: 5px;
    left: 0;
    right: 0;
    top: 103%;
    position: absolute;
    border: 1px solid var(--gray-250);
    z-index: 9999;
    overflow-y: auto;
    opacity: 0;
    
    &.fade-in {
        opacity: 1;
        animation: fadeIn .25s ease-in;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            border: 1px solid var(--white);
            border-radius: 4px;
            margin-bottom: 5px;
            padding: 2px 5px;
            font-size: var(--font-size-sm);

            &:hover {
                background: var(--blue-color-100);
                color: var(--white);
                // background-color: var(--yellow-400);
                // border: 1px solid var(--yellow-200);
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`
export const AutoCompleteInputWrapper = styled.div`
    height: 100%;
    position: relative;

    .query-submit-text {
        position: absolute;
        right: 60px;
        font-size: var(--font-size-xs);
        top: 4px;
        color: var(--gray-575);
        opacity:1;
        z-index: 9;
    }

    .ant-input {
        height: 100%;
        text-overflow: ellipsis;
    }

    textarea {
        resize: none;
        padding-top: 8px;
        padding-left: 0;
    }

    .ant-input-textarea-affix-wrapper.ant-input-affix-wrapper {
        height: 100%!important;
        justify-content: center;
        align-items: center;

        .ant-input-suffix .ant-input-clear-icon {
            right: 60px;
            top: 55%;
            transform: translateY(-50%);
        }
    }
`
