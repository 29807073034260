import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { TabContentWrapper, TabContentHeader, DataLabelWrapper, DataValueWrapper } from './style';

const MessagePropertiesTabContent: FC<{ data?: any, logExceptionType?: string }> = ({ data, logExceptionType }) => {
  return (
        <TabContentWrapper>
            <Row>
                <Col xs={2} md={6}><TabContentHeader style={{ textAlign: 'left' }}>Name</TabContentHeader></Col>
                <Col md={8} xl={16}><TabContentHeader style={{ textAlign: 'left' }}>Value</TabContentHeader></Col>
            </Row>
            {
                data?.map((elementInArray: any) => {
                  return (<Row gutter={[8, 8]} key={`${String(elementInArray.name || elementInArray.Name)}`}>
                        <Col xs={2} md={6}>
                            <DataLabelWrapper>{elementInArray.name || elementInArray.Name}</DataLabelWrapper>
                        </Col>
                        <Col md={8} xl={16}>
                            <DataValueWrapper>{elementInArray.value || elementInArray.Value}</DataValueWrapper>
                        </Col>
                    </Row>)
                })
            }

        </TabContentWrapper>
  );
};
MessagePropertiesTabContent.propTypes = {
  data: PropTypes.any,
  logExceptionType: PropTypes.string
};
export default MessagePropertiesTabContent;
